'use strict';

angular.module('core').controller('ArtworkGalleryModalController', [
    '$rootScope',
    '$scope',
    '$stateParams',
    '$timeout',
    'OpenPopupForm',
    'Messages',
    '_',
    function ($rootScope, $scope, $stateParams, $timeout, OpenPopupForm, Messages, _) {
        var self = this;
        this.OpenPopupForm = OpenPopupForm;
        this.Messages = Messages;
        this.$stateParams = $stateParams;

        function findArtworkIndex() {
            self.currentArtworkIndex = undefined;

            var foundIndex = _.findIndex(self.artworks, function (artwork) {
                return artwork.id === self.artworkId;
            });

            if (foundIndex > -1) {
                self.currentArtworkIndex = foundIndex;
            }
        }

        this.init = function () {
            self.artwork = self.params && self.params.artwork ? self.params.artwork : {};
            self.channel = self.params && self.params.channel ? self.params.channel : {};
            self.artworkId = self.artwork.id;
            self.artworks = self.params && self.params.artworks ? self.params.artworks : [self.artwork];
            self.eventId = self.params && self.params.eventId ? self.params.eventId : undefined;
            self.event = self.params && self.params.event ? self.params.event : undefined;
            self.isEventOwner =
                self.params && self.params.isEventOwner ? self.params.isEventOwner : undefined;
            self.galleryTitle = self.params && self.params.galleryTitle ? self.params.galleryTitle : '';
            // Params that will be available on artwork info controller
            $stateParams.artworkId = self.artworkId;
            $stateParams.channelId = self.artwork.belongsToChannelId;
            $stateParams.channelHidePrices = self.channel ? self.channel.hide_prices : false;
            $stateParams.collectionId = self.artwork.belongsToCollectionId;
            $stateParams.fromModal = true;
            $stateParams.autoPlay = true;
            self.notLoadingArtwork = true;
            self.loadingArtworkInfo = true;
            findArtworkIndex();
        };

        $scope.$on('ArtworkInfoArtworkController::finished_loading', function () {
            $timeout(function () {
                self.loadingArtworkInfo = false;
            }, 0);
        });

        this.navigateArtwork = function (to) {
            if (
                (self.currentArtworkIndex === 0 && to < 0) ||
                (self.currentArtworkIndex === self.artworks.length - 1 && to > 0)
            ) {
                return;
            } else {
                var nextArtwork = self.artworks[self.currentArtworkIndex + to];
                if (nextArtwork) {
                    self.notLoadingArtwork = false;
                    self.loadingArtworkInfo = true;
                    $timeout(function () {
                        $stateParams.artworkId = nextArtwork.id;
                        $stateParams.channelId = nextArtwork.belongsToChannelId;
                        $stateParams.collectionId = nextArtwork.belongsToCollectionId;
                        self.currentArtworkIndex += to;
                        self.notLoadingArtwork = true;
                    }, 0);
                }
            }
        };
    },
]);
