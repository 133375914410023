'use strict';

angular.module('admin-area').controller('AdminAreaEventsController', [
    '$rootScope',
    '$scope',
    'events',
    'EventsDataService',
    'Messages',
    '_',
    '$state',
    function ($rootScope, $scope, events, EventsDataService, Messages, _, $state) {
        var self = this;
        this.EventsDataService = EventsDataService;
        this.events = events;
        this.fromState = 'admin-area';

        $scope.getMaxHeightOfSideAndMainElements = function () {
            var sideBarHeight = angular.element(document.getElementById('events-sidebar'))[0].offsetHeight;
            var mainAreaHeight = angular.element(document.getElementById('events-main-area'))[0].offsetHeight;
            var maxHeight = Math.max(sideBarHeight, mainAreaHeight, 100);
            maxHeight += 'px';

            return { height: maxHeight };
        };

        function goToEvent(events, id) {
            var eventId = events && !id ? events[0].id : id;
            $state.go(`${self.fromState}.events.event`, { eventId });
        }

        this.addEvent = function () {
            var messageParams = {};
            messageParams.message = 'You are about to create a new event-submission.';
            messageParams.title = 'Create Event';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Create';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.EventsDataService.addEvent().then(
                        function (res) {
                            var messageParams = {};
                            messageParams.message = 'Event was created';
                            $scope.submitWasClicked = false;
                            Messages.openMessage($scope, messageParams);
                            goToEvent(null, res.id);
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'Event creation failed.\n';
                            messageParams.title = 'Failed To Create Event';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        this.duplicateEvent = function (eventId) {
            var messageParams = {};
            messageParams.message = 'You are about to duplicate this event';
            messageParams.title = 'Duplicate Event';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Duplicate';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.EventsDataService.duplicateEvent(eventId).then(
                        function (res) {
                            var messageParams = {};
                            messageParams.message = 'Event was created';
                            $scope.submitWasClicked = false;
                            Messages.openMessage($scope, messageParams);
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'Event creation failed.\n';
                            messageParams.title = 'Failed To Create Event';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        this.deleteEvent = function (eventId) {
            var messageParams = {};
            messageParams.message = 'You are about to delete this event and all its data!';
            messageParams.title = 'Delete Event';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.EventsDataService.deleteEvent(eventId).then(
                        function (res) {
                            var messageParams = {};
                            messageParams.message = 'Event was deleted';
                            $scope.submitWasClicked = false;
                            Messages.openMessage($scope, messageParams);
                            $state.go('admin-area.events', { reload: true });
                        },
                        function (error) {
                            var info = (error.data.message && error.data.message.text) || 'Internal Error';

                            var messageParams = {};
                            messageParams.message = 'Event deletion failed.\n More Info: ' + info;
                            messageParams.title = 'Failed To Delete Event';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        $rootScope.$on('HomeDataService::refresh_all_events', function ($event, events) {
            self.events = events;
        });

        this.init = function () {
            if ($state.current.name === self.fromState + '.events') {
                goToEvent(events);
            }
        };
    },
]);
