'use strict';

angular.module('discover').controller('DiscoverMainTabController', [
    '$scope',
    function ($scope) {
        $scope.init = function () {
            $scope.lastScroll = null;
        };

        $scope.sidebar = [
            {
                name: 'Featured',
                link: 'featured',
            },
        ];
    },
]);
