'use strict';

angular.module('core').run([
    '$rootScope',
    '$timeout',
    '$state',
    '$filter',
    function ($rootScope, $timeout, $state, $filter) {
        var listener = function (event, toState) {
            $timeout(function () {
                if ($state.current.name) {
                    var titleArray = $state.current.name.split('.'),
                        i;

                    $rootScope.pageTitle = '';

                    for (i = 0; i < titleArray.length; i++) {
                        if (i !== titleArray.length) {
                            $rootScope.pageTitle += ' / ';
                        }
                        $rootScope.pageTitle += $filter('inflector')(titleArray[i], 'humanize');
                    }
                }
            });
        };

        $rootScope.$on('$stateChangeSuccess', listener);
    },
]);
