'use strict';

angular.module('catalog-thumbnail').directive('catalogThumbnail', [
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                channel: '=',
                link: '&',
                linkState: '@',
                linkStateParams: '@',
                totalItems: '=?',
                labelsEnabled: '=',
                buttonsEnabled: '=?',
                actionsEnabled: '=?',
                isAdmin: '=',
                isDiscover: '=',
                hideTitle: '=',
                hideDescription: '=',
                isMyArea: '=',
                adminPanelEnabled: '=',
            },
            templateUrl: 'modules/catalog-thumbnail/views/catalog-thumbnail.html',
            controller: 'ChannelController',
            controllerAs: 'vm',
            bindToController: true, //required in 1.3+ with controllerAs
        };
    },
]);
