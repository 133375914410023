'use strict';

angular.module('core').constant('PLAYER_TYPES', {
    REGULAR: 'FULL',
    AW_ONLY: 'AW_ONLY',
    AW_COVER: 'AW_COVER',
    ARTIST_BIO: 'ARTIST_BIO',
    CHANNEL_COVER: 'CH_COVER',
    CAMPAIGN: 'CAMPAIGN',
});
