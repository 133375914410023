'use strict';

angular.module('core').directive('adminPanel', [
    'AdminManager',
    'TagsService',
    '$window',
    '$http',
    '$timeout',
    function (AdminManager, TagsService, $window, $http, $timeout) {
        return {
            restrict: 'E',
            scope: {
                item: '=',
                type: '=',
                totalItems: '=',
                hideDelete: '=',
                title: '=?',
                onDone: '&?',
                inline: '=?',
            },
            link: function (scope, element, attrs) {
                scope.item.market_order = Number(scope.item.market_order);
                scope.AdminManager = AdminManager;
                scope.ratings = [0, 1, 2, 3, 4, 5];
                scope.title = angular.isDefined(scope.title) ? scope.title : '';
                scope.updatingTags = false;

                scope.inline = angular.isDefined(scope.inline) ? scope.inline : false;

                scope.handleAction = function (type, id, actionName, value) {
                    if (actionName === 'market_order' && (value > 100 || value === undefined)) value = 100;

                    scope.item[actionName] = value;
                    AdminManager.handleAdminAction(type, id, actionName, value).then(function (res) {
                        if (actionName === 'tags') {
                            $timeout(() => {
                                scope.updatingTAgs = false;
                            }, 1000);
                        }
                        if (scope.onDone !== undefined) {
                            scope.onDone();
                        } else {
                            if (actionName === 'rating' || actionName === 'market_order') {
                                //                            $state.go($state.current, {}, {reload: true});
                                //                            $location.path(scope.transitionTo);
                                $window.location.reload(true);
                            }
                        }
                    });
                };
                scope.openAdvancedAdminPanel = function () {};

                scope.copyArtWorkId = function (id) {
                    $window.navigator.clipboard.writeText(id);
                };

                scope.loadTags = function ($query) {
                    var data = {
                        query: $query,
                    };

                    return $http.post('/tags/getTags', data).then((res) => {
                        var tags = [];
                        res.data.tags.forEach((tag) => {
                            tags.push({ text: tag.Tag.name });
                        });
                        return tags;
                    });
                };

                // Handle Tags
                scope.$watch(
                    'item.tags',
                    function (newVal, oldVal) {
                        var rand = Math.floor(Math.random() * (2 - 1 + 1) + 1); //Generate Random number between 1 - 2
                        setTimeout(() => {
                            if (newVal && newVal !== oldVal && !scope.updatingTags) {
                                scope.updatingTAgs = true;
                                TagsService.updateTags(newVal);
                                scope.handleAction(scope.type, scope.item.id, 'tags', newVal);
                            }
                        }, rand * 100);
                    },
                    true
                ); // 'true' for deep object watching
            },
            templateUrl: 'modules/core/directives/admin-panel.html',
        };
    },
]);
