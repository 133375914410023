'use strict';

angular.module('core').service('ChannelService', [
    '$http',
    '$rootScope',
    '$q',
    '$log',
    '$analytics',
    'HomeDataService',
    'OpenPopupForm',
    '$state',
    'Messages',
    'User',
    '_',
    'ENTITY_ACTIONS',
    'CHANNEL_TYPES',
    'LocalConfig',
    function (
        $http,
        $rootScope,
        $q,
        $log,
        $analytics,
        HomeDataService,
        OpenPopupForm,
        $state,
        Messages,
        User,
        _,
        ENTITY_ACTIONS,
        CHANNEL_TYPES,
        LocalConfig
    ) {
        var self = this;
        self.LocalConfig = LocalConfig;

        function isAllowedForUnauth(action) {
            var result = false;

            if (action.indexOf('getArtworksPathsForThumbnail') > -1) {
                result = true;
            }

            return result;
        }

        this.handleChannelAction = function (actionName, channel, params) {
            var deferred = $q.defer(),
                actionNotAllowed,
                action;
            params = params || {};

            if (!User.exists() && !isAllowedForUnauth(actionName)) {
                $log.debug('ChannelService::handleArtworkAction action not allowed', {
                    channel: channel,
                    action: actionName,
                });
                $rootScope.$broadcast('Auth:Failed', {
                    service: 'channel',
                    actionName: actionName,
                    channel: channel,
                    params: params,
                });
                actionNotAllowed = true;
            }

            switch (actionName) {
                case 'delete':
                    action = self.deleteChannel;
                    break;
                case 'getArtworksPathsForThumbnail':
                    action = self.getArtworksPathsForThumbnail;
                    break;
                case 'subscribe':
                    action = self.subscribe;
                    break;
                case 'unsubscribe':
                    action = self.unsubscribe;
                    break;
                case 'share':
                case 'toggleSubscription':
                    action = self.toggleSubscription;
                    break;
                default:
                    action = '';
                    $log.debug('trying to perform an action on channel that is not defined');
            }

            if (action && !actionNotAllowed) {
                action(channel, params).then(
                    function (res) {
                        $log.debug('ChannelService::' + actionName + ' Success', {
                            channel: channel,
                            params: params,
                            res: res,
                        });
                        if (actionName !== 'getArtworksPathsForThumbnail') {
                            $analytics.eventTrack(actionName + 'Channel', {
                                category: 'Channel',
                                label: 'ID: ' + channel.id + ' Name: ' + channel.name,
                            });
                        }
                        deferred.resolve(res);
                    },
                    function (err) {
                        if (err && err === 'channel.is_locked') {
                            openPasswordPopup('channel', { channel: channel, channelParams: params });
                            deferred.reject(err);
                        }

                        $log.debug('ChannelService::' + actionName + ' Failure', {
                            channel: channel,
                            params: params,
                            error: err,
                        });
                        deferred.reject(err);
                    }
                );
            } else {
                deferred.reject();
            }

            return deferred.promise;
        };

        this.getArtworksPathsForThumbnail = function (channel) {
            var deferred = $q.defer();
            var paths, artworks, i, j, thumbnailExists;
            var maxthumbs = 1;
            paths = [];
            if (channel.cover && channel.cover.path) {
                paths.push('https:' + channel.cover.path);
            } else {
                artworks = channel.artworks ? channel.artworks.slice(0, maxthumbs) : [];

                if (artworks.length > 0) {
                    paths = _.pluck(artworks, 'thumbnail_path');
                    for (i = 0; i < paths.length; i++) {
                        paths[i] = 'https:' + paths[i];
                    }
                }

                if (paths.length < maxthumbs && channel.collections.length > 0) {
                    i = 0;
                    // For every collection
                    while (paths.length < maxthumbs && channel.collections.length > i) {
                        j = 0;
                        // For every artwork
                        while (paths.length < maxthumbs && channel.collections[i].artworks.length > j) {
                            thumbnailExists =
                                paths.indexOf(channel.collections[i].artworks[j].thumbnail_path) > -1;

                            if (!thumbnailExists) {
                                paths.push('https:' + channel.collections[i].artworks[j].thumbnail_path);
                            }
                            j++;
                        }
                        i++;
                    }
                }
            }

            channel.thumbnail_paths = paths;

            deferred.resolve(paths);
            return deferred.promise;
        };

        this.getTotalArtworksInChannel = function (channel) {
            if (channel.total_artworks !== undefined) {
                // came from server - return "as is"
                return channel.total_artworks;
            }
            var total_number = 0;
            total_number += channel.artworks.length;
            channel.collections.forEach(function (single_collection) {
                total_number += single_collection.artworks.length;
            });
            return total_number;
        };

        this.deleteChannel = function (channelId) {
            var data = {
                channelId: channelId,
            };

            return $http.post('/channels/deleteChannel', data);
        };

        this.fetchChannelDataByUrlName = function (channelUrlName, params, channel) {
            var data = {
                channelUrlName: channelUrlName,
                isEdit: false,
            };

            if (params !== undefined) {
                if (params.publishUniqueSeq !== undefined) {
                    data.publishUniqueSeq = params.publishUniqueSeq;
                    data.password = params.password;
                }
            }

            return $http.post(
                '/channels/getChannelDataByUrlName?load=' + self.LocalConfig.get('loadLevel', 1),
                data
            );
        };

        this.fetchChannelData = function (channelId, params, channel) {
            var data = {
                channelId: channelId,
                isEdit: false,
            };

            return $http.post('/channels/getChannelData?load=' + self.LocalConfig.get('loadLevel', 1), data);
        };

        //Duplicate with ChannelInfoChannelController
        function openPasswordPopup(passwordType, params) {
            var messageParams = {};

            messageParams.disableAutoDismiss = true;
            // messageParams.cancelText = 'Cancel';
            messageParams.blur = true;
            messageParams.hideFooter = true;
            // messageParams.cancelFunction = function () {
            //     $state.go('home-page');
            // };
            //messageParams.okFunction = loadChannel;
            messageParams.templateParams = { passwordType: passwordType, channelParams: params };
            messageParams.template =
                'modules/channel-info/views/channel-info.channel-password.client.view.html';
            Messages.openMessage($rootScope, messageParams);
        }

        this.toggleSubscription = function (channel, params) {
            if (channel.is_subscribed) {
                return self.unsubscribe(channel, params);
            } else {
                if (!params.share && channel.is_locked && params.actionPassword === undefined) {
                    var deferred = $q.defer();
                    deferred.reject('channel.is_locked');
                    return deferred.promise;
                }
                return self.subscribe(channel, params);
            }
        };

        this.getIdFromUrlName = function (urlName) {
            return urlName ? urlName.split('-')[0] : '-1';
        };

        this.subscribe = function (channel, params) {
            var deferred = $q.defer(),
                data = {
                    channelId: channel.id,
                },
                messageParams = {};

            if (params) {
                data.publishUniqueSeq = params.share;
                data.password = params.password;
                data.actionPassword = params.actionPassword;
                data.actionParameters = params.action ? params.action.parameters : null;
            }

            $http
                .post('/channelSubscriptions/subscribeToChannel', data)
                .success(function (res) {
                    channel.is_subscribed = true;
                    $rootScope.$broadcast(
                        'ChannelService::success_subscribe_toggle',
                        'subscribe',
                        channel,
                        res,
                        data
                    );
                    if (params && params.action) {
                        params.action.active = !params.action.active;
                        if (params.action.active) {
                            var channelsName = channel.type === 'AC' ? 'subscriptions' : 'sharedCatalogs';
                            // $state.go('home.channels.channel', {channelsName: 'shared' + type, channelUrlName: channel.url_name});
                            $state.go('home.channels.main', { channelsName: channelsName });
                        }
                    }
                    $log.debug('ChannelService::subscribe Success', res, data);

                    deferred.resolve(res);
                })
                .error(function (reason, status) {
                    $log.debug('ChannelService::subscribe Failure', reason, data);
                    if (status !== 401) {
                        // Unauthorize will be catched by interceptor
                        $rootScope.$broadcast(
                            'ChannelService::failed_subscribe_toggle',
                            'subscribe',
                            channel,
                            reason
                        );
                    }
                    // messageParams.message = 'There was an error subscribing to ' + channel.name + '.\nRefresh the page and try again.';
                    // messageParams.disableAutoDismiss = true;
                    // Messages.openMessage($rootScope, messageParams);
                    deferred.reject(reason);
                });

            return deferred.promise;
        };

        this.unsubscribe = function (channel, params) {
            var deferred = $q.defer(),
                data = {
                    channelId: channel.id,
                },
                messageParams = {};

            if (params) {
                data.publishUniqueSeq = params.share;
                data.password = params.password;
                data.actionParameters = params.action ? params.action.parameters : null;
            }

            messageParams = {};
            if (channel.type === CHANNEL_TYPES.CATALOG) {
                messageParams.title = 'Remove Catalog';
                messageParams.message = 'You are about to remove this catalog from your personal area.';
                messageParams.okText = 'Remove';
            } else {
                messageParams.title = 'Unsubscribe Artcast';
                messageParams.message = 'You are about to unsubscribe from this Artcast. ';
                messageParams.message +=
                    "This action will immediately remove the Artcast's content from your account and any paired display.";
                messageParams.okText = 'Unsubscribe';
            }
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http
                        .post('/channelSubscriptions/unsubscribeToChannel', data)
                        .success(function (res) {
                            $log.debug('ChannelService::unsubscribe Success', res, data);
                            channel.is_subscribed = false;
                            if (params && params.action) {
                                params.action.active = !params.action.active;
                                if (!params.action.active) {
                                    params.action.display = true;
                                    self.setActionNames(params.action, channel); //PATCH to solve RND-1510
                                }
                            }
                            $rootScope.$broadcast(
                                'ChannelService::success_subscribe_toggle',
                                'unsubscribe',
                                channel,
                                res,
                                data
                            );

                            deferred.resolve(res);
                        })
                        .error(function (reason, status) {
                            $log.debug('ChannelService::unsubscribe Failure', reason, data);
                            if (status !== 401) {
                                // Unauthorize will be catched by interceptor
                                $rootScope.$broadcast(
                                    'ChannelService::failed_subscribe_toggle',
                                    'unsubscribe',
                                    channel,
                                    reason
                                );
                            }
                            // messageParams.message = 'There was an error unsubscribing to ' + channel.name + '.\nRefresh the page and try again.';
                            // messageParams.disableAutoDismiss = true;
                            // Messages.openMessage($rootScope, messageParams);
                            deferred.reject(reason);
                        });
                } else {
                    deferred.resolve();
                }
            });

            return deferred.promise;
        };

        this.setActionActiveState = function (action, channel) {
            if (channel && action && action.id) {
                if (action.id === ENTITY_ACTIONS.SHARE) {
                    action.active = channel.is_subscribed;
                    if (!action.active) {
                        action.display = true;
                    }
                    self.setActionNames(action, channel); //PATCH to solve RND-1510
                }
                // else if (action.id === ENTITY_ACTIONS.LOAN) {
                //     action.active = channel.is_loaned;
                // } else if (action.id === ENTITY_ACTIONS.RENT) {
                //     action.active = channel.is_rented;
                // } else if (action.id === ENTITY_ACTIONS.TRANSFER_OWNERSHIP) {
                //     action.active = channel.is_ownership_transferred;
                // }
            }
        };

        this.setActionNames = function (action, channel) {
            if (channel && action && action.id) {
                if (action.id === ENTITY_ACTIONS.SHARE) {
                    action.addName = 'Subscribe';
                    action.removeName = 'Unsubscribe';

                    if (channel.type === CHANNEL_TYPES.CATALOG) {
                        action.addName = 'Add Catalog';
                        action.removeName = 'Remove';
                    }
                    action.onCoverOnly = true;
                } else if (action.id === ENTITY_ACTIONS.SENDPREVIEW) {
                    //We should not come to this point since we remove this action from action list...
                    action.addName = 'Show Preview';
                    action.removeName = 'Show Preview';
                }
                // else if (action.id === ENTITY_ACTIONS.LOAN) {
                //     action.addName = 'Loan';
                //     action.removeName = 'End Loaning';
                // } else if (action.id === ENTITY_ACTIONS.RENT) {
                //     action.addName = 'Rent';
                //     action.removeName = 'End Rental';
                // } else if (action.id === ENTITY_ACTIONS.DOWNLOAD) {
                //     action.addName = 'Download';
                //     action.removeName = 'Download';
                // } else if (action.id === ENTITY_ACTIONS.TRANSFER_OWNERSHIP) {
                //     action.addName = 'Accept';
                //     action.removeName = 'Accepted';
                // } else if (action.id === ENTITY_ACTIONS.BUY) {
                //     action.addName = 'Buy';
                //     action.removeName = 'Return';
                // }
            }
        };

        this.getChannelActions = function (type) {
            var actions = [
                {
                    id: '1',
                    name: 'share',
                    addName: 'Subscribe To Artcast',
                    removeName: 'Unsubscribe',
                    view: 'Private Subscribe',
                    onCoverOnly: true,
                    selected: true,
                    parameters: {},
                    shouldShow: true,
                    isPrimary: true,
                    explanation: 'Privately share your channel and allow subscription.',
                    display: false,
                },
                {
                    id: '8',
                    name: 'sendPreview',
                    addName: 'Show Preview',
                    removeName: 'Show Preview',
                    view: 'Send Preview',
                    onCoverOnly: true,
                    selected: false,
                    parameters: {},
                    shouldShow: true,
                    isPrimary: true,
                    disabled: false,
                    explanation:
                        'Privately share a link to your channel preview (without subscription option).',
                },
            ];

            if (type === CHANNEL_TYPES.CATALOG) {
                var shareAction = _.find(actions, function (action) {
                    return action.id === '1';
                });

                shareAction.addName = 'Add Catalog';
                shareAction.removeName = 'Remove';
                shareAction.view = 'Private Share';
                shareAction.explanation =
                    'Privately share your catalog and allow add it to their personal area.';

                var previewAction = _.find(actions, function (action) {
                    return action.id === '8';
                });

                previewAction.explanation =
                    'Privately share a link to your catalog preview, without ability to add to their personal area option.';
            }

            return actions;
        };

        this.initActions = function (channel) {
            // var actions = angular.copy(self.actions);
            var actions = self.getChannelActions();
            actions.forEach(function (action) {
                self.setActionActiveState(action, channel);
            });
            return actions;
        };

        this.updateEntityAdditionalFileStatus = function (channelId, params, channel) {
            var deferred = $q.defer();
            var data = {
                channelId: channelId,
                additionalFileId: params.additionalFileId,
                status: params.status,
                reason: params.reason,
            };

            $http.post('/additionalFiles/updateEntityAdditionalFileStatus', data).then(
                function (res) {
                    // if (artwork) artwork.status = params.status;
                    deferred.resolve(channelId);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        $rootScope.$on(
            'ChannelService::failed_subscribe_toggle',
            function (events, isSubscribe, channel, reason) {
                var messageParams = {};
                if (channel.type === CHANNEL_TYPES.CATALOG) {
                    messageParams.title = 'Action Failed';
                    messageParams.message =
                        'There was an error ' +
                        (isSubscribe === 'subscribe' ? 'adding' : 'removing') +
                        ' ' +
                        channel.name +
                        ' catalog.';
                    if (reason && reason.message && reason.message.text) {
                        messageParams.message += '\nError: ' + reason.message.text;
                    }
                } else {
                    if (reason.message.code && reason.message.code === 402) {
                        messageParams.title = 'Subscription';
                        messageParams.message = '';
                        if (reason.message.quota !== 0) {
                            messageParams.message =
                                'Sorry you have already subscribed to ' +
                                reason.message.used +
                                ' Curated Exhibitions.<br>';
                        }
                        // quota === 0 -> user is not allowed to subscribe to any channel

                        messageParams.message +=
                            'In order to unlock access to additional curated exhibitions, you can subscribe to one of our premium plans.';
                        messageParams.okText = 'Go To Plans';
                        messageParams.cancelText = 'Back';
                        messageParams.okFunction = function () {
                            Messages.ok();
                            OpenPopupForm.openMenu('plans', { fromModal: true });
                        };
                    } else {
                        messageParams.title = 'Subscription';
                        messageParams.message =
                            'There was an error ' +
                            (isSubscribe === 'subscribe' ? '' : 'un') +
                            'subscribing to ' +
                            channel.name +
                            '.';
                        if (reason && reason.message && reason.message.text) {
                            messageParams.message += '\nError: ' + reason.message.text;
                        }
                    }
                }

                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
            }
        );

        $rootScope.$on(
            'ChannelService::success_subscribe_toggle',
            function (events, isSubscribe, channel, response, data) {
                var messageParams = {};
                if (channel.type === CHANNEL_TYPES.CATALOG) {
                    messageParams.message =
                        'Successfully ' +
                        (isSubscribe === 'subscribe' ? 'added' : 'removed') +
                        ' ' +
                        channel.name +
                        ' catalog';
                } else {
                    messageParams.message =
                        'Successfully ' +
                        (isSubscribe === 'subscribe' ? '' : 'un') +
                        'subscribed channel ' +
                        channel.name;
                }
                Messages.openMessage($rootScope, messageParams);
                // if (isSubscribe !== 'subscribe') {
                //     $rootScope.previousState();
                // }

                HomeDataService.resetCachedChannels();
            }
        );
    },
]);
