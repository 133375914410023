//English
translationsEN.ADMIN_AREA = {};

translationsEN.ADMIN_AREA.DASHBOARD = 'Dashboard';
translationsEN.ADMIN_AREA.CMS = 'CMS';
translationsEN.ADMIN_AREA.REPORTS = 'Analytics';
translationsEN.ADMIN_AREA.STATUS = 'Displays Status';
translationsEN.ADMIN_AREA.DEVICE_MANAGEMENT = 'Display Management';
translationsEN.ADMIN_AREA.CAMPAIGNS = 'Campaigns';
translationsEN.ADMIN_AREA.INVITATIONS = 'Invitations';
translationsEN.ADMIN_AREA.RE_RUN = 'Re-Runs';
translationsEN.ADMIN_AREA.SERVICES = 'Services';

//HebrewDISCOVER
translationsHE.ADMIN_AREA = {};

translationsHE.ADMIN_AREA.STREAMS = 'שותף';
translationsHE.ADMIN_AREA.MY_ARTWORKS = 'האומנות שלי';
translationsHE.ADMIN_AREA.CONNECTIONS = 'קשרים';
translationsHE.ADMIN_AREA.DEVICES = 'מכשירים';
