'use strict';

angular.module('core').service('ArtworkService', [
    'Messages',
    '$http',
    '$rootScope',
    '$state',
    '$q',
    '$filter',
    '$window',
    '$log',
    '$analytics',
    'User',
    'BillingService',
    'ENTITY_ACTIONS',
    'OpenPopupForm',
    '_',
    'CHANNEL_TYPES',
    'EVENT_TYPES',
    'FavoritesService',
    function (
        Messages,
        $http,
        $rootScope,
        $state,
        $q,
        $filter,
        $window,
        $log,
        $analytics,
        User,
        BillingService,
        ENTITY_ACTIONS,
        OpenPopupForm,
        _,
        CHANNEL_TYPES,
        EVENT_TYPES,
        FavoritesService
    ) {
        var self = this;

        //this.downloadResolutions = [/*{profile: 'preview', view: 'Preview Quality (720p)'}, {profile: 'original', view: 'Original'}*/];
        this.thumbnailHeight = {};
        this.thumbnailImageRes = {};
        this.actions = [
            {
                id: '1',
                name: 'share',
                addName: 'Accept',
                removeName: 'Discard',
                view: 'Private Share',
                selected: true,
                parameters: {},
                shouldShow: true,
                isPrimary: true,
                explanation: 'Privately share your artwork. Revoke at any time.',
            },
            {
                id: '2',
                name: 'loan',
                addName: 'Loan (no fee)',
                removeName: 'End Loan',
                view: 'Loan (no fee)',
                selected: false,
                parameters: { periodInMonths: 3, periodType: 'M' },
                shouldShow: true,
                isPrimary: true,
                explanation:
                    'Privately loan your artwork (No transaction fee for the recipient). You can also define loan time period and additional meta-data as appears on the Transfer Artwork form.',
            },
            {
                id: '3',
                name: 'rent',
                addName: 'Borrow',
                removeName: 'End Borrow',
                view: 'Loan',
                selected: false,
                parameters: { periodInMonths: 3, periodType: 'M', price: 0 },
                shouldShow: true,
                isPrimary: true,
                explanation:
                    'Privately loan your artwork. You can define a transaction fee that will be paid by the recipient in order to access the loan. You can also define loan time period and additional meta-data as appears on the Transfer Artwork form.',
            },
            {
                id: '4',
                name: 'download',
                addName: 'Download',
                removeName: 'Download',
                removeIcon: 'modules/core/img/download.svg',
                addIcon: 'modules/core/img/download.svg',
                view: 'Download',
                selected: false,
                parameters: { downloadResolution: null },
                shouldShow: false,
                isPrimary: false,
            },
            {
                id: '5',
                name: 'transferOwnership',
                addName: 'Accept',
                removeName: 'Accepted',
                view: 'Transfer Ownership',
                selected: false,
                parameters: {},
                shouldShow: true,
                isPrimary: true,
                explanation: 'Transfer full ownership of a specific edition. Non-revocable.',
            },
            {
                id: '6',
                name: 'buy',
                addName: 'Buy',
                removeName: 'Purchased',
                view: 'Send Private Purchase Link',
                selected: false,
                parameters: {},
                shouldShow: true,
                isPrimary: true,
                explanation: 'Allow a specific recipient to purchase an artwork edition.',
            },
            {
                id: '7',
                name: 'consign',
                addName: 'Accept Consign',
                removeName: 'Remove Consign',
                removeIcon: 'modules/core/img/remove.svg',
                addIcon: 'modules/core/img/remove.svg',
                addedClass: 'btn-warning',
                view: 'Consign Artwork (Recipient May Sell & Loan Your Work)',
                selected: false,
                parameters: {},
                shouldShow: true,
                isPrimary: true,
                disabled: false,
                explanation:
                    "Allow another entity to sell and loan this artwork's editions on your behalf (i.e. Gallery).",
            },
            //{ 'id': '8', 'name': 'loan', 'addName': 'Loan', 'removeName': 'Return', 'view': 'Loan', selected: false, parameters: {}, shouldShow: true, isPrimary: true, disabled: true },
            //{ 'id': '9', 'name': 'rent', 'addName': 'Rent', 'removeName': 'Return', 'view': 'Rent', selected: false, parameters: {}, shouldShow: true, isPrimary: true, disabled: true },
            {
                id: '8',
                name: 'sendPreview',
                addName: 'Show Preview',
                removeName: 'Show Preview',
                view: 'Send Preview',
                selected: false,
                parameters: {},
                shouldShow: true,
                isPrimary: true,
                disabled: false,
                explanation:
                    'Privately share a link to your artwork preview. The recipient can only access a web page link with a watermarked version of the artwork.',
            },
            // TODO: Activate these buttons
            // { 'id': '10', 'name': 'inquire', 'addName': 'Inquire', 'removeName': 'Inquire', 'view': 'Inquire', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '11', 'name': 'wishlist', 'addName': 'Add To Wishlist', 'removeName': 'Remove From Wishlist', 'view': 'Wishlist', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '12', 'name': 'social-share', 'addName': 'Social Share', 'removeName': 'Social Share', 'view': 'Social Share', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '13', 'name': 'edit', 'addName': 'Edit', 'removeName': 'Edit', 'view': 'Edit', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '14', 'name': 'delete', 'addName': 'Delete', 'removeName': 'Delete', 'view': 'Delete', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '15', 'name': 'manage', 'addName': 'Manage', 'removeName': 'Manage', 'view': 'Manage', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '16', 'name': 'add', 'addName': 'Add', 'removeName': 'Remove', 'view': 'Add', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '17', 'name': 'events-shortlist', 'addName': 'ADD TO SHORTLIST', 'removeName': 'UNLIST', 'view': 'Shortlist', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '18', 'name': 'events-finalist', 'addName': 'ADD TO FINALIST', 'removeName': 'Remove From Finalist', 'view': 'Delete', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  },
            // { 'id': '19', 'name': 'events-comment', 'addName': 'ADD COMMENTS', 'removeName': 'VIEW COMMENTS', 'view': 'Comments', selected: false, parameters: {}, shouldShow: false, isPrimary: true, disabled: false, explanation: ''  }
        ];

        function isAllowedForUnauth(action) {
            var result = false;

            if (action.indexOf('getPreviewPathWithExtension') > -1) {
                result = true;
            }

            return result;
        }

        this.handleArtworkAction = function (actionName, artwork, params) {
            var deferred = $q.defer(),
                artworkId = artwork.id,
                actionNotAllowed,
                action;

            if (!User.exists() && !isAllowedForUnauth(actionName)) {
                $log.debug('ArtworkService::handleArtworkAction action not allowed', {
                    artwork: artwork,
                    action: actionName,
                });
                $rootScope.$broadcast('Auth:Failed', {
                    service: 'artwork',
                    actionName: actionName,
                    artwork: artwork,
                    params: params,
                });
                actionNotAllowed = true;
                deferred.reject();
            }

            if (!actionNotAllowed) {
                switch (actionName) {
                    case 'buy':
                        action = self.buyArtwork;
                        break;
                    case 'add':
                        action = self.addArtwork;
                        break;
                    case 'share':
                        action = self.toggleAcceptedArtwork;
                        break;
                    case 'addToFinalist':
                        action = self.addToFinalist;
                        break;
                    case 'loan':
                        action = self.toggleLoanedArtwork;
                        break;
                    case 'rent':
                        action = self.toggleRentedArtwork;
                        break;
                    case 'approve':
                        action = self.toggleEventApprovedArtwork;
                        break;
                    case 'approveArtworks':
                        action = self.toggleEventApprovedArtworks;
                        break;
                    case 'eventRating':
                        action = self.setEventRating;
                        break;
                    case 'eventSubmissionComments':
                        action = self.setEventSubmissionComments;
                        break;
                    case 'transferOwnership':
                        action = self.toggleTransferOwnership;
                        break;
                    case 'consign':
                        action = self.toggleConsignArtwork;
                        break;
                    case 'download':
                        action = self.downloadArtwork;
                        break;
                    case 'toggleArtwork':
                        action = self.toggleArtwork;
                        artwork.is_added = !artwork.is_added;
                        break;
                    case 'inquire':
                        action = self.openInquireArtworkModal;
                        break;
                    case 'delete':
                        action = self.deleteArtwork;
                        break;
                    case 'toggleWishlist':
                        action = self.toggleWishlist;
                        break;
                    case 'updateArtworkStatus':
                        action = self.updateArtworkStatus;
                        break;
                    case 'getArtworkStatus':
                        action = self.getArtworkStatus;
                        break;
                    case 'getPreviewPathWithExtension':
                        action = self.getPreviewPathWithExtension;
                        break;
                    default:
                        action = '';
                        $log.debug('trying to perform an action that is not defined');
                }
                if (action) {
                    action(artworkId, params, artwork).then(
                        function (res) {
                            $log.debug('ArtworkService::' + actionName + ' Success', {
                                artwork: artwork,
                                params: params,
                                res: res,
                            });
                            if (actionName !== 'getPreviewPathWithExtension') {
                                $analytics.eventTrack(actionName + 'Artwork', {
                                    category: 'Artwork',
                                    label: 'ID: ' + artworkId + ' Title: ' + artwork.title,
                                });
                            }
                            deferred.resolve(res);
                        },
                        function (err) {
                            $log.debug('ArtworkService::' + actionName + ' Failure', {
                                artwork: artwork,
                                params: params,
                                error: err,
                            });
                            var messageParams = {};
                            if (action === 'toggleWishlist') {
                                artwork.in_wishlist = !artwork.in_wishlist;
                                messageParams.message =
                                    'There was an error and the favorites was not updated';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($rootScope, messageParams);
                            } else if (action === 'toggleArtwork') {
                                artwork.is_added = !artwork.is_added;
                                messageParams.message = 'Action is not allowed, please contact sender';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($rootScope, messageParams);
                            }
                            deferred.reject(err);
                        }
                    );
                } else {
                    deferred.reject();
                }
            }

            return deferred.promise;
        };

        this.buyArtwork = function (artworkId, params, artwork) {
            const deferred = $q.defer();

            const data = {
                type: BillingService.otpTypes.artwork,
                artworkId,
                channelId: params.channelId,
                share: params.share,
            };

            params = params || {};

            BillingService.publishUniqueSeq = params.share;
            BillingService.password = params.password;
            BillingService.actionId = 'buy';
            BillingService.actionParameters = params.action ? params.action.parameters : null;
            BillingService.channelId = params.channelId;
            BillingService.isArtworkTransfer = params.share && !params.channelId;

            if (params.fromModal) {
                Messages.ok();
            }
            $state.go('buy', data);
            deferred.resolve();

            return deferred.promise;
        };

        this.addArtwork = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                data = {
                    artworkId,
                };

            $http
                .post('/artworks/addArtwork', data)
                .success(function (res) {
                    $log.debug('success trying to add artwork');
                    deferred.resolve(res);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.toggleLoanedArtwork = function (artworkId, params, artwork) {
            return self.toggleAcceptedArtworkImp(artworkId, params, artwork, 'loan');
        };

        this.toggleRentedArtwork = function (artworkId, params, artwork) {
            return self.toggleAcceptedArtworkImp(artworkId, params, artwork, 'rent');
        };

        this.toggleAcceptedArtwork = function (artworkId, params, artwork) {
            return self.toggleAcceptedArtworkImp(artworkId, params, artwork, 'share');
        };

        this.addToFinalist = function (artworkId, params, artwork) {
            if (params.event && params.event.type) {
                switch (params.event.type) {
                    case EVENT_TYPES.OPEN_CALL:
                        return self.toggleAcceptedArtwork(artworkId, params, artwork);
                    case EVENT_TYPES.CONSIGN_SUBMISSION:
                        return self.toggleConsignArtwork(artworkId, params, artwork, '7');
                }
            }
        };

        this.toggleAcceptedArtworkImp = function (artworkId, params, artwork, actionId) {
            var deferred = $q.defer();
            var actionParameters = params.action
                ? params.action.parameters
                : '{"eventId": "' + params.eventId + '"}';

            // Make sure that eventId will appear on params. actionParams comes as string and not json
            var jsonParams =
                typeof actionParameters === 'string' || actionParameters instanceof String
                    ? JSON.parse(actionParameters)
                    : actionParameters;
            params.eventId = params.eventId || jsonParams.eventId;
            jsonParams.eventId = params.eventId;
            actionParameters = JSON.stringify(jsonParams);

            var gotoNextState = params.eventId
                ? function () {
                      $state.go('home.events.host', { eventId: params.eventId }, { reload: true });
                  }
                : function () {
                      $state.go('home.vault.artwork', {
                          collectionId: '-2',
                          collectionLink: 'transfers',
                          artworkId,
                      });
                  };
            var redirectStringSuffix = params.eventId ? '' : ' Navigating to your vault';
            var data = {
                artworkId,
                publishUniqueSeq: params.share,
                password: params.password,
                actionId,
                actionParameters,
            };
            var messageParams = {};
            var optimisticAssumption = User.exists();
            var preventToggle;

            function doToggle() {
                // if (optimisticAssumption) {
                //     if(actionId === 'loan') {
                //         artwork.is_loaned = !artwork.is_loaned;
                //     } else if(actionId === 'rent') {
                //         artwork.is_rented = !artwork.is_rented;
                //     } else {
                //         artwork.is_accepted = !artwork.is_accepted;
                //     }
                //
                //     self.setActionActiveState(params.action, artwork);
                // }

                $http
                    .post('/artworks/toggleAcceptedArtwork', data)
                    .success(function (res) {
                        $log.debug('success trying to toggle artwork');
                        if (actionId === 'loan') {
                            artwork.is_loaned = res.new_value;
                        } else if (actionId === 'rent') {
                            artwork.is_rented = res.new_value;
                        } else {
                            artwork.is_accepted = res.new_value;
                        }
                        self.setActionActiveState(params.action, artwork);

                        $rootScope.$broadcast(
                            'ArtworkService::artwork_toggled_accept',
                            artworkId,
                            actionId,
                            res.new_value
                        );

                        if (res.new_value) {
                            messageParams = {};
                            messageParams.message =
                                'Artwork was successfully accepted.' + redirectStringSuffix;
                            Messages.openMessage($rootScope, messageParams).then(function () {
                                if (gotoNextState) {
                                    gotoNextState();
                                }
                            });
                        } else {
                            $rootScope.$broadcast('artwork_revoked', artworkId, artwork.collectionId);
                            var navigateAfterRemoval = null;
                            var navigateAfterRemovalParams = {};
                            var navigateAfterRemovalReload = false;
                            var navigateAfterRemovalMsg = '';
                            if ($state.includes('home.vault.main')) {
                                navigateAfterRemoval = null;
                            } else if ($state.includes('home.events.host')) {
                                navigateAfterRemoval = 'home.events.host';
                                navigateAfterRemovalParams = { eventId: params.eventId };
                                navigateAfterRemovalReload = true;
                            } else {
                                navigateAfterRemoval = 'home.vault.main';
                                navigateAfterRemovalParams = { collectionId: 0, collectionLink: 'all' };
                                navigateAfterRemovalMsg = '\nNavigating to your vault';
                            }
                            messageParams = {};
                            messageParams.message = 'Artwork was successfully removed.';
                            messageParams.message += navigateAfterRemovalMsg;
                            Messages.openMessage($rootScope, messageParams).then(function () {
                                if (navigateAfterRemoval) {
                                    $state.go(
                                        navigateAfterRemoval,
                                        navigateAfterRemovalParams,
                                        navigateAfterRemovalReload
                                    );
                                    $rootScope.$broadcast('Messages:closeOpenedModals'); //close all open dialogs.
                                }
                            });
                        }
                        deferred.resolve(res);
                    })
                    .error(function (err) {
                        // if (optimisticAssumption) {
                        //
                        //     if(actionId === 'loan') {
                        //         artwork.is_loaned = !artwork.is_loaned;
                        //     } else if(actionId === 'rent') {
                        //         artwork.is_rented = !artwork.is_rented;
                        //     } else {
                        //         artwork.is_accepted = !artwork.is_accepted;
                        //     }
                        //
                        //     self.setActionActiveState(params.action, artwork);
                        // }

                        messageParams = {};
                        messageParams.message = 'Operation failed.';
                        messageParams.message +=
                            err && err.message && err.message.text ? '\n' + err.message.text : '';
                        Messages.openMessage($rootScope, messageParams);

                        deferred.reject(err);
                    });
            }

            messageParams = null;
            if (actionId === 'share' && artwork.is_accepted) {
                messageParams = {
                    title: 'Discard Artwork',
                    message: 'Are you sure you want to discard this artwork?',
                    okText: 'Discard',
                };
            } else if (actionId === 'loan' && artwork.is_loaned) {
                messageParams = {
                    title: 'End Loan',
                    message:
                        'Are you sure you want to end this art work loan?\nPlease note that you can not undo this action.',
                    okText: 'End Loan',
                };
            } else if (actionId === 'rent') {
                if (artwork.is_rented) {
                    messageParams = {
                        title: 'End Rent',
                        message:
                            'Are you sure you want to end this art work rental?\nPlease note that you can not undo this action.',
                        okText: 'End Rent',
                    };
                } else {
                    const data = {
                        type: BillingService.otpTypes.rent,
                        artworkId,
                        channelId: params.channelId,
                        share: params.share,
                    };

                    BillingService.publishUniqueSeq = params.share;
                    BillingService.password = params.password;
                    BillingService.actionParameters = actionParameters;
                    BillingService.actionId = actionId;
                    BillingService.channelId = params.channelId;
                    BillingService.isArtworkTransfer = params.share && !params.channelId;

                    if (params.fromModal) {
                        Messages.ok();
                    }

                    $state.go('buy', data);
                    deferred.resolve();
                    preventToggle = true;
                }
            }

            if (!preventToggle) {
                if (messageParams !== null) {
                    messageParams.disableAutoDismiss = true;
                    messageParams.cancelText = 'Cancel';
                    messageParams.okClass = 'danger';
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        if (res === 'ok') {
                            doToggle();
                        } else {
                            deferred.resolve();
                        }
                    });
                } else {
                    doToggle();
                }
            }

            return deferred.promise;
        };

        this.toggleEventApprovedArtwork = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                data = {
                    artworkId,
                    eventId: params.eventId,
                },
                messageParams = {},
                silenceMassages = params.noMessages;

            function doToggle() {
                $http
                    .post('/events/toggleApprovedArtwork', data)
                    .success(function (res) {
                        $log.debug('success trying to toggle "approve"');
                        artwork.artwork_submission.approved =
                            res.new_value !== 'null' ? res.new_value : false;

                        if (!silenceMassages) {
                            if (res.new_value) {
                                messageParams = {};
                                messageParams.message = 'Artwork was added to Shortlist listed successfully.';
                                Messages.openMessage($rootScope, messageParams).then(function () {
                                    //do nothing...
                                });
                            } else {
                                messageParams = {};
                                messageParams.message = 'Artwork was successfully removed from Shortlist.';
                                Messages.openMessage($rootScope, messageParams).then(function () {
                                    //do nothing.
                                });
                            }

                            $rootScope.$broadcast('event_shortlist_refreshed', data.eventId);
                        }

                        deferred.resolve(res);
                    })
                    .error(function (err) {
                        if (!silenceMassages) {
                            messageParams = {};
                            messageParams.message = 'Operation failed.';
                            if (err && err.message) {
                                messageParams.message += err.message.text ? '\n' + err.message.text : '';
                            }
                            Messages.openMessage($rootScope, messageParams);
                        }

                        deferred.reject(err);
                    });
            }

            if (!silenceMassages) {
                messageParams = null;
                if (artwork.artwork_submission.approved) {
                    messageParams = {
                        title: 'Unlist Artwork',
                        message: 'Are you sure you want to unlist this artwork?',
                        okText: 'Unlist',
                    };
                }
                if (messageParams !== null) {
                    messageParams.disableAutoDismiss = true;
                    messageParams.cancelText = 'Cancel';
                    messageParams.okClass = 'danger';
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        if (res === 'ok') {
                            doToggle();
                        } else {
                            deferred.resolve();
                        }
                    });
                } else {
                    doToggle();
                }
            } else {
                doToggle();
            }

            return deferred.promise;
        };

        this.toggleEventApprovedArtworks = function (artworkId, params, artwork) {
            var deferred = $q.defer();

            params.noMessages = true;
            var artworks = params ? params.artworks : [];
            var nonApprovedArtworkExist = _.find(artworks, function (artwork) {
                return !artwork.artwork_submission.approved;
            });

            if (nonApprovedArtworkExist && artworks && artworks.length > 0) {
                var i = 0,
                    promises = [];

                for (i; i < artworks.length; i++) {
                    if (!artworks[i].artwork_submission.approved) {
                        promises.push(self.toggleEventApprovedArtwork(artworks[i].id, params, artworks[i]));
                    }
                }

                $q.all(promises)
                    .then(
                        function (result) {
                            var messageParams = {};
                            messageParams.message = 'Artworks were successfully added to Shortlist.';
                            Messages.openMessage($rootScope, messageParams);
                            deferred.resolve();
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'Something went wrong.';
                            Messages.openMessage($rootScope, messageParams);
                            deferred.reject(error);
                        }
                    )
                    .finally(function () {
                        $rootScope.$broadcast('event_shortlist_refreshed', params.eventId);
                    });
            } else if (!nonApprovedArtworkExist) {
                var messageParams = {};
                messageParams.message = 'All artworks are already in the Shortlist';
                Messages.openMessage($rootScope, messageParams);
                deferred.resolve();
            }

            return deferred.promise;
        };

        this.setEventRating = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                data = {
                    artworkId,
                    eventId: params.eventId,
                    rating: params.rating,
                };

            if (params.rating < 0 || params.rating > 5) {
                deferred.reject('invalid rating');
                return deferred.promise;
            }

            $http.post('/events/setArtworkRating', data).success(function (res) {
                $log.debug('successfully set rating.');

                if (res.new_value === undefined || res.new_value < 0 || res.new_value > 5) {
                    deferred.reject('invalid rating');
                    return;
                }

                $rootScope.$broadcast('artwork_changed_rating', data.artworkId, res.new_value, data.eventId);

                deferred.resolve(res);
            });

            return deferred.promise;
        };

        this.setEventSubmissionComments = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                data = {
                    artworkId,
                    eventId: params.eventId,
                };

            data.comments = artwork.artwork_submission.judge_comments.comments;

            $http.post('/events/setSubmissionComments', data).success(function (res) {
                $log.debug('successfully set comments.');
                artwork.artwork_submission.judge_comments.comments = res.new_value;
                $rootScope.$broadcast(
                    'event_submission_comments_changed',
                    data.artworkId,
                    res.new_value,
                    data.eventId
                );

                deferred.resolve(res);
            });

            return deferred.promise;
        };

        this.downloadArtwork = function (artworkId, params, artwork) {
            var actionParameters =
                params.action && !params.eventId
                    ? params.action.parameters
                    : '{"eventId": "' + params.eventId + '"}';

            var deferred = $q.defer(),
                data = {
                    artworkId,
                    publishUniqueSeq: params.share,
                    password: params.password,
                    actionParameters,
                },
                messageParams = {};

            $http
                .post('/artworks/downloadArtwork', data)
                .success(function (res) {
                    $log.debug('success trying to download artwork');
                    if (res.downloaded) {
                        var downloadUrl = res.downloaded.url;
                        // TODO RICH: media type should be specific, INTERACTIVE_ZIP
                        if (
                            artwork.file_metadata.media_type === undefined ||
                            artwork.file_metadata.media_type === null ||
                            artwork.file_metadata.media_type === ''
                        ) {
                            messageParams = {};
                            messageParams.title = 'Security Warning';
                            messageParams.message =
                                'This type of file might be harmful to your computer.\nPlease check if the artwork was sent from a trusted user.\nIt is recommended to scan the downloaded files with an updated anti virus software.\nDo you want to download it anyway?';
                            messageParams.disableAutoDismiss = true;
                            messageParams.cancelText = 'Cancel';
                            messageParams.okText = 'Download';
                            messageParams.okClass = 'danger';
                            Messages.openMessage($rootScope, messageParams).then(function (res) {
                                if (res === 'ok') {
                                    window.open(downloadUrl, '_self');
                                }
                            });
                        } else {
                            window.open(downloadUrl, '_self');
                        }
                    } else {
                        messageParams = {};
                        messageParams.title = 'Download Failed';
                        messageParams.message = 'Artwork download is not allowed';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                    deferred.resolve(res);
                })
                .error(function (err) {
                    self.setActionActiveState(params.action, artwork);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.toggleTransferOwnership = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                data = {
                    artworkId,
                    publishUniqueSeq: params.share,
                    password: params.password,
                    actionParameters: params.action.parameters,
                },
                messageParams = {},
                optimisticAssumption = User.exists();

            // if (optimisticAssumption) {
            //     artwork.is_ownership_transferred = !artwork.is_ownership_transferred;
            //     self.setActionActiveState(params.action, artwork);
            // }

            if (self.hasAvailableEdition(artwork)) {
                if (self.userOwnsAnEdition(artwork)) {
                    $log.debug('ArtworkService::toggleTransferOwnership user owns an edition already');
                    // if (optimisticAssumption) {
                    //     artwork.is_ownership_transferred = !artwork.is_ownership_transferred;
                    //     self.setActionActiveState(params.action, artwork);
                    // }
                    messageParams.title = 'Accept Artwork Edition';
                    messageParams.message =
                        'You already own an edition of this artwork, please contact the sender';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                    deferred.reject();
                } else {
                    $http
                        .post('/artworks/toggleTransferOwnership', data)
                        .success(function (res) {
                            $log.debug(
                                'ArtworkService::toggleTransferOwnership success trying to transfer artwork'
                            );
                            if (res.is_ownership_transferred !== artwork.is_ownership_transferred) {
                                artwork.is_ownership_transferred = res.is_ownership_transferred;
                                self.setActionActiveState(params.action, artwork);
                            }
                            if (artwork.is_ownership_transferred) {
                                messageParams.message =
                                    'Artwork was successfully accepted. Navigating to your vault';
                                Messages.openMessage($rootScope, messageParams).then(() => {
                                    $state.go('home.vault.artwork', {
                                        collectionId: '0',
                                        collectionLink: 'all',
                                        artworkId,
                                    });
                                });
                                //} else {
                                //    $rootScope.$broadcast('artwork_revoked', artworkId, artwork.collectionId);
                                //    var navigateAfterRemoval = true;
                                //
                                //    if ($state.includes('home.vault.main')) {
                                //        navigateAfterRemoval = false;
                                //    }
                                //    messageParams.message = 'Artwork was successfully removed.';
                                //    messageParams.message += navigateAfterRemoval ? '\nNavigating to your vault' : '';
                                //    Messages.openMessage($rootScope, messageParams)
                                //        .then(function() {
                                //            if (navigateAfterRemoval) {
                                //                $state.go('home.vault.main', {'collectionId': 0, 'collectionLink': 'all'});
                                //            }
                                //        });
                            }
                            deferred.resolve(res);
                        })
                        .error(function (err) {
                            $log.debug(
                                'ArtworkService::toggleTransferOwnership failed trying to transfer artwork'
                            );

                            // if (optimisticAssumption) {
                            //     artwork.is_ownership_transferred = !artwork.is_ownership_transferred;
                            //     self.setActionActiveState(params.action, artwork);
                            // }
                            messageParams.title = 'Accept Artwork Edition';
                            messageParams.message = 'Action is not allowed, please contact sender';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams);
                            deferred.reject(err);
                        });
                }
            } else {
                // not available edition exist
                $log.debug('ArtworkService::toggleTransferOwnership not an available edition to sell');
                // if (optimisticAssumption) {
                //     artwork.is_ownership_transferred = !artwork.is_ownership_transferred;
                //     self.setActionActiveState(params.action, artwork);
                // }
                messageParams.title = 'Accept Artwork Edition';
                messageParams.message =
                    'There is not an available edition of this artwork, please contact the sender';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
                deferred.reject();
            }

            return deferred.promise;
        };

        this.toggleConsignArtwork = function (artworkId, params, artwork, actionId) {
            var deferred = $q.defer();
            var actionParameters =
                params.action && !params.eventId
                    ? params.action.parameters
                    : '{"eventId": "' + params.eventId + '"}';
            actionId = params.action ? params.action.id : actionId;

            var gotoNextState = params.eventId
                ? function () {
                      $state.go('home.events.host', { eventId: params.eventId }, { reload: true });
                  }
                : function () {
                      $state.go('home.portfolio.artwork', {
                          collectionId: '-4',
                          collectionLink: 'consigned',
                          artworkId,
                      });
                  };
            var redirectStringSuffix = params.eventId ? '' : ' Navigating to you portfolio';
            var data = {
                artworkId,
                publishUniqueSeq: params.share,
                password: params.password,
                actionId,
                actionParameters,
            };
            var messageParams = {};
            var optimisticAssumption = User.exists();

            function doToggle() {
                $http
                    .post('/artworks/toggleArtworkConsignee', data)
                    .success(function (res) {
                        $log.debug('success trying to toggle artwork');
                        artwork.is_consignee = res.new_value;
                        self.setActionActiveState(params.action, artwork);
                        $rootScope.$emit('home_reset_vault_cache');
                        $rootScope.$emit('home_reset_portfolio_cache');
                        $rootScope.$emit('home_reset_channels_cache');
                        $rootScope.$broadcast(
                            'ArtworkService::artwork_toggled_consign',
                            artworkId,
                            actionId,
                            res.new_value
                        );

                        if (res.new_value) {
                            messageParams = {};
                            messageParams.message =
                                'Artwork was successfully consigned.' + redirectStringSuffix;
                            Messages.openMessage($rootScope, messageParams).then(function () {
                                if (gotoNextState) {
                                    gotoNextState();
                                }
                            });
                        } else {
                            $rootScope.$broadcast('artwork_revoked', artworkId, artwork.collectionId);
                            var navigateAfterRemoval = null;
                            var navigateAfterRemovalParams = {};
                            var navigateAfterRemovalReload = false;
                            var navigateAfterRemovalMsg = '';
                            if ($state.includes('home.portfolio.main')) {
                                navigateAfterRemoval = null;
                            } else if ($state.includes('home.events.host')) {
                                navigateAfterRemoval = 'home.events.host';
                                navigateAfterRemovalParams = { eventId: params.eventId };
                                navigateAfterRemovalReload = true;
                            } else {
                                navigateAfterRemoval = 'home.portfolio.main';
                                navigateAfterRemovalParams = { collectionId: 0, collectionLink: 'all' };
                                navigateAfterRemovalMsg = '\nNavigating to your portfolio';
                            }
                            messageParams = {};
                            messageParams.message = 'Artwork was successfully removed.';
                            messageParams.message += navigateAfterRemovalMsg;
                            Messages.openMessage($rootScope, messageParams).then(function () {
                                if (navigateAfterRemoval) {
                                    $state.go(
                                        navigateAfterRemoval,
                                        navigateAfterRemovalParams,
                                        navigateAfterRemovalReload
                                    );
                                }
                            });
                        }
                        deferred.resolve(res);
                    })
                    .error(function (err) {
                        // if (optimisticAssumption) {
                        //
                        //     if(actionId === 'loan') {
                        //         artwork.is_loaned = !artwork.is_loaned;
                        //     } else if(actionId === 'rent') {
                        //         artwork.is_rented = !artwork.is_rented;
                        //     } else {
                        //         artwork.is_accepted = !artwork.is_accepted;
                        //     }
                        //
                        //     self.setActionActiveState(params.action, artwork);
                        // }

                        messageParams = {};
                        messageParams.message = 'Operation failed.';
                        messageParams.message += err.message.text ? '\n' + err.message.text : '';
                        Messages.openMessage($rootScope, messageParams);

                        deferred.reject(err);
                    });
            }

            messageParams = null;
            if (actionId === '7' && artwork.is_consignee) {
                messageParams = {
                    title: 'Remove Consigned Artwork',
                    message: 'Are you sure you want to be removed from consigning this artwork?',
                    okText: 'Remove',
                };
            }

            if (messageParams !== null) {
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okClass = 'danger';
                Messages.openMessage($rootScope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        doToggle();
                    } else {
                        deferred.resolve();
                    }
                });
            } else {
                doToggle();
            }

            return deferred.promise;
        };

        this.setActionActiveState = function (action, artwork) {
            if (artwork && action && action.id) {
                if (action.id === ENTITY_ACTIONS.SHARE) {
                    action.active = artwork.is_accepted;
                } else if (action.id === ENTITY_ACTIONS.LOAN) {
                    action.active = artwork.is_loaned;
                } else if (action.id === ENTITY_ACTIONS.RENT) {
                    action.active = artwork.is_rented;
                } else if (action.id === ENTITY_ACTIONS.TRANSFER_OWNERSHIP) {
                    action.active = artwork.is_ownership_transferred;
                } else if (action.id === ENTITY_ACTIONS.CONSIGN) {
                    action.active = artwork.is_consignee;
                }
            }
        };

        this.setActionNames = function (action, artwork) {
            if (artwork && action && action.id) {
                if (action.id === ENTITY_ACTIONS.SHARE) {
                    action.addName = 'Accept';
                    action.removeName = 'Discard';
                } else if (action.id === ENTITY_ACTIONS.SENDPREVIEW) {
                    //We should not come to this point since we remove this action from action list...
                    action.addName = 'Show Preview';
                    action.removeName = 'Show Preview';
                } else if (action.id === ENTITY_ACTIONS.LOAN) {
                    action.addName = 'Loan';
                    action.removeName = 'End Loaning';
                } else if (action.id === ENTITY_ACTIONS.RENT) {
                    action.addName = 'Rent';
                    action.removeName = 'End Rental';
                } else if (action.id === ENTITY_ACTIONS.DOWNLOAD) {
                    action.addName = 'Download';
                    action.removeName = 'Download';
                } else if (action.id === ENTITY_ACTIONS.TRANSFER_OWNERSHIP) {
                    action.addName = 'Accept';
                    action.removeName = 'Accepted';
                } else if (action.id === ENTITY_ACTIONS.BUY) {
                    action.addName = 'Buy';
                    action.removeName = 'Return';
                } else if (action.id === ENTITY_ACTIONS.CONSIGN) {
                    action.addName = 'Accept Consign';
                    action.removeName = 'Remove Consign';
                }
            }
        };

        this.initActions = function (artwork) {
            var actions = angular.copy(self.actions);
            actions.forEach(function (action) {
                self.setActionActiveState(action, artwork);
            });
            return actions;
        };

        this.toggleArtwork = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                data = {
                    artworkId,
                };

            $http
                .post('/artworks/toggleArtwork', data)
                .success(function (res) {
                    $rootScope.$emit('home_reset_vault_cache');
                    $rootScope.$emit('home_reset_channels_cache');
                    deferred.resolve(res.is_added);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.openInquireArtworkModal = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                menuParams = {
                    artwork: artwork,
                };

            OpenPopupForm.openMenu('inquire', menuParams);
            return deferred.promise;
        };

        this.inquireArtwork = function (artworkId, params, artwork) {
            var deferred = $q.defer(),
                data = {
                    artworkId,
                    message: params.message || '',
                },
                messageParams = {};

            $http
                .post('/artworks/inquireArtwork', data)
                .success(function (res) {
                    messageParams.message = 'Your request was successfully sent to the artist.';
                    Messages.openMessage($rootScope, messageParams);
                    deferred.resolve();
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.isInquire = function (artwork, channel) {
            return (
                (!artwork.sale_type ||
                    artwork.sale_type === 'WIP' ||
                    artwork.sale_type === 'PRE' ||
                    (channel && channel.type && channel.type !== CHANNEL_TYPES.CATALOG) ||
                    (artwork.sale_type === 'SRB' &&
                        parseInt(artwork.total_copies) > 0 &&
                        parseInt(artwork.purchased_copies) >= parseInt(artwork.total_copies)) ||
                    (channel &&
                        !artwork.channel_owner_is_consignee &&
                        artwork.uploader &&
                        artwork.uploader.user_id !== channel.user_id)) &&
                !artwork.is_accepted /*&& artwork.editions.length === 0*/
            );
        };

        this.canNotLoanedInCatalog = function (artwork, channel) {
            return (
                !artwork.sale_type ||
                !channel ||
                !channel.type ||
                channel.type !== CHANNEL_TYPES.CATALOG ||
                artwork.uploader_type !== 'ARTIST' ||
                (!artwork.channel_owner_is_consignee &&
                    artwork.uploader &&
                    artwork.uploader.user_id !== channel.user_id) ||
                artwork.is_accepted
            );
        };

        this.hasAvailableEdition = function (artwork) {
            var intPurchasedCopies = parseInt(artwork.purchased_copies);
            var intTotalCopies = parseInt(artwork.total_copies);
            //return artwork.sale_type && artwork.sale_type === 'SRB' && intPurchasedCopies < intTotalCopies;
            return artwork.sale_type && intPurchasedCopies < intTotalCopies;
        };

        this.userOwnsAnEdition = function (artwork) {
            return artwork.editions.length > 0;
        };

        this.deleteArtwork = function (artworkId, params, artwork) {
            var data = {
                    artworkId,
                },
                deferred = $q.defer();

            if (
                !User.isAdmin() &&
                parseInt(artwork.total_copies) > 0 &&
                parseInt(artwork.purchased_copies) > 0
            ) {
                // Can't delete artwork if 1 copy was sold
                deferred.reject("You can't delete the artwork because at least 1 copy of it was sold.");
            } else {
                $http.post('/artworks/deleteArtwork', data).then(
                    function (response) {
                        $rootScope.$emit('home_reset_vault_cache');
                        $rootScope.$emit('home_reset_portfolio_cache');
                        $rootScope.$emit('home_reset_channels_cache');
                        deferred.resolve(response);
                    },
                    function (err) {
                        $rootScope.$emit('home_reset_vault_cache');
                        $rootScope.$emit('home_reset_portfolio_cache');
                        $rootScope.$emit('home_reset_channels_cache');
                        deferred.reject(err);
                    }
                );
            }
            return deferred.promise;
        };

        function executeToggleWishlist(data, deferred) {
            $http
                .post('/artworks/toggleWishlist', data)
                .success(function (res) {
                    $rootScope.$emit('home_reset_vault_cache');
                    $rootScope.$emit('home_reset_channels_cache');
                    $rootScope.$broadcast(
                        'ArtworkService::artwork_toggled_wishlist',
                        data.artworkId,
                        res.inWishlist
                    );
                    deferred.resolve(res.inWishlist);
                })
                .error(function (err) {
                    deferred.reject(err);
                });
        }

        this.toggleWishlist = async function (artworkId, params, artwork) {
            return FavoritesService.toggleToFavorites('artwork', artworkId);
        };

        this.updateArtworkStatus = function (artworkId, params, artwork) {
            var deferred = $q.defer();
            var data = {
                artworkId,
                status: params.status,
                reason: params.reason,
            };

            $http.post('/artworks/updateStatus', data).then(
                function (res) {
                    if (artwork) artwork.status = params.status;
                    $rootScope.$emit('home_reset_vault_cache');
                    $rootScope.$emit('home_reset_portfolio_cache');
                    $rootScope.$emit('home_reset_channels_cache');
                    deferred.resolve(artworkId);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getArtworkStatus = function (artworkId, params, artwork) {
            var data = {
                artworkId,
            };

            return $http.post('/artworks/getStatus', data);
        };

        this.getArtworkResolutions = function (artworkId) {
            var data = {
                artworkId,
            };

            return $http.post('/artworks/getResolutions', data).success(function (data) {
                return data.resolutions;
            });
        };

        function isImage(path) {
            var extension = path.split('.').pop().toLowerCase();
            return ['gif', 'jpeg', 'jpg', 'bmp', 'tif', 'png'].indexOf(extension) >= 0;
        }

        this.getLabelText = function () {
            console.log('getLabelText', this.artwork.nft_data);
        };

        this.getPreviewPathWithExtension = function (artworkId, params, artwork) {
            var path,
                isZipDefaultThumbnail = false,
                deferred = $q.defer();

            if (params.media_type === null || params.media_type === 'INTERACTIVE_ZIP') {
                if (
                    !params.preview_file ||
                    (params.preview_file.length !== undefined && params.preview_file.length === 0)
                ) {
                    isZipDefaultThumbnail = true;
                    params.preview = 'modules/artwork-info/img/ZipMediaBig.png';
                }
            }

            if (!params.preview) {
                deferred.reject('No preview');
            }

            var prefix = params.preview && params.preview.indexOf('https:') === 0 ? '' : 'https:';
            path = !isZipDefaultThumbnail ? prefix + params.preview : params.preview;

            // TODO RICH: media type should be specific, INTERACTIVE_ZIP
            if (path) {
                setTimeout(function () {
                    if (isImage(path)) {
                        deferred.resolve({ path: path, type: 'image' });
                    } else {
                        var res = { type: 'video', src: [] };
                        if (params.preview_hls !== undefined) {
                            var pathhls = prefix + params.preview_hls;
                            var mimeTypehls = pathhls.toLowerCase().endsWith('m3u8')
                                ? 'application/x-mpegURL'
                                : 'video/mp4';
                            res.src.push({ src: pathhls, type: mimeTypehls });
                        }
                        var mimeType = path.toLowerCase().endsWith('m3u8')
                            ? 'application/x-mpegURL'
                            : 'video/mp4';
                        res.src.push({ src: path, type: mimeType });
                        deferred.resolve(res);
                    }
                }, 10);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        };

        // ARTWORK API
        this.fetchArtworkData = function (artworkId, params, artwork) {
            var data = {
                artworkId,
                isBuy: params?.isBuy,
            };

            if (params !== undefined) {
                if (params.publishUniqueSeq !== undefined) {
                    data.publishUniqueSeq = params.publishUniqueSeq;
                    data.password = params.password;
                }
                data.channelId = params.channelId;
                data.isEdit = params.isEdit;
                data.collectionId = params.collectionId;
                data.eventId = params.eventId;
                data.userId = params.userId;
                data.action = params.action;
            }

            return $http.post('/artworks/getArtworkData', data);
        };

        this.fetchArtworkCertificates = function (artworkId, params, artwork) {
            var data = {
                artworkId,
            };

            return $http.post('/signedArtworks/getArtworkPurchasedEditions', data);
        };

        this.processArtwork = function (artworkId, params, artwork) {
            var data = {
                    artworkId,
                },
                deferred = $q.defer();
            for (attrname in params) {
                if (params.hasOwnProperty(attrname)) {
                    data[attrname] = params[attrname];
                }
            }

            $http.post('/artworks/processArtwork', data).then(
                function (res) {
                    $log.debug('ArtworkService::processArtwork Successfully started processing artwork', res);
                    deferred.resolve(res);
                },
                function (error) {
                    $log.debug('ArtworkService::processThumbnail Failed to start processing artwork', error);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.updateEntityAdditionalFileStatus = function (artworkId, params, artwork) {
            var deferred = $q.defer();
            var data = {
                artworkId,
                additionalFileId: params.additionalFileId,
                status: params.status,
                reason: params.reason,
            };

            $http.post('/additionalFiles/updateEntityAdditionalFileStatus', data).then(
                function (res) {
                    if (artwork) artwork.status = params.status;
                    deferred.resolve(artworkId);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.isEventShortlist = function (artwork, eventId) {
            return (
                eventId &&
                artwork.artwork_submission &&
                artwork.artwork_submission.event_id === eventId &&
                artwork.artwork_submission.approved
            );
        };

        this.isEventFinalist = function (artwork, eventId, eventType) {
            var result = false;

            if (eventId && artwork.artwork_submission && artwork.artwork_submission.event_id === eventId) {
                if (eventType === EVENT_TYPES.OPEN_CALL && artwork.is_accepted) {
                    result = true;
                } else if (eventType === EVENT_TYPES.CONSIGN_SUBMISSION && artwork.is_consignee) {
                    result = true;
                }
            }
            return result;
        };
        // in case there are several elements with the same class (discover.artworks.main.client.view.html)
        // the one that has clientWidth > 0 (visible) will be selected
        this.getCorrectElm = function (elm) {
            var artworks = document.querySelectorAll(elm);
            if (artworks.length) {
                var artworkElmList = Array.prototype.slice.call(artworks);
                var filteredItems = artworkElmList.filter(function (elm) {
                    return elm.clientWidth > 0;
                });
                if (filteredItems.length) {
                    return filteredItems[0];
                }
            }
            return null;
        };
        this.addArtworkMetadataDescription = function (artwork) {
            var metaDescription = '';
            if (!artwork) {
                return;
            }
            if (artwork.category) {
                metaDescription += $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
            }
            if (artwork.total_copies) {
                metaDescription += ', ';
                metaDescription += artwork.editions && artwork.editions.length > 1 ? 'Editions ' : 'Edition ';
                if (artwork.editions && artwork.editions.length > 0) {
                    artwork.editions.forEach(function (edition) {
                        metaDescription += ' ' + edition.edition_number + ',';
                    });
                    metaDescription = metaDescription.slice(0, -1); // Remove last comma
                }
                metaDescription += ' of ' + artwork.total_copies;
            }
            return metaDescription;
        };

        this.getAdditionalFileIcon = function (filename) {
            if (filename) {
                var extension = filename.split('.').pop().toLowerCase();
                var icon = 'file-o';

                switch (extension) {
                    case 'pdf':
                        icon = 'file-pdf-o';
                        break;
                    case 'xls':
                    case 'xla':
                    case 'xlsx':
                    case 'xml':
                    case 'csv':
                        icon = 'file-excel-o';
                        break;
                    case 'zip':
                        icon = 'file-zip-o';
                        break;
                    case 'txt':
                        icon = 'file-text-o';
                        break;
                    case 'mp4':
                    case 'mpeg2':
                    case 'mpeg':
                    case 'mpg':
                    case 'mov':
                    case 'mkv':
                    case 'vob':
                        icon = 'file-video-o';
                        break;
                    case 'gif':
                    case 'jpeg':
                    case 'jpg':
                    case 'bmp':
                    case 'tif':
                    case 'png':
                        icon = 'file-image-o';
                        break;
                    case 'doc':
                    case 'docx':
                        icon = 'file-word-o';
                        break;
                    case 'mp3':
                    case 'wav':
                    case 'aif':
                    case 'aac':
                    case 'ogg':
                        icon = 'file-audio-o';
                        break;
                    default:
                        icon = 'file-o';
                }

                return 'fa-' + icon;
            }
        };
        // processPreview
    },
]);
