'use strict';

angular.module('core').directive('artworkListviewRow', [
    'ArtworkService',
    'User',
    'UploadProgress',
    'S3Uploader',
    '$state',
    '$filter',
    '_',
    'OpenPopupForm',
    function (ArtworkService, User, UploadProgress, S3Uploader, $state, $filter, _, OpenPopupForm) {
        return {
            restrict: 'A',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                artwork: '=',
                totalArtworks: '=',
                buttonsEnabled: '=',
                actionsEnabled: '=',
                editEnabled: '=',
                uploadEnabled: '=',
                adminPanelEnabled: '=',
                labelsEnabled: '=',
                isAdmin: '=',
                isInWishlist: '@',
                fromModule: '=',
                fromView: '=',
                link: '&',
                editLink: '=',
                editText: '=?',
                deleteArtwork: '&',
                cancelUpload: '&',
                toggleAddedArtwork: '&',
                retryUpload: '&',
                retryProcess: '&',
                buy: '&',
                hidePrices: '=',
                hideVisibility: '=',
                inquire: '&?',
                eventHostAcceptTitle: '=',
                eventHostDispalySubmitteddate: '=',
                eventHostAccept: '&?',
                eventHostApproveTitle: '=',
                eventHostApproveIcon: '=',
                eventHostApprove: '&?',
                eventHostDownload: '&?',
                eventHostDownloadTitle: '=',
                toggleWishlist: '&',
                isOther: '=',
                hideCreator: '=?',
                hideThumbnails: '=?',
                hideCategory: '=?',
                hideSize: '=?',
                hideManage: '=?',
                hideDelete: '=?',
                noActions: '=',
                stickyActions: '=?',
                sortable: '=',
                displaySortIndex: '=',
                dataidx: '=',
                multiActions: '=',
                onSelectionChange: '&',
                showEventRating: '=?',
                eventId: '=?',
                isInShortlist: '=?',
                isEventOwner: '=?',
                isAllowDownload: '=?',
                channel: '=?',
                eventJudgeComments: '=?',
            },
            templateUrl: 'modules/core/directives/artwork-listview-row.html',
            compile: function (element, attrs) {
                if (!attrs.noActions) {
                    attrs.noActions = false;
                }
                if (!attrs.sortable) {
                    attrs.sortable = false;
                }
                attrs.isDisabled = !attrs.sortable;
                this.scope.sortable = attrs.sortable;
                return {
                    pre: function (scope, iElem, iAttrs) {
                        /* scope.sortable = iAttrs.sortable ? true:false;*/
                    },
                    post: function (scope, iElem, iAttrs) {},
                };
            },
            controller: 'ArtworkController',
        };
    },
]);
