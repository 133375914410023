'use strict';

angular.module('core').filter('ageRating', [
    function () {
        return function (input) {
            var out = '';

            if (input === 'E') {
                out = '"E" Rated - For everyone, including children';
            } else if (input === 'T') {
                out = '"T" Rated - Age 13+ only';
            } else if (input === 'M') {
                out = '"M" Rated - Age 17+ only';
            } else if (input === 'A') {
                out = '"AO" Rated - Restricted, Age 18+, Adults only';
            }

            return out;
        };
    },
]);
