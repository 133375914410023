'use strict';

angular.module('core').filter('categoryName', [
    'CategoriesService',
    '_',
    function (CategoriesService, _) {
        return function (input) {
            const foundCategory = _.find(CategoriesService.categories, (category) => {
                return category.code === input;
            });
            return foundCategory.name;
        };
    },
]);
