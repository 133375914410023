'use strict';

//Authentication service for user variables
angular.module('users').factory('AuthInterceptor', [
    '$log',
    '$q',
    '$rootScope',
    '$localStorage',
    'NetworkStatus',
    'TokenService',
    'SERVER',
    function ($log, $q, $rootScope, $localStorage, NetworkStatus, TokenService, SERVER) {
        return {
            request: function (config) {
                if (config.url.indexOf('html') === -1) {
                    if (NetworkStatus.isOnline) {
                        config.headers = config.headers || {};

                        config.url =
                            $rootScope.whiteList && $rootScope.whiteList.isWhiteListed(config.url)
                                ? config.url
                                : SERVER.address + config.url;

                        // encode json data + add view mobile
                        if (!config.isForm) {
                            config.data = config.data || {};
                            config.data.view = 'web';
                            config.data = { json: JSON.stringify(config.data) };
                        }

                        var token = TokenService.getToken();
                        var publicToken = TokenService.getOrCreatePublicToken();

                        if (token) {
                            config.headers['X-MyApiTokenHeader'] = token;
                            //config.url = config.url + '?_token=' + JSON.stringify($localStorage.token);
                            //$log.debug('GOT TOKEN', $localStorage.token);
                        }

                        config.headers['X-MyApiTokenHeaderPublic'] = publicToken;
                    } else {
                        $rootScope.$broadcast('user_is_offline');
                        return false;
                    }
                }

                return config || $q.when(config);
            },
            responseError: function (rejection) {
                if ([401].includes(rejection.status)) {
                    // Add more conditions...
                    $log.debug('Source: ', rejection.config.url);
                    $log.debug('Rejection message: ', rejection.data);
                    $rootScope.$broadcast('Auth:Failed');
                }

                return $q.reject(rejection);
            },
        };
    },
]);
