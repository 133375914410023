'use strict';

angular.module('discover').controller('DiscoverMembersMainController', [
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$timeout',
    '$window',
    '_',
    'User',
    'Messages',
    'DiscoverDataService',
    'OpenPopupForm',
    function (
        $scope,
        $state,
        $stateParams,
        $log,
        $timeout,
        $window,
        _,
        User,
        Messages,
        DiscoverDataService,
        OpenPopupForm
    ) {
        $scope.userId = User.getId();
        $scope.offset = 0;
        var initial = true;
        $scope.init = function () {
            $scope.sidebarSelection = $stateParams.sidebarSelection;
            $scope.$emit('DiscoverMembersMainController:members_name_changed', $stateParams.sidebarSelection);

            var selection = _.find($scope.sidebarItems, function (sidebarItem) {
                return sidebarItem.link === $scope.sidebarSelection;
            });

            if (!selection) {
                $state.go('discover.members.main', { sidebarSelection: 'featured' });
            }
            $scope.load();
            console.log($scope);
        };

        function loadedUsers() {
            $scope.limit = 20;
            $scope.noMoreResults = false;
            $scope.loading = false;
            // $scope.initItemsToLoad();
            $scope.lastScrollItemNumber = null;

            $timeout(function () {
                $scope.$emit('DiscoverMembersMainController:members_refreshed');
            }, 100);

            $timeout(function () {
                $scope.loaded = true;
            }, 2000);
        }
        $scope.load = function () {
            // if (User.isAdmin()) {
            var accounts = DiscoverDataService.getCachedUsers();
            if (accounts && accounts.users && accounts.users.length) {
                $scope.accounts = accounts.users;
                $scope.isAdmin = accounts.isAdmin;
                $scope.offset = $scope.accounts.length;
                loadedUsers();
            } else {
                $scope.loading = true;
                DiscoverDataService.getDisplayUsers(
                    $scope.$state.params.sidebarSelection,
                    $scope.limit,
                    $scope.offset
                ).then(function (res) {
                    DiscoverDataService.setCachedUsers(res);
                    $scope.accounts = DiscoverDataService.getCachedUsers().users;
                    $scope.isAdmin = res.isAdmin;
                    initial = false;
                    loadedUsers();
                });
            }
        };
        $scope.loadMore = function (limit) {
            var currentLimit = limit || $scope.limit;
            if (!initial) {
                $scope.offset += currentLimit;
            }
            initial = false;
            $scope.loading = true;
            DiscoverDataService.getDisplayUsers(
                $scope.$state.params.sidebarSelection,
                currentLimit,
                $scope.offset
            ).then(function (res) {
                $scope.accounts = $scope.accounts.concat(res.users);
                DiscoverDataService.setCachedUsers({ users: $scope.accounts, isAdmin: res.isAdmin });
                if (!res.users.length || res.users.length < currentLimit) {
                    $scope.noMoreResults = true;
                }
                $scope.isAdmin = res.isAdmin;
                $scope.loading = false;
                $scope.$emit('DiscoverMembersMainController:members_refreshed');
            });
        };
        function pageScrollEvent() {
            //TODO make a directive
            if (!$scope.loading) {
                $scope.loading = true;
                var windowHeight =
                    'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
                var body = document.body,
                    html = document.documentElement;
                var docHeight = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                );
                var windowBottom = windowHeight + window.pageYOffset;
                if (windowBottom >= docHeight - 800 && !$scope.noMoreResults) {
                    $scope.loadMore();
                } else {
                    $scope.loading = false;
                }
            }
        }
        angular.element($window).on('scroll', pageScrollEvent);

        $scope.$on('$destroy', function () {
            angular.element($window).off('scroll', pageScrollEvent);
        });
    },
]);
