'use strict';

angular.module('core').directive('pendingRequests', [
    '$rootScope',
    function ($rootScope) {
        return function (scope, element, attrs) {
            scope.$on('ajax-start', function () {
                $rootScope.hasPendingRequests = true;
                element.addClass('active');
            });

            scope.$on('ajax-stop', function () {
                $rootScope.hasPendingRequests = false;
                element.removeClass('active');
            });
        };
    },
]);
