angular.module('billing').controller('BillingBuyController', [
    '$rootScope',
    '$scope',
    '$state',
    '$window',
    '$stateParams',
    '$http',
    '$sce',
    '$log',
    '$q',
    'User',
    'TokenService',
    'BillingService',
    'EventsDataService',
    'ArtworkService',
    'ArtworkInfoService',
    'Messages',
    function (
        $rootScope,
        $scope,
        $state,
        $window,
        $stateParams,
        $http,
        $sce,
        $log,
        $q,
        User,
        TokenService,
        BillingService,
        EventsDataService,
        ArtworkService,
        ArtworkInfoService,
        Messages
    ) {
        var userId = User.getId();

        $scope.errorMessages = {};

        BillingService.artworkId = $stateParams.artworkId;
        BillingService.eventId = $stateParams.eventId;

        function loadItemData() {
            $scope.type = $stateParams.type;
            var params = {
                channelId: $stateParams.channelId || BillingService.channelId,
                isArtworkTransfer: BillingService.isArtworkTransfer,
                password: BillingService.password || ArtworkInfoService.artworkSharedPassword,
                publishUniqueSeq: BillingService.publishUniqueSeq || $stateParams.share,
                isBuy: true,
            };

            if ($scope.type === 'artwork' || $scope.type === 'rent') {
                ArtworkService.fetchArtworkData($stateParams.artworkId, params)
                    .then((res) => {
                        const data = res.data.data;
                        $scope.itemData = {
                            ...data,
                            ...JSON.parse(data.preset_actions?.[0]?.parameters || '{}'),
                        };
                        $scope.artworkCreatedFullYear = new Date($scope.itemData.created).getFullYear();

                        if (
                            $scope.type === 'artwork' &&
                            !ArtworkService.hasAvailableEdition($scope.itemData)
                        ) {
                            var messageParams = {};
                            messageParams.message = 'There is no available edition on this artwork';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams).then(() => {
                                $state.go('discover');
                            });
                        }
                    })
                    .catch(() => {
                        this.init();
                    });
            } else if ($scope.type === 'submission_fee') {
                EventsDataService.getEventSubmissionData($stateParams.eventId, userId).then((res) => {
                    $scope.itemData = res.event;
                });
            } else {
                var messageParams = {};
                messageParams.message = 'There is no available type handler';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            }
        }

        $scope.$on('setErrors', (event, errors) => {
            $scope.errorMessages = errors;
        });

        $scope.$on('submit', (event, itemData) => {
            $rootScope.$broadcast('ajax-start');
            itemData.actionId = $stateParams.type === BillingService.otpTypes.artwork ? 'buy' : 'rent';
            const allPresets = $scope?.itemData?.preset_actions || $scope?.itemData?.default_preset_actions;
            if (allPresets) {
                const activePreset = allPresets.find((preset) => preset.name === itemData.actionId);
                itemData.actionParameters = activePreset?.parameters || '';
            }

            BillingService.submitPayForItem(itemData)
                .then((res) => {
                    // Payment completed, on Android, the app injects this function to close the webview
                    if (self.fromIframe) {
                        window.closeWebview?.();
                    } else if (res?.data?.data?.returnState) {
                        $state.go(
                            `billing.${res.data.data.returnState}${
                                $stateParams.type === 'rent' ? '-rent' : ''
                            }`,
                            { artworkId: $stateParams.artworkId }
                        );
                    }
                })
                .catch((error) => {
                    try {
                        const errorParsed = this.parseError(error.data.message.text);
                        if ($scope.errorMessages[errorParsed.field]) {
                            $scope.errorMessages[errorParsed.field].active = true;
                        } else {
                            $scope.errorMessages.general.message = errorParsed.message;
                            $scope.errorMessages.general.active = true;
                        }
                    } catch (e) {
                        $scope.errorMessages.general.active = true;
                    }
                })
                .finally(() => {
                    $rootScope.$broadcast('ajax-stop');
                    $rootScope.$emit('submit-done');
                });
        });

        async function isPasswordRequired(sharedLink, channelId) {
            if (sharedLink && !channelId) {
                var data = {
                    publishUniqueSeq: sharedLink,
                };

                return $http.post('/transfer/isPasswordRequiredForPublish', data);
            }

            return {
                data: {
                    isPasswordRequired: false,
                },
            };
        }

        function openPasswordPopup() {
            var messageParams = {};

            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.blur = true;
            messageParams.cancelFunction = function () {
                $state.go('home-page');
            };
            messageParams.okFunction = loadItemData;
            messageParams.template =
                'modules/artwork-info/views/artwork-info.artwork-password.client.view.html';
            Messages.openMessage($rootScope, messageParams);
        }

        this.init = function () {
            self.currentState =
                $state.current.data && $state.current.data.state ? $state.current.data.state : undefined;
            self.collectionId = $stateParams.collectionId;
            $rootScope.$broadcast('ajax-start');

            // 1. See if a password is needed from server
            isPasswordRequired($stateParams.share, $stateParams.channelId)
                .then((res) => {
                    self.passwordRequired = res.data.isPasswordRequired;

                    if (self.passwordRequired && !BillingService.password) {
                        openPasswordPopup();
                    } else {
                        loadItemData();
                    }
                })
                .finally(() => {
                    $rootScope.$broadcast('ajax-stop');
                });
        };

        this.init();

        this.parseError = function (errorText) {
            var errorParts = errorText.split(' : ', 2);
            var erroredField = errorParts[0];
            var errorMessage = errorParts[1];

            if (erroredField.indexOf(' ') !== -1) {
                erroredField = null;
                errorMessage = errorText;
            }

            return {
                field: erroredField,
                message: errorMessage,
            };
        };
    },
]);
