'use strict';

angular.module('channel-info').controller('ChannelInfoChannelPasswordController', [
    'ChannelService',
    'ChannelInfoService',
    '$rootScope',
    '$scope',
    'Messages',
    function (ChannelService, ChannelInfoService, $rootScope, $scope, Messages) {
        var self = this;
        this.ChannelInfoService = ChannelInfoService;
        this.ChannelService = ChannelService;
        this.Messages = Messages;
        this.params = null; //wil be set by ng-init().
        this.targetField = null;
        this.title = '';
        this.onDone = function () {
            ChannelInfoService.channelSharedPassword = self.targetField;
            Messages.ok();
        };
        this.buttonPressed = false;

        this.subscribeToChannel = function () {
            self.buttonPressed = true;

            if (!this.targetField) {
                //TODO: Display error to the user.
                return;
            }

            var channel = self.params.channelParams.channel;
            var paramsForSubscribe = self.params.channelParams.channelParams;
            paramsForSubscribe.actionPassword = this.targetField;
            self.ChannelService.handleChannelAction('toggleSubscription', channel, paramsForSubscribe)
                .then(
                    function (res) {
                        //TODO: Close dialog.
                        Messages.ok();
                    },
                    function (err) {
                        //TODO: Display message
                    }
                )
                .finally(function () {
                    self.buttonPressed = false;
                });
        };

        this.init = function () {
            $rootScope.blurContent = true;
            switch (self.params.passwordType) {
                case 'channel':
                    self.title = 'Channel Password';
                    self.okText = 'Subscribe';
                    // self.targetField = ChannelInfoService.channelSharedPassword;
                    self.onDone = self.subscribeToChannel;
                    break;
                case 'share':
                    self.title = 'Shared Link Password';
                    self.okText = 'Unlock';
                    break;
            }
            self.targetField = '';
        };

        $scope.$on('$destroy', function () {
            $rootScope.blurContent = false;
        });
    },
]);
