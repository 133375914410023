'use strict';

angular.module('admin-area').controller('AdminAreaArtworkMappingController', [
    '$rootScope',
    '$scope',
    '$filter',
    '$state',
    '$q',
    '$http',
    '$log',
    '$window',
    'Messages',
    'UserPlansDataService',
    'Search',
    'CHANNEL_TYPES',
    'OpenPopupForm',
    function (
        $rootScope,
        $scope,
        $filter,
        $state,
        $q,
        $http,
        $log,
        $window,
        Messages,
        UserPlansDataService,
        Search,
        CHANNEL_TYPES,
        OpenPopupForm
    ) {
        $scope.submitWasClicked = false;
        $scope.accountStatusTouched = false;

        var self = this;
        this.fromState = 'admin-area';
        this.UserPlansDataService = $scope.UserPlansDataService = UserPlansDataService;
        this.Search = Search;
        this.tmpAW = {
            name: '',
            id: undefined,
            channels: undefined,
            channelUsers: undefined,
            clear: function () {
                this.id = undefined;
                this.name = '';
                this.channels = [];
                this.channelUsers = [];
                this.notify();
            },
            notify: function () {
                $scope.$broadcast('mapping:artwork:changed', this);
            },
        };

        this.specificArtworkSelected = function (item, model, label, tmpAWObject) {
            if (item.basicType === 'artwork') {
                tmpAWObject.id = item.id;
                tmpAWObject.channels = undefined;
                tmpAWObject.channelUsers = undefined;
                tmpAWObject.notify();
            } else {
                tmpAWObject.clear();
            }
        };

        this.clear = function () {
            if (self.tmpAW.id !== undefined) {
                self.tmpAW.clear();
            }
        };

        this.getArtworkData = function () {
            var deferred = $q.defer();

            if (self.tmpAW.id !== undefined) {
                var data = {
                    artworkId: self.tmpAW.id,
                };

                $http
                    .post('/channels/getChannelsByAWId', data)
                    .success(function (res) {
                        self.tmpAW.channels = res.data.channels;
                        self.tmpAW.channelUsers = res.data.users;
                        deferred.resolve(self.tmpAW.channels);
                    })
                    .error(function (error) {
                        $log.debug('getChannelsByAWId failed for AW ID:' + self.tmpAW.id);
                        self.tmpAW.channels = [];
                        self.tmpAW.channelUsers = [];
                        deferred.reject('fail to get data');
                    });
            } else {
                deferred.reject('parameter is empty');
            }

            return deferred.promise;
        };

        this.getChannelUser = function (channelUserId) {
            for (var i = 0; i < self.tmpAW.channelUsers.length; i++) {
                if (self.tmpAW.channelUsers[i].id === channelUserId) {
                    return self.tmpAW.channelUsers[i];
                }
            }

            return undefined;
        };

        this.getChannelUserDisplayName = function (channelUserId) {
            var u = self.getChannelUser(channelUserId);
            if (u === undefined) {
                return 'NA';
            }
            var f = u.first_name;
            var l = u.last_name !== undefined && u.last_name !== null ? u.last_name : '';
            return f + ' ' + l;
        };

        this.getChannelUserUrl = function (channelUserId) {
            var u = self.getChannelUser(channelUserId);
            if (u === undefined) {
                return 'NA';
            }
            return u.url;
        };

        this.openChannelPage = function (ch) {
            var user = self.getChannelUser(ch.user_id);
            if (user === undefined) {
                $log.error('Invalid user data for channel ID:' + ch.id);
                return;
            }

            var state = 'discover.channels.channel';
            var stateParams = {
                channelUrlName: ch.url_name,
                channelsName: ch.type === CHANNEL_TYPES.ARTCAST ? 'allChannels' : 'allCatalogs',
            };

            var urlToChannel = $state.href(state, stateParams);
            $window.open(urlToChannel, '_blank');
        };

        this.openSubscribers = function (ch) {
            OpenPopupForm.openMenu('channelManagement', { channel: ch });
        };

        this.getHeaderForCSV = function () {
            return ['Channel Name', 'Type', 'C.Rule', 'created', 'ch. user', 'curated by', 'num subscribers'];
        };

        this.exportCSV = function () {
            var array = [];
            self.tmpAW.channels.forEach(function (ch) {
                array.push({
                    a: ch.name + ' (' + ch.id + ')',
                    b: ch.type,
                    c: ch.commercial_rule,
                    d: ch.created,
                    e: self.getChannelUserDisplayName(ch.user_id) + ' (' + ch.user_id + ')',
                    f: ch.curated_by || 'NA',
                    g: ch.subscriptions,
                });
            });
            return array;
        };

        this.init = function () {
            self.tmpAW.clear();
        };
    },
]);
