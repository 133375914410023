'use strict';

angular.module('discover').service('DiscoverDataService', [
    '$rootScope',
    '$http',
    '$q',
    '$filter',
    'User',
    'ArtworkService',
    'LocalConfig',
    function ($rootScope, $http, $q, $filter, User, ArtworkService, LocalConfig) {
        var self = this;
        self.LocalConfig = LocalConfig;
        //        // DEVICE
        //        this.deviceLogin = function(deviceId, deviceCode) {
        //            var deferred = $q.defer();
        //
        //            if (deviceId && deviceCode) {
        //                var data = {
        //                    deviceId: deviceId,
        //                    deviceCode: deviceCode
        //                };
        //
        //                $rootScope.duringDeviceLogin =
        //
        //                $http.post('/users/loginByDevice', data)
        //                    .success(function (res) {
        //                        User.setUserDetails(res).then(function (res) {
        //                            deferred.resolve(true);
        //                        }, function (error) {
        //                            deferred.resolve(false);
        //                        });
        //                    })
        //                    .error(function (error) {
        //                        console.log('Couldn\'t login device using getChannelsByUser: ' + error.message.text);
        //                        deferred.resolve(false);
        //                    });
        //            } else {
        //                deferred.resolve(false);
        //            }
        //
        //            return deferred.promise;
        //        };

        // TRANSFER
        this.isPublishActive = function (uniqueSeq, artworkId, channelUrlName) {
            var deferred = $q.defer();
            var data = {
                publishUniqueSeq: uniqueSeq,
                artworkId,
                channelUrlName,
            };

            $http.post('/transfer/isPublishActive', data).then(
                function (res) {
                    if (res.data.isPublishActive) {
                        deferred.resolve(res);
                    } else {
                        deferred.reject(res);
                    }
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        // ARTWORK
        this.getArtwork = function (artworkId, artworkName) {
            var deferred = $q.defer();

            ArtworkService.fetchArtworkData(artworkId).then(
                function (res) {
                    var artworkData = res.data.data;
                    var artworkDataName = $filter('inflector')(artworkData.title.toLowerCase(), 'underscore');

                    if (artworkName && artworkDataName !== artworkName) {
                        deferred.reject('artwork name does not match');
                    } else {
                        deferred.resolve(artworkData);
                    }
                },
                function (reason) {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        };

        // CHANNELS
        this.getMarketChannels = function (limit, type, offset, theme, portrait) {
            var data = {
                type: type,
                theme,
                portrait,
            };
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.post(
                '/channels/getMarketChannels' + limit + offset + '?load=' + self.LocalConfig.get('loadLevel', 2),
                data
            );
        };

        this.getFeaturedChannels = function (limit, type, offset, theme, portrait) {
            var data = {
                type: type,
                theme,
                portrait,
            };
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.post(
                '/channels/getFeaturedChannels' + limit + offset + '?load=' + self.LocalConfig.get('loadLevel', 2),
                data
            );
        };

        this.getRecentChannels = function (limit, type, offset, theme, portrait) {
            var data = {
                type: type,
                theme,
                portrait,
            };

            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.post(
                '/channels/getRecentChannels' + limit + offset + '?load=' + self.LocalConfig.get('loadLevel', 2),
                data
            );
        };

        this.getChannelsToDisplay = function (channelsName, type, limit, offset, theme, portrait) {
            var deferred = $q.defer();
            limit = limit || 20;
            offset = offset || 0;
            theme = theme || '';
            portrait = portrait || null;

            switch (channelsName) {
                case 'featured':
                    self.getFeaturedChannels(limit, type, offset, theme, portrait).then(
                        function (res) {
                            var displayChannels = res.data.data;
                            deferred.resolve(displayChannels);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                case 'all':
                    //if (User.isAdmin()) {
                    self.getMarketChannels(limit, type, offset, theme, portrait).then(
                        function (res) {
                            var displayChannels = res.data.data;
                            deferred.resolve(displayChannels);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    //} else {
                    //    deferred.reject('non supported channels name');
                    //}
                    break;
                case 'latest':
                    //if (User.isAdmin()) {
                    self.getRecentChannels(limit, type, offset, theme, portrait).then(
                        function (res) {
                            var displayChannels = res.data.data;
                            deferred.resolve(displayChannels);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    //} else {
                    //    deferred.reject('non supported channels name');
                    //}
                    break;
                default:
                    deferred.reject('non supported channels name');
            }
            return deferred.promise;
        };
        this.getCachedDisplayArtcasts = function () {
            return self.displayArtcasts;
        };

        this.setCachedDisplayArtcasts = function (displayArtcasts) {
            self.displayArtcasts = displayArtcasts;
        };
        this.getCachedDisplayCatalogs = function () {
            return self.displayCatalogs;
        };

        this.setCachedDisplayCatalogs = function (displayCatalogs) {
            self.displayCatalogs = displayCatalogs;
        };
        // ARTOWRKS
        this.getAllArtworks = function (limit, offset, theme, portrait) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.post('/artworks/getMarketArtworks' + limit + offset, { theme: theme, portrait: portrait });
        };

        this.getFeaturedArtworks = function (limit, offset, theme, portrait) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.post('/artworks/getFeaturedArtworks' + limit + offset, { theme: theme, portrait: portrait });
        };

        this.getRecentArtworks = function (limit, offset, theme, portrait) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.post('/artworks/getRecentArtworks' + limit + offset, { theme: theme, portrait: portrait });
        };

        this.getNftArtworks = function (limit, offset, theme, portrait) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.post('/artworks/getNftArtworks' + limit + offset, { theme: theme, portrait: portrait });
        };

        this.getArtworksToDisplay = function (filterName, limit, offset, theme, portrait) {
            var deferred = $q.defer();

            limit = limit || 20;
            offset = offset || 0;
            theme = theme || '';
            portrait = portrait || null;

            switch (filterName) {
                case 'featured':
                    self.getFeaturedArtworks(limit, offset, theme, portrait).then(
                        function (res) {
                            var displayArtworks = res.data.data.artworks;
                            deferred.resolve(displayArtworks);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                case 'all':
                    self.getAllArtworks(limit, offset, theme, portrait).then(
                        function (res) {
                            var displayArtworks = res.data.data.artworks;
                            deferred.resolve(displayArtworks);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );

                    break;
                case 'latest':
                    //if (User.isAdmin()) {
                    self.getRecentArtworks(limit, offset, theme, portrait).then(
                        function (res) {
                            var displayArtworks = res.data.data.artworks;
                            deferred.resolve(displayArtworks);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    //} else {
                    //    deferred.reject('non supported selection');
                    //}
                    break;
                case 'nft':
                    //if (User.isAdmin()) {
                    self.getNftArtworks(limit, offset, theme, portrait).then(
                        function (res) {
                            console.log('NFT Artworks Res', res);
                            var displayArtworks = res.data.data.artworks;
                            deferred.resolve(displayArtworks);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    //} else {
                    //    deferred.reject('non supported selection');
                    //}
                    break;
                default:
                    deferred.reject('non supported selection');
            }
            return deferred.promise;
        };

        this.getCachedDisplayArtworks = function () {
            return self.displayArtworks;
        };
        this.setCachedDisplayArtworks = function (displayArtworks) {
            self.displayArtworks = displayArtworks;
        };
        // ARTIST
        this.getAllArtists = function (limit, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.get('/users/getMarketArtists' + limit + offset);
        };
        this.getFeaturedArtists = function (limit, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.get('/users/getFeaturedArtists' + limit + offset);
        };
        this.getOfficialArtists = function (limit, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.get('/users/getOfficialArtists' + limit + offset);
        };
        this.getArtistsToDisplay = function (filterName, limit, offset) {
            var deferred = $q.defer();
            limit = limit || 20;
            offset = offset || 0;
            switch (filterName) {
                case 'featured':
                    self.getFeaturedArtists(limit, offset).then(
                        function (res) {
                            var displayArtists = res.data.data;
                            deferred.resolve(displayArtists);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                case 'official':
                    self.getOfficialArtists(limit, offset).then(
                        function (res) {
                            var displayArtists = res.data.data;
                            deferred.resolve(displayArtists);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                case 'all':
                    self.getAllArtists(limit, offset).then(
                        function (res) {
                            var displayArtists = res.data.data;
                            deferred.resolve(displayArtists);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                default:
                    deferred.reject('non supported filter');
            }
            return deferred.promise;
        };
        this.getCachedDisplayArtists = function () {
            return self.displayArtists;
        };
        this.setCachedDisplayArtists = function (displayArtists) {
            self.displayArtists = displayArtists;
        };
        // USERS
        this.getUsersCount = function () {
            return $http.get('/users/getDiscoverCount').then(function (response) {
                return response.data.data;
            });
        };
        this.getAllUsers = function (limit, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.get('/users/getAllUsers' + limit + offset);
        };
        this.getDisplayUsers = function (type, limit, offset) {
            var deferred = $q.defer();
            limit = limit || 20;
            offset = offset || 0;
            switch (type) {
                case 'featured':
                    self.getFeaturedUsers(limit, offset).then(
                        function (res) {
                            deferred.resolve(res.data.data);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                case 'official':
                    self.getOfficialUsers(limit, offset).then(
                        function (res) {
                            deferred.resolve(res.data.data);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                case 'artists':
                    self.getAllArtists(limit, offset).then(
                        function (res) {
                            deferred.resolve({ users: res.data.data.artists });
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                case 'all':
                    self.getAllUsers(limit, offset).then(
                        function (res) {
                            deferred.resolve(res.data.data);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                    break;
                default:
                    if (type) {
                        var orgType = null;
                        if (type !== 'collectors' && type !== 'curators' && type !== 'art_fans') {
                            orgType = User.getOrganizationByTitle(type);
                        }
                        self.getUsersByType(User.getUserTitleNum(type), limit, offset, orgType).then(
                            function (res) {
                                deferred.resolve(res.data.data);
                            },
                            function (error) {
                                deferred.reject(error);
                            }
                        );
                    }
            }
            return deferred.promise;
        };
        this.getOfficialUsers = function (limit, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.get('/users/getOfficialUsers' + limit + offset);
        };
        this.getFeaturedUsers = function (limit, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            return $http.get('/users/getFeaturedUsers' + limit + offset);
        };
        this.getUsersByType = function (type, limit, offset, orgType) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            orgType = orgType ? '?orgType=' + orgType : '';
            return $http.get('/users/getUsersByType/' + type + limit + offset + orgType);
        };
        this.getCachedUsers = function () {
            return self.displayAccounts;
        };
        this.setCachedUsers = function (accounts) {
            self.displayAccounts = accounts;
        };
        // FOR YOU
        this.getDynamicLayoutJson = function () {
            return $http.get('/channels/getDataForMobile/');
        };

        this.init = function () {
            self.displayArtcasts = null;
            self.displayArtworks = null;
            self.displayCatalogs = null;
            self.displayAccounts = null;
            self.displayArtists = null;
        };

        $rootScope.$on('ClearData', function () {
            self.init();
        });

        $rootScope.$on('InitServices', function () {
            self.init();
        });

        $rootScope.$on('artwork_deleted', function () {
            self.setCachedDisplayArtworks(null);
        });
    },
]);
