'use strict';

angular.module('core').directive('ifEmptyChildren', [
    '$rootScope',
    '$timeout',
    '_',
    function ($rootScope, $timeout, _) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attr) {
                var functionToRun = $attr.ifEmptyChildrenFunction; // run a function on match
                var classToAdd = $attr.ifEmptyChildrenClass || 'hidden'; // set a specific class - defaults to active
                var subClassSelectorChild = $attr.ifEmptyChildrenSubSelector; // set a specific class - defaults to active
                var childsAreEmpty = true;
                $element.addClass('invisible');

                function findClass(classValue) {
                    return classValue === subClassSelectorChild;
                }

                $timeout(function () {
                    if ($element[0].children.length > 0) {
                        var i;
                        for (i = 0; i < $element[0].children.length; i++) {
                            if (subClassSelectorChild) {
                                var j;
                                if ($element[0].children[i].classList) {
                                    var theClass = _.find($element[0].children[i].classList, findClass);

                                    if (theClass) {
                                        for (j = 0; j < $element[0].children[i].children.length; j++) {
                                            if (
                                                ($element[0].children[i].children[j].children &&
                                                    $element[0].children[i].children[j].children.length >
                                                        0) ||
                                                $element[0].children[i].children[j].innerText
                                            ) {
                                                childsAreEmpty = false;
                                                break;
                                            }
                                        }
                                    }
                                }
                            } else {
                                if (
                                    ($element[0].children[i].children &&
                                        $element[0].children[i].children.length > 0) ||
                                    $element[0].children[i].innerText
                                ) {
                                    childsAreEmpty = false;
                                    break;
                                }
                            }
                        }
                    }

                    if (childsAreEmpty) {
                        $element.addClass(classToAdd);
                        $scope.$eval(functionToRun);
                    }

                    $element.removeClass('invisible');
                });
            },
        };
    },
]);
