'use strict';

angular.module('home').directive('eventSubmittedBox', [
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                submission: '=',
                event: '=',
            },
            templateUrl: 'modules/home/directives/home.event-submitted-box.client.view.html',
            controller: 'EventSubmittedBoxController',
            controllerAs: 'vm',
            bindToController: true, //required in 1.3+ with controllerAs
        };
    },
]);
