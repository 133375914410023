'use strict';

// Setting up route
angular.module('search').config([
    '$stateProvider',
    function ($stateProvider) {
        // Users state routing
        $stateProvider
            .state('search', {
                url: '/search',
                templateUrl: 'modules/search/views/search.client.view.html',
                controller: 'SearchController',
                abstract: true,
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('search.main', {
                url: '/:sidebarSelection?query&type',
                templateUrl: 'modules/search/views/search.main.client.view.html',
                controller: 'SearchMainController',
            });
    },
]);
