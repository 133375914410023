'use strict';

angular.module('components').controller('ComponentsController', [
    '$scope',
    '$state',
    'User',
    function ($scope, $state, User) {
        //        $scope.$log.debug('Checking security from: home.js -> Security.status wrapper');
        //        Security.status(function () {
        $scope.init = function () {
            if (!User.isAdmin()) {
                $state.go('home');
            }
        };

        $scope.$state = $state;

        $scope.pages = [
            {
                link: 'channels',
                name: 'CHANNELS',
            },
            {
                link: 'vault',
                name: 'VAULT',
            },
            {
                link: 'portfolio',
                name: 'PORTFOLIO',
            },
            {
                link: 'devices',
                name: 'DEVICES',
            },
        ];
        //        });
    },
]);
