'use strict';

angular.module('admin-area').controller('AdminAreaStatusesTransfersController', [
    '$q',
    '$rootScope',
    '$http',
    '$log',
    '$filter',
    '_',
    'User',
    'OpenPopupForm',
    'Messages',
    'ArtworkService',
    'AdminAreaDataService',
    function (
        $q,
        $rootScope,
        $http,
        $log,
        $filter,
        _,
        User,
        OpenPopupForm,
        Messages,
        ArtworkService,
        AdminAreaDataService
    ) {
        var self = this;
        this.AdminAreaDataService = AdminAreaDataService;
        this.OpenPopupForm = OpenPopupForm;
        this.toggles = self.toggles || {};
        this.itemsPerPage = 10;
        this.currentPage = 1;
        this.maxSize = 5;
        this.periods = [
            { days: '1', name: 'Past Day' },
            { days: '7', name: 'Past Week' },
            { days: '14', name: 'Past 2 Weeks' },
            { days: '30', name: 'Past Month' },
            { days: '60', name: 'Past 2 Months' },
            { days: '91', name: 'Past 3 Months' },
            { days: '182', name: 'Past 6 Months' },
            { days: '365', name: 'Past 1 Year' },
        ];

        this.setPage = function (pageNo) {
            self.currentPage = pageNo;
        };

        this.tableFilter = {};

        // Grid
        this.orderByField = 'modified';
        this.reverseSort = true;

        this.getPublishes = function () {
            var data = {
                period_days: self.selectedPeriod.days,
            };
            self.toggles.spinRefresh = true;
            return $http
                .post('/transfer/getAllPublishes', data)
                .then(function (res) {
                    self.publishes = res.data.data.publishes;
                    self.publishesStatusTime = new Date();
                })
                .finally(function () {
                    self.toggles.spinRefresh = false;
                });
        };

        this.getTransfersStatusReport = function () {
            self.toggles.spinReportRefresh = true;
            return self.AdminAreaDataService.getTransfersReport()
                .then(function (data) {
                    self.transfersStatusReportTime = new Date();
                    self.transfersReport = data;
                    self.transfersReport.totalReceived = 0;
                    _.each(self.transfersReport.artworks, function (artwork) {
                        self.transfersReport.totalReceived += parseInt(artwork.amount);
                    });
                })
                .finally(function () {
                    self.toggles.spinReportRefresh = false;
                });
        };

        this.init = function () {
            self.selectedPeriod = self.periods[3]; // 1 month
            //self.getArtworksStatus();
        };
    },
]);
