'use strict';

angular.module('home').controller('AdminAreaStatusesTotalsController', [
    'AdminAreaDataService',
    '$rootScope',
    '$log',
    '$q',
    'Messages',
    '_',
    '$filter',
    function (AdminAreaDataService, $rootScope, $log, $q, Messages, _, $filter) {
        var self = this;

        self.searchDate = undefined;
        self.data = {
            date: undefined,
            usersTotals: undefined,
            artworksReport: undefined,
            displaysCounts: undefined,
            transfersReport: undefined,
            //transfersCounts: undefined,
            eventsReport: undefined,

            getDateFormatted: function () {
                return $filter('date')(this.date, 'yyyy_MM_dd');
            },
            clear: function () {
                this.date = undefined;
                this.usersTotals = undefined;
                this.artworksReport = undefined;
                this.displaysCounts = undefined;
                this.eventsReport = undefined;
                this.transfersReport = undefined;
            },
        };

        this.titles = [
            { name: 'Artists', id: '1' },
            { name: 'Collectors', id: '2' },
            { name: 'Curators', id: '3' },
            { name: 'Art Fans', id: '4' },
            { name: 'Organizations', id: '5' },
        ];
        this.organizations = [
            { name: 'Academies', id: 'AC' },
            { name: 'Archives', id: 'AR' },
            { name: 'Art event', id: 'AE' },
            { name: 'Galleries', id: 'GA' },
            { name: 'Institutions', id: 'IN' },
            { name: 'Museums', id: 'MU' },
            { name: 'Public Installation', id: 'HO' },
            { name: 'Others', id: 'OT' },
        ];
        this.AdminAreaDataService = AdminAreaDataService;
        this.toggles = self.toggles || { spinRefresh: false };

        function setTimeOfCurrentStatus() {
            self.data.date = new Date();
        }

        this.init = function () {
            self.searchDate = undefined;
            self.refreshAll();
        };

        this.openArrayModal = function (title, arrayToDisplay) {
            var messageParams = {};
            messageParams.title = title;
            var string = '';
            Object.keys(arrayToDisplay).forEach(function (key) {
                if (string !== '') {
                    string = string + ',\n';
                }
                string = string + key + ' (' + arrayToDisplay[key] + ')';
                // do something with obj[key]
            });
            // messageParams.message = (arrayToDisplay.toString()).split(',').join(', \n');
            messageParams.message = string;
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($rootScope, messageParams);
        };

        this.refreshAll = function () {
            self.toggles.spinRefresh = true;

            var promises = [];
            promises[0] = AdminAreaDataService.getGlobalDisplaysCounts().then(
                function (res) {
                    self.data.displaysCounts = res;
                },
                function (err) {
                    self.data.displaysCounts = -1;
                }
            );

            promises[1] = AdminAreaDataService.getArtworksStatusReport().then(
                function (res) {
                    self.data.artworksReport = res;
                },
                function (err) {
                    self.data.artworksReport = null;
                }
            );

            promises[2] = AdminAreaDataService.getGlobalUsersCounts().then(
                function (res) {
                    self.data.usersTotals = res;

                    var ordered = {};
                    Object.keys(self.data.usersTotals.countries)
                        .sort()
                        .forEach(function (key) {
                            ordered[key] = self.data.usersTotals.countries[key];
                        });
                    self.data.usersTotals.countries = ordered;

                    ordered = {};
                    Object.keys(self.data.usersTotals.cities)
                        .sort()
                        .forEach(function (key) {
                            ordered[key] = self.data.usersTotals.cities[key];
                        });
                    self.data.usersTotals.cities = ordered;

                    self.data.usersTotals.countriesCount = Object.keys(
                        self.data.usersTotals.countries
                    ).length;

                    // self.data.usersTotals.countries = _.sortBy(self.data.usersTotals.countries, function(key, value) {
                    //     return self.data.usersTotals.countries[key];
                    // });
                    //
                    // self.data.usersTotals.cities = _.sortBy(self.data.usersTotals.cities, function(key, value) {
                    //     return self.data.usersTotals.cities[key];
                    // });
                },
                function (err) {
                    self.data.usersTotals = -1;
                }
            );

            promises[3] = AdminAreaDataService.getTransfersReport().then(
                function (res) {
                    self.data.transfersReport = res;
                    self.data.transfersReport.totalReceived = 0;
                    _.each(self.data.transfersReport.artworks, function (artwork) {
                        self.data.transfersReport.totalReceived += parseInt(artwork.amount);
                    });
                },
                function (err) {
                    self.data.transfersReport = -1;
                    //self.transfersCounts = -1;
                }
            );

            promises[4] = AdminAreaDataService.getEventsReport().then(
                function (res) {
                    self.data.eventsReport = res;
                    var totalSubmissions180DaysByEvent = _.map(
                        _.countBy(self.data.eventsReport.totalSubmissions180Days, function (submission) {
                            return submission.event.name;
                        }),
                        function (count, key) {
                            return { name: key, filled: count };
                        }
                    );

                    var totalSubmitted180DaysByEvent = _.map(
                        _.countBy(self.data.eventsReport.totalSubmitted180Days, function (submitted) {
                            return submitted.event.name;
                        }),
                        function (count, key) {
                            return { name: key, submitted: count };
                        }
                    );

                    self.data.eventsReport.totalSubmitted180DaysByEventFull = _.map(
                        totalSubmissions180DaysByEvent,
                        function (submission) {
                            var foundSubmitted = _.find(totalSubmitted180DaysByEvent, function (submitted) {
                                return submission.name === submitted.name;
                            });
                            return _.extend(submission, foundSubmitted);
                        }
                    );
                },
                function (err) {
                    self.data.eventsReport = -1;
                }
            );

            $q.all(promises).finally(function () {
                setTimeOfCurrentStatus();
                self.toggles.spinRefresh = false;
            });
        };

        this.removeTimeZoneOffset = function (date) {
            var timeZoneOffset = date.getTimezoneOffset();
            date.setMinutes(date.getMinutes() - timeZoneOffset);
            date.setDate(date.getDate() + 1);
            date.setMilliseconds(date.getMilliseconds() - 1);
        };

        this.searchHistory = function () {
            if (self.searchDate === undefined) {
                return;
            }

            self.toggles.spinRefresh = true;

            self.data.clear();

            self.AdminAreaDataService.getHistoryStatus(self.searchDate)
                .then(
                    function (res) {
                        if (res === undefined || res.TotalsRecord === undefined) {
                            return;
                        }

                        var rprt = res.TotalsRecord;
                        if (!rprt.data || rprt.data.length === 0) {
                            return;
                        }
                        rprt.data = JSON.parse(rprt.data);

                        self.data.displaysCounts = rprt.data.globalDevicesCounts;
                        self.data.artworksReport = rprt.data.artworksStatusReport;

                        self.data.usersTotals = rprt.data.globalUsersCounts;
                        var ordered = {};
                        Object.keys(self.data.usersTotals.countries)
                            .sort()
                            .forEach(function (key) {
                                ordered[key] = self.data.usersTotals.countries[key];
                            });
                        self.data.usersTotals.countries = ordered;
                        ordered = {};
                        Object.keys(self.data.usersTotals.cities)
                            .sort()
                            .forEach(function (key) {
                                ordered[key] = self.data.usersTotals.cities[key];
                            });
                        self.data.usersTotals.cities = ordered;
                        self.data.usersTotals.countriesCount = Object.keys(
                            self.data.usersTotals.countries
                        ).length;

                        var transReport = rprt.data.transferStatusReport;
                        transReport.totalTransfers = 0;
                        _.each(transReport.transfers, function (transfer) {
                            transReport.totalTransfers += parseInt(transfer.amount);
                        });
                        transReport.totalReceived = 0;
                        _.each(transReport.artworks, function (artwork) {
                            transReport.totalReceived += parseInt(artwork.amount);
                        });
                        self.data.transfersReport = transReport;

                        self.data.eventsReport = rprt.data.eventsStatusReport;
                        var totalSubmissions180DaysByEvent = _.map(
                            _.countBy(self.data.eventsReport.totalSubmissions180Days, function (submission) {
                                return submission.event.name;
                            }),
                            function (count, key) {
                                return { name: key, filled: count };
                            }
                        );
                        var totalSubmitted180DaysByEvent = _.map(
                            _.countBy(self.data.eventsReport.totalSubmitted180Days, function (submitted) {
                                return submitted.event.name;
                            }),
                            function (count, key) {
                                return { name: key, submitted: count };
                            }
                        );
                        self.data.eventsReport.totalSubmitted180DaysByEventFull = _.map(
                            totalSubmissions180DaysByEvent,
                            function (submission) {
                                var foundSubmitted = _.find(
                                    totalSubmitted180DaysByEvent,
                                    function (submitted) {
                                        return submission.name === submitted.name;
                                    }
                                );
                                return _.extend(submission, foundSubmitted);
                            }
                        );

                        self.data.date = rprt.created;
                    },
                    function (err) {
                        self.data.displaysCounts = -1;
                        self.data.artworksReport = null;
                        self.data.usersTotals = -1;
                        //self.data.transfersCounts = -1;
                        self.data.transfersReport = -1;
                        self.data.eventsReport = -1;
                        self.data.date = self.searchDate;
                    }
                )
                .finally(function () {
                    self.toggles.spinRefresh = false;
                });
        };

        function traverse(o, func, prefix, excludeList) {
            excludeList = excludeList || [];
            prefix = prefix || '';
            for (var i in o) {
                if (typeof o[i] === 'function') {
                    continue;
                }
                var sep = prefix !== '' ? '_' : '';
                var label = prefix + sep + i;
                if (excludeList.indexOf(label) >= 0) {
                    continue;
                }
                if (o[i] !== null && typeof o[i] === 'object') {
                    //going one step down in the object tree!!
                    traverse(o[i], func, label, excludeList);
                } else {
                    func.apply(self, [label, o[i]]);
                }
            }
        }

        var csvData = {
            keys: [],
            values: [],
            clear: function () {
                this.keys = [];
                this.values = [];
            },
        };

        function recreateCSV(key, value) {
            csvData.keys.push(key);
            csvData.values.push(value);
        }

        function GetExcelColumnName(columnNumber) {
            var dividend = columnNumber;
            var columnName = '';
            var modulo;

            while (dividend > 0) {
                modulo = (dividend - 1) % 26;
                columnName = String.fromCharCode(65 + modulo) + columnName;
                dividend = Math.floor((dividend - modulo) / 26);
            }

            return columnName;
        }

        this.getHeaderForCSV = function () {
            csvData.clear();
            var excludeList = [
                'usersTotals_countries',
                'usersTotals_cities',
                'transfersReport_artworks',
                'transfersReport_transfers',
                'eventsReport_totalSubmitted180Days',
                'eventsReport_totalSubmissions180Days',
                'eventsReport_totalSubmitted180DaysByEventFull',
            ];
            traverse(self.data, recreateCSV, '', excludeList);
            return ['parameter', self.data.date];
        };

        var prettyCSVLabels = {
            src: [
                'usersTotals by titles 1',
                'usersTotals by titles 2',
                'usersTotals by titles 3',
                'usersTotals by titles 4',
                'usersTotals by titles 5',
                'usersTotals organizations AC',
                'usersTotals organizations AE',
                'usersTotals organizations AR',
                'usersTotals organizations GA',
                'usersTotals organizations HO',
                'usersTotals organizations IN',
                'usersTotals organizations MU',
                'usersTotals organizations OT',
                'artworksReport totalBytes',
            ],
            trg: [
                'usersTotals Artists',
                'usersTotals Collectors',
                'usersTotals Curators',
                'usersTotals Art Fans',
                'usersTotals Organizations',
                'usersTotals organizations Academies',
                'usersTotals organizations Art event',
                'usersTotals organizations Archives',
                'usersTotals organizations Galleries',
                'usersTotals organizations Public Installation',
                'usersTotals organizations Institutions',
                'usersTotals organizations Museums',
                'usersTotals organizations Others',
                'Originals Upload Size (bytes)',
            ],
        };

        this.exportCSV = function () {
            var arr = [];
            for (var i = 0; i < csvData.values.length; i++) {
                var label = csvData.keys[i].replace(/_/g, ' ');
                var lidx = prettyCSVLabels.src.indexOf(label);
                label = lidx >= 0 ? prettyCSVLabels.trg[lidx] : label;
                var ds = {
                    a: label,
                    b: csvData.values[i],
                };
                arr.push(ds);
            }

            return arr;
        };
    },
]);
