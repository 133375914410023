'use strict';

angular.module('core').service('gdprHandlerService', [
    '$cookies',
    function ($cookies) {
        const wpCookie = $cookies.get('cookie_notice_accepted');
        if (wpCookie) {
            const currentDate = new Date();
            const expiredDate = currentDate.setFullYear(currentDate.getFullYear() + 1);
            $cookies.put('cookieconsent_status', 'dismiss', { expires: expiredDate.toString() });
        }
    },
]);
