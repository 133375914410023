'use strict';
/*global Image */

angular.module('artwork-info').service('ArtworkInfoService', [
    '$log',
    '$http',
    '$q',
    '$filter',
    '$window',
    '$timeout',
    '$rootScope',
    'User',
    'ArtworkService',
    'HomeDataService',
    function (
        $log,
        $http,
        $q,
        $filter,
        $window,
        $timeout,
        $rootScope,
        User,
        ArtworkService,
        HomeDataService
    ) {
        var self = this;

        this.artworkSharedPassword = '';
        this.currentArtworkId = undefined;
        this.thumbnailBase64 = undefined;
        this.isLoadingCertificateAsset = false;

        // Taken from here: https://davidwalsh.name/convert-image-data-uri-javascript
        function getDataUri(url) {
            var image = new Image();
            var deferred = $q.defer();

            image.onload = function () {
                $log.debug('ArtworkInfoService::getDataUri Success loading image ' + url);
                var canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth; // or 'width' if you want a special/scaled size
                canvas.height = this.naturalHeight; // or 'height' if you want a special/scaled size

                canvas.getContext('2d').drawImage(this, 0, 0);

                // Get raw image data
                //deferred.resolve(canvas.toDataURL('image/png').replace(/^data:image\/(png|jpg);base64,/, ''));
                // ... or get as Data URI
                deferred.resolve(canvas.toDataURL('image/png'));
            };

            image.onerror = function () {
                $log.debug('ArtworkInfoService::getDataUri Failed loading image ' + url);
                deferred.reject('Something wrong happened');
            };

            image.setAttribute('crossOrigin', 'anonymous');
            image.src = url;
            return deferred.promise;
        }

        getDataUri('modules/artwork-info/img/certificate.png').then(function (dataUri) {
            self.certificateBase64 = dataUri;
        });

        getDataUri('modules/artwork-info/img/certificate_preview.png').then(function (dataUri) {
            self.thumbnailBase64 = dataUri;
        });

        this.loadCertificateAssets = function (artwork) {
            self.isLoadingCertificateAsset = true;
            self.failedLoadingCertificate = false;

            //self.setThumbnailForCertificate(artwork)
            //    .then(function(res) {
            if (!self.certificateBase64) {
                getDataUri('modules/artwork-info/img/certificate.png').then(function (dataUri) {
                    self.certificateBase64 = dataUri;
                    self.isLoadingCertificateAsset = false;
                });
            } else {
                self.isLoadingCertificateAsset = false;
            }
            //}, function (error) {
            //    self.failedLoadingCertificate = true;
            //});
        };

        this.getArtwork = function (
            artworkId,
            artworkName,
            publishUniqueSeq,
            artworkSharedPassword,
            channelId,
            collectionId,
            eventId
        ) {
            var deferred = $q.defer();

            var params = {
                publishUniqueSeq: publishUniqueSeq,
                password: artworkSharedPassword,
                channelId: channelId,
                collectionId: collectionId,
                eventId,
            };

            // if (!channelId && !collectionId) {
            //     params.publishUniqueSeq = publishUniqueSeq;
            //     params.password = artworkSharedPassword;
            // }

            ArtworkService.fetchArtworkData(artworkId, params).then(
                function (res) {
                    var artworkData = res.data.data;
                    var artworkDataName = $filter('inflector')(artworkData.title.toLowerCase(), 'underscore');

                    if (artworkName && artworkDataName !== artworkName) {
                        deferred.reject('artwork name does not match');
                    } else {
                        deferred.resolve(artworkData);
                    }
                },
                function (reason) {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        };

        this.createCertificatePDF = function (certificate, artwork) {
            var blockchain_id, blockchain_title;
            if (artwork.editions && artwork.editions[0] && artwork.editions[0].blockchain_id) {
                blockchain_title = 'Edition Blockchain ID:';
                blockchain_id = artwork.editions[0].blockchain_id;
            } else if (artwork.blockchain_id) {
                blockchain_title = 'Artwork Blockchain ID:';
                blockchain_id = artwork.blockchain_id;
            }
            var margins = {
                    // left, top, right, bottom
                    page: [0, 30, 0, 0],
                    header: [0, 140, 0, 30],
                    preview: [0, 140, 0, 50],
                    value: [0, 0, 0, 20],
                    label: [0, 0, 0, 3],
                    pair: [100, 0, 50, 0],
                    footer: [0, 20, 0, 0],
                },
                issueDate = certificate.modified
                    ? $filter('date')(new Date(certificate.modified), 'longDate')
                    : '',
                //creationDate = $filter('date')(new Date($scope.artwork.creation_date), 'yyyy'),
                //    creationDate = artwork.creation_date.split('/')[1],
                ownerDisplayName = User.getDisplayName(),
                pdfObject,
                docDefinition = {
                    pageSize: 'A4',
                    pageOrientation: 'landscape',
                    pageMargins: margins.page,
                    background: [
                        {
                            image: self.certificateBase64,
                            fit: [841, 841],
                        },
                    ],
                    content: [
                        {
                            columns: [
                                {
                                    image: self.thumbnailBase64,
                                    //width: 250,
                                    style: ['center'],
                                    margin: margins.preview,
                                },
                                {
                                    width: '*',
                                    stack: [
                                        {
                                            stack: [
                                                {
                                                    text: artwork.title,
                                                    style: ['headerLabel'],
                                                    margin: margins.label,
                                                },
                                                {
                                                    text: 'by ' + artwork.artist.display_name,
                                                    style: ['value'],
                                                    margin: margins.value,
                                                },
                                            ],
                                            margin: margins.header,
                                        },
                                        {
                                            columns: [
                                                {
                                                    stack: [
                                                        {
                                                            text: 'Issued to:',
                                                            style: ['label'],
                                                            margin: margins.label,
                                                        },
                                                        {
                                                            text: ownerDisplayName,
                                                            style: ['value'],
                                                            margin: margins.value,
                                                        },
                                                    ],
                                                },
                                                {
                                                    stack: [
                                                        {
                                                            text: 'Date of Issue:',
                                                            style: ['label'],
                                                            margin: margins.label,
                                                        },
                                                        {
                                                            text: issueDate,
                                                            style: ['value'],
                                                            margin: margins.value,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            stack: [
                                {
                                    text: 'Issued by:',
                                    style: ['label'],
                                    margin: margins.label,
                                },
                                {
                                    text: artwork.uploader.display_name,
                                    style: ['value'],
                                    margin: margins.value,
                                },
                            ],
                            margin: margins.pair,
                        },
                        {
                            stack: [
                                {
                                    text: 'Edition #:',
                                    style: ['label'],
                                    margin: margins.label,
                                },
                                {
                                    text:
                                        certificate.edition_number +
                                        ' / ' +
                                        artwork.total_copies +
                                        (artwork.extra_metadata.artists_proof
                                            ? ' + ' + artwork.extra_metadata.artists_proof + ' AP'
                                            : ''),
                                    style: ['value'],
                                    margin: margins.value,
                                },
                            ],
                            margin: margins.pair,
                        },
                        {
                            columns: [
                                {
                                    stack: [
                                        {
                                            text: 'Certificate Number:',
                                            style: ['label'],
                                            margin: margins.label,
                                        },
                                        {
                                            text: certificate.serial_number,
                                            style: ['value'],
                                            margin: margins.value,
                                        },
                                    ],
                                    margin: margins.pair,
                                    width: 'auto',
                                },
                            ],
                        },
                    ],
                    styles: {
                        center: {
                            alignment: 'center',
                        },
                        left: {
                            alignment: 'left',
                        },
                        label: {
                            fontSize: 12,
                            alignment: 'left',
                            color: '#202C2F',
                            bold: false,
                        },
                        headerLabel: {
                            fontSize: 24,
                            alignment: 'left',
                            color: '#202C2F',
                            bold: true,
                        },
                        value: {
                            fontSize: 20,
                            alignment: 'left',
                            color: '#202C2F',
                            bold: false,
                        },
                    },
                };

            // Set width / height of preview thumbnail
            docDefinition.content[0].columns[0].fit = ['*', 150];
            //if (artwork.file_metadata.is_landscape) {
            //    docDefinition.content[0].columns[0].fit = [350, '*'];
            //} else {
            //    docDefinition.content[0].columns[0].fit = ['*', 350];
            //}
            if (blockchain_id) {
                docDefinition.content[3].columns[1] = {
                    stack: [
                        {
                            text: blockchain_title,
                            style: ['label'],
                            margin: margins.label,
                        },
                        {
                            text: blockchain_id,
                            style: ['value'],
                            margin: margins.value,
                        },
                    ],
                    width: 'auto',
                };
            }

            pdfObject = $window.pdfMake.createPdf(docDefinition);
            return pdfObject;
        };
    },
]);
