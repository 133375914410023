'use strict';

angular.module('devices').controller('DevicesSelectedSettingsController', [
    '$scope',
    '$timeout',
    '$log',
    'Messages',
    'DevicesDataService',
    'User',
    'FormValidationService',
    '_',
    'ClipboardService',
    'PLAYER_TYPES',
    '$http',
    function (
        $scope,
        $timeout,
        $log,
        Messages,
        DevicesDataService,
        User,
        FormValidationService,
        _,
        ClipboardService,
        PLAYER_TYPES,
        $http
    ) {
        $scope.isAdmin = User.isAdmin();
        $scope.isArtistOrOrganization = User.isArtist() || User.isOrganization();
        $scope.isPayingUser = !User.isFreeUser();

        $scope.PLAYER_TYPES = PLAYER_TYPES;

        $scope.timezones = [
            { key: '-10:00', name: '-10:00 Cities: Honolulu' },
            { key: '-09:00', name: '-09:00 Cities: Anchorage, Juneau' },
            {
                key: '-08:00',
                name: '-08:00 Cities: Ensenada, LasVegas, LosAngeles, PortlandOR, Seattle, SanDiego, SanFransisco, VancouverBC',
            },
            { key: '-07:00', name: '-07:00 Cities: Boise, Denver, Edmonton' },
            {
                key: '-06:00',
                name: '-06:00 Cities: Belize, Chicago, CostaRica, Dallas, Drury, Houstan, Lawrence, KansasCity, Memphis, MadisonWI, MexicoCity, SanJose, St.Louis',
            },
            {
                key: '-05:00',
                name: '-05:00 Cities: Atlanta, Bahamas, Bogata, Boston, Cayman, Detriot, Greensboro, Havana, Indianapolis, Montreal, NewYork, Panama, Peru, Philadelphia, Raleigh',
            },
            { key: '-04:00', name: '-04:00 Cities: Antigua' },
            { key: '-03:00', name: '-03:00 Cities: BuenosAires, *Chillan, *Santiago' },
            { key: '-02:00', name: '-02:00 Cities: *SaoPaulo' },
            { key: '-01:00', name: '-01:00 Cities: Azores, Cape Verde Is.' },
            { key: '+00:00', name: '+00:00 Cities: Dublin, Lisbon, London, Reykjavik-Iceland' },
            {
                key: '+01:00',
                name: '+01:00 Cities: Algiers, Amsterdam, Belgrade, Berlin, Brussels, Budapest, Copenhagan, Johannesburg, Madrid, Oslo, Prague, Rome, Sarajevo, Stockholm, Vienna, Warsaw, Zurich',
            },
            {
                key: '+02:00',
                name: '+02:00 Cities: Athens, Bucharest, Cairo, Helsinki, Istanbul, Jerusalem, Vinnista',
            },
            { key: '+03:00', name: '+03:00 Cities: Bahrain, DaresSalaam, Moscow, Nairobi' },
            { key: '+04:00', name: '+04:00 Cities: Dubai, Muscat' },
            { key: '+05:00', name: '+05:00 Cities: Islamabad, Karachi' },
            { key: '+05:30', name: '+05:30 Cities: Bombay, Calcutta, New Delhi' },
            { key: '+05:45', name: '+05:45 Cities: Katmandu' },
            { key: '+06:00', name: '+06:00 Cities: Dhaka, Thimbu' },
            { key: '+07:00', name: '+07:00 Cities: Bangkok, Hanoi, Jakarta, PhnomPenh' },
            {
                key: '+08:00',
                name: '+08:00 Cities: Beijing, Hong Kong, Kuala Lumpur, Manila, Perth, Shanghai, Singapore, Taipei',
            },
            { key: '+09:00', name: '+09:00 Cities: Kawasaki, Melbourne, Sydney, Tokyo' },
            { key: '+09:30', name: '+09:30 Cities: Darwin' },
            { key: '+10:00', name: '+10:00 Cities: Vladivostok' },
            { key: '+10:30', name: '+10:30 Cities: *Adelaide' },
            { key: '+11:00', name: '+11:00 ' },
            { key: '+12:00', name: '+12:00 ' },
            { key: '+13:00', name: '+13:00 Cities: *Auckland, *Rotorua' },
        ];

        function initTransitionMinutes() {
            if ($scope.settings && $scope.settings.Advanced) {
                if ($scope.settings.Advanced.InteractiveDurationDivider === undefined) {
                    $scope.settings.Advanced.InteractiveDurationDivider = $scope.settings.Advanced.VideoDurationDivider;
                }
                $scope.dividers.imageSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.StaticImageDurationDivider
                );
                $scope.dividers.animGifsSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.AnimationDurationDivider
                );
                $scope.dividers.videoSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.VideoDurationDivider
                );
                $scope.dividers.interactiveSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.InteractiveDurationDivider
                );
            }
        }

        function initAdvancedFeatures() {
            $scope.MIN_SLOW_BANDWIDTN_BITS = 1080000;
            if ($scope.settings && $scope.settings.Advanced) {
                $scope.localServerPrev = $scope.settings.Advanced.localWebPort || 0;
                $scope.localServerEnabled = $scope.settings.Advanced.localWebPort > 0;

                $scope.restoreHDMIPrev = $scope.settings.Advanced.restoreHDMI || 0;
                $scope.restoreHDMIEnabled = $scope.settings.Advanced.restoreHDMI > 0;

                $scope.connectivityPingerPrev = $scope.settings.Advanced.connectivityPingerIntervalSec || 0;
                $scope.connectivityPingerEnabled = $scope.settings.Advanced.connectivityPingerIntervalSec > 0;

                $scope.settings.Advanced.wifiFWmode = $scope.settings.Advanced.wifiFWmode || 2;

                $scope.optionalGroupAddresses = [
                    '239.1.1.234',
                    '239.1.1.235',
                    '239.1.1.236',
                    '239.1.1.237',
                    '239.129.1.2',
                    '239.254.1.234',
                ];
                $scope.settings.Advanced.GroupAddress = $scope.settings.Advanced.GroupAddress || '239.1.1.234';
                $scope.settings.Advanced.GroupPort = $scope.settings.Advanced.GroupPort || 7234;

                if (!$scope.settings.Advanced.InteractiveDurationDivider) {
                    $scope.settings.Advanced.InteractiveDurationDivider = 1000;
                }
                $scope.settings.Advanced.interactiveMaxFatal = $scope.settings.Advanced.interactiveMaxFatal || 5;

                $scope.settings.Advanced.forceEntireLoop =
                    $scope.settings.Advanced.forceEntireLoop !== undefined
                        ? $scope.settings.Advanced.forceEntireLoop
                        : true;

                $scope.settings.Advanced.videoFormat = $scope.settings.Advanced.videoFormat || 1;

                $scope.settings.Advanced.imagesMode = $scope.settings.Advanced.imagesMode || '1';
                $scope.settings.Advanced.premiumVideoMode = $scope.settings.Advanced.premiumVideoMode || '1';
                $scope.settings.Advanced.offlineMode = $scope.settings.Advanced.offlineMode || '1';

                $scope.settings.Advanced.ignoreDisplayGuidelines =
                    $scope.settings.Advanced.ignoreDisplayGuidelines !== undefined
                        ? $scope.settings.Advanced.ignoreDisplayGuidelines
                        : true;

                $scope.settings.Advanced.niioAnalyticsMode = $scope.settings.Advanced.niioAnalyticsMode || 1;

                $scope.settings.Advanced.autoDownloads = $scope.settings.Advanced.autoDownloads || {};
                $scope.settings.Advanced.autoDownloads.algo = $scope.settings.Advanced.autoDownloads.algo || 0;
                $scope.settings.Advanced.autoDownloads.reserveSpaceMB =
                    $scope.settings.Advanced.autoDownloads.reserveSpaceMB || 200;
                $scope.settings.Advanced.autoDownloads.maxAws = $scope.settings.Advanced.autoDownloads.maxAws || 10;

                $scope.settings.Advanced.slowNetwork = $scope.settings.Advanced.slowNetwork || {};
                $scope.settings.Advanced.slowNetwork.windowTime = $scope.settings.Advanced.slowNetwork.windowTime || 15;
                $scope.settings.Advanced.slowNetwork.windowSize = $scope.settings.Advanced.slowNetwork.windowSize || 3;
                $scope.settings.Advanced.slowNetwork.windowPerc = $scope.settings.Advanced.slowNetwork.windowPerc || 60;
                $scope.settings.Advanced.slowNetwork.MinBandwidth =
                    $scope.settings.Advanced.slowNetwork.MinBandwidth || 0;
                $scope.slowNetworkEnabled = $scope.settings.Advanced.slowNetwork.MinBandwidth > 0;
                $scope.settings.Advanced.slowNetwork.title = $scope.settings.Advanced.slowNetwork.title || 'Offline';
                $scope.settings.Advanced.displayDiscover = $scope.settings.Advanced.displayDiscover || '1';
                if ($scope.settings.Advanced?.ArtistBioTime === undefined) {
                    $scope.settings.Advanced.ArtistBioTime = 2;
                }
            } else {
                $scope.localServerPrev = 0;
                $scope.localServerEnabled = false;
                $scope.restoreHDMIPrev = 0;
                $scope.restoreHDMIEnabled = false;
                $scope.connectivityPingerPrev = 0;
                $scope.connectivityPingerEnabled = false;
                $scope.slowNetworkEnabled = false;
            }

            if ($scope.settings.Appearance.logoTitle === undefined) {
                $scope.settings.Appearance.logoTitle = {
                    IsChecked: false,
                    Text: '',
                    Font: 'Arial',
                    Color: '#FFFFFF',
                    BGColor: '#000000',
                    FontSize: 20,
                };
            }

            if ($scope.settings.Appearance.QR === undefined) {
                $scope.settings.Appearance.QR = {};
            }

            if ($scope.settings.Appearance.QR.size === undefined) {
                $scope.settings.Appearance.QR.size = 'S';
            }

            if ($scope.settings.Appearance.CoverRotation === undefined) {
                $scope.settings.Appearance.CoverRotation = 0;
            }
            if ($scope.settings.Appearance.CoverTemplate === undefined) {
                $scope.settings.Appearance.CoverTemplate = '';
            }

            if ($scope.settings.Appearance.logoTitle.FontSize === undefined) {
                $scope.settings.Appearance.logoTitle.FontSize = 20;
            }
            if (
                $scope.settings.Appearance.ChannelCover !== undefined &&
                $scope.settings.Appearance.ChannelCover.ShowCredit === undefined
            ) {
                $scope.settings.Appearance.ChannelCover.ShowCredit = true;
            }
            if (
                $scope.settings.Appearance.ArtworkCover !== undefined &&
                $scope.settings.Appearance.ArtworkCover.ShowDescription === undefined
            ) {
                $scope.settings.Appearance.ArtworkCover.ShowDescription = true;
            }
            if ($scope.settings.Info.playerType === undefined) {
                $scope.settings.Info.playerType = PLAYER_TYPES.REGULAR;
            }
            if ($scope.settings.Appearance.ArtistBioCover === undefined) {
                $scope.settings.Appearance.ArtistBioCover = {
                    IsChecked: false,
                    Text: '',
                    Font: 'Arial',
                    Color: 'FFFFFF',
                    BGColor: '000000',
                    FontSize: 20,
                    ShowArtistThumbnail: true,
                };
            }

            if (!$scope.settings.Appearance.offlineTag || !$scope.settings.Appearance.offlineTag.playing) {
                $scope.settings.Appearance.offlineTag = { playing: { isChecked: false } };
            }
        }

        $scope.initCampaignMode = function () {
            if (
                $scope.settings.Appearance.SponsorCover !== undefined &&
                $scope.settings.Appearance.SponsorCover.mode === undefined
            ) {
                $scope.settings.Appearance.SponsorCover.mode = 'campaign';
            }
        };

        function initAvailableDisplayModes() {
            if (
                $scope.settings &&
                $scope.settings.Appearance &&
                $scope.settings.Appearance.AvailableDisplayModes &&
                (typeof $scope.settings.Appearance.AvailableDisplayModes === 'string' ||
                    $scope.settings.Appearance.AvailableDisplayModes instanceof String)
            ) {
                $scope.settings.Appearance.AvailableDisplayModes =
                    $scope.settings.Appearance.AvailableDisplayModes.split(',');
            }
        }

        $scope.init = function () {
            $scope.submitWasClicked = false;
            $scope.defaultTab.selection = 'settings';
            $scope.dividers = {};
            if (!_.isEmpty($scope.settings)) {
                initAvailableDisplayModes();
                initTransitionMinutes();
                initAdvancedFeatures();
            }
            $scope.formValidator = FormValidationService;
        };

        $scope.$on('settings_fetched', function (event) {
            initAvailableDisplayModes();
            initTransitionMinutes();
            initAdvancedFeatures();
            $scope.$emit('selected_chosen', $scope.selectedId, $scope.deviceOrGroup);
        });

        $scope.$on('onRepeatLast', function (scope, element, attrs) {
            // Run after dom is rendered to solve logic dependancy with dom elements
            $scope.onRepeatLastTimer = $timeout($scope.validateSchedule, 0);
        });

        $scope.getArtworksProfiles = function () {
            $scope.artworksProfiles = [];
            DevicesDataService.getDeviceArtworksProfiles($scope.selectedId).then(
                function (res) {
                    $log.debug('DevicesSelectedSettingsController::getArtworksProfiles Success', {
                        res: res,
                    });
                    $scope.artworksProfiles = res.data.artProfiles;
                    $scope.artworksProfilesDate = new Date();
                },
                function (error) {
                    $log.debug('DevicesSelectedSettingsController::getArtworksProfiles Failure', {
                        error: error,
                    });
                }
            );
        };

        $scope.updateSettings = function () {
            $scope.submitWasClicked = true;
            DevicesDataService.updateSettings($scope.deviceOrGroup, $scope.selectedId, $scope.settings).then(
                function (res) {
                    $scope.selected.name = $scope.settings.Info.name;
                    $scope.selected.location = $scope.settings.Info.location;
                    $scope.selected.player_type = $scope.settings.Info?.playerType
                        ? $scope.settings.Info.playerType
                        : PLAYER_TYPES.REGULAR;
                    $scope.sortDevices();
                    $scope.updateChannelsFromSchedules($scope.settings.Schedule);

                    var messageParams = {};
                    messageParams.message = 'Settings were updated successfully';
                    messageParams.title = 'Settings Update';
                    Messages.openMessage($scope, messageParams);
                    $scope.submitWasClicked = false;
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'Failed to update settings.\nPlease refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    messageParams.title = 'Settings Update Failed';
                    Messages.openMessage($scope, messageParams);
                    $scope.submitWasClicked = false;
                }
            );
        };

        function isOverlapping(a_start, a_end, b_start, b_end) {
            var result = false;
            if (a_start <= b_end && b_start <= a_end) {
                //If the starting point of a schedule is in the space of another schedule
                result = true;
            } else {
                if (a_start.getDate() !== a_end.getDate()) {
                    //In case we added a day to one of the dates
                    var modified_a_end = a_end;
                    modified_a_end.setDate(a_start.getDate());
                    if (a_start <= b_end || b_start <= modified_a_end) {
                        result = true;
                    }
                } else if (b_start.getDate() !== b_end.getDate()) {
                    var modified_b_end = b_end;
                    modified_b_end.setDate(b_start.getDate());
                    if (a_start <= modified_b_end || b_start <= a_end) {
                        result = true;
                    }
                }
            }
            return result;
        }

        function getSchedulesToTestAgainst(i_activeSchedules, i_testingSchedule) {
            return _.filter(i_activeSchedules, function notTestingSchedule(schedule) {
                return schedule !== i_testingSchedule;
            });
        }

        $scope.validateSchedule = function (inputField) {
            // This should happen only after dom is rendered
            var activeSchedules,
                lastStartTime,
                lastEndTime,
                overlapping,
                form,
                idxA = 0,
                overlappingSchedule,
                testingSchedule,
                schedulesToTestAgainst,
                idxB,
                a_start,
                a_end,
                b_start,
                b_end;
            if (inputField && inputField.$error.required) {
                return;
            }
            form = $scope.updatesettingsForm.allSchedulesForm;
            _.each(form.$error.timeNotOverlapping, function (scheduleForm) {
                _.each(scheduleForm.$error.timeNotOverlapping, function (input) {
                    input.$setValidity('timeNotOverlapping', true);
                });
            });
            activeSchedules = _.filter($scope.settings.Schedule, function isScheduleActive(schedule) {
                return schedule.IsChecked;
            });

            for (idxA; idxA < activeSchedules.length; idxA += 1) {
                testingSchedule = activeSchedules[idxA];
                schedulesToTestAgainst = getSchedulesToTestAgainst(activeSchedules, testingSchedule);
                idxB = 0;
                a_start = testingSchedule.StartTime;
                a_end = testingSchedule.EndTime;
                for (idxB; idxB < schedulesToTestAgainst.length; idxB += 1) {
                    b_start = schedulesToTestAgainst[idxB].StartTime;
                    b_end = schedulesToTestAgainst[idxB].EndTime;
                    if (a_end < a_start) {
                        a_end.setDate(a_end.getDate() + 1); //We add a day to the dates to pass validation of future time (the end time was before the start time).
                    }
                    if (b_end < b_start) {
                        b_end.setDate(b_end.getDate() + 1);
                    }
                    if (isOverlapping(a_start, a_end, b_start, b_end)) {
                        overlapping = true;
                        overlappingSchedule = schedulesToTestAgainst[idxB];
                        break;
                    }
                }
                if (overlapping) {
                    break;
                }
            }
            if (overlapping) {
                var field;
                if (inputField) {
                    // An overlapping was found, after manipulating this inputField.
                    field = inputField;
                } else {
                    // An overlapping was found, on general check.
                    field = overlappingSchedule.inputField.startTime;
                    field.$setViewValue(field.$viewValue);
                }
                field.$setValidity('timeNotOverlapping', false);
            }

            //validate maintanace actions
            //if()
        };

        $scope.changeScheduleEnabledStatus = function (IsChecked, inputField) {
            if (IsChecked) {
                $scope.validateSchedule(inputField);
            } else {
                $scope.validateSchedule();
            }
        };

        $scope.removeSchedule = function (index) {
            $scope.settings.Schedule.splice(index, 1);
            $scope.validateSchedule();
        };

        $scope.addSchedule = function () {
            if (!$scope.settings.Schedule) {
                $scope.settings.Schedule = [];
            }
            var newSchedule = {
                Name: 'Schedule' + ($scope.settings.Schedule.length + 1),
                IsChecked: true,
                StartTime: '',
                EndTime: '',
                Brightness: 100,
                Volume: 100,
                Channels: [],
                ChannelsAreChecked: false,
                HdmiOff: false,
            };

            $scope.settings.Schedule.push(newSchedule);
        };

        $scope.changeScheduleHdmiStatus = function (schedule) {
            if (schedule.HdmiOff) {
                schedule.ChannelsAreChecked = false;
            }
        };

        $scope.onChannelsSelected = function (schedule) {
            if (schedule.ChannelsAreChecked) {
                schedule.HdmiOff = false;
            }
        };

        $scope.MaintenanceSchedule = function (index) {
            $scope.settings.MaintenanceSchedule.splice(index, 1);
        };

        $scope.max_maintenance_schedule_records = 24; //player limit = 30
        $scope.addMaintenanceSchedule = function () {
            if (!$scope.settings.MaintenanceSchedule) {
                $scope.settings.MaintenanceSchedule = [];
            }
            if ($scope.settings.MaintenanceSchedule.length > $scope.max_maintenance_schedule_records) {
                return;
            }
            var newSchedule = {
                Name: 'Action' + ($scope.settings.MaintenanceSchedule.length + 1),
                IsChecked: true,
                StartTime: '',
                EndTime: '',
                Action: '1',
                Parameters: '',
            };

            $scope.settings.MaintenanceSchedule.push(newSchedule);
        };

        $scope.$on('$destroy', function (event) {
            $timeout.cancel($scope.onRepeatLastTimer);
        });

        // DTU / divider = minutes
        $scope.setDivider = function (seconds, type) {
            if (seconds > 1) {
                if (!$scope.settings.Advanced.StaticImageDurationDivider) {
                    $scope.settings.Advanced.StaticImageDurationDivider = 1000;
                }
                if (!$scope.settings.Advanced.InteractiveDurationDivider) {
                    $scope.settings.Advanced.InteractiveDurationDivider = 1000;
                }

                if (type === 'image') {
                    $scope.settings.Advanced.StaticImageDurationDivider = $scope.settings.Advanced.DTU / seconds;
                } else if (type === 'animGif') {
                    $scope.settings.Advanced.AnimationDurationDivider = $scope.settings.Advanced.DTU / seconds;
                } else if (type === 'video') {
                    $scope.settings.Advanced.VideoDurationDivider = $scope.settings.Advanced.DTU / seconds;
                } else if (type === 'interactive') {
                    $scope.settings.Advanced.InteractiveDurationDivider = $scope.settings.Advanced.DTU / seconds;
                }
            }
        };

        $scope.hasSchedulingInClipboard = function () {
            return ClipboardService.contains('copyScheduling');
        };

        $scope.saveSchedulingToClipboard = function () {
            var data = angular.copy($scope.settings.Schedule);
            _.each(data, function (sr) {
                sr.Channels = [];
            });
            ClipboardService.set('copyScheduling', data);
        };

        $scope.replaceSchedulingFromClipboard = function () {
            var data = ClipboardService.get('copyScheduling', null);
            if (data === null) {
                return;
            }
            $scope.settings.Schedule = data;
        };
    },
]);
