//English
translationsEN.USERS = {};
translationsEN.USERS.PASSWORD = 'Password';
translationsEN.USERS.EMAIL = 'Email';
translationsEN.USERS.FIRST_NAME = 'First Name';
translationsEN.USERS.LAST_NAME = 'Last Name';
translationsEN.USERS.USERNAME = 'Username';

//Hebrew
translationsHE.USERS = {};
translationsHE.USERS.PASSWORD = 'סיסמה';
translationsHE.USERS.EMAIL = 'אי-מייל';
translationsHE.USERS.FIRST_NAME = 'שם פרטי';
translationsHE.USERS.LAST_NAME = 'שם משפחה';
translationsHE.USERS.USERNAME = 'כינוי';
