'use strict';

angular.module('core').directive('searchUser', [
    'Search',
    '$window',
    function (Search, $window) {
        return {
            restrict: 'E',
            scope: {
                userselected: '&',
                displayid: '=?',
                control: '=',
            },
            link: function (scope, element, attrs) {
                scope.Search = Search;
                scope.displayid = angular.isDefined(scope.displayid) ? scope.displayid : false;
                scope.internalControl = scope.control || {};
                scope.internalControl.selectedUserId = undefined;
                scope.internalControl.clear =
                    scope.internalControl.clear ||
                    function () {
                        if (scope.item !== undefined) {
                            scope.item = undefined;
                            scope.internalControl.selectedUserId = undefined;
                        }
                    };
                scope.specificUserSelected = function (item, model, label, tmpUserObject) {
                    if (item.basicType === 'user') {
                        if (scope.userselected) {
                            scope.internalControl.selectedUserId = item.id;
                            scope.userselected({ selectedItem: item });
                        }
                    }
                };
            },
            template:
                '<input type="text" class="form-control" ng-model="item.name" placeholder="Search User" ' +
                'uib-typeahead="result.value for result in Search.searchUsers(item.name)" ' +
                'typeahead-template-url="modules/search/services/searchTemplate.html" ' +
                'typeahead-wait-ms="300" ' +
                'typeahead-on-select="specificUserSelected($item, $model, $label)" ' +
                'typeahead-append-to-body="true" ' +
                'typeahead-loading="vm.loadingResults"> ' +
                '<small ng-if="internalControl.selectedUserId && internalControl.selectedUserId>0">id: {{internalControl.selectedUserId}}</small>',
        };
    },
]);
