'use strict';

angular.module('home').controller('HomeMainTabMainController', [
    '$log',
    '$scope',
    '$q',
    '$stateParams',
    'ChannelService',
    'ArtworkService',
    'HomeDataService',
    'User',
    function ($log, $scope, $q, $stateParams, ChannelService, ArtworkService, HomeDataService, User) {
        function getPortfolioArtworks() {
            HomeDataService.fetchPortfolioArtworks('6', $scope.profile.id).then(
                function (res) {
                    $scope.portfolioArtworks = res.data.data.artworks;
                },
                function (error) {
                    $log.debug('there was an error fetching portfolio artworks: ', error);
                }
            );
        }

        function getVaultArtworks() {
            $q.all([
                HomeDataService.fetchVaultPurchasedArtworks('6', $scope.profile.id),
                HomeDataService.fetchVaultCollectedArtworks('6', $scope.profile.id),
            ]).then(function (result) {
                var purchased = result[0].data.data.artworks;
                var collected = result[1].data.data.artworks;

                $scope.vaultArtworks = purchased.concat(collected);
                $scope.vaultArtworks.splice(6, 6);
            });
        }

        function getCuratedChannels() {
            HomeDataService.fetchCuratedChannels('6', $scope.profile.id).then(
                function (res) {
                    $scope.curatedChannels = res.data.data.channels;
                },
                function (error) {
                    $log.debug('there was an error fetching curated channels: ', error);
                }
            );
        }

        function getCuratedArtcasts() {
            HomeDataService.fetchCuratedChannels('6', $scope.profile.id, 'AC').then(
                function (res) {
                    $scope.curatedArtcasts = res.data.data.channels;
                },
                function (error) {
                    $log.debug('there was an error fetching curated artcasts: ', error);
                }
            );
        }

        function getCuratedCatalogs() {
            HomeDataService.fetchCuratedChannels('6', $scope.profile.id, 'CT').then(
                function (res) {
                    $scope.curatedCatalogs = res.data.data.channels;
                },
                function (error) {
                    $log.debug('there was an error fetching curated artcasts: ', error);
                }
            );
        }

        $scope.showFullBio = function () {
            $scope.displayMore = false;
            $scope.bio = $scope.profile.about;
        };

        $scope.init = function () {
            $scope.sidebarSelection = $stateParams.sidebarSelection;
            $scope.ChannelService = ChannelService;
            $scope.ArtworkService = ArtworkService;
            $scope.isAdmin = User.isAdmin();
            $scope.showSocialIcons =
                $scope.profile.facebook_link ||
                $scope.profile.linkedin_link ||
                $scope.profile.twitter_link ||
                $scope.profile.website_link ||
                $scope.profile.instagram_link ||
                $scope.profile.blog_link ||
                $scope.profile.tumblr_link ||
                $scope.profile.discord_link;
            $scope.bio = $scope.profile.about;
            if ($scope.bio && $scope.bio.length > 400) {
                $scope.displayMore = true;
                $scope.bio = $scope.bio.substring(0, 400) + '...';
            }
            getPortfolioArtworks();
            getVaultArtworks();
            getCuratedChannels();
            getCuratedArtcasts();
            getCuratedCatalogs();
        };
    },
]);
