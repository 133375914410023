'use strict';

angular.module('admin-area').controller('AdminAreaServicesReRunController', [
    '$scope',
    '$http',
    '$log',
    '$filter',
    'Messages',
    'OpenPopupForm',
    '_',
    function ($scope, $http, $log, $filter, Messages, OpenPopupForm, _) {
        $scope.newRerun = $scope.newRerun || {};
        $scope.report = $scope.params ? $scope.params.report : {};
        $scope.toggles = $scope.toggles || {};
        $scope.formattedToday = $filter('date')(new Date(), 'ddMMyyyy-HHmmss');
        $scope.OpenPopupForm = OpenPopupForm;

        // Grid
        $scope.orderByField = 'id';
        $scope.reverseSort = true;

        $scope.reportOrderByField = 'artwork_id';
        $scope.reportReverseSort = false;

        $scope.itemsPerPageOptions = [10, 25, 50, 100, 250, 500, 1000];
        $scope.engineVersionOperators = {
            eq: '=',
            gt: '>',
            ne: '!=',
            lt: '<',
            ge: '>=',
            le: '<=',
        };

        $scope.timeFilterAvailable = [
            { key: -1, value: 'All' },
            { key: 7, value: 'Last Week', default: true },
            { key: 14, value: 'Last 2 Weeks' },
            { key: 31, value: 'Last Month' },
        ];
        $scope.timeFilter = _.find($scope.timeFilterAvailable, function (time) {
            return time.default;
        });

        // Pagination
        $scope.rerunsPagination = {
            itemsPerPage: 10,
            currentPage: 1,
            maxSize: 5,
            totalItems: 11,
            isRequire: function () {
                return true;
                // return $scope.rerunsPagination.itemsPerPage < $scope.rerunsPagination.totalItems;
            },
            setPage: function (pageNo) {
                $scope.rerunsPagination.currentPage = pageNo;
            },
        };

        $scope.rerunArtworksPagination = {
            itemsPerPage: 10,
            currentPage: 1,
            maxSize: 5,
            totalItems: 11,
            isRequire: function () {
                // return $scope.rerunArtworksPagination.itemsPerPage < $scope.rerunArtworksPagination.totalItems;
                return true;
            },
            setPage: function (pageNo) {
                $scope.rerunArtworksPagination.currentPage = pageNo;
            },
        };

        $scope.rerunArtworksTasksPagination = {
            itemsPerPage: 10,
            currentPage: 1,
            maxSize: 5,
            totalItems: 11,
            isRequire: function () {
                // return $scope.rerunArtworksTasksPagination.itemsPerPage < $scope.rerunArtworksTasksPagination.totalItems;
                return true;
            },
            setPage: function (pageNo) {
                $scope.rerunArtworksTasksPagination.currentPage = pageNo;
            },
        };

        //$scope.reruns = [
        //    {
        //        "id": "1",
        //        "status": "STARTED",
        //        "input_json": "{\"ignoreList\":[\"*.png*\",\"*.jpg*\"],\"usersIds\":[\"30\"],\"artworksIds\":[]}",
        //        "start_time": "2015-03-23 12:49:54",
        //        "end_time": null,
        //        "num_of_tasks": 4,
        //        "num_of_completed_tasks": 0
        //    },
        //    {
        //        "id": "2",
        //        "status": "FINISHED",
        //        "input_json": "{\"ignoreList\":[\"*.png*\"],\"usersIds\":[\"30\"],\"artworksIds\":[]}",
        //        "start_time": "2015-03-23 12:49:54",
        //        "end_time": null,
        //        "num_of_tasks": 4,
        //        "num_of_completed_tasks": 4
        //    },
        //    {
        //        "id": "3",
        //        "status": "CANCELED",
        //        "input_json": "{\"ignoreList\":[\"*.png*\"],\"usersIds\":[\"30\"],\"artworksIds\":[]}",
        //        "start_time": "2015-03-23 12:49:54",
        //        "end_time": null,
        //        "num_of_tasks": 4,
        //        "num_of_completed_tasks": 0
        //    },
        //    {
        //        "id": "4",
        //        "status": "PAUSED",
        //        "input_json": "{\"ignoreList\":[\"*.png*\"],\"usersIds\":[\"30\"],\"artworksIds\":[]}",
        //        "start_time": "2015-03-23 12:49:54",
        //        "end_time": null,
        //        "num_of_tasks": 4,
        //        "num_of_completed_tasks": 0
        //    }
        //];

        function splitAndTrimArray(arrayString) {
            var array;

            if (arrayString && Object.prototype.toString.call(arrayString) !== '[object Array]') {
                array = arrayString.split(',');
                array = _.map(array, function (item) {
                    return item.trim();
                });
                array = _.compact(array);
                array = _.uniq(array);
            }

            return array;
        }

        function prepareReRunData(rerun, isReport) {
            var newRerunData = {};
            if ($scope.toggles.selectIgnoreList) {
                newRerunData.ignoreList = splitAndTrimArray(rerun.ignoreList);
            }
            if ($scope.toggles.includeUsersIds) {
                newRerunData.includeUsersIds = splitAndTrimArray(rerun.includeUsersIds);
            }
            if ($scope.toggles.excludeUsersIds) {
                newRerunData.excludeUsersIds = splitAndTrimArray(rerun.excludeUsersIds);
            }
            if ($scope.toggles.includeArtworksIds) {
                newRerunData.includeArtworksIds = splitAndTrimArray(rerun.includeArtworksIds);
            }
            if ($scope.toggles.excludeArtworksIds) {
                newRerunData.excludeArtworksIds = splitAndTrimArray(rerun.excludeArtworksIds);
            }
            if ($scope.toggles.includeChannelsIds) {
                newRerunData.includeChannelsIds = splitAndTrimArray(rerun.includeChannelsIds);
            }
            if ($scope.toggles.excludeChannelsIds) {
                newRerunData.excludeChannelsIds = splitAndTrimArray(rerun.excludeChannelsIds);
            }
            if ($scope.toggles.selectPlatform) {
                newRerunData.platform = rerun.platform;
            }
            if ($scope.toggles.selectEngine) {
                if (rerun.engineVersion && rerun.engineOperator) {
                    newRerunData.engineVersion = rerun.engineVersion;
                    newRerunData.engineOperator = rerun.engineOperator.toLowerCase();
                }
            }

            newRerunData.forceSendEmail = $scope.toggles.forceSendEmail;
            newRerunData.forceSendDebugTaskEmail = $scope.toggles.forceSendDebugTaskEmail;
            newRerunData.isReport = isReport;

            return newRerunData;
        }

        function parseRerun(rerun) {
            var parsedRerun = rerun;
            parsedRerun.id = parseInt(rerun.id);
            // Turn the string of the conditions to array.
            if (rerun.input_json) {
                // input_json comes only when the rerun returned from Server
                try {
                    var input_json = angular.fromJson(rerun.input_json);
                    parsedRerun.ignoreList = input_json.ignoreList;
                    parsedRerun.usersIds = input_json.usersIds;
                    parsedRerun.artworksIds = input_json.artworksIds;
                    parsedRerun.channelsIds = input_json.channelsIds;
                    parsedRerun.includeUsersIds = input_json.includeUsersIds;
                    parsedRerun.excludeUsersIds = input_json.excludeUsersIds;
                    parsedRerun.includeArtworksIds = input_json.includeArtworksIds;
                    parsedRerun.excludeArtworksIds = input_json.excludeArtworksIds;
                    parsedRerun.includeChannelsIds = input_json.includeChannelsIds;
                    parsedRerun.excludeChannelsIds = input_json.excludeChannelsIds;
                    parsedRerun.platform = input_json.platform;
                    parsedRerun.engineVersion = input_json.engineVersion;
                    parsedRerun.engineOperator = input_json.engineOperator;
                } catch (e) {
                    $log.debug('AdminAreaServicesReRunController::parseRerun Failed to parse rerun input_json', e);
                }
            } else {
                // input_json doesn't exist if the rerun is currently only the client side
                var data = prepareReRunData(rerun);
                parsedRerun.ignoreList = data.ignoreList;
                parsedRerun.includeUsersIds = data.includeUsersIds;
                parsedRerun.excludeUsersIds = data.excludeUsersIds;
                parsedRerun.includeArtworksIds = data.includeArtworksIds;
                parsedRerun.excludeArtworksIds = data.excludeArtworksIds;
                parsedRerun.includeChannelsIds = data.includeChannelsIds;
                parsedRerun.excludeChannelsIds = data.excludeChannelsIds;
            }

            return parsedRerun;
        }

        $scope.getRerunList = function () {
            $scope.toggles.spinRefresh = true;
            var data = {
                lastXDays: $scope.timeFilter.key,
            };

            $http.post('/engineReruns/getAll', data).then(
                function (res) {
                    var input_json;
                    $log.debug(
                        'AdminAreaServicesReRunController::getRerunList Success retrieving codes engineReruns/getAll',
                        res
                    );
                    $scope.toggles.spinRefresh = false;
                    $scope.reruns = res.data.data || [];
                    $scope.reruns = _.map($scope.reruns, function (rerun) {
                        return parseRerun(rerun);
                    });
                    $scope.rerunsPagination.totalItems = $scope.reruns.length;
                },
                function (error) {
                    $log.debug(
                        'AdminAreaServicesReRunController::getRerunList Error retrieving codes engineReruns/getAll',
                        error
                    );
                    $scope.toggles.spinRefresh = false;
                }
            );
        };

        $scope.init = function () {
            $scope.submitWasClicked = false;
            $scope.reportWasClicked = false;
            $scope.getRerunList();
        };

        function isEmptyToggles() {
            var enabledToggleFound = false;

            _.each($scope.toggles, function (value, key) {
                if (value === true) {
                    enabledToggleFound = true;
                }
            });

            return !enabledToggleFound;
        }

        $scope.createNewRerun = function (isReport) {
            var data = prepareReRunData($scope.newRerun, isReport);
            if (isReport) {
                $scope.reportWasClicked = true;
            } else {
                $scope.submitWasClicked = true;
            }
            var messageParams = {};

            messageParams = {};
            messageParams.message =
                'You are about to perform a rerun ' +
                (isReport ? 'report ' : '') +
                'using these parameters: \n' +
                JSON.stringify(data);
            messageParams.title = 'Execute ' + (isReport ? 'Report' : 'ReRun');
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Execute';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/engineReruns/startRerun', data).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesReRunController::createNewRerun Success in engineReruns/startRerun',
                                { res: res, data: data }
                            );
                            if (isReport) {
                                $scope.reportWasClicked = false;
                            } else {
                                $scope.submitWasClicked = false;
                            }
                            $scope.getRerunList();
                            $scope.newRerun = {};
                            $scope.toggles = {};
                            var messageParams = {};
                            messageParams.message = 'Rerun task was submitted';
                            Messages.openMessage($scope, messageParams);
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesReRunController::createNewRerun Success in engineReruns/startRerun',
                                { error: error, data: data }
                            );
                            if (isReport) {
                                $scope.reportWasClicked = false;
                            } else {
                                $scope.submitWasClicked = false;
                            }
                            var messageParams = {};
                            messageParams.message = 'Re-run task generation failed.\n';
                            messageParams.message +=
                                error && error.data && error.data.message && error.data.message.text
                                    ? error.data.message.text
                                    : '';
                            messageParams.title = 'Failed To Submit Re-Run';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                } else {
                    if (isReport) {
                        $scope.reportWasClicked = false;
                    } else {
                        $scope.submitWasClicked = false;
                    }
                }
            });

            // This popup should appear first
            if (isEmptyToggles() || _.isEmpty(data)) {
                messageParams = {};
                messageParams.message =
                    '<div class="alert alert-danger" role="alert">You are about to perform a rerun ' +
                    (isReport ? 'report ' : '') +
                    'with empty parameters!\nThis means that the Rerun will be run on ALL ARTWORKS!';
                messageParams.message += "\n\nAre you sure you know what you're doing?</div>";
                messageParams.title = 'Execute ' + (isReport ? 'Report' : 'ReRun') + ' - Empty Parameters';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'No';
                messageParams.okText = 'Yes';
                messageParams.okClass = 'danger';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res !== 'ok') {
                        // No
                        Messages.cancel();
                    }
                });
            }
        };

        // $scope.generateReport = function () {
        //     var data = prepareReRunData($scope.newRerun);
        //     $scope.reportWasClicked = true;
        //
        //     var messageParams = {};
        //     messageParams.message = 'You are about to perform a generate a rerun report using these parameters: \n' + JSON.stringify(data);
        //     messageParams.title = 'Generate Report';
        //     messageParams.disableAutoDismiss = true;
        //     messageParams.cancelText = 'Cancel';
        //     messageParams.okText = 'Generate';
        //     messageParams.okClass = 'danger';
        //     Messages.openMessage($scope, messageParams)
        //         .then(function(res) {
        //             if (res === 'ok') {
        //                 $http.post('/engineReruns/generateReport', data)
        //                     .then(function (res) {
        //                         $log.debug('AdminAreaServicesReRunController::generateReport Success in engineReruns/generateReport', {res: res, data: data});
        //                         $scope.reportWasClicked = false;
        //                         $scope.updateReport(res.data.data, $scope.newRerun);
        //                         $scope.openReport($scope.report);
        //                     }, function (error) {
        //                         $log.debug('AdminAreaServicesReRunController::generateReport Error in engineReruns/generateReport', {error: error, data: data});
        //                         $scope.reportWasClicked = false;
        //                         var messageParams = {};
        //                         messageParams.message = 'Report generation failed, please try again.';
        //                         messageParams.title = 'Failed To Generate Code';
        //                         messageParams.disableAutoDismiss = true;
        //                         Messages.openMessage($scope, messageParams);
        //                     });
        //             } else {
        //                 $scope.reportWasClicked = false;
        //             }
        //         });
        //
        //     // This popup should appear first
        //     if (isEmptyToggles() || _.isEmpty(data)) {
        //         messageParams = {};
        //         messageParams.message = '<div class="alert alert-danger" role="alert">You are about to perform a rerun with empty parameters!\nThis means that the Rerun will be run on ALL ARTWORKS!';
        //         messageParams.message += '\n\nAre you sure you know what you\'re doing?</div>';
        //         messageParams.title = 'Execute Rerun - Empty Parameters';
        //         messageParams.disableAutoDismiss = true;
        //         messageParams.cancelText = 'No';
        //         messageParams.okText = 'Yes';
        //         messageParams.okClass = 'danger';
        //         Messages.openMessage($scope, messageParams)
        //             .then(function(res) {
        //                 if (res !== 'ok') { // No
        //                     Messages.cancel();
        //                 }
        //             });
        //     }
        // };

        $scope.cancelRerun = function (id, index) {
            var data = {
                rerunId: id,
            };
            var messageParams = {};
            messageParams.message = 'You are about to cancel this rerun. Are you sure?';
            messageParams.title = 'Cancel ReRun';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Back';
            messageParams.okText = 'Cancel Rerun';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/engineReruns/cancelRerun', data).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesReRunController::cancelRerun Success in engineReruns/cancelRerun',
                                { res: res, data: data }
                            );
                            $scope.getRerunList();
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesReRunController::cancelRerun Error in engineReruns/cancelRerun',
                                { error: error, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Cancel failed, please try again.';
                            messageParams.title = 'Failed To Cancel Rerun';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };

        $scope.pauseRerun = function (id, index) {
            var data = {
                rerunId: id,
            };

            var messageParams = {};
            messageParams.message = 'You are about to pause this rerun. Are you sure?';
            messageParams.title = 'Pause ReRun';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Back';
            messageParams.okText = 'Pause Rerun';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/engineReruns/pauseRerun', data).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesReRunController::pauseRerun Success in engineReruns/pauseRerun',
                                { res: res, data: data }
                            );
                            $scope.getRerunList();
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesReRunController::pauseRerun Error in engineReruns/pauseRerun',
                                { error: error, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Pause failed, please try again.';
                            messageParams.title = 'Failed To Pause Rerun';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };

        $scope.resumeRerun = function (id, index) {
            var data = {
                rerunId: id,
            };

            var messageParams = {};
            messageParams.message = 'You are about to resume this rerun. Are you sure?';
            messageParams.title = 'Resume ReRun';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Back';
            messageParams.okText = 'Resume Rerun';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/engineReruns/resumeRerun', data).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesReRunController::resumeRerun Success in engineReruns/resumeRerun',
                                { res: res, data: data }
                            );
                            $scope.getRerunList();
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesReRunController::resumeRerun Error in engineReruns/resumeRerun',
                                { error: error, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Resume failed, please try again.';
                            messageParams.title = 'Failed To Resume Rerun';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };

        $scope.getTasks = function (rerun, index, report, refreshReport) {
            var data = {
                rerunId: rerun.id,
            };
            if (refreshReport) {
                $scope.toggles.spinRefresh = true;
            }

            $http
                .post('/engineReruns/getTasks', data)
                .then(
                    function (res) {
                        $log.debug('AdminAreaServicesReRunController::getTasks Success in engineReruns/getTasks', {
                            res: res,
                            data: data,
                        });
                        $scope.updateReport(res.data.data, rerun, report);
                        if (!refreshReport) {
                            $scope.openReport(report);
                        }
                    },
                    function (error) {
                        $log.debug('AdminAreaServicesReRunController::getTasks Error in engineReruns/getTasks', {
                            error: error,
                            data: data,
                        });
                        var messageParams = {};
                        messageParams.message = 'Resume failed, please try again.';
                        messageParams.title = 'Failed To Resume Rerun';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    }
                )
                .finally(function () {
                    if (refreshReport) {
                        $scope.toggles.spinRefresh = false;
                    }
                });
        };

        $scope.rerunFailedTasks = function (rerun, index) {
            var data = {
                rerunId: rerun.id,
            };

            var messageParams = {};
            messageParams.message = 'You are about to rerun failed tasks. Are you sure?';
            messageParams.title = 'Rerun Failed Tasks';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Back';
            messageParams.okText = 'Rerun Failed Tasks';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/engineReruns/reRunFailedTasks', data).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesReRunController::rerunFailedTasks Success in engineReruns/reRunFailedTasks',
                                { res: res, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Successfully reran failed task';
                            messageParams.title = 'Rerun Failed Tasks';
                            Messages.openMessage($scope, messageParams);
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesReRunController::rerunFailedTasks Error in engineReruns/reRunFailedTasks',
                                { error: error, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Rerun failed tasks failed, please try again.';
                            messageParams.title = 'Failed To Rerun Failed Tasks';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };

        //$scope.tasks = [
        //    {
        //        "artwork_id": "394",
        //        "todo_json": "{\"SourceMediaName\":\"Business_lunch_dealTHUMB_(1).jpg\",\"Width\":700,\"Height\":700,\"FrameRate\":0,\"EmployDRM\":false,\"Watermark\":true,\"StaticPrefix\":\"Preview\"}",
        //        "status": "QUEUED",
        //        "type": "IMAGE",
        //        "engine_version": "117",
        //        "priority": 0
        //    },
        //    {
        //        "artwork_id": "395",
        //        "todo_json": "{\"SourceMediaName\":\"Business_lunch_dealTHUMB_(1).jpg\",\"Width\":700,\"Height\":700,\"FrameRate\":0,\"EmployDRM\":false,\"Watermark\":true,\"StaticPrefix\":\"Preview\"}",
        //        "status": "QUEUED",
        //        "type": "IMAGE",
        //        "engine_version": "116",
        //        "priority": 0
        //    },
        //    {
        //        "artwork_id": "394",
        //        "todo_json": "{\"SourceMediaName\":\"Business_lunch_dealTHUMB_(1).jpg\",\"Width\":700,\"Height\":700,\"FrameRate\":0,\"EmployDRM\":false,\"Watermark\":true,\"StaticPrefix\":\"Preview\"}",
        //        "status": "QUEUED",
        //        "type": "IMAGE",
        //        "engine_version": "116",
        //        "priority": 0
        //    },
        //    {
        //        "artwork_id": "394",
        //        "todo_json": "{\"SourceMediaName\":\"Business_lunch_dealTHUMB_(1).jpg\",\"Width\":700,\"Height\":700,\"FrameRate\":0,\"EmployDRM\":false,\"Watermark\":true,\"StaticPrefix\":\"Preview\"}",
        //        "status": "QUEUED",
        //        "type": "IMAGE",
        //        "engine_version": "117",
        //        "priority": 0
        //    },
        //    {
        //        "artwork_id": "394",
        //        "todo_json": "{\"SourceMediaName\":\"Business_lunch_dealTHUMB_(1).jpg\",\"Width\":700,\"Height\":700,\"FrameRate\":0,\"EmployDRM\":false,\"Watermark\":true,\"StaticPrefix\":\"Preview\"}",
        //        "status": "QUEUED",
        //        "type": "IMAGE",
        //        "engine_version": "117",
        //        "priority": 0
        //    }
        //];

        // TASK
        //         artwork_id: "2000"
        //         channel_id: null
        //         created: "2020-11-07 21:17:07"
        //         ended: "2020-11-07 21:18:51"
        //         engine_rerun_id: "65"
        //         id: "252843"
        //         num_of_failures: "0"
        //         platform: null
        //         priority: "0"
        //         started: "2020-11-07 21:17:35"
        //         status: "FINISHED"
        //         todo_json: "{"SourceMediaName":"1448535647775-XsobUSznTS6Xck7i.mov","Width":1280,"Height":720,"FrameRate":25,"EmployDRM":false,"Watermark":false,"ConstantBitRate":0,"UseH265":0,"MakeHLS":0,"StaticPrefix":"Preview-wm0","Public":true,"SubFolder":"wm0\/"}"
        //         type: "VIDEO"
        //         worker_host: "HetznerProd1"
        //         worker_ip: "138.201.131.166↵"

        // ARTWORK
        // artwork_id: "2222"
        // created: "2020-11-12 13:08:55"
        // engine_rerun_id: "91"
        // id: "37960"
        // reason: "failed creating tasks"
        // status: "FAILED"

        $scope.updateReport = function (tasks, rerun, report) {
            report.artworks = tasks.processedArtworks;
            report.tasks = _.map(tasks.todoTasks, function (task) {
                try {
                    task.id = parseInt(task.id);
                    task.todo_json = angular.fromJson(task.todo_json) || {};
                } catch (e) {
                    $log.debug(
                        'AdminAreaServicesReRunController::updateReport Failed to parse task ' +
                            task.id +
                            ' input_json',
                        e
                    );
                }
                return task;
            });

            var artworkStatuses = {};
            var tasksStatuses = {};
            _.each(report.artworks, function (artwork) {
                artworkStatuses[artwork.status] = (artworkStatuses[artwork.status] || 0) + 1;
                artwork.tasks = _.filter(report.tasks, function (task) {
                    return task.artwork_id === artwork.artwork_id;
                });
                if (artwork.tasks && artwork.tasks.length > 0) {
                    var totalTasks = _.countBy(artwork.tasks, function (task) {
                        return task.status && task.status === 'FINISHED' ? 'finished' : 'unfinished';
                    });
                    artwork.finishedTasks = totalTasks.finished || 0;
                    artwork.unfinishedTasks = totalTasks.unfinished || 0;

                    var tasksBeingProcessedNow = _.filter(artwork.tasks, function (task) {
                        return task.status === 'PROCESSING';
                    });
                    if (tasksBeingProcessedNow && tasksBeingProcessedNow.length > 0) {
                        artwork.nowProcessing = true;
                        artwork.numOfCurrentlyProcessed = tasksBeingProcessedNow.length;
                    } else {
                        artwork.nowProcessing = false;
                    }
                }
                artwork.artwork_id = parseInt(artwork.artwork_id);
            });

            _.each(report.tasks, function (task) {
                tasksStatuses[task.status] = (tasksStatuses[task.status] || 0) + 1;
            });

            rerun.artworkStatuses = artworkStatuses;
            rerun.tasksStatuses = tasksStatuses;
            report.rerun = parseRerun(rerun);
        };

        $scope.openReport = function (report) {
            var oldFormat = false;
            if (report && report.rerun && report.rerun.start_time) {
                oldFormat = new Date(report.rerun.start_time) <= new Date('2020-11-16 00:00:00');
            }
            var params = { report: report, oldFormat: oldFormat };

            var messageParams = {};
            messageParams.size = 'lg';
            messageParams.title = 'Report (' + report.rerun.status + ')';
            messageParams.disableAutoDismiss = true;
            messageParams.template = 'modules/admin-area/views/admin-area.services.re-run.report.client.view.html';
            messageParams.templateParams = params;
            // messageParams.templateStyle = {'max-height': '600px'};
            Messages.openMessage($scope, messageParams);
            //$state.go('.report');
        };

        $scope.reRunSingleTask = function (task, rerun, index) {
            var data = {
                rerunId: rerun.id,
                taskId: task.id,
            };

            var messageParams = {};
            messageParams.message = 'You are about to rerun this task. Are you sure?';
            messageParams.title = 'Rerun Single Task';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Back';
            messageParams.okText = 'Rerun Task';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/engineReruns/reRunSingleTask', data).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesReRunController::reRunSingleTask Success in engineReruns/reRunFailedTasks',
                                { res: res, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Successfully reran task';
                            messageParams.title = 'Rerun Task';
                            Messages.openMessage($scope, messageParams);
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesReRunController::reRunSingleTask Error in engineReruns/reRunFailedTasks',
                                { error: error, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Failed to rerun task, please try again.';
                            messageParams.title = 'Rerun Task';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };

        $scope.reRunSingleFailedArtwork = function (artwork, rerun, index) {
            var data = {
                rerunId: rerun.id,
                artworkId: artwork.artwork_id,
            };

            var messageParams = {};
            messageParams.message = 'You are about to rerun this failed artwork. Are you sure?';
            messageParams.title = 'Rerun Single Failed Artwork';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Back';
            messageParams.okText = 'Rerun Artwork';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/engineReruns/reRunSingleFailedArtwork', data).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesReRunController::reRunSingleFailedArtwork Success in engineReruns/reRunFailedTasks',
                                { res: res, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Successfully reran artwork TODO';
                            messageParams.title = 'Rerun Artwork TODO';
                            Messages.openMessage($scope, messageParams);
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesReRunController::reRunSingleFailedArtwork Error in engineReruns/reRunFailedTasks',
                                { error: error, data: data }
                            );
                            var messageParams = {};
                            messageParams.message = 'Failed to rerun artwork TODO, please try again.';
                            messageParams.title = 'Rerun Artwork TODO';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };

        $scope.finishIconClass = function (rerun) {
            var styleClass = '';
            if (rerun.status === 'FINISHED') {
                if (rerun.num_of_completed_tasks === rerun.num_of_tasks) {
                    styleClass = 'glyphicon glyphicon-ok';
                } else {
                    styleClass = 'glyphicon glyphicon-remove';
                }
            }
            return styleClass;
        };

        $scope.finishIconStyle = function (rerun) {
            var styleStyle = {};
            if (rerun && rerun.status === 'FINISHED') {
                if (rerun.is_report || rerun.num_of_completed_tasks === rerun.num_of_tasks) {
                    styleStyle = { color: '#00BA31' };
                } else {
                    styleStyle = { color: 'red' };
                }
            }
            return styleStyle;
        };

        $scope.colorLine = function (rerun) {
            var styleStyle = {};

            if (rerun) {
                if (rerun.status === 'PAUSED') {
                    styleStyle = { warning: true };
                } else if (rerun.status === 'CANCELED') {
                    styleStyle = { danger: true };
                } else if (rerun.status === 'FINISHED') {
                    styleStyle = { success: true };
                }
            }

            return styleStyle;
        };

        $scope.colorArtworkLine = function (artwork) {
            var styleStyle = {};

            if (artwork) {
                if (artwork.status === 'IGNORE') {
                    styleStyle = { warning: true };
                } else if (artwork.status === 'FINISHED') {
                    styleStyle = { success: true };
                } else if (artwork.status === 'FAILED') {
                    styleStyle = { danger: true };
                } else if (artwork.status === 'PROCESSING_IN_ENGINE') {
                    if (artwork.nowProcessing) {
                        styleStyle = { info: true };
                    }
                }
            }

            return styleStyle;
        };

        $scope.colorTaskLine = function (task) {
            var styleStyle = {};

            if (task) {
                if (task.status === 'FINISHED') {
                    styleStyle = { success: true };
                } else if (task.status === 'FAILED') {
                    styleStyle = { danger: true };
                }
            }

            return styleStyle;
        };

        // Export Report to CSV
        $scope.getHeaderForSubmissionsCSV = function () {
            var array = [];
            // array.push('Task ID', 'Artwork ID', 'Status', 'Type', 'Engine Version', 'Priority', 'Task Parameters');
            array.push('Task ID', 'Artwork ID', 'Status', 'Type', 'Task Parameters');
            return array;
        };

        function incrementAlphabetChar(char) {
            // Taken from here: http://stackoverflow.com/questions/30685916/increment-a-string-with-letters
            var result = (parseInt(char, 36) + 1).toString(36).replace(/0/g, 'a');

            if (result[0] === '1') {
                result = 'a' + result.slice(1);
            }

            return result;
        }

        $scope.exportSubmissionsCSV = function (tasks) {
            var array = [];

            if (tasks) {
                tasks.forEach(function (row, rowIndex) {
                    var line = {};
                    var index = 'a';

                    // line[index] = rowIndex + 1;
                    // index = incrementAlphabetChar(index);
                    line[index] = row.id;
                    index = incrementAlphabetChar(index);
                    line[index] = row.artwork_id;
                    index = incrementAlphabetChar(index);
                    line[index] = row.status;
                    index = incrementAlphabetChar(index);
                    line[index] = row.type;
                    // index = incrementAlphabetChar(index);
                    // line[index] = row.engine_version;
                    // index = incrementAlphabetChar(index);
                    // line[index] = row.priority;
                    index = incrementAlphabetChar(index);
                    line[index] = '';
                    for (var key in row.todo_json) {
                        line[index] += key + ': ' + row.todo_json[key] + '\n';
                    }

                    array.push(line);
                });
            }

            return array;
        };
    },
]);
