'use strict';

angular.module('core').directive('artworkActionButton', [
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                artwork: '=',
                collection: '=?',
                addedClass: '=?',
            },
            templateUrl: 'modules/common/directives/common.artwork-action-button.view.html',
            controller: 'ArtworkActionButtonController',
            controllerAs: 'vm',
            bindToController: true, //required in 1.3+ with controllerAs
        };
    },
]);
