'use strict';

angular.module('admin-area').controller('AdminAreaController', [
    '$scope',
    '$state',
    'User',
    function ($scope, $state, User) {
        var isProCurator = User.isProCurator();
        $scope.pages = [
            {
                link: 'statuses',
                name: 'Status',
            },
            {
                link: 'cms',
                name: 'CMS',
            },
            {
                link: 'reports',
                name: 'Reports',
            },
            {
                link: 'dashboard',
                name: 'Dashboard',
            },
            {
                link: 'devices',
                name: 'Displays Management',
            },
            {
                link: 'campaigns',
                name: 'Campaigns',
            },
            {
                link: 'invitations',
                name: 'Invitations',
            },
            {
                link: 'user-plans',
                name: 'Users & Plans',
            },
            {
                link: 'events',
                name: 'Events',
            },
            {
                link: 'services',
                name: 'Services',
            },
        ];
        $scope.init = function () {};

        $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if (toState.name && toState.name.includes('admin-area') && isProCurator) {
                $scope.pages = [
                    { link: 'cms', name: 'CMS' },
                    { link: 'services', name: 'Services' },
                    { link: 'devices', name: 'Displays Management' },
                ];
            }
            if (toState === 'admin-area' || toState.name === 'admin-area') {
                if (isProCurator) {
                    $state.go('admin-area.cms');
                } else {
                    $state.go('admin-area.statuses');
                }
            }
        });
    },
]);
