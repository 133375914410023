'use strict';

/**
 * Converts variable-esque naming conventions to something presentational, capitalized words separated by space.
 * @param {String} value The value to be parsed and prettified.
 * @param {String} [inflector] The inflector to use. Default: humanize.
 * @return {String}
 * @example {{ 'Here Is my_phoneNumber' | inflector:'humanize' }} => Here Is My Phone Number
 *          {{ 'Here Is my_phoneNumber' | inflector:'underscore' }} => here_is_my_phone_number
 *          {{ 'Here Is my_phoneNumber' | inflector:'variable' }} => hereIsMyPhoneNumber
 */
angular.module('core').filter('inflector', function () {
    function ucwords(text) {
        return text.replace(/^([a-z])|\s+([a-z])/g, function ($1) {
            return $1.toUpperCase();
        });
    }

    function breakup(text, separator) {
        return text.replace(/[A-Z]+([a-z])|\s+([a-z])/g, function (match) {
            return separator + match;
        });
    }

    var inflectors = {
        humanize: function (value) {
            var string = value.split('_').join(' ').toLowerCase();
            var splitString = string.split(' ');
            for (var i = 0; splitString.length > i; i++) {
                splitString[i] = splitString[i].charAt(0).toUpperCase() + splitString[i].slice(1);
            }
            string = splitString.join(' ');
            return string;
        },
        underscore: function (value) {
            return (
                value.substr(0, 1).toLowerCase() +
                breakup(value.substr(1), '_').toLowerCase().split(' ').join('_')
            );
        },
        variable: function (value) {
            value =
                value.substr(0, 1).toLowerCase() +
                ucwords(value.split('_').join(' ')).substr(1).split(' ').join('');
            return value;
        },
    };

    return function (text, inflector, separator) {
        if (inflector !== false && angular.isString(text)) {
            inflector = inflector || 'humanize';
            return inflectors[inflector](text);
        } else {
            return text;
        }
    };
});
