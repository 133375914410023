'use strict';

angular.module('core').service('Messages', [
    '$rootScope',
    '$uibModal',
    '$q',
    '$document',
    '$uibModalStack',
    '$sanitize',
    '$sce',
    'Notifications',
    function ($rootScope, $uibModal, $q, $document, $uibModalStack, $sanitize, $sce, Notifications) {
        var self = this;
        // Register a body reference to use later
        var bodyRef = angular.element($document[0].body);
        var htmlRef = angular.element(document.querySelector('#ng-app'));
        this.modalInstances = [];

        this.openMessage = function (scope, params) {
            var deferred = $q.defer();
            var backdropClass = params.backdropClass || '';
            var windowClass = params.windowClass || '';
            windowClass += ' overflowXHidden';
            //htmlRef.addClass('overflowHidden');
            bodyRef.addClass('positionRelative');
            $rootScope.isBlur = params.blur;
            var backdrop = true;
            if (params.backdrop !== undefined) {
                backdrop = params.backdrop;
            } else if (params.template) {
                // backdrop = 'static';
                backdrop = true;
            }

            params.hideLogo = typeof params.hideLogo === 'boolean' && params.hideLogo === false ? false : true;

            if (params.disableAutoDismiss !== true) {
                return Notifications.add(params.message);
            }

            self.modalInstances.push(
                $uibModal.open({
                    templateUrl: 'modules/core/services/messagesTemplate.html',
                    controller: 'ModalInstanceController',
                    controllerAs: 'messageVM',
                    bindToController: true,
                    size: params.size || 'md',
                    scope: params.scope || $rootScope,
                    windowClass: windowClass,
                    backdrop: backdrop,
                    backdropClass: backdropClass,
                    resolve: {
                        hideHeader: function () {
                            return params.hideHeader || false;
                        },
                        hideFooter: function () {
                            return params.hideFooter || !params.disableAutoDismiss || false;
                        },
                        hideLogo: function () {
                            return params.hideLogo;
                        },
                        hideTitle: function () {
                            return params.hideTitle;
                        },
                        message: function () {
                            // params.message = $sce.trustAsHtml(params.message);
                            return params.message;
                        },
                        template: function () {
                            return params.template;
                        },
                        templateStyle: function () {
                            return params.templateStyle;
                        },
                        templateParams: function () {
                            return params.templateParams;
                        },
                        title: function () {
                            return params.title;
                        },
                        okText: function () {
                            return params.okText || 'OK';
                        },
                        okClass: function () {
                            return params.okClass || 'primary';
                        },
                        cancelText: function () {
                            return params.cancelText || null;
                        },
                        disableAutoDismiss: function () {
                            return params.disableAutoDismiss || false;
                        },
                        alertType: function () {
                            return params.alertType || 'warning';
                        },
                    },
                })
            );

            self.modalInstances[self.modalInstances.length - 1].result.then(
                function (result) {
                    //htmlRef.removeClass('overflowHidden');
                    bodyRef.removeClass('positionRelative');

                    if (params.okFunction) {
                        params.okFunction();
                    }
                    self.modalInstances.splice([self.modalInstances.length - 1], 1);
                    $rootScope.isBlur = false;

                    deferred.resolve('ok');
                },
                function (error) {
                    //htmlRef.removeClass('overflowHidden');
                    bodyRef.removeClass('positionRelative');

                    console.info('Modal dismissed at: ' + new Date());
                    if (params.cancelFunction) {
                        params.cancelFunction(error);
                    }
                    self.modalInstances.splice([self.modalInstances.length - 1], 1);
                    $rootScope.isBlur = false;

                    deferred.resolve('cancel');
                }
            );

            return deferred.promise;
        };

        this.openPasswordChangeModal = function (params) {
            self.modalInstances.push(
                $uibModal.open({
                    templateUrl: 'modules/core/services/messagesChangePasswordTemplate.html',
                    controller: 'MessagesChangePasswordController',
                    controllerAs: 'changePasswordVM',
                    bindToController: true,
                    resolve: {
                        params: function () {
                            return params;
                        },
                    },
                })
            );

            self.modalInstances[self.modalInstances.length - 1].result.finally(function () {
                self.modalInstances.splice([self.modalInstances.length - 1], 1);
            });
        };

        this.openAddRemoveExternalLoginModal = function (params) {
            self.modalInstances.push(
                $uibModal.open({
                    templateUrl: 'modules/core/services/messagesAddRemoveExternalLoginTemplate.html',
                    controller: 'MessagesAddRemoveExternalLoginController',
                    controllerAs: 'vm',
                    bindToController: true,
                    resolve: {
                        params: function () {
                            return params;
                        },
                    },
                })
            );

            self.modalInstances[self.modalInstances.length - 1].result.finally(function () {
                self.modalInstances.splice([self.modalInstances.length - 1], 1);
            });
        };

        this.openTermsModal = function () {
            window.open('/site/terms/', '_blank');
        };

        this.openPrivacyModal = function () {
            // var messageParams = {};
            // messageParams.template = 'modules/core/views/privacy.client.view.html';
            // messageParams.size = 'lg';
            // messageParams.disableAutoDismiss = true;
            // messageParams.okText = 'Close';
            // return self.openMessage(null, messageParams);
            window.open('/site/privacy/', '_blank');
        };

        this.openCookiesModal = function () {
            window.open('/site/cookies/', '_blank');
        };

        this.openDmcaPolicy = function () {
            window.open('/welcome/dmca', '_blank');
        };

        this.ok = function (returnValue) {
            returnValue = returnValue || 'ok';
            //$modalStack.close('ok');
            var currentModal = $uibModalStack.getTop();
            if (currentModal && currentModal.key) {
                currentModal.key.close(returnValue);
            }
            //self.modalInstances[self.modalInstances.length - 1].close('ok');
        };

        this.cancel = function () {
            //$uibModalStack.dismissAll();
            //var currentModal = $uibModalStack.getTop();
            //currentModal.key.close('ok');
            if (self.modalInstances[self.modalInstances.length - 1]) {
                self.modalInstances[self.modalInstances.length - 1].dismiss('cancel');
            }
        };

        //For cases we want to clear all open modals and do not hold them in state context.
        $rootScope.$on('Messages:closeOpenedModals', function () {
            for (var t = self.modalInstances.length; t > 0; t--) {
                self.modalInstances[t - 1].dismiss('cancel');
            }
        });
    },
]);
