'use strict';

angular.module('home').controller('AdminAreaStatusesDisplaysController', [
    'displaysCounts',
    'AdminAreaDataService',
    '$log',
    'Search',
    function (displaysCounts, AdminAreaDataService, $log, Search) {
        var self = this;
        this.displaysCounts = displaysCounts;
        this.Search = Search;

        function setTimeOfCurrentStatus() {
            self.date = new Date();
            self.date.setHours(self.date.getHours() - 1);
        }

        this.init = function () {
            setTimeOfCurrentStatus();
            self.selectDevicesFormObj = {};
            self.selectDevicesFormObj.specificUser = false;
            self.selectDevicesFormObj.since = '730';
            self.selectDevicesFormObj.status = 'active';
        };

        this.specificUserSelected = function (item, model, label) {
            if (item.basicType === 'user' && self.selectDevicesFormObj.specificUser) {
                self.selectDevicesFormObj.specificUserId = item.id;
            }
        };

        this.getReport = function () {
            if (self.selectDevicesFormObj.specificUser) {
                if (!self.selectDevicesFormObj.specificUserId) {
                    self.selectDevicesFormObj.specificUser = false;
                }
            } else {
                self.selectDevicesFormObj.specificUserId = '';
            }
            setTimeOfCurrentStatus();
            AdminAreaDataService.getAssociatedDisplaysReport(
                self.selectDevicesFormObj.status,
                self.selectDevicesFormObj.specificUserId,
                self.selectDevicesFormObj.since
            ).then(
                function (res) {
                    self.devices = res;
                    self.devices.forEach(function (device) {
                        device.id = parseInt(device.id);
                    });
                },
                function (error) {
                    $log.debug(
                        'AdminAreaStatusDevicesController::getReport failed to retrieve devices',
                        error
                    );
                }
            );

            AdminAreaDataService.getGlobalDisplaysCounts().then(function (res) {
                self.displaysCounts = res;
            });
        };

        this.isRedLine = function (status) {
            var styleObject = {};

            if (status === 'offline') {
                styleObject.color = 'red';
            }

            return styleObject;
        };

        // Grid
        this.orderByField = 'belongsTo';
        this.reverseSort = false;
    },
]);
