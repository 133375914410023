'use strict';
/*global NiioPlayer */

angular.module('devices').controller('DevicesAddDeviceController', [
    '$log',
    '$rootScope',
    '$scope',
    '$http',
    '$stateParams',
    '$location',
    '$state',
    'Messages',
    'User',
    'Security',
    'OpenPopupForm',
    function (
        $log,
        $rootScope,
        $scope,
        $http,
        $stateParams,
        $location,
        $state,
        Messages,
        User,
        Security,
        OpenPopupForm
    ) {
        $scope.association = 'init';

        function resetFields() {
            $scope.newDevice = {};
        }

        function onAddDeviceError(error) {
            $scope.association = 'failed';
            $scope.associationFailed = 'please try again';
            if (error && error.messages && error.message.text) {
                $scope.associationFailed = error.message.text;
            }

            if (!$rootScope.fromPlayer) {
                var messageParams = {};
                messageParams.message = 'Failed to associate display: ' + $scope.associationFailed;
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            }

            $log.debug('DevicesAddDeviceController::onAddDeviceError', error);
        }

        function onAddDeviceSuccess(res) {
            resetFields();
            // indication for successful association
            if (res.data && res.data.id) {
                if ($scope.devices) {
                    // if from device management
                    $scope.devices.push(res.data);
                    $scope.sortDevices();
                }

                $scope.association = 'associated';
                if (!$rootScope.fromPlayer) {
                    var messageParams = {};
                    messageParams.message = 'Display successfully associated with your account';
                    Messages.openMessage($scope, messageParams).then(function (modalRes) {
                        $scope.finishProcess(res.data);
                    });
                }
            } else {
                onAddDeviceError(res);
            }
        }

        $scope.finishProcess = function (device) {
            if (!$rootScope.fromPlayer) {
                if (device) {
                    // $scope.selectSingleDevice(device);
                    $scope.$emit('DevicesAddGroupController:add_device', device);
                    if ($scope.fromPair || $scope.fromStandalone) {
                        $state.go('home.devices.device', { id: device.id });
                    }
                } else {
                    // if from device management, return to the selected option from sidebar
                    $scope.selectedDevicesOrGroupsHaveChanged();
                }
            }
        };
        $scope.init = function () {
            $scope.fromPair = $state.current.name === 'pair';
            $scope.fromStandalone = $state.current.name === 'add-device';
            $rootScope.fromIframe = $rootScope.fromIframe || $stateParams?.deviceType === 'tvOS';
            $rootScope.fromTvOS = $location.search()?.deviceType === 'tvOS';

            resetFields();
            // From player this page is a stand-alone so it doesn't have parents
            // to call to these functions from, and they are irrelevant in that context as well
            if (!$rootScope.fromPlayer && !$rootScope.webFromPlayer) {
                if (typeof $scope.unselectDevicesAndGroups === 'function') {
                    $scope.unselectDevicesAndGroups();
                }

                if (typeof $scope.clearSingleSelected === 'function') {
                    $scope.clearSingleSelected();
                }
            }

            if (!$scope.fromPair) {
                Security.status().then(
                    function (res) {
                        $log.debug('DevicesAddDeviceController::init - user is authorized', res);

                        if ($rootScope.fromPlayer || $rootScope.webFromPlayer) {
                            $scope.newDevice.deviceId = $stateParams.deviceId;
                            var secret = $stateParams.secret || $stateParams.shortSecret;
                            $scope.newDevice.deviceCode = secret.substr(0, 4);
                            $scope.newDevice.deviceName = $stateParams.deviceName;
                            $scope.addDevice(); // Automatic Association
                        }
                    },
                    function (error) {
                        $log.debug('DevicesAddDeviceController::init - user is not authorized', error);
                    }
                );
            } else {
                if ($stateParams.clickedAddDevice) {
                    $scope.newDevice.deviceId = $stateParams.deviceId;
                    $scope.newDevice.deviceCode = $stateParams.deviceCode;
                    $scope.newDevice.deviceName = $stateParams.deviceName;
                    delete $state.params.deviceId;
                    $location.search('deviceId', null);
                    delete $state.params.deviceCode;
                    $location.search('deviceCode', null);
                    delete $state.params.deviceName;
                    $location.search('deviceName', null);
                    delete $state.params.clickedAddDevice;
                    $location.search('clickedAddDevice', null);
                    $scope.addDevice();
                }
            }
        };

        $scope.addDevice = function () {
            if (!User.isAuthenticated()) {
                $rootScope.lastStateBeforeLogin = $state.current.name;
                $rootScope.lastStateParamsBeforeLogin = {
                    deviceId: $scope.newDevice.deviceId,
                    deviceCode: $scope.newDevice.deviceCode,
                    deviceName: $scope.newDevice.deviceName,
                    clickedAddDevice: true,
                };
                OpenPopupForm.openMenu('join', $stateParams);
                return;
            }
            $scope.association = 'processing';
            if ($scope.users && $scope.users[0]) {
                $scope.newDevice.userId = $scope.users[0].id;
            }

            //update an existing device with current user's id
            $http.post('/devices/attachDevice', $scope.newDevice).success(onAddDeviceSuccess).error(onAddDeviceError);
        };

        $scope.terminatePlayer = function () {
            NiioPlayer.Terminate();
        };
    },
]);
