'use strict';

// Setting up route
angular.module('core').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/');

        $stateProvider
            //.state('about', {
            //    url: '/about',
            //    templateUrl: 'modules/core/views/about.client.view.html'
            //})

            //.state('terms', {
            //    url: '/terms',
            //    onEnter: ['$window', function ($window) {
            //        $window.open('/site/terms/', '_self');
            //    }]
            //})
            //
            //.state('privacy', {
            //    url: '/privacy',
            //    onEnter: ['$window', function ($window) {
            //        $window.open('/site/privacy/', '_self');
            //    }]
            //})

            .state('cookies', {
                url: '/cookies',
                templateUrl: 'modules/core/views/cookies.client.view.html',
            })

            .state('delete-account', {
                url: '/delete-account',
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('app-need-upgrade', {
                url: '/app-need-upgrade?fromIframe&provider',
                templateUrl: 'modules/core/views/app-need-upgrade.client.view.html',
            })

            .state('download-app', {
                url: '/download-app',
                templateUrl: 'modules/core/views/download-app.client.view.html',
                controller: 'DownloadAppController',
                data: {
                    security: {
                        secured: true,
                    },
                    //fromMobile: {
                    //    allowed: ['Android']
                    //}
                },
            });
    },
]);
