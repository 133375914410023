'use strict';

angular.module('admin-area').controller('AdminAreaServicesPushController', [
    '$scope',
    '$http',
    '$log',
    '$filter',
    'Messages',
    'OpenPopupForm',
    'FormValidationService',
    '_',
    function ($scope, $http, $log, $filter, Messages, OpenPopupForm, FormValidationService, _) {
        $scope.FormValidationService = FormValidationService;
        $scope.report = $scope.params ? $scope.params.report : {};
        $scope.toggles = $scope.toggles || {};
        $scope.formattedToday = $filter('date')(new Date(), 'ddMMyyyy-HHmmss');
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.countries = _.uniq(_.values(FormValidationService.countries));

        // Grid
        $scope.orderByField = 'id';
        $scope.reverseSort = true;

        $scope.reportOrderByField = 'artwork_id';
        $scope.reportReverseSort = false;

        $scope.itemsPerPageOptions = [10, 25, 50, 100, 250, 500, 1000];
        $scope.pushTypes = [
            { key: 'content', display: 'Content', subType: 'contentType' },
            { key: 'browser', display: 'Browser', subType: 'url' },
            { key: 'editorial', display: 'Editorial', subType: 'url' },
        ];
        $scope.pushContentTypes = [
            { key: 'artwork', display: 'Artwork' },
            { key: 'artcast', display: 'Artcast' },
            { key: 'catalog', display: 'Catalog' },
            { key: 'member', display: 'Member' },
        ];

        $scope.timeFilterAvailable = [
            { key: -1, value: 'All' },
            { key: 7, value: 'Last Week', default: true },
            { key: 14, value: 'Last 2 Weeks' },
            { key: 31, value: 'Last Month' },
        ];
        $scope.timeFilter = _.find($scope.timeFilterAvailable, function (time) {
            return time.default;
        });

        // Pagination
        $scope.pushNotificationsPagination = {
            itemsPerPage: 10,
            currentPage: 1,
            maxSize: 5,
            totalItems: 11,
            isRequire: function () {
                return true;
                // return $scope.pushNotificationsPagination.itemsPerPage < $scope.pushNotificationsPagination.totalItems;
            },
            setPage: function (pageNo) {
                $scope.pushNotificationsPagination.currentPage = pageNo;
            },
        };

        $scope.rerunArtworksPagination = {
            itemsPerPage: 10,
            currentPage: 1,
            maxSize: 5,
            totalItems: 11,
            isRequire: function () {
                // return $scope.rerunArtworksPagination.itemsPerPage < $scope.rerunArtworksPagination.totalItems;
                return true;
            },
            setPage: function (pageNo) {
                $scope.rerunArtworksPagination.currentPage = pageNo;
            },
        };

        $scope.rerunArtworksTasksPagination = {
            itemsPerPage: 10,
            currentPage: 1,
            maxSize: 5,
            totalItems: 11,
            isRequire: function () {
                // return $scope.rerunArtworksTasksPagination.itemsPerPage < $scope.rerunArtworksTasksPagination.totalItems;
                return true;
            },
            setPage: function (pageNo) {
                $scope.rerunArtworksTasksPagination.currentPage = pageNo;
            },
        };

        $scope.typeChanged = () => {
            const type = $scope.newPushNotification.data.type;
            if (type === 'content') {
                delete $scope.newPushNotification.data['url'];
                $scope.newPushNotification.data['contentType'] = $scope.pushContentTypes[0]['key'];
                $scope.newPushNotification.data['id'] = null;
            } else if (type === 'browser' || type === 'editorial') {
                delete $scope.newPushNotification.data['contentType'];
                delete $scope.newPushNotification.data['id'];
                $scope.newPushNotification.data['url'] = '';
            }
        };

        function createNewPushNotification() {
            $scope.newPushNotification = {};
            $scope.newPushNotification.countries = [];
            $scope.newPushNotification.data = {};
            $scope.newPushNotification.data.type = $scope.pushTypes[0]['key'];
            $scope.typeChanged();
            if ($scope.newPushForm && $scope.newPushForm.submitDirty) {
                $scope.newPushForm.submitDirty = false;
                $scope.newPushForm.$setPristine();
            }
        }

        $scope.loadPushNotification = function (pushNotification) {
            createNewPushNotification();
            $scope.newPushNotification.title = pushNotification.title;
            $scope.newPushNotification.body = pushNotification.body;
            $scope.newPushNotification.data = pushNotification.data;
            $scope.newPushNotification = { ...$scope.newPushNotification, ...pushNotification.input };
            let messageParams = {};
            messageParams.title = 'Successfully Loaded Push Notification';
            messageParams.message =
                'Push notification was loaded successfully:\n' + JSON.stringify($scope.newPushNotification, null, 2);
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($scope, messageParams);
        };

        $scope.getPushNotifications = function () {
            $scope.toggles.spinRefresh = true;
            let data = {
                lastXDays: $scope.timeFilter.key,
            };

            $http.post('/admin/getPushNotifications', data).then(
                function (res) {
                    let input_json;
                    $log.debug(
                        'AdminAreaServicesPushController::getPushNotifications Success retrieving push notifications',
                        res
                    );
                    $scope.toggles.spinRefresh = false;
                    // for eachj push notification
                    _.each(res.data.push_notifications, function (push) {
                        push.id = parseInt(push.id);
                        _.each(push.input, function (value, key) {
                            if (_.isEmpty(value)) {
                                delete push.input[key];
                            }
                        });
                    });

                    $scope.pushNotifications = res.data.push_notifications || [];
                    $scope.pushNotificationsPagination.totalItems = $scope.pushNotifications.length;
                },
                function (error) {
                    $log.debug(
                        'AdminAreaServicesPushController::getRerunList Error retrieving codes engineReruns/getAll',
                        error
                    );
                    $scope.toggles.spinRefresh = false;
                }
            );
        };

        $scope.init = function () {
            $scope.submitWasClicked = false;
            createNewPushNotification();
            $scope.getPushNotifications();
        };

        $scope.contentTypeChanged = function () {
            delete $scope.newPushNotification.data.id;
            delete $scope.newPushNotification.data.memberUrl;
        };

        $scope.$watchGroup(
            ['newPushNotification.data.id', 'newPushNotification.data.memberUrl'],
            (newValue, oldValue) => {
                let contentType = _.find($scope.pushContentTypes, function (contentType) {
                    return contentType.key === $scope.newPushNotification.data.contentType;
                });

                let newId = null;
                if (newValue[0] !== undefined) {
                    newId = newValue[0];
                } else if (newValue[1] !== undefined) {
                    newId = newValue[1];
                }

                $scope.contentTypeTitle = contentType.display + ' ID';
                $scope.contentTypeTitle += newId ? ' (' + newId + ')' : ' (empty)';
            }
        );

        $scope.sendNewPushNotification = function () {
            let messageParams = {};
            $scope.submitWasClicked = true;

            messageParams = {};
            messageParams.message =
                'You are about to send a new push notification ' +
                'using these parameters: \n' +
                JSON.stringify($scope.newPushNotification, null, 2);
            messageParams.title = 'Send Push Notification';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Send';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $http.post('/admin/sendPushNotification', $scope.newPushNotification).then(
                        function (res) {
                            $log.debug(
                                'AdminAreaServicesPushController::sendNewPushNotification Success in admin/sendNewPushNotification',
                                { res: res, data: $scope.newPushNotification }
                            );
                            $scope.submitWasClicked = false;
                            $scope.getPushNotifications();
                            createNewPushNotification();
                            $scope.toggles = {};
                            let messageParams = {};
                            messageParams.title = 'Successfully Sent Push Notification';
                            messageParams.message = 'Push notification was sent:\n' + JSON.stringify(res.data, null, 2);
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        },
                        function (error) {
                            $log.debug(
                                'AdminAreaServicesPushController::sendNewPushNotification Failed in admin/sendNewPushNotification',
                                { error: error, data: $scope.newPushNotification }
                            );
                            $scope.submitWasClicked = false;
                            let messageParams = {};
                            messageParams.message = 'Failed to Sent Push notification.\n';
                            messageParams.message +=
                                error && error.data && error.data.message && error.data.message.text
                                    ? error.data.message.text
                                    : '';
                            messageParams.title = 'Failed To send Push Notification';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });

            // This popup should appear first
            if ($scope.newPushNotification.allUsers || _.isEmpty($scope.newPushNotification)) {
                messageParams = {};
                messageParams.message =
                    '<div class="alert alert-danger" role="alert">You are about to send a push notification ' +
                    'with empty parameters or to all users!\nThis means that the Push notification will be run on ALL REMOTES!';
                messageParams.message += "\n\nAre you sure you know what you're doing?</div>";
                messageParams.title = 'Send Push Notification with Empty Parameters';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'No';
                messageParams.okText = 'Yes';
                messageParams.okClass = 'danger';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res !== 'ok') {
                        // No
                        Messages.cancel();
                    }
                });
            }
        };

        $scope.getTasks = function (pushNotification, index, report, refreshReport) {
            let data = {
                push_notification_id: pushNotification.id,
            };
            if (refreshReport) {
                $scope.toggles.spinRefresh = true;
            }

            $http
                .post('/admin/getPushNotificationRemotes', data)
                .then(
                    function (res) {
                        $log.debug('AdminAreaServicesPushController::getTasks Success in engineReruns/getTasks', {
                            res: res,
                            data: data,
                        });
                        if (!refreshReport) {
                            $scope.openPushRemotes(report);
                        }
                    },
                    function (error) {
                        $log.debug('AdminAreaServicesPushController::getTasks Error in engineReruns/getTasks', {
                            error: error,
                            data: data,
                        });
                        let messageParams = {};
                        messageParams.message = 'Resume failed, please try again.';
                        messageParams.title = 'Failed To Resume Rerun';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    }
                )
                .finally(function () {
                    if (refreshReport) {
                        $scope.toggles.spinRefresh = false;
                    }
                });
        };

        $scope.openPushRemotes = function (report) {
            let params = { pushNotifications: report };

            let messageParams = {};
            messageParams.size = 'lg';
            messageParams.title = 'Report (' + report.rerun.status + ')';
            messageParams.disableAutoDismiss = true;
            messageParams.template = 'modules/admin-area/views/admin-area.services.push.report.client.view.html';
            messageParams.templateParams = params;
            // messageParams.templateStyle = {'max-height': '600px'};
            Messages.openMessage($scope, messageParams);
            //$state.go('.report');
        };
    },
]);
