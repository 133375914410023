'use strict';

angular.module('discover').controller('DiscoverMembersController', [
    '$scope',
    '$state',
    '$timeout',
    '$window',
    'DiscoverDataService',
    'User',
    '_',
    function ($scope, $state, $timeout, $window, DiscoverDataService, User, _) {
        $scope.$state = $state;
        $scope.hideSidebar = false; /*!User.isAdmin();*/
        $scope.isAdmin = User.isAdmin();
        $scope.isProCurator = User.isProCurator();
        $scope.sidebarItems = [
            {
                name: 'All',
                link: 'all',
            },
            {
                name: 'Featured',
                link: 'featured',
            },
            {
                name: 'Artists',
                link: 'artists',
            },
        ];
        if ($scope.isAdmin || $scope.isProCurator) {
            $scope.sidebarItems.splice(1, 0, {
                name: 'Verified as Official',
                link: 'official',
            });
            $scope.sidebarItems.push(
                {
                    name: 'Collectors',
                    link: 'collectors',
                },
                {
                    name: 'Galleries',
                    link: 'galleries',
                },
                {
                    name: 'Curators',
                    link: 'curators',
                },
                {
                    name: 'Art Fans',
                    link: 'art_fans',
                },
                {
                    name: 'Art Event',
                    link: 'art_event',
                },
                {
                    name: 'Museums',
                    link: 'museums',
                },
                {
                    name: 'Archives',
                    link: 'archives',
                },
                {
                    name: 'Academies',
                    link: 'academies',
                },
                {
                    name: 'Institutions',
                    link: 'institutions',
                },
                {
                    name: 'Public Installation',
                    link: 'installations',
                },
                {
                    name: 'Other Organizations',
                    link: 'other_organizations',
                }
            );
        }

        $scope.fakeSelection = 'featured';
        $scope.currentSelection = {};
        $scope.$watch('fakeSelection', (newValue, oldValue) => {
            $scope.currentSelection = $scope.sidebarItems.find((item) => item.link === newValue);
            console.debug('[debug] -> sidebarSelection', $scope.sidebarSelection);
        });

        $scope.selectedSection = function (sidebarSelection) {
            DiscoverDataService.setCachedUsers(null);
            $state.go('discover.members.main', { sidebarSelection: sidebarSelection });
        };

        $scope.clearMembersFilter = () => {
            DiscoverDataService.setCachedUsers(null);
            $state.go('discover.members.main', { sidebarSelection: 'all' });
        };

        if ($scope.isAdmin || $scope.isProCurator) {
            DiscoverDataService.getUsersCount().then(function (results) {
                $scope.count = results;
            });
        }
        // function countMembers(users) {
        //     var count = _.countBy(users, function(user) {
        //         if (user.titles.indexOf('1') > -1) {return 'artists'; }
        //         if (user.titles.indexOf('2') > -1) {return 'collectors'; }
        //         if (user.titles.indexOf('3') > -1) {return 'curators'; }
        //         if (user.titles.indexOf('4') > -1) {return 'art_fans'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'GA') {return 'galleries'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'AE') {return 'art_event'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'MU') {return 'museums'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'AR') {return 'archives'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'AC') {return 'academies'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'IN') {return 'institutions'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'HO') {return 'installations'; }
        //         if (user.titles.indexOf('5') > -1 && user.organization_type === 'OT' && user.organization_type_other) {return 'other_organizations'; }
        //     });
        //
        //     count.featured = users.filter(function(user) { return user.featured; }).length;
        //     count.all = users.length;
        //     return count;
        // }
        $scope.$on('DiscoverMembersMainController:members_name_changed', function (event, sidebarSelection) {
            if (sidebarSelection) {
                $scope.fakeSelection = sidebarSelection;
            }
        });
    },
]);
