'use strict';

angular.module('core').filter('daysOfWeek', [
    '$filter',
    function ($filter) {
        const dayNames = {
            1: 'Mon',
            2: 'Tue',
            3: 'Wed',
            4: 'Thu',
            5: 'Fri',
            6: 'Sat',
            7: 'Sun',
        };

        return function (daysArray) {
            return daysArray.map((day) => dayNames[day]).join(', ');
        };
    },
]);
