'use strict';

angular.module('channel-info').service('ChannelInfoService', [
    '$log',
    '$http',
    '$q',
    '$filter',
    '$window',
    '$rootScope',
    '$state',
    'PlatformManager',
    'User',
    'ChannelService',
    'Messages',
    function (
        $log,
        $http,
        $q,
        $filter,
        $window,
        $rootScope,
        $state,
        PlatformManager,
        User,
        ChannelService,
        Messages
    ) {
        var self = this;

        this.channelSharedPassword = '';

        this.getChannel = function (channelUrlName, publishUniqueSeq, channelSharedPassword) {
            var deferred = $q.defer();

            var params = {
                publishUniqueSeq,
                password: channelSharedPassword,
            };

            ChannelService.fetchChannelDataByUrlName(channelUrlName, params).then(
                (res) => {
                    var channelData = res.data.data;
                    deferred.resolve(channelData);
                },
                (error) => {
                    Messages.openMessage($rootScope, {
                        message: 'The artwork is hidden. Please contact artist or gallery',
                    }).then(() => {
                        if (window.history && window.history.length > 2) {
                            Messages.ok();
                            window.history.back();
                        } else {
                            Messages.ok();
                            $state.go('home');
                        }
                    });
                    var text =
                        error && error.data && error.data.message
                            ? error.data.message.text
                            : "Channel doesn't exist";
                    deferred.reject(text);
                }
            );

            return deferred.promise;
        };
    },
]);
