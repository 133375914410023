'use strict';

angular.module('go-back').directive('goBack', [
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                profileUrl: '=?',
                fromArtwork: '=?',
                fromChannel: '=?',
                forceShow: '@?',
                buttonText: '@?',
                showArrow: '=?',
            },
            templateUrl: 'modules/go-back/views/go-back.client.view.html',
            controller: 'GoBackController',
            controllerAs: 'vm',
            bindToController: true, //required in 1.3+ with controllerAs
        };
    },
]);
