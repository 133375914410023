'use strict';

angular.module('admin-area').directive('adminUtilsPanel', [
    '$window',
    '$state',
    '$timeout',
    'Search',
    'Messages',
    'HomeDataService',
    '$log',
    'User',
    function ($window, $state, $timeout, Search, Messages, HomeDataService, $log, User) {
        return {
            restrict: 'E',
            scope: {
                type: '=',
                subType: '=?',
                selectOnly: '=?',
                onSelect: '=?',
                onSelectText: '=?',
                clearAfterOnSelect: '=?',
                selectedId: '=?',
                selected: '=?',
                limit: '=?',
                title: '=',
                placeholder: '=?',
                params: '=?',
            },
            link: function (scope, element, attrs) {
                var self = scope;

                scope.Search = Search;
                scope.HomeDataService = HomeDataService;
                scope.hideAdminPanelDelete = User.isProCurator();
                scope.adminUtilsCtrl = {
                    item: {
                        name: '',
                        id: undefined,
                        selected: undefined,
                        entity: undefined,
                        clear: function () {
                            this.id = undefined;
                            this.name = '';
                            this.selected = undefined;
                            this.entity = undefined;
                        },
                    },
                    specificItemSelected: function (item, model, label, tmpAWObject) {
                        if (item.basicType === self.type) {
                            tmpAWObject.id = item.id;
                            tmpAWObject.selected = item;
                            tmpAWObject.entity = undefined;

                            if (self.selectOnly) {
                                self.adminUtilsCtrl.getEntityData();
                            }
                        } else {
                            tmpAWObject.clear();
                        }
                    },
                    clear: function () {
                        if (self.adminUtilsCtrl.item.id !== undefined) {
                            self.adminUtilsCtrl.item.clear();
                        }
                        if (self.selectedId) {
                            self.selectedId = null;
                        }
                        if (self.selected) {
                            self.selected = null;
                        }
                    },
                    doSearch: function (q) {
                        if (self.type === 'artwork') {
                            return self.Search.searchArtworks(self.adminUtilsCtrl.item.name, self.limit);
                        } else if (self.type === 'user') {
                            return self.Search.searchUsers(self.adminUtilsCtrl.item.name, self.limit);
                        } else if (self.type === 'channel') {
                            return self.Search.searchChannels(self.adminUtilsCtrl.item.name, self.limit, self.subType);
                        } else if (self.type === 'device') {
                            return self.Search.searchDevices(self.adminUtilsCtrl.item.name, self.params);
                        } else {
                            return [];
                        }
                    },
                    getEntityData: function () {
                        var df;
                        if (self.type === 'artwork') {
                            df = self.HomeDataService.getArtwork(
                                self.adminUtilsCtrl.item.selected.id,
                                self.adminUtilsCtrl.item.selected.name
                            ).then(
                                function (aw) {
                                    self.selectedId = aw.id;
                                    self.selected = aw;
                                    return aw;
                                },
                                function (error) {
                                    $log.debug('getArtwork failed for id:' + self.adminUtilsCtrl.item.selected.id);
                                }
                            );
                        } else if (self.type === 'user') {
                            df = self.HomeDataService.getUserDetailsByUrl(self.adminUtilsCtrl.item.selected.url).then(
                                function (user) {
                                    self.selectedId = user.url;
                                    self.selected = user;
                                    return user;
                                },
                                function (error) {
                                    $log.debug(
                                        'getUserDetailsByUrl failed for url:' + self.adminUtilsCtrl.item.selected.url
                                    );
                                }
                            );
                        } else if (self.type === 'channel') {
                            df = self.HomeDataService.getChannelData(self.adminUtilsCtrl.item.selected.id, false).then(
                                function (channel) {
                                    self.selectedId = channel.id;
                                    self.selected = channel;
                                    return channel;
                                },
                                function (error) {
                                    $log.debug(
                                        'getUserDetailsByUrl failed for url:' + self.adminUtilsCtrl.item.selected.url
                                    );
                                }
                            );
                        } else if (self.type === 'device') {
                            self.selectedId = self.adminUtilsCtrl.item.selected.id;
                            self.selected = self.adminUtilsCtrl.item.selected;
                            // df = self.HomeDataService.getChannelData(self.adminUtilsCtrl.item.selected.id, false).then(
                            //     function (channel) {
                            //         return channel;
                            //     },
                            //     function (error) {
                            //         $log.debug(
                            //             'getUserDetailsByUrl failed for url:' + self.adminUtilsCtrl.item.selected.url
                            //         );
                            //     }
                            // );
                        }

                        if (df !== undefined) {
                            self.adminUtilsCtrl.item.entity = undefined;

                            df.then(
                                function (data) {
                                    self.adminUtilsCtrl.item.entity = data;
                                },
                                function (err) {
                                    //TODO: display error.
                                }
                            );
                        }
                    },
                    onSelectPressed: function () {
                        if (self.onSelect) {
                            self.onSelect(self.adminUtilsCtrl.item);
                            if (self.clearAfterOnSelect) {
                                self.adminUtilsCtrl.clear();
                            }
                        }
                    },
                    onDone: function () {
                        self.adminUtilsCtrl.getEntityData();
                    },
                    goToEntity: function () {
                        if (self.type === 'artwork') {
                            const artworkLink = $state.href('discover.artworks.artwork', {
                                artworksName: self.adminUtilsCtrl.item.name,
                                artworkId: self.adminUtilsCtrl.item.id,
                            });
                            return artworkLink;
                        } else if (self.type === 'user') {
                            const userLink = $state.href('profile', {
                                url: self.adminUtilsCtrl.item.entity.url,
                            });
                            return userLink;
                        } else if (self.type === 'channel') {
                            const channelLink = $state.href('discover.channels.channel', {
                                channelsName: self.adminUtilsCtrl.item.name,
                                channelUrlName: self.adminUtilsCtrl.item.entity.url_name,
                            });
                            return channelLink;
                        }
                    },
                };

                //scope.handleAction = function (type, id, actionName, value) {
                //    AdminManager.handleAdminAction(type, id, actionName, value)
                //        .then(function (res) {
                //            if (actionName === 'rating' || actionName === 'order') {
                //                $window.location.reload(true);
                //            }
                //        });
                //};
            },
            templateUrl: 'modules/admin-area/directives/admin-area.utils-panel.html',
        };
    },
]);
