'use strict';

angular.module('admin-area').controller('InvitationUsersController', [
    '$state',
    '$scope',
    'Messages',
    '_',
    'NIIO_URL',
    function ($state, $scope, Messages, _, NIIO_URL) {
        var self = this;
        this.Messages = Messages;

        function prepareTable() {
            self.rows = [];
            // PRE_APPROVED_USERS - pre approved
            if (self.code.pre_approved_users && self.code.pre_approved_users.length > 0) {
                self.code.pre_approved_users.forEach(function (pre_approved_user) {
                    self.rows.push({ pre_approved_email: pre_approved_user.email });
                });
            }
            // USERS - accepted accounts
            if (self.code.users && self.code.users.length > 0) {
                var preApproved;
                self.code.users.forEach(function (used_user) {
                    preApproved = _.find(self.rows, function (row) {
                        return row.pre_approved_email === used_user.email;
                    });
                    if (preApproved) {
                        preApproved.used_email = used_user.email;
                        preApproved.used_display_name = used_user.display_name;
                        preApproved.used_url = used_user.url;
                    } else {
                        self.rows.push({
                            used_email: used_user.email,
                            used_display_name: used_user.display_name,
                            used_url: used_user.url,
                        });
                    }
                });
            }
        }

        this.init = function () {
            self.code = self.params && self.params.code ? self.params.code : {};
            prepareTable();
        };

        this.getHeaderForCSV = function () {
            return ['Pre Approved', 'Used By - Name', 'Used By - URL'];
        };

        this.exportCSV = function () {
            var array = [];
            self.rows.forEach(function (row) {
                array.push({
                    a: row.pre_approved_email,
                    b: row.used_display_name,
                    c: row.used_url ? NIIO_URL + '/' + row.used_url : '',
                });
            });
            return array;
        };

        $scope.$on('$stateChangeStart', function () {
            Messages.ok();
        });
    },
]);
