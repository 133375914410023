'use strict';

angular.module('core').controller('PasswordProtectPopupController', [
    'PasswordProtectLink',
    '$rootScope',
    '$scope',
    'Messages',
    function (PasswordProtectLink, $rootScope, $scope, Messages) {
        var self = this;
        this.PasswordProtectLink = PasswordProtectLink;
        this.Messages = Messages;

        this.init = function () {
            $rootScope.blurContent = true;
            self.password = '';
            // PasswordProtectLink.setPassword('');
        };

        this.setPassword = function () {
            PasswordProtectLink.setPassword(self.password);
        };

        $scope.$on('$destroy', function () {
            $rootScope.blurContent = false;
        });
    },
]);
