'use strict';

angular.module('core').constant('ORGANIZATION_TYPES', {
    GA: 'Gallery',
    MU: 'Museum',
    AR: 'Archive',
    AC: 'Academy',
    IN: 'Institution',
    HO: 'Public Installation',
    AE: 'Art Event',
    OT: 'Other',
});
