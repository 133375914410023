'use strict';

angular.module('admin-area').controller('AdminAreaEventsSelectedSettingsController', [
    '$rootScope',
    '$scope',
    '$http',
    '$q',
    'HomeDataService',
    'Messages',
    'EVENT_TYPES',
    '_',
    function ($rootScope, $scope, $http, $q, HomeDataService, Messages, EVENT_TYPES, _) {
        var self = this;
        this.EVENT_TYPES = EVENT_TYPES;
        this.currencies = HomeDataService.enums.currencies;

        this.updateSettings = function () {
            $scope.eventCtrl.saveCurrentEventSettings($scope.currentEvent);
        };

        this.getTypeDefaults = function (event, typeId) {
            var deferred = $q.defer();
            var data = {
                typeId: typeId,
            };

            $http.post('/events/getTypeDefaults', data).then(
                function (res) {
                    var defaults = res.data.type;
                    var newKey;
                    //refresh
                    _.each(defaults, function (parameter, key) {
                        newKey = key.replace('default_', '');
                        if (event.hasOwnProperty(newKey)) {
                            event[newKey] = parameter;
                        }
                    });

                    var messageParams = {};
                    messageParams.message = 'Type defaults were changed';
                    Messages.openMessage($scope, messageParams);

                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.init = function () {
            $rootScope.$broadcast('admin_event_selectedTab', 'settings');
        };
    },
]);
