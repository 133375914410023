'use strict';

//Authentication service for user variables
angular.module('users').service('Security', [
    '$log',
    '$rootScope',
    '$location',
    '$state',
    '$stateParams',
    '$http',
    '$q',
    'User',
    'OpenPopupForm',
    'Messages',
    'HostIframeSdk',
    '$timeout',
    function (
        $log,
        $rootScope,
        $location,
        $state,
        $stateParams,
        $http,
        $q,
        User,
        OpenPopupForm,
        Messages,
        HostIframeSdk,
        $timeout
    ) {
        var self = this;
        let walletHandled = false;

        // Private functions
        function wrapUser(user) {
            // Temp solution
            //            user.invitation = 'n110stream,cl11';

            return { User: user };
        }

        // Check the sessions status
        this.status = function () {
            var deferred = $q.defer();

            $http
                .get('/users/status')
                .success(function (res) {
                    if (res.data) {
                        User.setUserDetails(res.data);
                        $log.debug('Success in setting user details after status check', res.data);
                        deferred.resolve();
                    } else {
                        $log.debug('Error in setting user details after status check');
                        deferred.reject();
                    }
                })
                .error(function (error) {
                    $log.debug('Error in status check', error);
                    deferred.reject();
                });

            return deferred.promise;
        };

        function handleDefaultAfterLogin() {
            if ($rootScope.backFromPaymentBool) {
                $rootScope.backFromPayment();
                delete $rootScope.backFromPaymentBool;
            }

            // If coming from modal -for example artwork info open from channel, do not change state
            //   All the $stateParams got reset and it's not correct.
            if (
                !$stateParams.fromModal &&
                !(
                    $state.current &&
                    ($state.current.name === 'discover.artwork' || $state.current.name === 'discover.channel')
                )
            ) {
                if (!$rootScope.lastStateBeforeLogin) {
                    if (User.isArtist() || User.isGallery()) {
                        $location.path('home');
                    } else {
                        $state.go('discover.for-you');
                    }
                    //                $state.go('home');
                } else {
                    $state.go($rootScope.lastStateBeforeLogin, $rootScope.lastStateParamsBeforeLogin);
                }
            }
        }

        function executeSuccessFunctionAfterLogin(successFunction) {
            if (typeof successFunction === 'function') {
                var result = successFunction().then(function (res) {
                    if (res === 'default') {
                        handleDefaultAfterLogin();
                    } else if (typeof res === 'function') {
                        res().then(
                            function (res) {
                                // location.reload();
                                handleDefaultAfterLogin();
                            },
                            function (err) {
                                handleDefaultAfterLogin();
                            }
                        );
                    }
                });
            } else {
                handleDefaultAfterLogin();
            }
        }

        function openArtistOrArtfanPopup() {
            // let url = 'https://app.develop.niio.dev/site/artfan-or-artists-and-galleries';
            let url = 'https://www.niio.com/site/artfan-or-artists-and-galleries';
            // let url = 'https://app.pre.niio.dev/site/artfan-or-artists-and-galleries';
            // let params = { size: 'wallet', hideHeader: true };
            let params = { size: 'wide', hideTitle: true, hideLogo: true };

            window.closeIFrame = () => {
                Messages.cancel();
            };

            // link in elementor: javascript:parent.window.closeIFrame();

            OpenPopupForm.openMenu('niio-sdk', {
                ...params,
                type: 'modal',
                // scope: this,
                url: `${url}`,
            });

            $timeout(() => {
                const iframe = document.getElementById('niioSdkPortal');
                let iframeHeight = '39.5vh';
                // iframe.style.maxHeight = iframeHeight;
                iframe.style.height = iframeHeight;

                iframe.onload = function () {
                    // console.log('iFrame has finished loading');
                    iframeHeight =
                        iframe.contentWindow.document.documentElement.scrollHeight ||
                        iframe.contentWindow.document.body.scrollHeight;
                    if (iframeHeight) {
                        // iframe.style.maxHeight = '39.5vh';
                        iframe.style.height = `${iframeHeight}px`;
                    }
                };
            }, 100);
        }

        async function afterLoginFlow(successFunction, source) {
            executeSuccessFunctionAfterLogin(successFunction);
            const successFunctionAfterLoginRes = await successFunction();
            if (successFunctionAfterLoginRes === 'default' && source === 'join') {
                // Don't open plans from TVOS
                if (!$rootScope.fromTvOS) {
                    OpenPopupForm.openMenu('plans', {
                        fromModal: true,
                        fromJoin: true,
                    }).then(function (res) {
                        openArtistOrArtfanPopup();
                    });
                }
            }

            $rootScope.$broadcast('Auth:Succeed');
        }

        this.login = function (user, successFunction, errorFunction, source) {
            $rootScope.duringLogin = true;

            $http.post('/users/signIn', wrapUser(user)).then(
                function (res) {
                    if (res.data) {
                        User.setUserDetails(res.data.data); // Update the session
                        $rootScope.$emit('InitServices');
                        $rootScope.duringLogin = false;
                        $log.debug(res.data);
                        afterLoginFlow(successFunction, source);
                    }
                },
                function (error) {
                    $rootScope.duringLogin = false;
                    if (typeof errorFunction === 'function') {
                        errorFunction(error);
                    }
                }
            );
        };

        this.loginExternal = function (
            provider,
            code,
            providerData,
            successFunction,
            errorFunction,
            source,
            joinSuccessFunction
        ) {
            $rootScope.duringLogin = true;

            var data = {
                provider: provider,
                code: code,
                action: 'login',
            };
            if (providerData) {
                data.oid = providerData.oid;
            }
            if (joinSuccessFunction) {
                data.redirect = true; // redirect to join after fail login (continuous login)
            }

            $http
                .post('/users/externalSignIn?code=' + code, data)
                .success(function (res) {
                    if (res.data) {
                        if (res.data.profile) {
                            // redirect to join response
                            joinSuccessFunction(res.data, provider);
                        } else {
                            User.setUserDetails(res.data); // Update the session
                            $rootScope.$emit('InitServices');
                            $rootScope.duringLogin = false;
                            $log.debug(res.data);
                            afterLoginFlow(successFunction, source);
                        }
                    }
                })
                .error(function (error, status, headers, config) {
                    $rootScope.duringLogin = false;
                    error.status = status;
                    if (typeof errorFunction === 'function') {
                        errorFunction(error);
                    }
                });
        };

        this.getExternalProfile = function (provider, code, appleProfile, providerData) {
            var deferred = $q.defer();

            var data = {
                provider: provider,
                code: code,
                action: 'profile',
            };
            if (appleProfile) {
                // from failed Apple login - need to send back the profile (Apple can't auth twice with same token)
                data.profile = appleProfile;
            }

            if (providerData) {
                data.firstName = providerData.firstName;
                data.lastName = providerData.lastName;
                data.oid = providerData.oid;
            }

            $http
                .post('/users/externalSignIn?code=' + code, data)
                .success(function (res) {
                    $log.debug(res.data);
                    deferred.resolve(res.data);
                })
                .error(function (error, status, headers, config) {
                    error.status = status;
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        this.deviceLogin = function (deviceId, deviceCode) {
            var deferred = $q.defer();

            if (deviceId && deviceCode) {
                var data = {
                    deviceId: deviceId,
                    deviceCode: deviceCode,
                };

                $rootScope.duringDeviceLogin = true;

                $http
                    .post('/users/loginByDevice', data)
                    .success(function (res) {
                        $log.debug('Security::deviceLogin Success', res, data);
                        $rootScope.duringDeviceLogin = false;

                        if (res.data) {
                            User.setUserDetails(res.data);
                            deferred.resolve(res);
                        } else {
                            deferred.reject();
                        }
                    })
                    .error(function (error) {
                        $rootScope.duringDeviceLogin = false;
                        $log.debug('Security::deviceLogin Failure', error, data);
                        deferred.reject(error.message.text);
                    });
            } else {
                deferred.reject('empty device id and code');
            }

            return deferred.promise;
        };

        this.logout = function (success, error) {
            User.clearUser().then(
                function (res) {
                    $http
                        .get('/users/signOut')
                        .success(function (res) {
                            success();
                            $rootScope.$emit('ClearData');
                        })
                        .error(error);
                },
                function (error) {
                    $http
                        .get('/users/signOut')
                        .success(function (res) {
                            success();
                        })
                        .error(error);
                }
            ); // Clear the session
        };

        this.signup = function (user, success, error) {
            $http
                .post('/users/signUp', wrapUser(user))
                .success(function (res) {
                    // User.setUserDetails(res.data); // Update the session
                    // $log.debug(res.data);
                    var result = success(res);
                    $stateParams.o = '';
                    if (typeof result === 'function') {
                        result().finally(function () {
                            location.reload();
                        });
                    }
                })
                .error(error);
        };

        this.sendInvitation = function (user, successCallback, errorCallback) {
            $http
                .post('/invitations/addInvitation', { Invitation: user })
                .success(function (res) {
                    if (typeof successCallback === 'function') {
                        successCallback();
                    }
                })
                .error(function (error) {
                    if (typeof errorCallback === 'function') {
                        errorCallback();
                    }
                });
        };
    },
]);
