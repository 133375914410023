'use strict';

angular.module('core').service('Search', [
    '$http',
    '$q',
    '$filter',
    '$stateParams',
    '_',
    'User',
    'CHANNEL_TYPES',
    'LocalConfig',
    'USER_TITLES',
    'ORGANIZATION_TYPES',
    function ($http, $q, $filter, $stateParams, _, User, CHANNEL_TYPES, LocalConfig, USER_TITLES, ORGANIZATION_TYPES) {
        var self = this;
        self.LocalConfig = LocalConfig;
        var isAdmin = User.isAdmin();
        this.searchCounters = {};
        this.results = [];
        this.noMoreResults = {};
        this.sidebarItems = [
            {
                name: 'All',
                link: 'all',
                type: 'all',
            },
            {
                name: 'Artworks',
                link: 'artworks',
                type: 'artwork',
            },
            {
                name: 'Artcasts',
                link: 'artcasts',
                type: 'artcast',
            },
            {
                name: 'Catalogs',
                link: 'catalogs',
                type: 'catalog',
            },
            {
                name: 'Members',
                link: 'users',
                type: 'user',
            },
        ];

        if (User.isAdmin()) {
            self.sidebarItems.push({
                name: 'Sets',
                link: 'collections',
                type: 'set',
            });
        }

        const allUserTypes = [...Object.values(ORGANIZATION_TYPES), ...USER_TITLES];

        this.getCounters = () => {
            return self.results.reduce((acc, result) => {
                const topType = allUserTypes.includes(result.type) ? 'user' : result.type;

                acc[topType] ??= 0;
                acc[topType]++;
                return acc;
            }, {});
        };
        this.setResults = (res) => {
            self.results = res;
            self.searchCounters = self.getCounters();
        };

        this.getResultsLimit = () => {
            if ($stateParams.sidebarSelection !== 'all') {
                return 15;
            }
            return 6;
        };

        this.getTitleIdByName = (titleName) => {
            return Object.entries(USER_TITLES).find(([id, title]) => title === titleName)?.[0];
        };

        this.searchAll = function (query, limit, searchResults) {
            var deferred = $q.defer();
            var q = [
                self.searchArtworks(query, limit),
                self.searchArtcasts(query, limit, searchResults),
                self.searchCatalogs(query, limit, searchResults),
                self.searchUsers(query, limit),
            ];
            if (isAdmin) {
                // only admin can search sets
                q.push(self.searchCollections(query, limit));
            }

            $q.all(q).then(
                function (result) {
                    var artworks = result[0];
                    var artcasts = result[1];
                    var catalogs = result[2];
                    var users = result[3];
                    var collections = isAdmin ? result[4] : [];

                    var results = _.union(artworks, artcasts, catalogs, users, collections);

                    deferred.resolve(results);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.searchArtworks = function (query, limit, searchResults, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            var deferred = $q.defer(),
                data = {
                    searchKeyword: query,
                };

            $http
                .post('/artworks/searchArtworks' + limit + offset, data)
                .success(function (res) {
                    var results = [];

                    if (res.data.artworks && res.data.artworks.length > 0) {
                        res.data.artworks.forEach(function (artwork) {
                            var result = {};
                            result.basicType = 'artwork';
                            result.type = 'artwork';
                            result.id = artwork.id;
                            if (artwork.artist_first_name) {
                                result.value = artwork.title + ' (' + artwork.artist_first_name;
                                if (artwork.artist_last_name) {
                                    result.value += ' ' + artwork.artist_last_name;
                                }
                                result.value += ')';
                            } else {
                                result.value = artwork.title + ' (' + artwork.uploader.display_name + ')';
                            }
                            result.state = 'discover.artworks.artwork';
                            result.params = {
                                artworksName: 'all',
                                artworkId: artwork.id,
                            };
                            result.thumbnail = artwork.thumbnail_path;
                            result.category = artwork.category;
                            results.push(result);
                        });
                    }
                    deferred.resolve(results);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.searchCollections = function (query, limit, searchResults, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            var deferred = $q.defer(),
                data = {
                    searchKeyword: query,
                };

            $http
                .post('/collections/searchCollections' + limit + offset, data)
                .success(function (res) {
                    var results = [];

                    if (res.data.collections && res.data.collections.length > 0) {
                        res.data.collections.forEach(function (collection) {
                            var result = {};
                            result.basicType = 'set';
                            result.type = 'set';
                            result.id = collection.id;
                            result.url = collection.creator.url;
                            result.value = collection.name + ' (' + collection.creator.display_name + ')';
                            result.category = collection.type === 'VAULT' ? 'vault' : 'portfolio';
                            if (collection.creator.user_id === User.getId()) {
                                result.state = 'home.' + result.category + '.main';
                            } else {
                                result.state = 'profile.' + result.category + '.main';
                            }
                            result.params = {
                                url: result.url,
                                collectionId: result.id,
                                collectionLink: $filter('inflector')(collection.name.toLowerCase(), 'underscore'),
                            };
                            //                        result.thumbnail = collection.thumbnail_path;
                            results.push(result);
                        });
                    }
                    deferred.resolve(results);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.searchArtcasts = function (query, limit, searchResults, offset) {
            return self.searchChannels(query, limit, 'AC', searchResults, offset);
        };

        this.searchCatalogs = function (query, limit, searchResults, offset) {
            return self.searchChannels(query, limit, 'CT', searchResults, offset);
        };

        this.searchChannels = function (query, limit, type, searchResults, offset) {
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';
            var deferred = $q.defer(),
                data = {
                    searchKeyword: query,
                };
            if (type) {
                data.type = type;
            }

            $http
                .post(
                    '/channels/searchChannels' +
                        limit +
                        offset +
                        '?load=' +
                        self.LocalConfig.get('loadLevelForSearch', 2),
                    data
                )
                .success(function (res) {
                    var results = [];

                    if (res.data.channels && res.data.channels.length > 0) {
                        res.data.channels.forEach(function (channel) {
                            var result = {};
                            result.basicType = 'channel';
                            result.type = channel.type === CHANNEL_TYPES.ARTCAST ? 'artcast' : 'catalog';
                            result.id = channel.id;
                            result.url = channel.creator.url;
                            result.value = channel.name + ' (' + channel.creator.display_name + ')';
                            if (searchResults) {
                                result.state = CHANNEL_TYPES.ARTCAST
                                    ? 'discover.channels.channel'
                                    : 'discover.catalogs.channel';
                            } else {
                                if (channel.creator.user_id === User.getId()) {
                                    result.state = 'home.channels.main';
                                } else {
                                    result.state = 'profile.channels.main';
                                }
                            }
                            result.params = {
                                url: result.url,
                                channelsName: channel.type === CHANNEL_TYPES.ARTCAST ? 'allChannels' : 'allCatalogs',
                            };
                            if (searchResults) {
                                result.params.channelUrlName = channel.url_name;
                            }
                            result.thumbnail = channel.cover ? channel.cover.path : null;
                            results.push(result);
                        });
                    }
                    deferred.resolve(results);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.searchDevices = function (query, params) {
            var deferred = $q.defer(),
                data = {
                    searchKeyword: query,
                    ...params,
                };

            $http
                .post('/devices/searchDevices', data)
                .success(function (res) {
                    var results = [];

                    if (res.data.devices && res.data.devices.length > 0) {
                        res.data.devices.forEach(function (device) {
                            var result = {};
                            result.basicType = 'device';
                            result.type = 'device';
                            result.id = device.id;
                            result.url = device.owner.url;
                            result.user_id = device.owner.id;
                            result.value =
                                (device.name ? device.name : 'Display') +
                                ' (Display #' +
                                device.id +
                                ') - ' +
                                device.owner.display_name +
                                ' (User #' +
                                device.owner.id +
                                ')';
                            result.thumbnail =
                                'modules/search/img/device' +
                                (device.platform ? '_' + device.platform : '_minix') +
                                '.png';
                            results.push(result);
                        });
                    }
                    deferred.resolve(results);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.searchUsers = function (query, limit, searchResults, offset, type) {
            const originalLimit = Number(limit);
            limit = limit ? '/' + limit : '';
            offset = offset ? '/' + offset : '';

            // For now always show all
            if (originalLimit !== 4) {
                limit = '';
                offset = '';
            }

            var deferred = $q.defer(),
                data = {
                    searchKeyword: query,
                    type,
                };

            $http
                .post('/users/searchUsers' + limit + offset, data)
                .success(function (res) {
                    var results = [];

                    if (res.data.users && res.data.users.length > 0) {
                        res.data.users.forEach(function (user) {
                            var result = {};
                            result.basicType = 'user';
                            result.type = getUserTitle(
                                user.titles[0] ?? 0,
                                user.organization_type,
                                user.organization_type_other
                            );
                            result.id = user.id;
                            result.url = user.url;
                            result.value = user.display_name;
                            if (user.id === User.getId()) {
                                result.state = 'home';
                            } else {
                                result.state = 'profile';
                            }
                            result.params = {
                                url: result.url,
                            };
                            result.thumbnail = User.getOtherProfileImagePath(user.profile_path, user.id);
                            result.credits_balance = user.credits_balance;
                            results.push(result);
                        });
                    }
                    deferred.resolve(results);
                })
                .error(function (err) {
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        function getUserTitle(id, organizationType, organizationTypeOther) {
            var result;
            if (parseInt(id) === 5) {
                if (organizationType === 'OT') {
                    result = organizationTypeOther;
                } else {
                    result = ORGANIZATION_TYPES[organizationType];
                }
            } else {
                result = USER_TITLES[id];
            }
            return result;
        }
    },
]);
