angular.module('core').controller('FavoritesController', [
    '$scope',
    'FavoritesService',
    'DetectMobileBrowser',
    function ($scope, FavoritesService, DetectMobileBrowser) {
        $scope.FavoritesService = FavoritesService;
        $scope.favorites = FavoritesService.favorites;
        $scope.isMobile = DetectMobileBrowser.isAny;

        // Generic function
        $scope.toggleToFavorites = (type = $scope.type, ids = [$scope.id]) => {
            FavoritesService.toggleToFavorites(type, ids);

            // $scope.$apply();
        };
    },
]);
