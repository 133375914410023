angular.module('home').controller('HomeController', [
    'profile',
    '$rootScope',
    '$scope',
    '$q',
    '$state',
    '$timeout',
    '$localStorage',
    '$stateParams',
    '$http',
    '$location',
    '$log',
    'HomeDataService',
    'OpenDeepLink',
    'User',
    'S3Uploader',
    'Messages',
    'OpenPopupForm',
    'PushHandlerService',
    'DetectMobileBrowser',
    'UploadProgress',
    'FavoritesService',
    'GoogleAnalytics',
    '_',
    'NIIO_APP',
    function (
        profile,
        $rootScope,
        $scope,
        $q,
        $state,
        $timeout,
        $localStorage,
        $stateParams,
        $http,
        $location,
        $log,
        HomeDataService,
        OpenDeepLink,
        User,
        S3Uploader,
        Messages,
        OpenPopupForm,
        PushHandlerService,
        DetectMobileBrowser,
        UploadProgress,
        FavoritesService,
        GoogleAnalytics,
        _,
        NIIO_APP
    ) {
        $scope.UploadProgress = UploadProgress;
        $scope.User = User;
        $scope.showEmailStrip = false;
        $scope.followingInProgress = false;
        $scope.infoPages = function () {
            return !!($state.current.name.endsWith('artwork') || $state.current.name.endsWith('channel'));
        };
        $scope.showNotificationsStrip = PushHandlerService.$storage.pushy.notificationIsGranted;
        $scope.needToShow = true;
        $scope.askForPermission = function () {
            $scope.needToShow = false;
            PushHandlerService.askUserForPermission();
        };
        $scope.isMobile = DetectMobileBrowser.isAny;

        function initOpenSubmissionBanner() {
            var promises = [];

            promises.push(HomeDataService.eventInProcessExists($scope.profile.id));
            promises.push(HomeDataService.getUserPreferences($scope.profile.id));

            $q.all(promises)
                .then(
                    (res) => {
                        var events = res[0].data.data.submissions;
                        var hostEvents = res[0].data.data.host;
                        var today = new Date();
                        var messageParams = {};
                        var toggles = {};
                        var message;
                        var title;
                        var okFunction;
                        const isFromIframe = $state.params.fromIframe === 'true';
                        today.setHours(0, 0, 0, 0);

                        var preferences = res[1].data && res[1].data.preferences ? res[1].data.preferences : null;

                        // update if user verified the account
                        $rootScope.verificationProfile.verified = !!preferences.verified;
                        $scope.isUserVerified = $rootScope.verificationProfile.verified;

                        $scope.showEmailStrip =
                            !isFromIframe && !$scope.isOther && !$scope.isUserVerified && !$scope.infoPages();
                        if (events && events.length > 0) {
                            if (
                                $state.current.name.indexOf('home.events') < 0 &&
                                $state.current.name !== 'home.enrollToEvent' &&
                                !isFromIframe &&
                                (!$localStorage.openSubmissions ||
                                    !$localStorage.openSubmissions.expiryDate ||
                                    $localStorage.openSubmissions.expiryDate < today.getTime())
                            ) {
                                if (!preferences || !preferences.hide_events_popup) {
                                    toggles = {
                                        dontShowAgain: false,
                                    };
                                    message =
                                        'You seem to have an event submission in process.\nWould you like to complete your submission process now?\n';
                                    title = 'Event Submission in Process';
                                    okFunction = function () {
                                        $state.go('home.events.submission', { eventId: events[0].event_id });
                                    };
                                    OpenPopupForm.openMenu('popup', {
                                        message,
                                        title,
                                        toggles,
                                        okText: 'Take Me There',
                                        cancelText: 'Later',
                                        okFunction,
                                    }).then((res) => {
                                        if (toggles.dontShowAgain) {
                                            updateEventsPopupPreference(toggles.dontShowAgain);
                                        }
                                    });

                                    // var today = new Date();
                                    // tomorrow.setDate(tomorrow.getDate() + 1);
                                    $localStorage.openSubmissions = {
                                        expiryDate: today.getTime(),
                                    };
                                }
                            }
                        } else if (!isFromIframe && hostEvents) {
                            if (
                                $state.current.name.indexOf('home.events') < 0 &&
                                $state.current.name !== 'home.enrollToEvent'
                            ) {
                                if (!preferences || !preferences.hide_events_popup) {
                                    toggles = {
                                        dontShowAgain: false,
                                    };
                                    message =
                                        'You seem to have an event submission in process.\nWould you like to review the event submission now?';
                                    title = 'Event Submission in Process';
                                    okFunction = function () {
                                        $state.go('home.events');
                                    };
                                    OpenPopupForm.openMenu('popup', {
                                        message,
                                        title,
                                        toggles,
                                        okText: 'Take Me There',
                                        cancelText: 'Later',
                                        okFunction,
                                    }).then((res) => {
                                        if (toggles.dontShowAgain) {
                                            updateEventsPopupPreference(toggles.dontShowAgain);
                                        }
                                    });
                                }
                            }
                        }
                    },
                    (error) => {
                        var messageParams = {};
                        messageParams.message = 'Something went wrong.';
                        Messages.openMessage($rootScope, messageParams);
                        // deferred.reject(error);
                    }
                )
                .finally(() => {});
        }

        function requestDeleteAccount() {
            const subType = 'Delete Account';
            OpenPopupForm.openContact('support', User.email, '', subType);
            OpenDeepLink.func(NIIO_APP + 'delete-account').then(function (res) {
                // Successfully opened the link in the app
            });
        }

        function getS3UsersAssets() {
            return $http.get('/users/getS3UsersAssets');
        }

        function eventsExistForUser() {
            var data = {
                userId: $scope.profile ? $scope.profile.id : null,
            };
            return $http.post('/events/eventsExistForUser', data);
        }

        function updateEventsPopupPreference(value) {
            var data = {
                userId: $scope.profile ? $scope.profile.id : null,
                popup: value,
            };
            return $http.post('/users/updateEventsPopupPreferences', data);
        }

        function createEventTab() {
            var foundPage = _.findIndex($scope.pages, (page) => {
                return page.link === 'channels';
            });

            var eventsPage = { link: 'events', name: 'Events' };

            if (foundPage) {
                $scope.pages.splice(foundPage, 0, eventsPage);
            } else {
                $scope.pages.push(eventsPage);
            }
        }

        function handleTabs() {
            $scope.pages = [];

            if ($scope.isOther) {
                // Discover
                $scope.pages.push({ link: 'main', name: 'HOME.ABOUT' });
                $scope.pages.push({ link: 'portfolio', name: 'HOME.ARTWORKS' });
                $scope.pages.push({ link: 'channels', name: 'HOME.ARTCASTS' });
                $scope.pages.push({ link: 'catalogs', name: 'HOME.CATALOGS' });

                if (User.isAdmin()) {
                    if (
                        User.isArtist(profile) ||
                        (User.isOrganization(profile) && !User.isOrganizationTypeBusiness(profile))
                    ) {
                        $scope.pages.push({
                            link: 'portfolio',
                            name: 'HOME.PORTFOLIO_ADMIN',
                            extraclass: 'page-menu-admin',
                        });
                    }
                    $scope.pages.push({
                        link: 'vault',
                        name: 'HOME.VAULT_ADMIN',
                        extraclass: 'page-menu-admin',
                    });
                    $scope.pages.push({
                        link: 'channels',
                        name: 'HOME.CHANNELS_ADMIN',
                        extraclass: 'page-menu-admin',
                    });
                }
            } else {
                // Home
                if (User.isAdmin()) {
                    $scope.pages.push({ link: 'main', name: 'HOME.MAIN' });
                }

                if (
                    User.isArtist(profile) ||
                    (User.isOrganization(profile) && !User.isOrganizationTypeBusiness(profile))
                ) {
                    $scope.pages.push({ link: 'portfolio', name: 'HOME.PORTFOLIO' });
                }

                $scope.pages.push({ link: 'vault', name: 'HOME.VAULT' });
                $scope.pages.push({ link: 'channels', name: 'HOME.CHANNELS' });

                if ($scope.from === 'home') {
                    $scope.pages.push({
                        link: 'devices',
                        source: null,
                        name: 'HOME.DEVICES',
                    });
                }
            }

            if (!$scope.isOther || $scope.isAdmin) {
                if ($state.current.name.includes('events')) {
                    createEventTab();
                } else {
                    $rootScope.increaseImportantRequests();
                    eventsExistForUser()
                        .then((res) => {
                            if (res.data.eventsExist) {
                                createEventTab();
                            }
                        })
                        .finally(() => {
                            $rootScope.decreaseImportantRequests();
                        });
                }
            }
        }

        function isTabAllowed() {
            var state = $location.path().split('/')[2];
            var result = true;

            if (
                state &&
                (state === 'main' ||
                    state === 'vault' ||
                    state === 'portfolio' ||
                    state === 'channels' ||
                    state === 'devices')
            ) {
                var foundAllowedTab = _.find($scope.pages, (page) => {
                    return page.link === state;
                });

                result = !!foundAllowedTab;
            }

            return result;
        }

        function redirectToFirstTab() {
            // redirect to first available tab for own page (home)
            if (
                !isTabAllowed() ||
                $location.path() === '/home' ||
                $location.path() === '/home/' ||
                $location.path() === `/${$scope.profile?.url}` ||
                $location.path() === `/${$scope.profile?.url}/`
            ) {
                if ($scope.isOther) {
                    $location.path(`${$scope.profile.url}/${$scope.pages[0].link}`);
                } else {
                    $location.path(`home/${$scope.pages[0].link}`);
                }
            }
        }

        function setProfile(profile) {
            $scope.profile = profile || User.getUserDetailsWithBind();
            $scope.profileId = $scope.profile ? $scope.profile?.id : null;
            $scope.isOther = profile && profile?.id !== User.getId();
            $scope.from = $scope.isOther ? $stateParams.url : 'home';
            $scope.fromState = $scope.isOther ? 'profile' : 'home';
            $scope.isAdmin = User.isAdmin();
            $scope.isSelfOrAdmin = !$scope.isOther || $scope.isAdmin;

            if (!$scope.isOther || $scope.isAdmin) {
                // only if same user or admin create verificationProfile
                $rootScope.verificationProfile = $scope.profile;
            }
        }

        $scope.init = function () {
            $scope.lastScroll = null;
            if ($state.current && $state.current.name === 'mobile-profile') {
                $state.go('profile', $stateParams);
            } else {
                setProfile(profile);
                // Check if user has valid profile and cover pictures
                $scope.coverPic = {};
                $scope.profilePic = {};
                handleTabs();
                handleResetPasswordFlow();
                if (!$stateParams.fromIframe && (!$scope.isOther || $scope.isAdmin)) {
                    initOpenSubmissionBanner();
                }
                if ($stateParams.deleteAccount) {
                    requestDeleteAccount();
                }
            }
        };

        $scope.$on('HomeEditProfileController:profile_changed', (event, profile) => {
            setProfile(profile);
        });

        $scope.$on('HomeEditProfileController:titles_changed', (event) => {
            handleTabs();
        });

        $scope.$on('HomeEditProfileController:enroll_to_event', (event) => {
            handleTabs();
        });

        $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
            if (
                toState === 'profile' ||
                toState.name === 'profile' ||
                toState === 'home' ||
                toState.name === 'home' ||
                !isTabAllowed()
            ) {
                redirectToFirstTab();
            }
        });

        $scope.$on('HomeEditProfileController:profile_updated', (event, newUser) => {
            $scope.profile = $scope.isOther ? newUser : User.getUserDetailsWithBind();
        });

        $scope.hideCoverConditions = function () {
            return (
                $state.includes('home.vaultUpload.**') ||
                $state.includes('home.portfolioUpload.**') ||
                $state.includes('home.vaultAddCollection.**') ||
                $state.includes('home.portfolioAddCollection.**') ||
                $state.includes('home.addChannel.**') ||
                $state.includes('home.editProfile.**') ||
                $state.includes('profile.editProfile.**') ||
                ($state.includes('home.devices.**') && $scope.isMobile) ||
                $state.is('home.channels.channel') ||
                $state.is('home.portfolio.artwork') ||
                $state.is('home.vault.artwork') ||
                $state.is('home.mobileUpload') ||
                $state.is('profile.catalogs.channel') ||
                $state.is('profile.channels.channel') ||
                $state.is('profile.portfolio.artwork') ||
                $state.is('profile.vault.artwork')
            );
        };

        $scope.showFollowAction = function () {
            return $state.includes('profile.**');
        };

        $scope.hideSecondaryNavConditions = function () {
            return (
                $state.includes('home.vaultUpload.**') ||
                $state.includes('home.portfolioUpload.**') ||
                $state.includes('home.vaultAddCollection.**') ||
                $state.includes('home.portfolioAddCollection.**') ||
                $state.includes('home.addChannel.**') ||
                $state.includes('home.editProfile.**') ||
                $state.is('home.mobileUpload') ||
                $state.includes('profile.editProfile.**')
            );
        };

        function isSupportedFileType(file) {
            var error = '';

            if (!file.type.match('image/png') && !file.type.match('image/jpeg')) {
                error = 'File type is not supported. only .png and .jpg are allowed';
            } else if (file.size > 1048576) {
                error = 'Only pictures under 1MB are allowed. Please choose another one.';
            }

            return error;
        }

        $scope.$state = $state;

        function removeFiles() {
            _removeFiles('coverPicFile');
            _removeFiles('profilePicFile');
            if ($scope.coverPic !== undefined) {
                $scope.coverPic = [];
            }
            if ($scope.profilePic !== undefined) {
                $scope.profilePic.files = [];
            }
        }

        function _removeFiles(elId) {
            var e1 = document.getElementById(elId);
            if (e1 !== undefined && e1.value !== undefined) {
                e1.value = '';
            }
        }

        $scope.uploadProfilePic = function (files) {
            var deferred = $q.defer();
            var file = files[0];

            if (file) {
                var error = isSupportedFileType(file);

                if (!error) {
                    getS3UsersAssets().then((res) => {
                        var opts = {
                            submitOnChange: true,
                            getOptionsUri: '/Users/getUserProfileS3AccessToken',
                            acl: 'public-read',
                            uploadingKey: 'uploading',
                            enableValidation: true,
                            s3Bucket: res.data.s3.bucket,
                            s3Folder: res.data.s3.folder,
                            s3FileName: res.data.s3.profile,
                            extraParams: {
                                uploadType: 'profile',
                            },
                        };

                        $scope.profileImagePath = `https://${res.data.s3.bucket}.s3.amazonaws.com/${res.data.s3.folder}/${res.data.s3.profile}`;

                        $scope.uploadFile(file, opts, 'profile').then(
                            (res) => {
                                $log.debug('HomeController::uploadProfilePic::uploadFile Successfully uploaded file', {
                                    res,
                                    file,
                                    option: opts,
                                    type: 'profile',
                                });
                                var messageParams = {};
                                messageParams.message = 'Profile Pic Uploaded Successfully';
                                Messages.openMessage($scope, messageParams);
                            },
                            (error) => {
                                $log.debug('HomeController::uploadProfilePic::uploadFile Failed to upload to S3', {
                                    error,
                                    file,
                                    options: opts,
                                    type: 'profile',
                                });
                                var messageParams = {};
                                messageParams.title = 'Error uploading profile picture';
                                messageParams.message =
                                    'Failed to upload profile picture, Please retry.\nIf the problem persists, please contact support.';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                            }
                        );
                    });
                } else {
                    var messageParams = {};
                    messageParams.message = error;
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            } else {
                deferred.reject('no file');
            }

            return deferred.promise;
        };

        $scope.uploadCoverPic = function (files) {
            var deferred = $q.defer();
            var file = files[0];

            if (file) {
                var error = isSupportedFileType(file);

                if (!error) {
                    getS3UsersAssets().then((res) => {
                        var opts = {
                            submitOnChange: true,
                            getOptionsUri: '/Users/getUserProfileS3AccessToken',
                            acl: 'public-read',
                            uploadingKey: 'uploading',
                            enableValidation: true,
                            s3Bucket: res.data.s3.bucket,
                            s3Folder: res.data.s3.folder,
                            s3FileName: res.data.s3.cover,
                            extraParams: {
                                uploadType: 'cover',
                            },
                        };

                        $scope.coverImagePath = `https://${res.data.s3.bucket}.s3.amazonaws.com/${res.data.s3.folder}/${res.data.s3.cover}`;

                        $scope.uploadFile(file, opts, 'cover').then(
                            (res) => {
                                $log.debug('HomeController::uploadCoverPic::uploadFile Successfully uploaded file', {
                                    res,
                                    file,
                                    option: opts,
                                    type: 'cover',
                                });
                            },
                            (error) => {
                                $log.debug('HomeController::uploadCoverPic::uploadFile Failed to upload to S3', {
                                    error,
                                    file,
                                    options: opts,
                                    type: 'cover',
                                });
                                var messageParams = {};
                                messageParams.title = 'Error uploading cover picture';
                                messageParams.message =
                                    'Failed to upload cover picture, Please retry.\nIf the problem persists, please contact support.';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                            }
                        );
                    });
                } else {
                    var messageParams = {};
                    messageParams.message = error;
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            } else {
                deferred.reject('no file');
            }

            return deferred.promise;
        };

        $scope.uploadFile = function (file, opts, type) {
            var deferred = $q.defer();

            S3Uploader.getUploadUserPicsOptions(opts.getOptionsUri, type, $scope.profileId).then(
                (s3Options) => {
                    var successFunction = opts.successFunction;
                    var failFunction = opts.failFunction;
                    var exraParams = opts.extraParams;

                    // var s3Uri = 'https://' + opts.s3Bucket + '.s3.amazonaws.com/';
                    var key = `${opts.s3Folder}/${opts.s3FileName}`;
                    S3Uploader.upload(
                        'regular',
                        opts.s3Bucket,
                        key,
                        opts.acl,
                        file.type,
                        s3Options.key,
                        s3Options.policy,
                        s3Options.signature,
                        file,
                        exraParams,
                        successFunction,
                        failFunction
                    ).then(
                        (res) => {
                            deferred.resolve(res);
                        },
                        (error) => {
                            deferred.reject(error);
                        }
                    );
                },
                (error) => {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        $rootScope.$on('s3upload:start', (scope, msg) => {
            var uploadType = msg.upload.extraParams.uploadType;

            if (uploadType === 'profile') {
                $scope.profilePicActive = true;
            } else if (uploadType === 'cover') {
                $scope.coverPicActive = true;
            }
        });

        $rootScope.$on('s3upload:progress', (scope, msg) => {
            var uploadProgress = msg.value;
            var uploadType = msg.upload.extraParams.uploadType;

            if (uploadType === 'profile') {
                $scope.profilePicActive = true;
                $scope.profilePicProgress = uploadProgress;
            } else if (uploadType === 'cover') {
                $scope.coverPicActive = true;
                $scope.coverPicProgress = uploadProgress;
            }
        });

        $rootScope.$on('s3upload:success', (scope, data) => {
            var uploadType = data.upload.extraParams.uploadType;

            removeFiles();

            if (uploadType === 'profile') {
                // User.setProfileImagePath($scope.profileImagePath);
                $http.post('/users/uploadedProfilePic').then((res) => {
                    User.setProfileImagePath(res.data.path);
                    // $scope.profile.profile_path = res.data.path;
                    $scope.profilePicActive = false;
                    setProfile(profile);
                });
            } else if (uploadType === 'cover') {
                // User.setCoverImagePath($scope.coverImagePath);
                $http.post('/users/uploadedCoverPic').then((res) => {
                    User.setCoverImagePath(res.data.path);
                    // $scope.profile.cover_path = res.data.path;
                    $scope.coverPicActive = false;
                });
            }
        });

        $rootScope.$on('s3upload:error', (scope, data) => {
            var uploadType = data.upload.extraParams.uploadType;
            var messageParams = {};

            if (uploadType && (uploadType === 'profile' || uploadType === 'cover')) {
                messageParams.message = `${uploadType} image change failed. Please try again.`;
                messageParams.title = 'Error Change Image';
                messageParams.disableAutoDismiss = true;

                Messages.openMessage($scope, messageParams);

                if (uploadType === 'profile') {
                    $scope.profilePicActive = false;
                } else if (uploadType === 'cover') {
                    $scope.coverPicActive = false;
                }
            }
        });

        function followUser(userId) {
            $scope.followingInProgress = true;

            User.followUser(userId).then(
                (res) => {
                    $log.debug('HomeController::followUser Success', { userId, res });
                    $scope.followingInProgress = false;
                    $scope.profile.is_following = true;
                },
                (error) => {
                    $log.debug('HomeController::followUser Failure', { userId, error });
                    $scope.followingInProgress = false;
                    var messageParams = {};
                    messageParams.title = 'Error following user';
                    messageParams.message =
                        'Failed to follow user, Please retry.\nIf the problem persists, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        }

        function toggleToFavorites(type = $scope.type, ids = [$scope.id]) {
            FavoritesService.toggleToFavorites(undefined, ids);
        }

        function unfollowUser(userId) {
            $scope.followingInProgress = true;

            User.unfollowUser(userId).then(
                (res) => {
                    $log.debug('HomeController::unfollowUser Success', { userId, res });
                    $scope.followingInProgress = false;
                    $scope.profile.is_following = false;
                },
                (error) => {
                    $log.debug('HomeController::unfollowUser Failure', { userId, error });
                    $scope.followingInProgress = false;
                    var messageParams = {};
                    messageParams.title = 'Error unfollowing user';
                    messageParams.message =
                        'Failed to unfollow user, Please retry.\nIf the problem persists, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        }

        $scope.toggleFollow = function (userId, is_following) {
            if (is_following) {
                unfollowUser(userId);
            } else {
                followUser(userId);
            }
        };

        // Social Share
        $scope.openSocialPopup = function (user) {
            OpenPopupForm.openMenu('social', { user });
        };

        function handleResetPasswordFlow() {
            var messageParams = {};

            if ($stateParams.reset) {
                // Success - continue to reset password
                if ($stateParams.reset === '1') {
                    OpenDeepLink.func(`${NIIO_APP}reset?code=${$stateParams.code}&email=${$stateParams.email}`).then(
                        (res) => {
                            // Successfully opened the link in the app
                        },
                        (err) => {
                            var params = {
                                reset: true,
                                email: $stateParams.email,
                                code: $stateParams.code,
                            };
                            Messages.openPasswordChangeModal(params);
                        }
                    );
                } else if ($stateParams.reset === '2') {
                    messageParams.title = 'Reset Password';
                    messageParams.message =
                        'Oops, there seem to be a problem with resetting your password. Please contact support (press OK to continue)';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams).then((res) => {
                        OpenPopupForm.openContact('support', $stateParams.email, 'reset password problem');
                    });
                } else if ($stateParams.reset === '3') {
                    delete $stateParams.email;
                    delete $stateParams.code;
                    delete $stateParams.reset;
                    window.history.replaceState(null, null, window.location.pathname);
                }
            }
        }

        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            var splitFromState = fromState.name.split('.'); // for example home.events.submission

            /* 1st condition is hack to fix bug in search routes */
            if (toState.name !== 'search.main' && toState.name === `${splitFromState[0]}.${splitFromState[1]}`) {
                // is going to home.events?
                event.preventDefault();
                $state.go(fromState, fromParams);
            }
        });
        $scope.$on('add_channel_changed', () => {
            HomeDataService.resetCachedChannels();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            HomeDataService.resetCachedVaultCollectionsByUser();
        });
        $scope.$on('upload_aborted', () => {
            HomeDataService.resetCachedChannels();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            HomeDataService.resetCachedVaultCollectionsByUser();
        });
        $scope.$on('upload_start-prepare-data-for-upload', () => {
            HomeDataService.resetCachedChannels();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            HomeDataService.resetCachedVaultCollectionsByUser();
        });
        $scope.$on('artwork_changed_status', () => {
            HomeDataService.resetCachedChannels();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            HomeDataService.resetCachedVaultCollectionsByUser();
        });
        $scope.$on('artwork_deleted', () => {
            HomeDataService.resetCachedChannels();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            HomeDataService.resetCachedVaultCollectionsByUser();
        });
        $scope.$on('add_collection_changed', () => {
            HomeDataService.resetCachedChannels();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            HomeDataService.resetCachedVaultCollectionsByUser();
        });
        $scope.$on('collection_first_time_upload', () => {
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            HomeDataService.resetCachedVaultCollectionsByUser();
        });

        function replaceArtworkStatusFromPush(artworkId, status) {
            var i = 0;

            for (i; i < $scope.artworks.length; i++) {
                if (artworkId === $scope.artworks[i].id && $scope.artworks[i].status !== status) {
                    replaceNoneReadyArtworkWithReadyArtwork($scope.artworks[i], status);
                    break;
                }
            }
        }

        function updateCachedCollections() {
            HomeDataService.resetCachedVaultCollectionsByUser();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
        }

        $rootScope.$on('pushy_update_artwork_ready', (event, data) => {
            updateCachedCollections();
        });

        $rootScope.$on('pushy_update_artwork_preview_ready', (event, data) => {
            updateCachedCollections();
        });

        $scope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            if (
                fromState.name === `${$scope.fromState}.${fromState.data?.state}.main` ||
                fromState.name === `${$scope.fromState}.channels.main`
            ) {
                $scope.lastScroll = document.documentElement.scrollTop;
            }

            var fromSplit = fromState.name.split('.');
            var toSplit = toState.name.split('.');
            if (
                toSplit[1] &&
                fromSplit[1] &&
                toSplit[1].indexOf(fromSplit[1]) < 0 &&
                fromSplit[1].indexOf(toSplit[1]) < 0
            ) {
                $scope.lastScroll = null;
            }
        });

        function scrollToLast() {
            if ($scope.lastScroll) {
                $timeout(() => {
                    window.scrollTo(0, $scope.lastScroll);
                }, 10);
            }
        }

        $scope.$on('HomeCollectionsMainController:collection_chosen', () => {
            scrollToLast();
        });

        $scope.$on('HomeChannelsMainController:channel_chosen', () => {
            scrollToLast();
        });

        $scope.resendConfirmation = function () {
            const email = $scope.User.getEmail();
            $http
                .post('/users/reSendConfirmationMail', { email })
                .success((res) => {
                    var messageParams = {};
                    messageParams.message = 'Resent confirmation email';
                    messageParams.message =
                        'An email was sent to you, please check your inbox and follow the instructions.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                })
                .error((error) => {
                    var messageParams = {};
                    messageParams.title = 'Resend Confirmation Email';
                    messageParams.message = 'There was an error sending confirmation link, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                });
        };
    },
]);
