'use strict';

angular.module('go-back').controller('GoBackController', [
    '$state',
    '$location',
    function ($state, $location) {
        var self = this;
        this.$state = $state;
        this.forceShow = this.forceShow || false;
        this.buttonText = this.buttonText || 'Back';
        this.showArrow = this.showArrow !== false && this.showArrow !== 'false';

        this.showBackButton = function () {
            return (
                self.forceShow ||
                (self.fromChannel && $state.includes('**.channel')) ||
                (self.fromArtwork && $state.includes('**.artwork'))
            );
            // $state.is('home.channels.channel') ||
            //     $state.is('home.portfolio.artwork') ||
            //     $state.is('home.vault.artwork') ||
            //     $state.is('discover.artworks.artwork') ||
            //     $state.is('discover.catalogs.channel') ||
            //     $state.is('discover.channels.channel') ||
            //     $state.is('discover.channel') ||
            //     $state.is('discover.artwork') ||
            //     $state.is('profile.catalogs.channel') ||
            //     $state.is('profile.channels.channel') ||
            //     $state.is('profile.portfolio.artwork') ||
            //     $state.is('profile.vault.artwork');
        };

        this.goBack = function () {
            // if (window.history && window.history.length > 0 && document.referrer.indexOf(window.location.host) !== -1) {
            if (window.history && window.history.length > 0) {
                window.history.back();
            } else {
                var newState = $state.current.name.split('.');
                if (newState[0] === 'profile' && self.profileUrl) {
                    newState[0] = self.profileUrl;
                }
                newState = newState.slice(0, newState.length - 1).join('/');
                $location.path(newState);
            }
        };
    },
]);
