'use strict';

angular.module('artwork-thumbnail').directive('artworkThumbnail', [
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                artwork: '=',
                totalArtworks: '=',
                buttonsEnabled: '=',
                actionsEnabled: '=',
                editEnabled: '=',
                hidePrices: '=?',
                uploadEnabled: '=',
                adminPanelEnabled: '=',
                labelsEnabled: '=',
                isDiscovery: '=',
                isDiscoveryArtcast: '=',
                isAdmin: '=',
                isInWishlist: '@',
                fromModule: '=',
                fromView: '=',
                editLink: '=',
                link: '&',
                linkState: '@',
                linkStateParams: '@',
                deleteArtwork: '&',
                cancelUpload: '&',
                toggleAddedArtwork: '&',
                retryUpload: '&',
                retryProcess: '&',
                buy: '&',
                inquire: '&?',
                isShortlisted: '=?',
                eventHostAcceptTitle: '=',
                eventHostAccept: '&?',
                eventHostApproveTitle: '=',
                eventHostApproveIcon: '=',
                eventHostApprove: '&?',
                eventHostDownload: '&?',
                eventHostDownloadTitle: '=',
                toggleWishlist: '&',
                showEventRating: '=?',
                eventId: '=?',
                isInShortlist: '=?',
                isEventOwner: '=?',
                isAllowDownload: '=?',
                channel: '=?',
                hideExtraText: '=?',
                hideCaption: '=?',
                allowPortrait: '=?',
            },
            templateUrl: 'modules/artwork-thumbnail/views/artwork-thumbnail.html',
            controller: 'ArtworkController',
        };
    },
]);
