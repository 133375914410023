'use strict';

angular.module('home').controller('HomeEventsSubmissionFormController', [
    '$rootScope',
    '$filter',
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$timeout',
    '$http',
    '_',
    'smoothScroll',
    'HomeDataService',
    'FormValidationService',
    'Messages',
    'OpenPopupForm',
    function (
        $rootScope,
        $filter,
        $scope,
        $state,
        $stateParams,
        $log,
        $q,
        $timeout,
        $http,
        _,
        smoothScroll,
        HomeDataService,
        FormValidationService,
        Messages,
        OpenPopupForm
    ) {
        $scope.HomeDataService = HomeDataService;
        $scope.FormValidationService = FormValidationService;

        // function updateSubmissionsQuestions() {
        //     matchQuestionsToForm($scope.currentEvent.submissions[0].questions, $scope.customForm); // Update from the form back to the questions
        // }

        $scope.init = function () {
            if ($scope.currentEvent) {
                var customFormString = $scope.currentEvent.event.custom_form_structure;
                $scope.customForm = JSON.parse(customFormString);
                $scope.questions = $scope.currentEvent.submissions[0].questions;
                HomeDataService.matchQuestionsToForm($scope.customForm, $scope.questions);
            }
        };

        $scope.$on('reinitiazlie_step', function (event) {
            $scope.init();
        });

        function cleanCustomForm(customForm) {
            customForm.forEach(function (question) {
                delete question.$$hashKey;
                if (question.title) {
                    delete question.answer;
                }
            });
        }

        $scope.updateForm = function () {
            cleanCustomForm($scope.customForm);
            var data = {
                customForm: $scope.customForm,
                eventId: $scope.currentEvent.event.id,
                userId: $scope.userId,
            };

            $scope.submitWasClicked = true;

            $http
                .post('/events/saveFormQuestions', data)
                .then(
                    function (res) {
                        $log.debug('HomeEventsSubmissionFormController::updateForm Success', res, data);
                        $scope.currentEvent.submissions[0].questions = res.data.questions;
                        var messageParams = {};
                        messageParams.title = 'Form Saved Successfully';
                        messageParams.message = 'The form was saved successfully, please continue to the next step.';
                        Messages.openMessage($scope, messageParams).then(function (res) {
                            $scope.$emit('submission_step_completed');
                        });
                    },
                    function (error) {
                        $log.debug('HomeEventsSubmissionFormController::updateForm::updateDetails Failed', error, data);
                        var messageParams = {};
                        messageParams.title = 'Failed to Save Form';
                        messageParams.message =
                            'There was a problem saving the form. Please contact support (press OK to continue).';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams).then(function (res) {
                            OpenPopupForm.openContact(
                                'support',
                                null,
                                'event submission form save failed (event id: ' + $stateParams.eventId + ')'
                            );
                        });
                    }
                )
                .finally(function () {
                    $scope.submitWasClicked = false;
                });
        };
    },
]);
