'use strict';

angular.module('core').controller('ModalInstanceController', [
    '$timeout',
    'Messages',
    'message',
    'title',
    'okClass',
    'okText',
    'cancelText',
    'template',
    'templateStyle',
    'templateParams',
    'hideTitle',
    'hideLogo',
    'hideHeader',
    'hideFooter',
    'disableAutoDismiss',
    'alertType',
    '$uibModalInstance',
    function (
        $timeout,
        Messages,
        message,
        title,
        okClass,
        okText,
        cancelText,
        template,
        templateStyle,
        templateParams,
        hideTitle,
        hideLogo,
        hideHeader,
        hideFooter,
        disableAutoDismiss,
        alertType,
        $uibModalInstance
    ) {
        var self = this;
        this.Messages = Messages;
        this.message = message;
        this.title = title;
        this.hideHeader = hideHeader;
        this.hideFooter = hideFooter;
        this.hideLogo = hideLogo;
        this.hideTitle = hideTitle;
        this.template = template;
        this.templateStyle = templateStyle;
        this.templateParams = templateParams || {};
        this.disableAutoDismiss = disableAutoDismiss;
        this.alertType = alertType;
        this.okClass = okClass;

        if (okText) {
            self.okText = okText;
        }

        if (cancelText) {
            self.cancelText = cancelText;
        }

        if (!disableAutoDismiss) {
            var messageTimeout = $timeout(function () {
                if ($uibModalInstance) {
                    $uibModalInstance.dismiss();
                }
                $timeout.cancel(messageTimeout);
            }, 3000);
        }
    },
]);
