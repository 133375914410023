'use strict';

angular.module('admin-area').controller('AdminAreaStatusesArtworksTasksController', [
    '$state',
    '$rootScope',
    '$scope',
    '$q',
    '$filter',
    'Messages',
    'AdminAreaDataService',
    '_',
    'NIIO_URL',
    function ($state, $rootScope, $scope, $q, $filter, Messages, AdminAreaDataService, _, NIIO_URL) {
        var self = this;
        this.Messages = Messages;
        this.priorities = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

        function processTask(task) {
            // task.details = JSON.parse(task.todo_json);
            task.details = task.todo_json;
            task.details = task.details.replace(/\"/g, '');
            task.details = task.details.replace(/:/g, ': ');
            task.details = task.details.replace(/,/g, ', ');
            task.details = task.details.substr(1); // remove first {
            task.details = task.details.substring(0, task.details.length - 1); // remove last }

            task.startedDate = task.started ? $filter('mysqlDateToISOString')(task.started) : null;
            task.endedDate = task.ended ? $filter('mysqlDateToISOString')(task.ended) : null;
            task.createdDate = task.created ? $filter('mysqlDateToISOString')(task.created) : null;
            task.totalTimeSeconds =
                task.endedDate && task.startedDate
                    ? parseInt((new Date(task.endedDate) - new Date(task.startedDate)) / 1000)
                    : null;
            task.totalTime = task.totalTimeSeconds
                ? new Date(1970, 0, 1).setSeconds(task.totalTimeSeconds)
                : null;
        }

        function fetchTasks(artworkId) {
            var deferred = $q.defer();

            AdminAreaDataService.getArtworkTasks(artworkId).then(
                function (res) {
                    self.tasks = res;
                    self.tasks.forEach(function (task) {
                        processTask(task);
                    });

                    deferred.resolve();
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'Failed to fetch tasks: ' + error.text;
                    messageParams.title = 'Failed Fetching Tasks';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        this.init = function () {
            self.artwork = self.params && self.params.artwork ? self.params.artwork : {};
            self.tasks = self.params && self.params.tasks ? self.params.tasks : null;

            if (self.tasks) {
                // Current processing Tasks
                self.isProcessingTasks = true;
                self.tasks.forEach(function (task) {
                    processTask(task);
                });
            } else {
                self.artworkId = self.artwork.id;
                self.loadingTasks = true;
                self.tasks = [];
                fetchTasks(self.artworkId).finally(function () {
                    self.loadingTasks = false;
                });
            }
        };

        this.updatePriority = function (taskId, priority) {
            AdminAreaDataService.updateTaskPriority(taskId, priority).then(
                function (res) {
                    var messageParams = {};
                    messageParams.message = 'Successfully updated priority';
                    Messages.openMessage($rootScope, messageParams);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'Failed to update priority';
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        $scope.$on('$stateChangeStart', function () {
            Messages.ok();
        });
    },
]);
