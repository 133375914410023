'use strict';

angular.module('core').filter('cutText', [
    function () {
        return function (input, maxChars, method) {
            method = method || 'middle';
            input = input || '';
            maxChars = maxChars || 14;
            var out = input;

            if (method === 'middle') {
                if (input.length > maxChars) {
                    var textLengthToTake = maxChars / 2 - 1;
                    out = input.substr(0, textLengthToTake);
                    out += '...';
                    out += input.substr(input.length - textLengthToTake, textLengthToTake + 1);
                }
            } else {
                if (input.length > maxChars) {
                    out = input.substr(0, maxChars);
                    out += '...';
                }
            }

            return out;
        };
    },
]);
