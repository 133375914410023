'use strict';

angular.module('core').filter('currencySymbol', [
    function () {
        return function (input) {
            var out = input;

            if (input !== undefined && input !== null) {
                var currency = input.toLowerCase();

                if (currency === 'usd') {
                    out = '$';
                } else if (currency === 'eur') {
                    out = '€';
                } else if (currency === 'gbp') {
                    out = '£';
                } else if (currency === 'btc') {
                    out = '฿';
                }
            }

            return out;
        };
    },
]);
