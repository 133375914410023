'use strict';
/*global NiioPlayer */
/*global AppleID */

angular.module('users').controller('LoginController', [
    '$rootScope',
    '$http',
    '$q',
    '$scope',
    '$state',
    '$stateParams',
    '$location',
    '$timeout',
    'jwtHelper',
    'Partial',
    'Security',
    'ChannelService',
    'ArtworkService',
    'OpenPopupForm',
    'Messages',
    'FormValidationService',
    'PlatformManager',
    '$auth',
    'User',
    function (
        $rootScope,
        $http,
        $q,
        $scope,
        $state,
        $stateParams,
        $location,
        $timeout,
        jwtHelper,
        Partial,
        Security,
        ChannelService,
        ArtworkService,
        OpenPopupForm,
        Messages,
        FormValidationService,
        PlatformManager,
        $auth,
        User
    ) {
        var self = this;
        this.Messages = Messages;
        this.loginInProcess = false;

        function initAppleConnect() {
            AppleID.auth.init({
                clientId: 'com.niio.web',
                scope: 'email',
                redirectURI: PlatformManager.platform.url + '/Server/users/ExternalAuthRedirect',
                //state : '',
                usePopup: true,
            });
        }

        this.init = function () {
            self.showForgotPassword = false;
            self.FormValidationService = FormValidationService;
            self.User = {};
            self.User.email = self.params ? self.params.email : undefined;

            if ($stateParams.from) {
                $rootScope.redirectAfterLogin = $stateParams.from; // To enable redirecting to different location based on query strong from
            }

            if ($rootScope.fromPlayer) {
                self.deviceId = $stateParams.deviceId;
                self.deviceCode = $stateParams.secret;
                self.string =
                    PlatformManager.platform.url +
                    '/add-device?deviceId=' +
                    $stateParams.deviceId +
                    '&shortSecret=' +
                    $stateParams.secret;
                self.string +=
                    $stateParams.deviceName !== null
                        ? '&deviceName=' + encodeURIComponent($stateParams.deviceName)
                        : '';
                self.playerLastError = self.getLastPlayerError();
            }

            initAppleConnect();
        };

        this.terminatePlayer = function () {
            NiioPlayer.Terminate();
        };

        this.getLastPlayerError = function () {
            return NiioPlayer.GetLastError();
        };

        function onSuccessLogin(res) {
            var deferred = $q.defer();
            if (typeof Messages.ok === 'function') {
                Messages.ok();
            }

            if (self.params && self.params.service) {
                if (self.params.service === 'artwork') {
                    deferred.resolve(
                        Partial.func(
                            ArtworkService.handleArtworkAction,
                            self.params.actionName,
                            self.params.artwork,
                            self.params.params
                        )
                    );
                } else if (self.params.service === 'channel') {
                    deferred.resolve(
                        Partial.func(
                            ChannelService.handleChannelAction,
                            self.params.actionName,
                            self.params.channel,
                            self.params.params
                        )
                    );
                } else if (self.params.service === 'plans') {
                    $rootScope.$emit('continue_payment');
                    deferred.resolve('default');
                }
            } else {
                deferred.resolve('default');
            }

            self.loginInProcess = false;
            return deferred.promise;
        }

        function onFailedLogin(error) {
            if (error && error.status === 412) {
                // 2FA code is required
                self.display2FA = true;
            } else {
                self.User.password = '';
                self.loginForm.password.$setPristine();
                var messageParams = {};
                if (error && error.status) {
                    if (error.status === 500) {
                        messageParams.title = 'Login Failed';
                        messageParams.message = 'Wrong credentials or missing account with that email';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    } else if (error.status === 501) {
                        delete self.User.tfa_code;
                        delete self.display2FA;
                        messageParams.title = 'Login Failed';
                        messageParams.message = 'The 2FA code you entered is incorrect';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    } else {
                        if (error.status === 401) {
                            self.loginForm.notVerifiedAccount = true;
                        } else if (error.status === 403) {
                            self.loginForm.blockedAccount = true;
                        }
                        messageParams.title = 'Login Failed';
                        messageParams.message =
                            'Please make sure you entered valid and existing credentials.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                } else {
                    messageParams.title = 'Login Failed';
                    messageParams.message =
                        'A general error has occurred. Please check your internet connection.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            }
            self.loginInProcess = false;
        }

        this.openJoin = function (authProfile) {
            Messages.cancel();
            if (self.params) {
                self.params.authProfile = authProfile;
            } else {
                self.params = { authProfile: authProfile };
            }
            $timeout(function () {
                OpenPopupForm.openMenu('join', self.params);
            }, 10);
        };

        this.login = function () {
            self.loginInProcess = true;
            //if (self.fromPlayer) {
            //    alert('Checking status from server');
            //    $http.get('/users/status')
            //        .success(function (res) {
            //            var messageParams = {};
            //            messageParams.title = 'Login';
            //            messageParams.message = JSON.stringify(res.data);
            //            messageParams.size = 'lg';
            //            messageParams.okText = 'OK';
            //            Messages.openMessage($rootScope, messageParams);
            //            //alert(JSON.stringify(res.data));
            //        })
            //        .error(function (error) {
            //            var messageParams = {};
            //            messageParams.title = 'Login';
            //            messageParams.message = JSON.stringify(error);
            //            messageParams.size = 'lg';
            //            messageParams.okText = 'OK';
            //            Messages.openMessage($rootScope, messageParams);
            //            //alert(JSON.stringify(error));
            //        });
            //} else {
            self.loginForm.notVerifiedAccount = false;
            self.loginForm.blockedAccount = false;
            Security.login(self.User, onSuccessLogin, onFailedLogin, 'login');
            //}
        };

        this.exauthenticate = function (provider) {
            if (provider === 'apple') {
                AppleID.auth.signIn().then(
                    function (response) {
                        if (response.authorization && response.authorization.code) {
                            var user = response.authorization.user;
                            var id_token = response.authorization.id_token;
                            var data = {};
                            if (user) {
                                data.firstName = user.firstName;
                                data.lastName = user.lastName;
                            }
                            if (id_token) {
                                var decodedIdToken = jwtHelper.decodeToken(id_token);
                                if (decodedIdToken) {
                                    data.oid = decodedIdToken.sub;
                                }
                            }
                            Security.loginExternal(
                                provider,
                                response.authorization.code,
                                data,
                                onSuccessLogin,
                                onFailedLogin,
                                'login',
                                function (res) {
                                    // join success function
                                    self.openJoin(res);
                                }
                            );
                        } else {
                            onFailedLogin();
                        }
                    },
                    function (err) {
                        onFailedLogin();
                    }
                );
            } else {
                $auth.authenticate(provider).then(function (response) {
                    var data = {
                        provider: provider,
                    };
                    self.loginForm.notVerifiedAccount = false;
                    Security.loginExternal(
                        provider,
                        response.code,
                        null,
                        onSuccessLogin,
                        onFailedLogin,
                        'login',
                        function (res) {
                            // join success function
                            self.openJoin(res);
                        }
                    );
                });
            }
        };

        this.resendConfirmation = function (email) {
            $http
                .post('/users/reSendConfirmationMail', { email: email })
                .success(function (res) {
                    var messageParams = {};
                    messageParams.message = 'Resent confirmation email';
                    messageParams.message =
                        'An email was sent to you, please check your inbox and follow the instructions.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                })
                .error(function (error) {
                    var messageParams = {};
                    messageParams.title = 'Resend Confirmation Email';
                    messageParams.message =
                        'There was an error sending confirmation link, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                });
        };

        this.sendForgotPassword = function (email) {
            if (email !== '' && email !== undefined) {
                $http
                    .post('/users/resetPassword', { email: email })
                    .success(function (res) {
                        var messageParams = {};
                        messageParams.title = 'Password recovery';
                        messageParams.message =
                            'An email was sent to you, please check your inbox and follow the instructions.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                        self.showForgotPassword = !self.showForgotPassword;
                    })
                    .error(function (error) {
                        var messageParams = {};
                        messageParams.title = 'Password recovery';
                        messageParams.message = 'The email or password you entered is incorrect';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    });
            } else {
                var messageParams = {};
                messageParams.title = 'Password recovery';
                messageParams.message = 'Please fill email address';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
            }
        };
    },
]);
