'use strict';

// Setting up route
angular.module('home-page').config([
    '$stateProvider',
    'ENV',
    function ($stateProvider, ENV) {
        // Users state routing
        var homepageState = {
            url: '/?v&email&reset&code&invitationCode&o&s&fromIframe&deleteAccount&deviceType',
            templateUrl: 'modules/home-page/views/home-page.client.view.html',
            controller: 'HomePageController',
            resolve: {
                //                    isAuthenticated: ['$state', 'User', function ($state, User) {
                //                        if (User.isAuthenticated()) {
                //                            $state.go('home');
                //                        }
                //                    }]
                //                 homepageBackgrounds: ['$http', function($http) {
                // $s3Prefix = (PROFILE == 'local')? '//s3.amazonaws.com/'.S3_BUCKET_MEDIA.'/' : '//d2agnttnwx4l6r.cloudfront.net/'; // CloudFront specifically for Web / Mobile
                // var folder = '';
                // if (ENV === 'local' || ENV === 'dev') {
                //     folder = '01_StaticDev';
                // } else if (ENV === 'pre') {
                //     folder = '03_StaticPre';
                // } else { // prod
                //     folder = '02_StaticProd';
                // }
                //
                // // return $http.get('//d2agnttnwx4l6r.cloudfront.net/' + folder + '/Homepage_Backgrounds/homepage_backgrounds.json');
                // // TODO change to cloudfront
                // return $http.get('//s3.amazonaws.com/niiomediafiles/' + folder + '/Homepage_Backgrounds/homepage_backgrounds.json');
                // }]
            },
        };

        var loginState = angular.copy(homepageState);
        loginState.url = '/signin';

        var inviteState = angular.copy(homepageState);
        inviteState.url = '/invite';
        inviteState.params = inviteState.params || {};
        inviteState.params.o = 'i';

        var joinState = angular.copy(homepageState);
        joinState.url = '/join';
        joinState.params = joinState.params || {};
        joinState.params.o = 'j';

        $stateProvider
            .state('home-page', homepageState)
            .state('signin', loginState)
            .state('invite', inviteState)
            .state('join', joinState);
    },
]);
