'use strict';

angular.module('core').controller('MessagesAddRemoveExternalLoginController', [
    'params',
    '$rootScope',
    '$state',
    '$uibModalInstance',
    '$q',
    '$http',
    '$log',
    'FormValidationService',
    'Messages',
    function (
        params,
        $rootScope,
        $state,
        $uibModalInstance,
        $q,
        $http,
        $log,
        FormValidationService,
        Messages
    ) {
        var self = this;
        this.Messages = Messages;
        this.FormValidationService = FormValidationService;
        this.addRemoveProfileObj = {};
        this.params = params || {};
        this.actionMethod = function () {
            if (self.params.action === 'add') {
                self.addProfile();
            } else {
                self.removeProfile();
            }
        };

        this.init = function () {
            this.actionTitle = this.params.action === 'add' ? 'Add' : 'Remove';
            this.actionDescription =
                this.params.action === 'add' ? 'adding a Social Account ' : 'with removal of Social Account ';
        };
        this.init();

        this.removeProfile = function () {
            var data = {
                code: self.addRemoveProfileObj.code,
                ticket: self.params.ticket,
            };

            var messageParams = {};
            $http.post('/users/RemoveExternalLogin', data).then(
                function () {
                    messageParams.message = 'Profile was removed successfully.';
                    $uibModalInstance.close('ok');
                    $rootScope.$broadcast('user::socialProfileChanged');
                    Messages.openMessage($rootScope, messageParams);
                },
                function (error) {
                    messageParams.message =
                        'There was a problem finish your operation. Please retype the code and try again.';
                    messageParams.disableAutoDismiss = true;
                    $uibModalInstance.dismiss('cancel');
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        this.addProfile = function () {
            var data = {
                code: self.addRemoveProfileObj.code,
                ticket: self.params.ticket,
            };

            var messageParams = {};
            $http.post('/users/AddExternalLogin', data).then(
                function () {
                    messageParams.message = 'Profile was added successfully.';
                    $uibModalInstance.close('ok');
                    $rootScope.$broadcast('user::socialProfileChanged');
                    Messages.openMessage($rootScope, messageParams);
                },
                function (error) {
                    messageParams.message =
                        'There was a problem finish your operation. Please retype the code and try again.';
                    messageParams.disableAutoDismiss = true;
                    $uibModalInstance.dismiss('cancel');
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };
    },
]);
