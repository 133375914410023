'use strict';

angular.module('home').controller('HomeMainTabController', [
    '$rootScope',
    '$scope',
    'smoothScroll',
    function ($rootScope, $scope, smoothScroll) {
        $scope.init = function () {
            //Scroll to head of page in init
            if (!$scope.isOther) {
                var homeSecondNavElement = document.getElementById('home-secondary-nav');
                if (homeSecondNavElement) {
                    smoothScroll(homeSecondNavElement, { offset: $rootScope.heightOfNav });
                }
            }
        };

        $scope.sidebar = [
            {
                name: 'Featured',
                link: 'featured',
            },
        ];
    },
]);
