'use strict';

angular.module('home').controller('ArtworkManagementController', [
    'Messages',
    function (Messages) {
        var self = this;
        this.Messages = Messages;
        this.selectedPage = {};

        this.pages = [
            {
                source: 'modules/home/views/home.transfer-history.client.view.html',
                name: 'Transfers',
            },
        ];

        this.init = function () {
            self.artwork = self.params && self.params.artwork ? self.params.artwork : {};
            self.artworkId = self.params && self.params.artwork ? self.params.artwork.id : '';

            if (self.artwork.sale_type === 'SRB' || self.artwork.entity_type === 'nft') {
                self.pages.unshift({
                    source: 'modules/home/views/home.edition-management.client.view.html',
                    name: 'Edition Management',
                });
            }

            self.selectedPage = self.pages[0];
        };
    },
]);
