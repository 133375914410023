'use strict';

angular.module('core').directive('googleAnalytics', [
    '$log',
    '$rootScope',
    'GoogleAnalytics',
    function ($log, $rootScope, GoogleAnalytics) {
        return {
            restrict: 'E',
            link: function (scope, iElement, iAttrs) {
                (function (w, d, s, l, i) {
                    w[l] = w[l] || [];
                    w[l].push({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js',
                    });
                    var f = d.getElementsByTagName(s)[0],
                        j = d.createElement(s),
                        dl = l !== 'dataLayer' ? '&l=' + l : '';
                    j.async = true;
                    j.src = 'https://www.googletagmanager.com/gtag/js?id=' + i + dl;
                    f.parentNode.insertBefore(j, f);
                })(window, document, 'script', 'dataLayer', GoogleAnalytics.measurementID); // GA4 Measurement ID

                scope.$watch(
                    function () {
                        return GoogleAnalytics.measurementID;
                    },
                    function (newId, oldId) {
                        if (newId !== undefined) {
                            $log.debug('googleAnalytics Directive - Added measurement ID', newId);
                            window.dataLayer = window.dataLayer || [];
                            function gtag() {
                                dataLayer.push(arguments);
                            }
                            gtag('js', new Date());
                            GoogleAnalytics.setUserId($rootScope.$user.getId());
                            GoogleAnalytics.setUserProperty('user_title', $rootScope.$user.getUserTitleValue()); // user_title
                        }
                    },
                    true
                );
            },
        };
    },
]);
