// All definitions of the consts are defined in the env files and should be declared here

angular
    .module('consts', [])
    .constant('NIIO_DEBUG', process.env.NIIO_DEBUG === 'true')
    .constant('NIIO_APP', process.env.NIIO_APP)
    .constant('NIIO_VERSION', process.env.NIIO_VERSION)
    .constant('NIIO_BRANCH', process.env.NIIO_BRANCH)
    .constant('NIIO_BRANCH_COMMITER', process.env.NIIO_BRANCH_COMMITER)
    .constant('NIIO_COMMIT_MESSAGE', process.env.NIIO_COMMIT_MESSAGE)
    .constant('PAYPAL_EMAIL', process.env.PAYPAL_EMAIL)
    .constant('PAYPAL_ADDRESS', process.env.PAYPAL_ADDRESS)
    .constant('GOOGLE_ID', process.env.GOOGLE_ID)
    .constant('GOOGLE_ANALYTICS', process.env.GOOGLE_ANALYTICS)
    .constant('FB_ID', process.env.FB_ID)
    .constant('ENV', process.env.ENV)
    .constant('NIIO_URL', process.env.NIIO_URL)
    .constant('NIIO_API_URL', process.env.NIIO_API_URL)
    .constant('SERVER', {
        address: process.env.SERVER,
    })
    .constant('COGNITO_POOL', process.env.COGNITO_POOL)
    .constant('COGNITO_POOL_REGION', process.env.COGNITO_POOL_REGION)
    .constant('GOOGLE_RECAPTCHA', process.env.GOOGLE_RECAPTCHA)
    .constant('INTERCOM_APP_ID', process.env.INTERCOM_APP_ID);
