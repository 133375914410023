'use strict';

angular.module('admin-area').controller('AdminAreaServicesController', [
    '$scope',
    '$state',
    'User',
    function ($scope, $state, User) {
        $scope.init = function () {
            $state.go('admin-area.services.' + $scope.sidebar[0].link);
        };

        $scope.sidebar = [
            {
                name: 'Push Notifications',
                link: 'push',
            },
        ];

        if (User.isAdmin()) {
            $scope.sidebar.push(
                {
                    name: 'Re-run',
                    link: 're-run',
                },
                {
                    name: 'Batch Import',
                    link: 'batch',
                },
                {
                    name: 'API Management',
                    link: 'api',
                }
            );
        }
    },
]);
