'use strict';

angular.module('core').service('MetaInformation', [
    function () {
        var metaDescription = '';
        var metaKeywords = '';
        return {
            metaDescription: function () {
                return metaDescription;
            },
            metaKeywords: function () {
                return metaKeywords;
            },
            init: function () {
                metaDescription =
                    'Niio is a visual content and technology company that plans to be the singular trusted source for discovering and streaming premium 4K artistic content to any display in the world. We are doing to Digital Art, what Soundcloud has done to music. ';
                metaDescription +=
                    'For the first time ever, today’s leading digital creators have access to a dedicated cloud-based platform and marketplace that offers them comprehensive tools for managing, distributing and monetizing their digitally displayed works, ultimately connecting creators to consumers and their screens. ';
                metaDescription +=
                    'Developed exclusively for professional digital artists and content owners, Niio supports many artistic formats from still photos, to video to VR and is the first self-publishing community platform able to manage and play up to 4K resolution. ';
                metaDescription +=
                    'The Niio 4K Smart Player can transform one or multiple screens into pixel perfect, dedicated art devices, while our mobile app lets viewers discover, curate and control their on screen digital art experiences.';
                metaKeywords =
                    'Niio, Digital Art, Artist, Artists, AnimGif, AnimGifs, Moving Images, Video Art, Interactive, Generative, Generated, 4K, U/HD, UHD, HD, PRO tools, digart, Art Console, Art Box, Art Device, Art Frame, Art Display, Remote Control, Art Player, niio.com, niioart, community, marketplace, VR, 3D, ArtConsole, ArtDevice, ArtBox, 4K Video Player, Art Player, NiioPlayer, NiioApp, Curation Tool, Uninterrupted Player, Video Art Installation, Video Exhibition';
            },
            setMetaDescription: function (newMetaDescription) {
                metaDescription = newMetaDescription;
            },
            appendMetaKeywords: function (newKeywords) {
                for (var key in newKeywords) {
                    if (metaKeywords === '') {
                        metaKeywords += newKeywords[key].name;
                    } else {
                        metaKeywords += ', ' + newKeywords[key].name;
                    }
                }
            },
        };
    },
]);
