'use strict';

angular.module('core').controller('InquireController', [
    '$rootScope',
    '$http',
    '$location',
    'FormValidationService',
    'Messages',
    'User',
    'ArtworkService',
    function ($rootScope, $http, $location, FormValidationService, Messages, User, ArtworkService) {
        var self = this;
        this.Messages = Messages;
        this.FormValidationService = FormValidationService;

        this.init = function () {
            self.inquireFormObj = {};

            self.inquireFormObj.params = self.params.params || {};
            self.inquireFormObj.artwork = self.params.artwork;
            self.inquireFormObj.message = self.params.message;

            self.title = 'Inquire About "' + self.inquireFormObj.artwork.title + '"';
            self.subtitle = 'Send personal message to ' + self.inquireFormObj.artwork.uploader.display_name;
        };

        this.sendForm = function () {
            self.submitWasClicked = true;

            self.inquireFormObj.params.message = self.inquireFormObj.message;

            ArtworkService.inquireArtwork(
                self.inquireFormObj.artwork.id,
                self.inquireFormObj.params,
                self.inquireFormObj.artwork
            ).then(
                function (res) {
                    var messageParams = {};
                    messageParams.message = 'Thank you. Your request was sent successfully.';
                    self.submitWasClicked = false;
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        Messages.cancel();
                    });
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'There was a problem sending the request:\n' + error.data.errors;
                    messageParams.disableAutoDismiss = true;
                    self.submitWasClicked = false;
                    Messages.openMessage($rootScope, messageParams).then();
                }
            );
        };
    },
]);
