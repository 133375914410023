'use strict';

angular.module('discover').controller('DiscoverForYouController', [
    'dynamicJson',
    '$log',
    '$q',
    '$scope',
    '$stateParams',
    '$window',
    '$timeout',
    'ChannelService',
    'ArtworkService',
    'DiscoverDataService',
    'HomeDataService',
    'User',
    function (
        dynamicJson,
        $log,
        $q,
        $scope,
        $stateParams,
        $window,
        $timeout,
        ChannelService,
        ArtworkService,
        DiscoverDataService,
        HomeDataService,
        User
    ) {
        var self = this;
        self.featuredItems = [];
        self.layoutList = {};
        self.viewAllList = {};
        self.selectedPreset = {};
        self.arrowToggles = {};

        function getFeaturedChannels(limit, type) {
            return DiscoverDataService.getFeaturedChannels(limit, type).then(
                function (res) {
                    // res.data.data.channels.forEach(function (channel) {
                    //     channel.profileImagePath = User.getOtherProfileImagePath(channel.creator.profile_path, channel.creator.user_id);
                    // });
                    return res.data.data.channels;
                },
                function (error) {
                    $log.debug('there was an error fetching featured channels: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getFeaturedUsers(limit) {
            return DiscoverDataService.getFeaturedUsers(limit).then(
                function (res) {
                    // res.data.data.users.forEach(function (user) {
                    //     user.profileImagePath = User.getOtherProfileImagePath(user.profile_path, user.id);
                    // });
                    return res.data.data.users;
                },
                function (error) {
                    $log.debug('there was an error fetching featured users: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getLatestChannels(limit, type) {
            return DiscoverDataService.getRecentChannels(limit, type).then(
                function (res) {
                    // res.data.data.channels.forEach(function (channel) {
                    //     channel.profileImagePath = User.getOtherProfileImagePath(channel.creator.profile_path, channel.creator.user_id);
                    // });
                    return res.data.data.channels;
                },
                function (error) {
                    $log.debug('there was an error fetching recent channels: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getMarketChannels(limit, type) {
            return DiscoverDataService.getMarketChannels(limit, type).then(
                function (res) {
                    // res.data.data.channels.forEach(function (channel) {
                    //     channel.profileImagePath = User.getOtherProfileImagePath(channel.creator.profile_path, channel.creator.user_id);
                    // });
                    return res.data.data.channels;
                },
                function (error) {
                    $log.debug('there was an error fetching market channels: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getAllUsers(limit) {
            return DiscoverDataService.getAllUsers(limit).then(
                function (res) {
                    // res.data.data.users.forEach(function (user) {
                    //     user.profileImagePath = User.getOtherProfileImagePath(user.profile_path, user.id);
                    // });
                    return res.data.data.users;
                },
                function (error) {
                    $log.debug('there was an error fetching all users: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getFeaturedArtworks(limit) {
            return DiscoverDataService.getFeaturedArtworks(limit).then(
                function (res) {
                    return res.data.data.artworks;
                },
                function (error) {
                    $log.debug('there was an error fetching featured artworks: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getLatestArtworks(limit) {
            return DiscoverDataService.getRecentArtworks(limit).then(
                function (res) {
                    return res.data.data.artworks;
                },
                function (error) {
                    $log.debug('there was an error fetching latest artworks: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getMarketArtworks(limit) {
            return DiscoverDataService.getAllArtworks(limit).then(
                function (res) {
                    return res.data.data.artworks;
                },
                function (error) {
                    $log.debug('there was an error fetching market artworks: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getUserDetailsByUrl(listIDs) {
            var promises = [];
            listIDs.forEach(function (id) {
                promises.push(HomeDataService.getUserDetailsByUrl(id));
            });
            return $q.all(promises).then(
                function (res) {
                    var items = res.map(function (itemsRes) {
                        return itemsRes.data.data;
                    });
                    return items;
                },
                function (error) {
                    $log.debug('there was an error fetching user by id: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getArtworksById(listIDs) {
            var promises = [];
            listIDs.forEach(function (id) {
                promises.push(ArtworkService.fetchArtworkData(id));
            });
            return $q.all(promises).then(
                function (res) {
                    var items = res.map(function (itemsRes) {
                        return itemsRes.data.data;
                    });
                    return items;
                },
                function (error) {
                    $log.debug('there was an error fetching artworks: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        function getChannelsById(listIDs) {
            var promises = [];
            listIDs.forEach(function (id) {
                promises.push(ChannelService.fetchChannelData(id));
            });
            return $q.all(promises).then(
                function (res) {
                    var items = res.map(function (itemsRes) {
                        return itemsRes.data.data;
                    });
                    return items;
                },
                function (error) {
                    $log.debug('there was an error fetching channels: ' + JSON.stringify(error));
                    return [];
                }
            );
        }

        // only for featured items (first one)
        function getFeaturedItems() {
            var promises = [];
            self.selectedPreset.featuredItems.forEach(function (item) {
                promises.push(ChannelService.fetchChannelData(item.id));
            });
            return $q.all(promises);
        }

        function sortByMarketOrder(artworks) {
            artworks.sort(function (a, b) {
                if (a.market_order === null && b.market_order === null) {
                    return 0;
                }
                if (a.market_order === null) {
                    return 1;
                }
                if (b.market_order === null) {
                    return -1;
                }
                return +a.market_order > +b.market_order ? 1 : -1;
            });
        }

        function fetch(layoutData) {
            var deferred = $q.defer();
            layoutData.filter = layoutData.filter || 'featured';
            switch (layoutData.type) {
                case 'artworks':
                    if (layoutData.dynamicFromDiscover) {
                        if (layoutData.filter === 'featured') {
                            getFeaturedArtworks(layoutData.limit).then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        } else if (layoutData.filter === 'latest') {
                            const artworkLimit = layoutData.limit ?? 15;
                            getLatestArtworks(artworkLimit).then(
                                function (res) {
                                    sortByMarketOrder(res);
                                    var artworks = res.slice(0, layoutData.limit);
                                    deferred.resolve(artworks);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        } else if (layoutData.filter === 'all') {
                            getMarketArtworks(layoutData.limit).then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        }
                    } else {
                        getArtworksById(layoutData.IDs).then(
                            function (res) {
                                deferred.resolve(res);
                            },
                            function (error) {
                                deferred.reject(error);
                            }
                        );
                    }
                    break;
                case 'catalogs':
                    if (layoutData.dynamicFromDiscover) {
                        if (layoutData.filter === 'featured') {
                            getFeaturedChannels(layoutData.limit, 'CT').then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        } else if (layoutData.filter === 'latest') {
                            getLatestChannels(layoutData.limit, 'CT').then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        } else if (layoutData.filter === 'all') {
                            getMarketChannels(layoutData.limit, 'CT').then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        }
                    } else {
                        getChannelsById(layoutData.IDs).then(
                            function (res) {
                                deferred.resolve(res);
                            },
                            function (error) {
                                deferred.reject(error);
                            }
                        );
                    }
                    break;
                case 'artcasts':
                    if (layoutData.dynamicFromDiscover) {
                        if (layoutData.filter === 'featured') {
                            getFeaturedChannels(layoutData.limit, 'AC').then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        } else if (layoutData.filter === 'latest') {
                            getLatestChannels(layoutData.limit, 'AC').then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        } else if (layoutData.filter === 'all') {
                            getMarketChannels(layoutData.limit, 'AC').then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        }
                    } else {
                        getChannelsById(layoutData.IDs).then(
                            function (res) {
                                deferred.resolve(res);
                            },
                            function (error) {
                                deferred.reject(error);
                            }
                        );
                    }
                    break;
                case 'members':
                    if (layoutData.dynamicFromDiscover) {
                        if (layoutData.filter === 'featured') {
                            getFeaturedUsers(layoutData.limit).then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        } else if (layoutData.filter === 'latest') {
                            // no support for latest members
                            deferred.reject('non support for  latest members');
                        } else if (layoutData.filter === 'all') {
                            getAllUsers(layoutData.limit).then(
                                function (res) {
                                    deferred.resolve(res);
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );
                        }
                    } else {
                        getUserDetailsByUrl(layoutData.IDs).then(
                            function (res) {
                                deferred.resolve(res);
                            },
                            function (error) {
                                deferred.reject(error);
                            }
                        );
                    }
                    break;
            }
            return deferred.promise;
        }

        function getSelectedPreset() {
            var rolePresetName = '';
            var selectedPresetLink = '';
            var userDetails = User.getUserDetails();
            if (User.isOrganization()) {
                switch (userDetails.organization_type) {
                    case 'AC':
                        rolePresetName = 'academy';
                        break;
                    case 'GA':
                        rolePresetName = 'gallery';
                        break;
                    case 'MU':
                        rolePresetName = 'museum';
                        break;
                    case 'AR':
                        rolePresetName = 'archive';
                        break;
                    case 'IN':
                        rolePresetName = 'institution';
                        break;
                    case 'HO':
                        rolePresetName = 'publicInstallation';
                        break;
                    case 'AE':
                        rolePresetName = 'artEvent';
                        break;
                    case 'BS':
                        rolePresetName = 'business';
                        break;
                    case 'AS':
                        rolePresetName = 'artistStudio';
                        break;
                    case 'OT':
                    default:
                        rolePresetName = 'other';
                        break;
                }
                if (rolePresetName) {
                    selectedPresetLink = dynamicJson.newJSONSection.roleToPresetLinks.organizations[rolePresetName];
                }
            } else {
                if (userDetails.titles && userDetails.titles.length > 0) {
                    if (User.isArtist()) {
                        rolePresetName = 'artist';
                    } else if (User.isCollector()) {
                        rolePresetName = 'collector';
                    } else if (User.isCurator()) {
                        rolePresetName = 'curator';
                    } else if (User.isArtfan()) {
                        rolePresetName = 'artFan';
                    }
                    if (rolePresetName) {
                        selectedPresetLink = dynamicJson.newJSONSection.roleToPresetLinks[rolePresetName];
                    }
                }
            }
            //found preset by role
            if (selectedPresetLink) {
                // sort layout by onTop
                var preset = dynamicJson.newJSONSection.presets[selectedPresetLink];
                preset.layout = preset.layout.sort(function (a, b) {
                    return a.onTop - b.onTop;
                });
                // remove twitter from list
                var twitterIndex = preset.layout.findIndex(function (item) {
                    return item.type === 'twitter';
                });
                if (twitterIndex > -1) {
                    preset.layout.splice(twitterIndex, 1);
                }
                // remove instagram from list
                var instagramIndex = preset.layout.findIndex(function (item) {
                    return item.type === 'instagram';
                });
                if (instagramIndex > -1) {
                    preset.layout.splice(instagramIndex, 1);
                }
                return preset;
            } else {
                //TODO : handle case where no user details/role found
                return null;
            }
        }

        this.onCarouselInit = function (data) {
            console.log(data);
        };
        this.fetchDataByType = function (layoutData) {
            if (layoutData.type !== 'editorial') {
                fetch(layoutData).then(function (res) {
                    self.layoutList[layoutData.onTop] = res;
                });
            }
        };
        this.getUrl = function (type, subPath = '') {
            var returnUrl = 'discover/{{page.link}}';
            switch (type) {
                case 'artworks':
                    returnUrl = 'discover/artworks';
                    break;
                case 'catalogs':
                    returnUrl = 'discover/catalogs';
                    break;
                case 'artcasts':
                    returnUrl = 'discover/channels';
                    break;
                case 'members':
                    returnUrl = 'discover/channels';
                    break;
            }
            return `${returnUrl}/${subPath ? subPath : ''}`;
        };

        function checkScroll(event) {
            var elementId = event.target.id;
            $timeout(function () {
                var element = document.getElementById(elementId);
                if (element.scrollLeft <= 0) {
                    self.arrowToggles[element.id + 'Prev'] = false;
                    self.arrowToggles[element.id + 'Next'] = true;
                } else if (element.scrollLeft + element.offsetWidth < element.scrollWidth) {
                    self.arrowToggles[element.id + 'Prev'] = true;
                    self.arrowToggles[element.id + 'Next'] = true;
                } else if (element.scrollLeft + element.offsetWidth >= element.scrollWidth) {
                    self.arrowToggles[element.id + 'Prev'] = true;
                    self.arrowToggles[element.id + 'Next'] = false;
                }
            }, 10);
        }

        this.init = function () {
            // self.channelPath = $state.current.name.startsWith('profile.') && $scope.channelsName === 'curatedCatalogs'?
            //     'catalogs' : 'channels';
            // Taken from here: https://docs.google.com/document/d/1Y66RqyNIau6qbqlzfNZ1YlpaPkP47Kup5vyzddwGKxc/edit#
            $log.debug('JSON:', dynamicJson);
            self.selectedPreset = getSelectedPreset();
            if (self.selectedPreset) {
                getFeaturedItems().then(function (res) {
                    self.featuredItems = res.map(function (featuredItemsRes) {
                        return featuredItemsRes.data.data;
                    });
                    var elements = document.getElementsByClassName('slide-group-row');
                    var i;
                    for (i = 0; i < elements.length; i++) {
                        angular.element(elements[i]).on('scroll', checkScroll);
                    }
                });
            }
        };

        this.next = function (id) {
            var el = document.getElementById(id);
            if (el.scrollLeft + el.offsetWidth <= el.scrollWidth) {
                el.scroll({ left: el.scrollLeft + el.offsetWidth, behavior: 'smooth' });
            } else {
                el.scroll({ left: el.scrollWidth - el.offsetWidth, behavior: 'smooth' });
            }
        };
        this.previous = function (id) {
            var el = document.getElementById(id);
            if (el.scrollLeft - el.offsetWidth >= 0) {
                el.scroll({ left: el.scrollLeft - el.offsetWidth, behavior: 'smooth' });
            } else {
                el.scroll({ left: 0, behavior: 'smooth' });
            }
        };
    },
]);
