'use strict';

angular.module('core').controller('ContactController', [
    '$rootScope',
    '$http',
    '$location',
    'FormValidationService',
    'Messages',
    'User',
    'NIIO_VERSION',
    'NIIO_URL',
    'ENV',
    function ($rootScope, $http, $location, FormValidationService, Messages, User, NIIO_VERSION, NIIO_URL, ENV) {
        var self = this;
        this.Messages = Messages;
        this.FormValidationService = FormValidationService;

        this.init = function () {
            self.contactFormObj = {};
            self.contactFormObj.type = self.params.type;
            self.contactFormObj.subType = self.params.subType;
            if (self.contactFormObj.subType === 'Delete Account') {
                self.title = 'Request to Delete My Account';
                self.messageTitle = "We're sorry to see you go,\n" + 'why do you wish to delete your Niio account?';
                self.messagePlaceholder = 'Ні,\n' + 'Please delete my account...';
                self.importantNote =
                    '<b>Please note:</b><br>' +
                    'This action will delete your Niio account on all devices and permanently erase all your content, playlists, metadata, and personalized settings. This process is irreversible.';
            } else {
                self.title = self.params.type === 'support' ? self.params.type + ' request' : self.params.type;
                self.title += self.params.subType ? ' - ' + self.params.subType : '';
            }

            if (self.params.message) {
                self.contactFormObj.message =
                    (!self.contactFormObj.subType ? 'Support request due to ' : '') + self.params.message;
            }

            if (User.isAuthenticated()) {
                self.notAuthUser = false;
                self.contactFormObj.name = $rootScope.verificationProfile.name
                    ? $rootScope.verificationProfile.name
                    : User.getDisplayName();
                self.contactFormObj.email = $rootScope.verificationProfile.email
                    ? $rootScope.verificationProfile.email
                    : User.getEmail();
                self.contactFormObj.auth = true;
            } else {
                self.notAuthUser = true;
                self.contactFormObj.email = self.params.email;
            }

            self.contactFormObj.from = $location.absUrl();
            self.contactFormObj.env = ENV;
            self.contactFormObj.version = NIIO_VERSION;
            self.contactFormObj.url = NIIO_URL + '/' + User.getUrl();
        };

        this.sendForm = function () {
            self.submitWasClicked = true;

            $http.post('/system/sendContactMessage', self.contactFormObj).then(
                function (res) {
                    var messageParams = {};
                    messageParams.message =
                        'Thank you. Your ' +
                        (self.params.type === 'support' ? 'request' : 'feedback') +
                        ' was sent successfully.';
                    self.submitWasClicked = false;
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        Messages.cancel();
                    });
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'There was a problem sending the form:\n' + error.data.errors;
                    messageParams.disableAutoDismiss = true;
                    self.submitWasClicked = false;
                    Messages.openMessage($rootScope, messageParams).then();
                }
            );
        };
    },
]);
