'use strict';

angular.module('core').filter('convertServerDate', [
    '$filter',
    function ($filter) {
        return function (input, applyCurrentOffset) {
            var convertedDate = input;

            if (convertedDate) {
                // Taken from here: http://stackoverflow.com/questions/5324178/javascript-date-parsing-on-iphone
                convertedDate = input.split(/[- :]/);
                convertedDate = new Date(
                    convertedDate[0],
                    convertedDate[1] - 1,
                    convertedDate[2],
                    convertedDate[3],
                    convertedDate[4],
                    convertedDate[5]
                );
                if (applyCurrentOffset) {
                    var today = new Date();
                    convertedDate.setMinutes(convertedDate.getMinutes() - today.getTimezoneOffset());
                }
            }

            return convertedDate;
        };
    },
]);
