angular.module('core').directive('inputPhone', [
    'Countries',
    '$http',
    function (Countries, $http) {
        return {
            restrict: 'E',
            scope: {
                bindedModel: '=',
                country: '=',
                inputName: '=',
            },
            link: function (scope, element, attrs) {
                scope.isPhoneValid = true;
                scope.setCountryFromProps = () => {
                    if (!scope.country) return false;

                    scope.countryItem = scope.allCountries.find((item) =>
                        item.name.toLowerCase().includes(scope.country.toLowerCase())
                    );
                    scope.initialCountry = scope.countryItem;
                    return true;
                };

                scope.allCountries = Countries.getAll(scope.bindedModel);
                if (!scope.setCountryFromProps()) {
                    $http.get('/app/clientPreferences').then((response) => {
                        scope.countryItem = scope.allCountries.find((item) =>
                            item.code.includes(response.data.data.country_code)
                        );
                        scope.initialCountry = scope.countryItem;
                    });
                }

                scope.validatePhoneState = () => {
                    if (scope.countryItem?.code?.length !== 0) {
                        // Check if the bindedModel is either empty or only contains the prefix
                        const currentPrefix = `+${scope.countryItem.dialCode}`;
                        if (!scope.bindedModel || scope.bindedModel === currentPrefix) {
                            scope.isPhoneValid = true; // Consider empty input or just prefix as valid
                        } else {
                            scope.isPhoneValid = Boolean(scope.countryItem.isValid(scope.bindedModel));
                        }
                    }
                };

                function cleanPhoneNumber(phoneNumber) {
                    // Remove all non-digit or + characters
                    return phoneNumber.replace(/[^\d+]/g, '');
                }

                scope.addCountryPrefix = (force = false) => {
                    const currentPrefix = `+${scope.countryItem.dialCode}`;

                    // If there's no existing value or force is set, set the prefix.
                    if (!scope.bindedModel || force) {
                        scope.prevPrefix = currentPrefix === '+' ? '' : currentPrefix;
                        return;
                    }

                    // Extract the "rest" of the phone number by removing the existing prefix.
                    // let restNumber = scope.bindedModel.replace(scope.prevPrefix, '');
                    let restNumber;

                    if (scope.prevPrefix && scope.bindedModel.startsWith(scope.prevPrefix)) {
                        // Remove the old prefix if it exists
                        restNumber = scope.bindedModel.substring(scope.prevPrefix.length);
                    } else {
                        // If the model does not start with the previous prefix,
                        // assume it's just the "rest" of the phone number.
                        restNumber = scope.bindedModel;
                    }

                    // Update the previous prefix.
                    scope.prevPrefix = currentPrefix;

                    // If the remaining part already starts with the current prefix, then no update is needed.
                    if (!restNumber || restNumber.startsWith(currentPrefix)) {
                        return;
                    }

                    // Remove leading zeros
                    if (restNumber) {
                        restNumber = parseInt(restNumber).toString();
                    }

                    // Update the model only if it's different.
                    const newModel = `${currentPrefix}${restNumber}`;
                    if (scope.bindedModel !== newModel) {
                        scope.bindedModel = newModel;
                    }
                };

                scope.onPhoneNumberChange = () => {
                    if (!scope.bindedModel) {
                        scope.validatePhoneState();
                        return;
                    }
                    // Clean the input before processing it
                    scope.bindedModel = cleanPhoneNumber(scope.bindedModel);

                    if (!scope.bindedModel.startsWith('+')) {
                        // Add country code by selected country
                        scope.addCountryPrefix();
                    } else {
                        const typesCountries = scope.allCountries.filter((country) =>
                            scope.bindedModel.startsWith(`+${country.dialCode}`)
                        );

                        typesCountries.sort((a, b) => b.dialCode.length - a.dialCode.length);

                        // Put United States first, keep united states in the list, both as first and its original location
                        const unitedStates = typesCountries.find((country) => country.code === 'US');
                        if (unitedStates) {
                            // typesCountries.splice(typesCountries.indexOf(unitedStates), 1);
                            typesCountries.unshift(unitedStates);
                        }

                        const typesCountry = typesCountries[0];

                        if (typesCountry) {
                            if (typesCountry.dialCode !== scope.countryItem?.dialCode) {
                                scope.countryItem = typesCountry;
                            }
                            scope.addCountryPrefix();
                            console.log('[Debug] -> typesCountry', typesCountry);
                        }
                    }

                    scope.validatePhoneState();
                };

                scope.onSelectionChange = () => {
                    scope.addCountryPrefix();
                    scope.validatePhoneState();
                };

                scope.$watch('country', () => {
                    scope.setCountryFromProps();
                    scope.addCountryPrefix();
                    scope.validatePhoneState();
                });

                scope.$watch('bindedModel', () => {
                    setTimeout(() => scope.onPhoneNumberChange(), 1000);
                });
            },
            templateUrl: 'modules/core/views/input-phone.client.view.html',
        };
    },
]);
