'use strict';

angular.module('admin-area').controller('AdminAreaEventsSelectedUsersController', [
    '$rootScope',
    '$scope',
    'Search',
    'Messages',
    '_',
    'FormValidationService',
    function ($rootScope, $scope, Search, Messages, _, FormValidationService) {
        var self = this;
        this.FormValidationService = FormValidationService;

        this.Search = Search;
        this.tmpHostUser = {
            uiState: 'org',
            name: '',
            id: undefined,
            type: '1',
        };
        this.tmpCohostUser = {
            uiState: 'org',
            name: '',
            id: undefined,
            type: '2',
        };

        $scope.clearUserTmpData = function (dataObj, type) {
            dataObj.uiState = 'org';
            dataObj.name = '';
            dataObj.id = undefined;
            dataObj.type = type === 'cohost' ? '2' : '1';
        };

        $scope.toggleUserControl = function (dataObj) {
            if (dataObj.uiState !== 'org') {
                $scope.clearUserTmpData(self.tmpHostUser, 'host');
            } else {
                dataObj.uiState = 'draft';
            }
        };

        this.specificUserSelected = function (item, model, label, tmpUserObject) {
            if (item.basicType === 'user') {
                tmpUserObject.id = item.id;
            }
        };

        this.clearSubmissionDate = function (submitter) {
            var messageParams = {};
            var submitterId = submitter.submitterId;
            var display_name = submitter.submitter.display_name;

            messageParams.message = "You are about to remove this user's submission date.";
            messageParams.message += '\nuser: <b>' + display_name + '</b>';
            messageParams.message += '\nsubmission id: <b>' + submitterId + '</b>';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = "Unsubmit User's Submission";
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Unsubmit';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $scope.eventCtrl.clearSubmissionDate(submitterId);
                }
            });
        };

        this.changeHost = function () {
            if (self.tmpHostUser.uiState !== 'draft' || self.tmpHostUser.id === undefined) {
                return;
            }

            self.tmpHostUser.uiState = 'process';
            var uid = self.tmpHostUser.id;
            $scope.eventCtrl.changeEventHost(uid).then(function () {
                $scope.clearUserTmpData(self.tmpHostUser, 'host');
            });
        };

        this.addCohost = function () {
            if (self.tmpCohostUser.uiState === 'process' || self.tmpCohostUser.id === undefined) {
                return;
            }

            self.tmpCohostUser.uiState = 'process';
            var uid = self.tmpCohostUser.id;
            var type = self.tmpCohostUser.type;
            $scope.eventCtrl.addEventCohost(uid, type).then(function () {
                $scope.clearUserTmpData(self.tmpCohostUser, 'cohost');
            });
        };

        this.updateCohost = function (uid, type) {
            $scope.eventCtrl.updateEventCohost(uid, type).then(
                function () {},
                function (error) {
                    var messageParams = {};

                    messageParams.message = 'Updating cohost type failed. Please try again';
                    messageParams.title = 'Update Co-Host Type';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        this.removeCohost = function (cohost) {
            var uid = cohost.id;
            var display_name = cohost.display_name;
            var messageParams = {};

            messageParams.message =
                'You are about to remove user <b>' +
                display_name +
                '(#' +
                uid +
                ')</b> from being a co-host of this event.';
            messageParams.message += 'Are you sure you want to continue?';
            messageParams.title = 'Remove Co-Host';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Remove';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $scope.eventCtrl.removeEventCohost(uid);
                }
            });
        };

        this.removeSubmitter = function (submitter) {
            var messageParams = {};
            var submitterId = submitter.submitterId;
            var display_name = submitter.submitter.display_name;

            messageParams.message = "You are about to delete user's submission from this event.";
            messageParams.message += '\nuser: <b>' + display_name + '</b>';
            messageParams.message += '\nsubmission id: <b>' + submitterId + '</b>';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Remove Submission';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Remove';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $scope.eventCtrl.removeSubmitter(submitterId).then(function (res) {
                        // var foundIndex = _.findIndex($scope.currentEvent.calculatedData.availableSubmitters, function(submitter) {
                        //     return submitter.submitterId === submitterId;
                        // });
                        //
                        // if (foundIndex) {
                        //     $scope.currentEvent.calculatedData.availableSubmitters.splice(foundIndex, 1);
                        // }

                        var messageParams = {};
                        messageParams.message = "Successfully removed user's submission";
                        messageParams.title = 'Remove Submission';
                        Messages.openMessage($rootScope, messageParams);
                    });
                }
            });
        };

        this.init = function () {
            $rootScope.$broadcast('admin_event_selectedTab', 'users');
            $scope.clearUserTmpData(self.tmpHostUser, 'host');
        };
    },
]);
