const Sentry = require('@sentry/browser');
const Integrations = require('@sentry/integrations');

//Start by defining the main module and adding the module dependencies
//REMOVE? Should be declared only once in config.js angular.module(ApplicationConfiguration.applicationModuleName, ApplicationConfiguration.applicationModuleVendorDependencies);

// Setting HTML5 Location Mode
angular
    .module(ApplicationConfiguration.applicationModuleName)
    .config([
        '$provide',
        '$httpProvider',
        '$locationProvider',
        '$translateProvider',
        '$logProvider',
        '$compileProvider',
        'NIIO_DEBUG',
        function (
            $provide,
            $httpProvider,
            $locationProvider,
            $translateProvider,
            $logProvider,
            $compileProvider,
            NIIO_DEBUG
        ) {
            //$locationProvider.html5Mode({
            //    enabled: true,
            //    requireBase: false
            //}).hashPrefix('!');
            ////$locationProvider.hashPrefix('!');
            //
            //// Remove debugging mode in Angular
            //$compileProvider.debugInfoEnabled(NIIO_DEBUG);
            //// add translation tables
            //$translateProvider.translations('en', translationsEN);
            //$translateProvider.translations('he', translationsHE);
            //$translateProvider.preferredLanguage('en');
            //$translateProvider.fallbackLanguage('en');
            //
            //// push authentication interceptors to handle 401 Auth:Fail
            //$httpProvider.interceptors.push('PendingRequestsInterceptor');
            //$httpProvider.interceptors.push('AuthInterceptor');
            //
            //// Add timestamp to $log messages
            //$provide.decorator('$log', ['$delegate', '$filter', function ($delegate, $filter) {
            //    // Keep track of the original debug method, we'll need it later.
            //    var origDebug = $delegate.debug;
            //    /*
            //     * Intercept the call to $log.debug() so we can add on
            //     * our enhancement. We're going to add on a date and
            //     * time stamp to the message that will be logged.
            //     */
            //    $delegate.debug = function () {
            //        var args = [].slice.call(arguments);
            //        var formattedDate = $filter('date')(new Date(), 'MM/dd/yy HH:mm:ss.sss');
            //        args.unshift(formattedDate + ': ');
            //
            //        // Send on our enhanced message to the original debug method.
            //        origDebug.apply(null, args);
            //    };
            //
            //    return $delegate;
            //}]);
            //// on production, remove log messages
            //$logProvider.debugEnabled(NIIO_DEBUG);
            ////      *** Transform Angularjs request to JQuery request -
            ////      *** PHP doesn't support application/json requests
            //
            //        // Use x-www-form-urlencoded Content-Type
            //        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
            ////        $httpProvider.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
            ////        $httpProvider.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
            //
            //        /**
            //         * The workhorse; converts an object to x-www-form-urlencoded serialization.
            //         * @param {Object} obj
            //         * @return {String}
            //         */
            //        var param = function(obj) {
            //            var query = '', name, value, fullSubName, subName, subValue, innerObj, i;
            //
            //            for(name in obj) {
            //                value = obj[name];
            //
            //                if(value instanceof Array) {
            //                    for(i=0; i<value.length; ++i) {
            //                        subValue = value[i];
            //                        fullSubName = name + '[' + i + ']';
            //                        innerObj = {};
            //                        innerObj[fullSubName] = subValue;
            //                        query += param(innerObj) + '&';
            //                    }
            //                }
            //                else if(value instanceof Object) {
            //                    for(subName in value) {
            //                        subValue = value[subName];
            //                        fullSubName = name + '[' + subName + ']';
            //                        innerObj = {};
            //                        innerObj[fullSubName] = subValue;
            //                        query += param(innerObj) + '&';
            //                    }
            //                }
            //                else if(value !== undefined && value !== null)
            //                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
            //            }
            //
            //            return query.length ? query.substr(0, query.length - 1) : query;
            //        };
            //
            //        // Override $http service's default transformRequest
            //        $httpProvider.defaults.transformRequest = [function(data) {
            //            return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
            //        }];
        },
    ])
    .config([
        '$sceDelegateProvider',
        'NIIO_URL',
        function ($sceDelegateProvider, NIIO_URL) {
            $sceDelegateProvider.resourceUrlWhitelist([
                // Allow same origin resource loads.
                'self',
                NIIO_URL + '/**',
            ]);
        },
    ]);

angular
    .module(ApplicationConfiguration.applicationModuleName)
    .filter('trustThisUrl', [
        '$sce',
        function ($sce) {
            return function (val) {
                return $sce.trustAsResourceUrl(val);
            };
        },
    ])
    .run([
        '$rootScope',
        '$urlRouter',
        '$location',
        '$state',
        '$stateParams',
        '$localStorage',
        '$timeout',
        '$window',
        '$log',
        'PlatformManager',
        'GoogleAnalytics',
        'User',
        '$interval',
        'Security',
        'PushHandlerService',
        'Messages',
        'S3Uploader',
        'DetectMobileBrowser',
        'mobileBannerService',
        'TokenService',
        'NetworkStatus',
        'MetaInformation',
        'BillingService',
        'IntercomService',
        'OpenPopupForm',
        'OpenDeepLink',
        'PasswordProtectLink',
        'ENV',
        'NIIO_URL',
        'NIIO_APP',
        'NIIO_VERSION',
        'NIIO_BRANCH',
        'NIIO_BRANCH_COMMITER',
        'CHANNEL_TYPES',
        'SERVER',
        'GOOGLE_RECAPTCHA',
        function (
            $rootScope,
            $urlRouter,
            $location,
            $state,
            $stateParams,
            $localStorage,
            $timeout,
            $window,
            $log,
            PlatformManager,
            GoogleAnalytics,
            User,
            $interval,
            Security,
            PushHandlerService,
            Messages,
            S3Uploader,
            DetectMobileBrowser,
            mobileBannerService,
            TokenService,
            NetworkStatus,
            MetaInformation,
            BillingService,
            IntercomService,
            OpenPopupForm,
            OpenDeepLink,
            PasswordProtectLink,
            ENV,
            NIIO_URL,
            NIIO_APP,
            NIIO_VERSION,
            NIIO_BRANCH,
            NIIO_BRANCH_COMMITER,
            CHANNEL_TYPES,
            SERVER,
            GOOGLE_RECAPTCHA
        ) {
            Sentry.init({
                dsn: 'https://390cfaad2cd44072bd37b908484e4b8d@o30486.ingest.sentry.io/5834513',
                integrations: [new Integrations.Angular()],
                enabled: ENV !== 'local',
                environment: ENV,
                release: NIIO_VERSION,
                initialScope: {
                    tags: {
                        branch: NIIO_BRANCH,
                    },
                    user: {
                        id: User.getId(),
                    },
                },
            });

            /**
             * getBodyClasses function adding dynamic CSS class for each page with the page name.
             * For example, when you visit the "/login" page, the page-name class will be "login".
             * This class will be used to add a specific CSS class to the
             * body element when needed.
             *
             * @release: 1.7.9
             * @returns {string}
             */
            $rootScope.getBodyClasses = () => {
                const conditionsClasses = {
                    'home-page': $rootScope.homepage,
                    device: $rootScope.fromPlayer || $rootScope.fromIframe,
                    unverified: !$rootScope.verificationProfile.verified,
                    'is-admin': User.isAdmin(),
                    [$rootScope.$state.current.name.replaceAll('.', '_')]: true,
                };

                return Object.keys(conditionsClasses)
                    .filter((key) => conditionsClasses[key])
                    .join(' ');
            };

            NetworkStatus.init();
            MetaInformation.init();
            PlatformManager.setPlatform();
            setServerByLocalStorage();
            $rootScope.heightOfNav = 71;
            $rootScope.heightOfMainHome = 120;
            $rootScope.$platformManager = PlatformManager;
            $rootScope.MetaInformation = MetaInformation;
            $rootScope.$googleAnalytics = GoogleAnalytics;
            $rootScope.OpenPopupForm = OpenPopupForm;
            $rootScope.playersCounter = 0;
            $rootScope.$user = User;
            $rootScope.$state = $state;
            $rootScope.isSmartPhone = DetectMobileBrowser.isAny;
            $rootScope.fromTvOS = $location.search()?.deviceType === 'tvOS';
            $rootScope.fromIframe = $location.search().hasOwnProperty('fromIframe') || $rootScope.fromTvOS;
            $rootScope.smartPhoneName = DetectMobileBrowser.name;
            $rootScope.ENV = ENV;
            $rootScope.parseInt = parseInt;
            $rootScope.NIIO_VERSION = NIIO_VERSION;
            $rootScope.NIIO_BRANCH = NIIO_BRANCH;
            $rootScope.NIIO_BRANCH_COMMITER = NIIO_BRANCH_COMMITER;
            $rootScope.fromPlayer = undefined;
            $rootScope.makeVideoPlayableInline = $window.makeVideoPlayableInline;
            $rootScope.S3Uploader = S3Uploader;
            $rootScope.OpenPopupForm = OpenPopupForm;
            $rootScope.$location = $location;
            $rootScope.pendingPayment = false;
            $rootScope.backFromPaymentBool = false;
            $rootScope.discoverItemsToLoad = 20;
            // On artwork info or channel info, don't stop all requests if there are important requests still running
            $rootScope.importantRequestsRunning = 0;
            $rootScope.CHANNEL_TYPES = CHANNEL_TYPES;
            $rootScope.GOOGLE_RECAPTCHA = GOOGLE_RECAPTCHA;
            IntercomService.init();

            $rootScope.whiteList = {
                WL: ['homepage_backgrounds.json', '.svg', 'app/getEndpoints'],
                isWhiteListed: function (url) {
                    var result = false;

                    if ($rootScope.whiteList.WL.indexOf(url) >= 0) {
                        result = true;
                    } else {
                        for (var i = 0; i < $rootScope.whiteList.WL.length; i++) {
                            if (url.indexOf($rootScope.whiteList.WL[i]) >= 0) {
                                result = true;
                                break;
                            }
                        }
                    }

                    // return $rootScope.whiteList.WL.indexOf(url) >= 0;
                    return result;
                },
            };
            $rootScope.isCookieConsentOpen = false;
            $rootScope.isCookieConsentClosed = false;
            // Init push notifications - in case that the user is logged in
            if (User.isAuthenticated()) {
                PushHandlerService.init();
                Sentry.setUser({ id: User.getId() });
                User.checkUserUpgraded();
                // Update cookie expiration so that niio.com will redirect to web app instead of /site/
                var display_name = User.getDisplayName() || '';
                var cookiedata = { name: display_name };
                TokenService.setCookie(cookiedata);
            }

            function setServerByLocalStorage() {
                if ($localStorage.modifiedServer) {
                    if ($localStorage.modifiedServer.env === 'local') {
                        SERVER.address = `${NIIO_URL}Server`;
                    } else {
                        SERVER.address = $localStorage.modifiedServer.url + 'Server';
                    }
                }
            }

            function setCookieConsent() {
                window.cookieconsent.initialise({
                    container: document.querySelector('.cc-wrapper'),
                    onPopupOpen: function () {
                        $rootScope.$apply(function () {
                            $rootScope.isCookieConsentOpen = true;
                            // remove problematic vendor class
                            document.getElementsByClassName('cc-window')[0].classList.add('container-fluid');
                            document.getElementsByClassName('cc-window')[0].classList.remove('cc-floating');
                        });
                    },
                    onPopupClose: function () {
                        $rootScope.$apply(function () {
                            $rootScope.isCookieConsentClosed = true;
                            $rootScope.isCookieConsentOpen = false;
                        });
                    },
                    content: {
                        message: 'This website uses cookies to ensure you get the best experience on our website. ',
                        dismiss: 'Got it!',
                        link: 'Learn More',
                        target: '_blank',
                        href: 'https://www.niio.com/cookies',
                        theme: 'light-bottom',
                    },
                });
            }
            function initCookieConsent() {
                // Load Cookie consent if not from player
                // Cookie Consent plugin by Silktide - http://silktide.com/cookieconsent
                var styles = ['//cdnjs.cloudflare.com/ajax/libs/cookieconsent/3.1.1/cookieconsent.min.css'],
                    j = 0;
                var scripts = ['//cdnjs.cloudflare.com/ajax/libs/cookieconsent/3.1.1/cookieconsent.min.js'],
                    script,
                    i = 0;
                if (!$rootScope.fromPlayer && !$rootScope.fromIframe) {
                    for (i = 0; i < scripts.length; i++) {
                        script = document.createElement('script');
                        script.src = scripts[i];
                        document.getElementsByTagName('head')[0].appendChild(script);
                        script.addEventListener('load', setCookieConsent);
                    }
                    for (j = 0; j < styles.length; j++) {
                        var link = document.createElement('link');
                        link.href = styles[j];
                        link.type = 'text/css';
                        link.rel = 'stylesheet';
                        link.media = 'all';
                        document.getElementsByTagName('head')[0].appendChild(link);
                    }
                }
            }
            function initGlobals() {
                $rootScope.offlineMessageIsOpen = false;
                $rootScope.lastStateBeforeLogin = undefined;
                $rootScope.lastStateParamsBeforeLogin = {};
            }

            initGlobals();
            $rootScope.previousState = function (defaultState) {
                // $previousState.go().then(function(){
                //
                //     },
                //     function(error){
                //         if(defaultState!==undefined){
                //             $state.go(defaultState);
                //         }
                //     });
                if (defaultState !== undefined) {
                    $state.go(defaultState);
                }
            };

            $rootScope.$on('Auth:Failed', (event, params) => {
                $log.debug('Auth:Failed Clearing User from session');
                User.clearUser();

                // Device login should occour if device Id and secret appear
                if (!$rootScope.duringDeviceLogin) {
                    if (
                        $rootScope.lastStateParamsBeforeLogin &&
                        $rootScope.lastStateParamsBeforeLogin.deviceId &&
                        $rootScope.lastStateParamsBeforeLogin.secret
                    ) {
                        $log.debug('Auth:Failed going to login using device credentials');

                        Security.deviceLogin(
                            $rootScope.lastStateParamsBeforeLogin.deviceId,
                            $rootScope.lastStateParamsBeforeLogin.secret
                        ).then(
                            (res) => {
                                $state.go($rootScope.lastStateBeforeLogin, $rootScope.lastStateParamsBeforeLogin);
                            },
                            (error) => {
                                $state.go('login', $rootScope.lastStateParamsBeforeLogin); // TODO - Fix this, it's not redirecting to login page
                            }
                        );
                    } else {
                        // if ($stateParams.share) {
                        if (
                            $state.current &&
                            ($state.current.name === 'discover.artwork' ||
                                $state.current.name === 'discover.channel' ||
                                $state.current.name.indexOf('profile') === 0)
                        ) {
                            OpenPopupForm.openMenu('join', params);
                        } else {
                            // What else when user is not authenticated?
                            $log.debug('Auth:Failed Redirecting to login');
                            if (
                                $state.current &&
                                ($state.current.name === 'discover.artwork' ||
                                    $state.current.name === 'discover.channel' ||
                                    $state.current.name === 'plan-subscribe')
                            ) {
                                saveLastStateBeforeLogin($state.current, $state.params);
                            }

                            //TODO: Delete - we use inline login.... $rootScope.lastStateParamsBeforeLogin.o = true; // open login or join form
                            if ($rootScope.lastStateBeforeLogin === 'plan-subscribe') {
                                $rootScope.lastStateParamsBeforeLogin.o = 'j'; // open join from the plans page
                                $state.go('plans', $rootScope.lastStateParamsBeforeLogin);
                            } else {
                                $state.go('home-page', $rootScope.lastStateParamsBeforeLogin); // TODO - Fix this, it's not redirecting to login page
                            }
                        }
                    }
                }
            });

            function nonLoginRequiredState(state) {
                return (
                    state === 'login' ||
                    state.name === 'login' ||
                    state === 'join' ||
                    state.name === 'discover.artwork' ||
                    state === 'discover.artwork' ||
                    state.name === 'discover.channel' ||
                    state === 'discover.channel' ||
                    state.name === 'join' ||
                    state === 'home-page' ||
                    state.name === 'home-page' ||
                    state === 'invite' ||
                    state.name === 'invite' ||
                    state === 'signin' ||
                    state.name === 'signin' ||
                    state === 'plans' ||
                    state.name === 'plans' ||
                    state === 'pair' ||
                    state.name === 'pair'
                );
            }

            function onlyLoggedOutState(state) {
                return (
                    state === 'login' ||
                    state.name === 'login' ||
                    state === 'join' ||
                    state.name === 'join' ||
                    state === 'home-page' ||
                    state.name === 'home-page' ||
                    state === 'invite' ||
                    state.name === 'invite' ||
                    state === 'signin' ||
                    state.name === 'signin'
                );
            }

            function userHasNecessaryRole(roles) {
                var authorized = !roles || roles.length === 0,
                    i = 0;

                while (!authorized && roles[i]) {
                    authorized = User.hasRole(roles[i]);
                    i++;
                }

                return authorized;
            }

            function saveLastStateBeforeLogin(toState, toParams) {
                if (
                    (toState.name === 'discover.artwork' ||
                        toState.name === 'discover.channel' ||
                        toState.name === 'plan-subscribe' ||
                        toState.name === 'delete-account' ||
                        toState.name === 'add-device' ||
                        toParams.url ||
                        toParams.eventCode ||
                        toParams.share ||
                        $rootScope.fromPlayer ||
                        $rootScope.webFromPlayer) &&
                    toState.name !== ''
                ) {
                    $log.debug('save state the user wanted to go to after login');
                    $rootScope.lastStateBeforeLogin = toState.name;
                    $rootScope.lastStateParamsBeforeLogin = toParams;

                    if (toState.name === 'delete-account') {
                        OpenDeepLink.func(NIIO_APP + 'delete-account').then(function (res) {
                            // Successfully opened the link in the app
                            return;
                        });
                        let messageParams = {};
                        messageParams.title = 'Delete Account';
                        messageParams.message = 'Please login to delete your account.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                }
            }

            //        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
                if ($rootScope.duringDeviceLogin || $location.search().ModalBackFromPayment) {
                    return;
                }

                $log.debug(
                    'Coming from: ' +
                        fromState.name +
                        ' with params: ' +
                        JSON.stringify(fromParams) +
                        ' And going to: ' +
                        toState.name +
                        ' with params: ' +
                        JSON.stringify(toParams)
                );
                event.preventDefault();
                $urlRouter.update(true);
                var redirectToDifferentState = false;
                var firstTimeFromPlayer; // In order to init the Cookie Consent only if not from player
                $rootScope.navbarCollapsed = true; // Close open menu in header

                // if (fromParams.preventRedirect) {
                //     redirectToDifferentState = true;
                // }

                if (toParams.token) {
                    redirectToDifferentState = true;
                    User.tryLoginWithQueryParams(toParams).then(() => {
                        $state
                            .go(toState, toParams, {
                                notify: false,
                            })
                            .then(function () {
                                $rootScope.$broadcast('$stateChangeSuccess', toState, toParams, fromState, fromParams);
                            });
                    });
                }

                // From device?
                if ($rootScope.fromPlayer === undefined) {
                    // first time check from player
                    firstTimeFromPlayer = true;
                }

                $rootScope.fromPlayer = toParams.deviceId && toParams.secret ? true : false;
                $rootScope.fromIframe = toParams.fromIframe || $rootScope.fromTvOS;
                if (firstTimeFromPlayer) {
                    initCookieConsent();
                }

                $rootScope.webFromPlayer = toParams.deviceId && toParams.shortSecret ? true : false; // Scanned QR code
                $rootScope.homepage = toState === 'home-page' || toState.name === 'home-page';

                if (toState.data && toState.data.security && toState.data.security.secured) {
                    $log.debug('security check from $stateChangeStart event!');

                    if (User.isAuthenticated() && !userHasNecessaryRole(toState.data.security.roles)) {
                        $log.debug(
                            '$stateChangeStart::SecurityCheck user is authenticated but not allowed for this page.'
                        );
                        redirectToDifferentState = true;
                        saveLastStateBeforeLogin(toState, toParams);
                        $state.go('home', toParams);
                        return;
                    } else if (!User.isAuthenticated()) {
                        $log.debug('$stateChangeStart::SecurityCheck user is not authenticated.');
                        redirectToDifferentState = true;
                        saveLastStateBeforeLogin(toState, toParams);
                        $rootScope.$broadcast('Auth:Failed');
                        return;
                    }
                }

                if (toState.data && toState.data.fromMobile) {
                    $log.debug('Checking mobile limitations');

                    var isAllowed = toState.data.fromMobile.allowed.indexOf($rootScope.smartPhoneName) > -1;

                    if (!$rootScope.isSmartPhone || !isAllowed) {
                        $log.debug(
                            '$stateChangeStart::MobileCheck user is not from the mobile device required.',
                            toState.data.fromMobile,
                            $rootScope.smartPhoneName
                        );
                        redirectToDifferentState = true;
                        saveLastStateBeforeLogin(toState, toParams);
                        $state.go('home');
                    }
                }

                if (toState.data && toState.data.discoverPassword) {
                    if (!PasswordProtectLink.isAuthorized()) {
                        redirectToDifferentState = true;
                        PasswordProtectLink.openPassword(toState.name);
                    }
                }

                // onEnter - Join / Login
                if (nonLoginRequiredState(toState)) {
                    if ((toState === 'login' || toState.name === 'login') && !$rootScope.fromPlayer) {
                        redirectToDifferentState = true;
                        $rootScope.homepage = true;
                        $state.go('signin', {}, { notify: false }).then(function () {
                            // line 907 state.js
                            $rootScope.$broadcast('$stateChangeSuccess', 'signin', {}, fromState, fromParams);
                        });
                    }

                    if (User.isAuthenticated() && onlyLoggedOutState(toState)) {
                        // redirect if authenticated
                        redirectToDifferentState = true;
                        if (
                            $rootScope.fromPlayer &&
                            $rootScope.lastStateBeforeLogin &&
                            fromState.name !== $rootScope.lastStateBeforeLogin
                        ) {
                            $log.debug('going to last state: ' + $rootScope.lastStateParamsBeforeLogin);
                            $rootScope.homepage = false;
                            $state
                                .go($rootScope.lastStateBeforeLogin, $rootScope.lastStateParamsBeforeLogin, {
                                    notify: false,
                                })
                                .then(function () {
                                    // line 907 state.js
                                    $rootScope.$broadcast(
                                        '$stateChangeSuccess',
                                        $rootScope.lastStateBeforeLogin,
                                        $rootScope.lastStateParamsBeforeLogin,
                                        fromState,
                                        fromParams
                                    );
                                });
                        } else {
                            if (
                                toState !== undefined &&
                                toState.name !== undefined &&
                                ['signin', 'join'].indexOf(toState.name) >= 0
                            ) {
                                //PATCH: Add cookie to "old users" if they are loggedin.
                                User.tryRestoreCookie();
                            }

                            $log.debug('going to home');
                            $rootScope.homepage = false;
                            $state.go('home', toParams, { notify: false }).then(function () {
                                // line 907 state.js
                                $rootScope.$broadcast('$stateChangeSuccess', 'home', {}, fromState, fromParams);
                            });
                        }
                        //$rootScope.lastStateBeforeLogin = null;
                        //$rootScope.lastStateParamsBeforeLogin = {};
                    }

                    if (toParams.share) {
                        saveLastStateBeforeLogin(toState, toParams);
                    }
                }

                // onExit - Join / Login
                if (nonLoginRequiredState(fromState)) {
                    if (
                        $rootScope.fromPlayer &&
                        $rootScope.lastStateBeforeLogin &&
                        toState.name !== 'login' &&
                        toState.name !== 'join' &&
                        toState.name !== 'home-page'
                    ) {
                        redirectToDifferentState = true;
                        $rootScope.homepage = false;
                        $state
                            .go($rootScope.lastStateBeforeLogin, $rootScope.lastStateParamsBeforeLogin, {
                                notify: false,
                            })
                            .then(function () {
                                // line 907 state.js
                                $rootScope.$broadcast(
                                    '$stateChangeSuccess',
                                    $rootScope.lastStateBeforeLogin,
                                    $rootScope.lastStateParamsBeforeLogin,
                                    fromState,
                                    fromParams
                                );
                            });
                        //$rootScope.lastStateBeforeLogin = null;
                        //$rootScope.lastStateParamsBeforeLogin = {};
                    }
                }

                // mobile profile - member - redirect to mobile-profile to catch in mobile app.
                if (
                    toState.name.indexOf('profile') === 0 &&
                    $rootScope.isSmartPhone &&
                    fromState.name !== 'mobile-profile' &&
                    fromState.name.indexOf('profile') < 0
                ) {
                    // Redirect to member/url
                    redirectToDifferentState = true;
                    $state.go('mobile-profile', toParams, { notify: false }).then(function () {
                        // line 907 state.js
                        $rootScope.$broadcast('$stateChangeSuccess', 'profile-mobile', toParams, fromState, fromParams);
                    });
                }

                // For delete-account, redirect to home and open the delete account modal
                if (toState.name === 'delete-account') {
                    redirectToDifferentState = true;
                    // add to toParams deleteAccount: true
                    if (!toParams) {
                        toParams = {};
                    }
                    toParams.deleteAccount = true;
                    $state.go('home', toParams, { notify: false }).then(function () {
                        // line 907 state.js
                        $rootScope.$broadcast('$stateChangeSuccess', 'home', toParams, fromState, fromParams);
                    });
                }

                $log.debug('redirect to different state: ' + redirectToDifferentState);
                if (!redirectToDifferentState) {
                    //                event.preventDefault();
                    //                $urlRouter.sync();
                    $state.go(toState.name, toParams, { notify: false }).then(function () {
                        // line 907 state.js
                        $rootScope.$broadcast('$stateChangeSuccess', toState, toParams, fromState, fromParams);
                    });
                }
            });

            //        $rootScope.$on('$locationChangeStart', function (event, next, current) {
            //            $rootScope.$broadcast('$locationChangeSuccess', next, current);
            //        });

            $rootScope.backFromPayment = function () {
                if ($rootScope.backFromPaymentBool) {
                    var messageParams = {};
                    messageParams.message =
                        'Thank you for the payment! We are processing the transaction, please wait...';
                    messageParams.alertType = 'success';
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        $rootScope.pendingPayment = true;
                        BillingService.enablePollingAfterPayment();
                    });
                }
            };

            $rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
                // Catch redirect event. probably when the billing process ends in the iframe and it wants to communicate to the parent to reload
                var qs = $location.search();

                if (qs.rdr) {
                    var location;
                    $rootScope.redirecting = true;
                    $rootScope.$broadcast('ajax-start');
                    $log.debug('Redirect is happening', qs);
                    if (qs.mobile) {
                        var referrer = qs.referrer ? qs.referrer : document.referrer;
                        location = referrer + '#' + $location.path();
                        //alert(location);
                        //$window.top.location = location; - for iFrame
                        $window.location = location;
                    } else {
                        location = $window.location.href.replace('&rdr=true', '');
                        location = location.replace('rdr=true&', '');
                        location = location.replace('rdr=true', '');
                        //$window.top.location.href = location; - for iFrame
                        $window.location.href = location;
                    }
                } else if (qs.hostedpage_id && !qs.ModalBackFromPayment) {
                    $rootScope.backFromPaymentBool = true;
                    $rootScope.backFromPayment();
                } else if (qs.o && qs.o === 'plans') {
                    $timeout(function () {
                        OpenPopupForm.openMenu('plans', { fromModal: true });
                    }, 2000);
                }
            });

            $rootScope.$on('$stateNotFound', function (event, unfoundState, fromState, fromParams) {
                $log.debug('State not found, To: ' + unfoundState.to); // "lazy.state"
                $log.debug('State not found, ToParams: ' + JSON.stringify(unfoundState.toParams)); // {a:1, b:2}
                $log.debug('State not found, ToOptions: ' + JSON.stringify(unfoundState.options)); // {inherit:false} + default options
            });

            $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
                $log.debug('State change error, Error: ' + error.message); // "lazy.state"
                $log.debug('State change error, From: ' + fromState.name); // "lazy.state"
                $log.debug('State change error, To: ' + toState.name); // "lazy.state"
            });

            $rootScope.$on('$routeChangeError', function (event, current, previous, rejection) {
                $log.debug('Route change error, From: ' + previous.name); // "lazy.state"
                $log.debug('Route change error, To: ' + current.name); // "lazy.state"
            });

            $rootScope.$on('$routeChangeStart', function (event, current, previous, rejection) {
                $log.debug('Route change start, From: ' + previous.name); // "lazy.state"
                $log.debug('Route change start, To: ' + current.name); // "lazy.state"
            });

            $rootScope.$on('$routeChangeSuccess', function (event, current, previous, rejection) {
                $log.debug('Route change success, From: ' + previous.name); // "lazy.state"
                $log.debug('Route change success, To: ' + current.name); // "lazy.state"
            });

            $rootScope.$on('user_is_offline', function (event) {
                if (!$rootScope.offlineMessageIsOpen) {
                    //var messageParams = {};
                    //messageParams.title = 'Network Status';
                    //messageParams.message = 'Seems that you network is offline, please connect to the internet and try again.';
                    //messageParams.disableAutoDismiss = true;
                    //Messages.openMessage($rootScope, messageParams)
                    //    .then(function(res) {
                    //        $rootScope.offlineMessageIsOpen = false;
                    //    });
                    //$rootScope.offlineMessageIsOpen = true;
                }
            });

            $rootScope.$on('ClearData', function (event) {
                initGlobals();
            });

            function stopAllRequests() {
                // Stop downloading of other images if coming from other page
                //cancel image downloads - taken from: http://stackoverflow.com/questions/930237/javascript-cancel-stop-image-requests
                if (window.stop !== undefined) {
                    window.stop();
                } else if (document.execCommand !== undefined) {
                    document.execCommand('Stop', false);
                }
                dispatchEvent(new Event('load'));
            }

            $rootScope.increaseImportantRequests = function () {
                $rootScope.importantRequestsRunning++;
            };

            $rootScope.decreaseImportantRequests = function () {
                $rootScope.importantRequestsRunning--;
            };
            $rootScope.$on('stop_all_requests', function (event) {
                if ($rootScope.importantRequestsRunning === 0) {
                    stopAllRequests();
                }
            });

            // When interacting with a tab
            $window.onfocus = function () {
                if (!$rootScope.isTabActive) {
                    $rootScope.$emit('tab_turned_active');
                }

                $rootScope.isTabActive = true;
            };

            angular.element($window).bind('blur', function () {
                if ($rootScope.isTabActive) {
                    $rootScope.$emit('tab_turned_inactive');
                }

                $rootScope.isTabActive = false;
            });

            // if (!document.hidden) {
            //     if ($rootScope.isTabActive) {
            //         $rootScope.$emit('tab_turned_inactive');
            //     }
            //
            //     $rootScope.isTabActive = false;
            //     alert('super inactive');
            // }
            window.onbeforeunload = function (e) {
                var Event = e || window.event;
                var message;

                $log.debug(Event);

                if (S3Uploader.isUploading()) {
                    message =
                        'You still have artwork uploading to the platform.\nIf you exit Niio, all uploads will be cancelled.';
                }

                // For IE and Firefox
                if (Event && message) {
                    Event.returnValue = message;
                }

                // For Safari
                return message;
            };
        },
    ]);
