'use strict';

angular.module('player').service('PlayerService', [
    '$q',
    function ($q) {
        this.isImage = function (src) {
            var deferred = $q.defer();

            var image = new Image();
            image.onerror = function () {
                deferred.resolve(false);
            };
            image.onload = function () {
                deferred.resolve(true);
            };
            image.src = src;

            return deferred.promise;
        };
    },
]);
