'use strict';

angular.module('home').controller('HomeEventsHostController', [
    '$rootScope',
    '$filter',
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$timeout',
    '$http',
    '_',
    'smoothScroll',
    'HomeDataService',
    'EventsDataService',
    'Messages',
    'EVENT_TYPES',
    function (
        $rootScope,
        $filter,
        $scope,
        $state,
        $stateParams,
        $log,
        $q,
        $timeout,
        $http,
        _,
        smoothScroll,
        HomeDataService,
        EventsDataService,
        Messages,
        EVENT_TYPES
    ) {
        $scope.$state = $state;
        $scope.collapsibles = {};
        $scope.HomeDataService = HomeDataService;
        $scope.EventsDataService = EventsDataService;
        $scope.eventId = $stateParams.eventId;
        $scope.maxArtworksSubmitted = 0;

        function getRows() {
            var deferred = $q.defer();
            var result = [];

            // get questions

            $q.all([
                HomeDataService.getEventHostData(
                    $stateParams.eventId,
                    $scope.profileId,
                    false /* show not only submitted */
                ),
                HomeDataService.getAllSubmissionsFormQuestions($stateParams.eventId, $scope.profileId),
            ]).then(
                function (res) {
                    var event = res[0];
                    var answers = res[1].data;
                    var foundArtworks;

                    if (event) {
                        if (event.submissions) {
                            event.submissions.forEach(function (submission) {
                                foundArtworks = [];

                                if (event.artworks) {
                                    foundArtworks = _.filter(event.artworks, function (artwork) {
                                        return artwork.uploader.user_id === submission.user_id;
                                    });
                                }

                                result.push({
                                    artworks: foundArtworks,
                                    submission: submission,
                                    user: submission.user,
                                    answers: answers[submission.user.id],
                                });
                            });
                        }
                    } else {
                        deferred.reject();
                    }

                    deferred.resolve(result);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        $scope.init = function () {
            $scope.loadingEvent = true;
            $scope.formattedToday = $filter('date')(new Date(), 'dd/MM/yyyy');

            HomeDataService.getEventHostData($stateParams.eventId, $scope.profileId)
                .then(function (eventData) {
                    $scope.artworks = eventData.artworks;
                    $scope.artworks.forEach(function (artwork) {
                        var submitterId = artwork.artwork_submission.user_id;
                        var submissions = eventData.submissions.find(function (submission) {
                            return submission.user_id === submitterId;
                        });
                        artwork.artwork_submission.submittedDate = submissions
                            ? submissions.submitted
                            : undefined;
                    });
                    $scope.$emit('event_selected', eventData, 'host');
                })
                .finally(function () {
                    $scope.loadingEvent = false;
                });

            // Load data for report
            $scope.loadingDataForReport = true;
            getRows()
                .then(function (rows) {
                    $scope.reportRows = rows;
                    // var groupedByArtworkSubmissions = _.groupBy($scope.artworks, function(artwork) {
                    //     return artwork.artwork_submission.user_id;
                    // });

                    var maxArtworks = _.max($scope.reportRows, function (row) {
                        return row.artworks.length;
                    });

                    if (maxArtworks && maxArtworks.artworks) {
                        $scope.maxArtworksSubmitted = maxArtworks.artworks.length;
                    }
                })
                .finally(function () {
                    $scope.loadingDataForReport = false;
                });
        };

        $scope.setActionTitle = function (artwork) {
            if ($scope.currentEvent.event.type === EVENT_TYPES.OPEN_CALL) {
                return artwork.is_accepted ? 'DISCARD' : 'ADD TO FINALISTS';
            } else if ($scope.currentEvent.event.type === EVENT_TYPES.CONSIGN_SUBMISSION) {
                return artwork.is_consignee ? 'DISCARD' : 'ACCEPT';
            }
        };

        $scope.submitList = function (listName) {
            var uppercaseList = $filter('upperFirstLetter')(listName);

            HomeDataService.submitList($scope.currentEvent.event.id, $scope.userId, listName).then(
                function (res) {
                    var messageParams = {};
                    messageParams.title = uppercaseList + ' Submitted';
                    messageParams.message =
                        'The ' +
                        listName +
                        ' selection was submitted successfully and will be reviewed, thank you.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.title = 'Failed to Submit ' + uppercaseList;
                    messageParams.message =
                        "We're sorry but the " +
                        listName +
                        ' selection could not be submitted at this time, please refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        };

        $scope.$on('event_submission_comments_changed', function ($event, artworkId, newValue, eventId) {
            var foundArtwork = $scope.artworks.find(function (artwork) {
                return artwork.id === artworkId;
            });
            if ($scope.eventId === eventId && foundArtwork) {
                foundArtwork.artwork_submission.judge_comments.comments = newValue;
            }
        });
    },
]);
