'use strict';

angular.module('admin-area').controller('AdminAreaStatusesController', [
    '$state',
    function ($state) {
        var self = this;
        this.pages = [
            {
                name: 'Artworks',
                link: 'artworks',
            },
            {
                name: 'Transfers',
                link: 'transfers',
            },
            {
                name: 'Displays',
                link: 'displays',
            },
            {
                name: 'Users',
                link: 'users',
            },
            {
                name: 'Totals',
                link: 'totals',
            },
            {
                name: 'Activities',
                link: 'activities',
            },
        ];

        this.init = function () {
            $state.go('admin-area.statuses.' + self.pages[0].link);
        };
    },
]);
