'use strict';

angular.module('home').controller('HomeCollectionsMainController', [
    'artworks',
    'ArtworkService',
    'User',
    '$log',
    '$rootScope',
    '$scope',
    '$localStorage',
    '$q',
    '$http',
    '$state',
    '$stateParams',
    '$timeout',
    '$filter',
    '_',
    'S3Uploader',
    'HomeDataService',
    'Messages',
    'OpenPopupForm',
    function (
        artworks,
        ArtworkService,
        User,
        $log,
        $rootScope,
        $scope,
        $localStorage,
        $q,
        $http,
        $state,
        $stateParams,
        $timeout,
        $filter,
        _,
        S3Uploader,
        HomeDataService,
        Messages,
        OpenPopupForm
    ) {
        //$scope.S3Uploader = S3Uploader;
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.HomeDataService = HomeDataService;
        $scope.$filter = $filter;
        $scope.menu = $stateParams.collectionLink;
        $scope.collectionLink = $stateParams.collectionLink;
        $scope.collectionId = $stateParams.collectionId;
        $scope.ArtworkService = ArtworkService;
        $scope.pollingEnabled = !$localStorage.pushy || !$localStorage.pushy.notificationIsGranted;
        $scope.cleanupFuncs = [];

        var timeoutArray = [];
        var counter = 0;

        $scope.Pagination = {
            itemsPerPage: 100,
            currentPage: 1,
            maxSize: 5,
            totalItems: 10,
            isRequire: function () {
                return this.itemsPerPage < $scope.filtertedArtwork.length;
            },
            setPage: function (pageNo) {
                this.currentPage = pageNo;
            },
        };

        $scope.$watch('artistFilter.artist.display_name', function () {
            $scope.Pagination.setPage(1);
        });

        $scope.search = {
            enable: false,
            text: '',
            filterArtworks: function (artwork) {
                if (!$scope.search.enable || $scope.search.text === '') {
                    return true;
                }

                var lowerCasedSearchText = $scope.search.text.toLowerCase();

                if (
                    artwork.title.toLowerCase().indexOf(lowerCasedSearchText) !== -1 ||
                    (artwork.other_categorization_id &&
                        artwork.other_categorization_id.toLowerCase().indexOf(lowerCasedSearchText) !== -1) ||
                    (artwork.artist.display_name &&
                        artwork.artist.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1)
                ) {
                    return true;
                }
                return false;
            },
        };
        $scope.toggleSearch = function () {
            $scope.search.text = '';
            if ($scope.search.enable) {
                $scope.search.text = '';
                $scope.search.enable = false;
            } else {
                $scope.search.enable = true;
            }
        };

        function matchUploadId(artworkId) {
            var result = '',
                i = 0;

            for (i; i < S3Uploader.uploads.length; i++) {
                if (S3Uploader.uploads[i] && S3Uploader.uploads[i].extraParams) {
                    if (
                        artworkId === S3Uploader.uploads[i].extraParams.artworkId &&
                        !S3Uploader.uploads[i].deleted &&
                        !S3Uploader.uploads[i].extraParams.isPreviewFile
                    ) {
                        $log.debug(
                            'HomeCollectionsMainController::matchUploadId found upload for artwork ' +
                                artworkId,
                            S3Uploader.uploads[i]
                        );
                        result = S3Uploader.uploads[i].id;
                        break;
                    }
                }
            }

            return result;
        }

        function updateArtworkWithUploadId(artworkId, uploadId) {
            var i;

            for (i = 0; i < $scope.artworks.length; i++) {
                if (artworkId === $scope.artworks[i].id) {
                    $scope.artworks[i].uploadId = uploadId;
                    break;
                }
            }
        }

        function addTimeout(artwork) {
            artwork.retryNumber = 0;
            artwork.retryGap = 30000;
            $scope.checkAgainIfArtworkIsReady(artwork, counter);
            counter++;
        }

        function addNoneReadyArtworkToQueue(artwork) {
            var addArtwork = false;
            if (
                artwork.status === 'UPLOADING' ||
                artwork.status === 'PENDING' ||
                artwork.status === 'PREVIEW_READY'
            ) {
                addArtwork = true;
                artwork.progress = 100;
            }

            if (addArtwork && $scope.pollingEnabled) {
                var alreadyInNoneReadyArtworks = _.find(
                    $scope.noneReadyArtworks,
                    function (noneReadyArtwork) {
                        return noneReadyArtwork.id === artwork.id;
                    }
                );
                if (!alreadyInNoneReadyArtworks) {
                    $scope.noneReadyArtworks.push(artwork);
                    addTimeout(artwork);
                }
            }
        }

        function addNoneReadyArtworksToQueue() {
            $scope.artworks.forEach(function (artwork) {
                if (
                    artwork.status === 'UPLOADING' ||
                    artwork.status === 'UPLOAD_FAILED' ||
                    artwork.status === 'UPLOAD_ABORTED'
                ) {
                    artwork.uploadId = matchUploadId(artwork.id);
                }
                addNoneReadyArtworkToQueue(artwork);
            });
        }

        function replaceNoneReadyArtworkWithReadyArtwork(artwork, status) {
            var deferred = $q.defer();
            if (artwork.status === 'UPLOADING') {
                $log.debug(
                    'HomeCollectionsMainController::replaceNoneReadyArtworkWithReadyArtwork - status is UPLOADING'
                );
                if (status !== 'UPLOADING') {
                    // changed from uploading to new status (probably from Filestack)
                    $log.debug(
                        'HomeCollectionsMainController::replaceNoneReadyArtworkWithReadyArtwork - changed from uploading to new status ' +
                            status
                    );
                    artwork.status = status;
                    for (var i = 0; i < $scope.artworks.length; i++) {
                        if ($scope.artworks[i].id === artwork.id) {
                            $scope.artworks[i].status = status;
                            break;
                        }
                    }
                }
                deferred.resolve(false);
            } else if (status !== 'PENDING') {
                $log.debug(
                    'HomeCollectionsMainController::replaceNoneReadyArtworkWithReadyArtwork - if status is not pending we will fetch the artwork data',
                    artwork,
                    status
                );
                ArtworkService.fetchArtworkData(artwork.id).then(
                    function (res) {
                        $log.debug(
                            'HomeCollectionsMainController::replaceNoneReadyArtworkWithReadyArtwork::ArtworkService.fetchArtworkData - fetched artwork data',
                            res.data.data.status,
                            res.data.data
                        );

                        var newArtwork = res.data.data;

                        //Fix fields arrived from server
                        newArtwork.order_in_collection = parseInt(newArtwork.order_in_collection);
                        newArtwork.order_in_collection = isNaN(newArtwork.order_in_collection)
                            ? ''
                            : newArtwork.order_in_collection;

                        // Replace current Artwork with the READY one
                        var i,
                            oldArtworkIndex = -1;

                        for (i = 0; i < $scope.artworks.length; i++) {
                            if ($scope.artworks[i].id === newArtwork.id) {
                                oldArtworkIndex = i;
                                break;
                            }
                        }

                        $scope.artworks[oldArtworkIndex] = newArtwork;

                        // If preview ready state, it still needed to check status
                        if (status !== 'PREVIEW_READY') {
                            if ($scope.pollingEnabled) {
                                // Find artwork in noneReadyArtworks array
                                var indexInNoneReadyArtworks = -1;
                                for (i = 0; i < $scope.noneReadyArtworks.length; ++i) {
                                    if ($scope.noneReadyArtworks[i].id === newArtwork.id) {
                                        indexInNoneReadyArtworks = i;
                                        break;
                                    }
                                }

                                // If exists, remove it from pending artworks
                                if (indexInNoneReadyArtworks > -1) {
                                    $scope.noneReadyArtworks.splice(indexInNoneReadyArtworks, 1);
                                    deferred.resolve(true);
                                }
                            } else {
                                deferred.resolve(true);
                            }
                        } else {
                            deferred.resolve(false);
                        }
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.resolve(false);
            }

            return deferred.promise;
        }

        var extendAtrworkFields = function (artworks) {
            angular.forEach(artworks, function (artwork) {
                artwork.order_in_collection = parseInt(artwork.order_in_collection);
                artwork.order_in_collection = isNaN(artwork.order_in_collection)
                    ? ''
                    : artwork.order_in_collection;
                artwork.numericPrice = artwork.price ? parseInt(artwork.price) : 0;
                artwork.durationInSec = artwork.file_metadata.video_duration
                    ? Date.parse('Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC')
                    : null;
                artwork.numericFileSize =
                    artwork.file_metadata.size && artwork.file_metadata.size
                        ? parseInt(artwork.file_metadata.size)
                        : 0;
                artwork.isOwner = artwork.isOwner || artwork.user_id === User.getId();
                artwork.canTransfer = artwork.status === 'READY' && (artwork.isOwner || User.isAdmin());
                artwork.canEdit = artwork.isOwner || User.isAdmin();
                artwork.editLink =
                    $scope.fromState +
                    '.' +
                    $scope.currentState +
                    'Upload({ collectionId: ' +
                    $scope.collectionId +
                    ", collectionLink: '" +
                    $scope.collectionLink +
                    "', artworkId: " +
                    artwork.id +
                    ' })';
                artwork.canDelete = artwork.isOwner || User.isAdmin();
                artwork.isMultiActionsEnable = artwork.canDelete;
            });
        };

        // Grid
        $scope.orderByField = 'Artwork.title';
        $scope.reverseSort = false;

        var validViewTypes = ['thumbnail', 'list', 'sort'];
        $scope.setViewType = function (newV) {
            if (validViewTypes.indexOf(newV) < 0) {
                return;
            }

            $scope.sortArtworkByCollectionOrder(); //Default sorting
            HomeDataService.setViewType(newV);
        };

        $scope.toggleSortViewType = function () {
            $scope.sortArtworkByCollectionOrder(); //Default sorting
            if (HomeDataService.viewType !== 'sort') {
                $scope.setViewType('sort');
            } else {
                $scope.setViewType(HomeDataService.prevViewType);
            }
        };

        $scope.init = function (initArtworks) {
            $scope.loadingArtworks = true;
            // Loaded artworks using $timeout to enable animation on them.
            $scope.artworks = [];
            $scope.noneReadyArtworks = [];
            $timeout(function () {
                $scope.artworks = initArtworks || artworks || [];
                $scope.Pagination.totalItems = $scope.artworks.length;
                extendAtrworkFields($scope.artworks);
                addNoneReadyArtworksToQueue();
                $scope.sortArtworkByCollectionOrder();
                if (!$scope.isSortable($scope.collectionId) && HomeDataService.viewType === 'sort') {
                    $scope.setViewType('list');
                }
                $scope.countMaxSelectableAWs();
                $scope.loadingArtworks = false;

                $scope.$emit(
                    'HomeCollectionsMainController:collection_chosen',
                    $scope.collectionId,
                    $scope.artworks
                );
            }, 100);
            $scope.S3Uploader = S3Uploader;
        };
        $scope.refreshArtworks = () => {
            $rootScope.$broadcast('ajax-start');
            HomeDataService.getCollectionArtworks(
                $scope.collections,
                $stateParams.collectionId,
                $stateParams.collectionLink,
                false
            ).then(function (artworks) {
                $scope.init(artworks);
                $rootScope.$broadcast('ajax-stop');
            });
        };

        const cloudUploadsEvent = $scope.$on(
            'HomeCollectionsMainController::refresh_collection_artworks',
            (event) => {
                $scope.refreshArtworks();
            }
        );
        $scope.cleanupFuncs.push(cloudUploadsEvent);

        $scope.isSortable = function (collectionId) {
            return ['0', '-1', '-2'].indexOf(collectionId) < 0;
        };

        //        $scope.$on('HomeArtworkController:artwork_deleted', function (event, artworkId) {
        //            var artworkIndex = -1,
        //                i;
        //
        //            for (i = 0; i < $scope.artworks.length; i++) {
        //                if ($scope.artworks[i].Artwork.id === artworkId) {
        //                    artworkIndex = i;
        //                    break;
        //                }
        //            }
        //
        //            if (artworkIndex > 0) {
        //                $scope.artworks.splice(artworkIndex, 1);
        //            }
        //        });

        $rootScope.$on('artwork_deleted_multiple', function (event) {
            $scope.selectAllSync();
        });

        $rootScope.$on('artwork_deleted_multiple_error', function (event) {
            $scope.selectAllSync();
        });

        // TODO - when synced with server this won't get called
        $rootScope.$on('S3Uploader:upload_synced_with_server', function (event, artworkId, status) {
            $log.debug('event activated: Synced upload with server');

            var artwork = _.find($scope.artworks, function (artwork) {
                return artwork.id === artworkId;
            });

            if (artwork) {
                $log.debug('found artwork: Synced upload with server');
                artwork.status = status;
            }
        });

        $scope.checkAgainIfArtworkIsReady = function (artwork, index) {
            if (artwork.status !== 'READY') {
                ArtworkService.getArtworkStatus(artwork.id).then(
                    function (res) {
                        if (res.data.status === undefined) {
                            //We did not get a real answer...
                            console.log('getArtworkStatus failed.', res.data);
                            return;
                        }
                        replaceNoneReadyArtworkWithReadyArtwork(artwork, res.data.status).then(
                            function (finishedReplace) {
                                if (artwork.retryNumber > 20) {
                                    artwork.retryGap = 300000;
                                }
                                if (finishedReplace) {
                                    if ($scope.noneReadyArtworks.length === 0) {
                                        $timeout.cancel(timeoutArray[index]);
                                    }
                                } else {
                                    artwork.retryNumber++;
                                    timeoutArray[index] = $timeout(function () {
                                        $scope.checkAgainIfArtworkIsReady(artwork, index);
                                    }, artwork.retryGap);
                                }
                            },
                            function (error) {
                                $log.debug('FetchArtworkDataError', error);
                                timeoutArray[index] = $timeout(function () {
                                    $scope.checkAgainIfArtworkIsReady(artwork, index);
                                }, artwork.retryGap);
                            }
                        );
                    },
                    function (error) {
                        $log.debug('getArtworkStatus', error);
                        timeoutArray[index] = $timeout(function () {
                            $scope.checkAgainIfArtworkIsReady(artwork, index);
                        }, artwork.retryGap);
                    }
                );
            }
        };

        $scope.toggleWishlist = function (artwork, index) {
            var deferred = $q.defer();
            var prevWishlist = artwork.in_wishlist;

            ArtworkService.handleArtworkAction('toggleWishlist', artwork).then(function (res) {
                if (prevWishlist !== artwork.in_wishlist) {
                    if ($state.includes('home') && !artwork.in_wishlist) {
                        var messageParams = {};
                        messageParams.message = 'Artwork was removed from your favorites';
                        Messages.openMessage($rootScope, messageParams);
                        $rootScope.$emit('artwork_deleted', artwork.id, $scope.collectionId);
                        // $scope.artworks.splice(index, 1);
                    }
                }
            });

            return deferred.promise;
        };

        $scope.toggleAddedArtwork = function (artwork, index) {
            var deferred = $q.defer();
            var prevAddedArtwork = artwork.is_added;

            ArtworkService.handleArtworkAction('toggleArtwork', artwork).then(function (res) {
                if (prevAddedArtwork !== artwork.is_added) {
                    if ($state.includes('home') && !artwork.is_added) {
                        var messageParams = {};
                        messageParams.message = 'Artwork was removed from your vault';
                        Messages.openMessage($rootScope, messageParams);
                        $rootScope.$emit('artwork_deleted', artwork.id, $scope.collectionId);
                    }
                }
            });

            return deferred.promise;
        };

        $scope.deleteArtwork = function (artwork, index) {
            var deferred = $q.defer(),
                messageParams = {};
            var uploadId = matchUploadId(artwork.id);

            messageParams.message = 'You are about to permanently Delete this artwork from your account.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete Artwork “' + artwork.title + '”';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    ArtworkService.handleArtworkAction('delete', artwork).then(
                        function (res) {
                            $rootScope.$emit('artwork_deleted', artwork.id, $scope.collectionId);
                            //$scope.artworks.splice(index, 1);
                            var messageParams = {};
                            messageParams.message = 'Successfully deleted the artwork';
                            messageParams.title = 'Delete Artwork';
                            Messages.openMessage($scope, messageParams);
                            if (uploadId >= 0) {
                                S3Uploader.deleteUpload(uploadId).then(
                                    function (res) {
                                        $log.debug('successfully deleted upload', res);
                                    },
                                    function (error) {
                                        $log.debug('failed to delete upload', error);
                                    }
                                );
                                $rootScope.$emit('artwork_deleted', artwork.id, $scope.collectionId);
                            }
                            deferred.resolve();
                        },
                        function (error) {
                            var messageParams = {};
                            var errorMessage = angular.isString(error)
                                ? error
                                : error && error.data && error.data.message && error.data.message.text
                                ? error.data.message.text
                                : '';
                            messageParams.message = 'Failed to delete the artwork. ' + errorMessage;
                            messageParams.title = 'Delete Artwork';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams).then(function (res) {
                                deferred.reject();
                            });
                        }
                    );
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        $scope.deleteSelectedArtworks = function () {
            var deferred = $q.defer(),
                messageParams = {};

            var suffix = $scope.multiSelection.numAWselected > 1 ? 's' : '';
            var messagesTitle = 'Delete Artwork' + suffix;
            messageParams.message =
                'You are about to permanently Delete ' +
                $scope.multiSelection.numAWselected +
                ' artwork' +
                suffix +
                ' from your account.';

            messageParams.message += '<ul>';
            for (var h = 0; h < $scope.artworks.length; h++) {
                if ($scope.artworks[h].selected) {
                    messageParams.message += '<li>' + $scope.artworks[h].title + '</li>';
                }
            }
            messageParams.message += '</ul>';

            messageParams.message += 'Are you sure you want to continue?';
            messageParams.title = messagesTitle;
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var promiseStack = [];

                    // Create a deletion promise (don't fire it yet)
                    var newDeleteAWPromise = function (artwork) {
                        return function () {
                            var deferred = $q.defer();
                            var uploadId = matchUploadId(artwork.id);
                            ArtworkService.handleArtworkAction('delete', artwork).then(
                                function (res) {
                                    $rootScope.$emit('artwork_deleted', artwork.id, $scope.collectionId);

                                    if (uploadId >= 0) {
                                        S3Uploader.deleteUpload(uploadId).then(
                                            function (res) {
                                                $log.debug('successfully aborted upload', res);
                                            },
                                            function (error) {
                                                $log.debug('failed to abort upload', error);
                                            }
                                        );
                                    }
                                    deferred.resolve();
                                },
                                function (error) {
                                    deferred.reject(error);
                                }
                            );

                            return deferred.promise;
                        };
                    };

                    for (var i = 0; i < $scope.artworks.length; i++) {
                        if ($scope.artworks[i].selected) {
                            var artwork = $scope.artworks[i];
                            promiseStack.push(newDeleteAWPromise(artwork));
                        }
                    }

                    // Fire the first promise in the queue
                    var fire = function () {
                        // If the queue has remaining items...
                        return (
                            promiseStack.length &&
                            // Remove the first promise from the array
                            // and execute it
                            promiseStack
                                .shift()()

                                // When that promise resolves, fire the next
                                // promise in our queue
                                .then(function () {
                                    return fire();
                                })
                        );
                    };

                    var loadingMessageParams = {};
                    loadingMessageParams.disableAutoDismiss = true;
                    loadingMessageParams.blur = true;
                    loadingMessageParams.hideHeader = true;
                    loadingMessageParams.hideFooter = true;
                    loadingMessageParams.title = messagesTitle;
                    loadingMessageParams.message = 'Please wait while deleting artwork' + suffix + '...';
                    Messages.openMessage($rootScope, loadingMessageParams);
                    // Begin the queue
                    fire().then(
                        function (res) {
                            $rootScope.$emit('artwork_deleted_multiple');

                            var messageParams = {};
                            messageParams.message = 'Successfully delete all artworks';
                            messageParams.title = 'Delete Multiple Artworks';
                            Messages.ok();
                            Messages.openMessage($scope, messageParams);

                            deferred.resolve();
                        },
                        function (error) {
                            var messageParams = {};
                            var statusText =
                                error.data !== undefined && error.data.message !== undefined
                                    ? error.data.message.text
                                    : error.statusText;
                            messageParams.message = 'Failed to delete artwork. (' + statusText + ' )';
                            messageParams.title = 'Delete Multiple Artworks';
                            messageParams.disableAutoDismiss = true;
                            Messages.ok();
                            Messages.openMessage($scope, messageParams).then(function (res) {
                                $scope.selectAllSync();
                                deferred.reject();
                            });
                            $rootScope.$emit('artwork_deleted_multiple_error');
                        }
                    );
                } else {
                    $rootScope.$emit('artwork_deleted_multiple_error');
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        $scope.multiSelection = {
            maxSelectableAWs: 0,
            selectAllAWs: false,
            anyAWselected: false,
            numAWselected: false,
            lastChecked: null,
            shiftKey: false,
        };
        $scope.onKeyChanged = function ($event) {
            $scope.multiSelection.shiftKey = $event.shiftKey;
        };

        $scope.countMaxSelectableAWs = function () {
            var numSelectable = 0;
            for (var i = 0; i < $scope.artworks.length; i++) {
                if ($scope.artworks[i].isMultiActionsEnable) {
                    numSelectable++;
                }
            }
            $scope.multiSelection.maxSelectableAWs = numSelectable;
        };

        $scope.selectAllToggle = function (newVal) {
            //var initialState = ($scope.multiSelection.numAWselected === $scope.multiSelection.maxSelectableAWs);
            //$scope.selectAllAWs = (newVal!==undefined) ? newVal:!initialState;
            $scope.multiSelection.selectAllAWs = newVal ? true : false;
            var numSelected = 0;
            for (var i = 0; i < $scope.artworks.length; i++) {
                if ($scope.artworks[i].isMultiActionsEnable) {
                    $scope.artworks[i].selected = newVal;
                    numSelected++;
                }
            }
            $scope.multiSelection.numAWselected = newVal ? numSelected : 0;
            $scope.multiSelection.anyAWselected = $scope.multiSelection.numAWselected > 0;
        };

        $scope.selectAllSync = function () {
            $scope.multiSelection.numAWselected = 0;
            for (var i = 0; i < $scope.artworks.length; i++) {
                if ($scope.artworks[i].selected) {
                    $scope.multiSelection.numAWselected++;
                }
            }
            $scope.multiSelection.selectAllAWs =
                $scope.multiSelection.numAWselected === $scope.multiSelection.maxSelectableAWs;
            $scope.multiSelection.anyAWselected = $scope.multiSelection.numAWselected > 0;
        };

        $scope.onAWSelectedChanged = function (artwork) {
            var start = $scope.artworks.indexOf(artwork);
            var end = start;

            if ($scope.multiSelection.shiftKey && $scope.multiSelection.lastChecked) {
                end = $scope.artworks.indexOf($scope.multiSelection.lastChecked);
            }
            var toBeChecked = $scope.artworks.slice(Math.min(start, end), Math.max(start, end) + 1);
            angular.forEach(toBeChecked, function (ii) {
                if (!ii.isMultiActionsEnable) {
                    return;
                }
                ii.selected = artwork.selected;
            });
            $scope.multiSelection.lastChecked = artwork;

            $scope.selectAllSync();
        };

        $scope.retryProcess = function (artwork) {
            var messageParams = {};
            messageParams.message = 'Are you sure you want to retry to process this artwork?';
            messageParams.title = 'Retry Artwork Process';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Retry';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        artworkId: artwork.id,
                    };

                    $http.post('/artworks/reProcessArtwork', data).then(
                        function (res) {
                            $log.debug('Successfully started reprocessing artwork', res, data);
                            var messageParams = {};
                            messageParams.message = 'reprocessing artwork';
                            messageParams.title = 'Retry Artwork Process';
                            Messages.openMessage($scope, messageParams);
                            ArtworkService.getArtworkStatus(artwork.id).then(function (res) {
                                artwork.status = res.data.status;
                                if ($scope.pollingEnabled) {
                                    addNoneReadyArtworkToQueue(artwork);
                                }
                            });
                        },
                        function (error) {
                            $log.debug('Failed during reprocessing artwork', error, data);
                            var messageParams = {};
                            messageParams.message =
                                'Error reprocessing artwork.\nPlease refresh the page and try again.';
                            messageParams.title = 'Retry Artwork Process Failed';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams).then(function (res) {
                                ArtworkService.handleArtworkAction('updateArtworkStatus', artwork, {
                                    status: 'FAILED',
                                    reason:
                                        'HomeCollectionsMainController::retryProcess Failed during reprocessing artwork' +
                                        JSON.stringify(error),
                                });
                            });
                        }
                    );
                }
            });
        };

        $scope.retryUpload = function (artworkId) {
            var messageParams = {};
            var uploadId = matchUploadId(artworkId);

            if (uploadId >= 0) {
                messageParams.message = 'Are you sure you want to retry to upload this artwork?';
                messageParams.title = 'Retry Artwork';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Retry';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        S3Uploader.restartUpload(uploadId).then(
                            function (res) {
                                $log.debug(
                                    'HomeCollectionsMainController::retryUpload Retry artwork success',
                                    { uploadId: uploadId, artworkId: artworkId, res: res }
                                );
                            },
                            function (error) {
                                $log.debug(
                                    'HomeCollectionsMainController::retryUpload Retry artwork failure',
                                    { uploadId: uploadId, artworkId: artworkId, error: error }
                                );
                                messageParams = {};
                                messageParams.message =
                                    'Please enter edit artwork and choose the file again to retry upload.';
                                messageParams.title = 'Retry Artwork';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                            }
                        );
                    }
                });
            } else {
                messageParams = {};
                messageParams.message =
                    'Please enter edit artwork and choose the file again to retry upload.';
                messageParams.title = 'Retry Artwork';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            }
        };

        $scope.cancelUpload = function (artworkId) {
            var messageParams = {};
            var uploadId = matchUploadId(artworkId);

            if (uploadId >= 0) {
                messageParams.message = 'Are you sure you want to cancel the upload of this artwork?';
                messageParams.title = 'Cancel Artwork Upload';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Yes';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        $rootScope.$broadcast('upload_aborted');
                        S3Uploader.abortUpload(uploadId).then(
                            function (res) {
                                $log.debug(
                                    'HomeCollectionsMainController::cancelUpload Abort artwork success',
                                    { uploadId: uploadId, artworkId: artworkId, res: res }
                                );
                            },
                            function (error) {
                                $log.debug(
                                    'HomeCollectionsMainController::cancelUpload Abort artwork failure',
                                    { uploadId: uploadId, artworkId: artworkId, error: error }
                                );
                                messageParams = {};
                                messageParams.message =
                                    'Failed to cancel artwork upload. Please delete the artwork and create new artwork.';
                                messageParams.title = 'Cancel Artwork Upload';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                            }
                        );
                    }
                });
            } else {
                messageParams = {};
                messageParams.message =
                    'Please enter edit artwork and choose another file to upload new artwork file or delete current artwork.';
                messageParams.title = 'Cancel Artwork Upload';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            }
        };

        $scope.orderChanged = false;

        function rowOrderChanged(event) {
            var fromIdx = event.source.index;
            var toIdx = event.dest.index;

            $log.debug('change order from: ' + fromIdx + ' to: ' + toIdx);
            $scope.orderChanged = true;
        }

        $scope.dragControlListeners = {
            orderChanged: rowOrderChanged,
            containment: '.droparea', //optional param.
            clone: false, //optional param for clone feature.
            allowDuplicates: false, //optional param allows duplicates to be dropped.
            additionalPlaceholderClass: 'drag-placeholder',
            placeholder:
                '<tr style="text-align:center;padding-top:20px;" data-content="Drop to set the Artwork here"></tr>',
        };

        $scope.resetCollectionOrder = function () {
            $scope.sortArtworkByCollectionOrder();
            $scope.orderChanged = false;
        };

        $scope.saveCollectionOrder = function () {
            var data = {
                collectionId: $scope.collectionId, // For edit
                orderedIds: [],
            };

            for (var i = 0; i < $scope.artworks.length; i++) {
                data.orderedIds.push($scope.artworks[i].id);
            }

            return $http
                .post('/collections/changeCollectionOrder', data)
                .success(function () {
                    //Make change on the AW we already loaded.
                    for (var i = 0; i < $scope.artworks.length; i++) {
                        $scope.artworks[i].order_in_collection = i + 1;
                    }

                    var messageParams = {};
                    messageParams.message = 'Successfully change Set order';
                    messageParams.title = 'Set Order';
                    Messages.openMessage($scope, messageParams);

                    $scope.orderChanged = false;
                })
                .error(function () {
                    var messageParams = {};
                    messageParams.message = 'Failed to change Set order. ';
                    messageParams.title = 'Set Order';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                });
        };

        $scope.sortArtworkByCollectionOrder = function () {
            $scope.artworks = $filter('orderBy')($scope.artworks, 'order_in_collection');
        };

        $scope.$on('$destroy', function (event) {
            $log.debug('HomeCollectionsMainController::$destroy Destroying all timeouts', timeoutArray);

            timeoutArray.forEach(function (timeout) {
                $log.debug('HomeCollectionsMainController::$destroy cancelling timeout', timeout);
                $timeout.cancel(timeout);
                $log.debug('HomeCollectionsMainController::$destroy cancelled timeout', timeout);
            });
        });

        $rootScope.$on('s3upload:start', function (scope, data) {
            var uploadArtworkId = data.upload.extraParams.artworkId;
            $log.debug('HomeCollectionsMainController::s3upload:start update artwork with upload id', {
                uploadArtworkId: uploadArtworkId,
                uploadId: data.upload.id,
                upload: data.upload,
            });
            if (!data.upload.extraParams.isPreviewFile) {
                updateArtworkWithUploadId(uploadArtworkId, data.upload.id);
            }
        });

        $rootScope.$on('artwork_changed_status', function (event, artworkId, status) {
            checkArtworkStatusChangedFromBroadcast(artworkId, status, 'artwork_changed_status');
        });

        function checkArtworkStatusChangedFromBroadcast(artworkId, status, origin) {
            var i = 0;

            for (i; i < $scope.artworks.length; i++) {
                if (artworkId === $scope.artworks[i].id && $scope.artworks[i].status !== status) {
                    $log.debug('HomeCollectionsMainController::' + origin, {
                        artwork: $scope.artworks[i],
                        status: status,
                    });
                    $scope.artworks[i].status = status;
                    if ($scope.pollingEnabled) {
                        addNoneReadyArtworkToQueue($scope.artworks[i]);
                    }
                    break;
                }
            }
        }

        function replaceArtworkStatusFromPush(artworkId, status) {
            var i = 0;

            for (i; i < $scope.artworks.length; i++) {
                if (artworkId === $scope.artworks[i].id && $scope.artworks[i].status !== status) {
                    replaceNoneReadyArtworkWithReadyArtwork($scope.artworks[i], status);
                    break;
                }
            }
        }

        $rootScope.$on('pushy_update_artwork_ready', function (event, data) {
            $log.debug('HomeCollectionsMainController::pushy_update_artwork_ready', data);
            const artworkId = data.artworkId;
            const status = data.status;
            replaceArtworkStatusFromPush(artworkId, status);
        });

        $rootScope.$on('pushy_update_artwork_preview_ready', function (event, data) {
            $log.debug('HomeCollectionsMainController::pushy_update_artwork_preview_ready', data);
            const artworkId = data.artworkId;
            const status = data.status;
            replaceArtworkStatusFromPush(artworkId, status);
        });

        $rootScope.$on('pushy_update_artwork_pending', function (event, data) {
            $log.debug('HomeCollectionsMainController::pushy_update_artwork_pending', data);
            const artworkId = data.artworkId;
            const status = data.status;
            replaceArtworkStatusFromPush(artworkId, status);
        });

        const cleanFunc = $rootScope.$on('cloud_uploads_all_finished', function (event, args) {
            $log.debug('HomeCollectionsMainController::cloud_uploads_all_finished');
            HomeDataService.resetCachedVaultCollectionsByUser();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
            $timeout(addNoneReadyArtworksToQueue, 3000);
        });

        function removeArtwork(event, artworkId) {
            var i = 0;

            for (i; i < $scope.artworks.length; i++) {
                if (artworkId === $scope.artworks[i].id) {
                    $log.debug('HomeCollectionsMainController::artwork_revoked', {
                        artwork: $scope.artworks[i],
                    });
                    $scope.artworks.splice(i, 1);
                    break;
                }
            }
        }

        $rootScope.$on('artwork_deleted', removeArtwork);
        $rootScope.$on('artwork_revoked', removeArtwork);

        $scope.$on('$destroy', () => {
            $scope.cleanupFuncs.forEach((func) => func());
        });
    },
]);
