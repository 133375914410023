'use strict';

angular.module('discover').controller('DiscoverMainTabMainController', [
    '$log',
    '$scope',
    '$stateParams',
    'ChannelService',
    'ArtworkService',
    'DiscoverDataService',
    function ($log, $scope, $stateParams, ChannelService, ArtworkService, DiscoverDataService) {
        function getFeaturedChannels() {
            DiscoverDataService.getFeaturedChannels('6').then(
                function (res) {
                    $scope.featuredChannels = res.data.data.channels;
                    $scope.$emit('DiscoverMainTabMainController:channels_refreshed');
                },
                function (error) {
                    $log.debug('there was an error fetching featured channels: ' + JSON.stringify(error));
                }
            );
        }

        function getFeaturedArtworks() {
            DiscoverDataService.getFeaturedArtworks('6').then(
                function (res) {
                    $scope.featuredArtworks = res.data.data.artworks;
                },
                function (error) {
                    $log.debug('there was an error fetching featured artworks: ' + JSON.stringify(error));
                }
            );
        }

        function getLatestArtworks() {
            DiscoverDataService.getRecentArtworks('6').then(
                function (res) {
                    $scope.latestArtworks = res.data.data.artworks;
                },
                function (error) {
                    $log.debug('there was an error fetching latest artworks: ' + JSON.stringify(error));
                }
            );
        }

        $scope.init = function () {
            $scope.sidebarSelection = $stateParams.sidebarSelection;
            $scope.ChannelService = ChannelService;
            $scope.ArtworkService = ArtworkService;

            switch ($scope.sidebarSelection) {
                case 'featured':
                    getFeaturedArtworks();
                    getLatestArtworks();
                    getFeaturedChannels();
                    break;
                default:
                    $log.debug('trying to perform an action that is not defined');
            }
        };
    },
]);
