'use strict';

angular.module('devices').directive('playerType', [
    '$filter',
    'PLAYER_TYPES',
    function ($filter, PLAYER_TYPES) {
        var playerTypeController = [
            '$scope',
            function ($scope) {
                var self = this;

                $scope.selectPType = function (newType) {
                    $scope.pType = newType;
                    $scope.typeChanged({ newPlayerType: $scope.pType, resetFN: $scope.reset });
                };
                $scope.reset = function (value) {
                    $scope.pType = value;
                };
                $scope.PLAYER_TYPES = PLAYER_TYPES;
            },
        ];

        return {
            restrict: 'E',
            scope: {
                pType: '<',
                showSeparator: '=',
                typeChanged: '&',
            },
            link: function (scope, element, attrs) {
                attrs.showSeparator = attrs.showSeparator || false;
            },
            controller: playerTypeController,
            template:
                '<label>Playback Mode - Covers Only & Artworks:</label>' +
                '<div ng-cloak="true">' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="pType" ng-value="PLAYER_TYPES.REGULAR" name="pType" ng-change="selectPType(PLAYER_TYPES.REGULAR)">&nbsp;Regular Player Mode' +
                '</span>' +
                '<span class="checkbox">' +
                '<input disabled type="radio" ng-checked="pType !== PLAYER_TYPES.REGULAR">&nbsp;Unique Mode - Covers Only & Artworks Only' +
                '</span>' +
                '<div style="padding-left: 3em;">' +
                '<span class="radio">' +
                '<input type="radio" ng-model="pType" ng-value="PLAYER_TYPES.AW_ONLY" name="pType" ng-change="selectPType(PLAYER_TYPES.AW_ONLY)">{{\'PLAYER_TYPE.AW_ONLY\'|translate}}' +
                '</span>' +
                '<span class="radio">' +
                '<input disabled type="radio" ng-checked="pType !== PLAYER_TYPES.AW_ONLY && pType !== PLAYER_TYPES.REGULAR">Play Cover Pages Only' +
                '</span>' +
                '<div style="padding-left: 3em;">' +
                '<span class="radio">' +
                '<input type="radio" ng-model="pType" ng-value="PLAYER_TYPES.AW_COVER" name="pType" ng-change="selectPType(PLAYER_TYPES.AW_COVER)">{{\'PLAYER_TYPE.AW_COVER\'|translate}}' +
                '</span>' +
                '<span class="radio">' +
                '<input type="radio" ng-model="pType" ng-value="PLAYER_TYPES.ARTIST_BIO" name="pType" ng-change="selectPType(PLAYER_TYPES.ARTIST_BIO)">{{\'PLAYER_TYPE.ARTIST_BIO\'|translate}}' +
                '</span>' +
                '<span class="radio">' +
                '<input type="radio" ng-model="pType" ng-value="PLAYER_TYPES.CHANNEL_COVER" name="pType" ng-change="selectPType(PLAYER_TYPES.CHANNEL_COVER)">{{\'PLAYER_TYPE.CH_COVER\'|translate}}' +
                '</span>' +
                '<span class="radio">' +
                '<input type="radio" ng-model="pType" ng-value="PLAYER_TYPES.CAMPAIGN" name="pType" ng-change="selectPType(PLAYER_TYPES.CAMPAIGN)">{{\'PLAYER_TYPE.CAMPAIGN\'|translate}}' +
                '</span>' +
                '</div>' +
                '</div>' +
                '</div>' +
                '<pre style="display:none">&nbsp;&nbsp;DEBUG: {{pType | json}}</pre>' +
                '<div ng-if="showSeparator" class="full-hl"></div>',
        };
    },
]);
