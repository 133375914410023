'use strict';

angular.module('core').controller('UploadsController', [
    '$rootScope',
    '$log',
    'S3Uploader',
    'Messages',
    'UploadProgress',
    '_',
    function ($rootScope, $log, S3Uploader, Messages, UploadProgress, _) {
        var self = this;
        this.Messages = Messages;
        this.S3Uploader = S3Uploader;
        this.UploadProgress = UploadProgress;
        this.orderByField = 'time';
        this.reverseSort = true;

        this.init = function () {
            this.orderByField = 'time';
            this.reverseSort = true;
        };

        this.numberOfUploads = function () {
            var uploads = _.filter(S3Uploader.uploads, function (upload) {
                return (
                    !upload.extraParams.uploadType || ['cover', 'profile'].indexOf(upload.extraParams.uploadType) > -1
                );
            });
            // <tr ng-repeat="upload in vm.S3Uploader.uploads | orderBy:vm.orderByField:vm.reverseSort" ng-if="['cover', 'profile'].indexOf(upload.extraParams.uploadType) < 0">
            return uploads.length;
        };

        this.cancelUpload = function (uploadId, artworkId) {
            var messageParams = {};

            messageParams.message = 'Are you sure you want to cancel the upload for this file?';
            messageParams.title = 'Cancel Upload';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'No';
            messageParams.okText = 'Yes';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    S3Uploader.abortUpload(uploadId).then(
                        function (res) {
                            $log.debug('UploadsController::cancelUpload Abort artwork success', {
                                uploadId: uploadId,
                                artworkId: artworkId,
                                res: res,
                            });
                        },
                        function (error) {
                            $log.debug('UploadsController::cancelUpload Abort artwork failure', {
                                uploadId: uploadId,
                                artworkId: artworkId,
                                error: error,
                            });
                            messageParams = {};
                            messageParams.message =
                                'Failed to cancel artwork upload. Please delete the artwork and create new artwork.';
                            messageParams.title = 'Cancel Artwork Upload';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams);
                        }
                    );
                }
            });
        };
    },
]);
