'use strict';

angular.module('home').controller('HomeAddCollectionController', [
    'collection',
    'artworks',
    'User',
    '$rootScope',
    '$scope',
    '$http',
    '$q',
    '$state',
    '$stateParams',
    '$filter',
    '$location',
    'Messages',
    'HomeDataService',
    'FormValidationService',
    'TagsService',
    '_',
    function (
        collection,
        artworks,
        User,
        $rootScope,
        $scope,
        $http,
        $q,
        $state,
        $stateParams,
        $filter,
        $location,
        Messages,
        HomeDataService,
        FormValidationService,
        TagsService,
        _
    ) {
        $scope.from = $state.current.data.from;
        $scope.allArtworks = {};
        $scope.checkedItems = [];
        $scope.formValidator = FormValidationService;
        $scope.isAdmin = User.isAdmin();
        $scope.viewChecked = false;

        function loadArtworks() {
            var deferred = $q.defer();

            angular.forEach($scope.artworks, function (artwork) {
                artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                artwork.display_name = artwork.artist_first_name
                    ? artwork.artist_first_name + artwork.artist_last_name
                    : artwork.uploader.display_name;
                artwork.price = artwork.price ? parseInt(artwork.price) : null;
                artwork.numericFileSize =
                    artwork.file_metadata !== undefined && artwork.file_metadata.size
                        ? parseInt(artwork.file_metadata.size)
                        : 0;
                artwork.durationInSec =
                    artwork.file_metadata !== undefined && artwork.file_metadata.video_duration !== null
                        ? Date.parse('Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC')
                        : 0;
            });

            deferred.resolve();

            return deferred.promise;
        }

        function setOrderToCheckedItems() {
            var i,
                j,
                index = 1;

            for (i = 0; i < $scope.checkedItems.length; i++) {
                $scope.checkedItems[i].order = index;
                for (j = 0; j < $scope.artworks; j++) {
                    if ($scope.artworks[j].id === $scope.checkedItems[i].id) {
                        $scope.artworks[j].order = index;
                    }
                }
                index++;
            }
        }

        function compare(a, b) {
            var comparison = 0;
            if (a.order >= b.order) {
                comparison = 1;
            } else if (a.order < b.order) {
                comparison = -1;
            }
            return comparison;
        }

        function sortCheckedItemsByOrder() {
            if ($scope.checkedItems && $scope.checkedItems.length > 1 && $scope.checkedItems[0].order) {
                $scope.checkedItems = $scope.checkedItems.sort(compare);
            }
        }

        function checkArtworks() {
            var i, j;
            $scope.checkedItems = [];

            for (i = 0; i < $scope.collection.artworks.length; i++) {
                for (j = 0; j < $scope.artworks.length; j++) {
                    if ($scope.artworks[j].id === $scope.collection.artworks[i].id) {
                        $scope.artworks[j].isChecked = true;
                        $scope.artworks[j].order = $scope.collection.artworks[i].order;
                        $scope.checkedItems.push(copyOnlyNecessaryItems($scope.artworks[j]));
                        break;
                    }
                }
            }

            sortCheckedItemsByOrder();
            setOrderToCheckedItems();
            $scope.allArtworksAreChecked();
        }

        function collectAllCheckedArtworks() {
            var checkedArtworks = [];

            $scope.checkedItems.forEach(function (checkedItem) {
                if (checkedItem.isChecked) {
                    checkedArtworks.push({ id: checkedItem.id, order: checkedItem.order });
                }
            });

            return checkedArtworks;
        }

        $scope.search = {
            enable: false,
            text: '',
            filterRows: function (row) {
                if (!$scope.search.enable || $scope.search.text === '') {
                    return true;
                }

                var lowerCasedSearchText = $scope.search.text.toLowerCase();

                if (row.title.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }
                if (row.display_name && row.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }

                if (row.creator && row.creator.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }

                if (row.artist && row.artist.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }
                return false;
            },
        };
        $scope.toggleSearch = function () {
            $scope.search.text = '';
            if ($scope.search.enable) {
                $scope.search.text = '';
                $scope.search.enable = false;
            } else {
                $scope.search.enable = true;
            }
        };

        $scope.init = function () {
            $scope.submitWasClicked = false;
            $scope.artworks = artworks !== '' ? artworks : [];
            $scope.artworks = _.filter($scope.artworks, function (artwork) {
                return artwork.status !== 'FAILED';
            });

            $scope.collection = collection !== '' ? collection : {};

            loadArtworks().then(function () {
                if ($scope.collection && $scope.collection.id) {
                    checkArtworks();
                }
            });
        };

        $scope.loadTags = function ($query) {
            var data = {
                query: $query,
            };

            return $http.post('/tags/getTags', data).then(function (res) {
                var tags = [];
                res.data.tags.forEach(function (tag) {
                    tags.push({ text: tag.Tag.name });
                });
                return tags;
            });
        };

        function onCreateNewCollectionSuccess(res) {
            $rootScope.$broadcast('add_collection_changed', true);
            //Notify if collection name has been changed.
            if ($stateParams.collectionId && $stateParams.collectionLink) {
                var newName = $scope.collection.name;
                if (newName && newName !== $stateParams.collectionLink) {
                    $rootScope.$broadcast(
                        'collection_name_changed',
                        $stateParams.collectionId,
                        newName,
                        $stateParams.collectionLink
                    );
                }
            }

            var messageParams = {};
            messageParams.message = $scope.collection.id
                ? 'Set was changed successfully.'
                : 'Set was created successfully.';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if ($stateParams.collectionId && $stateParams.collectionLink) {
                    var link = $filter('inflector')($scope.collection.name.toLowerCase(), 'underscore');
                    link = encodeURIComponent(link);
                    $state.go('home.' + $scope.from + '.main', {
                        collectionId: $stateParams.collectionId,
                        collectionLink: link,
                    });
                } else {
                    $location.path('/home/' + $scope.from);
                }
                $scope.submitWasClicked = false;
            });
        }

        function onCreateNewCollectionError(error) {
            $rootScope.$broadcast('add_collection_changed', true);
            var messageParams = {};
            messageParams.message = 'An error has occurred. Please try again later.' + ' ' + error.message.text;
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($scope, messageParams);
            $scope.submitWasClicked = false;
        }

        $scope.sendFormToServer = function () {
            sortCheckedItemsByOrder();
            setOrderToCheckedItems();
            $scope.collection.aws = collectAllCheckedArtworks();
            $scope.submitWasClicked = true;

            var data = {
                collectionId: $scope.collection.id, // For edit
                artworkType: $scope.collection.artworkType,
                name: $scope.collection.name,
                type: $scope.collection.type || $scope.from.toUpperCase(),
                description: $scope.collection.description,
                tags: $scope.collection.tags,
                is_synced: $scope.collection.is_synced,
                aws: $scope.collection.aws,
            };

            return $http
                .post('/collections/createNewCollection', data)
                .success(onCreateNewCollectionSuccess)
                .error(onCreateNewCollectionError);
        };

        function copyOnlyNecessaryItems(row) {
            var newRow = {
                id: row.id,
                isChecked: row.isChecked,
                order: row.order,
                title: row.title,
                category: row.category,
                belongsTo: row.belongsTo,
                numericFileSize: row.numericFileSize,
                durationInSec: row.durationInSec,
                commercialRule: row.commercialRule,
                loan_price: row.loan_price,
                loan_currency: row.loan_currency,
                loan_period: row.loan_period,
                price: row.price,
                currency: row.currency,
                thumbnail_path: row.thumbnail_path,
                isArtwork: row.isArtwork,
                isCollection: row.isCollection,
            };

            if (row.User) newRow.User = { display_name: row.User.display_name };
            if (row.uploader) newRow.uploader = { display_name: row.uploader.display_name };
            if (row.creator) newRow.creator = { display_name: row.creator.display_name };

            return newRow;
        }

        $scope.itemToggled = function (row) {
            if (row.isChecked) {
                if (!row.order) {
                    row.order = $scope.checkedItems.length + 1;
                }
                $scope.checkedItems.push(copyOnlyNecessaryItems(row));
            } else {
                $scope.checkedItems.splice(
                    _.findIndex($scope.checkedItems, function (item) {
                        return item.id === row.id;
                    }),
                    1
                );

                var foundRow = _.find($scope.artworks, function (repoRow) {
                    return repoRow.id === row.id;
                });

                if (foundRow) {
                    foundRow.isChecked = row.isChecked;
                }
            }

            sortCheckedItemsByOrder();
            $scope.allArtworksAreChecked();
            if ($scope.checkedItems.length === 0 && $scope.viewChecked) {
                $scope.toggleViewChecked();
            }
        };

        $scope.allArtworksAreChecked = function () {
            var checkedArtworksNum = 0,
                lengthOfTotalArtworks = $scope.artworks.length;

            $scope.artworks.forEach(function (artwork) {
                checkedArtworksNum += artwork.isChecked ? 1 : 0;
            });

            $scope.allArtworks.selectAllIsChecked = checkedArtworksNum === lengthOfTotalArtworks;
        };

        function toggleAllArtworks(check) {
            var filteredRows = $filter('filter')($scope.artworks, $scope.search.filterRows);
            filteredRows.forEach(function (artwork) {
                artwork.isChecked = check ? true : false;
            });
        }

        $scope.selectAllToggle = function () {
            if ($scope.allArtworks.selectAllIsChecked) {
                toggleAllArtworks(true);
            } else {
                toggleAllArtworks(false);
            }
        };

        $scope.deleteCollection = function (collectionId, index) {
            $scope.submitWasClicked = true;

            var messageParams = {};
            messageParams.message = 'Are you sure you want to delete this set?';
            messageParams.message += '\nPlease note, you cannot undo this action.';
            messageParams.title = 'Delete Set';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    HomeDataService.deleteCollection(collectionId).then(
                        function (res) {
                            $rootScope.$broadcast('add_collection_changed', true);
                            var messageParams = {};
                            messageParams.message = 'Set was deleted successfully.';
                            Messages.openMessage($scope, messageParams).then(function (res) {
                                $location.path('/home/' + $scope.from);
                                $scope.submitWasClicked = false;
                            });
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = `An error has occurred. ${error?.data.message.text}`;
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        $scope.toggleViewChecked = function () {
            setOrderToCheckedItems();
            $scope.viewChecked = !$scope.viewChecked;
            if ($scope.viewChecked) {
                $scope.savedReverseState = $scope.reverseSort;
                $scope.reverseSort = false;
            } else if ($scope.savedReverseState !== undefined) {
                $scope.reverseSort = $scope.savedReverseState;
                delete $scope.savedReverseState;
            }
        };

        // Handle Tags
        $scope.$watch(
            'collection.tags',
            function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    // Call your updateTags function here
                    TagsService.updateTags(newVal);
                }
            },
            true
        );

        function rowOrderChanged(event) {
            var fromIdx = event.source.index;
            var toIdx = event.dest.index;
            setOrderToCheckedItems();
        }

        function placeHolder(itemScope) {
            var tr = itemScope.element[0];
            var clone = tr.cloneNode(true);
            clone.id = 'drag-placeholder';
            clone.style.display = 'table-row';
            return clone;
        }

        // Taken from: https://gist.github.com/SebLours/224266714a2f52bfa253f03c6b518a45
        $scope.dragControlListeners = {
            orderChanged: rowOrderChanged,
            containment: '#artworks-table',
            placeholder: placeHolder,
        };

        // Grid
        $scope.orderByField = 'Artwork.title';
        $scope.reverseSort = false;
    },
]);
