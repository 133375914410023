'use strict';

angular.module('core').service('Notifications', [
    '$rootScope',
    function ($rootScope) {
        this.idPrefix = 'notification_';
        this.notifications = [];

        this.safeUpdate = () => {
            $rootScope.$broadcast('safeUpdate');
        };

        this.remove = (id) => {
            const notificationElement = document.getElementById(id);
            if (notificationElement) {
                notificationElement.addEventListener('transitionend', () => {
                    this.notifications = this.notifications.filter((notification) => notification.id !== id);
                    this.safeUpdate();
                });
            }
            const currentNotification = this.notifications.find((notification) => notification.id === id);
            currentNotification && (currentNotification.status = false);
            this.safeUpdate();
        };

        this.addNewNotification = (notification = { duration: 3000 }) => {
            const notificationData = {
                status: true,
                ...notification,
            };
            if (!notificationData.id)
                notificationData.id = this.idPrefix + Math.random() + '_' + new Date().getTime();

            if (!notificationData.type) notificationData.type = 'success';

            this.notifications.push(notificationData);

            this.safeUpdate();

            return new Promise((resolve) => {
                setTimeout(() => {
                    this.remove(notificationData.id);
                    resolve('ok');
                }, notificationData.duration);
            });
        };

        this.add = (message, duration = 3000, type) => {
            return this.addNewNotification({ message, duration, type });
        };

        $rootScope.$on('addNotification', (e, notification) => {
            this.addNewNotification(notification);
        });
    },
]);
