'use strict';

angular.module('admin-area').controller('AdminAreaCampaignsController', [
    'FormValidationService',
    '$log',
    '$rootScope',
    '$localStorage',
    '$scope',
    '$q',
    '$http',
    '$window',
    '$filter',
    'S3Uploader',
    'Messages',
    '_',
    function (
        FormValidationService,
        $log,
        $rootScope,
        $localStorage,
        $scope,
        $q,
        $http,
        $window,
        $filter,
        S3Uploader,
        Messages,
        _
    ) {
        $scope.formValidator = FormValidationService;
        $scope.maxAdditionalFiles = 15;
        $scope.maxAdditionalFileSize = 100 * 1024 * 1024 * 1024; // Changed to 100GB from 1.5 GB for dev / pre AND production as well

        function getCampaigns() {
            var deferred = $q.defer();

            $http.get('/campaigns/getAllCampaigns').then(
                function (res) {
                    $scope.campaigns = res.data.data.campaigns;
                    deferred.resolve(res.data.data.campaigns);
                },
                function (error) {
                    $log.debug(
                        'AdminAreaCampaignsController::getCampaigns Error in campaigns/getAllCampaigns',
                        error
                    );
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function addRemoveHashForColors(color, addRemoveHash) {
            var result = color;

            if (color) {
                if (addRemoveHash === 'removeHash' && color.substr(0, 1) === '#') {
                    result = color.substr(1, 6);
                } else if (addRemoveHash === 'addHash' && color.substr(0, 1) !== '#') {
                    result = '#' + color;
                }
            }

            return result;
        }

        $scope.init = function () {
            $scope.optionWasSelected = false;
            $scope.submitWasClicked = false;
            $scope.clearCampaignForm();

            getCampaigns();
        };

        $scope.clearCampaignForm = function () {
            $scope.campaignFormObj = {
                font: 'Arial',
                color: 'FFFFFF',
                bg_color: '000000',
                mode: 'channel',
            };
            $scope.removeFile('icon');
            $scope.removeFile('background');
            $scope.tasks = [];
            $scope.backupFiles = {};
            if ($scope.campaignForm && $scope.campaignForm.submitDirty) {
                $scope.campaignForm.submitDirty = false;
            }
        };

        $scope.getCampaignData = function (campaignId) {
            var deferred = $q.defer();

            var data = {
                campaignId: campaignId,
            };

            $http.get('/campaigns/getCampaignData', data).then(
                function (res) {
                    deferred.resolve(res.data.data.campaignData);
                },
                function (error) {
                    $log.debug(
                        'AdminAreaCampaignsController::getCampaignData Error in campaigns/getCampaignData',
                        error
                    );
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        $scope.updateCampaignSelection = function (campaignForm, selectedCampaign) {
            if (
                $scope.campaignFormObj.id &&
                $scope.campaignFormObj.id !== selectedCampaign.id &&
                campaignForm &&
                !campaignForm.$pristine
            ) {
                var messageParams = {};
                messageParams.message =
                    'There are unsaved changes in current campaign.\nAre you sure you want to change campaign?';
                messageParams.title = 'Unsaved changes';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Yes';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        $scope.clearCampaignForm();
                        $scope.optionWasSelected = true;
                        if (selectedCampaign.id === null) {
                            $scope.selectedCampaign = null;
                        }
                        //angular.extend($scope.campaignFormObj, selectedCampaign);
                        $scope.mergeFormAndSelectedCampaign($scope.campaignFormObj, selectedCampaign);
                        $scope.campaignFormObj.color = addRemoveHashForColors(
                            $scope.campaignFormObj.color,
                            'addHash'
                        );
                        $scope.campaignFormObj.bg_color = addRemoveHashForColors(
                            $scope.campaignFormObj.bg_color,
                            'addHash'
                        );
                        campaignForm.$setPristine();
                    } else {
                        selectedCampaign = _.find($scope.campaigns, function (campaign) {
                            return campaign.id === $scope.campaignFormObj.id;
                        });
                        //angular.extend(selectedCampaign, $scope.campaignFormObj);
                    }
                });
            } else {
                $scope.clearCampaignForm();
                $scope.optionWasSelected = true;
                //angular.extend($scope.campaignFormObj, selectedCampaign);
                $scope.mergeFormAndSelectedCampaign($scope.campaignFormObj, selectedCampaign);
                $scope.backupFiles.iconBackup = $scope.campaignFormObj.icon_filename
                    ? $scope.campaignFormObj.iconFile
                    : '';
                $scope.backupFiles.backgroundBackup = $scope.campaignFormObj.background_filename
                    ? $scope.campaignFormObj.backgroundFile
                    : '';
                $scope.campaignFormObj.color = addRemoveHashForColors(
                    $scope.campaignFormObj.color,
                    'addHash'
                );
                $scope.campaignFormObj.bg_color = addRemoveHashForColors(
                    $scope.campaignFormObj.bg_color,
                    'addHash'
                );
            }
        };

        $scope.mergeFormAndSelectedCampaign = function (campaignFormObj, selectedCampaign) {
            angular.extend(campaignFormObj, selectedCampaign);
            if (!$scope.uploading) {
                $scope._refreshImages();
            }
        };

        $scope._refreshImages = function () {
            var ts = '?t=' + new Date().getTime();
            $scope.campaignFormObj.icon_path_preview = $scope.campaignFormObj.icon_path + ts;
            $scope.campaignFormObj.background_path_preview = $scope.campaignFormObj.background_path + ts;
        };

        function isImage(type, extension) {
            return (
                _.indexOf(FormValidationService.supportedImageMimetypes, type) > -1 ||
                _.indexOf(FormValidationService.supportedImageExtensions, extension) > -1
            );
        }

        function isVideo(type, extension) {
            return (
                _.indexOf(FormValidationService.supportedVideoMimetypes, type) > -1 ||
                _.indexOf(FormValidationService.supportedVideoExtensions, extension) > -1
            );
        }

        $scope.getBackgroundColorStyle = function (color) {
            return { 'background-color': color };
        };

        // var s3UploadOptions = {
        //     getOptionsUri: '/Campaigns/getCampaignS3AccessToken'
        //     //folder: 'ShaiArtworks/'
        // };
        // var opts = angular.extend({}, s3UploadOptions);
        // opts = angular.extend({
        //     submitOnChange: true,
        //     //getOptionsUri: '/getS3Options',
        //     acl: 'public-read',
        //     uploadingKey: 'uploading',
        //     //folder: '',
        //     enableValidation: true
        // }, opts);

        var s3UploadOptions = {};
        var opts = angular.extend({}, s3UploadOptions);
        opts = angular.extend(
            {
                submitOnChange: true,
                getOptionsUri: '/getS3Options',
                acl: 'private',
                uploadingKey: 'uploading',
                folder: '',
                enableValidation: true,
            },
            opts
        );

        $scope.uploadFile = function (
            file,
            bucket,
            key,
            campaignId,
            entityData,
            isPreviewFile,
            isAdditionalFile,
            additionalFileId,
            additionalFile
        ) {
            $log.debug('HomeAddChannelController::uploadFile Uploading file', {
                file: file,
                bucket: bucket,
                key: key,
                campaignId: campaignId,
            });
            var deferred = $q.defer();

            var exraParams = {
                campaignId: campaignId,
                entityType: 'campaign',
                isPreviewFile: isPreviewFile,
                isAdditionalFile: isAdditionalFile,
                additionalFileId: additionalFileId,
                additionalFile: additionalFile,
                campaign: entityData || $scope.campaignFormObj,
            };

            S3Uploader.upload(
                'multipart',
                bucket,
                key,
                opts.acl,
                file.type,
                null,
                null,
                null,
                file,
                exraParams,
                null
            ).then(
                function (res) {
                    deferred.resolve(res);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        // $scope.upload = function (newAdditionalFiles) {
        //     //var deferred = $q.defer();
        //
        //     $scope.submitWasClicked = true;
        //
        //     $scope.uploading = true;
        //
        //     if (newAdditionalFiles) {
        //         setNewFilenameToFiles(newAdditionalFiles);
        //     }
        //
        //     sendFormToServer()
        //         .then(function (res) {
        //             $scope.campaignFormObj.id = res.data.s3.campaignId;
        //
        //             //$q.all([
        //             //    uploadFile($scope.campaignFormObj.iconFile[0], res.data.s3, $scope.campaignFormObj.icon_filename),
        //             //    uploadFile($scope.campaignFormObj.backgroundFile[0], res.data.s3, $scope.campaignFormObj.background_filename)
        //             //])
        //
        //
        //             var okFunc = function (res) {
        //                 $scope.submitWasClicked = false;
        //                 $scope.uploading = false;
        //                 $scope._refreshImages();
        //                 $log.debug('files uploaded successfully', res);
        //                 var messageParams = {};
        //                 messageParams.message = 'Campaign was saved successfully';
        //                 Messages.openMessage($rootScope, messageParams);
        //                 //deferred.resolve();
        //             };
        //             var failFunc = function (error) {
        //                 $scope.submitWasClicked = false;
        //                 $scope.uploading = false;
        //                 $scope._refreshImages();
        //                 $log.debug('files failed to upload', error);
        //                 var messageParams = {};
        //                 messageParams.title = 'Error uploading image';
        //                 messageParams.message = 'Failed to upload image. Please retry.\nIf the problem persists, please contact support.';
        //                 messageParams.disableAutoDismiss = true;
        //                 Messages.openMessage($rootScope, messageParams);
        //                 //deferred.reject();
        //             };
        //
        //             var tasks = [];
        //             if($scope.campaignFormObj.iconFile.length > 0){
        //                 tasks.push(uploadFile($scope.campaignFormObj.iconFile[0], res.data.s3, $scope.campaignFormObj.icon_filename));
        //             }
        //             if($scope.campaignFormObj.backgroundFile.length > 0){
        //                 tasks.push(uploadFile($scope.campaignFormObj.backgroundFile[0], res.data.s3, $scope.campaignFormObj.background_filename));
        //             }
        //             if(tasks.length === 0){
        //                 okFunc();
        //                 return;
        //             }
        //             $q.all(
        //                 tasks
        //             ).then(okFunc, failFunc);
        //
        //         }, function (error) {
        //             $scope.submitWasClicked = false;
        //             $scope.uploading = false;
        //             $log.debug('failed to create new campaign', error);
        //             //deferred.reject();
        //         });
        //
        //     //return deferred.promise;
        // };

        var capitalize = function (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        };

        $scope.removeFile = function (type) {
            $scope.campaignFormObj[type + 'File'] = {};
            $scope.campaignFormObj['fakeValidation' + capitalize(type) + 'Model'] = undefined;
            delete $scope.campaignFormObj['fakeValidation' + capitalize(type) + 'Model'];

            // Restore original backup file
            if ($scope.backupFiles && $scope.backupFiles[type + 'Backup']) {
                $scope.campaignFormObj[type + 'File'] = $scope.backupFiles[type + 'Backup'];
            }

            $scope.campaignFormObj[type + 'File'].file = [];
            delete $scope.campaignFormObj[type + 'File'].file;
        };

        function onCreateNewCampaignSuccess(res) {
            $scope.submitWasClicked = false;
            $scope._refreshImages();
            // $log.debug('files uploaded successfully', res);

            // Upload files
            if (
                $scope.campaignFormObj.iconFile &&
                $scope.campaignFormObj.iconFile.file &&
                $scope.campaignFormObj.iconFile.file[0]
            ) {
                $scope.tasks.push(
                    $scope.uploadFile(
                        $scope.campaignFormObj.iconFile.file[0],
                        res.data.iconS3.bucket,
                        res.data.iconS3.key,
                        res.data.iconS3.campaignId,
                        $scope.campaignFormObj,
                        /* isPreview */ false,
                        /* is additional file */ true,
                        res.data.iconS3.additionalFileId,
                        $scope.campaignFormObj.iconFile.file[0]
                    )
                );
            }

            if (
                $scope.campaignFormObj.backgroundFile &&
                $scope.campaignFormObj.backgroundFile.file &&
                $scope.campaignFormObj.backgroundFile.file[0]
            ) {
                $scope.tasks.push(
                    $scope.uploadFile(
                        $scope.campaignFormObj.backgroundFile.file[0],
                        res.data.backgroundS3.bucket,
                        res.data.backgroundS3.key,
                        res.data.backgroundS3.campaignId,
                        $scope.campaignFormObj,
                        /* isPreview */ false,
                        /* is additional file */ true,
                        res.data.backgroundS3.additionalFileId,
                        $scope.campaignFormObj.backgroundFile.file[0]
                    )
                );
            }

            var messageParams = {};
            messageParams.message = 'Campaign was saved successfully.';

            if ($scope.tasks.length > 0) {
                messageParams.message += ' Files are being uploaded.';

                $q.all($scope.tasks);
            }

            Messages.openMessage($rootScope, messageParams);

            getCampaigns().then(function (campaigns) {
                $scope.clearCampaignForm();
                var campaign = _.find($scope.campaigns, function (campaign) {
                    return campaign.id === res.data.id;
                });
                $scope.campaignForm.$setPristine();
                $scope.updateCampaignSelection(null, campaign);
            });
            return res;
        }

        function onCreateNewCampaignError(error) {
            $scope.submitWasClicked = false;

            $log.debug('AdminAreaCampaignsController::CreateNewCampaign', error);
            var messageParams = {};
            messageParams.title = 'Error Creating Campaign';
            messageParams.message = 'Failed to create campaign: ' + JSON.stringify(error);
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($rootScope, messageParams);
        }

        $scope.sendFormToServer = function () {
            $scope.submitWasClicked = true;

            S3Uploader.generateCognitoIdentity().then(function (cognitoId) {
                $scope.campaignFormObj.cognitoId = cognitoId || $localStorage.cognitoId;
                var backgroundFile,
                    backgroundOriginalFilename,
                    backgroundFilename,
                    backgroundSize,
                    backgroundFileType;
                var iconFile, iconOriginalFilename, iconFilename, iconSize, iconFileType;

                // Additional Files
                if (
                    $scope.campaignFormObj.iconFile &&
                    $scope.campaignFormObj.iconFile.file &&
                    $scope.campaignFormObj.iconFile.file[0]
                ) {
                    iconFile = $scope.campaignFormObj.iconFile.file[0];
                    setNewFilenameToFile(iconFile);
                    iconOriginalFilename = iconFile.original;
                    iconFilename = iconFile.filename || iconFile.name;
                    iconSize = iconFile.size;
                    iconFileType = iconFile.type;
                }

                if (
                    $scope.campaignFormObj.backgroundFile &&
                    $scope.campaignFormObj.backgroundFile.file &&
                    $scope.campaignFormObj.backgroundFile.file[0]
                ) {
                    backgroundFile = $scope.campaignFormObj.backgroundFile.file[0];
                    setNewFilenameToFile(backgroundFile);
                    backgroundOriginalFilename = backgroundFile.original;
                    backgroundFilename = backgroundFile.filename || backgroundFile.name;
                    backgroundSize = backgroundFile.size;
                    backgroundFileType = backgroundFile.type;
                }

                // if (tasks.length === 0){
                //     okFunc();
                //     return;
                // }
                //
                // $q.all(tasks).then(okFunc, failFunc);

                //Remove hash from colors
                $scope.campaignFormObj.color = addRemoveHashForColors(
                    $scope.campaignFormObj.color,
                    'removeHash'
                );
                $scope.campaignFormObj.bg_color = addRemoveHashForColors(
                    $scope.campaignFormObj.bg_color,
                    'removeHash'
                );

                // Icon file
                $scope.campaignFormObj.iconFileUpload = !!iconFile;
                $scope.campaignFormObj.iconOriginalFilename = iconOriginalFilename
                    ? iconOriginalFilename
                    : null;
                $scope.campaignFormObj.iconFilename = iconFilename ? iconFilename : null;
                $scope.campaignFormObj.iconSize = iconSize ? iconSize : null;
                $scope.campaignFormObj.iconFileType = iconFileType ? iconFileType : null;

                // Background file
                $scope.campaignFormObj.backgroundFileUpload = !!backgroundFile;
                $scope.campaignFormObj.backgroundOriginalFilename = backgroundOriginalFilename
                    ? backgroundOriginalFilename
                    : null;
                $scope.campaignFormObj.backgroundFilename = backgroundFilename ? backgroundFilename : null;
                $scope.campaignFormObj.backgroundSize = backgroundSize ? backgroundSize : null;
                $scope.campaignFormObj.backgroundFileType = backgroundFileType ? backgroundFileType : null;

                $scope.campaignFormObj.cognitoId = $localStorage.cognitoId ? $localStorage.cognitoId : null;

                return $http
                    .post('/campaigns/createNewCampaign', $scope.campaignFormObj)
                    .success(onCreateNewCampaignSuccess)
                    .error(onCreateNewCampaignError);
            });
        };

        $scope.removeAdditionalFile = function (additionalFile) {
            additionalFile.file = [];
            additionalFile.fakeValidationAdditionalFileModel = undefined;
        };

        function setNewFilenameToFiles(files) {
            _.each(files, function (file) {
                if (file.filename || file.name) {
                    setNewFilenameToFile(file);
                } else if (file.file && file.file[0] && (file.file[0].filename || file.file[0].name)) {
                    setNewFilenameToFile(file.file[0]);
                } else {
                    $log.debug('HomeUploadController::setNewFilenameToFiles file not found', file);
                    var messageParams = {};
                    messageParams.title = 'Error uploading artwork';
                    messageParams.message =
                        'Failed to rename artwork. Please refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            });
        }

        function setNewFilenameToFile(file) {
            var fileName = file ? file.name || file.filename : $scope.uploadFormObj.filename;
            file.original = fileName;
            file.filename = generateFilename(fileName);
        }

        function generateFilename(filename) {
            var selectedFileExtension = filename.split('.').pop();
            return new Date().getTime() + '-' + S3Uploader.randomString(16) + '.' + selectedFileExtension;
        }

        // $scope.addNewAdditionalFile = function () {
        //     var totalFiles = 0;
        //     totalFiles += $scope.campaignFormObj.newAdditionalFiles ? $scope.campaignFormObj.newAdditionalFiles.length : 0;
        //     totalFiles += $scope.campaignFormObj.additionalFiles ? $scope.campaignFormObj.additionalFiles.length : 0;
        //     totalFiles += $scope.campaignFormObj.newAdditionalFilesTemp ? $scope.campaignFormObj.newAdditionalFilesTemp.length : 0;
        //     var messageParams = {};
        //     if ($scope.campaignFormObj.newAdditionalFilesTemp.length > 0 && totalFiles <= $scope.maxAdditionalFiles) {
        //         //var newAdditionalFile = {
        //         //    title: '',
        //         //    description: '',
        //         //    tags: '',
        //         //    visible: false,
        //         //    file: $scope.campaignFormObj.newAdditionalFilesTemp
        //         //};
        //         //$scope.campaignFormObj.newAdditionalFiles.push(newAdditionalFile);
        //         //$scope.$apply();
        //
        //         var newAdditionalFile;
        //         var bigAdditionalFiles = [];
        //         $scope.campaignFormObj.newAdditionalFilesTemp.forEach(function(file) {
        //             if (file.size < $scope.maxAdditionalFileSize) {
        //                 newAdditionalFile = {
        //                     title: file.name.substr(0, file.name.lastIndexOf('.')).substr(0, FormValidationService.titleCharLimit), // Filename without extension + limit to 60 characters
        //                     description: '',
        //                     tags: '',
        //                     visible: true,
        //                     file: [file],
        //                     new: true
        //                 };
        //                 $scope.campaignFormObj.newAdditionalFiles.push(newAdditionalFile);
        //                 $scope.$apply();
        //             } else {
        //                 bigAdditionalFiles.push(file);
        //             }
        //         });
        //
        //         if (bigAdditionalFiles.length > 0) {
        //             messageParams = {};
        //             messageParams.message = 'Maximum file size allowed is ' + $filter('bytes')($scope.maxAdditionalFileSize) + '. The following files were not added: \n';
        //             bigAdditionalFiles.forEach(function(file) {
        //                 messageParams.message += file.name + ' (' + $filter('bytes')(file.size) + ')\n';
        //             });
        //             messageParams.title = 'Files Exceeded Maximum Allowed Size';
        //             messageParams.disableAutoDismiss = true;
        //             Messages.openMessage($rootScope, messageParams);
        //         }
        //
        //         //for (var i = 0; i < $scope.campaignFormObj.newAdditionalFilesTemp.length; i++) {
        //         //    newAdditionalFile = {
        //         //        title: '',
        //         //        description: '',
        //         //        tags: '',
        //         //        visible: false,
        //         //        file: $scope.campaignFormObj.newAdditionalFilesTemp[i]
        //         //    };
        //         //
        //         //    $scope.campaignFormObj.newAdditionalFiles.push(newAdditionalFile);
        //         //}
        //         //$scope.$apply();
        //
        //         if ($scope.campaignFormObj.newAdditionalFiles.length > 0 && !$scope.toggles.additionalFilesIsOpen) {
        //             $scope.toggles.additionalFilesIsOpen = true;
        //             $scope.$apply();
        //         }
        //     } else {
        //         messageParams = {};
        //         messageParams.message = 'The maximum amount of files allowed per artwork is 15, current amount is ' + totalFiles + '.';
        //         messageParams.title = 'Number of Files Exceeded Maximum Amount';
        //         messageParams.disableAutoDismiss = true;
        //         Messages.openMessage($rootScope, messageParams);
        //     }
        // };

        // $scope.removeNewAdditionalFile = function (newAdditionalFile, newAdditionalFileIndex) {
        //     $scope.removeAdditionalFile(newAdditionalFile);
        //     $scope.campaignFormObj.newAdditionalFiles.splice(newAdditionalFileIndex, 1);
        //     if ($scope.campaignFormObj.additionalFiles.length === 0 && $scope.campaignFormObj.newAdditionalFiles.length === 0) {
        //         $scope.toggles.additionalFilesIsOpen = false;
        //     }
        // };

        $scope.updateAdditionalFile = function (additionalFile, additionalFileIndex) {
            var data = additionalFile;
            data.campaignId = data.campaign_id;
            data.additionalFileId = data.id;

            additionalFile.updating = true;

            return (
                $http
                    .post('/additionalFiles/updateEntityAdditionalFile', data)
                    // .then(function (res) {
                    //     var messageParams = {};
                    //     messageParams.message = 'Successfully updated the file';
                    //     messageParams.title = 'Update File';
                    //     Messages.openMessage($rootScope, messageParams);
                    // })
                    .finally(function (res) {
                        additionalFile.updating = false;
                        additionalFile.isChanged = false;
                    })
            );
        };

        $scope.deleteAdditionalFile = function (additionalFile, additionalFileIndex) {
            var messageParams = {};

            messageParams.message = 'You are about to delete the file from this campaign.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete File';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        campaignId: additionalFile.campaign_id,
                        additionalFileId: additionalFile.id,
                    };

                    additionalFile.deleting = true;

                    return $http
                        .post('/additionalFiles/deleteEntityAdditionalFile', data)
                        .then(function (res) {
                            var messageParams = {};
                            messageParams.message = 'Successfully deleted the file';
                            messageParams.title = 'Delete File';
                            Messages.openMessage($rootScope, messageParams);
                            var type = additionalFile.type_id === '1' ? 'background' : 'icon';
                            $scope.removeFile(type);
                        })
                        .finally(function (res) {
                            additionalFile.deleting = false;
                            additionalFile.isChanged = false;
                        });
                }
            });
        };

        $scope.additionalFileStatusPopover = function (additionalFile) {
            var popover = '';

            if (additionalFile.status && additionalFile.status !== 'READY') {
                if (additionalFile.status === 'UPLOADING') {
                    popover = 'File is being uploaded or was interrupted in the middle of uploading';
                } else {
                    popover = 'File failed to upload, please delete this line and re-upload';
                }
            } else if (additionalFile.file && additionalFile.file.length > 0) {
                popover = 'File will begin to upload when the form will be submitted';
            }

            return popover;
        };

        $scope.validateAdditionalFile = function (file, type) {
            var result = true;
            var messageParams = {};

            if (file) {
                $scope.campaignFormObj[type + 'File'].file[0].generalType = '';

                var fileType = file.type;
                var extension = file.name.split('.').pop().toLowerCase();

                if (file.size > $scope.maxAdditionalFileSize) {
                    // 100GB
                    messageParams = {};
                    messageParams.title = 'File Size Exceeds Upload Limit';
                    messageParams.message =
                        file.name +
                        ' File size (' +
                        $filter('bytes')(file.size) +
                        ') exceeds your allowed single upload limit.\nPlease use a reduced file size (up to ' +
                        $filter('bytes')($scope.maxAdditionalFileSize) +
                        ')';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    result = false;
                    // } else if ((!isCover && isVideo(fileType, extension)) || (isCover && isVideoForPreview(fileType, extension))) {
                    //     switch (fileType) {
                    //         case 'video/ogg':
                    //         case 'video/mp4':
                    //         case 'video/webm':
                    //             break;
                    //         default:
                    //             fileType = '';
                    //     }
                    //     $scope.fileType = 'video';
                } else if (type === 'background' && isVideo(fileType, extension)) {
                    switch (fileType) {
                        case 'video/ogg':
                        case 'video/mp4':
                        case 'video/webm':
                            break;
                        default:
                            fileType = '';
                    }
                    $scope.campaignFormObj[type + 'File'].file[0].generalType = 'video';
                } else if (isImage(fileType, extension)) {
                    $scope.campaignFormObj[type + 'File'].file[0].generalType = 'image';
                } else {
                    messageParams = {};
                    messageParams.message =
                        file.name +
                        ' File type is not supported.\nSupported file types are: gif, jpeg, jpg, png, and for background also videos: mpg, mpeg, mp4, avi, mov, flv and mkv';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    result = false;
                }
            } else {
                result = false;
            }
            return result;
        };

        $scope.onAdditionalFileChange = function (type, newFile) {
            // Validate File
            var fileIsValid = $scope.validateAdditionalFile(newFile, type);

            if (!fileIsValid) {
                $scope.removeFile(type);
            }
        };

        $rootScope.$on('campaign_additional_file_changed_status', function (event, campaignId, status) {
            if (status === 'READY') {
                getCampaigns().then(function () {
                    $scope.clearCampaignForm();
                    var campaign = _.find($scope.campaigns, function (campaign) {
                        return campaign.id === campaignId;
                    });
                    $scope.campaignForm.$setPristine();
                    $scope.updateCampaignSelection(null, campaign);
                });
            }
        });
    },
]);
