'use strict';

angular.module('devices').controller('DevicesDefaultSettingsController', [
    '$log',
    '$scope',
    'DevicesDataService',
    'Messages',
    '_',
    'FormValidationService',
    function ($log, $scope, DevicesDataService, Messages, _, FormValidationService) {
        $scope.pages = [
            {
                link: 'basic',
                name: 'DEVICES.BASIC',
            },
            //            {
            //                link: 'advanced',
            //                name: 'DEVICES.ADVANCED'
            //            }
        ];

        function initTransitionMinutes() {
            if ($scope.settings && $scope.settings.Advanced) {
                if ($scope.settings.Advanced.InteractiveDurationDivider === undefined) {
                    $scope.settings.Advanced.InteractiveDurationDivider =
                        $scope.settings.Advanced.VideoDurationDivider;
                }
                $scope.dividers.imageSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.StaticImageDurationDivider
                );
                $scope.dividers.animGifsSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.AnimationDurationDivider
                );
                $scope.dividers.videoSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.VideoDurationDivider
                );
                $scope.dividers.interactiveSeconds = Math.round(
                    $scope.settings.Advanced.DTU / $scope.settings.Advanced.InteractiveDurationDivider
                );
            }
        }

        function initGroupAddress() {
            if ($scope.settings && $scope.settings.Advanced) {
                $scope.optionalGroupAddresses = [
                    '239.1.1.234',
                    '239.1.1.235',
                    '239.1.1.236',
                    '239.1.1.237',
                    '239.129.1.2',
                    '239.254.1.234',
                ];
                $scope.settings.Advanced.GroupAddress =
                    $scope.settings.Advanced.GroupAddress || '239.1.1.234';
                $scope.settings.Advanced.GroupPort = $scope.settings.Advanced.GroupPort || 7234;
            }
        }

        $scope.init = function () {
            $scope.fromDefaultSettings = true;
            $scope.canManageDevice = true; // for default settings - allow admin to change
            $scope.settings = {};
            $scope.parent = {};
            $scope.dividers = {};
            $scope.parent.applyAll = false;
            $scope.formValidator = FormValidationService;

            DevicesDataService.getDefaultSettings().then(
                function (res) {
                    $scope.settings = res;
                    initTransitionMinutes();
                    initGroupAddress();
                },
                function (error) {
                    $log.debug('Error getting default display settings: ' + error);
                }
            );
        };

        $scope.updateSettings = function () {
            DevicesDataService.updateDefaultSettings($scope.settings, $scope.parent.applyAll).then(
                function (res) {
                    var messageParams = {};
                    messageParams.message = 'Default settings were updated successfully';
                    messageParams.title = 'Settings Update';
                    Messages.openMessage($scope, messageParams);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message =
                        'Failed to update default settings. Please refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    messageParams.title = 'Settings Update Failed';
                    Messages.openMessage($scope, messageParams);
                }
            );
        };

        $scope.alertIfApplyAll = function () {
            if ($scope.parent.applyAll) {
                var messageParams = {};
                messageParams.message =
                    'Attention! This option will change the settings for all displays and groups in ' +
                    $scope.$platformManager.platform.capital +
                    "\nAre you sure know what you're doing?";
                messageParams.title = 'Default settings apply for all displays and groups';
                messageParams.size = 'lg';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Yes';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    $scope.parent.applyAll = res === 'ok';
                });
            }
        };
    },
]);
