'use strict';

angular.module('home').controller('HomeUploadArtistBioController', [
    function () {
        var self = this;
        this.init = function () {
            self.formObj = self.params.uploadFormObj;
            self.form = self.params.uploadForm;
        };
    },
]);
