angular.module('billing').controller('BillingFormController', [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    '$window',
    '$sce',
    '$log',
    '$stateParams',
    '$location',
    'FormValidationService',
    'ArtworkInfoService',
    'Messages',
    'User',
    'OpenPopupForm',
    'BillingService',
    'NIIO_URL',
    '_',
    function (
        $rootScope,
        $scope,
        $state,
        $timeout,
        $window,
        $sce,
        $log,
        $stateParams,
        $location,
        FormValidationService,
        ArtworkInfoService,
        Messages,
        User,
        OpenPopupForm,
        BillingService,
        NIIO_URL,
        _
    ) {
        var self = this;
        this.currentDate = new Date();
        this.currentYear = this.currentDate.getFullYear();
        this.expireDate = '';
        this.isFetched = true;
        this.freeTrialExpireString = '';
        this.countries = FormValidationService.countryListAllIsoData;
        this.states = FormValidationService.states;
        this.BillingService = BillingService;
        this.plans = this.BillingService.plans;
        this.billingTypes = this.BillingService.billingTypes;
        this.Messages = Messages;
        this.User = User;
        this.elite = '4f9800fe-0cb2-11e8-ac68-55f1bcd80799';
        this.pro = '4f9800fd-0cb2-11e8-ac68-55f1bcd80799';
        this.prime = '4f9800f7-0cb2-11e8-ac68-55f1bcd80799';
        this.plus = '4f9800fb-0cb2-11e8-ac68-55f1bcd80799';
        this.annuallyDivisionBy = 12;
        this.couponCode = '';
        this.couponCodeFetching = false;
        this.planActionButton = 'Continue';

        // props
        this.price;
        this.isYearly;
        this.payedItemId;
        this.trialDays;
        // default values for props
        this.$onInit = () => {
            this.isYearly = this.isYearly || false;
            this.trialDays = this.trialDays || 0;
        };

        this.priceCoupon = null;
        this.firstName = $rootScope.$user.getFirstName();
        this.lastName = $rootScope.$user.getLastName();
        this.email = $rootScope.$user.getEmail();
        this.company = '';
        this.country = this.countries?.find((i) => i.name === $rootScope.$user.getCountry()) || '';
        this.state = $rootScope.$user.getState();
        this.city = $rootScope.$user.getCity();
        this.street = '';
        this.zip = '';

        this.selectedPlanData = null;
        $scope.$watch(
            'vm.selectedPlan',
            (newVal) => {
                self.selectedPlanData = self.plans.find((plan) => plan.uid === newVal) || null;
            },
            true
        );

        $scope.$watch(
            'vm.billingEmail',
            (newVal, oldVal, $scope) => {
                const finalBillingEmail = this.billingEmail || this.email;
                if (finalBillingEmail === $rootScope.$user.getEmail()) {
                    return;
                }

                const messageParams = {};
                messageParams.title = 'WARNING!';
                messageParams.message = `Please note: Your billing information will be sent to ${this.billingEmail} which is different than the email you are using in your Niio account.`;
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            },
            true
        );

        $scope.$watch(
            'vm.errors',
            (newVal, oldVal, $scope) => {
                var activeErrors = Object.values(newVal)
                    .filter((err) => err.active)
                    .map((err) => err.message);
                if (activeErrors.length === 0) return;

                var messageParams = {};
                messageParams.message = activeErrors.join('\n').replace(/\.\s/g, '\n');
                messageParams.title = 'Oops, Some error occurred';
                messageParams.disableAutoDismiss = true;

                Messages.openMessage($scope, messageParams);
            },
            true
        );

        this.isCardAddressEqualsBillingAddress = true;
        this.billingEmail = '';
        this.billingCountry = '';
        this.billingState = '';
        this.billingCity = '';
        this.billingStreet = '';
        this.billingZip = '';

        this.cardNumberString = '';
        this.cvc = '';
        this.expireYear = '';
        this.expireMonth = '';

        this.isSubmitted = false;

        this.selectedPaymentSource = null;
        this.paymentSources = [];
        this.isPaymentSourcesFetched = false;
        this.paymentSourceOptions = [];

        this.assetsBaseUrl = BillingService.assetsBaseUrl;

        this.getDefaultErrorMessages = function (errorKey) {
            errorKey = errorKey || null;
            const errorsObj = {
                'billing_address[zip]': {
                    message: 'Enter a valid zip code',
                    active: false,
                },
                general: {
                    message: 'Oops, something went wrong. Please contact us',
                    active: false,
                },
                'credit_card.billing_zip': {
                    message: 'Enter a valid zip code',
                    active: false,
                },
                'card[number]': {
                    message: 'Enter a valid card number',
                    active: false,
                },
                coupon: {
                    message: 'Enter a valid coupon code',
                    active: false,
                },
                email: {
                    message: 'Enter a valid email',
                    active: false,
                },
                expireDate: {
                    message: 'Enter a valid date',
                    active: false,
                },
                'card[expiry_month]': {
                    message: 'Enter a valid expiry date',
                    active: false,
                },
            };
            if (errorKey) return errorsObj[errorKey] || null;
            return { ...errorsObj };
        };

        this.init = function () {
            $scope.$emit('setErrors', self.getDefaultErrorMessages());
            $stateParams = Object.assign($stateParams, self.params); // self.params are the params from modal (templateParams)
            self.fromModal = $stateParams.fromModal;
            self.fromJoin = $stateParams.fromJoin;
            self.fromIframe = $stateParams.fromIframe;

            // Apply coupon via query params
            self.couponCode = $stateParams.cc || '';
            self.couponCode && self.onCouponFormUpdate();

            var xxlModal = document.getElementsByClassName('modal-xxl');
            if (xxlModal && xxlModal[0]) {
                xxlModal[0].style = 'min-height: 100%';
            }

            if (this.trialDays) {
                this.currentDate = new Date();
                this.freeTrialExpireDate = new Date(
                    this.currentDate.setDate(
                        this.currentDate.getDate() +
                            (this.User.getUserUsedTrial() > -1 && this.trialDays > this.User.getUserUsedTrial()
                                ? this.trialDays - this.User.getUserUsedTrial()
                                : this.trialDays)
                    )
                )
                    .toDateString()
                    .substring(4);
            }

            this.subscribe();
        };

        BillingService.getPaymentSources().then(async (paymentSources) => {
            self.isPaymentSourcesFetched = true;
            $scope.$emit('load');

            self.paymentSources = paymentSources;

            let haveSelected = false;
            self.paymentSourceOptions = (self.paymentSources || [])
                .map((paymentSource) => {
                    if (paymentSource.id === 'niio_credits') {
                        let creditSource = {
                            name: 'Niio credits - $' + paymentSource.balance,
                            value: 'niio_credits',
                        };
                        if (Number(paymentSource.balance) >= Number(self.price) && self.currency === 'USD') {
                            haveSelected = true;
                            creditSource.selected = true;
                        } else {
                            creditSource.disabled = true;
                            if (self.currency !== 'USD') {
                                creditSource.title = 'Currency not supported';
                            } else {
                                creditSource.title = 'Not enough balance';
                            }
                        }
                        return creditSource;
                    }
                    const nextMonth = new Date();
                    nextMonth.setMonth(nextMonth.getMonth() + 1);
                    nextMonth.setDate(1);

                    const expireDate = new Date(
                        `${paymentSource.card.expiry_month}/01/${paymentSource.card.expiry_year}`
                    );
                    const isExpired = nextMonth > expireDate;
                    const isSelected = !haveSelected && !isExpired;
                    if (isSelected) haveSelected = true;

                    return {
                        name: `
                        <div>
                            Card number - ${paymentSource.card.masked_number}
                            <br>
                            Expiry Data - 
                            <span class="${(isExpired && 'text-danger') || ''}">
                                ${paymentSource.card.expiry_month}/${paymentSource.card.expiry_year}
                                ${(isExpired && ' (Expired)') || ''}
                            </span>
                        </div>
                    `,
                        value: paymentSource.id,
                        disabled: isExpired,
                        selected: isSelected,
                    };
                })
                .sort((i) => (i.selected && -1) || (!i.disabled && 0) || 1) // selected=-1, not disabled=0, disabled=1
                .concat([
                    {
                        name: 'Add new card',
                        value: null,
                        selected: true,
                    },
                ]);

            self.selectedPaymentSource =
                self.paymentSourceOptions.find((i) => i.selected)?.value ||
                self.paymentSourceOptions.find((i) => !i.disabled)?.value ||
                null;
        });

        this.onCouponFormUpdate = () => {
            this.couponCodeFetching = true;
            if (!this.errors) this.errors = this.getDefaultErrorMessages();
            this.errors.coupon = this.getDefaultErrorMessages('coupon');
            $scope.$emit('setErrors', this.errors);

            BillingService.validateCoupon({
                coupon: self.couponCode,
                items: [
                    {
                        id: self.payedItemId,
                        type: $stateParams.type || 'subscription',
                        action: $stateParams.action,
                    },
                ],
                channelId: $stateParams.channelId,
                publishUniqueSeq: $stateParams.share,
                password: $stateParams.password || BillingService.password || ArtworkInfoService.artworkSharedPassword,
            })
                .then((couponData) => {
                    if (couponData.data.data) {
                        couponData.data = couponData.data.data;
                    }
                    self.priceCoupon = String(couponData.data.total);
                })
                .catch((err) => {
                    const errorParsed = self.parseError(err.data.message.text);
                    if (self.errors[errorParsed.field]) {
                        self.errors[errorParsed.field].active = true;
                    } else {
                        self.errors.general.message = errorParsed.message;
                        self.errors.general.active = true;
                    }
                    self.priceCoupon = null;
                })
                .finally(() => {
                    self.couponCodeFetching = false;
                    $scope.$emit('setErrors', self.errors || self.getDefaultErrorMessages());
                });
        };

        this.subscribe = function () {
            if (!User.isAuthenticated()) {
                $stateParams.source = 'plans';
                $stateParams.service = 'plans';
                self.fromModalAfterJoin = true;
                OpenPopupForm.openMenu('join', $stateParams);
                return;
            }
            $rootScope.$broadcast('ajax-start');

            this.planActionButton =
                (self.selectedPlanData && self.selectedPlanData.actionButton) || this.planActionButton;

            this.freeTrialExpireDate = new Date(
                this.currentDate.setDate(this.currentDate.getDate() + this.planExpireDaysCount)
            )
                .toDateString()
                .substring(4);

            if (Number(self.payedItemId) != self.payedItemId) {
                BillingService.canUserUpgradePlanByProvider().then(
                    (res) => {
                        if (res && res.data && res.data.data.canUpgrade) {
                            self.redirectToPayment = true;
                            var xxlModal = document.getElementsByClassName('modal-xxl');
                            if (xxlModal && xxlModal[0]) {
                                xxlModal[0].style['max-width'] = '670px';
                                // xxlModal[0].classList.remove('modal-xxl');
                            }
                            return;
                        }

                        var messageParams = {};

                        var currentProvider =
                            res.data.data.currentProvider === 'apple' ? 'Apple Pay' : 'Web or Android app';

                        messageParams.title = 'Subscription Exists on Other Platform';
                        messageParams.message =
                            `It seems like you subscribed to Niio already through ${res.data.data.currentProvider}. Please upgrade using ${res.data.data.currentProvider} or cancel your subscription there ` +
                            `and upgrade from this page.`;
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    },
                    (err) => {
                        $log.debug('provider check failure', err);
                        OpenPopupForm.openContact('support', null, 'Subscription plan payment failed... ');
                    }
                );
            }

            this.isSubmitted = false;
            $window.scrollTo(0, 0);
        };

        this.parseError = function (errorText) {
            var errorParts = errorText.split(' : ', 2);
            var erroredField = errorParts[0];
            var errorMessage = errorParts[1];

            if (erroredField.indexOf(' ') !== -1) {
                erroredField = null;
                errorMessage = errorText;
            }

            return {
                field: erroredField,
                message: errorMessage,
            };
        };

        $rootScope.$on('submit-done', () => {
            this.isSubmitted = false;
            $rootScope.$broadcast('ajax-stop');

            $timeout(() => {
                // Scroll to last error
                const element = document.querySelector('.payment-form-error-text')?.parentElement;
                if (!element) return;

                const headerHeight = self.fromIframe
                    ? 0
                    : document.querySelector('.main-nav').getClientRects()[0].height * 2;
                const y = element.getBoundingClientRect().top + window.pageYOffset - headerHeight;
                window.scrollTo({ top: y, behavior: 'smooth' });

                document.querySelector('.payment-form-error-text').scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }, 0);
        });

        this.onBillingFormSubmitted = (_) => {
            self.isSubmitted = true;

            var cardAddress = {};
            if (!this.isCardAddressEqualsBillingAddress) {
                cardAddress = {
                    billingCity: this.billingCity,
                    billingCountry: this.billingCountry.code,
                    billingStreet: this.billingStreet,
                    billingZip: this.billingZip,
                };
            }

            var paymentData = {};
            if (this.selectedPaymentSource) {
                paymentData.paymentSourceId = this.selectedPaymentSource;
            } else {
                paymentData = {
                    cardNumber: this.cardNumberString.replace(/\s/g, ''),
                    cvc: this.cvc,
                    expirationYear: this.expireYear,
                    expirationMonth: this.expireMonth,
                };
            }

            const isNewPaymentUser =
                this.paymentSources === null ||
                this.paymentSources?.length === 0 ||
                (this.paymentSources?.length === 1 && this.paymentSources?.[0]?.id === 'niio_credits');
            const accountInfo = {};
            if (isNewPaymentUser) {
                accountInfo.firstName = this.firstName;
                accountInfo.lastName = this.lastName;
                accountInfo.email = this.email;
                accountInfo.company = this.company;
            }

            const subscriptionData = {
                ...accountInfo,
                itemPriceId: this.payedItemId,
                country: this.country.code,
                state: this.state,
                city: this.city,
                street: this.street,
                zip: this.zip,
                coupon: this.couponCode,
                billingEmail: this.billingEmail,
                ...cardAddress,
                ...paymentData,
                password: $stateParams.password || BillingService.password || ArtworkInfoService.artworkSharedPassword,
                publishUniqueSeq: $stateParams.share,
                type: $stateParams.type,
                channelId: $stateParams.channelId, // for artwork from channel
                artworkId: $stateParams.artworkId, // for artwork purchase or rental
                eventId: $stateParams.eventId, // for event submission fee
                id: $stateParams.id, // for subscription
            };

            $scope.$emit('setErrors', this.getDefaultErrorMessages());
            $scope.$emit('submit', subscriptionData);

            $scope.$on('submit-done', () => {
                this.isSubmitted = false;
            });
        };

        this._keepNumbers = function (str) {
            str = str || '';
            return str.replace(/(\s|\D)/g, '');
        };

        this.onCardNumberChange = function () {
            var cleanNumbers = this._keepNumbers(this.cardNumberString);
            cleanNumbers = cleanNumbers.slice(0, 16);
            this.cardNumberString = cleanNumbers.replace(/(\d{4})/g, '$1  ').trim();
        };

        this.onDateChange = function () {
            event.preventDefault();
            if (!self.errors) {
                self.errors = self.getDefaultErrorMessages();
            }

            if (self.expireDate.length === 2 && event.data !== null) {
                this.expireDate += '/';
            }
            if (self.expireDate.length === 5) {
                const [month, year] = self.expireDate.split('/').map(Number);
                if (month < 1 || month > 12) {
                    self.expireDate = '';
                    self.errors.expireDate.active = true;
                    return;
                }
                const currentYearShort = Number(new Date().getFullYear().toString().substr(-2));
                if (year < currentYearShort || year >= currentYearShort + 50) {
                    self.expireDate = '';
                    self.errors.expireDate.active = true;
                    return;
                }

                // all valid
                const [expireMonth, expireYear] = self.expireDate.split('/');
                self.expireMonth = expireMonth;
                self.expireYear = expireYear;
            }
            self.errors.expireDate.active = false;

            $scope.$emit('setErrors', self.errors);
        };

        this.onCVCChange = function () {
            this.cvc = this._keepNumbers(this.cvc);
            if (this.cvc.length <= 3) return true;

            this.cvc = this.cvc.slice(0, event.data.length * -1);
        };
    },
]);
