'use strict';

angular.module('core').controller('DownloadAppController', [
    '$scope',
    'ENV',
    '$window',
    function ($scope, ENV, $window) {
        if (ENV === 'prod') {
            ENV = 'www';
        }

        var url = 'https://s3.amazonaws.com/niioplayer/' + ENV + '/NiioPlayer.ver';

        $scope.version = '';
        var jsonFile = new XMLHttpRequest();
        jsonFile.open('GET', url, true);
        jsonFile.send();

        jsonFile.onreadystatechange = function () {
            if (jsonFile.readyState === 4 && jsonFile.status === 200) {
                $scope.$apply(function () {
                    $scope.version = jsonFile.responseText;
                    $scope.lastDate = new Date(jsonFile.getResponseHeader('Last-Modified'));
                });
            }
        };

        $scope.downloadApp = function () {
            if ($scope.version) {
                $window.location.href =
                    'https://s3.amazonaws.com/niioplayer/' + ENV + '/NiioPlayer-' + $scope.version + '.apk';
            }
        };
    },
]);
