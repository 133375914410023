//English
translationsEN.HOME_PAGE = {};

translationsEN.HOME_PAGE.FIRST_NAME = 'First Name';
translationsEN.HOME_PAGE.LAST_NAME = 'Last Name';
translationsEN.HOME_PAGE.EMAIL = 'Email';
translationsEN.HOME_PAGE.SEND_REQUEST = 'Request Invitation';
translationsEN.HOME_PAGE.TITLE = 'art stream';

//Hebrew
translationsHE.HOME_PAGE = {};

translationsHE.HOME_PAGE.FIRST_NAME = 'שם פרטי';
translationsHE.HOME_PAGE.LAST_NAME = 'שם משפחה';
translationsHE.HOME_PAGE.EMAIL = 'דואר אלקטרוני';
translationsHE.HOME_PAGE.SEND_REQUEST = 'שלח בקשה';
translationsHE.HOME_PAGE.TITLE = 'זרם האומנות';
