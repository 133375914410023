'use strict';

angular.module('core').service('OpenDeepLink', [
    '$q',
    '$timeout',
    '$rootScope',
    '$location',
    function ($q, $timeout, $rootScope, $location) {
        this.func = function (deeplinkUrl) {
            // Taken from: https://github.com/omarish/tweetable/blob/master/tweetable.js
            // and here: https://stackoverflow.com/questions/13044805/how-to-check-if-an-app-is-installed-from-a-web-page-on-an-iphone
            var deferred = $q.defer();
            var originalStart = new Date();
            var lag = 1250;
            var linkToRedirect, search, params;
            // var deeplink_url = "niioart://post?message=" + encodeURIComponent(text);
            if (!$rootScope.isSmartPhone) {
                deferred.reject();
            } else {
                // If we tried to open using deeplink already

                search = window.location.search; /*your search query ex:-?author=bloggingdeveloper&a1=hello*/
                params = new URLSearchParams(search);

                // alert('search ' + JSON.stringify(window.location.href));
                // alert('search2 ' + JSON.stringify(params.get('deepLinked')));

                if (params.get('deepLinked') === 'deep') {
                    // alert('deleted');
                    // delete $location.search().deepLinked;
                    params.delete('deepLinked');
                    // alert('search2 after deletion ' + JSON.stringify(params.get('deepLinked')));
                    deferred.reject();
                } else {
                    // $location.search('deepLinked', 'true');
                    if (!params.get('deepLinked')) {
                        $location.search('deepLinked', 'universal');
                        linkToRedirect = $location.absUrl();
                        // alert('New link: ' + JSON.stringify(linkToRedirect));
                        // window.open(window.location.href, '_blank');
                        // location.reload(true);
                        // window.location.href = window.location.href;
                        //     window.location.replace($location.absUrl());
                        //     alert('universal2: ' + JSON.stringify($location.absUrl()));
                        window.location = $location.absUrl();
                        // window.location.replace($location.absUrl());
                        // window.open($location.absUrl(), '_blank');
                    } else {
                        $location.search('deepLinked', 'deep');
                        linkToRedirect = $location.absUrl();
                        // alert('New link: ' + JSON.stringify(linkToRedirect));
                        // window.location.href = deeplinkUrl;
                        // window.location.replace(deeplinkUrl);
                        // alert('deep: ' + JSON.stringify(deeplinkUrl));
                        let path = window.location.pathname;
                        if (path.charAt(0) === '/') {
                            path = path.slice(1);
                        }
                        window.location = deeplinkUrl + path + window.location.search;
                    }

                    $timeout(function () {
                        var timeSpentWaiting = new Date() - originalStart;
                        if (timeSpentWaiting > 2.0 * lag) {
                            // We can assume they have the app, so do nothing.
                            // alert('we have the app');
                            deferred.resolve();
                        } else {
                            // That was too fast so we can assume they don't have the app.
                            // alert('we don\'t have the app. Redirect here: ' + linkToRedirect);
                            $rootScope.$apply(function () {
                                $location.search('deepLinked', 'true');
                                window.location.replace(linkToRedirect);
                            });
                        }
                    }, lag);
                }
            }

            return deferred.promise;
        };
    },
]);
