'use strict';

angular.module('core').directive('validatePasswordCharacters', function () {
    var REQUIRED_PATTERNS = [
        /\d+/, //numeric values
        /[a-z]+/, //lowercase values
        ///[A-Z]+/, //uppercase values
        ///\W+/,    //special characters
        /^\S+$/, //no whitespace allowed
    ];

    //Password must include at least 8 characters, 1 digit, 1 upper case and 1 lower case letters

    return {
        require: 'ngModel',
        link: function ($scope, element, attrs, ngModel) {
            ngModel.$validators.passwordCharacters = function (value) {
                var status = true;
                angular.forEach(REQUIRED_PATTERNS, function (pattern) {
                    status = status && pattern.test(value);
                });
                return status;
            };
        },
    };
});
