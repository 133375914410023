'use strict';

// Setting up route
angular.module('components').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        // Users state routing
        $stateProvider.state('components', {
            url: '/components',
            //                abstract: true,
            templateUrl: 'modules/components/views/components.client.view.html',
            controller: 'ComponentsController',
        });
    },
]);
