'use strict';

angular.module('core').service('AdminManager', [
    'Messages',
    '$http',
    '$rootScope',
    '$q',
    '$log',
    function (Messages, $http, $rootScope, $q, $log) {
        this.handleAdminAction = function (type, id, actionName, value) {
            var deferred = $q.defer(),
                action;

            switch (actionName) {
                case 'market_order':
                    action = this.changeOrder;
                    break;
                case 'visible':
                    action = this.changeVisibility;
                    break;
                case 'status':
                    action = this.changeBlocked;
                    break;
                case 'featured':
                    action = this.changeFeatured;
                    break;
                case 'prime':
                    action = this.changePrime;
                    break;
                case 'flagged':
                    action = this.changeFlagged;
                    break;
                case 'tier':
                    action = this.changeTier;
                    break;
                case 'official_account':
                    action = this.changeOfficial;
                    break;
                case 'rating':
                    action = this.changeRating;
                    break;
                case 'restriction':
                    action = this.changeRestriction;
                    break;
                case 'delete':
                    action = this.deleteType;
                    break;
                case 'tags':
                    action = this.updateTags;
                    break;
                default:
                    action = '';
                    $log.debug('AdminManager::handleAdminAction trying to perform an action that is not defined');
            }

            if (action) {
                action(type, id, value).then(
                    function (res) {
                        deferred.resolve(res);
                        $log.debug(
                            'AdminManager::handleAdminAction success handlingAdminAction of type ' + actionName,
                            res
                        );
                    },
                    function (err) {
                        deferred.reject(err);
                        $log.debug(
                            'AdminManager::handleAdminAction error handlingAdminAction of type ' + actionName,
                            err
                        );
                    }
                );
            } else {
                deferred.reject();
            }

            return deferred.promise;
        };

        this.changeOrder = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.order = value;

            $http
                .post('/' + type + 's/updateOrderAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeOrder success trying to change order', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeOrder failed trying to change order', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changeVisibility = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.visible = value;

            $http
                .post('/' + type + 's/updateVisibilityAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeVisibility success trying to change visibility', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeVisibility failed trying to change visibility', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };
        this.changeBlocked = function (type, id, value) {
            var deferred = $q.defer();
            var data = {
                userId: id,
                status: value,
            };

            $http
                .post('/userPlans/setAccountStatus', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeBlocked success trying to change block status', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeBlocked failed trying to change block status', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changeFeatured = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.featured = value;

            $http
                .post('/' + type + 's/updateFeaturedAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeFeatured success trying to change featured', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeFeatured failed trying to change featured', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changePrime = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.prime = value;

            $http
                .post('/' + type + 's/updatePrimeAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changePrime success trying to change prime', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changePrime failed trying to change prime', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changeFlagged = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.flagged = value;

            $http
                .post('/' + type + 's/updateFlaggedAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeFlagged success trying to change flagged', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeFlagged failed trying to change flagged', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changeTier = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.tier = value;

            $http
                .post('/' + type + 's/updateTierAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeTier success trying to change tier', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeTier failed trying to change tier', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changeOfficial = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.official = value;

            $http
                .post('/' + type + 's/updateOfficialAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeOfficial success trying to change featured', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeOfficial failed trying to change featured', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changeRating = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.rating = value;

            $http
                .post('/' + type + 's/updateRatingAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeRating success trying to change rating', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeRating failed trying to change rating', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        this.changeRestriction = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.restricted = value;

            $http
                .post('/' + type + 's/updateRestrictedAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeRestriction success trying to change restriction', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeRestriction failed trying to change restriction', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };

        var deleteTypeImp = function (type, id, force) {
            var data = {},
                messageParams = {};

            data[type + 'Id'] = id;
            data.force = force;

            return $http.post(
                '/' + type + 's/delete' + type.substring(0, 1).toUpperCase() + type.substring(1) + 'Admin/',
                data
            );
        };

        this.deleteType = function (type, id, value) {
            var deferred = $q.defer(),
                messageParams = {};

            var title = value || '';
            messageParams.message = 'Are you sure you want to delete ' + type + ' ' + title + ' (' + id + ') ?';
            messageParams.title = 'Delete ' + type;
            messageParams.disableAutoDismiss = true;
            messageParams.okText = 'OK';
            messageParams.cancelText = 'Cancel';

            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    deleteTypeImp(type, id, false)
                        .success(function (res) {
                            $log.debug('AdminManager::deleteType success trying to delete', res);
                            messageParams.message = 'Successfully deleted ' + type + ' ' + id;
                            Messages.openMessage($rootScope, messageParams);
                            deferred.resolve(res);
                        })
                        .error(function (err, status) {
                            if (status === 409) {
                                //Double Confirm is required. (Currently only on delete USER
                                messageParams = {};
                                var userInfo = err.data || {
                                    userId: id,
                                    sold: 0,
                                    purchased: 0,
                                    transferRequests: 'Unknown',
                                    created: 'Unknown date',
                                    message: '',
                                };

                                messageParams.message =
                                    'There are Artworks related to that ' +
                                    type +
                                    ' that CAN NOT BE DELETED\n (i.e. There are ' +
                                    userInfo.sold +
                                    ' Sold editions, ' +
                                    userInfo.transferRequests +
                                    ' Requests for Transfer Ownership and ' +
                                    userInfo.purchased +
                                    ' Purchased Artworks editionss).\n User created on: ' +
                                    userInfo.created +
                                    '\nAre you sure you want to FORCE delete ( ' +
                                    type +
                                    ' ' +
                                    id +
                                    ' ) ?';
                                messageParams.title = 'ARE YOU SURE YOU WANT TO DELETE THIS ' + type + ' ?';
                                messageParams.disableAutoDismiss = true;
                                messageParams.okText = 'FORCE DELETE';
                                messageParams.okClass = 'danger';
                                messageParams.cancelText = 'Cancel';

                                Messages.openMessage($rootScope, messageParams).then(function (res) {
                                    if (res === 'ok') {
                                        deleteTypeImp(type, id, true /*force*/)
                                            .success(function (res) {
                                                $log.debug('AdminManager::deleteType success trying to delete', res);
                                                messageParams.title = 'Delete ' + type;
                                                messageParams.okText = 'OK';
                                                messageParams.okClass = 'primary';
                                                messageParams.message = 'Successfully deleted ' + type + ' ' + id;
                                                Messages.openMessage($rootScope, messageParams);
                                                deferred.resolve(res);
                                            })
                                            .error(function (err) {
                                                $log.debug('AdminManager::deleteType failed trying to delete', err);
                                                messageParams.message = 'Failed to delete ' + type + ' ' + id;
                                                messageParams.okText = 'OK';
                                                messageParams.okClass = 'primary';
                                                messageParams.disableAutoDismiss = true;
                                                Messages.openMessage($rootScope, messageParams);
                                                deferred.reject(err);
                                            });
                                    } else {
                                        deferred.reject();
                                    }
                                });
                            } else {
                                $log.debug('AdminManager::deleteType failed trying to delete', err);
                                messageParams.message = 'Failed to delete ' + type + ' ' + id;
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($rootScope, messageParams);
                                deferred.reject(err);
                            }
                        });
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        this.updateTags = function (type, id, value) {
            var deferred = $q.defer(),
                data = {};

            data[type + 'Id'] = id;
            data.tags = value;

            $http
                .post('/' + type + 's/updateTagsAdmin/', data)
                .success(function (res) {
                    $log.debug('AdminManager::changeRestriction success trying to update tags', res);
                    deferred.resolve(res);
                })
                .error(function (err) {
                    $log.debug('AdminManager::changeRestriction failed trying to update tags', err);
                    deferred.reject(err);
                });

            return deferred.promise;
        };
    },
]);
