'use strict';

angular.module('admin-area').controller('AdminAreaStatusesArtworksController', [
    '$q',
    '$rootScope',
    '$http',
    '$log',
    '$filter',
    '_',
    'User',
    'OpenPopupForm',
    'Messages',
    'ArtworkService',
    'AdminAreaDataService',
    function (
        $q,
        $rootScope,
        $http,
        $log,
        $filter,
        _,
        User,
        OpenPopupForm,
        Messages,
        ArtworkService,
        AdminAreaDataService
    ) {
        var self = this;
        this.AdminAreaDataService = AdminAreaDataService;
        this.OpenPopupForm = OpenPopupForm;
        this.toggles = self.toggles || {};
        this.itemsPerPage = 10;
        this.currentPage = 1;
        this.maxSize = 5;
        this.priorities = [
            { value: '0', view: 'Normal' },
            { value: '1', view: 'Medium' },
            { value: '2', view: 'High' },
        ];

        this.setPage = function (pageNo) {
            self.currentPage = pageNo;
        };

        this.timeFilterAvailable = [
            { key: -1, value: 'All' },
            { key: 7, value: 'Last Week', default: true },
            { key: 14, value: 'Last 2 Weeks' },
            { key: 31, value: 'Last Month' },
        ];

        this.tableFilter = {};
        this.timeFilter = _.find(self.timeFilterAvailable, function (time) {
            return time.default;
        });
        this.availableStatuses = [];

        // Grid
        this.orderByField = 'modified';
        this.reverseSort = true;

        function initTableFilterStatus() {
            self.tableFilterStatus = 'ALL';
        }

        this.statusFilterChanged = function (status) {
            self.tableFilter = function (value, index, array) {
                var isInTime =
                    self.timeFilter.key < 0 ||
                    (Number.isInteger(value.daysFromToday) && value.daysFromToday <= self.timeFilter.key);
                var isInStatus;
                if (status === 'ALL_PENDING') {
                    isInStatus =
                        value.status === 'PENDING' ||
                        value.status === 'PREVIEW_READY' ||
                        value.status === 'PROCESSING_IN_ENGINE_(RERUN)' ||
                        value.status === 'TASKS_CREATED_(RERUN)';
                } else if (status === 'ALL') {
                    isInStatus = true;
                } else {
                    isInStatus = value.status === status;
                }

                return isInStatus && isInTime;
            };
        };

        this.getArtworkStatusAsString = function (artwork) {
            if (artwork.status === 'UPLOADING' && !artwork.uploadId && artwork.uploadId !== 0) {
                return 'Upload In Process';
            }

            if (artwork.status === 'UPLOAD_ABORTED') {
                return 'Upload Canceled';
            }

            if (artwork.status === 'UPLOAD_FAILED') {
                return 'Upload Failed';
            }

            if (artwork.status === 'DAMAGED') {
                return 'File is damaged, please try another file';
            }

            if (artwork.status === 'PENDING') {
                return 'Artwork processing...';
            }

            if (artwork.status === 'FAILED') {
                return 'Artwork processing failed';
            }

            if (artwork.status === 'PREVIEW_READY') {
                return 'Artwork Preview is ready';
            }

            if (artwork.status === 'READY') {
                return 'Artwork is ready';
            }

            return '';
        };

        function calculateDaysFromToday(dateToCalc) {
            var today = new Date();
            var timeInMilisec = new Date(dateToCalc).getTime() - today.getTime();
            return -1 * Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
        }

        function extendArtworkFields(artworks) {
            angular.forEach(artworks, function (artwork) {
                artwork.numericPrice = artwork.price ? parseInt(artwork.price) : 0;
                artwork.durationInSec = artwork.file_metadata.video_duration
                    ? Date.parse('Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC')
                    : null;
                artwork.numericFileSize =
                    artwork.file_metadata.size && artwork.file_metadata.size
                        ? parseInt(artwork.file_metadata.size)
                        : 0;
                artwork.isOwner = artwork.isOwner || artwork.user_id === User.getId();
                artwork.canTransfer = artwork.status === 'READY' && (artwork.isOwner || User.isAdmin());
                artwork.canEdit = artwork.isOwner || User.isAdmin;
                artwork.editLink =
                    self.fromState +
                    '.' +
                    self.currentState +
                    'Upload({ collectionId: ' +
                    self.collectionId +
                    ', artworkId: ' +
                    artwork.id +
                    ' })';
                artwork.canDelete = artwork.isOwner || User.isAdmin;
                artwork.daysFromToday = calculateDaysFromToday(artwork.modified);
            });
        }

        function filterArtworksByTimeFilter(artworks, timeInDays) {
            if (timeInDays.key > -1) {
                var theTime = _.find(self.timeFilterAvailable, function (time) {
                    return time.key === timeInDays.key;
                });

                if (theTime) {
                    artworks = _.filter(artworks, function (artwork) {
                        return (
                            Number.isInteger(artwork.daysFromToday) && artwork.daysFromToday <= timeInDays.key
                        );
                    });
                }
            }

            return artworks;
        }

        this.getAvailableStatuses = function (artworks, timeInDays) {
            if (timeInDays.key > -1) {
                artworks = filterArtworksByTimeFilter(artworks, timeInDays);
            }

            self.availableStatusesTemp = _.uniq(artworks, function (artwork) {
                return artwork.status;
            });
            self.availableStatusesTemp = _.pluck(self.availableStatusesTemp, 'status');
            var tempStatusesJson = [],
                humanStatus = '';
            if (self.availableStatusesTemp.length > 0) {
                self.availableStatusesTemp.forEach(function (status) {
                    //humanStatus = $filter('inflector')(status);
                    tempStatusesJson.push({ key: status, value: status });
                });
            }
            self.availableStatuses = tempStatusesJson;
            _.sortBy(self.availableStatuses, function (status) {
                return status.key;
            });

            var countTotals = { all: 0, all_pending: 0 };

            if (artworks.length > 0) {
                var count = {};
                artworks.forEach(function (artwork) {
                    if (count[artwork.status]) {
                        count[artwork.status] = count[artwork.status] + 1;
                    } else {
                        count[artwork.status] = 1;
                    }
                });

                self.availableStatuses.forEach(function (status) {
                    countTotals.all += count[status.key];
                    if (
                        status.key === 'PENDING' ||
                        status.key === 'PREVIEW_READY' ||
                        status.key === 'PROCESSING_IN_ENGINE_(RERUN)' ||
                        status.key === 'TASKS_CREATED_(RERUN)'
                    ) {
                        countTotals.all_pending += count[status.key];
                    }

                    if (count[status.key]) {
                        status.count = count[status.key];
                    }
                });
            }
            self.availableStatuses.unshift({
                key: 'ALL_PENDING',
                value: 'All Pending Statuses',
                count: countTotals.all_pending,
            });
            self.availableStatuses.unshift({ key: 'ALL', value: 'All Statuses', count: countTotals.all });
        };

        this.refreshFilters = function (timeInDays, status) {
            // var newStatus = status ? status : self.tableFilterStatus;
            // var newTimeInDays = timeInDays ? timeInDays : self.tableFilterStatus;
            self.statusFilterChanged(status);
            self.getAvailableStatuses(self.artworks, timeInDays);
        };

        function attachArtworkToProcessingTasks(artworks, tasks) {
            if (artworks && tasks) {
                if (tasks.length > 0) {
                    tasks.forEach(function (task) {
                        task.artwork = _.find(artworks, function (artwork) {
                            if (artwork.id === task.artwork_id) {
                                artwork.isProcessing = true;
                            }

                            return artwork.id === task.artwork_id;
                        });
                    });
                }
            }

            return tasks;
        }

        this.getArtworksStatus = function () {
            var data = {
                includeRerun: self.includeRerun,
            };

            self.toggles.spinRefresh = true;
            return $http
                .post('/artworks/getArtworksStatus', data)
                .then(function (res) {
                    self.artworks = res.data.data.artworks;
                    self.rerunArtworks = res.data.data.rerunArtworks;
                    if (self.rerunArtworks && self.rerunArtworks.length > 0) {
                        self.rerunArtworks.forEach(function (rerunArtwork) {
                            var foundArtworkIndex = _.findIndex(self.artworks, function (artwork) {
                                return artwork.id === rerunArtwork.id;
                            });
                            if (foundArtworkIndex > -1) {
                                self.artworks.splice(foundArtworkIndex, 1);
                            }
                        });
                        self.artworks = self.artworks.concat(self.rerunArtworks);
                    }
                    self.processingTasks = res.data.data.processingTasks;
                    self.artworksStatusTime = new Date();
                    extendArtworkFields(self.artworks);
                    self.totalItems = self.artworks.length;
                    self.getAvailableStatuses(self.artworks, self.timeFilter);
                    if (self.processingTasks) {
                        self.processingTasks = attachArtworkToProcessingTasks(
                            self.artworks,
                            self.processingTasks
                        );
                    }
                })
                .finally(function () {
                    self.toggles.spinRefresh = false;
                });
        };

        this.getArtworksStatusReport = function () {
            self.toggles.spinReportRefresh = true;

            return self.AdminAreaDataService.getArtworksStatusReport()
                .then(function (data) {
                    self.artworksStatusReportTime = new Date();
                    self.artworksReport = data;
                })
                .finally(function () {
                    self.toggles.spinReportRefresh = false;
                });
        };

        this.init = function () {
            initTableFilterStatus();
            self.refreshFilters(self.timeFilter, self.tableFilterStatus);
            //self.getArtworksStatus();
        };

        this.updateArtworkPriority = function (artworkId, priority) {
            AdminAreaDataService.updateArtworkTasksPriority(artworkId, priority).then(
                function (res) {
                    var messageParams = {};
                    messageParams.message = 'Successfully updated priority';
                    Messages.openMessage($rootScope, messageParams);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'Failed to update priority';
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        this.deleteArtwork = function (artwork, index) {
            var deferred = $q.defer(),
                messageParams = {};

            messageParams.message = 'You are about to permanently Delete this artwork from your account.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete Artwork “' + artwork.title + '”';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    ArtworkService.handleArtworkAction('delete', artwork).then(
                        function (res) {
                            $rootScope.$emit('artwork_deleted', artwork.id);
                            self.artworks.splice(index, 1);
                            var messageParams = {};
                            messageParams.message = 'Successfully deleted the artwork';
                            messageParams.title = 'Delete Artwork';
                            Messages.openMessage($rootScope, messageParams);
                            deferred.resolve();
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'Failed to delete the artwork. ' + error;
                            messageParams.title = 'Delete Artwork';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams).then(function (res) {
                                deferred.reject();
                            });
                        }
                    );
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        this.retryProcess = function (artwork) {
            var messageParams = {};
            messageParams.message = 'Are you sure you want to retry to process this artwork?';
            messageParams.title = 'Retry Artwork Process';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Retry';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        artworkId: artwork.id,
                    };

                    $http.post('/artworks/reProcessArtwork', data).then(
                        function (res) {
                            $log.debug('Successfully started reprocessing artwork', res, data);
                            var messageParams = {};
                            messageParams.message = 'reprocessing artwork';
                            messageParams.title = 'Retry Artwork Process';
                            Messages.openMessage($rootScope, messageParams);
                            ArtworkService.getArtworkStatus(artwork.id).then(function (res) {
                                artwork.status = res.data.status;
                                artwork.modified = new Date.now();
                            });
                        },
                        function (error) {
                            $log.debug('Failed during reprocessing artwork', error, data);
                            var messageParams = {};
                            messageParams.message =
                                'Error reprocessing artwork.\nPlease refresh the page and try again.';
                            messageParams.title = 'Retry Artwork Process Failed';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams).then(function (res) {
                                ArtworkService.handleArtworkAction('updateArtworkStatus', artwork, {
                                    status: 'FAILED',
                                    reason:
                                        'HomeCollectionsMainController::retryProcess Failed during reprocessing artwork' +
                                        JSON.stringify(error),
                                });
                                artwork.modified = new Date.now();
                            });
                        }
                    );
                }
            });
        };
    },
]);
