const { initHost } = require('@o/host-iframe-sdk/dist/src/host-iframe-sdk');
const { debounce } = require('underscore');

angular.module('core').service('HostIframeSdk', [
    '$rootScope',
    '$window',
    '$state',
    '$stateParams',
    'OpenPopupForm',
    'User',
    'TokenService',
    'Messages',
    'NIIO_APP',
    'HomeDataService',
    function (
        $rootScope,
        $window,
        $state,
        $stateParams,
        OpenPopupForm,
        User,
        TokenService,
        Messages,
        NIIO_APP,
        HomeDataService
    ) {
        const afterCompleteHandler = (event) => {
            event.success ? Messages.ok() : Messages.cancel();

            if (event.broadcastEvents) {
                event.broadcastEvents.forEach((event) => {
                    $rootScope.$broadcast(event);
                });
                Messages.modalInstances.forEach((Message) => Message.close());
            }
        };

        this.initHost = initHost;
        this.getHost = () => $window.location.origin;
        this.hostId = `${Math.random()}-${new Date().getTime()}`;
        this.hostSdk = this.initHost({
            hostId: this.hostId,
            host: this.getHost(),
            handlers: {
                uploadFromCloud() {
                    $scope.$broadcast('uploadFromCloud');
                    // UploadsService.uploadFromCloud();
                },
                async getUser() {
                    const authData = {
                        token: TokenService.getToken(),
                        public: TokenService.getOrCreatePublicToken(),
                    };
                    return User.exists()
                        ? {
                              wallets: [
                                  {
                                      address: '0x3d468e8d57f7f3a3443f963dbef35b626257de6f',
                                      alias: 'My wallet ETH',
                                      owner: true,
                                      type: 'wallet',
                                  },
                                  {
                                      address: '0x83b5654564caee5c8e86a314b4e62330db99ce16',
                                      alias: 'My More',
                                      owner: true,
                                      type: 'wallet',
                                  },
                                  {
                                      address: '0x2cc12318de28edc9c753f7cb22100890af630c2d',
                                      alias: 'OpenSea',
                                      owner: false,
                                      type: 'wallet',
                                  },
                                  {
                                      address: '0x626160bea9450bb87f95adffd8c2d8b185f48f8c',
                                      alias: 'MetaKidz Contract',
                                      owner: false,
                                      type: 'contract',
                                  },
                              ],
                              ...User.getUserDetails(),
                              auth: authData,
                              authHeaders: {
                                  'X-MyApiTokenHeader': authData.token,
                                  'X-MyApiTokenHeaderPublic': authData.public,
                              },
                          }
                        : {};
                },
            },
        });

        this.openPortal = (url, params = {}) => {
            const closePortalCleanupFuncs = [];
            const isUppy = url.includes('uppyUploader');
            closePortalCleanupFuncs.push(
                this.hostSdk.addEventListener('adjustSize', (receivedHeight) => {
                    const iframe = document.getElementById('niioSdkPortal');
                    iframe.style.maxHeight = '80vh';
                    iframe.style.height = `${isUppy ? receivedHeight + 120 : receivedHeight}px`;
                })
            );

            closePortalCleanupFuncs.push(
                this.hostSdk.addEventListener('upload-error', (res) => {
                    debounce(
                        OpenPopupForm.openMenu('uppy-error', {
                            message:
                                'Some of the files can not be imported. Do you wish to discard the failed files and proceed?',
                            okText: 'Proceed to Import',
                            okFunction: () =>
                                afterCompleteHandler({
                                    success: true,
                                    broadcastEvents: [
                                        'cloud_uploads_all_finished',
                                        'batch_files_upload_started',
                                    ],
                                }),
                            cancelText: 'Back And Retry',
                        }),
                        2000
                    );
                })
            );

            closePortalCleanupFuncs.push(this.hostSdk.addEventListener('closePortal', afterCompleteHandler));

            closePortalCleanupFuncs.push(this.hostSdk.addEventListener('complete', afterCompleteHandler));

            const queryString = new URLSearchParams();
            queryString.set('hostId', this.hostId);
            queryString.set('hostOrigin', this.getHost());
            queryString.set('token', TokenService.getToken());
            queryString.set('publicToken', TokenService.getOrCreatePublicToken());

            OpenPopupForm.openMenu('niio-sdk', {
                ...params,
                type: 'modal',
                // scope: this,
                url: `${url}${url.includes('?') ? '&' : '?'}${queryString.toString()}`,
            }).then((res) => {
                const moblieAppDoneFunc = window.filestackUploadsFinished;
                if (moblieAppDoneFunc) {
                    moblieAppDoneFunc();
                } else {
                    const appRedirectLink = `${NIIO_APP}uploadDone?from=${$stateParams.from}`;
                    $rootScope.$broadcast('closePortal', {
                        success: res === 'ok',
                        returnLink: appRedirectLink,
                    });
                }

                setTimeout(() => {
                    closePortalCleanupFuncs.map((func) => func());
                }, 1000);
            });
        };
    },
]);
