angular.module('billing').service('BillingService', [
    '$http',
    '$q',
    '$log',
    '$rootScope',
    '$state',
    '$localStorage',
    '$interval',
    '$window',
    'User',
    'ENTITY_ACTIONS',
    '_',
    'Messages',
    function (
        $http,
        $q,
        $log,
        $rootScope,
        $state,
        $localStorage,
        $interval,
        $window,
        User,
        ENTITY_ACTIONS,
        _,
        Messages
    ) {
        var self = this;

        this.plansExpanded = {
            personal: [
                {
                    display_name: 'Standard',
                    uid: '4f9800f6-0cb2-11e8-ac68-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'personal',
                    description:
                        'Watch on one screen at a time, 2 Hi-Res playlists, Full HD quality, Access to artwork collections.',
                    // features: ['1 TB Cloud Storage']
                },
                {
                    display_name: 'Plus',
                    uid: '4f9800fb-0cb2-11e8-ac68-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'personal',
                    description:
                        'Watch on one screen at a time, 2 Hi-Res playlists, Full HD quality, Access to artwork collections.',
                    // features: ['1 TB Cloud Storage']
                },
                {
                    display_name: 'Prime',
                    uid: '4f9800f7-0cb2-11e8-ac68-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'personal',
                    description:
                        'Watch on five screen at a time, 6 Hi-Res playlists, Ultra HD quality (4K), Access to artwork collections & prime catalogs.',
                    // features: ['30 TB Cloud Storage']
                },
                {
                    display_name: 'Free',
                    uid: '4f95307c-0cb2-11e8-ac68-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'personal',
                    actionButton: 'Continue as free user',
                    twoLiner: {
                        line1: 'Watch art collection in preview mode',
                        line2: 'Create playlists and cast to any screen.',
                    },
                },
            ],
            business: [
                {
                    display_name: 'Starter',
                    uid: '4f9800fc-0cb2-11e8-ac68-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'business',
                    description:
                        'Watch on one screen at a time, 2 Hi-Res playlists, Full HD quality, Access to artwork collections.',
                    // features: ['1 TB Cloud Storage']
                },
                {
                    display_name: 'Pro',
                    uid: '4f9800fd-0cb2-11e8-ac68-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'business',
                    description:
                        'Watch on one screen at a time, 2 Hi-Res playlists, Full HD quality, Access to artwork collections.',
                    // features: ['1 TB Cloud Storage']
                },
                {
                    display_name: 'Elite',
                    uid: '4f9800fe-0cb2-11e8-ac68-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'business',
                    description:
                        'Watch on five screen at a time, 6 Hi-Res playlists, Ultra HD quality (4K), Access to artwork collections & prime catalogs.',
                    // features: ['30 TB Cloud Storage']
                },
                {
                    display_name: 'Bespoke',
                    uid: '4f9800fe-0cb2-11e8-0000-55f1bcd80799',
                    upgradable: true,
                    options: {
                        monthly: {},
                        annually: {},
                    },
                    type: 'business',
                    description:
                        'Watch on five screen at a time, 6 Hi-Res playlists, Ultra HD quality (4K), Access to artwork collections & prime catalogs.',
                    // features: ['30 TB Cloud Storage']
                },
            ],
        };

        this.billingTypes = [
            { id: 'monthly', name: 'Billed Monthly' },
            { id: 'annually', name: 'Billed Annually' },
        ];

        this.otpTypes = {
            artwork: 'artwork',
            rent: 'rent',
            submission_fee: 'submission_fee',
        };

        this.actions = ENTITY_ACTIONS;

        this.plans = _.flatten(Object.values(this.plansExpanded));

        function resetCache() {
            self.plansCached = {};
        }

        this.promptCancelSubscription = () => {
            var deferred = $q.defer();
            var messageParams = {};
            messageParams.message = `You are about to cancel your ${User.$storage.userDetails.plan_display_name} subscription.`;
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Cancel Subscription';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Ok';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then((res) => {
                if (res === 'ok') {
                    this.cancelSubscription().then((_) => setTimeout($window.reload, 1000 * 10));
                } else {
                    deferred.reject();
                }
            });
        };

        function init() {
            resetCache();
            self.artworkId = undefined;
            self.channelId = undefined;
            self.artwork = undefined;
            self.eventId = undefined;
            self.event = undefined;
            self.publishUniqueSeq = undefined;
            self.password = undefined;
            self.actionParameters = undefined;
            self.actionId = undefined;
            self.isArtworkTransfer = undefined;
            self.assetsBaseUrl = '/modules/billing/img/';
        }

        $rootScope.$on('$stateChangeStart', (event, to, toParams, from, fromParams) => {
            from.params = fromParams;
            $state.previous = from;
        });

        $rootScope.$on('ClearData', init);
        $rootScope.$on('InitServices', init);
        $rootScope.$on('UserUpgraded', resetCache);
        init();

        this.isAllowedToView = function (artworkId) {
            return $http.get(`/artworks/displayPurchaseStatusPage/${artworkId}`);
        };

        this.canUserUpgradePlanByProvider = function () {
            var data = {
                providerName: 'charge_bee',
            };

            return $http.post('/userPlans/canUserUpgradePlanByProvider', data);
        };

        this.validateCoupon = function (data) {
            return $http.post('/billing/validateCoupon', data);
        };

        this.submitBillingInfoAndSubscribe = function (data) {
            return $http.post('/billing/submitBillingInfoAndSubscribe', data);
        };

        this.cancelSubscription = function () {
            return $http.post('/billing/cancelSubscription', {}, {});
        };

        this.submitPayForItem = function (data) {
            return $http.post('/billing/payForItem', data);
        };

        this.clearCachedPlans = function () {
            self.plansCached = {};
        };

        this.freePlanId = '4f95307c-0cb2-11e8-ac68-55f1bcd80799';
        this.getFreePlan = async () => {
            return getPlanById(this.freePlanId);
        };
        this.isFreePlan = (plan) => {
            return this.freePlanId === (plan?.id || plan?.uid || plan);
        };

        /**
         * Async function to get plan by id, it's used `getSubscriptionPlans` function with cached mechanism
         * It's get the plans from server and combine the item prices into the plan object.
         * Example use: const plan = await BillingService.getPlanById('Provider-plan-name');
         * @param id - plan provider id
         * @param providerName default: charge_bee
         * @returns {Promise<Object>}
         */
        this.getPlanById = async function (id, providerName = 'charge_bee') {
            const dbPlans = await this.getSubscriptionPlans(providerName);
            let currentPlan = null;
            _.find(dbPlans, (plan) => {
                if (plan.options.monthly.provider_plan_id === id) {
                    currentPlan = {
                        isAnnually: false,
                        ...plan,
                        ...plan.options.monthly,
                    };
                } else if (plan.options.annually.provider_plan_id === id) {
                    currentPlan = {
                        isAnnually: true,
                        ...plan,
                        ...plan.options.annually,
                    };
                }
                return currentPlan;
            });
            return currentPlan;
        };

        this.getSubscriptionPlans = function (providerName) {
            var deferred = $q.defer();
            providerName = providerName || 'charge_bee';

            if (self.plansCached[providerName]) {
                deferred.resolve(self.plansCached[providerName]);
                return deferred.promise;
            }

            var data = {
                providerName,
            };

            $http.post('/billing/getPlans', data).then(
                (res) => {
                    //   res = [];
                    self.plans = self.syncPlans(res.data?.plans);
                    self.plansCached[providerName] = self.plans;
                    deferred.resolve(self.plansCached[providerName]);
                },
                (error) => {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };
        this.syncPlans = function (dbPlans) {
            var foundPlan = false;
            var parameters;
            var stringData;

            const localPlansFlaten = self.plans;
            dbPlans.forEach((dbPlan) => {
                foundPlan = _.find(localPlansFlaten, (plan) => dbPlan.uid === plan.uid);

                if (foundPlan) {
                    if (dbPlan.payment_provider_user_plans) {
                        var annuallyPriceItem = dbPlan.payment_provider_user_plans.find((item) => {
                            return item.payment_cycle === 'annually';
                        });
                        var monthlyPriceItem = dbPlan.payment_provider_user_plans.find((item) => {
                            return item.payment_cycle === 'monthly';
                        });

                        foundPlan.options.monthly = {
                            price: monthlyPriceItem.price / 100,
                            currency: monthlyPriceItem.currency,
                            id: monthlyPriceItem.id,
                            ...monthlyPriceItem,
                        };
                        foundPlan.options.annually = {
                            price: annuallyPriceItem.price / 100,
                            currency: annuallyPriceItem.currency,
                            id: annuallyPriceItem.id,
                            ...annuallyPriceItem,
                        };
                    }

                    foundPlan.display_name = dbPlan.display_name || foundPlan.options.monthly.provider_plan_name;
                    foundPlan.showMore = false;
                    foundPlan.features = [];
                    if (!isNaN(foundPlan.options.monthly.price) && !isNaN(foundPlan.options.annually.price)) {
                        var annuallyMonthPrice = foundPlan.options.annually.price / 12;
                        var monthlyMonthPrice = foundPlan.options.monthly.price;
                        var savePercent = (
                            100 -
                            ((monthlyMonthPrice - (monthlyMonthPrice - annuallyMonthPrice)) * 100) / monthlyMonthPrice
                        ).toFixed(0);
                        foundPlan.options.annually.savePercent = savePercent;
                        foundPlan.options.monthly.savePercent = savePercent;
                    }

                    if (dbPlan.parameters && dbPlan.plan_strings_json) {
                        parameters = JSON.parse(dbPlan.parameters);
                        stringData = JSON.parse(dbPlan.plan_strings_json);
                        foundPlan.assets = stringData.assets;
                        foundPlan.twoLiner = stringData.twoLiner;
                        foundPlan.actionButton = stringData.actionButton;
                        foundPlan.link = parameters?.bespokeLink;

                        if (self.currentPlan && foundPlan.uid === self.currentPlan && !User.isFreeUser()) {
                            foundPlan.actionButton = 'YOUR PLAN';
                        } else if (
                            self.currentPlan &&
                            User.$storage.userDetails.plan_display_name.toLowerCase() !== 'free'
                        ) {
                            foundPlan.actionButton = 'Change plan';
                        } else if (User.getUserUsedTrial() < 0) {
                            foundPlan.actionButton = 'Get plan';
                        }

                        if (parameters) {
                            stringData.features.forEach((featureItem) => {
                                if (parameters.storage.quota) {
                                    featureItem.text = featureItem.text.replace(
                                        '[storage]',
                                        parameters.storage.quota / 100000
                                    );
                                    if (foundPlan.twoLiner.line1) {
                                        foundPlan.twoLiner.line1 = foundPlan.twoLiner.line1.replace(
                                            '[storage]',
                                            parameters.storage.quota / 100000
                                        );
                                    }
                                    if (foundPlan.twoLiner.line2) {
                                        foundPlan.twoLiner.line2 = foundPlan.twoLiner.line2.replace(
                                            '[storage]',
                                            parameters.storage.quota / 100000
                                        );
                                    }
                                }
                                if (parameters.artcasts.public_quota) {
                                    featureItem.text = featureItem.text.replace(
                                        '[artcasts]',
                                        parameters.artcasts.public_quota
                                    );
                                    if (foundPlan.twoLiner.line1) {
                                        foundPlan.twoLiner.line1 = foundPlan.twoLiner.line1.replace(
                                            '[artcasts]',
                                            parameters.artcasts.public_quota
                                        );
                                    }
                                    if (foundPlan.twoLiner.line2) {
                                        foundPlan.twoLiner.line2 = foundPlan.twoLiner.line2.replace(
                                            '[artcasts]',
                                            parameters.artcasts.public_quota
                                        );
                                    }
                                }
                                if (parameters.screens.quota) {
                                    featureItem.text = featureItem.text.replace(
                                        '[screens]',
                                        ` screen${parameters.screens.quota > 1 ? 's' : ''}`
                                    );
                                    if (foundPlan.twoLiner.line1) {
                                        foundPlan.twoLiner.line1 = foundPlan.twoLiner.line1.replace(
                                            '[screens]',
                                            `${parameters.screens.quota} screen${
                                                parameters.screens.quota > 1 ? 's' : ''
                                            }`
                                        );
                                    }
                                    if (foundPlan.twoLiner.line2) {
                                        foundPlan.twoLiner.line2 = foundPlan.twoLiner.line2.replace(
                                            '[screens]',
                                            `${parameters.screens.quota} screen${
                                                parameters.screens.quota > 1 ? 's' : ''
                                            }`
                                        );
                                    }
                                }
                                foundPlan.features.push(featureItem);
                            });
                        }
                        foundPlan.assets.sort((a, b) => {
                            return a.order - b.order;
                        });
                        foundPlan.features.sort((a, b) => {
                            return a.order - b.order;
                        });
                    }

                    foundPlan.upgradable = foundPlan.display_name === 'Bespoke' ? true : dbPlan.upgradable;
                    foundPlan.upgradablePlusIconName = `${self.assetsBaseUrl}plus.svg`;
                    foundPlan.upgradableMinimizeIconName = `${self.assetsBaseUrl}minimize.svg`;
                    foundPlan.planExpireDaysCount = dbPlan.trial_days;
                }
            });

            return localPlansFlaten;
        };

        this.getPaymentSources = function () {
            var deferred = $q.defer();

            $http.post('/billing/getUserPaymentSources').then(
                (res) => {
                    deferred.resolve(res.data.data);
                },
                (error) => {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.enablePollingAfterPayment = function () {
            if (!$localStorage.pushy || !$localStorage.pushy.remote_id || !$localStorage.pushy.notificationIsGranted) {
                self.upgradePolling = $interval(() => {
                    if ($rootScope.pendingPayment) {
                        User.checkUserUpgraded(true, true).then((res) => {
                            if (res.plan) {
                                self.upgradePolling.cancel();
                            }
                        });
                    }
                }, 5000);
            }
        };

        this.getTransactionsHistory = function (userId = null) {
            var deferred = $q.defer();

            $http
                .post('/credits/history/100', {
                    userId,
                })
                .then(
                    (res) => {
                        deferred.resolve(res.data.data);
                    },
                    (error) => {
                        deferred.reject(error);
                    }
                );

            return deferred.promise;
        };
    },
]);
