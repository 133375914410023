//English
translationsEN.DISCOVER = {};

translationsEN.DISCOVER.BASIC = 'Basic info';
translationsEN.DISCOVER.ADVANCED = 'Advanced settings';
translationsEN.DISCOVER.MAIN = 'Main';
translationsEN.DISCOVER.CHANNELS = 'Artcasts';
translationsEN.DISCOVER.CATALOGS = 'Catalogs';
translationsEN.DISCOVER.ARTWORKS = 'Artworks';
translationsEN.DISCOVER.ARTISTS = 'Artists';
translationsEN.DISCOVER.MEMBERS = 'Members';
translationsEN.DISCOVER.FOR_YOU = 'For You';

//HebrewDISCOVER
translationsHE.DISCOVER = {};

translationsHE.DISCOVER.STREAMS = 'שותף';
translationsHE.DISCOVER.MY_ARTWORKS = 'האומנות שלי';
translationsHE.DISCOVER.CONNECTIONS = 'קשרים';
translationsHE.DISCOVER.DEVICES = 'מכשירים';
