'use strict';

// Setting up route
angular.module('player').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        // Player state routing
        $stateProvider.state('player', {
            url: '/player',
            //                abstract: true,
            templateUrl: 'modules/player/views/player.client.view.html',
            controller: 'PlayerController',
            data: {
                security: {
                    secured: true,
                },
            },
        });
    },
]);
