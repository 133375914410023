'use strict';

angular.module('devices').controller('DevicesSelectedDisplayArrayController', [
    '$scope',
    '$log',
    '$q',
    'Messages',
    'DevicesDataService',
    'FormValidationService',
    '_',
    function ($scope, $log, $q, Messages, DevicesDataService, FormValidationService, _) {
        $scope.formValidator = FormValidationService;
        $scope.units = ['mm', 'inches'];

        $scope.init = function () {
            $scope.submitWasClicked = false;
            $scope.$emit('selected_chosen', $scope.selectedId, $scope.deviceOrGroup);
        };

        function associateBlock(deviceId, block) {
            if (deviceId) {
                $scope.removeDeviceFromAvailableDevices(deviceId);
                block.device_id = deviceId;
                block.device = $scope.findDeviceById(deviceId);
            }
        }

        $scope.$watch(
            'selectedBlock',
            function (newVal, oldVal) {
                if (oldVal && newVal && oldVal.col === newVal.col && oldVal.row === newVal.row) {
                    // Removed device id from selected block
                    if (oldVal.device_id) {
                        $scope.addDeviceToAvailableDevices(oldVal.device_id);
                    }

                    // Added device id to block
                    if (newVal.device_id) {
                        associateBlock(newVal.device_id, newVal);
                    }
                } else if (newVal && newVal.device_id) {
                    // Attach device entity if loaded page
                    newVal.device = $scope.findDeviceById(newVal.device_id);
                }
            },
            true
        );

        $scope.isBlockSelected = function (row, col) {
            return (
                $scope.selectedBlock && $scope.selectedBlock.row === row && $scope.selectedBlock.col === col
            );
        };

        $scope.isBlockAssociated = function (row, col) {
            return $scope.displayArray.devices && $scope.displayArray.devices[row][col].device_id;
        };

        $scope.updateDisplaySettings = function () {
            $scope.submitWasClicked = true;

            if (!$scope.displayArray.devices[0][0].device_id) {
                var messageParams = {};
                messageParams.message =
                    'The left most device must be associated. Display array settings were not saved.';
                messageParams.disableAutoDismiss = true;
                messageParams.title = 'Display Array Update - Failure';
                Messages.openMessage($scope, messageParams);
                $scope.submitWasClicked = false;
            } else {
                var displayArrayCopy = angular.copy($scope.displayArray);
                var flatDevices = [];
                var i, j;

                for (i = 0; i < displayArrayCopy.devices.length; i++) {
                    for (j = 0; j < displayArrayCopy.devices[i].length; j++) {
                        if (displayArrayCopy.devices[i][j].device_id) {
                            displayArrayCopy.devices[i][j].device = undefined;
                            flatDevices.push($scope.displayArray.devices[i][j]);
                        }
                    }
                }

                displayArrayCopy.devices = flatDevices;

                DevicesDataService.updateGroupDisplayArray($scope.selected.id, displayArrayCopy).then(
                    function (res) {
                        var messageParams = {};
                        messageParams.message = 'Display array settings were updated successfully';
                        if (
                            $scope.displayArray.is_enabled &&
                            $scope.displayArray.num_of_cols * $scope.displayArray.num_of_rows >
                                flatDevices.length
                        ) {
                            messageParams.message +=
                                '\n* Please note that the grid is not complete and will not behave properly in case of videowall';
                        }
                        messageParams.title = 'Display Array Update';
                        messageParams.disableAutoDismiss = true;
                        messageParams.size = 'lg';
                        Messages.openMessage($scope, messageParams);
                        $scope.submitWasClicked = false;
                    },
                    function (error) {
                        var messageParams = {};
                        messageParams.message =
                            'Failed to update display array settings. Please refresh the page and try again.';
                        messageParams.disableAutoDismiss = true;
                        messageParams.title = 'Display Array Failed';
                        messageParams.size = 'lg';
                        Messages.openMessage($scope, messageParams);
                        $scope.submitWasClicked = false;
                    }
                );
            }
        };

        $scope.getItems = function (times) {
            return new Array(times);
        };

        function autoAssignDevicesPopup() {
            var deferred = $q.defer();

            // Are there devices assigned to a block already?
            if ($scope.availableDevices.length !== $scope.selected.devices.length) {
                var messageParams = {};
                messageParams.message =
                    'Some devices are already assigned in the grid. Auto-assigning devices will override current arrangement.\nAre you sure you want to continue?';
                messageParams.title = 'Auto-Assign Devices';
                messageParams.size = 'lg';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Yes';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    deferred.resolve(res);
                });
            } else {
                deferred.resolve('ok');
            }

            return deferred.promise;
        }

        $scope.autoAssignDevices = function () {
            autoAssignDevicesPopup().then(function (res) {
                if (res === 'ok') {
                    $scope.updateAvailableDevices();
                    $scope.selectBlock(null, null, true);
                    var availableDevices = angular.copy($scope.availableDevices).reverse();
                    var i, j;
                    var nextDevice;

                    for (i = 0; i < $scope.displayArray.devices.length; i++) {
                        for (j = 0; j < $scope.displayArray.devices[i].length; j++) {
                            $scope.displayArray.devices[i][j].device_id = undefined;
                            $scope.displayArray.devices[i][j].device = undefined;
                            if (availableDevices.length > 0) {
                                nextDevice = availableDevices.pop();
                                associateBlock(nextDevice.id, $scope.displayArray.devices[i][j]);
                            }
                        }
                    }
                }
            });
        };

        $scope.changeBlockSizeIfBig = function (rows, cols) {
            if (cols > 8 && cols <= 16) {
                return {
                    width: '42px',
                    height: '30px',
                };
            } else if (cols > 16) {
                return {
                    width: '34px',
                    height: '25px',
                };
            }
        };

        $scope.changeFontSizeIfBig = function (cols) {
            if (cols > 16) {
                return {
                    'font-size': '7px',
                };
            }
        };

        $scope.changeRowSizeIfBig = function (cols) {
            var style = {};
            if (cols <= 8) {
                style = { height: '90px' };
            } else if (cols <= 16) {
                style = { height: '30px' };
            } else if (cols > 16) {
                style = { height: '25px' };
            }

            return style;
        };
    },
]);
