'use strict';

angular.module('artwork-info').controller('ArtworkInfoArtworkEventJudgeCommentsController', [
    'Messages',
    'ArtworkService',
    function (Messages, ArtworkService) {
        var self = this;
        this.Messages = Messages;

        this.setSubmissionComments = function () {
            var valueToSend = self.comments;
            self.artwork.artwork_submission.judge_comments.comments = self.comments;
            ArtworkService.handleArtworkAction('eventSubmissionComments', self.artwork, {
                eventId: self.eventId,
            })
                .then(function (res) {
                    self.artwork.artwork_submission.judge_comments.comments = res.new_value;
                })
                .finally(function () {
                    Messages.ok();
                });
        };

        this.init = function () {
            self.artwork = self.params.artwork;
            self.eventId = self.params.eventId;
            self.judge = self.params.judge;
            self.comments = angular.copy(self.artwork.artwork_submission.judge_comments.comments);
        };
    },
]);
