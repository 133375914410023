angular.module('core').controller('NotificationsController', [
    '$scope',
    '$rootScope',
    '$timeout',
    'Notifications',
    function ($scope, $rootScope, $timeout, Notifications) {
        $scope.notifications = Notifications.notifications;

        $scope.safeUpdate = () => {
            if (!$scope.$$phase) {
                return $scope.$apply();
            }

            return $timeout($scope.safeUpdate);
        };

        $scope.remove = (id) => {
            Notifications.remove(id);
        };

        $scope.addNewNotification = (notification = { duration: 3000 }) => {
            Notifications.addNewNotification(notification);
        };

        $rootScope.$on('safeUpdate', (e, notification) => {
            $scope.notifications = Notifications.notifications;
            $scope.safeUpdate();
        });
    },
]);
