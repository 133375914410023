'use strict';
/* global jsPDF */

angular.module('home').controller('HomeEventsSubmissionInfoController', [
    '$rootScope',
    '$q',
    '$state',
    '$scope',
    '$stateParams',
    'Security',
    'Messages',
    'HomeDataService',
    '_',
    function ($rootScope, $q, $state, $scope, $stateParams, Security, Messages, HomeDataService, _) {
        var self = this;
        this.roles = [
            'Artist',
            'Gallery',
            'Collector',
            'Curator',
            'Organization',
            'Art Institution',
            'Art Fan',
            'Potential Partner',
        ];
        this.Messages = Messages;
        this.title = '';
        this.emptyQuestions = false;

        this.current = {
            eventId: undefined,
            userId: undefined,
            submission: undefined,
            qNaData: undefined,
            clear: function () {
                this.eventId = undefined;
                this.submission = undefined;
                this.qNaData = undefined;
            },
        };

        function loadSubmissionData(eventData, submitterId) {
            eventData.submissions.forEach(function (submission) {
                if (submission.user.id === submitterId) {
                    self.current.submission = submission;
                    return false;
                }
            });

            $scope.User = self.current.submission.user;

            var customFormString = eventData.event.custom_form_structure;
            $scope.eventQuestions = JSON.parse(customFormString);
        }

        this.init = function () {
            self.current.clear();
            $scope.User = undefined;
            self.current.eventId = self.params && self.params.eventId ? self.params.eventId : undefined;
            self.current.userId = self.params && self.params.userId ? self.params.userId : undefined;

            var dfEventData = $q.defer();
            if ($scope.currentEvent !== undefined) {
                dfEventData.resolve($scope.currentEvent);
            } else if (self.params && self.params.currentEvent) {
                dfEventData.resolve(self.params.currentEvent);
            } else {
                HomeDataService.getEventHostData(self.current.eventId, self.current.userId).then(function (
                    eventData
                ) {
                    dfEventData.resolve(eventData);
                });
            }

            //dfEventData.promise.then(function(eventData){
            //    loadSubmissionData(eventData, self.current.userId);
            //
            //});

            var dfAnswers = $q.defer();
            if (self.current.qNaData === undefined) {
                HomeDataService.getSubmissionFormQuestions(self.current.eventId, self.current.userId).then(
                    function (answers) {
                        dfAnswers.resolve(answers.data);
                    }
                );
            } else {
                dfAnswers.resolve(self.current.qNaData);
            }

            dfAnswers.promise.then(
                function () {
                    //do nothing.
                },
                function (err) {
                    self.current.qNaData = [];
                }
            );

            $q.all([dfEventData.promise, dfAnswers.promise]).then(function (results) {
                var eventData = results[0];
                loadSubmissionData(eventData, self.current.userId);

                var eventAnswers = results[1];
                if (eventAnswers && eventAnswers[0]) {
                    HomeDataService.matchQuestionsToForm($scope.eventQuestions, eventAnswers);
                    self.current.qNaData = $scope.eventQuestions;
                } else {
                    self.emptyQuestions = true;
                }
            });
        };

        /*jshint -W055 */
        var specialElementHandlers = {
            '#printing': function (element, renderer) {
                return true;
            },
            '#printingMain': function (element, renderer) {
                return true;
            },
            // '#nameOfUser': function(element, renderer){
            //     renderer.pdf.addHTML(self.current.submission.user.display_name + ' ');
            //     return true;
            // },
            '.controls': function (element, renderer) {
                return true;
            },
        };

        this.print = function () {
            var doc = new jsPDF();
            var form = document.getElementById('submissionInfo');
            doc.fromHTML(form, 15, 15, {
                width: 170,
                elementHandlers: specialElementHandlers,
            });

            // Save the PDF
            doc.save(self.current.submission.user.display_name + ' Application Form.pdf');
        };
    },
]);
