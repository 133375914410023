'use strict';

angular.module('artwork-info').controller('ArtworkInfoArtworkPasswordController', [
    'ArtworkInfoService',
    '$rootScope',
    '$scope',
    'Messages',
    function (ArtworkInfoService, $rootScope, $scope, Messages) {
        var self = this;
        this.ArtworkInfoService = ArtworkInfoService;
        this.Messages = Messages;

        this.init = function () {
            $rootScope.blurContent = true;
            ArtworkInfoService.artworkSharedPassword = '';
        };

        $scope.$on('$destroy', function () {
            $rootScope.blurContent = false;
        });
    },
]);
