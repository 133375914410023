'use strict';

angular.module('admin-area').controller('AdminAreaUserPlansMappingController', [
    '$rootScope',
    '$scope',
    '$filter',
    '$state',
    '$log',
    'Messages',
    'UserPlansDataService',
    'BillingService',
    'Search',
    function ($rootScope, $scope, $filter, $state, $log, Messages, UserPlansDataService, BillingService, Search) {
        $scope.submitWasClicked = false;
        $scope.accountStatusTouched = false;

        var self = this;
        this.fromState = 'admin-area';
        this.UserPlansDataService = $scope.UserPlansDataService = UserPlansDataService;
        this.BillingService = $scope.BillingService = BillingService;
        this.Search = Search;

        this.tmpUser = {
            name: '',
            id: undefined,
            currentPlan: undefined,
            selectedUserPlan: undefined,
            accountStatus: undefined,
            newAccountStatus: undefined,
            clear: function () {
                this.id = undefined;
                this.name = '';
                this.currentPlan = undefined;
                this.selectedUserPlan = undefined;
                this.accountStatus = undefined;
                this.newAccountStatus = undefined;
                this.notify();
            },
            notify: function () {
                $scope.$broadcast('mapping:user:changed', this);
            },
        };

        this.allPlans = [];
        self.UserPlansDataService.getAllPlans().then(
            function (data) {
                self.allPlans = data;
            },
            function (err) {
                $log.error('Fail to init plan list', err);
            }
        );

        $scope.onAccountStatusTouched = function () {
            $scope.accountStatusTouched = self.tmpUser.newAccountStatus !== self.tmpUser.accountStatus;
        };

        //$scope.$on('mapping:user:changed', function(){
        //
        //});

        this.specificUserSelected = function (item, model, label, tmpUserObject) {
            if (item.basicType === 'user') {
                tmpUserObject.id = item.id;
                tmpUserObject.url = item.url;
                tmpUserObject.credits_balance = item.credits_balance;
                tmpUserObject.currentPlan = undefined;
                tmpUserObject.selectedUserPlan = undefined;
                tmpUserObject.accountStatus = undefined;
                tmpUserObject.newAccountStatus = undefined;
                tmpUserObject.notify();
            } else {
                tmpUserObject.clear();
            }
        };

        this.openParameters = function (parameters_json) {
            var messageParams = {};
            messageParams.message = '<pre>' + $filter('json')(parameters_json) + '</pre>';
            messageParams.title = 'Parameters Json';
            messageParams.disableAutoDismiss = true;
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams);
        };

        this.getUserLatestReceipt = function () {
            if (self.tmpUser.id === undefined || self.tmpUser.url === undefined) {
                $log.error('parameter is empty');

                return;
            }

            self.UserPlansDataService.checkLatestReceipt(self.tmpUser.id).then(
                function (data) {
                    self.openParameters(data);
                },
                function (err) {
                    console.log('failed to get latest receipt data for user id: ' + self.tmpUser.id, err);
                    var messageParams = {};
                    messageParams.message = 'failed to get latest receipt data for user id: ' + self.tmpUser.id;
                    Messages.openMessage($scope, messageParams);
                }
            );
        };

        this.getUserData = function () {
            $scope.accountStatusTouched = false;
            if (self.tmpUser.id === undefined || self.tmpUser.url === undefined) {
                $log.error('parameter is empty');

                return;
            }

            self.UserPlansDataService.getUserPlanStatusByUserUrl(self.tmpUser.url).then(
                function (data) {
                    if (data.id === self.tmpUser.id) {
                        var accountStatus_ = data.status;
                        if (data.plan !== undefined) {
                            self.UserPlansDataService.getUserPlanData(data.plan).then(
                                function (planData) {
                                    self.tmpUser.currentPlan = planData;
                                    self.tmpUser.accountStatus = accountStatus_;
                                    self.tmpUser.newAccountStatus = accountStatus_;
                                },
                                function (err) {
                                    $log.error('invalid plan id:' + data.plan);
                                    self.tmpUser.clear();
                                }
                            );
                        } else {
                            $log.error('fail to get user plan');
                            self.tmpUser.clear();
                        }
                    }
                },
                function (err) {
                    console.log('fail to get data for user id: ' + self.tmpUser.id, err);
                    self.tmpUser.clear();
                }
            );

            self.UserPlansDataService.getSubscriptionPlansHistory(self.tmpUser.id).then(
                function (data) {
                    self.tmpUser.UserSubscriptionPlan = data.UserSubscriptionPlan;
                    self.tmpUser.PaymentProviderWebhook = data.PaymentProviderWebhook;
                },
                function (err) {
                    console.log('fail to get data for user id: ' + self.tmpUser.id, err);
                    self.tmpUser.clear();
                }
            );

            self.BillingService.getTransactionsHistory(self.tmpUser.id).then((data) => {
                self.tmpUser.UserTransactions = data;
            });
        };

        this.getEntityUrl = (type, id) => {
            return $state.href('discover.' + type, { [type + 'Id']: id }) || '#';
        };

        this.setUserPlan = function () {
            if (self.tmpUser.id === undefined || self.tmpUser.selectedUserPlan === undefined) {
                $log.error('invalid arguments');
                return;
            }

            var newPlan = JSON.parse(self.tmpUser.selectedUserPlan);

            var messageParams = {};
            messageParams.message =
                'You are about to set User-Plan <br/>' +
                newPlan.name +
                '<br/> to user ' +
                self.tmpUser.url +
                ' (id: ' +
                self.tmpUser.id +
                ')';
            messageParams.title = 'Map User-Plan';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Change';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.UserPlansDataService.setPlanToUser(self.tmpUser.id, newPlan.uid).then(
                        function (res) {
                            var messageParams = {};
                            messageParams.message = 'Operation succeed.';
                            $scope.submitWasClicked = false;
                            Messages.openMessage($scope, messageParams);
                            self.getUserData(); //refresh.
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'Operation failed.\nInfo:' + error;
                            messageParams.title = 'Failed To map User-Plan';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        this.saveAccountStatus = function () {
            if (
                self.tmpUser.id === undefined ||
                self.tmpUser.newAccountStatus === undefined ||
                self.tmpUser.newAccountStatus < 0 ||
                self.tmpUser.newAccountStatus > 2
            ) {
                $log.error('invalid arguments');
                return;
            }

            var newStatus = self.tmpUser.newAccountStatus;
            var newStatusName = self.tmpUser.newAccountStatus;

            var messageParams = {};
            messageParams.message =
                'You are about to change Account Status to: <br/>' +
                self.UserPlansDataService.mapStatusName(newStatusName) +
                '<br/> User ' +
                self.tmpUser.url +
                ' (id: ' +
                self.tmpUser.id +
                ')';
            messageParams.title = 'Change account status';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Change';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.UserPlansDataService.setAccountStatus(self.tmpUser.id, newStatus).then(
                        function (res) {
                            var messageParams = {};
                            messageParams.message = 'Operation succeed.';
                            $scope.submitWasClicked = false;
                            Messages.openMessage($scope, messageParams);
                            self.getUserData(); //refresh.
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'Operation failed.\nInfo:' + error;
                            messageParams.title = 'Failed To change Account status';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        this.resetAccountStatus = function () {
            self.tmpUser.newAccountStatus = self.tmpUser.accountStatus;
            $scope.accountStatusTouched = false;
        };

        this.init = function () {
            self.tmpUser.clear();
        };
    },
]);
