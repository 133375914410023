'use strict';

angular.module('admin-area').controller('AdminAreaInvitationsController', [
    '$scope',
    '$http',
    '$log',
    '$q',
    '$filter',
    'Messages',
    'OpenPopupForm',
    '_',
    function ($scope, $http, $log, $q, $filter, Messages, OpenPopupForm, _) {
        var today = new Date();
        $scope.OpenPopupForm = OpenPopupForm;

        $scope.availableExpirationDays = [
            { key: '14', name: '2 Weeks' },
            { key: '28', name: '1 Month' },
            { key: '56', name: '2 Months' },
            { key: '91', name: '3 Months' },
            { key: '182', name: '6 Months' },
            { key: '365', name: '1 Year' },
            { key: '3650', name: 'Not Expire Soon (10 years)' },
        ];

        $scope.availableTypes = [
            { id: '1', name: 'Regular' },
            { id: '2', name: 'Submission' },
        ];

        function initNewCode() {
            $scope.newCode = {
                expiration_days: '14',
                custom_code: '',
                allowed_amount: 1,
                used_amount: 0,
                type: '1',
            };
        }

        $scope.joinCodesFilter = 'manual';
        $scope.onJoinCodesFilterChanged = function () {
            $scope.codes = [];
            getCodes();
        };

        function calculateExpiration(code) {
            code.created = Date.parse(code.created);
            code.created = new Date(code.created);
            var createdCopy = angular.copy(code.created);
            code.expiration = createdCopy.setDate(createdCopy.getDate() + parseInt(code.expiration_days, 10));
            code.expired = code.expiration < today.getTime();
        }

        function getCodes() {
            $http.get('/invitations/getJoinCodes/' + $scope.joinCodesFilter).then(
                function (res) {
                    $log.debug(
                        'AdminAreaInvitationsController::getCodes Success retrieving codes invitations/getJoinCodes',
                        res
                    );
                    $scope.codes = res.data.data || [];
                    var created;
                    if ($scope.codes && $scope.codes.length > 0) {
                        $scope.codes.forEach(function (code) {
                            code.expiration_days = code.expiration_days || '14';
                            code.allowed_amount = code.allowed_amount ? parseInt(code.allowed_amount, 10) : 1;
                            code.used_amount = code.used_amount ? parseInt(code.used_amount, 10) : 0;
                            calculateExpiration(code);
                        });
                    }
                },
                function (error) {
                    $log.debug(
                        'AdminAreaInvitationsController::getCodes Error retrieving codes invitations/getJoinCodes',
                        error
                    );
                }
            );
        }

        $scope.init = function () {
            $scope.submitWasClicked = false;
            $scope.submittingInvitationRequests = true;
            $scope.invitationRequests = {};
            $scope.invitationRequests.periodDays = '';
            $scope.formattedDoday = $filter('date')(new Date(), 'dd/MM/yyyy');
            getCodes();
            initNewCode();
            $scope.orderByField = 'created';
            $scope.reverseSort = true;
        };

        function validateAllowedAmount(allowedAmount) {
            return allowedAmount < -1 || allowedAmount === 0 ? 1 : allowedAmount;
        }

        function readCsvFile(file) {
            var deferred = $q.defer();

            if (file) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    var string = reader.result;
                    deferred.resolve(string);
                };
                reader.readAsText(file);
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        }

        $scope.generateNewCode = function () {
            var csvFile = $scope.newCode.csv ? $scope.newCode.csv[0] : '';

            $scope.submitWasClicked = true;

            readCsvFile(csvFile).then(function (res) {
                var data = {
                    destination_email: $scope.newCode.destination_email,
                    type: $scope.newCode.type,
                    expiration_days: $scope.newCode.expiration_days,
                    custom_code: $scope.newCode.custom_code,
                    allowed_amount: $scope.newCode.allowed_amount,
                };

                if (csvFile) {
                    data.pre_approved_users = res;
                } else {
                    data.pre_approved_users = $scope.newCode.pre_approved_users;
                }

                $http.post('/invitations/getNewJoinCode', data).then(
                    function (res) {
                        $log.debug(
                            'AdminAreaInvitationsController::generateNewCode Success in invitations/getNewJoinCode',
                            res
                        );
                        $scope.newCode.allowed_amount = validateAllowedAmount($scope.newCode.allowed_amount);
                        var newCode = {
                            code: res.data.data.code,
                            id: res.data.data.id,
                            destination_email: $scope.newCode.destination_email,
                            type: $scope.newCode.type,
                            allowed_amount: $scope.newCode.allowed_amount,
                            pre_approved_users: $scope.newCode.pre_approved_users,
                            used_amount: $scope.newCode.used_amount,
                            expiration_days: $scope.newCode.expiration_days,
                            created: new Date(),
                            expired: false,
                            expiration: '',
                        };
                        calculateExpiration(newCode);
                        $scope.codes.push(newCode);
                        initNewCode();
                        $scope.submitWasClicked = false;
                        var messageParams = {};
                        messageParams.message = 'Code was generated: ' + newCode.code;
                        messageParams.title = 'Successfully Generated Code';
                        Messages.openMessage($scope, messageParams);
                    },
                    function (error) {
                        $log.debug(
                            'AdminAreaInvitationsController::generateNewCode Error in invitations/getNewJoinCode',
                            error
                        );
                        $scope.submitWasClicked = false;
                        var messageParams = {};
                        messageParams.message =
                            error.data && error.data.message
                                ? error.data.message.text
                                : 'Generation was failed, please try again.';
                        messageParams.title = 'Failed To Generate Code';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    }
                );
            });
        };

        $scope.deleteCode = function (id, index) {
            var data = {
                codeId: id,
            };

            $http.post('/invitations/deleteJoinCode', data).then(
                function (res) {
                    $log.debug(
                        'AdminAreaInvitationsController::deleteCode Success in invitations/deleteJoinCode',
                        res
                    );
                    var deletedCode = _.find($scope.codes, function (code) {
                        return code.id === id;
                    });
                    var indexOfCode = $scope.codes.indexOf(deletedCode);
                    $scope.codes.splice(indexOfCode, 1);
                },
                function (error) {
                    $log.debug(
                        'AdminAreaInvitationsController::deleteCode Error in invitations/deleteJoinCode',
                        error
                    );
                    var messageParams = {};
                    messageParams.message = 'Delete failed, please try again.';
                    messageParams.title = 'Failed To Delete Code';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        };

        $scope.updateCode = function (code) {
            code.allowed_amount = validateAllowedAmount(code.allowed_amount);

            var data = {
                codeId: code.id,
                code: code.code,
                type: code.type,
                destination_email: code.destination_email,
                allowed_amount: code.allowed_amount,
                comment: code.comment,
                expiration_days: code.expiration_days,
            };

            $http.post('/invitations/updateJoinCode', data).then(
                function (res) {
                    $log.debug(
                        'AdminAreaInvitationsController::updateDestinationEmail Success in invitations/updateJoinCode',
                        res
                    );
                    calculateExpiration(code);
                    code.unsaved = false;
                    var messageParams = {};
                    messageParams.message = 'Code was updated';
                    Messages.openMessage($scope, messageParams);
                },
                function (error) {
                    $log.debug(
                        'AdminAreaInvitationsController::updateDestinationEmail Error in invitations/updateJoinCode',
                        error
                    );
                    var messageParams = {};
                    messageParams.message =
                        error.data && error.data.message
                            ? error.data.message.text
                            : 'update failed, please try again.';
                    messageParams.title = 'Failed To Update Code';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        };

        $scope.getHeaderForInvitationRequestsCSV = function () {
            return [
                'First Name',
                'Last Name',
                'Email',
                'Role',
                'Type',
                'Source',
                'Website',
                'Subscribed to Newsletter',
                'Date',
            ];
        };

        function getInvitationRequests() {
            var deferred = $q.defer(),
                data = {
                    period_days: $scope.invitationRequests.period_days,
                };

            $http.post('/invitations/getRequests', data).then(
                function (res) {
                    $log.debug(
                        'AdminAreaInvitationsController::getInvitationRequests successfully retrieved invitation requests',
                        res
                    );
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug(
                        'AdminAreaInvitationsController::getInvitationRequests failed to retrieve invitation requests',
                        error
                    );
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        $scope.exportInvitationRequestsCSV = function () {
            var deferred = $q.defer();
            $scope.submittingInvitationRequests = true;
            getInvitationRequests()
                .then(function (res) {
                    if (res && res.requests && res.requests.length > 0) {
                        var array = [];
                        var invitationRow;
                        res.requests.forEach(function (row) {
                            invitationRow = row.Invitation;
                            array.push({
                                a: invitationRow.first_name,
                                b: invitationRow.last_name,
                                c: invitationRow.email,
                                d: invitationRow.role,
                                e: invitationRow.type,
                                f: invitationRow.source,
                                g: invitationRow.website_link,
                                h: invitationRow.newsletter,
                                i: invitationRow.created,
                            });
                        });
                        deferred.resolve(array);
                    }
                })
                .finally(function (res) {
                    $scope.submittingInvitationRequests = false;
                });
            return deferred.promise;
        };

        $scope.colorLine = function (code) {
            var styleObject = {};

            if (code.allowed_amount !== -1 && code.used_amount >= code.allowed_amount) {
                styleObject.color = 'green';
            } else if (code.expired) {
                styleObject.color = 'red';
            }

            return styleObject;
        };
    },
]);
