'use strict';

angular.module('core').controller('MessagesChangePasswordController', [
    'params',
    '$rootScope',
    '$state',
    '$uibModalInstance',
    '$q',
    '$http',
    '$log',
    'FormValidationService',
    'Messages',
    function (
        params,
        $rootScope,
        $state,
        $uibModalInstance,
        $q,
        $http,
        $log,
        FormValidationService,
        Messages
    ) {
        var self = this;
        this.Messages = Messages;
        this.FormValidationService = FormValidationService;
        this.changePasswordObj = {};
        this.params = params || {};

        function changePassword() {
            var deferred = $q.defer(),
                data = {
                    current: self.changePasswordObj.current,
                    new: self.changePasswordObj.new,
                    confirm: self.changePasswordObj.confirm,
                };

            $http.post('/users/changePassword', data).then(
                function (res) {
                    $log.debug('MessagesChangePasswordController::changePassword Success', res, data);
                    deferred.resolve();
                },
                function (error) {
                    $log.debug('MessagesChangePasswordController::changePassword Failure', error, data);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function updateNewPassword() {
            var deferred = $q.defer(),
                data = {
                    email: self.params.email,
                    pass: self.changePasswordObj.new,
                    code: self.params.code,
                };

            $http.post('/users/updateNewPassword', data).then(
                function (res) {
                    $log.debug('MessagesChangePasswordController::updateNewPassword Success', res, data);
                    deferred.resolve();
                },
                function (error) {
                    $log.debug('MessagesChangePasswordController::updateNewPassword Failure', error, data);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        this.clearQueryParams = function () {
            window.history.replaceState(null, null, window.location.pathname);
        };

        this.updatePassword = function () {
            var messageParams = {};

            if (self.params.reset) {
                updateNewPassword().then(
                    function () {
                        messageParams.message = 'Password was changed successfully.';
                        $uibModalInstance.close('ok');
                        Messages.openMessage($rootScope, messageParams).then(function () {
                            $state.go('home-page', { reset: '3', email: self.params.email });
                        });
                    },
                    function (error) {
                        messageParams.message =
                            'There was a problem changing the password. Please refresh the page and try again.';
                        messageParams.disableAutoDismiss = true;
                        $uibModalInstance.dismiss('cancel');
                        Messages.openMessage($rootScope, messageParams);
                    }
                );
            } else {
                changePassword().then(
                    function () {
                        messageParams.message = 'Password was changed successfully.';
                        $uibModalInstance.close('ok');
                        Messages.openMessage($rootScope, messageParams);
                    },
                    function (error) {
                        messageParams.message =
                            'There was a problem changing the password. Please refresh the page and try again.';
                        messageParams.disableAutoDismiss = true;
                        $uibModalInstance.dismiss('cancel');
                        Messages.openMessage($rootScope, messageParams);
                    }
                );
            }
        };
    },
]);
