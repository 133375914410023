'use strict';

angular.module('core').directive('matchUrl', [
    '$rootScope',
    '$location',
    '_',
    function ($rootScope, $location, _) {
        return {
            restrict: 'A',
            link: function ($scope, $element, $attr) {
                var functionToRun = $attr.matchUrlFunction; // run a function on match
                var classToAdd = $attr.matchUrlClass || 'active'; // set a specific class - defaults to active
                var elementPath = $element[0].href || $attr.ngHref || $attr.href;
                if (!elementPath) {
                    if ($element[0].children.length > 0) {
                        var childElemWithHref = _.find($element[0].children, function (childElem) {
                            return (
                                childElem.href ||
                                childElem.attributes.getNamedItem('ng-href').value ||
                                childElem.attributes.getNamedItem('href').value
                            );
                        });
                        if (childElemWithHref !== undefined) {
                            elementPath =
                                childElemWithHref.href ||
                                childElemWithHref.attributes.getNamedItem('ng-href').value ||
                                childElemWithHref.attributes.getNamedItem('href').value;
                        }
                    }
                }
                var baseURI = window.location.protocol + '//' + window.location.host + '/';
                $scope.$location = $location;
                //            $scope.$on('$locationChangeSuccess', function (locationPath) {
                $scope.$watch('$location.path()', function (locationPath) {
                    //                var elementPath = -1;

                    //        if (!element[0].href) { // active on href of child
                    //            if (element[0].children.length > 0) {
                    //                var childElemWithHref = _.find(element[0].children, function(childElem) {
                    //                    return childElem.href && childElem.baseURI;
                    //                });
                    //                if (childElemWithHref !== undefined) {
                    //                    url =  childElemWithHref.href.replace(childElemWithHref.baseURI, '');
                    //                }
                    //            }
                    //        } else {
                    //                if (element[0].href) {
                    //                    url = element[0].href.replace(element[0].baseURI, '');
                    //                }
                    //
                    //                if (url !== -1) {
                    //                    url = url.replace(/^#!/, ''); // if url starts with '#!' remove it
                    //
                    //                    if ($location.path().substring(0, url.length) === url) { // if current location starts with the url of the element
                    //                        element.addClass('active');
                    //                    }
                    //                }

                    //                locationPath = $scope.$location.url();

                    if (elementPath) {
                        elementPath = elementPath
                            .replace(baseURI, '')
                            .replace(/^#!/, '')
                            .replace(/\?(.*)/, ''); // if url starts with '#!' remove it, remove all query string
                        if (elementPath.charAt(0) !== '/') {
                            elementPath = '/' + elementPath;
                        }
                        var decodedLocationPath = decodeURIComponent(locationPath);
                        var decodedElementPath = decodeURIComponent(elementPath); // For cases where & gets translated into %26 etc.
                        locationPath = locationPath.substring(0, elementPath.length);
                        decodedLocationPath = decodedLocationPath.substring(0, decodedElementPath.length);
                        if (
                            locationPath === decodedElementPath ||
                            decodedLocationPath === elementPath ||
                            locationPath === elementPath ||
                            decodedLocationPath === decodedElementPath
                        ) {
                            if (functionToRun) {
                                $scope.$eval(functionToRun);
                            }
                            $element.addClass(classToAdd);
                        } else {
                            $element.removeClass(classToAdd);
                        }
                    }
                });
            },
        };
    },
]);
