'use strict';

angular.module('search').controller('SearchMainController', [
    '$scope',
    '$log',
    '$state',
    '$stateParams',
    '$window',
    '$timeout',
    '$filter',
    '$location',
    'Search',
    'Messages',
    '_',
    'User',
    function ($scope, $log, $state, $stateParams, $window, $timeout, $filter, $location, Search, Messages, _, User) {
        $scope.$filterFunc = $filter('filter');
        $scope.userRoles = [
            'Art Fan',
            'Artist',
            'Collector',
            'Curator',
            'Academy',
            'Archive',
            'Art Event',
            'Gallery',
            'Public Installation',
            'Institution',
            'Museum',
            'Other',
        ];
        $scope.Search = Search;
        $scope.query = $stateParams.query;
        $scope.sidebarSelection = $stateParams.sidebarSelection;
        $scope.loading = false;
        $scope.showAdminPanel = User.hasRole([User.getAdminRoleId(), User.getProCuratorRoleId()]);
        $scope.hideAdminPanelDelete = User.isProCurator();
        let initial = true;

        function getDifferentTypesOfResult(results) {
            return _.uniq(_.pluck(results, 'type'));
        }

        function onSearchSuccess(res) {
            $scope.loading = false;
            $log.debug('SearchMainController::onSearchSuccess Success', res);
            $scope.types = getDifferentTypesOfResult(res);
            Search.setResults(res);
            if ($scope.sidebarSelection === 'all') {
                Search.noMoreResults = Object.fromEntries(
                    Search.sidebarItems.map((v) => {
                        return [
                            v.type,
                            Search.results.filter((i) => i.type === v.type).length < Search.getResultsLimit(),
                        ];
                    })
                );
            }
            addIntersection();
        }

        function addIntersection() {
            $timeout(() => {
                const intersecElem = document.querySelector('table tbody tr:last-child');

                const scrollObserver = new IntersectionObserver(
                    ([entry], observe) => {
                        if (!entry.isIntersecting) return;

                        observe.unobserve(intersecElem);
                        observe.disconnect();

                        if (!Search.noMoreResults[findSidebarSelectionType()]) {
                            loadMore(Search.getResultsLimit());
                        } else {
                            $scope.loading = false;
                        }
                    },
                    { threshold: 1 }
                );
                scrollObserver.observe(intersecElem);
            }, 1000);
        }

        function onSearchFailure(error) {
            $log.debug('SearchMainController::onSearchFailure Failure', error);
            var messageParams = {};
            messageParams.title = 'Search Failure';
            messageParams.message = 'Search ended up in error. Please refresh the page and try again.';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($scope, messageParams);
        }

        function getCorrectSearchFunction() {
            var searchFunction = '';

            switch ($scope.sidebarSelection) {
                case 'all':
                    searchFunction = Search.searchAll;
                    break;
                case 'artworks':
                    searchFunction = Search.searchArtworks;
                    break;
                case 'artcasts':
                    searchFunction = Search.searchArtcasts;
                    break;
                case 'catalogs':
                    searchFunction = Search.searchCatalogs;
                    break;
                /*case 'channels':
                    searchFunction = Search.searchChannels;
                    break;*/
                case 'users':
                    searchFunction = Search.searchUsers;
                    break;
                case 'collections':
                    searchFunction = Search.searchCollections;
                    break;
            }

            return searchFunction;
        }

        $scope.getTitleIdByName = Search.getTitleIdByName;

        function searchAccordingToQuery() {
            $scope.loading = true;
            $scope.$emit('SearchMainController:notify_query');
            var searchFunction = getCorrectSearchFunction();

            searchFunction($scope.query, Search.getResultsLimit(), true, null, $state.params.type).then(
                function (res) {
                    onSearchSuccess(res);
                },
                function (error) {
                    onSearchFailure(error);
                }
            );
        }

        function findSidebarSelectionType() {
            const selectedItem = Search.sidebarItems.find(
                (sidebarItem) => sidebarItem.link === $scope.sidebarSelection
            );
            return selectedItem.type;
        }

        function loadMore(limit) {
            if ($stateParams.sidebarSelection !== 'all') {
                $scope.loading = true;
                let currentLimit = limit || Search.getResultsLimit();
                let offset = Search.results.length;
                const searchFunction = getCorrectSearchFunction();

                if (!initial) {
                    offset += currentLimit;
                }
                initial = false;
                searchFunction($scope.query, currentLimit, true, offset, $state.params.type).then(
                    function (res) {
                        if (res.length === 0 || res.length < currentLimit) {
                            Search.noMoreResults[findSidebarSelectionType()] = true;
                        }
                        $scope.loading = false;
                        Search.setResults(Search.results.concat(res));
                        addIntersection();
                    },
                    function (error) {
                        onSearchFailure(error);
                    }
                );
            }
        }

        $scope.init = function () {
            $scope.sidebarSelection = $stateParams.sidebarSelection;

            var selection = _.find(Search.sidebarItems, function (sidebarItem) {
                return sidebarItem.link === $scope.sidebarSelection;
            });

            if (!selection) {
                $state.go('search.main', { sidebarSelection: 'all', query: $scope.query });
            } else {
                if ($scope.query) {
                    searchAccordingToQuery();
                }
            }
        };

        $scope.searchResultsSelected = function (item, model, label) {
            if (item.type !== 'separator') {
                $state.go(item.state, item.params);
            }
        };

        $scope.searchIt = function () {
            $state.go('search.main', { sidebarSelection: $scope.sidebarSelection, query: $scope.query });
        };

        $scope.getTypeName = function (type) {
            if (!type) return '';

            if (type.toLowerCase().endsWith('y')) {
                return type.slice(0, -1) + 'ies';
            } else {
                return type + 's';
            }
        };
    },
]);
