'use strict';

angular.module('share').controller('ShareChannelController', [
    '$rootScope',
    '$state',
    '$scope',
    '$stateParams',
    '$q',
    '$http',
    '$log',
    'Security',
    'Messages',
    'ChannelService',
    'FormValidationService',
    'User',
    '_',
    'CHANNEL_TYPES',
    function (
        $rootScope,
        $state,
        $scope,
        $stateParams,
        $q,
        $http,
        $log,
        Security,
        Messages,
        ChannelService,
        FormValidationService,
        User,
        _,
        CHANNEL_TYPES
    ) {
        var self = this;
        self.parent = $scope.vm;
        self.viewType = 'channel';

        /*
        this.FormValidationService = FormValidationService;
        this.Messages = Messages;
        this.NIIO_URL = NIIO_URL;
        this.isAdmin = User.isAdmin();
        this.processingSave = false;
        */
        this.CHANNEL_TYPES = CHANNEL_TYPES;

        self.arrangeActions = function () {
            var actions = [],
                action,
                i;

            for (i = 0; i < self.parent.actions.length; i++) {
                if (self.parent.actions[i].selected) {
                    action = angular.copy(self.parent.actions[i]);
                    delete action.selected;
                    delete action.shouldShow;
                    delete action.view;
                    actions.push(action);
                }
            }

            return actions;
        };

        /* this.sendEmail = function() {
            var deferred = $q.defer();

            self.savePreset(self.selectedPublish)
                .then(function(res) {
                    $log.debug('ShareChannelController::sendEmail Success', {res: res});
                    var messageParams = {};
                    messageParams.message = 'Email was sent successfully';
                    Messages.openMessage($rootScope, messageParams)
                        .then(function() {
                            Messages.cancel();
                        });
                }, function(error) {
                    $log.debug('ShareChannelController::sendEmail Failure', {error: error});
                    if (!self.processingSave) {
                        var messageParams = {};
                        messageParams.message = 'There was a problem with sending the email: \n' + error.data.errors;
                        messageParams.title = 'Transfer Error';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                });

            return deferred.promise;
        };*/

        // this.sendEmail = function() {
        //     var deferred = $q.defer();
        //
        //     var data = {
        //         url: self.share.url,
        //         channelId: self.channel.id,
        //         recipients: self.share.recipients,
        //         description: self.share.description
        //     };
        //
        //     self.processingSave = true;
        //
        //     $http.post('/transfer/shareChannels', data)
        //         .then(function (res) {
        //             $log.debug('ShareController::sendEmail Success', {res: res});
        //             var messageParams = {};
        //             messageParams.message = 'Email was sent successfully';
        //             messageParams.size = 'lg';
        //             Messages.openMessage($rootScope, messageParams)
        //                 .then(function() {
        //                     Messages.cancel();
        //                 });
        //             deferred.resolve(res);
        //         }, function (error) {
        //             $log.debug('ShareController::sendEmail Failure', {error: error});
        //             var messageParams = {};
        //             messageParams.message = 'There was a problem with sending the email: ' + error.data.errors;
        //             messageParams.title = 'Share Error';
        //             messageParams.size = 'lg';
        //             messageParams.disableAutoDismiss = true;
        //             Messages.openMessage($rootScope, messageParams);
        //             deferred.reject(error);
        //         })
        //         .finally(function() {
        //             self.processingSave = false;
        //         });
        //
        //     return deferred.promise;
        // };

        this.actionWasChanged = function (newAction, oldActionString) {
            self.parent.actions.forEach(function (action) {
                if (action.id === newAction.id && action.view === newAction.view) {
                    action.selected = true;
                } else if (action.id !== '4') {
                    action.selected = false;
                }
            });
        };

        /*function initPublishes() {
            // TODO - get publishes from server
            self.publishes = [
                { id: '1', name: 'mail', view: 'Send Via Mail', icon: 'fa-envelope', actionButtons: [{view: 'Send', action: self.sendEmail}] },
                //{ id: '2', name: 'link', view: 'URL', icon: 'fa-link', actionButtons: [{view: 'Close', action: Messages.cancel}, {view: 'Copy', action: self.sendEmail2}] }
                { id: '2', name: 'link', view: 'URL', icon: 'fa-link', actionButtons: [{view: 'Activate URL', action: Messages.cancel}] }
            ];
            self.selectedPublish = self.publishes[0];
            self.linkPublish = self.publishes[1];
        }*/

        self.initPresets = function () {
            self.share = self.parent.share;
            self.share.presetId = undefined;
            self.share.url = '/channel/' + self.channel.url_name;
            self.share.uniqueSeq = '';

            // TODO - get actions from server
            self.parent.actions = ChannelService.getChannelActions(self.channel.type);
            // angular.copy(ChannelService.actions);
            self.share.selectedAction = self.parent.actions[0];
        };

        /*function arrangePublish(specificPublish) {
            var publish;
            if (specificPublish) {
                publish = angular.copy(specificPublish);
            } else {
                publish = angular.copy(self.selectedPublish);
            }

            delete publish.icon;
            delete publish.actionButtons;
            delete publish.view;

            return publish;
        }*/

        /* this.savePreset = function(specificPublish) {
            var deferred = $q.defer();
            // TODO - generator link from server

            if (!self.processingSave) {
                self.processingSave = true;

                // 1. send preset with actions
                var actions = arrangeActions();
                var publish = arrangePublish(specificPublish);

                var data = {
                    presetId: self.share.presetId,
                    channelId: self.channel.id,
                    actions: actions,
                    url: self.share.url,
                    publish: publish,
                    publishId: self.share.publishId,
                    uniqueSeq: self.share.uniqueSeq,
                    invitationCode: self.share.invitationCode,
                };

                if (self.enablePassword && self.share.password) {
                    data.password = self.share.password;
                }

                $http.post('/transfer/savePreset', data)
                    .then(function (res) {
                        //var res = { 'unique_seq': randomString(), presetId: '23' };
                        self.share.uniqueSeq = res.data.data.uniqueSeq;
                        self.share.presetId = res.data.data.entityPresetId;
                        self.share.publishId = res.data.data.publishId;
                        self.share.invitationCode = res.data.data.invitationCode;
                        $log.debug('ShareChannelController::savePreset Success', {res: res});
                        self.processingSave = false;
                        deferred.resolve(res);
                    }, function (error) {
                        $log.debug('ShareChannelController::savePreset Failure', {error: error});
                        self.processingSave = false;
                        deferred.reject(error);
                    });
            } else {
                $log.debug('ShareChannelController::savePreset Tried to save but currently in process of other share');
                deferred.reject('Saving in process already');
            }

            return deferred.promise;
        };*/

        this.generateEmbedCode = function () {
            //TODO: Rewrite.
            var embedParams = 'showinfo=' + self.parent.embed.showinfo;
            embedParams += '&urlType=' + (self.parent.embed.useSocialLink ? 'social' : 'share');
            embedParams += '&autoplay=' + self.parent.embed.autoplay;
            embedParams += '&mute=' + self.parent.embed.mute;
            embedParams += '&hidefullscreen=' + self.parent.embed.hidefullscreen;
            embedParams += '&controls=' + self.parent.embed.controls;
            if (self.parent.embed.hideinfobtn) {
                embedParams += '&hideinfobtn=' + self.parent.embed.hideinfobtn;
            }

            if (self.parent.embed.posterlink && self.parent.embed.posterlinkUrl !== '') {
                embedParams += '&poster=' + encodeURIComponent(self.parent.embed.posterlinkUrl);
            }

            self.parent.embedRatio = 9 / 16;
            if (self.artwork !== undefined && self.artwork.file_metadata !== undefined) {
                var wt = self.artwork.file_metadata.width * 1;
                var ht = self.artwork.file_metadata.height * 1;

                if (
                    self.artwork.display_guidelines !== undefined &&
                    self.artwork.display_guidelines !== null &&
                    self.artwork.display_guidelines.portrait_mode &&
                    self.artwork.display_guidelines.portrait_submode !== undefined &&
                    (self.artwork.display_guidelines.portrait_submode === 2 ||
                        self.artwork.display_guidelines.portrait_submode === 3)
                ) {
                    var t = ht;
                    ht = wt;
                    wt = t;
                }

                if (ht !== 0 && wt !== 0) {
                    self.parent.embedRatio = ht / wt;
                }
            }

            var isPortrait = self.parent.embedRatio > 1;
            var niioHost = new URL(self.parent.NIIO_URL).host;
            self.parent.embedUrl =
                '' +
                self.parent.NIIO_API_URL +
                '/index.html?' +
                embedParams +
                '&channelid=' +
                self.channel.id +
                '&channelurl=' +
                encodeURIComponent(self.channel.url_name) +
                '&portrait=' +
                isPortrait +
                (niioHost.indexOf('niio.com') === -1 ? '&endpoint=' + niioHost : '') +
                '&key=' +
                self.share.uniqueSeq +
                (self.share.invitationCode ? '&invitationCode=' + self.share.invitationCode : '');
            var wrpperStyle = isPortrait
                ? 'position: relative;height: 0;overflow: hidden; padding: 0 0 ' +
                  Math.ceil(self.parent.embedRatio * 100) +
                  '% 0;'
                : 'position: relative;height: 0;overflow: hidden; padding: 0 0 ' +
                  self.parent.embedRatio * 100 +
                  '% 0;';
            var frameDim = isPortrait
                ? 'width="560" height="' + 560 * self.parent.embedRatio + '" '
                : 'width="560" height="' + 560 * self.parent.embedRatio + '" ';
            var frameStyle = 'position: absolute;top:0;left:0;width:100%;height: 100%;';
            var embedCode = '<div style="' + wrpperStyle + '">';
            embedCode += '<iframe class="niio-media-frame" id="niioWidget1" src="';
            embedCode += self.parent.embedUrl;
            embedCode += '" frameborder="0" allowfullscreen="" webkitallowfullscreen mozallowfullscreen ';
            embedCode += frameDim;
            embedCode += ' style="';
            embedCode += frameStyle;
            embedCode += '" ></iframe></div>';

            if (self.share.selectedAction !== undefined) {
                self.share.selectedAction.parameters.embed = self.parent.embed;
            }
            $rootScope.$broadcast('embed:refreshed', embedCode);
        };

        this.extendPresetRequestData = function (data) {
            if (self.channel !== undefined) {
                data.channelId = self.channel.id;
            }
        };

        this.priceChanged = function () {
            //Donothing.
        };

        this.init = function (params) {
            self.params = params;
            self.channel = self.params && self.params.channel ? self.params.channel : {};

            /*initPresets();
            initPublishes();
            self.savePreset(self.linkPublish);*/
            var callbacks = {
                initPresets: self.initPresets,
                generateEmbedCode: self.generateEmbedCode,
                arrangeActions: self.arrangeActions,
                extendPresetRequestData: self.extendPresetRequestData,
                openLearnMore: self.openLearnMore,
            };
            self.parent.init(params, callbacks);
        };

        /* this.cancelPublish = function () {
            var data = {
                publishId: self.share.publishId,
                remove: true
            };

            $http.post('/transfer/deletePublish', data)
                .then(function (res) {
                    $log.debug('ShareChannelController::cancelPublish Success', {res: res});
                }, function (error) {
                    $log.debug('ShareChannelController::cancelPublish Failure', {error: error});
                });
            Messages.cancel();
        };*/

        /*this.loadTags = function ($query) {
            var data = {
                query: $query
            };

            return $http.post('/tags/getContacts', data).then(function (res) {
                var tags = [];
                res.data.tags.forEach(function(tag) {
                    tags.push({text: tag});
                });
                return tags;
            });
        };*/

        //TODO: Move to channel
        this.getModalTitle = function () {
            return (
                'Share ' + (self.channel && self.channel.type === self.CHANNEL_TYPES.CATALOG ? 'Catalog' : 'Artcast')
            );
        };

        this.getLearnMoreLabel = function () {
            return 'Learn more about share channel';
        };

        this.openLearnMore = function () {
            var messageParams = {};
            messageParams.title = 'Learn More About ‘Share Channel’';
            messageParams.disableAutoDismiss = true;
            messageParams.template = 'modules/share/views/share-channel.learn-more.client.view.html';
            Messages.openMessage($scope, messageParams);
        };
    },
]);
