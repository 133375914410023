'use strict';

angular.module('home').controller('HomeChannelsController', [
    'channels',
    '$log',
    '$rootScope',
    '$timeout',
    '$scope',
    '$state',
    '$location',
    '$stateParams',
    'HomeDataService',
    '_',
    'smoothScroll',
    'User',
    function (
        channels,
        $log,
        $rootScope,
        $timeout,
        $scope,
        $state,
        $location,
        $stateParams,
        HomeDataService,
        _,
        smoothScroll,
        User
    ) {
        $scope.init = function (channelsOrCatalogs) {
            //Scroll to head of page in init
            if (!$scope.isOther && !$scope.lastScroll) {
                var homeSecondNavElement = document.getElementById('home-secondary-nav');
                if (homeSecondNavElement) {
                    smoothScroll(homeSecondNavElement, { offset: $rootScope.heightOfNav });
                }
            }

            $scope.isAdmin = User.isAdmin();
            $scope.channels = channels;
            if (
                $state.params.channelUrlName &&
                !HomeDataService.isUsersChannel($scope.channels, $state.params.channelUrlName)
            ) {
                $state.go('home');
                return;
            }
        };

        $scope.$on('HomeChannelsMainController:channel_chosen', function (event, channelLink) {
            //$scope.channelId = channelId;
            $scope.selectedChannel = _.find($scope.sidebarChannels.groups, function (channel) {
                return channel.link === channelLink;
            });
        });

        var subscriptionsName =
            ($scope.isOther ? $scope.profile.display_name + "'s" : 'My') + ' Subscriptions';
        var curatedChannelsName = 'Created by ' + ($scope.isOther ? $scope.profile.display_name : 'Me');
        var sharedCatalogsName = 'Shared with ' + ($scope.isOther ? $scope.profile.display_name : 'Me');
        var curatedCatalogsName = 'Created by ' + ($scope.isOther ? $scope.profile.display_name : 'Me');

        $scope.sidebarChannels = {
            groups: [
                {
                    name: 'All Artcasts',
                    link: 'allChannels',
                    type: 'artcast',
                },
                {
                    name: subscriptionsName,
                    link: 'subscriptions',
                    type: 'artcast',
                },
                {
                    name: curatedChannelsName,
                    link: 'curatedChannels',
                    type: 'artcast',
                },
                {
                    name: 'All Catalogs',
                    link: 'allCatalogs',
                    type: 'catalog',
                },
                {
                    name: sharedCatalogsName,
                    link: 'sharedCatalogs',
                    type: 'catalog',
                },
                {
                    name: curatedCatalogsName,
                    link: 'curatedCatalogs',
                    type: 'catalog',
                },
            ],
            getCount: function (link) {
                if (link === 'subscriptions') {
                    return $scope.channels.subscribedChannels.length;
                }
                if (link === 'curatedChannels') {
                    return $scope.channels.curatedChannels.length;
                }
                if (link === 'sharedCatalogs') {
                    return $scope.channels.sharedCatalogs.length;
                }
                if (link === 'curatedCatalogs') {
                    return $scope.channels.curatedCatalogs.length;
                }
                if (link === 'allChannels') {
                    return $scope.channels.curatedChannels.length + $scope.channels.subscribedChannels.length;
                }
                if (link === 'allCatalogs') {
                    return $scope.channels.sharedCatalogs.length + $scope.channels.curatedCatalogs.length;
                }
                return -1;
            },
        };

        // $rootScope.$emit('ChannelInfoChannelController:channel_info_destroyed');

        $scope.$on('ChannelInfoChannelController:channel_info_entered', function () {
            $scope.hideSidebar = true;
        });

        $scope.$on('ChannelInfoChannelController:channel_info_destroyed', function () {
            $scope.hideSidebar = false;
        });
    },
]);
