'use strict';

angular.module('channel-info').controller('ChannelInfoChannelArtworkInfoModalController', [
    'ChannelInfoService',
    '$rootScope',
    '$scope',
    '$state',
    '$stateParams',
    '$timeout',
    'OpenPopupForm',
    'Messages',
    '_',
    function (
        ChannelInfoService,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $timeout,
        OpenPopupForm,
        Messages,
        _
    ) {
        var self = this;
        this.ChannelInfoService = ChannelInfoService;
        this.OpenPopupForm = OpenPopupForm;
        this.Messages = Messages;
        this.$state = $state;
        this.$stateParams = $stateParams;

        function findArtworkIndex() {
            self.currentArtworkIndex = undefined;

            var foundIndex = _.findIndex(self.artworks, function (artwork) {
                return artwork.id === self.artworkId;
            });

            if (foundIndex > -1) {
                self.currentArtworkIndex = foundIndex;
            }
        }

        this.init = function () {
            document.getElementById('modalElement').focus();

            self.artwork = self.params && self.params.artwork ? self.params.artwork : {};
            self.channel = self.params && self.params.channel ? self.params.channel : {};
            self.artworkId = self.artwork.id;
            self.artworks = self.params && self.params.artworks ? self.params.artworks : {};
            // Params that will be available on artwork info controller
            $stateParams.artworkId = self.artworkId;
            $stateParams.channelId = self.artwork.belongsToChannelId;
            $stateParams.channelHidePrices = self.channel ? self.channel.hide_prices : false;
            $stateParams.channel = self.channel;
            $stateParams.collectionId = self.artwork.belongsToCollectionId;
            self.from =
                self.$state.current.data && self.$state.current.data.from
                    ? self.$state.current.data.from
                    : '';
            // self.isDiscovery = self.from === 'discovery' || self.from === 'discovery-catalogs' || self.from === 'discovery-invitation' || self.from === 'discovery-artcasts';
            self.isDiscovery = true;
            $stateParams.fromModal = true;
            $stateParams.autoPlay = true;
            self.notLoadingArtwork = true;
            self.loadingArtworkInfo = true;
            findArtworkIndex();
        };

        $scope.$on('ArtworkInfoArtworkController::finished_loading', function () {
            $timeout(function () {
                self.loadingArtworkInfo = false;
            }, 0);
        });

        this.keyPressed = function ($event) {
            if ($event.keyCode === 39) {
                self.navigateArtwork(1); // Next
            } else if ($event.keyCode === 37) {
                self.navigateArtwork(-1); // Previous
            }
        };

        this.navigateArtwork = function (to) {
            if (
                self.loadingArtworkInfo ||
                (self.currentArtworkIndex === 0 && to < 0) ||
                (self.currentArtworkIndex === self.artworks.length - 1 && to > 0)
            ) {
                return;
            } else {
                var nextArtwork = self.artworks[self.currentArtworkIndex + to];
                if (nextArtwork) {
                    self.notLoadingArtwork = false;
                    self.loadingArtworkInfo = true;
                    $timeout(function () {
                        $stateParams.artworkId = nextArtwork.id;
                        $stateParams.channelId = nextArtwork.belongsToChannelId;
                        $stateParams.collectionId = nextArtwork.belongsToCollectionId;
                        self.currentArtworkIndex += to;
                        self.notLoadingArtwork = true;
                    }, 0);
                }
            }
        };
    },
]);
