'use strict';

angular.module('core').directive('iframeOnload', [
    function () {
        return {
            restrict: 'A',
            scope: {
                callBack: '&iframeOnload',
            },
            link: function (scope, element, attrs) {
                element.on('load', function () {
                    return scope.callBack();
                });

                scope.$on('$destroy', function () {
                    element.off('load');
                });
            },
        };
    },
]);
