'use strict';

angular.module('home').controller('HomeEventsSubmissionController', [
    '$rootScope',
    '$filter',
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$timeout',
    '$http',
    '_',
    'smoothScroll',
    'HomeDataService',
    'Messages',
    'OpenPopupForm',
    function (
        $rootScope,
        $filter,
        $scope,
        $state,
        $stateParams,
        $log,
        $q,
        $timeout,
        $http,
        _,
        smoothScroll,
        HomeDataService,
        Messages,
        OpenPopupForm
    ) {
        $scope.$state = $state;
        $scope.collapsibles = {};
        $scope.HomeDataService = HomeDataService;
        $scope.eventId = $stateParams.eventId;
        $scope.currentStep = undefined;

        function selectStep(stepLink, step) {
            var selectedStep = null;
            var states;
            var lastState;

            if (step) {
                selectedStep = step;
            } else if (stepLink) {
                selectedStep = _.find($scope.currentEvent.steps, function (step) {
                    return step.link === stepLink;
                });
            } else {
                var anyCompletedEventExists = _.findIndex($scope.currentEvent.steps, function (step) {
                    return step.completed;
                });

                var nextStep = _.find($scope.currentEvent.steps, function (step) {
                    if (anyCompletedEventExists > -1) {
                        return !step.completed && !step.notRequired;
                    } else {
                        // If completed some steps, first step is instructions which is not required
                        return !step.completed;
                    }
                });

                if (nextStep) {
                    selectedStep = nextStep;
                } else {
                    selectedStep = $scope.currentEvent.steps[$scope.currentEvent.steps.length - 1];
                }
            }

            $scope.currentStep = selectedStep;

            states = $state.current.name.split('.');
            lastState = states[states.length - 1];
            if (lastState === 'submission') {
                $state.go('.' + selectedStep.link);
            } else if (lastState !== selectedStep.link) {
                $state.go('^.' + selectedStep.link);

                // $state.go('^.' + selectedStep.link, $stateParams, { reload: true });
                //Scroll to head of page in step init
                var homeSecondNavElement = document.getElementById('home-secondary-nav');
                if (homeSecondNavElement) {
                    smoothScroll(homeSecondNavElement, { offset: $rootScope.heightOfNav, duration: 10 });
                }
            }
        }

        $scope.init = function () {
            $scope.loadingEvent = true;
            HomeDataService.getEventSubmissionData($stateParams.eventId, $scope.profileId)
                .then(function (eventData) {
                    $scope.$emit('event_selected', eventData, 'submission');
                })
                .finally(function () {
                    $scope.loadingEvent = false;
                });
        };

        $scope.$on('HomeEventsController::steps_initiated', function () {
            var states = $state.current.name.split('.');
            var lastState = states[states.length - 1];
            if (lastState !== 'submission') {
                $scope.$broadcast('reinitiazlie_step'); // When refreshing the browser and the step is selected as state
                // selectStep(lastState);
            } else {
                selectStep();
            }
        });

        $scope.$on('HomeEventsController::select_step', function ($event, step) {
            selectStep(step.link, step);
        });

        $scope.$on('submission_step_completed', function () {
            HomeDataService.updateStepCompleted(
                $scope.currentEvent.event.id,
                $scope.userId,
                $scope.currentStep.link
            ).then(
                function (res) {
                    $scope.currentStep.completed = true;
                    if ($scope.currentStep.link !== 'summary') {
                        selectStep();
                    }
                },
                function (error) {
                    var messageParams = {};
                    messageParams.title = 'Failed To Complete Step';
                    messageParams.message =
                        'Step could not be completed.\nPlease refresh the browser or logout  login again. If the problem consists, please contact support (press OK to continue).';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams).then(function (res) {
                        OpenPopupForm.openContact(
                            'support',
                            null,
                            'event submission step ' +
                                $scope.currentStep.name +
                                ' failure (event id: ' +
                                $stateParams.eventId +
                                ')'
                        );
                    });
                }
            );
        });

        $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            var isToSubmission = toState.name.indexOf('submission') > -1;
            var isFromSubmission = fromState.name.indexOf('submission') > -1;

            if (isToSubmission && isFromSubmission && fromParams.eventId === toParams.eventId) {
                var fromStateSplit = fromState.name.split('.');
                var fromLastStateName = fromStateSplit[fromStateSplit.length - 1];

                var toStateSplit = toState.name.split('.');
                var toLastStateName = toStateSplit[toStateSplit.length - 1];

                if (fromLastStateName !== 'submission' && toLastStateName === 'submission') {
                    // Go to same step
                    event.preventDefault();
                    $state.go(fromState, fromParams);
                }
            }
        });
    },
]);
