'use strict';

angular.module('admin-area').controller('AdminAreaServicesApiController', [
    '$rootScope',
    '$http',
    '$log',
    '$q',
    'Messages',
    'Search',
    '_',
    function ($rootScope, $http, $log, $q, Messages, Search, _) {
        var self = this;
        this.toggles = this.toggles || {};
        this.Search = Search;

        // Grid
        this.orderByField = 'id';
        this.reverseSort = true;

        this.specificUserSelected = function (item, model, label) {
            if (item.basicType === 'user') {
                self.newApiKey.userId = item.id;
            }
        };

        function initNewApiKey() {
            self.newApiKey = {
                name: '',
                userId: '',
            };
        }

        this.getAllAPI = function () {
            self.toggles.loadingApis = true;
            $http
                .post('/admin/getAllAPI')
                .then(
                    function (res) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::getAllAPI Success retrieving keys',
                            res
                        );
                        self.apiKeys = res.data ? res.data.apis : [];
                    },
                    function (error) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::getAllAPI Error retrieving keys',
                            error
                        );
                    }
                )
                .finally(function () {
                    self.toggles.loadingApis = false;
                });
        };

        this.deleteApiKey = function (apiKey) {
            var deferred = $q.defer(),
                messageParams = {};

            messageParams.message = 'You are about to permanently delete this API from Niio.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete API “' + apiKey.name + '”';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        apiKey: apiKey.api_key,
                    };
                    self.toggles.submitWasClicked = true;
                    $http
                        .post('/admin/deleteApi', data)
                        .then(
                            function (res) {
                                $log.debug(
                                    'AdminAreaServicesBatchImportController::deleteApiKey Success removing api key',
                                    res
                                );
                                var messageParams = {};
                                messageParams.message = 'Successfully removed api key.';
                                messageParams.title = 'Remove API Key';
                                // messageParams.disableAutoDismiss = true;
                                Messages.openMessage($rootScope, messageParams);
                                var index = self.apiKeys.indexOf(apiKey);
                                self.apiKeys.splice(index, 1);
                            },
                            function (error) {
                                $log.debug(
                                    'AdminAreaServicesBatchImportController::deleteApiKey Error removing api key',
                                    error
                                );
                                var messageParams = {};
                                messageParams.message = 'Failed to remove api key.\n';
                                messageParams.message += error.data.message.text;
                                messageParams.title = 'Remove API Key';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($rootScope, messageParams);
                            }
                        )
                        .finally(function () {
                            self.toggles.submitWasClicked = false;
                            deferred.resolve();
                        });
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        this.createNewApiKey = function () {
            var data = angular.copy(self.newApiKey);
            self.toggles.submitWasClicked = true;
            $http
                .post('/admin/createNewAPI', data)
                .then(
                    function (res) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::createNewAPI Success creating new api key',
                            res
                        );
                        var messageParams = {};
                        messageParams.message = 'Successfully added api key.';
                        messageParams.title = 'Add API Key';
                        // messageParams.disableAutoDismiss = true;
                        initNewApiKey();
                        self.getAllAPI();
                        Messages.openMessage($rootScope, messageParams);
                    },
                    function (error) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::createNewAPI Error creating new api key',
                            error
                        );
                        var messageParams = {};
                        messageParams.message = 'Failed to add api key.\n';
                        messageParams.message += error.data.message.text;
                        messageParams.title = 'Add API Key';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                )
                .finally(function () {
                    self.toggles.submitWasClicked = false;
                });
        };

        this.init = function () {
            self.submitWasClicked = false;
            initNewApiKey();
            self.getAllAPI();
        };
    },
]);
