'use strict';

// Setting up route
angular.module('profile').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            //.when('/:url', '/:url/main')
            //            .when('/:url/main', '/:url/main/all')
            .when('/:url/vault', '/:url/vault/0/all')
            .when('/:url/portfolio', '/:url/portfolio/0/all')
            .when('/:url/channels', '/:url/channels/curatedChannels')
            .when('/:url/catalogs', '/:url/catalogs/curatedCatalogs');
        // .when('/:url/channels', '/:url/channels/subscriptions');
        //            .when('/profile/vault', 'profile/vault/vault/Shai2')
        //            .when('/profile/channels', 'profile/channels/vault/Shai2');

        // Users state routing
        $stateProvider
            .state('mobile-profile', {
                // In case of mobile - we want to redirect to members so ios will catch it and open in the app itself.
                // For desktop, will be redirected to 'profile' from the HomeController init function.
                url: '/member/:url',
                templateUrl: 'modules/home/views/home.client.view.html',
                controller: 'HomeController',
                resolve: {
                    profile: function () {
                        return {};
                    },
                },
            })

            .state('profile', {
                url: '/:url?reset&code&email',
                //                abstract: true,
                templateUrl: 'modules/home/views/home.client.view.html',
                controller: 'HomeController',
                // data: {
                //     security: {
                //         secured: true
                //     }
                // },
                resolve: {
                    openInApp: [
                        'OpenDeepLink',
                        'NIIO_APP',
                        '$stateParams',
                        function (OpenDeepLink, NIIO_APP, $stateParams) {
                            return OpenDeepLink.func(NIIO_APP + 'member?url=' + $stateParams.url).then(
                                function (res) {
                                    // Successfully opened the link in the app
                                },
                                function (err) {
                                    // Continue with regular web
                                }
                            );
                        },
                    ],
                    profile: [
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$location',
                        '$stateParams',
                        'Messages',
                        'User',
                        function (
                            HomeDataService,
                            $log,
                            $rootScope,
                            $state,
                            $location,
                            $stateParams,
                            Messages,
                            User
                        ) {
                            if (User.getUrl() === $stateParams.url) {
                                $location.path('/home');
                                return false;
                            } else {
                                return HomeDataService.getUserDetailsByUrl($stateParams.url).then(
                                    function (user) {
                                        return user;
                                    },
                                    function (error) {
                                        $log.debug('profile::getUserDetailsByUrl', {
                                            state: $state,
                                            stateParams: $stateParams,
                                            error: error,
                                        });
                                        var messageParams = {};
                                        messageParams.message =
                                            'The url ' +
                                            $rootScope.$platformManager.platform.url +
                                            '/' +
                                            $stateParams.url +
                                            " doesn't exist. redirecting.";
                                        messageParams.disableAutoDismiss = true;
                                        $rootScope.previousState(User.isAuthenticated() ? 'home' : 'signin');
                                        Messages.openMessage($rootScope, messageParams);
                                    }
                                );
                            }
                        },
                    ],
                },
            })

            .state('profile.main-tab', {
                //                url: '/main',
                abstract: true,
                templateUrl: 'modules/home/views/home.main-tab.client.view.html',
                controller: 'HomeMainTabController',
            })

            .state('profile.main-tab.main', {
                //                url: '/:sidebarSelection',
                url: '/main',
                templateUrl: 'modules/home/views/home.main-tab.main.client.view.html',
                controller: 'HomeMainTabMainController',
            })

            .state('profile.about', {
                url: '/about',
                templateUrl: 'modules/home/views/home.about.client.view.html',
            })

            .state('profile.editProfile', {
                url: '/edit_profile',
                templateUrl: 'modules/home/views/home.edit-profile.client.view.html',
                controller: 'HomeEditProfileController',
                resolve: {
                    user: [
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$q',
                        '$stateParams',
                        'Messages',
                        'User',
                        function (
                            HomeDataService,
                            $log,
                            $rootScope,
                            $state,
                            $q,
                            $stateParams,
                            Messages,
                            User
                        ) {
                            var url = $stateParams.url;
                            var deferred = $q.defer();

                            if (url) {
                                HomeDataService.getUserDetailsByUrl(url).then(
                                    function (user) {
                                        $rootScope.$broadcast(
                                            'HomeEditProfileController:profile_changed',
                                            user
                                        );
                                        deferred.resolve(user);
                                    },
                                    function (error) {
                                        $log.debug('home::getUserDetailsByUrl', {
                                            state: $state,
                                            stateParams: $stateParams,
                                            error: error,
                                        });
                                        var messageParams = {};
                                        messageParams.message =
                                            'The url ' +
                                            $rootScope.$platformManager.platform.url +
                                            '/' +
                                            url +
                                            " doesn't exist. redirecting.";
                                        messageParams.disableAutoDismiss = true;
                                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                                            $rootScope.previousState('home');
                                        });
                                        deferred.reject(error);
                                    }
                                );
                            } else {
                                deferred.reject();
                            }

                            return deferred.promise;
                        },
                    ],
                },
            })

            .state('profile.channels', {
                url: '/channels',
                // abstract: true,
                templateUrl: 'modules/home/views/home.channels.client.view.html',
                controller: 'HomeChannelsController',
                data: {
                    security: {
                        secured: true,
                    },
                },
                resolve: {
                    channels: [
                        'profile',
                        'HomeDataService',
                        function (profile, HomeDataService) {
                            return HomeDataService.getChannels(profile.id);
                        },
                    ],
                },
            })

            .state('profile.catalogs', {
                url: '/catalogs',
                // abstract: true,
                templateUrl: 'modules/home/views/home.channels.client.view.html',
                controller: 'HomeChannelsController',
                data: {
                    security: {
                        secured: true,
                    },
                },
                resolve: {
                    channels: [
                        'profile',
                        'HomeDataService',
                        function (profile, HomeDataService) {
                            return HomeDataService.getChannels(profile.id);
                        },
                    ],
                },
            })

            .state('profile.addChannel', {
                url: '/channels/add_channel?channelId',
                templateUrl: 'modules/home/views/home.add-channel.client.view.html',
                controller: 'HomeAddChannelController',
                resolve: {
                    channel: [
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$stateParams',
                        'Messages',
                        function (HomeDataService, $log, $rootScope, $state, $stateParams, Messages) {
                            return HomeDataService.getChannelData(
                                $stateParams.channelId,
                                true /* isEdit */
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $log.debug('home.addChannel::', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason: reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message =
                                        'The channel is not valid, redirecting to your channels.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                    isAllowed: [
                        'channel',
                        'HomeDataService',
                        'User',
                        '$state',
                        function (channel, HomeDataService, User, $state) {
                            var result = false;
                            if (
                                channel &&
                                channel.creator.user_id &&
                                User.getId() !== channel.creator.user_id &&
                                !User.isAdmin()
                            ) {
                                $state.go('profile.channels.main', { channelsName: 'all' });
                            } else {
                                result = true;
                            }
                            return result;
                        },
                    ],
                },
            })

            .state('profile.channels.main', {
                url: '/:channelsName',
                templateUrl: 'modules/home/views/home.channels.main.client.view.html',
                controller: 'HomeChannelsMainController',
                resolve: {
                    displayChannels: [
                        'channels',
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$stateParams',
                        'Messages',
                        function (
                            channels,
                            HomeDataService,
                            $log,
                            $rootScope,
                            $state,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.getChannelsToDisplay(
                                channels,
                                $stateParams.channelsName
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $log.debug('profile.channels.main::getChannelsToDisplay', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason: reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                },
            })

            .state('profile.catalogs.main', {
                url: '/:channelsName',
                templateUrl: 'modules/home/views/home.channels.main.client.view.html',
                controller: 'HomeChannelsMainController',
                resolve: {
                    displayChannels: [
                        'channels',
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$stateParams',
                        'Messages',
                        function (
                            channels,
                            HomeDataService,
                            $log,
                            $rootScope,
                            $state,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.getChannelsToDisplay(
                                channels,
                                $stateParams.channelsName
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $log.debug('profile.catalogs.main::getChannelsToDisplay', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason: reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                },
            })

            .state('profile.channels.channel', {
                url: '/:channelsName/:channelUrlName?share&invitationCode',
                templateUrl: 'modules/channel-info/views/channel-info.channel.client.view.html',
                controller: 'ChannelInfoChannelController',
                controllerAs: 'vm',
            })

            .state('profile.catalogs.channel', {
                url: '/:channelsName/:channelUrlName?share&invitationCode',
                templateUrl: 'modules/channel-info/views/channel-info.channel.client.view.html',
                controller: 'ChannelInfoChannelController',
                controllerAs: 'vm',
            })

            .state('profile.vault', {
                url: '/vault',
                templateUrl: 'modules/home/views/home.collections.client.view.html',
                controller: 'HomeCollectionsController',
                abstract: true,
                data: {
                    security: {
                        secured: true,
                    },
                    state: 'vault',
                },
                resolve: {
                    collections: [
                        'profile',
                        'HomeDataService',
                        function (profile, HomeDataService) {
                            return HomeDataService.getVaultCollections(profile.id);
                        },
                    ],
                },
            })

            .state('profile.vault.main', {
                url: '/:collectionId/:collectionLink',
                templateUrl: 'modules/home/views/home.collections.main.client.view.html',
                controller: 'HomeCollectionsMainController',
                params: {
                    filterArtist: null,
                },
                resolve: {
                    artworks: [
                        'profile',
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            profile,
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.getCollectionArtworks(
                                collections,
                                $stateParams.collectionId,
                                $stateParams.collectionLink,
                                false,
                                profile.id
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $log.debug('profile.vault.main::getCollectionArtworks', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason: reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                },
            })

            .state('profile.vault.artwork', {
                url: '/:collectionId/:collectionLink/:artworkId?share',
                templateUrl: 'modules/artwork-info/views/artwork-info.artwork.client.view.html',
                controller: 'ArtworkInfoArtworkController',
                controllerAs: 'vm',
                resolve: {
                    isValidLink: [
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.redirectIfDesiredLinkIsNotInAllLinks(
                                collections,
                                $stateParams.collectionId,
                                $stateParams.collectionLink
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $log.debug(
                                        'profile.vault.artwork::redirectIfDesiredLinkIsNotInAllLinks',
                                        { state: $state, stateParams: $stateParams, reason: reason }
                                    );
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                    return false;
                                }
                            );
                        },
                    ],
                },
            })

            .state('profile.portfolio', {
                url: '/portfolio',
                templateUrl: 'modules/home/views/home.collections.client.view.html',
                controller: 'HomeCollectionsController',
                abstract: true,
                data: {
                    security: {
                        secured: true,
                    },
                    state: 'portfolio',
                },
                resolve: {
                    collections: [
                        'profile',
                        'HomeDataService',
                        function (profile, HomeDataService) {
                            return HomeDataService.getPortfolioCollections(profile.id);
                        },
                    ],
                },
            })

            .state('profile.portfolio.main', {
                url: '/:collectionId/:collectionLink',
                templateUrl: 'modules/home/views/home.collections.main.client.view.html',
                controller: 'HomeCollectionsMainController',
                params: {
                    filterArtist: null,
                },
                resolve: {
                    artworks: [
                        'profile',
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            profile,
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.getCollectionArtworks(
                                collections,
                                $stateParams.collectionId,
                                $stateParams.collectionLink,
                                false,
                                profile.id
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $log.debug('profile.portfolio.main::artworks::getCollectionArtworks', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason: reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                },
            })

            .state('profile.portfolioUpload', {
                url: '/portfolio/upload?artworkId&collectionId&filterArtist',
                templateUrl: 'modules/home/views/home.upload.client.view.html',
                controller: 'HomeUploadController',
                data: {
                    from: 'portfolio',
                },
                resolve: {
                    uploadSettings: [
                        'HomeDataService',
                        '$state',
                        '$stateParams',
                        function (HomeDataService, $state, $stateParams) {
                            return HomeDataService.getUploadSettings(
                                $stateParams.artworkId,
                                $stateParams.collectionId
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $state.go('profile.channels.main', { menu: 'all' });
                                }
                            );
                        },
                    ],
                    isAllowed: [
                        'uploadSettings',
                        'HomeDataService',
                        'User',
                        '$state',
                        '$stateParams',
                        function (uploadSettings, HomeDataService, User, $state, $stateParams) {
                            var result = false;
                            if (
                                uploadSettings &&
                                uploadSettings.artworkUserId &&
                                User.$storage.userDetails.id !== uploadSettings.artworkUserId &&
                                !User.isAdmin() &&
                                !uploadSettings.is_consignee
                            ) {
                                $state.go('home.portfolio.main', { collectionId: 0, collectionLink: 'all' });
                            } else {
                                if (uploadSettings.is_consignee) {
                                    $state.go('home.portfolioUpload', $stateParams);
                                    return false;
                                } else {
                                    result = true;
                                }
                            }
                            return result;
                        },
                    ],
                },
            })

            .state('profile.portfolioUpload.basic', {
                //                url: '/basic',
                templateUrl: 'modules/home/views/home.upload.basic.client.view.html',
            })

            .state('profile.portfolioUpload.advanced', {
                //                url: '/advanced',
                templateUrl: 'modules/home/views/home.upload.advanced.client.view.html',
            })

            .state('profile.vaultUpload', {
                url: '/vault/upload?artworkId&collectionId&filterArtist',
                templateUrl: 'modules/home/views/home.upload.client.view.html',
                controller: 'HomeUploadController',
                data: {
                    from: 'vault',
                },
                resolve: {
                    uploadSettings: [
                        'HomeDataService',
                        '$state',
                        '$stateParams',
                        function (HomeDataService, $state, $stateParams) {
                            return HomeDataService.getUploadSettings(
                                $stateParams.artworkId,
                                $stateParams.collectionId
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $state.go('profile.channels.main', { menu: 'all' });
                                }
                            );
                        },
                    ],
                    isAllowed: [
                        'uploadSettings',
                        'HomeDataService',
                        'User',
                        '$state',
                        function (uploadSettings, HomeDataService, User, $state) {
                            var result = false;
                            if (
                                uploadSettings &&
                                uploadSettings.artworkUserId &&
                                User.$storage.userDetails.id !== uploadSettings.artworkUserId &&
                                !User.isAdmin()
                            ) {
                                $state.go('profile.vault.main', { collectionId: 0, collectionLink: 'all' });
                            } else {
                                result = true;
                            }
                            return result;
                        },
                    ],
                },
            })

            .state('profile.vaultUpload.basic', {
                //                url: '/basic',
                templateUrl: 'modules/home/views/home.upload.basic.client.view.html',
            })

            .state('profile.vaultUpload.advanced', {
                //                url: '/advanced',
                templateUrl: 'modules/home/views/home.upload.advanced.client.view.html',
            })

            .state('profile.portfolio.artwork', {
                url: '/:collectionId/:collectionLink/:artworkId?share',
                templateUrl: 'modules/artwork-info/views/artwork-info.artwork.client.view.html',
                controller: 'ArtworkInfoArtworkController',
                controllerAs: 'vm',
                // templateUrl: 'modules/home/views/home.artwork.client.view.html',
                // controller: 'HomeArtworkController',
                resolve: {
                    isValidLink: [
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.redirectIfDesiredLinkIsNotInAllLinks(
                                collections,
                                $stateParams.collectionId,
                                $stateParams.collectionLink
                            ).then(
                                function (value) {
                                    return value;
                                },
                                function (reason) {
                                    $log.debug(
                                        'profile.portfolio.artwork::isValidLink::redirectIfDesiredLinkIsNotInAllLinks',
                                        { state: $state, stateParams: $stateParams, reason: reason }
                                    );
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                    return false;
                                }
                            );
                        },
                    ],
                },
            })

            .state('profile.events', {
                url: '/events',
                templateUrl: 'modules/home/views/home.events.client.view.html',
                controller: 'HomeEventsController',
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('profile.addEvent', {
                url: '/events/add_event?eventId',
                templateUrl: 'modules/home/views/home.add-event.client.view.html',
                controller: 'HomeAddEventController',
                // resolve: {
                //     channel: ['HomeDataService', '$log', '$rootScope', '$state', '$stateParams', '$previousState', 'Messages', function (HomeDataService, $log, $rootScope, $state, $stateParams, $previousState, Messages) {
                //         return HomeDataService.getChannelData($stateParams.channelId, true /* isEdit */)
                //             .then(function (value) {
                //                 return value;
                //             }, function (reason) {
                //                 $log.debug('home.addChannel::', {state: $state, stateParams: $stateParams, reason: reason});
                //                 var messageParams = {};
                //                 messageParams.message = 'The channel is not valid, redirecting to your channels.';
                //                 messageParams.disableAutoDismiss = true;
                //                 Messages.openMessage($rootScope, messageParams)
                //                     .then(function (res) {
                //                         $previousState.go();
                //                     });
                //             });
                //     }],
                //     isAllowed: ['channel', 'HomeDataService', 'User', '$state', function (channel, HomeDataService, User, $state) {
                //         var result = false;
                //         if (channel && channel.creator.user_id && User.getId() !== channel.creator.user_id) {
                //             $state.go('home.channels.main', { channelsName: 'all' });
                //         } else {
                //             result = true;
                //         }
                //         return result;
                //     }]
                // }
            })

            .state('profile.events.host', {
                url: '/host/:eventId',
                templateUrl: 'modules/home/views/home.events.host.client.view.html',
                controller: 'HomeEventsHostController',
                controllerAs: 'vm',
            })

            .state('profile.events.submission', {
                url: '/submission/:eventId',
                templateUrl: 'modules/home/views/home.events.submission.client.view.html',
                controller: 'HomeEventsSubmissionController',
                controllerAs: 'vm',
            })

            .state('profile.events.submission.instructions', {
                url: '/instructions',
                templateUrl: 'modules/home/views/home.events.submission.instructions.client.view.html',
            })

            .state('profile.events.submission.profile', {
                url: '/profile',
                templateUrl: 'modules/home/views/home.edit-profile.client.view.html',
                controller: 'HomeEditProfileController',
                resolve: {
                    user: [
                        '$rootScope',
                        function ($rootScope) {
                            return $rootScope.profile;
                        },
                    ],
                },
            })

            .state('profile.events.submission.form', {
                url: '/form',
                templateUrl: 'modules/home/views/home.events.submission.form.client.view.html',
                controller: 'HomeEventsSubmissionFormController',
                controllerAs: 'vm',
            })

            .state('profile.events.submission.artworks', {
                url: '/artworks',
                templateUrl: 'modules/home/views/home.events.submission.artworks.client.view.html',
                controller: 'HomeEventsSubmissionArtworksController',
                controllerAs: 'vm',
            })

            .state('profile.events.submission.summary', {
                url: '/summary',
                templateUrl: 'modules/home/views/home.events.submission.summary.client.view.html',
                controller: 'HomeEventsSubmissionSummaryController',
                controllerAs: 'vm',
            })

            .state('profile.error', {
                url: '/{path:.*}',
                controller: function ($log, $rootScope, $state, $stateParams, Messages) {
                    $log.debug('profile.error::', { state: $state, stateParams: $stateParams });

                    if (
                        $stateParams !== undefined &&
                        $stateParams.path !== undefined &&
                        $stateParams.path === ''
                    ) {
                        $state.go('profile', $stateParams);
                        return;
                    }

                    var messageParams = {};
                    messageParams.message =
                        'The URL ' +
                        $rootScope.$platformManager.platform.url +
                        '/home/' +
                        $stateParams.path +
                        ' is not valid, redirecting.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        $state.go('home');
                    });
                },
            });
    },
]);
