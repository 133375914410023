'use strict';

angular.module('core').filter('channelname', [
    'CHANNEL_TYPES',
    function (CHANNEL_TYPES) {
        return function (channel) {
            if (
                channel === undefined ||
                channel === null ||
                channel.id === undefined ||
                channel.name === undefined
            ) {
                return '';
            }
            if (channel.type === undefined || channel.type === CHANNEL_TYPES.ARTCAST) {
                return channel.name + ' Artcast';
            }
            if (channel.type === CHANNEL_TYPES.CATALOG) {
                return channel.name + ' Catalog';
            }
            return channel.name;
        };
    },
]);
