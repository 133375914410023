'use strict';

angular.module('discover').controller('DiscoverController', [
    '$rootScope',
    '$scope',
    '$state',
    '$location',
    '$timeout',
    'User',
    function ($rootScope, $scope, $state, $location, $timeout, User) {
        //        console.log('Checking security frdiscoverhome.js -> Security.status wrapper');
        //        Security.status(function () {
        function redirectToFirstTab(fromState, toState) {
            var result = false;
            if (fromState.url !== '/' + $scope.pages[0].link) {
                if ($location.path() === '/discover' || $location.path() === '/discover/') {
                    $location.path('discover/' + $scope.pages[0].link);
                    result = true;
                }
            }
            return result;
        }

        function initPages() {
            $scope.pages = [];

            if ($scope.isAdmin || $scope.isProCurator) {
                $scope.pages.push({ link: 'main', name: 'DISCOVER.MAIN' });
            }

            $scope.pages.push({ link: 'for-you', name: 'DISCOVER.FOR_YOU' });
            $scope.pages.push({ link: 'artworks', name: 'DISCOVER.ARTWORKS' });
            $scope.pages.push({ link: 'channels', name: 'DISCOVER.CHANNELS' });
            $scope.pages.push({ link: 'catalogs', name: 'DISCOVER.CATALOGS' });

            if ($scope.isAdmin || $scope.isProCurator) {
                $scope.pages.push({ link: 'artists', name: 'DISCOVER.ARTISTS' });
            }
            $scope.pages.push({ link: 'members', name: 'DISCOVER.MEMBERS' });
        }

        $scope.init = function () {
            $scope.isAdmin = User.isAdmin();
            $scope.isProCurator = User.isProCurator();
            initPages();
            $scope.initItemsToLoad();
            $scope.lastScroll = null;
            $scope.lastScrollItemNumber = null;
        };

        $scope.initItemsToLoad = function () {
            $rootScope.discoverItemsToLoad = 20;
        };

        $scope.$state = $state;
        $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if ((toState.name && toState.name === 'discover') || toState === 'discover') {
                redirectToFirstTab(fromState, toState);
            }
        });

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            if (
                fromState.name === 'discover.catalogs.main' ||
                fromState.name === 'discover.channels.main' ||
                fromState.name === 'discover.main-tab.main' ||
                fromState.name === 'discover.artworks.main'
            ) {
                $scope.lastScroll = document.documentElement.scrollTop;
            }

            if (fromState.name === 'discover.members.main') {
                $rootScope.lastMembersScroll = document.documentElement.scrollTop;
            }

            if (fromState.name === 'discover.artists.main') {
                $rootScope.lastArtistsScroll = document.documentElement.scrollTop;
            }

            if (toState.name.indexOf('discover.members') > -1 && fromState.name.indexOf('profile') < 0) {
                $rootScope.lastMembersScroll = null;
            }

            if (toState.name.indexOf('discover.artists') > -1 && fromState.name.indexOf('profile') < 0) {
                $rootScope.lastArtistsScroll = null;
            }

            var fromSplit = fromState.name.split('.');
            var toSplit = toState.name.split('.');
            if (fromSplit[1] !== toSplit[1]) {
                $scope.lastScroll = null;
            }
        });

        function scrollToLast(scroll) {
            if (scroll) {
                $timeout(function () {
                    window.scrollTo(0, scroll);
                }, 10);
            }
        }

        $scope.$on('DiscoverArtistsMainController:artists_refreshed', function () {
            scrollToLast($rootScope.lastArtistsScroll);
        });

        $scope.$on('DiscoverArtworksMainController:artworks_refreshed', function () {
            scrollToLast($scope.lastScroll);
            $scope.lastScroll = null;
        });

        $scope.$on('DiscoverChannelsMainController:channels_refreshed', function () {
            scrollToLast($scope.lastScroll);
            $scope.lastScroll = null;
        });

        $scope.$on('DiscoverMainTabMainController:channels_refreshed', function () {
            scrollToLast($scope.lastScroll);
            $scope.lastScroll = null;
        });

        $scope.$on('DiscoverMembersMainController:members_refreshed', function () {
            scrollToLast($rootScope.lastMembersScroll);
            $scope.lastScroll = null;
        });
    },
]);
