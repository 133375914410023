'use strict';

angular.module('home').controller('HomeEventsSubmissionArtworksController', [
    '$rootScope',
    '$filter',
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$timeout',
    '$http',
    '_',
    'smoothScroll',
    'HomeDataService',
    'FormValidationService',
    'Messages',
    'OpenPopupForm',
    function (
        $rootScope,
        $filter,
        $scope,
        $state,
        $stateParams,
        $log,
        $q,
        $timeout,
        $http,
        _,
        smoothScroll,
        HomeDataService,
        FormValidationService,
        Messages,
        OpenPopupForm
    ) {
        $scope.HomeDataService = HomeDataService;
        $scope.FormValidationService = FormValidationService;
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.toggles = {};

        function markSelectedArtworks(portfolioArtworks, selectedArtworks) {
            _.forEach(selectedArtworks, function (selectedArtwork) {
                var matchPortfolioArtwork = _.find(portfolioArtworks, function (portfolioArtwork) {
                    return portfolioArtwork.id === selectedArtwork.id;
                });

                if (matchPortfolioArtwork) {
                    matchPortfolioArtwork.selected = true;
                }
            });
        }

        function getAllCheckedArtworks(portfolioArtworks) {
            var checkedArtworks = [];

            portfolioArtworks.forEach(function (portfolioArtwork) {
                if (portfolioArtwork.selected) {
                    checkedArtworks.push(portfolioArtwork);
                }
            });

            return checkedArtworks;
        }

        $scope.init = function () {
            if ($scope.currentEvent) {
                $scope.loadingArtworks = true;

                // Get portfolio artworks
                HomeDataService.fetchPortfolioArtworks(null, $scope.userId, false)
                    .then(function (res) {
                        $scope.portfolioArtworks = res.data.data.artworks;
                        $scope.selectedArtworks = $scope.currentEvent.artworks;
                        $scope.portfolioArtworks = _.forEach($scope.portfolioArtworks, function (artwork) {
                            artwork.isMultiActionsEnable = true;
                            artwork.numericFileSize =
                                artwork.file_metadata.size && artwork.file_metadata.size
                                    ? parseInt(artwork.file_metadata.size)
                                    : 0;
                        });
                        markSelectedArtworks($scope.portfolioArtworks, $scope.selectedArtworks);
                    })
                    .finally(function () {
                        $scope.loadingArtworks = false;
                    });
            }
        };

        $scope.$on('reinitiazlie_step', function (event) {
            $scope.init();
        });

        $scope.$on('batch_files_upload_started', function (event) {
            $scope.init();
        });

        $scope.onArtworkSelectedChanged = function (artwork) {
            artwork.isChecked = !artwork.isChecked;
            selectAllSync();
        };

        var validateSelectedArtworksWereModified = function (selectedArtworks) {
            var notModifiedAwIds = [];
            var result = null;

            _.forEach(selectedArtworks, function (artwork) {
                if (artwork.title === artwork.original_work_filename && !artwork.description) {
                    notModifiedAwIds.push(artwork.id);
                }
            });

            if (notModifiedAwIds.length > 0) {
                result = notModifiedAwIds;
            }

            return result;
        };

        function update() {
            var data = {
                selectedArtworks: $scope.currentEvent.artworks,
                eventId: $scope.currentEvent.event.id,
                userId: $scope.userId,
            };

            $scope.submitWasClicked = true;

            $http
                .post('/events/saveSelectedArtworks', data)
                .then(
                    function (res) {
                        $log.debug(
                            'HomeEventsSubmissionFormController::updateSelectedArtworks Success',
                            res,
                            data
                        );
                        var messageParams = {};
                        messageParams.title = 'Successfully Selected Artworks';
                        messageParams.message =
                            'The artworks were successfully selected, please continue to the next step.';
                        Messages.openMessage($scope, messageParams).then(function (res) {
                            $scope.$emit('submission_step_completed');
                        });
                    },
                    function (error) {
                        $log.debug(
                            'HomeEventsSubmissionFormController::updateSelectedArtworks Failed',
                            error,
                            data
                        );
                        var messageParams = {};
                        messageParams.title = 'Failed to Select Artworks';
                        messageParams.message =
                            'There was a problem saving the selected artworks. Please contact support (press OK to continue).';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams).then(function (res) {
                            OpenPopupForm.openContact(
                                'support',
                                null,
                                'event submission artwork selection failed (event id: ' +
                                    $stateParams.eventId +
                                    ')'
                            );
                        });
                    }
                )
                .finally(function () {
                    $scope.submitWasClicked = false;
                });
        }

        $scope.updateSelectedArtworks = function () {
            var messageParams;
            $scope.currentEvent.artworks = getAllCheckedArtworks($scope.portfolioArtworks);

            if (!$scope.currentEvent.artworks || $scope.currentEvent.artworks.length === 0) {
                messageParams = {};
                messageParams.title = 'Must Select Artworks';
                messageParams.message =
                    'In order to complete this step you must select at least 1 artwork. Please select at least 1 artwork and continue.';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            } else if (
                $scope.currentEvent.event.max_allowed_artworks &&
                $scope.currentEvent.artworks.length > parseInt($scope.currentEvent.event.max_allowed_artworks)
            ) {
                messageParams = {};
                messageParams.title =
                    'Maximum ' + $scope.currentEvent.event.max_allowed_artworks + ' Artworks Allowed';
                messageParams.message =
                    'More than the maximum artworks allowed have been selected. The maximum allowed is ' +
                    $scope.currentEvent.event.max_allowed_artworks +
                    '. Please uncheck some artworks and try again.';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            } else {
                if (validateSelectedArtworksWereModified($scope.currentEvent.artworks)) {
                    messageParams = {};
                    messageParams.title = 'Artworks Metadata';
                    messageParams.message =
                        'It seems like you did not edit some of the artworks\' metadata.\nClick "Cancel" to return to the artworks list view and edit the metadata or continue without edition your works.';
                    messageParams.disableAutoDismiss = true;
                    messageParams.cancelText = 'Cancel';
                    messageParams.okText = 'Save & Continue';
                    Messages.openMessage($scope, messageParams).then(function (res) {
                        if (res === 'ok') {
                            update();
                        }
                    });
                } else {
                    update();
                }
            }
        };

        function selectAllSync() {
            var selected = 0;
            for (var i = 0; i < $scope.portfolioArtworks.length; i++) {
                if ($scope.portfolioArtworks[i].selected) {
                    selected++;
                }
            }

            $scope.toggles.selectAllAWs = selected === $scope.portfolioArtworks.length;
        }

        $scope.selectAllToggle = function (newVal) {
            for (var i = 0; i < $scope.portfolioArtworks.length; i++) {
                $scope.portfolioArtworks[i].selected = newVal;
            }

            $scope.toggles.selectAllAWs = newVal;
        };

        $scope.deleteArtwork = function () {
            var messageParams = {};
            messageParams.message = 'If you want to delete the artwork, please do so from your prortfolio.';
            messageParams.title = 'Delete Artwork';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($scope, messageParams);
        };
    },
]);
