'use strict';

angular.module('devices').directive('screenRotatePreview', function () {
    return {
        restrict: 'E',
        scope: {
            rotate: '=',
            coverRotate: '=',
            sampletext: '=',
        },
        link: function (scope, element, attrs) {},
        template:
            '<div id="box" style="border:1px solid black;border-bottom-width:10px;background:white;width:160px;height:90px;display:flex;transform:rotate(-{{rotate!==-1?rotate:0}}deg);padding:0;margin:0;">' +
            '<div id="sidebar" style="border:1px solid black;background:#adadad;height:100%;width:25%;padding:0;margin:0;font-size: 0.75em;">1<br>2<br>3</div>' +
            '<div id="playview" style="height:100%;width:75%;transform: rotate({{rotate!==-1?rotate:0}}deg) rotate({{coverRotate}}deg);padding:0;margin:0;text-align: center;vertical-align: middle;">COVER' +
            '</div></div>',
    };
});
