'use strict';

angular.module('core').service('TagsService', [
    function () {
        var self = this;
        this.updateTags = function (tags) {
            for (let i = 0; i < tags.length; i++) {
                // Replace spaces with underscores
                tags[i].text = tags[i].text.replace(/\s+/g, '_');

                // Remove special characters, allow letters and numbers of English only
                tags[i].text = tags[i].text.replace(/[^a-zA-Z0-9_]/gu, '');

                // Trim tag text to ensure its length is between 2 and 40 characters
                tags[i].text = tags[i].text.substring(0, 40);
            }

            // Remove duplicates by text
            let i = 0;
            while (i < tags.length) {
                const index = tags.findIndex((t, idx) => t.text === tags[i].text && idx !== i);
                if (index !== -1) {
                    tags.splice(index, 1); // Remove the duplicate
                } else {
                    i++; // Move to the next element if no duplicate was found
                }
            }
        };
    },
]);
