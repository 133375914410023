'use strict';

angular.module('core').filter('organizationTypeName', [
    'ORGANIZATION_TYPES',
    function (ORGANIZATION_TYPES) {
        return function (input, organizationType, organizationTypeOther) {
            var result = input;
            if (organizationType === 'OT') {
                result = organizationTypeOther;
            } else {
                result = ORGANIZATION_TYPES[organizationType];
            }

            return result;
        };
    },
]);
