'use strict';

angular.module('devices').service('DevicesDataService', [
    '$log',
    '$http',
    '$q',
    '$rootScope',
    '$interval',
    '$filter',
    '_',
    'LocalConfig',
    'PushHandlerService',
    function ($log, $http, $q, $rootScope, $interval, $filter, _, LocalConfig, PushHandlerService) {
        var self = this;
        self.LocalConfig = LocalConfig;

        this.getDevicesAndGroupsByUser = function (userId) {
            var data = {
                userId: userId,
                includeAppleTVDevices: true,
            };

            return $http.post('/devices/getDevicesAndGroupsByUser', data);
        };

        function formatLastAccessed(device) {
            if (device.last_accessed) {
                var today = new Date();
                // Taken from here: http://stackoverflow.com/questions/5324178/javascript-date-parsing-on-iphone
                var lastAccessedTemp = device.last_accessed.split(/[- :]/);
                lastAccessedTemp = new Date(
                    lastAccessedTemp[0],
                    lastAccessedTemp[1] - 1,
                    lastAccessedTemp[2],
                    lastAccessedTemp[3],
                    lastAccessedTemp[4],
                    lastAccessedTemp[5]
                );
                lastAccessedTemp.setMinutes(lastAccessedTemp.getMinutes() - today.getTimezoneOffset());
                device.last_accessed = lastAccessedTemp;
            }
        }

        this.getDevicesAndGroups = function (userId) {
            var deferred = $q.defer(),
                devicesAndGroups = {};

            self.getDevicesAndGroupsByUser(userId).then(
                function (res) {
                    $log.debug('DevicesDataService::getDevicesAndGroups::getDevicesAndGroupsByUser Success', res, {
                        userId: userId,
                    });

                    res.data.data.devices.forEach(function (group) {
                        group.devices.forEach(function (device) {
                            device.groupId = group.id;
                            device.groupName = group.name;
                            formatLastAccessed(device);
                        });
                    });
                    devicesAndGroups.user = res.data.data.user;
                    devicesAndGroups.groups = res.data.data.devices;
                    self.getDevicesFromGroups(devicesAndGroups.groups).then(function (res) {
                        devicesAndGroups.devices = res;
                        deferred.resolve(devicesAndGroups);
                    });
                },
                function (error) {
                    $log.debug('DevicesDataService::getDevicesAndGroups::getDevicesAndGroupsByUser Failure', error, {
                        userId: userId,
                    });
                }
            );

            return deferred.promise;
        };

        this.getDevicesFromGroups = function (groups) {
            var deferred = $q.defer();

            var devices = [];
            var devicesGroup = _.find(groups, function (group) {
                return group.id === '0';
            });
            if (devicesGroup) {
                devices = devicesGroup.devices;
            }
            deferred.resolve(devices);

            return deferred.promise;
        };

        // GROUPS
        function getSettingsFromServer(deviceOrGroup, id) {
            var data = {},
                promise;

            if (deviceOrGroup === 'device') {
                data.deviceId = id;
                promise = $http.post('/devices/getDeviceSettings', data);
            } else if (deviceOrGroup === 'group') {
                data.groupId = id;
                promise = $http.post('/devices/getGroupSettings', data);
            }

            return promise;
        }

        function isValidDate(i_date) {
            var result;
            if (Object.prototype.toString.call(i_date) !== '[object Date]') {
                result = false;
            } else {
                result = !isNaN(i_date.getTime());
            }
            return result;
        }

        function createFakeDateFromTime(i_hours, i_minutes) {
            var date = new Date(1970, 0, 1, i_hours, i_minutes, 0);
            var result = '';
            if (isValidDate(date)) {
                result = date;
            }
            return result;
        }

        function processSchedule(scheduleArray, fromServer) {
            var time;
            _.each(scheduleArray, function (schedule) {
                if (fromServer) {
                    var startTime = createFakeDateFromTime(schedule.StartHour, schedule.StartMinute);
                    var endTime = createFakeDateFromTime(schedule.EndHour, schedule.EndMinute);
                    if (startTime && endTime) {
                        //schedule.StartTime = $filter('date')(startTime, 'HH:mm');
                        schedule.StartTime = startTime;
                        //schedule.EndTime = $filter('date')(endTime, 'HH:mm');
                        schedule.EndTime = endTime;
                    } else {
                        //schedule.StartTime = new Date();
                        //schedule.EndTime = new Date();
                        $log.debug(
                            'There was a problem parsing date from time',
                            schedule.StartHour,
                            schedule.StartMinute,
                            schedule.EndHour,
                            schedule.EndMinute
                        );
                    }
                } else {
                    // To Server
                    schedule.StartHour = $filter('date')(schedule.StartTime, 'HH');
                    //schedule.StartHour = schedule.StartTime.split(':')[0];
                    //schedule.StartMinute = schedule.StartTime.split(':')[1];
                    schedule.StartMinute = $filter('date')(schedule.StartTime, 'mm');
                    schedule.EndHour = $filter('date')(schedule.EndTime, 'HH');
                    //schedule.EndHour = schedule.EndTime.split(':')[0];
                    //schedule.EndMinute = schedule.EndTime.split(':')[1];
                    schedule.EndMinute = $filter('date')(schedule.EndTime, 'mm');
                }
            });
        }

        function processMaintenanceSchedule(actionArray, fromServer) {
            var time;
            _.each(actionArray, function (schedule) {
                if (fromServer) {
                    var startTime = createFakeDateFromTime(schedule.StartHour, schedule.StartMinute);
                    if (startTime) {
                        //schedule.StartTime = $filter('date')(startTime, 'HH:mm');
                        schedule.StartTime = startTime;
                    } else {
                        $log.debug(
                            'There was a problem parsing date from time',
                            schedule.StartHour,
                            schedule.StartMinute
                        );
                    }
                } else {
                    // To Server
                    schedule.StartHour = $filter('date')(schedule.StartTime, 'HH');
                    //schedule.StartHour = schedule.StartTime.split(':')[0];
                    //schedule.StartMinute = schedule.StartTime.split(':')[1];
                    schedule.StartMinute = $filter('date')(schedule.StartTime, 'mm');
                    schedule.EndHour = '00';
                    schedule.EndMinute = '00';
                }
            });
        }

        function addRemoveHashForColors(color, addRemoveHash) {
            var result = color;

            if (color) {
                if (addRemoveHash === 'removeHash' && color.substr(0, 1) === '#') {
                    result = color.substr(1, 6);
                } else if (addRemoveHash === 'addHash' && color.substr(0, 1) !== '#') {
                    result = '#' + color;
                }
            }

            return result;
        }

        function processSettings(settings, addRemoveHash) {
            if (settings && settings.Appearance) {
                if (settings.Appearance.ChannelCover && settings.Appearance.ChannelCover.Color) {
                    settings.Appearance.ChannelCover.Color = addRemoveHashForColors(
                        settings.Appearance.ChannelCover.Color,
                        addRemoveHash
                    );
                }

                if (settings.Appearance.ChannelCover && settings.Appearance.ChannelCover.BGColor) {
                    settings.Appearance.ChannelCover.BGColor = addRemoveHashForColors(
                        settings.Appearance.ChannelCover.BGColor,
                        addRemoveHash
                    );
                }

                if (settings.Appearance.ArtworkCover && settings.Appearance.ArtworkCover.Color) {
                    settings.Appearance.ArtworkCover.Color = addRemoveHashForColors(
                        settings.Appearance.ArtworkCover.Color,
                        addRemoveHash
                    );
                }

                if (settings.Appearance.ArtworkCover && settings.Appearance.ArtworkCover.BGColor) {
                    settings.Appearance.ArtworkCover.BGColor = addRemoveHashForColors(
                        settings.Appearance.ArtworkCover.BGColor,
                        addRemoveHash
                    );
                }

                if (settings.Appearance.ArtistBioCover && settings.Appearance.ArtistBioCover.Color) {
                    settings.Appearance.ArtistBioCover.Color = addRemoveHashForColors(
                        settings.Appearance.ArtistBioCover.Color,
                        addRemoveHash
                    );
                }

                if (settings.Appearance.ArtistBioCover && settings.Appearance.ArtistBioCover.BGColor) {
                    settings.Appearance.ArtistBioCover.BGColor = addRemoveHashForColors(
                        settings.Appearance.ArtistBioCover.BGColor,
                        addRemoveHash
                    );
                }

                if (settings.Appearance.ArtworkMatting && settings.Appearance.ArtworkMatting.Color) {
                    settings.Appearance.ArtworkMatting.Color = addRemoveHashForColors(
                        settings.Appearance.ArtworkMatting.Color,
                        addRemoveHash
                    );
                }

                var fromServerBool = addRemoveHash === 'addHash';
                processSchedule(settings.Schedule, fromServerBool);
                processMaintenanceSchedule(settings.MaintenanceSchedule, fromServerBool);
            }
        }

        this.getDeviceArtworksProfiles = function (id) {
            var data = { deviceId: id };
            return $http.post('/devices/getDeviceArtworksProfiles', data);
        };

        this.getSettings = function (deviceOrGroup, id) {
            var deferred = $q.defer();

            if (id) {
                getSettingsFromServer(deviceOrGroup, id).then(
                    function (res) {
                        $log.debug('DevicesDataService::getSettings::getSettingsFromServer Success', res);
                        var settings = res.data.settings;
                        processSettings(settings, 'addHash');
                        deferred.resolve(settings);
                    },
                    function (error) {
                        $log.debug('DevicesDataService::getSettings::getSettingsFromServer Failure', error);
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.reject("id can't be empty");
            }

            return deferred.promise;
        };

        this.getDefaultSettings = function () {
            var deferred = $q.defer();

            $http.get('/devices/getDefaultDeviceSettings').then(
                function (res) {
                    $log.debug('DevicesDataService::getDefaultSettings Success', res);
                    var settings = res.data.defaultDeviceSettings;
                    processSettings(settings, 'addHash');
                    deferred.resolve(settings);
                },
                function (error) {
                    $log.debug('DevicesDataService::getDefaultSettings Failure', error);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.updateDefaultSettings = function (settings, applyAll) {
            var deferred = $q.defer();

            var settingsCopy = JSON.parse(JSON.stringify(settings));
            processSettings(settingsCopy, 'removeHash');

            var data = {
                settings: settingsCopy,
                applyAll: applyAll,
            };

            $http.post('/devices/saveDefaultDeviceSettings', data).then(
                function (res) {
                    deferred.resolve(res.data);
                },
                function (error) {
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        // Extend Date to add invalid date check

        function getGroupContent(groupId) {
            var deferred = $q.defer(),
                data = {
                    groupId: groupId,
                };

            $http.post('/devices/getGroupContent?load=' + self.LocalConfig.get('loadLevelForDevice', 2), data).then(
                function (res) {
                    $log.debug('DevicesDataService::getGroupContent Success', res, data);
                    deferred.resolve(res.data.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::getGroupContent Failure', error, data);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getDeviceContent(deviceId, getQueueData) {
            if (getQueueData === undefined) {
                getQueueData = true;
            }
            let deferred = $q.defer(),
                data = {
                    deviceId: deviceId,
                    getQueueData,
                };

            $http.post('/devices/getDeviceContent?load=' + self.LocalConfig.get('loadLevelForDevice', 2), data).then(
                function (res) {
                    $log.debug('DevicesDataService::getDeviceContent Success', res, data);
                    var deviceContent = res.data.data;
                    //                    if (deviceContent.selectedChannelId) {
                    //                        deviceContent.artworks = self.getArtworksOfChannel(deviceContent.channels, deviceContent.selectedChannelId);
                    //                    }
                    deferred.resolve(deviceContent);
                },
                function (error) {
                    $log.debug('DevicesDataService::getDeviceContent Failure', error, data);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        this.getQueueData = (deviceId, customQueue) => {
            let deferred = $q.defer(),
                data = {
                    deviceId: deviceId,
                };

            if (customQueue) {
                data.queuePlaylist = customQueue;
            }

            $http.post('/devices/getQueueData', data).then(
                function (res) {
                    $log.debug('DevicesDataService::getQueueData Success', res, data);
                    deferred.resolve(res.data.queueData);
                },
                function (error) {
                    $log.debug('DevicesDataService::getQueueData Failure', error, data);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getContent = function (deviceOrGroup, id) {
            var promise;

            if (id) {
                if (deviceOrGroup === 'device') {
                    promise = getDeviceContent(id);
                } else if (deviceOrGroup === 'group') {
                    promise = getGroupContent(id);
                }
            }

            return promise;
        };

        //        function getUserChannels(groupId) {
        //            var data = {
        //                'groupId': groupId
        //            };
        //
        //            return $http.post('/devices/getUserChannels', data);
        //        }

        this.getArtworksOfChannel = function (channels, channelId) {
            var artworks = [];

            channels.forEach(function (channel) {
                if (channel.id === channelId) {
                    channel.artworks.forEach(function (artwork) {
                        var newArtworkObj = {};

                        newArtworkObj.is_selected = artwork.is_selected;
                        newArtworkObj.id = artwork.id;
                        newArtworkObj.title = artwork.title;
                        newArtworkObj.description = artwork.description;
                        newArtworkObj.thumbnail_path = artwork.thumbnail_path;

                        artworks.push(newArtworkObj);
                    });
                }
            });

            return artworks;
        };

        //        function getChannelsArtworks(deviceId) {
        //            var data = {
        //                'deviceId': deviceId
        //            };
        //
        //            return $http.post('/devices/getChannelsArtworks', data);
        //        }

        this.device = {};
        this.group = {};

        // UPDATE GROUP CONTENT

        function updateGroupContent(id, content) {
            var deferred = $q.defer();
            var selectedChannelIds = angular.copy(content.selectedChannelIds);
            var selectedCollectionIds = angular.copy(content.selectedCollectionIds);

            if (content.selectedContent === 'all') {
                selectedChannelIds = selectedCollectionIds = [];
                content.distributed = false;
            } else {
                //content.displayVault = content.displayPortfolio = content.displayWishlist = false;
                if (content.selectedContent === 'specific') {
                    content.distributed = false;
                } else {
                    /* distributed */
                    content.distributed = true;
                    //Note: We must have EXACTLY ONE channel or collection.
                    selectedChannelIds =
                        content.distributeOptions.list === 'channels'
                            ? [content.distributeOptions.selectedChannelId]
                            : [];
                    selectedCollectionIds =
                        content.distributeOptions.list === 'collections'
                            ? [content.distributeOptions.selectedCollectionId]
                            : [];
                }
            }

            var data = {
                groupId: id,
                channelIds: selectedChannelIds,
                collectionIds: selectedCollectionIds,
                displayVault: content.displayVault,
                displayPortfolio: content.displayPortfolio,
                displayWishlist: content.displayWishlist,
                isDistributed: content.distributed,
            };

            $http.post('/devices/updateGroupContent', data).then(
                function (res) {
                    $log.debug('DevicesDataService::updateGroupContent Success', res, data);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::updateGroupContent Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        }

        function updateDeviceContent(id, content) {
            var deferred = $q.defer();
            var selectedChannelIds = angular.copy(content.selectedChannelIds);
            var selectedCollectionIds = angular.copy(content.selectedCollectionIds);

            if (content.selectedContent === 'all') {
                selectedChannelIds = selectedCollectionIds = [];
            } /*else {
                content.displayVault = content.displayPortfolio = content.displayWishlist = false;
            }*/

            var data = {
                deviceId: id,
                channelIds: selectedChannelIds,
                collectionIds: selectedCollectionIds,
                displayVault: content.displayVault,
                displayPortfolio: content.displayPortfolio,
                displayWishlist: content.displayWishlist,
            };

            $http.post('/devices/updateDeviceContent', data).then(
                function (res) {
                    $log.debug('DevicesDataService::updateDeviceContent Success', res, data);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::updateDeviceContent Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        }

        this.updateQueue = function (deviceId, userId, queuePlaylist, updaterRemoteId, getQueueData) {
            let deferred = $q.defer();

            var data = {
                deviceId: deviceId,
                userId: userId,
                queuePlaylist: queuePlaylist,
                updaterRemoteId: updaterRemoteId,
                getQueueData: getQueueData ? 'getQueueData' : '',
            };

            $http.post('/devices/setQueuePlaylist', data).then(
                function (res) {
                    $log.debug('DevicesDataService::updateQueue Success', res, data);
                    deferred.resolve(res.data.queue);
                },
                function (error) {
                    $log.debug('DevicesDataService::updateQueue Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.copyQueue = function (fromDeviceId, toDevicesIds, params) {
            let deferred = $q.defer();

            var data = {
                fromDeviceId,
                toDevicesIds,
                ...params,
            };

            $http.post('/devices/copyQueue', data).then(
                function (res) {
                    $log.debug('DevicesDataService::copyQueue Success', res, data);
                    deferred.resolve(res.data.devices);
                },
                function (error) {
                    $log.debug('DevicesDataService::copyQueue Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.copySettings = function (fromDeviceId, toDevicesIds, params) {
            let deferred = $q.defer();

            var data = {
                fromDeviceId,
                toDevicesIds,
                ...params,
            };

            $http.post('/devices/copySettings', data).then(
                function (res) {
                    $log.debug('DevicesDataService::copySettings Success', res, data);
                    deferred.resolve(res.data.devices);
                },
                function (error) {
                    $log.debug('DevicesDataService::copySettings Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.updateContent = function (deviceOrGroup, id, content) {
            var promise;

            if (id) {
                if (deviceOrGroup === 'device') {
                    promise = updateDeviceContent(id, content);
                } else if (deviceOrGroup === 'group') {
                    promise = updateGroupContent(id, content);
                }
            }

            return promise;
        };

        this.fetchSubscribedChannels = (limit, userId, load) => {
            limit = limit ? `/${limit}` : '';
            load = load ? load : '2';
            var data = {
                userId,
            };

            return $http.post(`/channels/getSubscribedChannelsByUser${limit}?load=${load}`, data);
        };

        function updateDeviceSettings(id, settings) {
            var deferred = $q.defer();

            var settingsCopy = angular.copy(settings);
            processSettings(settingsCopy, 'removeHash');

            var data = {
                deviceId: id,
                settings: settingsCopy,
            };

            $http.post('/devices/saveDeviceSettings', data).then(
                function (res) {
                    $log.debug('DevicesDataService::updateDeviceSettings Success', res, data);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::updateDeviceSettings Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        }

        function updateGroupSettings(id, settings) {
            var deferred = $q.defer();

            var settingsCopy = angular.copy(settings);
            processSettings(settingsCopy, 'removeHash');

            var data = {
                groupId: id,
                settings: settingsCopy,
            };

            $http.post('/devices/saveGroupSettings', data).then(
                function (res) {
                    $log.debug('DevicesDataService::updateGroupSettings Success', res, data);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::updateGroupSettings Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        }

        this.updateSettings = function (deviceOrGroup, id, settings) {
            var promise;

            if (id) {
                if (deviceOrGroup === 'device') {
                    promise = updateDeviceSettings(id, settings);
                } else if (deviceOrGroup === 'group') {
                    promise = updateGroupSettings(id, settings);
                }
            }

            return promise;
        };

        // Campaigns
        this.getCampaigns = function () {
            var deferred = $q.defer();

            $http.get('/campaigns/getAllCampaigns').then(
                function (res) {
                    $log.debug('DevicesDataService::getCampaigns Success', res);
                    deferred.resolve(res.data.data.campaigns);
                },
                function (error) {
                    $log.debug('DevicesDataService::getCampaigns Error in campaigns/getAllCampaigns', error);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        // Display Array
        this.getGroupDisplayArray = function (id) {
            var deferred = $q.defer(),
                data = {
                    groupId: id,
                };

            $http.post('/devices/getGroupDisplayArray', data).then(
                function (res) {
                    $log.debug('DevicesDataService::getGroupDisplayArray Success', res, data);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::getGroupDisplayArray Failed', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.updateGroupDisplayArray = function (id, displayArray) {
            var deferred = $q.defer();

            var data = {
                groupId: id,
                display_array: displayArray,
            };

            $http.post('/devices/updateGroupDisplayArray', data).then(
                function (res) {
                    $log.debug('DevicesDataService::updateGroupDisplayArray Success', res, data);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::updateGroupDisplayArray Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        //Remote Control
        this.sendCommandToPlayer = function (command, player_ids, gcm_data) {
            var deferred = $q.defer(),
                data = {
                    deviceIds: player_ids,
                    gcm_data: gcm_data,
                };

            $http.post('/player/notifyPlayerDevices/' + command, data).then(
                function (res) {
                    $log.debug('DevicesDataService::sendCommandToPlayer::notifyPlayerDevices Success', res, {
                        command: command,
                        player_ids: player_ids,
                    });
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::sendCommandToPlayer::notifyPlayerDevices Failed', error, {
                        command: command,
                        player_ids: player_ids,
                    });
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.sendMessageToNotificationsChannel = function (command, deviceIds, data, userId) {
            let deferred = $q.defer(),
                requestData = {
                    deviceId: deviceIds[0],
                    command,
                    data,
                    userId,
                };

            $http.post('/devices/sendMessageToNotificationsChannel', requestData).then(
                function (res) {
                    $log.debug('DevicesDataService::sendMessageToNotificationsChannel Success', res, requestData);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::sendMessageToNotificationsChannel Failed', error, requestData);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.getDevicePlaylist = function (id) {
            var deferred = $q.defer();

            var data = {
                extra: true,
            };

            $http.post('/player/getItemsForPlayer/' + id, data).then(
                function (res) {
                    $log.debug('DevicesDataService::getDevicePlaylist Success', res, data);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::getDevicePlaylist Failure', error, data);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.getDeviceArtworks = function (deviceId) {
            var deferred = $q.defer();

            $http.post('/admin/getDeviceRecentPlaylist/' + deviceId).then(
                function (res) {
                    $log.debug('DevicesDataService::getDeviceArtworks Success', res);
                    deferred.resolve(res.data.recent_playlist);
                },
                function (error) {
                    $log.debug('DevicesDataService::getDeviceArtworks Failure', error);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.getArtworkUrl = function (artworkId, deviceId) {
            var deferred = $q.defer();

            $http.post('/admin/getArtworkUrl/' + artworkId + '/' + deviceId + '?online=1&offline=1&image=1').then(
                function (res) {
                    $log.debug('DevicesDataService::getDeviceArtworks Success', res);
                    deferred.resolve(res.data);
                },
                function (error) {
                    $log.debug('DevicesDataService::getDeviceArtworks Failure', error);
                    deferred.reject(error.data);
                }
            );

            return deferred.promise;
        };

        this.setDebuggingOptions = function (deviceId, debuggingEnabled, debuggingEmails) {
            var deferred = $q.defer();

            var data = {
                deviceId: deviceId,
                debuggingEnabled: debuggingEnabled,
                debuggingEmails: debuggingEmails,
            };

            $http.post('/devices/setDebuggingOptions', data).then(
                function (res) {
                    $log.debug('DevicesDataService::setDebuggingOptions Success', { res: res, data: data });
                    deferred.resolve(res);
                },
                function (error) {
                    $log.debug('DevicesDataService::setDebuggingOptions Failure', {
                        error: error,
                        data: data,
                    });
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        function updateLastAccessed(lastAccessed) {
            var convertedLastAccessed = null;
            var notConnectedStage = null;

            if (lastAccessed) {
                var today = new Date();
                // Taken from here: http://stackoverflow.com/questions/5324178/javascript-date-parsing-on-iphone
                var lastAccessedTemp = lastAccessed.split(/[- :]/);
                lastAccessedTemp = new Date(
                    lastAccessedTemp[0],
                    lastAccessedTemp[1] - 1,
                    lastAccessedTemp[2],
                    lastAccessedTemp[3],
                    lastAccessedTemp[4],
                    lastAccessedTemp[5]
                );
                lastAccessedTemp.setMinutes(lastAccessedTemp.getMinutes() - today.getTimezoneOffset());
                convertedLastAccessed = lastAccessedTemp;
                var gracePeriod30seconds = new Date(convertedLastAccessed.getTime() + 1 * 60000); // 1 minutes no connections
                var gracePeriod1minute = new Date(convertedLastAccessed.getTime() + 2 * 60000); // 2 minutes no connections
                var gracePeriod5minutes = new Date(convertedLastAccessed.getTime() + 5 * 60000); // 5 minutes no connections
                if (gracePeriod5minutes < today.getTime()) {
                    // Not connected for 5 minutes
                    // newStatus.currentStatus = null;
                    notConnectedStage = 4;
                } else if (gracePeriod1minute < today.getTime()) {
                    // Not connected for 2 minutes
                    // newStatus.currentStatus = null;
                    notConnectedStage = 3;
                } else if (gracePeriod30seconds < today.getTime()) {
                    // Not connected for 30 seconds
                    // newStatus.currentStatus = null;
                    notConnectedStage = 2;
                } else {
                    // Connected
                    notConnectedStage = 1;
                    // newStatus.currentStatus = res.data.status.current_status;
                }
            } else {
                notConnectedStage = 5;
            }
            var result = {
                convertedLastAccessed: convertedLastAccessed,
                notConnectedStage: notConnectedStage,
            };

            return result;
        }

        function updateActiveItem(curChannel, curCollection, curItem) {
            return {
                channelId: curChannel || -1,
                collectionId: curCollection || -1,
                artworkId: curItem || -1,
            };

            /*
            var channelObjId = newStatus.currentStatus.CurChannel || -1;
            var collectionObjId =  newStatus.currentStatus.CurCollection || -1;
            var CurItem =  newStatus.currentStatus.CurItem || -1;
            newStatus.currentStatus.currentActive = {};
            var plist = newStatus.currentStatus.Playlist;
            for(var ch=0;ch<plist.length;ch++){
                if(plist[ch].original_id === channelObjId){
                    var chObj = plist[ch];
                    newStatus.currentStatus.currentActive.channelId = chObj.original_id;
                    if(collectionObjId !== undefined && collectionObjId > -1 ) {
                        for(var i=0;i<chObj.artworks.length;i++) {
                            var entity = chObj.artworks[i];
                            if (!entity.is_artwork) {
                                if (collectionObjId !== entity.id) {
                                    newStatus.currentStatus.currentActive.collectionId = entity.id + '';
                                    newStatus.currentStatus.currentActive.artworkId = getActiveItemInArtworksArray(CurItem, entity.artworks);
                                    return;
                                }
                            }
                        }
                    } else {
                        newStatus.currentStatus.currentActive.collectionId = 0;
                        newStatus.currentStatus.currentActive.artworkId = getActiveItemInArtworksArray(CurItem, chObj.artworks);
                        return;
                    }
                }
            }*/
        }

        this.processShortUpdatedStatus = function (lastAccessed, curChannel, curCollection, curItem, ETAG) {
            var updatedStatus = {
                loadedDeviceCurrentStatusFirstTime: true,
                currentStatus: undefined,
                notConnectedStage: undefined,
                lastAccessed: undefined,
                ETAG: undefined,
            };

            var lastAccessResult = updateLastAccessed(lastAccessed);
            if (lastAccessResult) {
                updatedStatus.lastAccessed = lastAccessResult.convertedLastAccessed;
                updatedStatus.notConnectedStage = lastAccessResult.notConnectedStage;
            }

            updatedStatus.currentActive = updateActiveItem(curChannel, curCollection, curItem);
            updatedStatus.loadedDeviceCurrentStatusFirstTime = true;
            updatedStatus.ETAG = ETAG;

            $log.debug(
                'DevicesSelectedRemoteControlController::processShortUpdatedStatus updatedStatus',
                updatedStatus
            );
            return updatedStatus;
        };

        this.getUpdatedStatus = function (deviceId) {
            var deferred = $q.defer(),
                data = {
                    deviceId: deviceId,
                };

            var newStatus = {
                loadedDeviceCurrentStatusFirstTime: true,
                currentStatus: undefined,
                notConnectedStage: undefined,
                lastAccessed: undefined,
                downloads: undefined,
                is_online: undefined,
                ETAG: undefined,
            };

            $http
                .post('/devices/getDeviceCurrentStatus', data)
                .then(
                    function (res) {
                        $log.debug('DevicesDataService::getCurrentStatus Success', {
                            id: deviceId,
                            res: res,
                        });
                        if (res.data.status) {
                            var lastAccessResult = updateLastAccessed(res.data.status.last_accessed);

                            if (lastAccessResult) {
                                newStatus.lastAccessed = lastAccessResult.convertedLastAccessed;
                                newStatus.notConnectedStage = lastAccessResult.notConnectedStage;
                                newStatus.currentStatus =
                                    lastAccessResult.notConnectedStage === 5
                                        ? res.data.status
                                        : res.data.status.current_status;
                            }
                        }

                        if (newStatus.currentStatus) {
                            newStatus.currentStatus.currentActive = updateActiveItem(
                                newStatus.currentStatus.CurChannel,
                                newStatus.currentStatus.CurCollection,
                                newStatus.currentStatus.CurItem
                            );
                            newStatus.ETAG = newStatus.currentStatus.ETAG;
                        }
                        updatePlayListInStatus(newStatus);

                        newStatus.downloads = res.data.status.downloads;
                        newStatus.is_online = res.data.status.is_online;
                        newStatus.loadedDeviceCurrentStatusFirstTime = true;
                        newStatus.loadedDeviceCurrentStatusFirstTime = true;
                        deferred.resolve(newStatus);
                    },
                    function (error) {
                        if (deviceId !== newStatus.getCurrentDeviceId()) {
                            deferred.resolve(newStatus);
                            return;
                        }
                        $log.debug('DevicesDataService::getCurrentStatus Failure', {
                            id: deviceId,
                            error: error,
                        });

                        newStatus.loadedDeviceCurrentStatusFirstTime = true;
                        newStatus.currentStatus = undefined;
                        newStatus.notConnectedStage = undefined;
                        newStatus.lastAccessed = undefined;
                        newStatus.ETAG = undefined;

                        //TODO: Was device deleted ?
                        deferred.resolve(newStatus);
                    }
                )
                .finally(function () {});
            return deferred.promise;
        };

        function getActiveItemInArtworksArray(itemId, artworks) {
            if (artworks === undefined) {
                return false;
            }
            for (var i = 0; i < artworks.length; i++) {
                if (artworks[i].id === itemId) {
                    return artworks[i].original_id;
                }
            }
            return 0;
        }

        function updatePlayListInStatus(newStatus) {
            //Set "checked" on playlist channels.
            if (newStatus.currentStatus && newStatus.currentStatus.Playlist) {
                var plist = newStatus.currentStatus.Playlist;
                for (var i = 0; i < plist.length; i++) {
                    syncChannelUICheckedOrNot(plist[i]);
                }
            }
        }

        function isAnyArtworkIsCheckedOrScheduled(artworks) {
            var ret = {
                checked: false,
                scheduled: false,
            };

            if (artworks === undefined) {
                return ret;
            }

            for (var i = 0; i < artworks.length; i++) {
                if (artworks[i].original_id !== undefined) {
                    artworks[i].id = artworks[i].original_id;
                }

                if (artworks[i].checked) {
                    ret.checked = true;
                }
                if (artworks[i].scheduled) {
                    ret.scheduled = true;
                }

                //We can't stop here since we must loop at least oceon over all artworks to set its id to original_id....
                //if(ret.checked && ret.scheduled) {
                //    return ret;
                //}
            }
            return ret;
        }

        function isArtworkIsCheckedOrScheduled(artwork) {
            var ret = {
                checked: false,
                scheduled: false,
            };

            if (artwork === undefined) {
                return ret;
            }

            if (artwork.checked) {
                ret.checked = true;
            }
            if (artwork.scheduled) {
                ret.scheduled = true;
            }

            if (artwork.original_id !== undefined) {
                artwork.id = artwork.original_id;
            }

            return ret;
        }

        function syncChannelUICheckedOrNot(channel) {
            //Go over all artworks and see ifat lease oneis checked.
            var channelIsChecked = false,
                collectionIsChecked = false,
                channelIsScheduled = false,
                collectionIsScheduled = false;

            if (channel.original_id !== undefined) {
                channel.id = channel.original_id;
            }

            if (channel.artworks === undefined) {
                return;
            }

            var status = {
                checked: false,
                scheduled: false,
            };

            for (var i = 0; i < channel.artworks.length; i++) {
                var entity = channel.artworks[i];
                if (entity.is_artwork) {
                    if (entity.original_id !== undefined) {
                        entity.id = entity.original_id;
                    }
                    if (status.checked && status.scheduled) {
                        //No need to check more artworks, but we should check collections.
                        continue;
                    }

                    var awFlags = isArtworkIsCheckedOrScheduled(entity);
                    status.checked = status.checked || awFlags.checked;
                    status.scheduled = status.scheduled || awFlags.scheduled;
                } else {
                    //It's a collection.
                    var collection = entity;
                    if (collection.original_id !== undefined) {
                        collection.id = collection.original_id;
                    }
                    if (collection === undefined || collection.artworks === undefined) {
                        continue;
                    }
                    var colFlags = isAnyArtworkIsCheckedOrScheduled(collection.artworks);
                    //Update collection's flags.
                    collection.checked = collection.checked || colFlags.checked;
                    collection.scheduled = collection.scheduled || colFlags.scheduled;
                    //Update channel's flags.
                    status.checked = status.checked || colFlags.checked;
                    status.scheduled = status.scheduled || colFlags.scheduled;
                }
            }

            channel.checked = status.checked;
            channel.scheduled = status.scheduled;
        }
    },
]);
