'use strict';

angular.module('core').constant('ENTITY_ACTIONS', {
    SHARE: '1',
    LOAN: '2',
    RENT: '3',
    DOWNLOAD: '4',
    TRANSFER_OWNERSHIP: '5',
    BUY: '6',
    CONSIGN: '7',
    SENDPREVIEW: '8',
});
