'use strict';

angular.module('core').service('FavoritesService', [
    '$rootScope',
    '$http',
    'Messages',
    'User',
    function ($rootScope, $http, Messages, User) {
        this.favorites = { members: {}, themes: {}, artworks: {}, collections: {} };
        let self = this;

        this.init = () => {
            if (User.isOtherFunc()) {
                this.fetchFollowingMembers();
                this.fetchFavoritesArtworks();
            }
        };

        this.toggleToFavorites = async (type, ids) => {
            // Converting to array if not array.
            if (!Array.isArray(ids)) {
                ids = [ids];
            }

            await this.toggleByTypeAndId(type, ids);
        };

        this.fetchFollowingMembers = async () => {
            const response = await $http.get('/users/getUserFollowings');
            const itemsFromServer = response?.data?.data ?? [];
            this.favorites.members = {};

            itemsFromServer.forEach((item) => {
                this.favorites.members[item.id] = item;
            });
        };

        this.fetchFavoritesArtworks = async () => {
            const data = {
                readyOnlyArtworks: true,
            };

            const res = await $http.post(`/artworkWishlists/getWishlistArtworksByUser`, data);
            this.favorites.artworks = {};

            res?.data?.data?.artworks.forEach((artwork) => {
                this.favorites.artworks[artwork.id] = artwork.in_wishlist;
            });
        };

        this.toggleByTypeAndId = async (type, ids) => {
            switch (type) {
                case 'member':
                    return this.toggleFollowingMemberRequest(ids);
                case 'artwork':
                    return this.toggleFollowingArtworkRequest(ids);
                default:
                    throw new Error('Unknown type');
            }
        };

        this.toggleFollowingMemberRequest = async (id, { onlyState = false, notify = true } = {}) => {
            if (Array.isArray(id)) {
                id = id[0];
            }

            try {
                if (this.favorites.members[id]) {
                    this.favorites.members[id] = false;
                    //delete this.favorites.members[id];
                    !onlyState && (await $http.post('/users/unfollowUser', { followingUserId: id }));
                    notify &&
                        $rootScope.$broadcast('addNotification', {
                            message: 'Artist removed from your favorites.',
                            duration: 3000,
                        });
                } else {
                    this.favorites.members[id] = { id };
                    !onlyState && (await $http.post('/users/followUser', { followingUserId: id }));
                    notify &&
                        $rootScope.$broadcast('addNotification', {
                            message: 'Artist added to your favorites.',
                            duration: 3000,
                        });
                }
            } catch (e) {
                console.log('%c Error in toggleFollowingMemberRequest', 'color: red', e);
                return await this.toggleFollowingMemberRequest(id, {
                    onlyState: true,
                    notify: false,
                });
            }
            !onlyState && this.fetchFollowingMembers();
        };

        this.toggleFollowingArtworkRequest = async (id, { onlyState = false, notify = true } = {}) => {
            if (Array.isArray(id)) {
                id = id[0];
            }

            try {
                let nextValue = !self.favorites.artworks[id];
                self.favorites.artworks[id] = nextValue;

                if (!nextValue) {
                    // It was already toggled, actually it's currently not the right value.
                    await $http.post('/artworkWishlists/wishlistArtworkExistsInChannel', { artworkId: id }).then(
                        async function (res) {
                            if (res.data.data && res.data.data.length > 0) {
                                var messageParams = {};

                                messageParams.message = 'You are about to remove this artwork from your favorites.';
                                messageParams.message += '\nThis artwork is part of a curated channel you created: \n';
                                res.data.data.forEach(function (channel) {
                                    messageParams.message += '<b>“' + channel.name + '”</b>\n';
                                });
                                messageParams.message +=
                                    '\nIf you remove it it will also disappear from these channels.\nAre you sure you want to continue?';
                                //messageParams.title = 'Remove Artwork “' + artwork.title + '” From Favorites';
                                messageParams.title = 'Remove Artwork From Favorites';
                                messageParams.disableAutoDismiss = true;
                                messageParams.cancelText = 'Cancel';
                                messageParams.okText = 'Remove';
                                messageParams.okClass = 'danger';
                                await Messages.openMessage($rootScope, messageParams).then(async function (res) {
                                    if (res === 'ok') {
                                        await self.toggleArtworkToFavorites(onlyState, notify, nextValue, id);
                                    } else {
                                        throw new Error('User canceled');
                                    }
                                });
                            } else {
                                await self.toggleArtworkToFavorites(onlyState, notify, nextValue, id);
                            }
                        },
                        function (err) {
                            console.log('Error in toggleFollowingArtworkRequest', err);
                            throw new Error('Rejected');
                        }
                    );
                } else {
                    await self.toggleArtworkToFavorites(onlyState, notify, nextValue, id);
                }
            } catch (e) {
                console.log('%c Error in toggleWishlist', 'color: red', e);
                return await self.toggleFollowingArtworkRequest(id, {
                    onlyState: true,
                    notify: false,
                });
            }
            !onlyState && self.fetchFavoritesArtworks();
        };

        this.toggleArtworkToFavorites = async (onlyState, notify, nextValue, id) => {
            !onlyState && (await $http.post('/artworks/toggleWishlist', { artworkId: id }));
            notify &&
                $rootScope.$broadcast('addNotification', {
                    message: `Artwork ${nextValue ? 'added to' : 'removed from'} your favorites.`,
                    duration: 3000,
                });
        };

        this.init();
    },
]);
