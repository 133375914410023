'use strict';

angular.module('core').directive('showMore', function () {
    return {
        restrict: 'AE',
        //   replace: true,
        scope: {
            text: '=',
            limit: '=',
        },

        template:
            '<div class="read-more-container read-more-container-desktop">' +
            '<p ng-show="largeText"> {{ text | subString :0 :end }}.... ' +
            '<a class="read-more" href="#" ng-click="showMoreClicked()">{{isShowMore ? \'Read More\' : \'Read Less\' }}</a>' +
            '</p>' +
            '<p ng-hide="largeText">{{ text }}</p>' +
            '</div> ',

        link: function (scope, iElement, iAttrs) {
            scope.text = scope.text || '';
            scope.end = scope.limit;
            scope.isShowMore = true;

            scope.largeText = scope.text.length > scope.limit;

            scope.showMoreClicked = function () {
                if (scope.isShowMore) {
                    scope.end = scope.text.length;
                    scope.isShowMore = false;
                } else {
                    scope.end = scope.limit;
                    scope.isShowMore = true;
                }
            };
        },
    };
});
angular.module('core').directive('showMoreMobile', function () {
    return {
        restrict: 'AE',
        //   replace: true,
        scope: {
            text: '=',
            limit: '=',
        },

        template:
            '<div class="read-more-container">' +
            '<p ng-show="largeText"> {{ text | subString :0 :end }} ' +
            '<a  class="read-more-mobile"  href="#" ng-click="toggle()" ng-class="{\'read-more-mobile-open\':!isShowMore}">' +
            '<span class="read-more-mobile-arrow"><img aria-hidden="true" src="modules/channel-info/img/show-more-arrow.svg" ng-class="{\'read-more-mobile-arrow-bottom\':isShowMore,\'read-more-mobile-arrow-top\':!isShowMore}"></span>' +
            '</a>' +
            '</p>' +
            '<p ng-hide="largeText">{{ text }}</p>' +
            '</div> ',

        link: function (scope) {
            scope.text = scope.text || '';
            scope.end = scope.limit;
            scope.isShowMore = true;

            scope.largeText = scope.text.length > scope.limit;

            scope.toggle = function () {
                scope.isShowMore = !scope.isShowMore;
                scope.end = scope.isShowMore ? scope.limit : scope.text.length;
            };
        },
    };
});
angular.module('core').filter('subString', function () {
    return function (str, start, end) {
        if (str !== undefined) {
            return str.substr(start, end);
        }
    };
});
