'use strict';

angular.module('core').filter('eventHostTypes', [
    function () {
        return function (input) {
            var out = input;

            if (input !== undefined && input !== null) {
                // var currency = input.toLowerCase();

                if (input === '1') {
                    out = 'Host';
                } else if (input === '2') {
                    out = 'Co-Curator';
                } else if (input === '3') {
                    out = 'Jury';
                }
            }

            return out;
        };
    },
]);
