/* global AWS */

angular.module('home').controller('HomeUploadController', [
    'HomeDataService',
    'FormValidationService',
    '$log',
    '$rootScope',
    '$scope',
    '$q',
    '$state',
    '$stateParams',
    '$sce',
    '$timeout',
    '$location',
    '$http',
    '$filter',
    '$localStorage',
    'S3Uploader',
    'Partial',
    'Messages',
    'ArtworkService',
    'User',
    'UploadProgress',
    'OpenPopupForm',
    'HostIframeSdk',
    'TokenService',
    'TagsService',
    '_',
    'NIIO_DEBUG',
    'NIIO_APP',
    'ENV',
    'BUSINESS_RESTRICTIONS',
    function (
        HomeDataService,
        FormValidationService,
        $log,
        $rootScope,
        $scope,
        $q,
        $state,
        $stateParams,
        $sce,
        $timeout,
        $location,
        $http,
        $filter,
        $localStorage,
        S3Uploader,
        Partial,
        Messages,
        ArtworkService,
        User,
        UploadProgress,
        OpenPopupForm,
        HostIframeSdk,
        TokenService,
        TagsService,
        _,
        NIIO_DEBUG,
        NIIO_APP,
        ENV,
        BUSINESS_RESTRICTIONS
    ) {
        $scope.UploadProgress = UploadProgress;
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.from = $state.current?.data?.from || $state.current?.data?.state || $stateParams.from;
        $scope.isFromEditUpload = $state.current.name.toLowerCase().indexOf('upload') > -1;
        $scope.uploadBelongsTo = $state.current.name.split('.')[0];
        $scope.$stateParams = $stateParams;
        $scope.homeOrUrl = $stateParams.url || 'home';
        $scope.linksPrefix = `${$scope.uploadBelongsTo}.${$scope.from}Upload.`;
        $scope.Messages = Messages;
        $scope.ArtworkService = ArtworkService;
        $scope.toggles = {};
        $scope.isAdmin = User.isAdmin();
        $scope.styles = HomeDataService.enums.styles;
        $scope.currencies = HomeDataService.enums.currencies;
        $scope.isFromMobileUpload =
            $state.current.name === 'home.mobileUpload' || $state.current.name === 'mobileUploadOpenUppy';
        $scope.priorities = [
            { value: '0', view: 'Normal' },
            { value: '1', view: 'Medium' },
            { value: '2', view: 'High' },
        ];

        $scope.mediaTypes = [
            { value: 'CD', name: 'CD' },
            { value: 'DV', name: 'DVD' },
            { value: 'BR', name: 'Blue-ray' },
            { value: 'BE', name: 'Beta' },
            { value: 'MS', name: 'USB stick / Drive' },
            { value: 'HD', name: 'External hard drive' },
            { value: 'CL', name: 'Media files / Cloud transfer' },
            { value: 'OT', name: 'Other' },
        ];
        $scope.batchMaxFiles = 50;
        $scope.maxAdditionalFiles = 15;
        $scope.maxAdditionalFileSize = 100 * 1024 * 1024 * 1024; // Changed to 100GN from 1.5 GB for dev / pre AND production as well
        $scope.maxFileSize = (ENV === 'prod' ? 300 : 30) * 1024 * 1024 * 1024; // 300 GB for production, 30 GB for dev / pre
        $scope.maxCoverFileSize = 2 * 1024 * 1024; // 2MB
        $scope.maxFileSizeFilestack = 100 * 1024 * 1024 * 1024 * 2.5; // 250 GB for dev / pre AND production as well
        $scope.maxFileSizeFilestackTooltip = 100 * 1024 * 1024 * 1024 * 2.5;

        $scope.formValidator = FormValidationService;
        $scope.cleanupFuncs = [];

        function initLoanDefaults() {
            if ($scope.uploadFormObj.default_loan_price_level) {
                $scope.uploadFormObj.default_loan_price_level_obj = _.find(
                    FormValidationService.loanPriceLevels,
                    (level) => {
                        return level.id === $scope.uploadFormObj.default_loan_price_level;
                    }
                );
            } else {
                var defaultLoanPriceLever = _.find(FormValidationService.loanPriceLevels, (level) => {
                    return level.default;
                });

                if (defaultLoanPriceLever) {
                    $scope.uploadFormObj.default_loan_price_level_obj = defaultLoanPriceLever;
                    $scope.uploadFormObj.default_loan_price_level = defaultLoanPriceLever.id;
                }
            }

            if ($scope.uploadFormObj.default_loan_period) {
                $scope.uploadFormObj.default_loan_period_obj = _.find(FormValidationService.loanPeriods, (period) => {
                    return period.id === $scope.uploadFormObj.default_loan_period;
                });
            } else {
                var defaultLoanPeriod = _.find(FormValidationService.loanPeriods, (period) => {
                    return period.default;
                });

                if (defaultLoanPeriod) {
                    $scope.uploadFormObj.default_loan_period_obj = defaultLoanPeriod;
                    $scope.uploadFormObj.default_loan_period = defaultLoanPeriod.id;
                }
            }
        }

        $scope.changedLoanPriceLevel = function (level) {
            $scope.uploadFormObj.default_loan_price_level = level.id;
        };

        $scope.changedLoanPeriod = function (period) {
            $scope.uploadFormObj.default_loan_period = period.id;
        };

        // Taken from: https://stackoverflow.com/questions/4459379/preview-an-image-before-it-is-uploaded
        function createViewablePreview(file) {
            var deferred = $q.defer();
            var type = file.type;
            var extension = file.name.split('.').pop().toLowerCase();

            if (isImage(type, extension)) {
                var reader = new FileReader();

                reader.onload = function (e) {
                    // $('#blah').attr('src', e.target.result);
                    deferred.resolve(e.target.result);
                };

                reader.readAsDataURL(file); // convert to base64 string
            } else if (isVideoForPreview(type, extension)) {
                // Taken From: https://stackoverflow.com/questions/45661913/vanilla-javascript-preview-video-file-before-upload-no-jquery
                var blobURL = URL.createObjectURL(file);
                deferred.resolve(blobURL);
            } else {
                deferred.reject();
            }

            return deferred.promise;
        }

        function isImageByPath(path) {
            var extension = path.split('.').pop().toLowerCase();
            return ['gif', 'jpeg', 'jpg', 'bmp', 'tif', 'png'].indexOf(extension) >= 0;
        }

        function initCoverThumbnail() {
            if ($scope.uploadFormObj.artworkThumb) {
                $scope.toggles.coverThumb = $sce.trustAsResourceUrl($scope.uploadFormObj.artworkThumb);
            } else {
                $scope.toggles.coverThumb = undefined;
            }
        }

        function initPreviewThumbnail() {
            if ($scope.uploadFormObj.preview && $scope.uploadFormObj.preview.filename) {
                $scope.toggles.previewIsImage = false;
                if (isImageByPath($scope.uploadFormObj.preview.filename)) {
                    $scope.toggles.previewIsImage = true;
                }

                ArtworkService.handleArtworkAction('getPreviewPathWithExtension', $scope.uploadFormObj, {
                    preview: $scope.uploadFormObj.preview_path,
                    preview_hls: $scope.uploadFormObj.preview_hls,
                    preview_file: $scope.uploadFormObj.preview.filename,
                    media_type: $scope.uploadFormObj.file_metadata.media_type,
                }).then((res) => {
                    var path;
                    if (res.type === 'video') {
                        // self.artwork.preview_allSrcs = res.src;
                        path = res.src[0].src;
                        // loadVideo(self.artwork.preview, res.src[0].type);
                    } else {
                        path = res.path;
                        $rootScope.$broadcast('ArtworkInfoArtworkController::finished_loading');
                    }

                    $scope.toggles.previewThumb = $sce.trustAsResourceUrl(path);
                });
            } else {
                $scope.toggles.previewThumb = undefined;
            }
        }

        function initThumbnails() {
            initCoverThumbnail();
            initPreviewThumbnail();
        }

        $scope.currencyChanged = () => {
            if ($scope.uploadFormObj.currency !== 'USD') {
                let messageParams = {};
                messageParams.title = 'Currency Not Supported';
                messageParams.message =
                    '<b>Please note</b>: Sell in this currency is not currently supported. \n' +
                    'Please choose USD or contact support.';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
                $scope.uploadFormObj.currency = 'USD';
            }
        };

        $scope.init = function () {
            $scope.loadingUploadSettings = true;
            var editArtworkId = $scope.isFromEditUpload ? $stateParams.artworkId : null;
            var editCollectionId = $scope.isFromEditUpload ? $stateParams.collectionId : null;

            if ($scope.isFromMobileUpload) {
                $scope.from =
                    $stateParams.from === 'vault' || $stateParams.from === 'portfolio'
                        ? $stateParams.from
                        : 'portfolio';
            }

            $http.post('/categories/getAll').then((allDatabaseCategories) => {
                $scope.categories = allDatabaseCategories.data.data || [];
            });

            HomeDataService.getUploadSettings(editArtworkId, editCollectionId)
                .then((uploadSettings) => {
                    if (uploadSettings) {
                        if (
                            uploadSettings.artworkUserId &&
                            User.$storage.userDetails.id !== uploadSettings.artworkUserId
                        ) {
                            $state.go(`home.${$scope.from}.main`, {
                                collectionId: 0,
                                collectionLink: 'all',
                            });
                        }

                        $scope.uploadFormObj = uploadSettings;
                        initOrientationMode();
                        _.each($scope.uploadFormObj.additionalFiles, (additionalFile) => {
                            additionalFile.isAudio = isAudio('', additionalFile.file_type);
                        });
                    } else {
                        $scope.uploadFormObj = {};
                        $scope.uploadFormObj.preview = {};
                        $scope.uploadFormObj.cover = {};
                        $scope.uploadFormObj.additionalFiles = [];
                        $scope.uploadFormObj.extra_metadata = {};
                        $scope.uploadFormObj.display_guidelines = {
                            landscape_mode: true,
                            portrait_mode: false,
                            portrait_submode: 1,
                        };
                        $scope.uploadFormObj.coCreators = [];
                        $scope.initTotalCopies();
                    }

                    initThumbnails();
                    FormValidationService.initLoanPackages().then((res) => {
                        initLoanDefaults();
                    });

                    $scope.uploader =
                        uploadSettings && User.getId() !== uploadSettings.artworkUserId
                            ? uploadSettings.uploader
                            : null;

                    const userProfile = $scope.uploader || User.getUserDetails();
                    if (User.isArtist(userProfile) || User.isOrganization(userProfile)) {
                        $scope.uploadFormObj.artworkType = $scope.from !== 'vault' ? 'ARTIST' : 'COLLECTOR';
                    } else {
                        $scope.uploadFormObj.artworkType = 'COLLECTOR';
                    }

                    $scope.submitWasClicked = false;
                    $scope.selectedTab = 'basic';
                    $scope.S3Uploader = S3Uploader;

                    if ($scope.uploadFormObj.sale_type === 'FCC') {
                        $scope.uploadFormObj.sale_type = 'FRE';
                        $scope.uploadFormObj.isCCLicense = true;
                    }

                    $scope.uploadFormObj.files = [];
                    $scope.uploadFormObj.newAdditionalFiles = [];
                    $scope.updateCheckboxState('allow_videowall', true);
                    if (
                        $scope.linksPrefix !== undefined &&
                        !$scope.isFromMobileUpload &&
                        (!$scope.params || $scope.params.type !== 'modal')
                    ) {
                        $state.go($scope.linksPrefix + $scope.selectedTab);
                    }
                })
                .finally(async () => {
                    $scope.loadingUploadSettings = false;
                    const urlSearchParams = new URLSearchParams(location.search);
                    const queryParams = Object.fromEntries(urlSearchParams.entries());
                    if ($stateParams.returnToApp) {
                        uploadUsingUppy($stateParams.open || null, queryParams);
                    }
                });
        };

        $scope.selectTab = function (state, link, page) {
            if (
                page.link !== 'advanced' ||
                (page.link === 'advanced' && $scope.uploadFormObj.artworkType === 'COLLECTOR')
            ) {
                $scope.selectedTab = link;
                $state.go(state);
            }
        };

        $scope.isSelected = function (link) {
            return link === $scope.selectedTab;
        };

        // Handle Tags
        $scope.$watch(
            'uploadFormObj.tags',
            function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    // Call your updateTags function here
                    TagsService.updateTags(newVal);
                }
            },
            true
        ); // 'true' for deep object watching

        $scope.$watch(
            'uploadFormObj.newAdditionalFiles',
            function (newAdditionalFile, oldAdditionalFile) {
                if (newAdditionalFile) {
                    for (let i = 0; i < newAdditionalFile.length; i++) {
                        if (newAdditionalFile[i]?.tags) {
                            if (newAdditionalFile[i].tags !== oldAdditionalFile[i].tags) {
                                TagsService.updateTags(newAdditionalFile[i].tags);
                            }
                        }
                    }
                }
            },
            true
        ); // 'true' for deep object watching

        $scope.$watch(
            'uploadFormObj.additionalFiles',
            function (newAdditionalFile, oldAdditionalFile) {
                if (newAdditionalFile) {
                    for (let i = 0; i < newAdditionalFile.length; i++) {
                        if (newAdditionalFile[i]?.tags) {
                            if (newAdditionalFile[i].tags !== oldAdditionalFile[i].tags) {
                                TagsService.updateTags(newAdditionalFile[i].tags);
                            }
                        }
                    }
                }
            },
            true
        ); // 'true' for deep object watching

        $scope.pages = [
            {
                link: 'basic',
                name: 'HOME.BASIC',
            },
            {
                link: 'advanced',
                name: 'HOME.ADVANCED',
            },
        ];

        function isImage(type, extension) {
            return (
                _.indexOf(FormValidationService.supportedImageMimetypes, type) > -1 ||
                _.indexOf(FormValidationService.supportedImageExtensions, extension) > -1
            );
        }

        function isVideo(type, extension) {
            return (
                _.indexOf(FormValidationService.supportedVideoMimetypes, type) > -1 ||
                _.indexOf(FormValidationService.supportedVideoExtensions, extension) > -1
            );
        }

        function isVideoForPreview(type, extension) {
            return _.indexOf(FormValidationService.supportedVideoMimetypesForPreview, type) > -1;
        }

        function isZip(type, extension) {
            return _.indexOf(FormValidationService.supportedZipMimetypes, type) > -1;
        }

        function isAudio(type, extension) {
            return (
                _.indexOf(FormValidationService.supportedAudioMimetypes, type) > -1 ||
                _.indexOf(FormValidationService.suppoertedAudioExtensions, extension) > -1
            );
        }

        $scope.validateFile = function (file, typeOfFile) {
            var result = { result: true, messageParams: {} };
            var messageParams = {};
            var imageOnly = false;
            var mp4Only = false;
            var maxFileSize = $scope.maxFileSize;

            typeOfFile = typeOfFile || 'regular';

            // if (file || $scope.uploadFormObj.files.length === 1) {
            if (file) {
                // file = file || $scope.uploadFormObj.files[0];

                $scope.fileType = '';

                var type = file.type;
                var extension = file.name.split('.').pop().toLowerCase();
                if (typeOfFile === 'cover') {
                    maxFileSize = $scope.maxCoverFileSize;
                    imageOnly = true;
                } else if (typeOfFile === 'preview') {
                    maxFileSize = $scope.maxFileSize;
                    mp4Only = true;
                }

                if (file.size > maxFileSize) {
                    // 30 GB for file
                    messageParams = {};
                    messageParams.title = 'File size exceeds upload limit';
                    messageParams.message = `${file.name} File size (${$filter('bytes')(file.size)}) exceeds ${
                        typeOfFile === 'regular' ? 'your' : 'the'
                    } allowed single upload limit.\n`;
                    messageParams.disableAutoDismiss = true;
                    // Messages.openMessage($rootScope, messageParams);
                    result = {
                        result: false,
                        messageParams,
                        type: 'size',
                        typeText: `Please use a reduced file size (up to ${$filter('bytes')(maxFileSize)}).`,
                    };
                } else if (
                    !imageOnly &&
                    ((mp4Only && isVideoForPreview(type, extension)) || isVideo(type, extension))
                ) {
                    switch (type) {
                        case 'video/ogg':
                        case 'video/mp4':
                        case 'video/webm':
                        case 'video/quicktime':
                            break;
                        default:
                            type = '';
                    }
                    $scope.fileType = 'video';
                } else if (isImage(type, extension)) {
                    $scope.fileType = 'image';
                } else if (
                    !imageOnly &&
                    isZip(type, extension) &&
                    ($scope.isAdmin ||
                        User.isArtist($scope.uploader) ||
                        User.isCurator($scope.uploader) ||
                        User.isCollector($scope.uploader) ||
                        User.isOrganization($scope.uploader))
                ) {
                    // Zip is blocked for ArtFun
                    $scope.fileType = 'zip';
                } else {
                    messageParams = {};
                    messageParams.title = 'File Type is Not Supported';
                    var extraTypes = imageOnly
                        ? ''
                        : `, mpg, mpeg${mp4Only ? ' and mp4' : ', mp4, avi, mov, flv and mkv'}`;
                    messageParams.message = `${file.name} File type is not supported.\nSupported file types are: gif, jpeg, jpg, png${extraTypes}`;
                    messageParams.message += `${file.name}\nTo upload these types of file formats, please use the "Additional Files" section under "Edit Artwork" which allows upload of files related to the artwork, in various formats, such as PDF documents, Audio files etc.`;
                    messageParams.disableAutoDismiss = true;
                    // Messages.openMessage($rootScope, messageParams);
                    result = {
                        result: false,
                        messageParams,
                        type: 'type',
                        typeText: `Supported file types are: gif, jpeg, jpg, png${extraTypes}.\nTo upload these types of file format, please use the "Additional Files" section under "Edit Artwork" which allows upload of files related to the artwork, in various formats, such as PDF documents, Audio files etc.`,
                    };
                }
            } else {
                result = { result: false, messageParams: {} };
            }
            return result;
        };

        // $scope.$watchCollection('uploadFormObj.files', $scope.validateFile);

        var s3UploadOptions = {
            getOptionsUri: '/Artworks/getArtworkUploadS3accessToken',
            folder: 'ShaiArtworks/',
        };

        var opts = angular.extend({}, s3UploadOptions);
        opts = angular.extend(
            {
                submitOnChange: true,
                getOptionsUri: '/getS3Options',
                acl: 'private',
                uploadingKey: 'uploading',
                folder: '',
                enableValidation: true,
            },
            opts
        );
        //        var bucket = 'niiotest';

        $scope.uploadFiles = function () {
            $scope.filesModel.forEach((file) => {
                $scope.uploadFile(file).then(
                    (res) => {
                        $log.debug('HomeUploadController::uploadFiles uploaded started', res);
                    },
                    (error) => {
                        $log.debug('HomeUploadController::uploadFiles error uploading', error);
                        var messageParams = {};
                        messageParams.message = 'Error uploading artwork';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                );
            });
        };

        function processArtwork(artworkId) {
            var data = {
                artworkId,
            };

            $http.post('/artworks/processArtwork', data).then(
                (res) => {
                    $log.debug('HomeUploadController::processArtwork Successfully started processing artwork', res);
                    $rootScope.$broadcast('artwork_changed_status', artworkId, 'PENDING');
                },
                (error) => {
                    $log.debug('HomeUploadController::processThumbnail Failed to start processing artwork', error);
                    ArtworkService.updateArtworkStatus($scope.s3ArtworkId, {
                        status: 'FAILED',
                        reason: `HomeUploadController::processArtwork Failed to start processing artwork${JSON.stringify(
                            error
                        )}`,
                    }).then((res) => {
                        $rootScope.$broadcast('artwork_changed_status', artworkId, 'FAILED');
                    });
                }
            );
        }

        function activateMobileUploadFunctions() {
            // For mobile, communicate back on progress
            if ($scope.isFromMobileUpload) {
                $rootScope.$on('s3upload:start', (scope, data) => {
                    $log.debug('s3upload:start data', data);

                    if (window.updateUploadStart && typeof window.updateUploadStart === 'function') {
                        // $rootScope.$emit('s3upload:start', {uploadMethod: uploadMethod, xhr: xhr, multipartUploadObj: multipartUploadObj, upload: self.uploads[self.uploadId]});
                        window.updateUploadStart(data);
                    }
                });

                $rootScope.$on('s3upload:success', (scope, data) => {
                    $log.debug('s3upload:success data', data);

                    if (window.updateUploadSuccess && typeof window.updateUploadSuccess === 'function') {
                        // uploadMethod: 'multipart', multipartUploadObj: params.multipartUploadObj, upload: self.uploads[params.uploadId]
                        window.updateUploadSuccess(data);
                    }
                });
                $rootScope.$on('s3upload:progress', (scope, data) => {
                    $log.debug('s3upload:progress data', data);

                    if (window.updateUploadProgress && typeof window.updateUploadProgress === 'function') {
                        // var msg = {uploadMethod: 'multipart', type: 'progress', value: self.uploads[params.uploadId].progress, loaded: progress.loaded, total: progress.total, speed: self.uploads[params.uploadId].speed, upload: self.uploads[params.uploadId]};
                        window.updateUploadProgress(data);
                    }
                });
                $rootScope.$on('s3upload:abort', (scope, data) => {
                    $log.debug('s3upload:abort data', data);

                    if (window.updateUploadAborted && typeof window.updateUploadAborted === 'function') {
                        // uploadMethod: 'multipart', multipartUploadObj: params.multipartUploadObj, upload: self.uploads[params.uploadId]
                        window.updateUploadAborted(data);
                    }
                });
                $rootScope.$on('s3upload:error', (scope, data) => {
                    $log.debug('s3upload:error data', data);

                    if (window.updateUploadError && typeof window.updateUploadError === 'function') {
                        // uploadMethod: 'multipart', multipartUploadObj: params.multipartUploadObj, upload: self.uploads[params.uploadId]
                        window.updateUploadError(data);
                    }
                });
            }
        }

        $scope.uploadFile = function (
            file,
            bucket,
            key,
            artworkId,
            artworkData,
            isPreviewFile,
            isAdditionalFile,
            additionalFileId,
            additionalFile
        ) {
            $log.debug('HomeUploadController::uploadFile Uploading file', {
                file,
                bucket,
                key,
                artworkId,
            });
            activateMobileUploadFunctions();
            var deferred = $q.defer();

            // S3Uploader.getUploadArtworkOptions(opts.getOptionsUri, $scope.s3ArtworkId)
            //    .then(function (s3Options) {
            // $log.debug('HomeUploadController::uploadFile::S3Uploader.getUploadArtworkOptions Success', {s3Options: s3Options, uri: opts.getOptionsUri, artworkId: $scope.s3ArtworkId});
            // var successFunction = Partial.func(processArtwork, $scope.s3ArtworkId);
            var exraParams = {
                artworkId,
                entityType: 'artwork',
                isPreviewFile,
                isAdditionalFile,
                additionalFileId,
                additionalFile,
                artwork: artworkData || $scope.uploadFormObj,
            };

            // var s3Uri = 'https://' + $scope.s3Bucket + '.s3.amazonaws.com/';
            //                  var key = opts.folder + (new Date()).getTime() + '-' + S3Uploader.randomString(16) + '.' + selectedFileExtension;
            //                    var key = $scope.s3FolderName + '/' + file.name;
            //                    var key = $scope.s3FolderName + '/' + $scope.fileName;

            // uploadMultipart($scope.s3Bucket, s3Uri, key, opts.acl, file.type, s3Options.key, s3Options.policy, s3Options.signature, file);

            S3Uploader.upload(
                'multipart',
                bucket,
                key,
                opts.acl,
                file.type,
                null,
                null,
                null,
                file,
                exraParams,
                null
            ).then(
                (res) => {
                    deferred.resolve(res);
                },
                (error) => {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        $scope.removePreviewFile = function () {
            $scope.uploadFormObj.preview.file = [];
            delete $scope.uploadFormObj.preview.file;
            angular.element(document.querySelector('#previewFileButtonUpload')).val('');
            $scope.uploadFormObj.cover.fakeValidationPreviewModel = undefined;
            delete $scope.uploadFormObj.cover.fakeValidationPreviewModel;
            initPreviewThumbnail();
        };

        $scope.removeCoverFile = function () {
            $scope.uploadFormObj.cover.file = [];
            delete $scope.uploadFormObj.cover.file;
            angular.element(document.querySelector('#coverFileButtonUpload')).val('');
            $scope.uploadFormObj.cover.fakeValidationCoverModel = undefined;
            delete $scope.uploadFormObj.cover.fakeValidationCoverModel;
            initCoverThumbnail();
        };

        $scope.removeAdditionalFile = function (additionalFile) {
            additionalFile.file = [];
            additionalFile.fakeValidationAdditionalFileModel = undefined;
        };

        $scope.removeFiles = function () {
            $scope.uploadFormObj.files = [];
            $scope.uploadFormObj.fakeValidationModel = undefined;
            $scope.fileType = '';
            // if ($scope.player) {
            //     $scope.player.pause();
            // }
        };

        function generateFilename(filename) {
            const selectedFileExtension = filename.split('.').pop();
            return `${new Date().getTime()}-${S3Uploader.randomString(16)}.${selectedFileExtension}`;
        }

        function prepareUserDataForUpload(file, previewFile, coverFile, newAdditionalFiles) {
            var fileName = file ? file.filename || file.name : $scope.uploadFormObj.filename;
            var originalFileName = file ? file.original : $scope.uploadFormObj.original_work_filename;
            var previewOriginalFilename = previewFile ? previewFile.original : null;
            var previewFilename = previewFile
                ? previewFile.filename || previewFile.name
                : $scope.uploadFormObj.preview.filename;
            var previewSize = previewFile ? previewFile.size : null;
            var previewFileType = previewFile ? previewFile.type : null;
            var previewAdditionalFileId = $scope.uploadFormObj.preview.id;
            var previewProperties = $scope.uploadFormObj.preview.properties_json;
            var previewPropertiesChanged = !_.isEqual(
                $scope.uploadFormObj.preview.properties_json_old,
                $scope.uploadFormObj.preview.properties_json
            );
            var coverOriginalFilename = coverFile ? coverFile.original : null;
            var coverFilename = coverFile ? coverFile.filename || coverFile.name : $scope.uploadFormObj.cover.filename;
            var coverSize = coverFile ? coverFile.size : null;
            var coverFileType = coverFile ? coverFile.type : null;
            var coverAdditionalFileId = $scope.uploadFormObj.cover.id;
            var coverProperties = $scope.uploadFormObj.cover.properties_json;
            var coverPropertiesChanged = !_.isEqual(
                $scope.uploadFormObj.cover.properties_json_old,
                $scope.uploadFormObj.cover.properties_json
            );
            if (!$scope.uploadFormObj.creationMonth) {
                $scope.uploadFormObj.creationMonth = 1;
            }
            if (!$scope.uploadFormObj.creationYear) {
                $scope.uploadFormObj.creationYear = $filter('date')(new Date(), 'yyyy');
            }
            var creationDate = `${
                ($scope.uploadFormObj.creationMonth < 10 ? '0' : '') + Number($scope.uploadFormObj.creationMonth)
            }/${$scope.uploadFormObj.creationYear}`;

            var saleType = $scope.uploadFormObj.sale_type;

            if ($scope.uploadFormObj.sale_type !== 'SRB' && $scope.uploadFormObj.sale_type !== 'REN') {
                $scope.uploadFormObj.price = undefined;
                $scope.uploadFormObj.hide_price = false;
                $scope.uploadFormObj.total_copies = undefined;
            }

            var allowed_loans =
                $scope.uploadFormObj.loanRestriction < 0
                    ? $scope.uploadFormObj.loanRestriction
                    : $scope.uploadFormObj.loanRestrictionCount;
            if ($scope.uploadFormObj.artworkType === 'COLLECTOR') {
                $scope.uploadFormObj.saleType = undefined;
                $scope.uploadFormObj.currency = undefined;
                $scope.uploadFormObj.price = undefined;
                $scope.uploadFormObj.hide_price = false;
                $scope.uploadFormObj.total_copies = undefined;
                if ($scope.uploadFormObj.extra_metadata.purchase_date) {
                    // When date was parsed into string after received from server,
                    // we need to convert it to date in order to disable timezone effects
                    if (
                        Object.prototype.toString.call($scope.uploadFormObj.extra_metadata.purchase_date) !==
                        '[object Date]'
                    ) {
                        var splitDate = $scope.uploadFormObj.extra_metadata.purchase_date.split('/');
                        $scope.uploadFormObj.extra_metadata.purchase_date = new Date(
                            splitDate[2],
                            splitDate[1] - 1,
                            splitDate[0]
                        );
                    }

                    // Disabling Timezone offset
                    $scope.uploadFormObj.extra_metadata.purchase_date.setMinutes(
                        $scope.uploadFormObj.extra_metadata.purchase_date.getMinutes() -
                            $scope.uploadFormObj.extra_metadata.purchase_date.getTimezoneOffset()
                    );
                }
            } else {
                $scope.uploadFormObj.extra_metadata = {
                    style: $scope.uploadFormObj.extra_metadata.style,
                    artists_proof: $scope.uploadFormObj.extra_metadata.artists_proof,
                    medium_primary: $scope.uploadFormObj.extra_metadata.medium_primary,
                    medium_secondary: $scope.uploadFormObj.extra_metadata.medium_secondary,
                    medium_details: $scope.uploadFormObj.extra_metadata.medium_details,
                    artist_bio: $scope.uploadFormObj.extra_metadata.artist_bio,
                    artist_country: $scope.uploadFormObj.extra_metadata.artist_country,
                    artist_gallery_name: $scope.uploadFormObj.extra_metadata.artist_gallery_name,
                    audio_description: $scope.uploadFormObj.extra_metadata.audio_description,
                    show_manual_duration: $scope.uploadFormObj.extra_metadata.show_manual_duration,
                    manual_duration: $scope.uploadFormObj.extra_metadata.manual_duration,
                };
            }

            $scope.uploadFormObj.display_guidelines = {
                landscape_mode: !!$scope.uploadFormObj.display_guidelines.landscape_mode,
                portrait_mode: !!$scope.uploadFormObj.display_guidelines.portrait_mode,
                portrait_submode: $scope.uploadFormObj.display_guidelines.portrait_submode || 1,
            };

            if ($scope.uploadFormObj.sale_type === 'FRE' && $scope.uploadFormObj.isCCLicense) {
                saleType = 'FCC';
            }

            // Additional Files
            var additionalFiles = [];
            var additionalFile;
            if (newAdditionalFiles && newAdditionalFiles.length > 0) {
                newAdditionalFiles.forEach((newAdditionalFile) => {
                    additionalFile = {
                        original_filename: newAdditionalFile.file[0].original,
                        filename: newAdditionalFile.file[0].filename,
                        size: newAdditionalFile.file[0].size,
                        fileType: newAdditionalFile.file[0].type,
                        title: newAdditionalFile.title,
                        description: newAdditionalFile.description,
                        tags: newAdditionalFile.tags,
                        visible: newAdditionalFile.visible,
                        type_id: newAdditionalFile.type_id,
                        properties: newAdditionalFile.properties_json,
                    };
                    additionalFiles.push(additionalFile);
                });
            }

            // RND-458: We should zero Artist name if uploaded to Portfolio.
            if ($scope.uploadFormObj.artworkType === 'ARTIST' && User.isArtist($scope.uploader)) {
                $scope.uploadFormObj.artistFirstName = undefined;
                $scope.uploadFormObj.artistLastName = undefined;
            }
            var data = {
                artworkId: $scope.uploadFormObj.id, // For edit
                //                artworkType: $scope.collectionId // If upload was performed from collection, the collection Id should be attached
                CollId: $scope.uploadFormObj.collectionId, // If upload was performed from collection, the collection Id should be attached
                original_work_filename: originalFileName,
                filename: fileName,
                extra_metadata: $scope.uploadFormObj.extra_metadata,
                display_guidelines: $scope.uploadFormObj.display_guidelines,
                coCreators: $scope.uploadFormObj.coCreators,
                allow_videowall: $scope.uploadFormObj.allow_videowall,
                visible: $scope.uploadFormObj.visible,
                preview_wm: $scope.uploadFormObj.preview_wm ? 1 : 0,
                preview_qtype: $scope.uploadFormObj.preview_qtype,
                artworkType: $scope.uploadFormObj.artworkType,
                artistFirstName: $scope.uploadFormObj.artistFirstName,
                artistLastName: $scope.uploadFormObj.artistLastName,
                blockchain_id: $scope.uploadFormObj.blockchain_id,
                other_categorization_id: $scope.uploadFormObj.other_categorization_id,
                private_comments: $scope.uploadFormObj.private_comments,
                title: $scope.uploadFormObj.title,
                description: $scope.uploadFormObj.description,
                creation_date: creationDate,
                category: $scope.uploadFormObj.category,
                tags: $scope.uploadFormObj.tags,
                restricted: $scope.uploadFormObj.restricted,
                sale_type: saleType,
                currency: $scope.uploadFormObj.currency,
                price: $scope.uploadFormObj.price,
                hide_price: $scope.uploadFormObj.hide_price,
                total_copies: $scope.uploadFormObj.total_copies,
                allowed_loans,
                isFileChanged: !!file,
                previewFileUpload: !!previewFile,
                coverFileUpload: !!coverFile,
                uploaded_using_cloud: $scope.uploadFormObj.uploaded_using_cloud,
                cloud_temp_path: $scope.uploadFormObj.cloud_temp_path,
                credit_line_1: $scope.uploadFormObj.credit_line_1,
                credit_line_2: $scope.uploadFormObj.credit_line_2,
                default_loan_price_level: $scope.uploadFormObj.default_loan_price_level,
                default_loan_period: $scope.uploadFormObj.default_loan_period,
                link: $scope.uploadFormObj.link,
                previewOriginalFilename,
                previewFilename,
                previewSize,
                previewFileType,
                previewAdditionalFileId,
                previewProperties,
                previewPropertiesChanged,
                coverOriginalFilename,
                coverFilename,
                coverSize,
                coverFileType,
                coverAdditionalFileId,
                coverProperties,
                coverPropertiesChanged,
                additionalFiles,
                engine_tasks_priority: $scope.uploadFormObj.engine_tasks_priority,
            };

            if (file && file.fromCloud) {
                // Cloud
                data.cloud_source = file.cloud_source;
                data.uploaded_using_cloud = true;
                data.cloud_temp_path = file.key ? file.key : file.url; // in case of big files from Filestack, we save the CDN path and wait for Webhook
                data.cloud_original_store_key = file.originalKey;
            }
            return data;
        }

        function prepareDefaultDataForUpload(file) {
            // if (file && file.filename) {
            //    var originalFilename;
            //    if (file.filename) {
            //        originalFilename = file.filename;
            //    } else {
            //        originalFilename = file.name;
            //    }
            // }
            // var fileName = file ? (file.name || file.filename.split('_').shift()) : $scope.uploadFormObj.filename;
            var fileName = file ? file.filename || file.name : $scope.uploadFormObj.filename;
            var originalFileName = file ? file.original : null;
            var cloudSource = file && file.source ? file.source : null;

            var data = {
                original_work_filename: originalFileName,
                filename: fileName,
                visible: false,
                artworkType: $scope.uploadFormObj.artworkType,
                title: originalFileName.substr(0, 60),
                creation_date: $filter('date')(new Date(), 'MM/yyyy'),
                category: 'MI',
                restricted: 'E',
                sale_type: 'WIP',
                isFileChanged: true,
                allow_videowall: $scope.uploadFormObj.allow_videowall,
                cloud_source: cloudSource,
            };

            if (file && file.fromCloud) {
                // Cloud
                data.uploaded_using_cloud = true;
                data.cloud_temp_path = file.key ? file.key : file.url; // in case of big files from Filestack, we save the CDN path and wait for Webhook
                data.cloud_original_store_key = file.originalKey;
            }

            return data;
        }

        function prepareDataForUpload(file, previewFile, coverFile, newAdditionalFiles, useDefaultData) {
            var deferred = $q.defer();
            var data;

            S3Uploader.generateCognitoIdentity().then(
                (cognitoId) => {
                    if (useDefaultData && !$scope.uploadFormObj.id) {
                        data = prepareDefaultDataForUpload(file);
                    } else {
                        data = prepareUserDataForUpload(file, previewFile, coverFile, newAdditionalFiles);
                    }
                    data.cognitoId = cognitoId || $localStorage.cognitoId;
                    deferred.resolve(data);
                },
                (error) => {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function sendFormToServer(
            file,
            previewFile,
            coverFile,
            newAdditionalFiles,
            useDefaultData,
            onlyProcess,
            fromFilestack
        ) {
            var deferred = $q.defer();
            $rootScope.$broadcast('upload_start-prepare-data-for-upload');
            prepareDataForUpload(file, previewFile, coverFile, newAdditionalFiles, useDefaultData).then(
                (data) => {
                    $http.post('/artworks/createNewArtwork', data).then(
                        (res) => {
                            if (useDefaultData && !fromFilestack) {
                                // if fromFilestack processing will be done from backend after a Webhook from Filestack
                                if (onlyProcess) {
                                    var params = {
                                        fromCloud: true,
                                        tempPath: file.key,
                                    };
                                    ArtworkService.processArtwork(res.data.data.s3.artworkId, params);
                                } else {
                                    $scope.uploadFile(
                                        file,
                                        res.data.data.s3.bucket,
                                        res.data.data.s3.key,
                                        res.data.data.s3.artworkId,
                                        data
                                    );
                                }
                            }

                            if (fromFilestack && $scope.isFromMobileUpload) {
                                if ((fromFilestack || fromCloud) && $scope.isFromMobileUpload) {
                                    const mobileAppFunc = window.cloudUploadFinished || window.filestackUploadFinished;
                                    mobileAppFunc?.({ artworkId: res.data.data.s3.artworkId });
                                }
                            }

                            $log.debug('HomeUploadController::sendFormToServer::prepareDataForUpload Success', res);
                            deferred.resolve(res);
                        },
                        (error) => {
                            $log.debug('HomeUploadController::sendFormToServer::prepareDataForUpload Failure', error);
                            var errors = error.data && error.data.message ? error.data.message.text : error.data.errors;
                            var messageParams = {};
                            if ($scope.uploadFormObj.id) {
                                messageParams.title = 'Error Saving Artwork';
                                messageParams.message = `Failed to save artwork ${data.title}'s settings. ${errors}`;
                            } else {
                                messageParams.title = 'Error Creating Artwork';
                                messageParams.message = `Failed to create artwork ${data.title}. ${errors}`;
                            }

                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams);
                            deferred.reject(error);
                        }
                    );
                },
                (error) => {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        $scope.loadTags = function ($query) {
            var data = {
                query: $query,
            };

            return $http.post('/tags/getTags', data).then((res) => {
                var tags = [];
                res.data.tags.forEach((tag) => {
                    tags.push({ text: tag.Tag.name });
                });
                return tags;
            });
        };
        $scope.maxDate = new Date();
        $scope.openDatePicker = function (event) {
            event.preventDefault();
            event.stopPropagation();

            $scope.uploadFormObj.extra_metadata.datePickerIsOpen = true;
        };

        $scope.redirectBackHome = function () {
            var homeOrProfile = $scope.homeOrUrl === 'home' ? 'home' : 'profile';
            var parameters;
            if ($scope.params && $scope.params.type === 'modal') {
                Messages.ok();
                $rootScope.$broadcast('batch_files_upload_started');
            } else if ($stateParams.eventId && $stateParams.eventId > 0) {
                parameters = { eventId: $stateParams.eventId };
                if ($scope.homeOrUrl) {
                    parameters.url = $scope.homeOrUrl;
                }
                $state.go(`${homeOrProfile}.events.submission.artworks`, parameters);
            } else if (window.history && window.history.length > 2) {
                window.history.back();
            } else if ($stateParams.collectionId && $stateParams.collectionLink) {
                parameters = {
                    collectionId: $stateParams.collectionId,
                    collectionLink: $stateParams.collectionLink,
                    filterArtist: $stateParams.filterArtist,
                };
                if ($scope.homeOrUrl) {
                    parameters.url = $scope.homeOrUrl;
                }
                $state.go(`${homeOrProfile}.${$scope.from}.main`, parameters);
            } else {
                parameters = { collectionId: 0, collectionLink: 'all' };
                if ($scope.homeOrUrl) {
                    parameters.url = $scope.homeOrUrl;
                }
                if ($stateParams.filterArtist !== undefined) {
                    parameters.filterArtist = $stateParams.filterArtist;
                }
                $state.go(`${homeOrProfile}.${$scope.from}.main`, parameters);
            }
        };

        function removeSpecialCharacters(str) {
            if (str === null || str === '') {
                return false;
            }
            str = str.toString();

            // return str.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
            return str.replace(/[^a-z0-9-._ ]+/gi, '');
        }

        function openPreparingMessage() {
            var messageParams = {};
            messageParams.disableAutoDismiss = true;
            messageParams.blur = true;
            messageParams.hideHeader = true;
            messageParams.hideFooter = true;
            messageParams.title = 'Preparing Your Artworks';
            messageParams.message = 'Please wait while we import the artworks...';
            Messages.openMessage($rootScope, messageParams);
        }

        function handleBatchUpload(deferred, files) {
            var fileIsValid;
            if (files.length > $scope.batchMaxFiles) {
                $scope.submitWasClicked = false;
                $log.debug('HomeUploadController::handleBatchUpload batch upload exceeded size limit');
                var messageParams = {};
                messageParams.title = 'Maximum Artworks Reached';
                messageParams.message = `Please select not more than ${$scope.batchMaxFiles} artworks to upload.`;
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
                $scope.removeFiles();
                deferred.reject();
            } else {
                S3Uploader.generateCognitoIdentity().then(
                    (cognitoId) => {
                        $log.debug('HomeUploadController::handleBatchUpload batch upload, generated cognito identity');
                        $localStorage.cognitoId = cognitoId;

                        var i = 0;
                        var uploadsPromises = [];
                        var messages = [];
                        // Batch upload
                        setNewFilenameToFiles(files);

                        for (i; i < files.length; i++) {
                            fileIsValid = $scope.validateFile(files[i]);
                            if (fileIsValid.result) {
                                uploadsPromises.push(
                                    sendFormToServer(
                                        files[i],
                                        /* preview file */ null,
                                        /* cover file */ null,
                                        /* new additional files */ null,
                                        true /* useDefaultData */
                                    )
                                );
                            } else {
                                messages.push({
                                    text: fileIsValid.messageParams.message,
                                    type: fileIsValid.type,
                                    typeText: fileIsValid.typeText,
                                });
                            }
                        }

                        openPreparingMessage();
                        $q.all(uploadsPromises).then(
                            (result) => {
                                $log.debug('HomeUploadController::handleBatchUpload multiple files upload began');
                                deferred.resolve();
                                if (messages.length > 0) {
                                    messageParams = {};
                                    messageParams.title = "Some Files Couldn't Be Uploaded";
                                    messageParams.message = 'These files were not uploaded:\n';
                                    for (i = 0; i < messages.length; i++) {
                                        messageParams.message += `${messages[i].text}\n`;
                                    }

                                    var types = [];
                                    var typeFound;
                                    _.each(messages, (message) => {
                                        // typeFound = _.find(types, Partial.func(compareTypes, type, messages[i].type));
                                        typeFound = _.find(types, (type) => {
                                            return type.type === message.type;
                                        });
                                        if (!typeFound) {
                                            types.push({
                                                type: message.type,
                                                typeText: message.typeText,
                                            });
                                        }
                                    });

                                    if (types.length > 0) {
                                        messageParams.message += '\n';
                                        for (i = 0; i < types.length; i++) {
                                            messageParams.message += `${types[i].typeText}\n`;
                                        }
                                    }

                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(() => {
                                        Messages.ok();
                                        if ($state.current.name !== 'home.mobileUpload') {
                                            $scope.redirectBackHome();
                                        }
                                    });
                                } else {
                                    Messages.ok();
                                    if ($state.current.name !== 'home.mobileUpload') {
                                        $scope.redirectBackHome();
                                    }
                                }
                            },
                            (error) => {
                                if ($state.current.name !== 'home.mobileUpload') {
                                    Messages.ok();
                                }
                            }
                        );
                    },
                    (error) => {
                        $log.debug(
                            'HomeUploadController::handleBatchUpload batch upload, failed to generate cognito identity'
                        );
                        deferred.reject(error);
                    }
                );
            }
        }

        function setNewFilenameToFile(file, fromCloud) {
            var fileName = file ? file.name || file.filename : $scope.uploadFormObj.filename;
            file.original = fileName;
            if (!fromCloud) {
                file.filename = generateFilename(fileName);
            }
        }

        function setNewFilenameToFiles(files, fromCloud) {
            _.each(files, (file) => {
                if (file.filename || file.name) {
                    setNewFilenameToFile(file, fromCloud);
                } else if (file.file && file.file[0] && (file.file[0].filename || file.file[0].name)) {
                    setNewFilenameToFile(file.file[0], fromCloud);
                } else {
                    $log.debug('HomeUploadController::setNewFilenameToFiles file not found', file);
                    var messageParams = {};
                    messageParams.title = 'Error uploading artwork';
                    messageParams.message = 'Failed to rename artwork. Please refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            });
        }

        function handleSingleUpload(deferred, files, previewFile, coverFile, newAdditionalFiles) {
            var fileIsValid;
            var coverFileIsValid;
            var previewFileIsValid;
            // Single file - edit form
            if (files[0]) {
                fileIsValid = $scope.validateFile(files[0]);

                if (!fileIsValid.result) {
                    if (fileIsValid.typeText) {
                        fileIsValid.messageParams.message += fileIsValid.typeText;
                    }
                    Messages.openMessage($rootScope, fileIsValid.messageParams);
                    $scope.removeFiles();
                    deferred.reject();
                } else {
                    setNewFilenameToFile(files[0]);
                }
            }

            if (previewFile) {
                previewFileIsValid = $scope.validateFile(previewFile, 'preview');

                if (!previewFileIsValid) {
                    $scope.removePreviewFile();
                    deferred.reject();
                } else {
                    setNewFilenameToFile(previewFile);
                }
            }

            if (coverFile) {
                coverFileIsValid = $scope.validateFile(coverFile, 'cover');

                if (!coverFileIsValid) {
                    $scope.removeCoverFile();
                    deferred.reject();
                } else {
                    setNewFilenameToFile(coverFile);
                }
            }

            if (newAdditionalFiles) {
                setNewFilenameToFiles(newAdditionalFiles);
            }

            sendFormToServer(files[0], previewFile, coverFile, newAdditionalFiles).then(
                (res) => {
                    var noError = true;

                    // If new media file, upload it
                    if (files[0]) {
                        if (res.data.data.s3) {
                            $scope
                                .uploadFile(
                                    files[0],
                                    res.data.data.s3.bucket,
                                    res.data.data.s3.key,
                                    res.data.data.s3.artworkId
                                )
                                .then((res) => {
                                    $log.debug(
                                        'HomeUploadController::handleSingleUpload::sendFormToServer::uploadFile Successfully uploaded file',
                                        { res, file: files[0] }
                                    );
                                });
                        } else {
                            noError = false;
                        }
                    }

                    // If new preview file, upload it
                    if (previewFile) {
                        $scope.uploadFile(
                            previewFile,
                            res.data.data.previewS3.bucket,
                            res.data.data.previewS3.key,
                            res.data.data.previewS3.artworkId,
                            /* artwork data */ null,
                            /* isPreview */ true,
                            /* is additional file */ false,
                            res.data.data.previewS3.additionalFileId,
                            previewFile
                        );
                    }

                    if (coverFile) {
                        $scope.uploadFile(
                            coverFile,
                            res.data.data.coverS3.bucket,
                            res.data.data.coverS3.key,
                            res.data.data.coverS3.artworkId,
                            /* artwork data */ null,
                            /* isPreview */ true,
                            /* is additional file */ false,
                            res.data.data.coverS3.additionalFileId,
                            coverFile
                        );
                    }

                    if (newAdditionalFiles) {
                        var i = 0;
                        var uploadsPromises = [];
                        // Batch upload
                        for (i; i < newAdditionalFiles.length; i++) {
                            if (res.data.data.additionalFilesS3[i] && newAdditionalFiles[i]) {
                                uploadsPromises.push(
                                    $scope.uploadFile(
                                        newAdditionalFiles[i].file[0],
                                        res.data.data.additionalFilesS3[i].bucket,
                                        res.data.data.additionalFilesS3[i].key,
                                        res.data.data.additionalFilesS3[i].artworkId,
                                        /* artwork data */ null,
                                        /* isPreview */ false,
                                        /* is additional file */ true,
                                        res.data.data.additionalFilesS3[i].additionalFileId,
                                        newAdditionalFiles[i]
                                    )
                                );
                            }
                        }

                        if (uploadsPromises.length > 0) {
                            $q.all(uploadsPromises).then(
                                (result) => {
                                    $log.debug(
                                        'HomeUploadController::handleSingleUpload Success multiple additional files upload began'
                                    );
                                },
                                (error) => {
                                    $log.debug(
                                        'HomeUploadController::handleSingleUpload Failure multiple additional files upload began'
                                    );
                                }
                            );
                        }
                    }

                    if (noError) {
                        deferred.resolve();
                        setTimeout(() => {
                            $scope.submitWasClicked = false;
                            $scope.redirectBackHome();
                        }, 1000);
                    } else {
                        $scope.submitWasClicked = false;
                        deferred.reject();
                    }
                },
                (reason) => {
                    $log.debug('HomeUploadController::handleSingleUpload Failure', reason);

                    const messageParams = {};
                    messageParams.title = 'Error uploading artwork';
                    messageParams.message = 'Failed to upload artwork. Please refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);

                    deferred.reject(reason);
                }
            );
        }

        // Co-creator

        $scope.addCoCreator = function () {
            if (!$scope.uploadFormObj.coCreators) {
                $scope.uploadFormObj.coCreators = [];
            }
            var coCreator = {
                first: '',
                last: '',
            };
            $scope.uploadFormObj.coCreators.push(coCreator);
        };

        $scope.onArtworkTypeChange = function () {
            $scope.uploadFormObj.artistFirstName = undefined;
            $scope.uploadFormObj.artistLastName = undefined;
            $scope.clearCheckboxState();
        };

        $scope.updateCheckboxState = function (model, force) {
            if (force) {
                $scope.uploadFormObj[model] = force;
            } else {
                if ($scope.uploadForm.$pristine && $scope.uploadFormObj.id) {
                    $scope.uploadFormObj[model] = true;
                } else {
                    $scope.uploadFormObj[model] = false;
                }
            }
        };

        $scope.clearCheckboxState = function () {
            $scope.uploadFormObj.amTheArtist = false;
            // $scope.uploadFormObj.termAndConditions = false;
        };

        $scope.onPreviewFileChange = function (file) {
            // Validate File
            var fileIsValid = $scope.validateFile(file, 'preview');

            if (!fileIsValid.result) {
                if (fileIsValid.messageParams) {
                    if (fileIsValid.typeText) {
                        fileIsValid.messageParams.message += fileIsValid.typeText;
                    }
                    Messages.openMessage($rootScope, fileIsValid.messageParams);
                }
                $scope.removePreviewFile();
            } else {
                createViewablePreview($scope.uploadFormObj.preview.file[0]).then(
                    (res) => {
                        $scope.toggles.previewThumb = $sce.trustAsResourceUrl(res);
                        $scope.toggles.previewIsImage = isImageByPath($scope.uploadFormObj.preview.file[0].name);
                        if (!$scope.uploadFormObj.preview.properties_json) {
                            $scope.uploadFormObj.preview.properties_json = {};
                        }
                        // No need to set when the parameter by default is false
                        // $scope.uploadFormObj.preview.properties_json.preventOriginalPlayback = false;
                    },
                    (err) => {
                        $scope.removePreviewFile();
                        var messageParams = {};
                        messageParams.title = 'Error loading preview';
                        messageParams.message = 'Preview file is not supported, please try different format or file.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                );
            }
        };

        $scope.onCoverFileChange = function (file) {
            // Validate File
            var fileIsValid = $scope.validateFile(file, 'cover');

            if (!fileIsValid.result) {
                if (fileIsValid.messageParams) {
                    if (fileIsValid.typeText) {
                        fileIsValid.messageParams.message += fileIsValid.typeText;
                    }
                    Messages.openMessage($rootScope, fileIsValid.messageParams);
                }
                $scope.removeCoverFile();
            } else {
                createViewablePreview($scope.uploadFormObj.cover.file[0]).then((res) => {
                    $scope.toggles.coverThumb = $sce.trustAsResourceUrl(res);
                });
            }
        };

        $scope.onFileChange = function () {
            if ($scope.uploadFormObj.id) {
                this.clearCheckboxState();
            }
            // Validate File
            var fileIsValid = $scope.validateFile($scope.uploadFormObj.files[0]);

            if (!fileIsValid.result) {
                if (fileIsValid.messageParams) {
                    if (fileIsValid.typeText) {
                        fileIsValid.messageParams.message += fileIsValid.typeText;
                    }
                    Messages.openMessage($rootScope, fileIsValid.messageParams);
                }
                $scope.removeFiles();
            }
        };

        $scope.initVisible = function () {
            if ($scope.uploadFormObj.visible === undefined) {
                $scope.uploadFormObj.visible = false;
            }
        };

        $scope.initPreviewSettings = function () {
            if ($scope.uploadFormObj.preview_wm === undefined) {
                $scope.uploadFormObj.preview_wm = true;
            }
            if ($scope.uploadFormObj.preview_qtype === undefined) {
                $scope.uploadFormObj.preview_qtype = '720';
            }
        };

        $scope.initSaleType = function () {
            if (!$scope.uploadFormObj.sale_type) {
                $scope.uploadFormObj.sale_type = 'SRB';
            }
        };

        $scope.initLoanRestrictions = function () {
            if ($scope.uploadFormObj.loanRestriction === undefined) {
                $scope.uploadFormObj.loanRestriction = -1; // Unlimit.
                $scope.uploadFormObj.loanRestrictionCount = BUSINESS_RESTRICTIONS.DEFAULT_NUM_LOAN_AWS;
            } else if (
                $scope.uploadFormObj.loanRestriction === -2 &&
                $scope.uploadFormObj.sale_type !== 'SRB' &&
                $scope.uploadFormObj.sale_type !== 'REN'
            ) {
                $scope.uploadFormObj.loanRestriction = -1; // Unlimit.
            }
        };

        $scope.initTotalCopies = function () {
            if (!$scope.uploadFormObj.total_copies) {
                $scope.uploadFormObj.total_copies = 12;
            }
        };

        $scope.closeCustomEdition = function () {
            if (typeof $scope.uploadFormObj.total_copies !== 'number') {
                $scope.uploadFormObj.total_copies = 12;
            } else {
                if ($scope.uploadFormObj.total_copies % 1 !== 0) {
                    $scope.uploadFormObj.total_copies = Math.floor($scope.uploadFormObj.total_copies);
                }
            }
        };

        $scope.warnIfUserHidden = function () {
            if (!User.isVisible($scope.uploader) && $scope.uploadFormObj.visible) {
                var messageParams = {};
                messageParams.title = 'Account Is Hidden';
                messageParams.message =
                    'Please note: Even though the artwork state is visible, your account is currently hidden. The artwork will become visible when the account will turn visible as well.';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
            }
        };

        $scope.loadTags = function ($query) {
            var data = {
                query: $query,
            };

            return $http.post('/tags/getTags', data).then((res) => {
                var tags = [];
                res.data.tags.forEach((tag) => {
                    tags.push({ text: tag.Tag.name });
                });
                return tags;
            });
        };

        $scope.openArtistBio = function () {
            OpenPopupForm.openMenu('uploadFormArtistBio', {
                uploadFormObj: $scope.uploadFormObj,
                uploadForm: $scope.uploadForm,
            });
        };

        function initOrientationMode() {
            if (
                !$scope.uploadFormObj.display_guidelines.portrait_mode ||
                $scope.uploadFormObj.display_guidelines.landscape_mode
            ) {
                $scope.uploadFormObj.display_guidelines.landscape_mode = true;
            }
        }

        $scope.toggleOrientationMode = function () {
            if ($scope.uploadFormObj.display_guidelines.portrait_mode) {
                $scope.uploadFormObj.display_guidelines.portrait_submode =
                    $scope.uploadFormObj.display_guidelines.portrait_submode || 1;
            } else {
                if (!$scope.uploadFormObj.display_guidelines.landscape_mode) {
                    $scope.uploadFormObj.display_guidelines.landscape_mode = true;
                }
            }
        };

        $scope.addNewAdditionalFile = function () {
            var totalFiles = 0;
            totalFiles += $scope.uploadFormObj.newAdditionalFiles ? $scope.uploadFormObj.newAdditionalFiles.length : 0;
            totalFiles += $scope.uploadFormObj.additionalFiles ? $scope.uploadFormObj.additionalFiles.length : 0;
            totalFiles += $scope.uploadFormObj.newAdditionalFilesTemp
                ? $scope.uploadFormObj.newAdditionalFilesTemp.length
                : 0;
            var messageParams = {};
            if ($scope.uploadFormObj?.newAdditionalFilesTemp?.length > 0 && totalFiles <= $scope.maxAdditionalFiles) {
                // var newAdditionalFile = {
                //    title: '',
                //    description: '',
                //    tags: '',
                //    visible: false,
                //    file: $scope.uploadFormObj.newAdditionalFilesTemp
                // };
                // $scope.uploadFormObj.newAdditionalFiles.push(newAdditionalFile);
                // $scope.$apply();

                var newAdditionalFile;
                var bigAdditionalFiles = [];
                var isFileAudio = false;
                for (let i = 0; i < $scope.uploadFormObj.newAdditionalFilesTemp.length; i++) {
                    let file = $scope.uploadFormObj.newAdditionalFilesTemp[i];
                    if (file.size < $scope.maxAdditionalFileSize) {
                        var type = file.type;
                        var extension = file.name.split('.').pop().toLowerCase();
                        isFileAudio = isAudio(type, extension);

                        newAdditionalFile = {
                            title: file.name
                                .substr(0, file.name.lastIndexOf('.'))
                                .substr(0, FormValidationService.titleCharLimit), // Filename without extension + limit to 60 characters
                            description: '',
                            tags: '',
                            visible: true,
                            file: [file],
                            new: true,
                            type_id: '3',
                            isAudio: isFileAudio,
                        };
                        $scope.uploadFormObj.newAdditionalFiles.push(newAdditionalFile);
                        $scope.$apply();
                    } else {
                        bigAdditionalFiles.push(file);
                    }
                }

                if (bigAdditionalFiles.length > 0) {
                    messageParams = {};
                    messageParams.message = `Maximum file size allowed is ${$filter('bytes')(
                        $scope.maxAdditionalFileSize
                    )}. The following files were not added: \n`;
                    bigAdditionalFiles.forEach((file) => {
                        messageParams.message += `${file.name} (${$filter('bytes')(file.size)})\n`;
                    });
                    messageParams.title = 'Files Exceeded Maximum Allowed Size';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }

                // for (var i = 0; i < $scope.uploadFormObj.newAdditionalFilesTemp.length; i++) {
                //    newAdditionalFile = {
                //        title: '',
                //        description: '',
                //        tags: '',
                //        visible: false,
                //        file: $scope.uploadFormObj.newAdditionalFilesTemp[i]
                //    };
                //
                //    $scope.uploadFormObj.newAdditionalFiles.push(newAdditionalFile);
                // }
                // $scope.$apply();

                if ($scope.uploadFormObj.newAdditionalFiles.length > 0 && !$scope.toggles.additionalFilesIsOpen) {
                    $scope.toggles.additionalFilesIsOpen = true;
                    $scope.$apply();
                }
            } else {
                messageParams = {};
                messageParams.message = `The maximum amount of files allowed per artwork is 15, current amount is ${totalFiles}.`;
                messageParams.title = 'Number of Files Exceeded Maximum Amount';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
            }
        };

        $scope.removeNewAdditionalFile = function (newAdditionalFile, newAdditionalFileIndex) {
            $scope.removeAdditionalFile(newAdditionalFile);
            $scope.uploadFormObj.newAdditionalFiles.splice(newAdditionalFileIndex, 1);
            if (
                $scope.uploadFormObj.additionalFiles.length === 0 &&
                $scope.uploadFormObj.newAdditionalFiles.length === 0
            ) {
                $scope.toggles.additionalFilesIsOpen = false;
            }
        };

        $scope.updateAdditionalFile = function (additionalFile, additionalFileIndex) {
            var data = angular.copy(additionalFile);
            data.artworkId = data.artwork_id;
            data.additionalFileId = data.id;

            additionalFile.updating = true;

            return (
                $http
                    .post('/additionalFiles/updateEntityAdditionalFile', data)
                    // .then(function (res) {
                    //     var messageParams = {};
                    //     messageParams.message = 'Successfully updated the file';
                    //     messageParams.title = 'Update File';
                    //     Messages.openMessage($rootScope, messageParams);
                    // })
                    .finally((res) => {
                        additionalFile.updating = false;
                        additionalFile.isChanged = false;
                    })
            );
        };

        $scope.deleteAdditionalFile = function (additionalFile, additionalFileIndex) {
            var messageParams = {};

            messageParams.message = 'You are about to delete this file from this artwork.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete File';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then((res) => {
                if (res === 'ok') {
                    var data = {
                        artworkId: additionalFile.artwork_id,
                        additionalFileId: additionalFile.id,
                    };

                    additionalFile.deleting = true;

                    return $http
                        .post('/additionalFiles/deleteEntityAdditionalFile', data)
                        .then((res) => {
                            var messageParams = {};
                            messageParams.message = 'Successfully deleted the file';
                            messageParams.title = 'Delete File';
                            Messages.openMessage($rootScope, messageParams);
                            if (additionalFileIndex !== undefined) {
                                $scope.uploadFormObj.additionalFiles.splice(additionalFileIndex, 1);
                            }
                            if (additionalFile.type_id === '1') {
                                // Preview
                                $scope.uploadFormObj.preview = {};
                                $scope.toggles.previewThumb = undefined;
                            }
                            if (additionalFile.type_id === '2') {
                                // Thumbnail
                                $scope.uploadFormObj.cover = {};
                                $scope.toggles.coverThumb = undefined;
                            }
                            if (
                                $scope.uploadFormObj.additionalFiles.length === 0 &&
                                $scope.uploadFormObj.newAdditionalFiles.length === 0
                            ) {
                                $scope.toggles.additionalFilesIsOpen = false;
                            }
                        })
                        .finally((res) => {
                            additionalFile.deleting = false;
                            additionalFile.isChanged = false;
                        });
                }
            });
        };

        $scope.additionalFileStatusPopover = function (additionalFile) {
            var popover = '';

            if (additionalFile.status && additionalFile.status !== 'READY') {
                if (additionalFile.status === 'UPLOADING') {
                    popover = 'File is being uploaded or was interrupted in the middle of uploading';
                } else {
                    popover = 'File failed to upload, please delete this line and re-upload';
                }
            } else if (additionalFile.file && additionalFile.file.length > 0) {
                popover = 'File will begin to upload when the form will be submitted';
            }

            return popover;
        };

        $scope.additionalFileTypeSelected = function (changedAdditionalFile, originalAdditionalFile) {
            if (changedAdditionalFile.type_id === '4') {
                var messageParams = {};

                if (!changedAdditionalFile.isAudio) {
                    changedAdditionalFile.type_id = '3';
                    messageParams.message = 'This file is not an audio file. Main educational file can only be audio';
                    messageParams.title = 'Educational File';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                } else {
                    // Check if another additionalFile is educational
                    var audioAdditionalExists = _.find($scope.uploadFormObj.additionalFiles, (additionalFile) => {
                        return additionalFile !== changedAdditionalFile && additionalFile.type_id === '4';
                    });

                    if (!audioAdditionalExists) {
                        audioAdditionalExists = _.find($scope.uploadFormObj.newAdditionalFiles, (additionalFile) => {
                            return additionalFile !== changedAdditionalFile && additionalFile.type_id === '4';
                        });
                    }

                    if (audioAdditionalExists) {
                        changedAdditionalFile.type_id = '3';
                        messageParams = {};
                        messageParams.message = `Only 1 file can be typed as educational audio file.\nPlease change "${audioAdditionalExists.title}" type to regular and try again`;
                        messageParams.title = 'Educational File';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    } else {
                        if (changedAdditionalFile.title.indexOf('Educational Audio: ') < 0) {
                            changedAdditionalFile.title = `Educational Audio: ${changedAdditionalFile.title}`;
                        }
                    }
                }
            } else {
                // Remove the added Educational Audio:
                if (
                    originalAdditionalFile &&
                    originalAdditionalFile.type_id === '4' &&
                    changedAdditionalFile.type_id !== '4'
                ) {
                    if (changedAdditionalFile.title.indexOf('Educational Audio: ') === 0) {
                        changedAdditionalFile.title = changedAdditionalFile.title.replace('Educational Audio: ', '');
                    }
                }
            }
        };

        // Local upload
        $scope.upload = (batch) => {
            console.log($scope.uploadFormObj);
            let deferred = $q.defer();
            let files = $scope.uploadFormObj.files;
            let previewFile;
            let coverFile;
            let newAdditionalFiles;

            if ($scope.uploadFormObj.preview.file && $scope.uploadFormObj.preview.file[0]) {
                previewFile = $scope.uploadFormObj.preview.file[0];
            }

            if ($scope.uploadFormObj.cover.file && $scope.uploadFormObj.cover.file[0]) {
                coverFile = $scope.uploadFormObj.cover.file[0];
            }

            if ($scope.uploadFormObj.newAdditionalFiles.length > 0) {
                newAdditionalFiles = $scope.uploadFormObj.newAdditionalFiles;
            }

            $scope.submitWasClicked = true;

            if (batch) {
                handleBatchUpload(deferred, files); // Will handle promise as well
            } else {
                handleSingleUpload(deferred, files, previewFile, coverFile, newAdditionalFiles);
            }

            return deferred.promise;
        };

        // Cloud upload
        $scope.uploadUsingCloud = () => {
            if (!openMobileUploadInBrowser()) {
                uploadUsingUppy();
            }
        };

        function openMobileUploadInBrowser() {
            if (typeof window.openInBrowser !== 'function') {
                return false;
            }

            let urlToOpen = new URL(`${location.origin}/mobile_upload_cloud${location.search}`);
            urlToOpen.searchParams.set('token', TokenService.getToken());
            urlToOpen.searchParams.set('publicToken', TokenService.getOrCreatePublicToken());
            urlToOpen.searchParams.set('returnToApp', 'true');
            window.openInBrowser(urlToOpen.href);
            return true;
        }

        // Uppy
        function uploadUsingUppy(specificProvider = null, params) {
            let providers = ['GoogleDrive', 'Dropbox', 'OneDrive', 'IPFS', 'Url', 'OpenSea'];
            let ignoredProviders = specificProvider ? providers.filter((i) => i !== specificProvider) : [];
            let artworkId = $scope.uploadFormObj.id; // isEdit
            let cryptoAddress = params?.address || null;
            if (artworkId) {
                ignoredProviders.push('IPFS');
                ignoredProviders.push('OpenSea');
            }
            let finalProviders = providers.filter((provider) => !ignoredProviders.includes(provider));
            const specificProviderQuery = specificProvider ? `&open=${specificProvider}` : '';

            if (window.navigator.onLine) {
                HostIframeSdk.openPortal(
                    `/micro-fe/uppyUploader?providers=${finalProviders.join(',')}&artworkId=${artworkId}&artworkType=${
                        $scope.uploadFormObj.artworkType
                    }${specificProviderQuery}&cryptoAddress=${cryptoAddress}`,
                    { hideHeader: false }
                );

                if ($stateParams.returnToApp) {
                    $scope.$on('closePortal', (event, data) => {
                        // Return to app when all files started to progress
                        $scope.returnToAppLink = data.returnLink;
                        const newWin = window.open($scope.returnToAppLink); // Open new tab with deeplink that will direct to the app
                        if (newWin) {
                            newWin.addEventListener('load', () => {
                                newWin.focus();
                                newWin.close();
                            });
                            $state.go('home');
                        } else {
                            location.href = $scope.returnToAppLink;
                        }

                        setTimeout(() => {
                            window.close(); // probably won't work... try Close current tab
                        }, 5000);
                    });
                }
            }
        }

        $scope.returnToAppButton = () => {
            // For iOS
            location.href = $scope.returnToAppLink;
            $state.go('home');
        };

        $scope.returnToHomeButton = () => {
            $state.go('home');
        };

        // Events
        $rootScope.$on('upload_finish_redirect', $scope.redirectBackHome);

        $scope.$on('$destroy', () => {
            $scope.cleanupFuncs.forEach((func) => func());
        });
    },
]);
