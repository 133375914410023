'use strict';

angular
    .module('admin-area')
    .controller('AdminAreaCmsUtilsController', [
        '$scope',
        '$http',
        '$log',
        'Messages',
        '_',
        function ($scope, $http, $log, Messages, _) {},
    ]);
