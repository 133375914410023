'use strict';

angular.module('home').controller('ChannelSubscribersController', [
    '$rootScope',
    '$http',
    '$q',
    '$log',
    '_',
    'Messages',
    function ($rootScope, $http, $q, $log, _, Messages) {
        var self = this;
        this.Messages = Messages;
        this.loading = false;
        this.itemsPerPage = 20;
        this.currentPage = 1;
        this.maxSize = 5;
        this.searchUserControl = {};

        function getSubscriptions(channelId) {
            var deferred = $q.defer();

            var data = {
                channelId: channelId,
            };

            $http.post('/channelSubscriptions/getChannelSubscriptions', data).then(
                function (res) {
                    self.subscribers = _.each(res.data.subscribers, function (subscriber, index) {
                        subscriber.index = index;
                    });
                    $log.debug('ChannelSubscribersController::getSubscriptions Success', { res: res });
                    deferred.resolve(res);
                },
                function (error) {
                    $log.debug('ChannelSubscribersController::getSubscriptions Failure', { error: error });
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        this.removeSubscriber = function (subscriber) {
            var deferred = $q.defer(),
                data = {
                    channelId: subscriber.channel_id,
                    userId: subscriber.user_id,
                },
                messageParams = {};
            messageParams.title = 'Remove Subscriber';
            messageParams.message = 'You are about to remove this subscriber from this channel.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.okText = 'Remove';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.processing = true;
                    $http
                        .post('/channelSubscriptions/unsubscribeUserFromChannel', data)
                        .success(function (res) {
                            $log.debug('ChannelService::unsubscribe User Success', res, data);
                            self.refreshSubscribers().then(
                                function () {
                                    deferred.resolve(res);
                                },
                                function (reason) {
                                    deferred.reject(reason);
                                }
                            );
                        })
                        .error(function (reason, status) {
                            $log.debug('ChannelService::unsubscribe User Failure', reason, data);
                            deferred.reject(reason);
                        })
                        .finally(function () {
                            self.processing = false;
                        });
                } else {
                    deferred.resolve();
                }
            });
            return deferred.promise;
        };

        this.selectedSubscriber = undefined;
        this.onUserSelected = function (item) {
            self.selectedSubscriber = item;
            delete self.selectedSubscriber.$invalid$alreadySubscribed;
            if (self.subscribers) {
                var existingSubscriber = _.find(self.subscribers, function (subscriber) {
                    return subscriber.user_id === item.id;
                });
                if (existingSubscriber !== undefined) {
                    self.selectedSubscriber.$invalid$alreadySubscribed = true;
                }
            }
        };

        this.clearSelectedSubscriber = function () {
            self.selectedSubscriber = undefined;
            if (self.searchUserControl.clear !== undefined) {
                self.searchUserControl.clear();
            }
        };
        this.addSelectedSubscriber = function () {
            var deferred = $q.defer();
            if (self.selectedSubscriber === undefined) {
                deferred.reject();
                return deferred.promise;
            }
            var data = {
                    channelId: self.channelId,
                    userId: self.selectedSubscriber.id,
                },
                messageParams = {};
            messageParams.title = 'Add Subscriber';
            messageParams.message = 'You are about to add user "';
            messageParams.message += self.selectedSubscriber.value + '" (' + self.selectedSubscriber.id + ')';
            messageParams.message += ' to channel "';
            messageParams.message += self.channelName;
            messageParams.message += '" (';
            messageParams.message += self.channelId;
            messageParams.message += ').';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.okText = 'Add';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.processing = true;
                    $http
                        .post('/channelSubscriptions/subscribeUserToChannel', data)
                        .success(function (res) {
                            $log.debug('ChannelService::subscribe User Success', res, data);
                            self.refreshSubscribers().then(
                                function () {
                                    deferred.resolve(res);
                                },
                                function (reason) {
                                    deferred.reject(reason);
                                }
                            );
                        })
                        .error(function (reason, status) {
                            $log.debug('ChannelService::subscribe User Failure', reason, data);
                            deferred.reject(reason);
                        })
                        .finally(function () {
                            self.processing = false;
                        });
                } else {
                    deferred.resolve();
                }
            });
            return deferred.promise;
        };

        this.refreshSubscribers = function () {
            self.loading = true;
            if (self.channelId) {
                return getSubscriptions(self.channelId).finally(function (res) {
                    self.clearSelectedSubscriber();
                    self.loading = false;
                });
            }
            var deferred = $q.defer();
            deferred.reject();
            return deferred.promise;
        };

        this.init = function () {
            self.channel = self.params && self.params.channel ? self.params.channel : {};
            self.channelId = self.params && self.params.channel ? self.params.channel.id : '';
            self.channelName = self.params && self.params.channel ? self.params.channel.name : '';
            self.refreshSubscribers();
            self.clearSelectedSubscriber();
        };
    },
]);
