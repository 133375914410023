'use strict';
/*global AppleID */

angular.module('home').controller('HomeEditProfileController', [
    'user',
    '$rootScope',
    '$scope',
    '$localStorage',
    '$http',
    '$log',
    '$window',
    '$state',
    '$stateParams',
    '$q',
    'Messages',
    'User',
    'FormValidationService',
    '_',
    'HostIframeSdk',
    'smoothScroll',
    'OpenPopupForm',
    'S3Uploader',
    'UploadProgress',
    'ArtworkService',
    'BillingService',
    'USER_TITLES',
    'UserPlansDataService',
    'jwtHelper',
    '$auth',
    'Security',
    'PlatformManager',
    function (
        user,
        $rootScope,
        $scope,
        $localStorage,
        $http,
        $log,
        $window,
        $state,
        $stateParams,
        $q,
        Messages,
        User,
        FormValidationService,
        _,
        HostIframeSdk,
        smoothScroll,
        OpenPopupForm,
        S3Uploader,
        UploadProgress,
        ArtworkService,
        BillingService,
        USER_TITLES,
        UserPlansDataService,
        jwtHelper,
        $auth,
        Security,
        PlatformManager
    ) {
        $scope.$storage = $localStorage;
        $scope.NIIO_URL = $scope.$platformManager.platform.url;
        $scope.USER_TITLES = USER_TITLES;
        $scope.ArtworkService = ArtworkService;
        $scope.UserPlansDataService = UserPlansDataService;
        $scope.UploadProgress = UploadProgress;
        $scope.isAdmin = User.isAdmin();
        $scope.fromEvents = !!$stateParams.eventId;
        $scope.readOnly = !!$stateParams.submitter;
        $scope.tfa = { code: '' };
        $scope.priorities = [
            { value: '0', view: 'Normal' },
            { value: '1', view: 'Medium' },
            { value: '2', view: 'High' },
        ];
        $scope.titles = [
            { name: 'Art Fan', value: '4' },
            { name: 'Artist', value: '1' },
            { name: 'Collector', value: '2' },
            { name: 'Curator', value: '3' },
            { name: 'Organization', value: '5' },
        ];
        $scope.visibility = [
            { name: 'Visible', value: true },
            { name: 'Hidden', value: false },
        ];
        $scope.editorUserId = User.getId();
        $scope.connectedWallets = [];

        function initAppleConnect() {
            AppleID.auth.init({
                clientId: 'com.niio.web',
                scope: 'email',
                redirectURI: PlatformManager.platform.url + '/Server/users/ExternalAuthRedirect',
                //state : '',
                usePopup: true,
            });
        }

        $scope.init = function () {
            if (!$scope.fromEvents || $scope.currentEvent) {
                //Scroll to head of page in init
                if (!$scope.fromEvents) {
                    var homeSecondNavElement = document.getElementById('edit-profile');
                    if (homeSecondNavElement) {
                        smoothScroll(homeSecondNavElement, {
                            offset: $rootScope.heightOfNav,
                            duration: '0',
                        });
                    }
                }

                $scope.submitWasClicked = false;
                $scope.User = user || $scope.profile;

                if ($scope.User.titles.length === 0) {
                    $scope.User.titles = ['1'];
                }
                if ($scope.User.preview_wm === undefined) {
                    $scope.User.preview_wm = true;
                }
                if ($scope.User.preview_qtype === undefined) {
                    $scope.User.preview_qtype = 720;
                }
                if ($scope.User.show_ext === undefined) {
                    $scope.User.show_ext = false;
                }

                $scope.profileCV = {};
                $scope.FormValidationService = FormValidationService;
                $scope.organizationTypes = FormValidationService.organizationTypes;
                $scope.onTitleChange();
                //TODO: Delete. Joined to user profile.
                //if($scope.User.plan !== undefined){
                //    $scope.UserPlansDataService.getUserPlanData($scope.User.plan).then(function(planData){
                //        $scope.User.planName = planData.display_name;
                //    });
                //}
                if ($scope.User.status !== undefined) {
                    $scope.User.statusName =
                        $scope.User.status === '1'
                            ? ''
                            : $scope.UserPlansDataService.mapStatusName($scope.User.status);
                }
            }

            $scope.FormValidationService = FormValidationService;
            $scope.organizationTypes = FormValidationService.organizationTypes;
            $scope.onTitleChange();

            $scope.socialProfiles = null;
            $scope.refreshSocialProfiles();
            initAppleConnect();
            getConnectedWallets();
        };

        $scope.$on('reinitiazlie_step', function (event) {
            $scope.init();
        });

        $scope.onTitleChange = function (value) {
            $scope.isArtist = $scope.User.titles.indexOf('1') > -1;
            $scope.isOrganization = $scope.User.titles.indexOf('5') > -1;
        };

        $scope.requestChangeField = function (field) {
            var subType;
            if (field === 'email address') {
                subType = 'Email Change';
            } else if (field === 'role') {
                subType = 'Role Change';
            }
            OpenPopupForm.openContact(
                'support',
                User.email,
                'Hi,\nPlease change my ' + field + ' to: \nReason for this request: \n\nThanks.',
                subType
            );
        };

        $scope.resendConfirmation = function (email) {
            $http
                .post('/users/reSendConfirmationMail', { email: email })
                .success(function (res) {
                    var messageParams = {};
                    messageParams.message = 'Resent confirmation email';
                    messageParams.message =
                        'An email was sent to you, please check your inbox and follow the instructions.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                })
                .error(function (error) {
                    var messageParams = {};
                    messageParams.title = 'Resend Confirmation Email';
                    messageParams.message =
                        'There was an error sending confirmation link, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                });
        };

        $scope.getUnverifiedDaysText = function () {
            var days = $scope.getUnverifiedDays();
            return days && days > 0 ? 'for ' + days + ' days' : 'yet';
        };

        $scope.getUnverifiedDays = function () {
            var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            var firstDate = new Date();
            var secondDate = new Date($rootScope.verificationProfile.created);
            return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
        };

        $scope.hasCancelOption = () => {
            return (
                $scope.User.plan_display_name !== 'Free' &&
                Number($scope.User.plan_will_renew) === 1 &&
                $scope.$storage.userDetails.provider_name === 'charge_bee'
            );
        };

        $scope.cancelSubscriptionFunctions = BillingService.promptCancelSubscription;

        function removeUnnecessaryProperties() {
            if ($scope.isOrganization) {
                $scope.User.username = null;
                $scope.User.last_name = null;
                $scope.User.display_username = null;
            } else {
                $scope.User.organization_type = null;
                $scope.User.organization_type_other = null;
            }

            if (!$scope.isArtist) {
                $scope.User.display_username = false;
                $scope.User.username = false;
            }

            if (!$scope.isArtist && !$scope.isOrganization) {
                $scope.User.paypal_account = false;
            }
        }

        function checkEventMandatoryFields() {
            var result = true;
            var messageParams = {};

            if ($scope.fromEvents) {
                if (
                    ($scope.profileForm.bio && $scope.profileForm.bio.$modelValue) ||
                    ($scope.profileForm.exhibitions && $scope.profileForm.exhibitions.$modelValue) ||
                    ($scope.profileForm.awards && $scope.profileForm.awards.$modelValue) ||
                    ($scope.profileForm.education && $scope.profileForm.education.$modelValue) ||
                    ($scope.profileForm.contact_info && $scope.profileForm.contact_info.$modelValue) ||
                    ($scope.profileForm.representation && $scope.profileForm.representation.$modelValue) ||
                    ($scope.profile && $scope.profile.profile_path) ||
                    ($scope.profileForm.fakeCV && $scope.profileForm.fakeCV.$modelValue)
                ) {
                    result = true;
                } else {
                    messageParams = {};
                    messageParams.title = 'Complete Form';
                    messageParams.message =
                        'Please fill in bio related fields or alternatively upload your CV';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    result = false;
                }

                // Mandatory profile picture for event submission
                // if ($scope.profile && $scope.profile.profile_path) {
                //     result = true;
                // } else {
                //     messageParams = {};
                //     messageParams.title = 'Complete Form';
                //     messageParams.message = 'Please upload profile picture';
                //     messageParams.disableAutoDismiss = true;
                //     Messages.openMessage($scope, messageParams);
                //     result = false;
                // }
            }

            return result;
        }

        $scope.updateProfile = function () {
            removeUnnecessaryProperties();
            var allowedToContinue = checkEventMandatoryFields();

            if (allowedToContinue) {
                var data = {
                    User: $scope.User,
                };
                delete data.User.tfa_secret; // not part of the form

                $scope.submitWasClicked = true;

                $http
                    .post('/users/updateDetails', data)
                    .then(
                        function (res) {
                            $log.debug(
                                'HomeEditProfileController::updateProfile::updateDetails Success',
                                res,
                                data
                            );
                            var messageParams = {};

                            var oldTitles = User.getTitles();
                            if (res.data.data) {
                                if (!$scope.isOther) {
                                    User.setUserDetails(res.data.data);
                                    if (_.difference(oldTitles, $scope.User.titles).length !== 0) {
                                        $scope.$emit('HomeEditProfileController:titles_changed');
                                    }
                                }

                                if ($scope.fromEvents) {
                                    messageParams = {};
                                    messageParams.title = 'Personal Data Saved Successfully';
                                    messageParams.message =
                                        'The personal info was saved successfully, please continue to the next step.';
                                    Messages.openMessage($scope, messageParams).then(function (res) {
                                        $scope.$emit('submission_step_completed');
                                    });
                                } else {
                                    messageParams.title = 'Update profile';
                                    messageParams.message = 'Profile was updated successfully.';
                                    Messages.openMessage($scope, messageParams).then(function (res) {
                                        // Redirecting back doesn't work...
                                        if ($scope.isOther) {
                                            $state.go('profile', { url: $scope.User.url }, { reload: true });
                                        } else {
                                            $state.go('home');
                                        }
                                    });
                                }

                                $scope.$emit('HomeEditProfileController:profile_updated', res.data.data);
                            } else {
                                $log.debug(
                                    'HomeEditProfileController::updateProfile::setUserDetails Failed',
                                    res,
                                    oldTitles
                                );
                                messageParams.title = 'Update profile';
                                messageParams.message =
                                    'There was a problem updating the profile. Please try again later.';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                            }
                        },
                        function (error) {
                            $log.debug(
                                'HomeEditProfileController::updateProfile::updateDetails Failed',
                                error,
                                data
                            );
                            var messageParams = {};
                            messageParams.title = 'Update profile';
                            messageParams.message =
                                'There was a problem updating the profile. Please try again later.';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    )
                    .finally(function () {
                        $scope.submitWasClicked = false;
                    });
            }
        };

        // Upload CV
        function isSupportedFileType(file) {
            var error = '';
            var fileExtension = file.name.split('.').pop();

            if (fileExtension.indexOf('pdf') < 0 && fileExtension.indexOf('doc') < 0) {
                error = 'File type is not supported. only .pdf and Office documents are allowed';
            } else if (file.size > 10000000) {
                error = 'Only documents under 10MB are allowed. Please choose another one.';
            }

            return error;
        }

        function uploadNewUserCV(filename) {
            var data = {
                userId: $scope.profileId,
                filename: filename,
            };
            return $http.post('/users/uploadNewUserCV', data);
        }

        $scope.deleteUserCV = function () {
            var deferred = $q.defer();
            var messageParams = {};

            messageParams.message = 'You are about to delete the CV from your account.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete CV';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        userId: User.getId(),
                    };

                    $scope.deletingCV = true;
                    $http
                        .post('/users/deleteUserCV', data)
                        .then(
                            function (res) {
                                $scope.User.cv_path = undefined;
                                User.setProfileCVPath(null);
                                var messageParams = {};
                                messageParams.message = 'Successfully deleted CV';
                                messageParams.title = 'Delete CV';
                                Messages.openMessage($scope, messageParams);
                                deferred.resolve();
                            },
                            function (error) {
                                var messageParams = {};
                                messageParams.message = 'Failed to delete the CV. ' + error;
                                messageParams.title = 'Delete CV';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams).then(function (res) {
                                    deferred.reject();
                                });
                            }
                        )
                        .finally(function () {
                            $scope.deletingCV = false;
                        });
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        $scope.uploadProfileCV = function (files) {
            var deferred = $q.defer();
            var file = files[0];

            if (file) {
                var error = isSupportedFileType(file);

                if (!error) {
                    uploadNewUserCV(file.name).then(function (res) {
                        var opts = {
                            submitOnChange: true,
                            getOptionsUri: '/Users/getUserProfileS3AccessToken',
                            acl: 'public-read',
                            uploadingKey: 'uploading',
                            enableValidation: true,
                            s3Bucket: res.data.s3.bucket,
                            s3Folder: res.data.s3.folder,
                            s3FileName: res.data.s3.filename,
                            extraParams: {
                                uploadType: 'cv',
                            },
                        };

                        // $scope.profileCV = 'https://' + res.data.s3.bucket + '.s3.amazonaws.com/' + res.data.s3.folder + '/' + res.data.s3.filename;

                        var fileExtension = res.data.s3.filename.split('.').pop();

                        $scope.uploadFile(file, opts, 'cv', fileExtension).then(
                            function (res) {
                                $log.debug(
                                    'HomeEditProfileController::uploadProfileCV::uploadFile Successfully uploaded file',
                                    {
                                        res: res,
                                        file: file,
                                        option: opts,
                                        type: 'cv',
                                    }
                                );
                            },
                            function (error) {
                                $log.debug(
                                    'HomeEditProfileController::uploadProfileCV::uploadFile Failed to upload to S3',
                                    {
                                        error: error,
                                        file: file,
                                        options: opts,
                                        type: 'cv',
                                    }
                                );
                                var messageParams = {};
                                messageParams.title = 'Error uploading profile CV';
                                messageParams.message =
                                    'Failed to upload profile CV, Please retry.\nIf the problem persists, please contact support.';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                            }
                        );
                    });
                } else {
                    var messageParams = {};
                    messageParams.message = error;
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            } else {
                deferred.reject('no file');
            }

            return deferred.promise;
        };

        $scope.uploadFile = function (file, opts, type, extension) {
            var deferred = $q.defer();

            S3Uploader.getUploadUserCVOptions(opts.getOptionsUri, type, $scope.profileId, extension).then(
                function (s3Options) {
                    var successFunction = opts.successFunction;
                    var failFunction = opts.failFunction;
                    var exraParams = opts.extraParams;

                    //var s3Uri = 'https://' + opts.s3Bucket + '.s3.amazonaws.com/';
                    var key = opts.s3Folder + '/' + opts.s3FileName;
                    S3Uploader.upload(
                        'regular',
                        opts.s3Bucket,
                        key,
                        opts.acl,
                        file.type,
                        s3Options.key,
                        s3Options.policy,
                        s3Options.signature,
                        file,
                        exraParams,
                        successFunction,
                        failFunction
                    ).then(
                        function (res) {
                            deferred.resolve(res);
                        },
                        function (error) {
                            deferred.reject(error);
                        }
                    );
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        $scope.validate2FACode = function () {
            if ($scope.tfaQrUrl && $scope.tfa.code) {
                User.validateTwoFactorAuthCode($scope.tfa.code).then(
                    function (res) {
                        var messageParams = {};
                        messageParams.message =
                            '2FA code is verified. You will be required to insert a new code on every sign-in.';
                        messageParams.title = '2FA Validation Success';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                        delete $scope.tfaQrUrl;
                    },
                    function (error) {
                        var messageParams = {};
                        messageParams.message =
                            'Failed to validate 2FA code. Please try again or contact support.';
                        messageParams.title = '2FA Validation Error';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                        $scope.tfa.code = '';
                    }
                );
            }
        };

        $scope.on2faClick = function () {
            $scope.tfa.code = '';
            if ($scope.User.is2fa) {
                // get QR code from server
                User.getTwoFactorAuthQR().then(
                    function (res) {
                        if (res.data && res.data.url) {
                            $scope.tfaQrUrl = res.data.url;
                        }
                    },
                    function (error) {
                        var messageParams = {};
                        messageParams.message = 'Failed to generate 2FA QR code. ' + error;
                        messageParams.title = '2FA Error';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    }
                );
            } else {
                // remove 2fa secret from DB
                User.disableTwoFactorAuth().then(
                    function (res) {
                        delete $scope.tfaQrUrl;
                        var messageParams = {};
                        messageParams.message =
                            '2FA code is disabled. You will not be required anymore to insert a new code on every sign-in.';
                        messageParams.title = '2FA Validation Disabled';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    },
                    function (error) {
                        var messageParams = {};
                        messageParams.message = 'Failed to disable 2FA. ' + error;
                        messageParams.title = '2FA Error';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    }
                );
            }
        };

        $rootScope.$on('s3upload:start', function (scope, msg) {
            var uploadType = msg.upload.extraParams.uploadType;

            if (uploadType === 'cv') {
                $scope.profileCVActive = true;
            }
        });

        $rootScope.$on('s3upload:progress', function (scope, msg) {
            var uploadProgress = msg.value;
            var uploadType = msg.upload.extraParams.uploadType;

            if (uploadType === 'cv') {
                $scope.profileCVActive = true;
                $scope.profileCVProgress = uploadProgress;
            }
        });

        function removeFiles() {
            document.getElementById('profileCVFile').value = '';
            $scope.profileCV.files = [];
        }

        $rootScope.$on('s3upload:success', function (scope, data) {
            var uploadType = data.upload.extraParams.uploadType;
            var requestData = {
                filename: data.upload.params.file.name,
                userId: $scope.profileId,
            };

            removeFiles();

            if (uploadType === 'cv') {
                $http.post('/users/uploadedProfileCV', requestData).then(function (res) {
                    if (!$scope.isOther) {
                        User.setProfileCVPath(res.data.data.path);
                        User.setProfileCVFilename(res.data.data.filename);
                        $scope.User.cv_path = res.data.data.path;
                        $scope.User.cv_filename = res.data.data.filename;
                    } else {
                        if ($scope.profile) {
                            $scope.profile.cv_path = res.data.data.path;
                            $scope.profile.cv_filename = res.data.data.filename;
                        }
                    }

                    $scope.profileCVActive = false;
                });
            }
        });

        $rootScope.$on('s3upload:error', function (scope, data) {
            var uploadType = data.upload.extraParams.uploadType,
                messageParams = {};

            if (uploadType && uploadType === 'cv') {
                messageParams.message = uploadType + ' upload failed. Please try again.';
                messageParams.title = 'Error Upload CV';
                messageParams.disableAutoDismiss = true;

                Messages.openMessage($scope, messageParams);

                if (uploadType === 'cv') {
                    $scope.profileCVActive = false;
                }
            }
        });

        $scope.openChangePasswordModal = function () {
            Messages.openPasswordChangeModal();
        };

        // TODO: Duplicate with JoinController
        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function displayErrorMsg(msg) {
            var messageParams = {
                message: msg,
                disableAutoDismiss: true,
            };
            Messages.openMessage($rootScope, messageParams);
        }

        $scope.addSocialProfile = function (provider) {
            if (provider === 'apple') {
                AppleID.auth.signIn().then(
                    (response) => {
                        // For more info: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
                        if (response.authorization && response.authorization.code) {
                            var user = response.authorization.user;
                            var id_token = response.authorization.id_token;
                            var providerData = {};
                            if (user) {
                                providerData.firstName = user.firstName;
                                providerData.lastName = user.lastName;
                            }
                            if (id_token) {
                                var decodedIdToken = jwtHelper.decodeToken(id_token);
                                if (decodedIdToken) {
                                    providerData.oid = decodedIdToken.sub;
                                }
                            }
                            Security.getExternalProfile(
                                provider,
                                response.authorization.code,
                                null,
                                providerData
                            ).then(
                                (data) => {
                                    var email = data.profile.email;
                                    if (data.user_id !== undefined && data.user_id !== null) {
                                        displayErrorMsg(
                                            `${capitalizeFirstLetter(
                                                provider
                                            )} account '${email}' is already in use. Please choose a different account.`
                                        );
                                        return;
                                    }
                                    addSocialProfile(data);
                                },
                                () => {
                                    displayErrorMsg(`Failed to connect to ${provider} account!`);
                                }
                            );
                        } else {
                            displayErrorMsg(`Failed to connect to ${provider} account!`);
                        }
                    },
                    (err) => {
                        displayErrorMsg(`Failed to connect to ${provider} account!`);
                    }
                );
            } else {
                $auth.authenticate(provider).then(
                    (response) => {
                        var data = {
                            provider,
                        };
                        Security.getExternalProfile(provider, response.code).then(
                            (data) => {
                                var email = data.profile.email;
                                if (data.user_id !== undefined && data.user_id !== null) {
                                    displayErrorMsg(
                                        `${capitalizeFirstLetter(
                                            provider
                                        )} account '${email}' is already in use. Please choose a different account.`
                                    );
                                    return;
                                }
                                addSocialProfile(data);
                            },
                            (err) => {
                                displayErrorMsg(`Fail to get user profile from ${provider} account!`);
                            }
                        );
                    },
                    () => {
                        // Fail to authenticate
                        displayErrorMsg(`Failed to connect to ${provider} account!`);
                    }
                );
            }
        };

        function addSocialProfile(profile) {
            // call server to email a confirmation password to user and display a popup
            var data = {
                action: 'add',
                ticket: profile.ticket,
            };

            var messageParams = {};
            // Send login to server to associate it with current user.
            $http.post('/users/AddExternalLogin', data).then(
                (res) => {
                    $log.debug('Post to AddExternalLogin Success', res, data);
                    Messages.openAddRemoveExternalLoginModal(data);
                },
                (error) => {
                    $log.debug('Post to AddExternalLogin  Failed', error, data);
                    messageParams.title = 'Add Social Profile';
                    messageParams.message =
                        'There was a problem adding a Social Profile to your account. Please try again later.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        }

        $scope.getSocialProfiles = function () {
            return $http.post('/users/getSocialProfiles').then((res) => res.data.data);
        };

        $scope.refreshSocialProfiles = function () {
            $scope.getSocialProfiles().then((data) => {
                $scope.socialProfiles = data;
            });
        };

        $rootScope.$on('user::socialProfileChanged', ($event) => {
            $scope.refreshSocialProfiles();
        });

        $scope.removeSocialProfile = function (profile) {
            // call server to email a confirmation password to user and display a popup
            var data = {
                action: 'remove',
                ticket: profile.ticket,
            };

            var messageParams = {};
            // Send login to server to associate it with current user.
            $http.post('/users/RemoveExternalLogin', data).then(
                (res) => {
                    $log.debug('Post to RemoveExternalLogin Success', res, data);
                    Messages.openAddRemoveExternalLoginModal(data);
                },
                (error) => {
                    $log.debug('Post to RemoveExternalLogin  Failed', error, data);
                    messageParams.title = 'Remove Social Login';
                    messageParams.message =
                        'There was a problem removing Profile from that account. Please try again later.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        };

        const getConnectedWallets = () => {
            $http.get('/cryptoWallets/getOwnedWallets').then((res) => {
                $scope.wallets = res.data.data.wallets;
            });
        };

        $scope.openWalletConnection = function () {
            let url = '/wallets-login/walletsManagementModal';
            HostIframeSdk.openPortal(url, { size: 'wallet', hideHeader: true });
        };
    },
]);
