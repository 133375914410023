'use strict';

angular.module('devices').directive('remoteArtworkThumbnail', [
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                artwork: '=',
                collection: '=?',
                channel: '=?',
                currentActive: '=?',
                clicked: '&',
                checkclicked: '&',
                isplayed: '&',
            },
            templateUrl: 'modules/devices/directives/devices.remote.artwork-thumbnail.client.view.html',
            controller: 'RemoteArtworkThumbnailController',
            controllerAs: 'vm',
            bindToController: true, //required in 1.3+ with controllerAs
        };
    },
]);
