angular.module('discover').controller('DiscoverArtworksController', [
    '$scope',
    '$rootScope',
    'User',
    '$state',
    'DiscoverDataService',
    '$http',
    '$stateParams',
    function ($scope, $rootScope, User, $state, DiscoverDataService, $http, $stateParams) {
        $scope.themes = [];
        $scope.init = async function () {
            $scope.lastScroll = null;
            $scope.isFilterModalOpen = false;
            $scope.syncFiltersFromParams();

            const allDatabaseThemes = await $http.get('/themes/getAll');
            $scope.themes = allDatabaseThemes.data.data || [];
            await $scope.syncUsersThemes();
            $scope.themesList = [];
        };
        $scope.$state = $state;
        $scope.showInternal = User.isProCurator() || User.isAdmin();

        $scope.syncFiltersFromParams = () => {
            $scope.modalSelectedCategory = $state.params.artworksName;
            $scope.modalSelectedTheme = $state.params.theme;
        };

        $scope.onShowResultsClick = () => {
            DiscoverDataService.setCachedDisplayArtworks(null);
            $state.go('discover.artworks.main', {
                artworksName: $scope.modalSelectedCategory || 'all',
                theme: $scope.modalSelectedTheme,
            });
            $scope.isFilterModalOpen = false;
        };

        $scope.resetTags = () => {
            DiscoverDataService.setCachedDisplayArtworks(null);
            $scope.filterByOrientationChange(null, true);
            $scope.isFilterModalOpen = false;
            $scope.modalSelectedCategory = 'all';
            $scope.modalSelectedTheme = '';
            $state.params.artworksName = 'all';
            $state.params.theme = null;
            reloadWithNewParams();
        };

        $scope.showFilterModal = () => {
            $scope.isFilterModalOpen = true;
        };

        $scope.hideFilterModal = () => {
            $scope.syncFiltersFromParams();
            $scope.isFilterModalOpen = false;
        };

        $scope.setSelectedCategory = (category) => {
            $scope.modalSelectedCategory = category || 'all';
        };

        $scope.setSelectedTheme = (theme) => {
            $scope.modalSelectedTheme = theme;
        };

        $scope.$watch('$state.params.theme', async () => {
            $scope.syncFiltersFromParams();
            if ($scope.themesList?.length) {
                $scope.syncCurrentTheme();
            } else {
                await $scope.syncUsersThemes();
            }
        });

        $scope.syncUsersThemes = async function () {
            const userFavoritesThemes = await $http.get('/themes/getOfUser');
            $scope.themesList = userFavoritesThemes?.data?.data || [];
            $scope.syncCurrentTheme();
        };

        $scope.syncCurrentTheme = function () {
            $scope.isFollowingCurrentTheme =
                $scope.themesList.find((theme) => theme.id === $state.params.theme) || null;
        };

        $scope.sidebarItems = [
            {
                name: 'Featured',
                link: 'featured',
            },
            {
                name: 'Latest',
                link: 'latest',
            },
            {
                name: 'NFT',
                link: 'nft',
            },
        ];

        /* if (User.isAdmin()) {
            $scope.sidebarItems.push({link: 'all', name: 'All'});
            $scope.sidebarItems.push({link: 'latest', name: 'Latest'});
        } */

        /*
        $scope.sidebarItems = [];
        if (User.isAdmin()) {
            $scope.sidebarItems.push({
                name: 'Display',
                options: [
                    {label: 'Featured', value: 'featured'},
                    {label: 'Latest', value: 'latest'},
                    {label: 'All', value: 'all'}
                ],
                selected: 'featured'
            });
        }
        $scope.sidebarItems.push({
            name: 'Genre',
            options: [
                {label: 'All', value: 'all'}
            ],
            selected: 'all'
        });
        $scope.sidebarItems.push({
            name: 'Medium',
            options: [
                {label: 'All', value: 'all'}
            ],
            selected: 'all'
        });
        $scope.sidebarItems.push({
            name: 'Orientation',
            options: [
                {label: 'All', value: 'all'},
                {label: 'Landscape', value: 'landscape'},
                {label: 'Portrait', value: 'portrait'},
                {label: 'Custom', value: 'custom'}
            ],
            selected: 'all'
        });
        $scope.sidebarItems.push({
            name: 'Quality',
            options: [
                {label: 'All', value: 'all'},
                {label: 'HD (720p)', value: '720'},
                {label: 'FHD (1080p)', value: '1080'},
                {label: '4K UHD (2160p)', value: '2160'},
                {label: '8K UHD (4320p)', value: '4320'}
            ],
            selected: 'all'
        });
        */

        const syncCurrentTheme = () => {
            $scope.currentTheme = $scope.themes.find((theme) => theme.id === $state.params.theme);
        };
        syncCurrentTheme();

        $scope.$watchGroup(['currentThemeId', '$state.params.theme', 'themes'], () => {
            syncCurrentTheme();
        });

        $scope.addThemeToFavorite = async (theme) => {
            $rootScope.$broadcast('addNotification', {
                message: 'Genre added to your favorites.',
                type: 'success',
                duration: 3000,
            });

            // ...
            $scope.isFollowingCurrentTheme = true;
            await $http.post('/themes/addToUser', { themes: [...theme.id] });
            await $scope.syncUsersThemes();
        };

        $scope.removeThemeFromFavorite = async (theme) => {
            $scope.isFollowingCurrentTheme = null;
            await $http.post('/themes/removeFromUser', { themes: [...theme.id] });
            await $scope.syncUsersThemes();
            $rootScope.$broadcast('addNotification', {
                message: 'Genre removed from your favorites.',
                type: 'success',
                duration: 3000,
            });
        };

        $scope.selectedSection = function (artworkLink) {
            DiscoverDataService.setCachedDisplayArtworks(null);
            $state.go('discover.artworks.main', { artworksName: artworkLink });
        };

        $scope.filterByGenreChange = function (themeId) {
            DiscoverDataService.setCachedDisplayArtworks(null);
            $state.go('discover.artworks.main', { ...$state.params, theme: themeId });
        };

        const reloadWithNewParams = (params) => {
            const newParams = params || { ...$state.params };
            $state.go('discover.artworks.main', newParams, { notify: true, reload: true });
        };

        $scope.filterByOrientationChange = function (orientation, preventRedirect) {
            DiscoverDataService.setCachedDisplayArtworks(null);
            $state.params.portrait = null;
            $state.params = orientation !== null ? { ...$state.params, portrait: orientation } : { ...$state.params };
            if (!preventRedirect) {
                reloadWithNewParams();
            }
        };

        $scope.fakeSelection = 'all';
        $scope.currentSelection = { name: 'All', link: 'all' };

        $scope.$watch('fakeSelection', (newValue, oldValue) => {
            $scope.currentSelection = $scope.sidebarItems.find((item) => item.link === newValue);
        });

        $scope.onFilterChange = function () {
            if ($scope.fakeSelection !== 'all') {
                DiscoverDataService.setCachedDisplayArtworks(null);
                $state.go('discover.artworks.main', { artworksName: 'all' });
            }
        };

        $scope.clearGenresFilter = function () {
            DiscoverDataService.setCachedDisplayArtworks(null);
            $state.go('discover.artworks.main', { theme: null });
        };

        $scope.hideSidebar = false; /*! User.isAdmin(); */
        $scope.displayTitle = true;
        $scope.$on('ArtworkInfoArtworkController:artwork_info_entered', () => {
            $scope.displayTitle = false;
            $scope.hideSidebar = true;
        });

        $scope.$on('ArtworkInfoArtworkController:artwork_info_destroyed', () => {
            $scope.displayTitle = true;
            $scope.hideSidebar = false;
        });

        $scope.$on('DiscoverArtworksMainController:artworks_name_changed', (event, artworksName) => {
            if (artworksName) {
                $scope.fakeSelection = artworksName;
            }
        });

        $rootScope.$on('discover_hide_sidebar', () => {
            $scope.hideSidebar = true;
        });

        $rootScope.$on('discover_show_sidebar', () => {
            $scope.hideSidebar = false;
        });
    },
]);
