'use strict';

angular.module('core').service('LocalConfig', [
    '$rootScope',
    '$localStorage',
    function ($rootScope, $localStorage) {
        var self = this;
        this.$storage = $localStorage;

        this.data = {
            v: 1,
            /*,loadLevel: 1*/
        };

        this.save = function () {
            self.$storage.config = self.data;
        };

        this.restore = function () {
            self.data = self.$storage.config || self.data;
        };

        this.clear = function () {
            delete this.$storage.config;
        };

        this.get = function (t, def) {
            return self.data[t] === undefined ? def : self.data[t];
        };

        this.set = function (t, v) {
            self.data[t] = v;
            self.save();
        };
        $rootScope.$on('ClearData', function () {
            self.clear();
        });

        this.init = function () {
            self.restore();
            self.save();
        };
        self.init();
    },
]);
