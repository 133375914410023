'use strict';
/*global NiioPlayer, angular */

angular.module('channel-info').controller('ChannelInfoChannelController', [
    'ChannelInfoService',
    'HomeDataService',
    'ChannelService',
    'ArtworkService',
    'OpenPopupForm',
    '$log',
    '$window',
    '$scope',
    '$location',
    '$rootScope',
    '$state',
    '$stateParams',
    '$http',
    '$q',
    '$filter',
    '_',
    'Messages',
    'smoothScroll',
    'User',
    'Partial',
    'ENTITY_ACTIONS',
    'CHANNEL_TYPES',
    'NIIO_URL',
    'mobileBannerService',
    function (
        ChannelInfoService,
        HomeDataService,
        ChannelService,
        ArtworkService,
        OpenPopupForm,
        $log,
        $window,
        $scope,
        $location,
        $rootScope,
        $state,
        $stateParams,
        $http,
        $q,
        $filter,
        _,
        Messages,
        smoothScroll,
        User,
        Partial,
        ENTITY_ACTIONS,
        CHANNEL_TYPES,
        NIIO_URL,
        mobileBannerService
    ) {
        var self = this;
        $rootScope.playersCounter++;
        this.playersId = $rootScope.playersCounter;
        var videoId = 'video' + this.playersId;
        this.nothingLoadedYet = true;
        this.User = User;
        this.isOther = undefined;
        this.HomeDataService = HomeDataService;
        this.ChannelService = ChannelService;
        this.ArtworkService = ArtworkService;
        this.ChannelInfoService = ChannelInfoService;
        this.CHANNEL_TYPES = CHANNEL_TYPES;
        this.OpenPopupForm = OpenPopupForm;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.loadingSharedActions = false;
        this.from =
            this.$state.current.data && this.$state.current.data.from ? this.$state.current.data.from : '';
        this.discoveryView =
            this.from === 'discovery' ||
            this.from === 'discovery-catalogs' ||
            this.from === 'discovery-invitation' ||
            this.from === 'discovery-artcasts';
        this.isDiscovery = true;
        this.additionalActions = [];
        this.moreAdditionalActions = [];
        this.showMoreActions = false;
        this.isMobile = mobileBannerService.isMobile();
        $scope.$emit('ChannelInfoChannelController:discovery_type_submitted', this.isDiscovery);
        this.discoveryInvitation = this.from === 'discovery-invitation';
        $scope.search = {
            enable: false,
            displayBtn: false,
            text: '',
            filterRows: function (row) {
                if (!$scope.search.enable || $scope.search.text === '') {
                    return true;
                }

                var lowerCasedSearchText = $scope.search.text.toLowerCase();

                if (row.title.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }
                if (row.display_name && row.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }
                if (
                    row.uploader &&
                    row.uploader.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1
                ) {
                    return true;
                }
                if (
                    row.artist &&
                    row.artist.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1
                ) {
                    return true;
                }
                return false;
            },
        };
        this.getCover = function () {
            if (self.channel) {
                if (self.channel.cover && self.channel.cover.path) {
                    return self.channel.cover && self.channel.cover.path;
                } else if (self.channel && self.channel.artworks && self.channel.artworks.length) {
                    return self.channel.artworks[0].thumbnail_path;
                }
            }
            return '';
        };
        this.getOrderNum = function (num) {
            if (!isNaN(num)) {
                return padWithZeroes(num, 3);
            }
            return '';
        };

        this.openMobilePopup = (action) => {
            $rootScope.$broadcast('openMobilePopup', { action });
        };

        this.toggleMoreActions = () => {
            this.showMoreActions = !this.showMoreActions;
        };

        $scope.toggleSearch = function () {
            self.showMoreActions = false;
            $scope.search.text = '';
            if ($scope.search.enable) {
                $scope.search.text = '';
                $scope.search.enable = false;
            } else {
                $scope.search.enable = true;
            }
        };

        function padWithZeroes(number, length) {
            var numberArr = number.split('.') || [''];
            var stg = '' + numberArr[0];
            while (stg.length < length) {
                stg = '0' + stg;
            }
            stg = numberArr.length > 1 ? stg + '.' + numberArr[1] : stg;
            return stg;
        }
        function isOtherFunc() {
            self.isOther =
                self.channel && self.channel.creator && self.channel.creator.user_id !== User.getId();
        }

        function toggleChannelSubscription(action) {
            ChannelService.handleChannelAction('toggleSubscription', self.channel).then(function () {
                action.active = !action.active;
            });
        }

        function setActionButtons() {
            if (!self.loadingSharedActions) {
                self.actionButtons = [];
                var isOwner = self.channel.creator.user_id === User.getId();
                var isAdmin = User.isAdmin();

                if (self.sharedActions && !isOwner) {
                    self.actionButtons = _.uniq(angular.copy(self.sharedActions), function (action, key) {
                        return action.id;
                    });
                    self.actionButtons.forEach(function (actionButton) {
                        actionButton.action = Partial.func(self.handleAction, actionButton);
                        actionButton.display = true;
                        actionButton.isSharedAction = true;
                        var parametersJson = JSON.parse(actionButton.parameters);
                        if (
                            (actionButton.name === 'buy' || actionButton.name === 'transferOwnership') &&
                            parametersJson
                        ) {
                            if (parametersJson.specificEdition) {
                                actionButton.addName +=
                                    ' Edition #' +
                                    parametersJson.specificEdition +
                                    ' / ' +
                                    self.artwork.total_copies;
                                actionButton.removeName +=
                                    ' Edition #' +
                                    parametersJson.specificEdition +
                                    ' / ' +
                                    self.artwork.total_copies;
                            }
                            if (parametersJson.price && parametersJson.currency) {
                                self.artwork.price = parametersJson.price;
                                self.artwork.currency = parametersJson.currency;
                                self.artwork.sale_type = 'SRB';
                            }
                        }
                    });
                    // self.actionButtons[0].addedClass = 'btn-primary'; // first button is primary
                } else {
                    if (self.actions) {
                        self.actionButtons = _.uniq(angular.copy(self.actions), function (action, key) {
                            return action.id;
                        });
                        self.actionButtons.forEach(function (actionButton) {
                            actionButton.action = Partial.func(self.handleAction, actionButton);
                            actionButton.isSharedAction =
                                ['share', 'loan', 'rent'].indexOf(actionButton.name) >= 0;
                        });
                    }

                    // if (!isOwner) {
                    // Subscribe
                    // if (self.artwork.sale_type === 'SRB' && self.artwork.purchased_copies < self.artwork.total_copies) {
                    // var subscribeButton = {display: true, active: self.channel.is_subscribed, removeName: 'Unsubscribe', addName: 'Subscribe', onCoverOnly: true};
                    // subscribeButton.action = Partial.func(ChannelService.handleChannelAction, 'share', self.channel, {share: $stateParams.share, password: ChannelInfoService.channelSharedPassword, action: subscribeButton});
                    // self.actionButtons.push(subscribeButton);
                    // }

                    // Buy
                    // if (self.artwork.sale_type === 'SRB' && self.artwork.purchased_copies < self.artwork.total_copies) {
                    //     self.actionButtons.push({action: Partial.func(ChannelService.handleArtworkAction, 'buy', self.artwork), display: true, active: true, removeName: 'Buy', addName: 'Buy', addedClass: 'btn-primary'});
                    // }
                    //
                    // // Inquire
                    // if (self.artwork.is_inquire) {
                    //     self.actionButtons.push({action: Partial.func(ChannelService.handleArtworkAction, 'inquire', self.artwork), display: true, active: true, removeName: 'Inquire', addName: 'Inquire', removeIcon: 'glyphicon-envelope', addIcon: 'glyphicon-envelope', addedClass: 'btn-primary'});
                    // }
                    //
                    // // Add / Remove
                    // if ((self.artwork.sale_type === 'FRE' || self.artwork.sale_type === 'FCC') && !self.artwork.is_accepted) {
                    //     var addButton = {display: true, active: self.artwork.is_added, removeName: 'Remove', addName: 'Add', removeIcon: 'glyphicon-ok', addIcon: 'glyphicon-remove', addedClass: 'btn-primary'};
                    //     // addButton.action = Partial.func(toggleArtwork, addButton);
                    //     self.actionButtons.push(addButton);
                    // }
                    //
                    // // Wishlist
                    // if (self.displayWishlist) {
                    //     var wishlistButton = {display: true, active: self.artwork.in_wishlist, removeName: 'Remove', addName: 'Add To Wishlist', removeIcon: 'glyphicon-star', addIcon: 'glyphicon-star-empty', addedClass: ''};
                    //     // wishlistButton.action = Partial.func(toggleWishlist, wishlistButton);
                    //     self.actionButtons.push(wishlistButton);
                    // }
                    // }
                }

                // Owner
                if (isOwner || isAdmin) {
                    // Transfer Channel
                    var channelTypeName =
                        !self.channel.type || self.channel.type === CHANNEL_TYPES.ARTCAST
                            ? 'Artcast'
                            : 'Catalog';
                    self.actionButtons.push({
                        action: Partial.func(OpenPopupForm.openMenu, 'shareChannel', {
                            channel: self.channel,
                        }),
                        active: true,
                        removeName: 'Transfer ' + channelTypeName,
                        addName: 'Transfer ' + channelTypeName,
                        removeIcon: 'modules/core/img/transfer.svg',
                        addIcon: 'modules/core/img/transfer.svg',
                        addedClass: 'btn-primary channel-info-action-buttons-block',
                    });
                    // Channel Management
                    self.moreAdditionalActions.push({
                        action: Partial.func(OpenPopupForm.openMenu, 'channelManagement', {
                            channel: self.channel,
                        }),
                        active: true,
                        removeName: 'Manage',
                        addName: 'Manage',
                        removeIcon: 'modules/core/img/manage.svg',
                        addIcon: 'modules/core/img/manage.svg',
                        addedClass: '',
                    });
                    // Edit
                    self.moreAdditionalActions.push({
                        action: Partial.func($state.go, (isOwner ? 'home' : 'profile') + '.addChannel', {
                            url: self.channel.creator.url,
                            channelId: self.channel.id,
                        }),
                        active: true,
                        removeName: 'Edit',
                        addName: 'Edit',
                        removeIcon: 'modules/core/img/pencil.svg',
                        addIcon: 'modules/core/img/pencil.svg',
                        addedClass: '',
                    });
                    // Delete
                    self.moreAdditionalActions.push({
                        action: Partial.func(self.deleteChannel, self.channel.id),
                        active: true,
                        removeName: 'Delete',
                        addName: 'Delete',
                        removeIcon: 'modules/core/img/trash.svg',
                        addIcon: 'modules/core/img/trash.svg',
                        addedClass: self.isMobile ? 'additional-actions-button-danger' : 'btn-warning',
                    });

                    // Remove subscribe action
                    var subscribeAction = _.find(self.actionButtons, function (action) {
                        return action.id === '1';
                    });
                    if (subscribeAction) {
                        subscribeAction.shouldShow = false;
                        subscribeAction.display = false;
                    }
                }

                // Add to queue
                self.additionalActions.push({
                    id: 2,
                    active: true,
                    removeName: 'Add to queue',
                    addName: 'Add to queue',
                    removeIcon: 'modules/core/img/add-to-queue.svg',
                    addIcon: 'modules/core/img/add-to-queue.svg',
                    addedClass: '',
                    action: self.openMobilePopup.bind(self, 'add-to-queue'),
                });

                // Add to playlist
                self.additionalActions.push({
                    id: 3,
                    active: true,
                    removeName: 'Add to playlist',
                    addName: 'Add to playlist',
                    removeIcon: 'modules/core/img/add-to-a-playlist.svg',
                    addIcon: 'modules/core/img/add-to-a-playlist.svg',
                    addedClass: '',
                    action: self.openMobilePopup.bind(self, 'add-to-playlist'),
                });

                // Social Share
                var socialLink = NIIO_URL + '/channel/' + self.channel.url_name;
                if (!self.isMobile) {
                    self.moreAdditionalActions.push({
                        id: 1,
                        action: Partial.func(OpenPopupForm.openMenu, 'social', {
                            channel: self.channel,
                            link: socialLink,
                        }),
                        active: true,
                        removeName: self.isDiscovery ? 'Share' : 'Social Share',
                        addName: 'Social Share',
                        removeIcon: 'modules/core/img/share.svg',
                        addIcon: 'modules/core/img/share.svg',
                        addedClass: '',
                    });
                } else {
                    self.additionalActions.push({
                        id: 1,
                        action: Partial.func(OpenPopupForm.openMenu, 'social', {
                            channel: self.channel,
                            link: socialLink,
                        }),
                        active: true,
                        removeName: self.isDiscovery ? 'Share' : 'Social Share',
                        addName: 'Social Share',
                        removeIcon: 'modules/core/img/share.svg',
                        addIcon: 'modules/core/img/share.svg',
                        addedClass: '',
                    });
                }

                // More
                if (self.moreAdditionalActions.length > 0 && self.isMobile) {
                    self.additionalActions.push({
                        id: 4,
                        active: true,
                        removeName: 'More',
                        addName: 'More',
                        removeIcon: 'modules/core/img/more-horizontal.svg',
                        addIcon: 'modules/core/img/more-horizontal.svg',
                        addedClass: '',
                        action: self.toggleMoreActions,
                    });
                }

                if (!self.isMobile) {
                    self.actionButtons = [...self.actionButtons, ...self.moreAdditionalActions];
                }

                self.actionButtons.forEach(function (actionButton) {
                    actionButton.isAdmin = isAdmin;
                    actionButton.isOwner = isOwner;
                });

                self.additionalActions.forEach(function (actionButton) {
                    actionButton.isAdmin = isAdmin;
                    actionButton.isOwner = isOwner;
                });
                self.additionalActions.sort((a, b) => a.id - b.id);
            }
        }

        function excludePreviewAction(sharedActions) {
            var actions = [];
            sharedActions.forEach(function (action) {
                if (action.id !== ENTITY_ACTIONS.SENDPREVIEW) {
                    actions.push(action);
                }
            });
            return actions;
        }

        function collectAllArtworks() {
            var artworks;
            // Get artworks from collections
            if (self.channel.collections && self.channel.collections.length) {
                artworks = _.pluck(self.channel.collections, 'artworks');
                var newArtworks = [];
                artworks.forEach(function (artworksObj) {
                    newArtworks = newArtworks.concat(artworksObj);
                });
                artworks = _.uniq(newArtworks, false, function (item, key, a) {
                    return item.id;
                });
            }

            if (self.channel.artworks && self.channel.artworks.length) {
                artworks = _.uniq(_.union(artworks, self.channel.artworks), false, function (item, key, a) {
                    return item.id;
                });
            }

            return artworks;
        }
        function prepareArtcastViewByArtworkOrder(artworks) {
            var compareObj = {};
            var artistId;
            if (artworks && artworks.length > 0) {
                artworks.forEach(function (artwork, index) {
                    var curIndex = index;
                    var prevIndex = index - 1;
                    if (artwork.artist && artwork.artist.user_id) {
                        artistId = artwork.artist.user_id;
                    } else {
                        artistId = artwork.artist.display_name.toLowerCase();
                    }
                    if (compareObj[artistId] && compareObj[artistId].order === prevIndex) {
                        artwork.hide = true;
                        compareObj[artistId].order = curIndex;
                    } else {
                        compareObj[artistId] = {
                            order: curIndex,
                        };
                    }
                });
                return artworks;
            }
        }
        function prepareArtcastViewByArtist(artworks) {
            var compareObj = {};
            if (artworks && artworks.length > 0) {
                artworks.sort(function (a, b) {
                    if (a.artist.display_name < b.artist.display_name) {
                        return -1;
                    }
                    if (a.artist.display_name > b.artist.display_name) {
                        return 1;
                    }
                    return 0;
                });

                artworks.forEach(function (artwork, index) {
                    var artistId;
                    artwork.order = (index + 1).toString();
                    if (artwork.artist && artwork.artist.user_id) {
                        artistId = artwork.artist.user_id;
                    } else {
                        artistId = artwork.artist.display_name.toLowerCase();
                    }
                    if (compareObj[artistId]) {
                        artwork.hide = true;
                    } else {
                        compareObj[artistId] = true;
                    }
                });

                return artworks;
            }
        }

        function getArtworksPathsForThumbnail(channel) {
            var path = '';
            if (channel.cover && channel.cover.path) {
                path = 'https:' + channel.cover.path;
            } else {
                if (channel.artworks.length > 0) {
                    path = 'https:' + channel.artworks[0].thumbnail_path;
                }
            }
            return path;
        }

        function filterFeaturedArtists() {
            self.filteredFeaturedArtists = {};
            var tempArtistMap = {};
            if (self.channel.artworks && self.channel.artworks.length) {
                self.channel.artworks.forEach(function (artwork) {
                    if (artwork.artist && artwork.artist.display_name) {
                        tempArtistMap[artwork.artist.display_name.toLowerCase()] =
                            artwork.artist.display_name;
                    }
                });
                var tempFeaturedArtists = [];
                Object.keys(tempArtistMap).forEach(function (artistName, index) {
                    if (index !== 10) {
                        tempFeaturedArtists.push(tempArtistMap[artistName]);
                    } else {
                        tempFeaturedArtists.push('and more...');
                    }
                });
                self.filteredFeaturedArtists.count = tempFeaturedArtists.length;
                self.filteredFeaturedArtists.text = tempFeaturedArtists.join(', ');
            }
        }

        function loadAndPrepareArtistData() {
            var artworks = self.channel.artworks;

            if (self.channel.type === CHANNEL_TYPES.ARTCAST && self.selectedLayout === 'artist') {
                artworks = prepareArtcastViewByArtist(artworks);
            } else if (
                self.channel.type === CHANNEL_TYPES.ARTCAST &&
                self.selectedLayout === 'artwork_order'
            ) {
                artworks = prepareArtcastViewByArtworkOrder(artworks);
            }
            self.artworks = artworks;
            var promises = [];
            if (self.artworks && self.artworks.length > 0 && self.channel.type === CHANNEL_TYPES.ARTCAST) {
                self.artworks.forEach(function (artwork) {
                    if (
                        artwork.uploader.user_id === artwork.user_id &&
                        (!artwork.artist.user_id ||
                            (artwork.artist.user_id && artwork.artist.user_id !== artwork.uploader.user_id))
                    ) {
                        artwork.isUploaderArtist = false;
                    } else if (artwork.artist.url) {
                        artwork.isUploaderArtist = true;
                    }
                    var deferred = $q.defer();
                    if (artwork.isUploaderArtist) {
                        self.HomeDataService.getUserDetailsByUrl(artwork.artist.url).then(
                            function (user) {
                                deferred.resolve(user);
                            },
                            function (reason) {
                                $log.debug(
                                    'channel-info.ChannelInfoChannelController::getUserDetailsByUrl Failure',
                                    {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason: reason,
                                    }
                                );
                                var messageParams = {};
                                messageParams.message = reason;
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($rootScope, messageParams).then(function () {
                                    $state.go('home');
                                });
                                deferred.reject(reason);
                            }
                        );
                    } else {
                        artwork.artist.about = artwork.artist.shortbio;
                        deferred.resolve(artwork.artist);
                    }
                    promises.push(deferred.promise);
                });
            }
            return $q.all(promises);
        }

        function loadChannel() {
            self.nothingLoadedYet = true;

            var deferred = $q.defer();

            getSharedActions().then(function () {
                ChannelInfoService.getChannel(
                    $stateParams.channelUrlName,
                    $stateParams.share,
                    ChannelInfoService.channelSharedPassword
                ).then(
                    function (res) {
                        $log.debug('channel-info.ChannelInfoChannelController::loadChannel Success', {
                            state: $state,
                            stateParams: $stateParams,
                            res,
                        });
                        self.channel = res;
                        self.selectedLayout = 'artist';
                        self.viewProperties = self.channel.view_properties_json;
                        if (self.channel.view_properties_json) {
                            if (self.channel.view_properties_json.artwork_sorting_type) {
                                self.selectedLayout = self.channel.view_properties_json.artwork_sorting_type;
                            }
                        }
                        self.isDiscoveryArtcast = true;
                        self.isDiscoveryCatalogs = true;
                        if (self.isDiscoveryCatalogs) {
                            filterFeaturedArtists();
                        }
                        self.channelCover = getArtworksPathsForThumbnail(self.channel);
                        isOtherFunc();
                        ChannelService.handleChannelAction('getArtworksPathsForThumbnail', self.channel);

                        self.nothingLoadedYet = false;
                        self.actions = ChannelService.initActions(self.channel);
                        if ($stateParams.share) {
                            self.sharedActions.forEach((action) => {
                                ChannelService.setActionActiveState(action, self.channel);
                                ChannelService.setActionNames(action, self.channel);
                            });
                        }
                        self.loadingSharedActions = false;
                        setActionButtons();
                        var collectionsArtworks = [];
                        _.forEach(self.channel.collections, function (collection) {
                            collectionsArtworks.push(collection.artworks);
                            var padWith = String(collection.artworks.length).length;
                            _.forEach(collection.artworks, function (artwork, index) {
                                artwork.order =
                                    collection.order +
                                    '.' +
                                    artwork.order_in_collection.padStart(padWith, '0');
                                artwork.collectionName = collection.name;
                                artwork.belongsToCollectionId = collection.id;
                            });
                        });

                        collectionsArtworks = _.flatten(collectionsArtworks);
                        self.channel.artworks = _.union(collectionsArtworks, self.channel.artworks);
                        self.channel.artworks = _.uniq(self.channel.artworks, function (artwork) {
                            return artwork.id;
                        });

                        self.channel.artworks.sort(function (a, b) {
                            var aOrder = Number(a.order);
                            var bOrder = Number(b.order);

                            if (aOrder < bOrder) {
                                return -1;
                            }
                            if (aOrder > bOrder) {
                                return 1;
                            }
                            return 0;
                        });

                        var artworkToOpen = null;
                        var collectionMap = {};
                        self.channel.artworks.forEach(function (artwork, index) {
                            artwork.belongsToChannelId = self.channel.id;
                            if (
                                artwork.belongsToCollectionId &&
                                !collectionMap[artwork.belongsToCollectionId]
                            ) {
                                collectionMap[artwork.belongsToCollectionId] = true;
                                artwork.showCollectionName = true;
                            }
                            artwork.order = (index + 1).toString();
                            if ($stateParams.artworkId === artwork.id) {
                                artworkToOpen = artwork;
                            }
                        });
                        self.artworks = self.channel.artworks;
                        if (artworkToOpen) {
                            OpenPopupForm.openMenu('channelInfoArtwork', {
                                artwork: artworkToOpen,
                                artworks: self.channel.artworks,
                                channel: self.channel,
                            });
                        }
                        if (self.isDiscoveryArtcast) {
                            loadAndPrepareArtistData().then(function (users) {
                                self.artistsMap = {};
                                users.forEach(function (user) {
                                    if (user.id) {
                                        self.artistsMap[user.id] = user;
                                    }
                                });
                            });
                        }
                        deferred.resolve();
                    },
                    function (reason) {
                        $log.debug('channel-info.ChannelInfoChannelController::loadChannel Failure', {
                            state: $state,
                            stateParams: $stateParams,
                            reason: reason,
                        });
                        // On 401 the auth interceptor will catch it, the state will be saved for after login

                        if (reason && reason.status && reason.status !== 401) {
                            var messageParams = {};
                            messageParams.message =
                                "Artwork is not available or doesn't exist. Redirecting back home.";
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams);
                            $state.go('home');
                        }

                        deferred.reject(reason);
                    }
                );
            });

            return deferred.promise;
        }

        //Duplicate with Core.ChannelService
        function openPasswordPopup(passwordType, params) {
            var messageParams = {};

            messageParams.disableAutoDismiss = true;
            messageParams.blur = true;
            messageParams.hideFooter = true;
            // messageParams.cancelText = 'Cancel';
            messageParams.cancelFunction = function () {
                $state.go('home-page');
            };
            // messageParams.okText = 'Unlock';
            messageParams.okFunction = loadChannel;
            messageParams.templateParams = { passwordType: passwordType, channelParams: params };
            messageParams.template =
                'modules/channel-info/views/channel-info.channel-password.client.view.html';
            Messages.openMessage($rootScope, messageParams);
        }

        function getSharedActions() {
            $rootScope.$broadcast('ajax-start');

            var data = {
                    publishUniqueSeq: $stateParams.share,
                    password: ChannelInfoService.channelSharedPassword,
                    channelUrlName: $stateParams.channelUrlName,
                },
                deferred = $q.defer();

            if ($stateParams.share) {
                $http
                    .post('/transfer/getEntityPresetActions', data)
                    .then(
                        function (res) {
                            self.sharedActions = res.data.actions;
                            self.sharedActions = excludePreviewAction(self.sharedActions);
                            deferred.resolve();
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message =
                                'There was a problem with the shared link: ' + error.data.message.text;
                            if (error.data.message.text === 'Link is not active') {
                                messageParams.message += '\nPlease contact the artist to get new URL';
                            }
                            messageParams.title = 'Shared Link Error';
                            messageParams.blur = true;
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams).then(function (res) {
                                if (self.passwordRequired) {
                                    openPasswordPopup('share', {});
                                } else {
                                    $state.go('home-page');
                                }
                                deferred.reject();
                            });
                        }
                    )
                    .finally(function () {
                        $rootScope.$broadcast('ajax-stop');
                    });
            } else {
                $rootScope.$broadcast('ajax-stop');
                deferred.resolve();
            }

            return deferred.promise;
        }

        function isPasswordRequired(sharedLink) {
            if (sharedLink) {
                var data = {
                    publishUniqueSeq: sharedLink,
                };

                return $http.post('/transfer/isPasswordRequiredForPublish', data);
            }

            var deferred = $q.defer(),
                result = {
                    data: {
                        isPasswordRequired: false,
                    },
                };
            deferred.resolve(result);
            return deferred.promise;
        }

        this.init = function () {
            $scope.$emit('ChannelInfoChannelController:channel_info_entered');

            //Scroll to head of page in init
            var homeSecondNavElement = document.getElementById('home-secondary-nav');
            if (homeSecondNavElement) {
                smoothScroll(homeSecondNavElement, { duration: 0, offset: $rootScope.heightOfNav });
            }

            self.currentState =
                $state.current.data && $state.current.data.state ? $state.current.data.state : undefined;
            $rootScope.$broadcast('ajax-start');

            // 1. See if a password is needed from server
            isPasswordRequired($stateParams.share)
                .then(function (res) {
                    self.passwordRequired = res.data.isPasswordRequired;
                    if (self.passwordRequired) {
                        openPasswordPopup('share', {});
                    } else {
                        loadChannel();
                    }
                })
                .finally(function () {
                    $rootScope.$broadcast('ajax-stop');
                });
            // loadChannel().then(function () {
            //     self.loadingSharedActions = false;
            //     setActionButtons();
            //     _.forEach(self.channel.collections, function(collection) {
            //         _.forEach(collection.artworks, function(artwork) {
            //             artwork.belongsToCollectionId = collection.id;
            //             artwork.belongsToChannelId = self.channel.id;
            //         });
            //     });
            //     _.forEach(self.channel.artworks, function(artwork) {
            //         artwork.belongsToChannelId = self.channel.id;
            //     });
            //     var collectionsArtworks = _.pluck(self.channel.collections, 'artworks');
            //     collectionsArtworks = _.flatten(collectionsArtworks);
            //     self.channel.artworks = _.union(collectionsArtworks, self.channel.artworks);
            //     self.channel.artworks = _.uniq(self.channel.artworks, function(artwork) {
            //         return artwork.id;
            //     });
            // });
        };

        this.init();

        this.handleAction = function (action) {
            action.isDisabled = true;
            ChannelService.handleChannelAction(action.name, self.channel, {
                share: $stateParams.share,
                password: ChannelInfoService.channelSharedPassword,
                action: action,
            }).finally(function () {
                action.isDisabled = false;
            });
        };

        this.closeDeviceInterface = function () {
            NiioPlayer.Close();
        };

        this.deleteChannel = function (channelId, index) {
            $scope.submitWasClicked = true;
            var messageParams = {};
            messageParams.message = 'Are you sure you want to delete this channel?';
            messageParams.message += '\nPlease note, you cannot undo this action.';
            messageParams.title = 'Delete Channel';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    ChannelService.handleChannelAction('delete', channelId).then(
                        function (res) {
                            $log.debug(
                                'HomeCollectionsMainController::deleteChannel channel was deleted',
                                res
                            );
                            var messageParams = {};
                            messageParams.message = 'Channel was deleted successfully.';
                            Messages.openMessage($scope, messageParams).finally(function () {
                                // TODO: This will perform full refresh of the page and the user will lose the back function
                                $window.location.href = '/' + $scope.from + '/channels';
                                // $state.go('^');
                                // $state.reload();
                                // $state.transitionTo($scope.from + '.channels', {}, {
                                //     reload: true,
                                //     inherit: false,
                                //     notify: true
                                // });
                            });
                        },
                        function (error) {
                            $log.debug(
                                'HomeCollectionsMainController::deleteChannel channel was failed to delete',
                                error
                            );
                            var messageParams = {};
                            messageParams.message = 'An error has occurred. Please try again later.';
                            messageParams.title = 'Delete Channel';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };

        // $scope.$on('ChannelService::failed_subscribe_toggle', function(events, isSubscribe, channel, reason) {
        //     var messageParams = {};
        //     messageParams.title = 'Subscription Failure';
        //     messageParams.message = 'There was an error ' + (isSubscribe === 'subscribe' ? '' : 'un') + 'subscribing to ' + channel.name + '.\nRefresh the page and try again.';
        //     messageParams.message += '\nError: ' + reason;
        //     messageParams.disableAutoDismiss = true;
        //     Messages.openMessage($rootScope, messageParams);
        // });
        //
        // $scope.$on('ChannelService::success_subscribe_toggle', function(events, isSubscribe, channel, response, data) {
        //     var messageParams = {};
        //     messageParams.message = 'Successfully ' + (isSubscribe === 'subscribe' ? '' : 'un') + 'subscribed channel ' + channel.name;
        //     Messages.openMessage($rootScope, messageParams);
        //     // if (isSubscribe !== 'subscribe') {
        //     //     $rootScope.previousState();
        //     // }
        // });

        $scope.$on('$destroy', function () {
            ChannelInfoService.channelSharedPassword = '';
            Messages.cancel();
            $scope.$emit('ChannelInfoChannelController:channel_info_destroyed');
        });
    },
]);
