'use strict';

angular.module('users').controller('InvitationController', [
    '$rootScope',
    '$state',
    '$scope',
    '$stateParams',
    'Security',
    'Messages',
    'FormValidationService',
    function ($rootScope, $state, $scope, $stateParams, Security, Messages, FormValidationService) {
        var self = this;
        this.roles = [
            'Artist',
            'Gallery',
            'Collector',
            'Curator',
            'Organization',
            'Art Institution',
            'Art Fan',
            'Potential Partner',
        ];
        this.FormValidationService = FormValidationService;
        this.Messages = Messages;
        this.title = '';

        function onSuccessInvitation() {
            Messages.ok();
            var messageParams = {};
            messageParams.message = 'Thank you. Your request has been submitted successfully.';
            Messages.openMessage($rootScope, messageParams);
        }

        function onFailedInvitation() {
            var messageParams = {};
            messageParams.message = 'Failed to send request.';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($rootScope, messageParams);
        }

        this.submitInvitation = function () {
            Security.sendInvitation(self.user, onSuccessInvitation, onFailedInvitation);
        };

        this.init = function () {
            self.user = {};
            self.user.role = 'Artist';
            self.user.type = self.params && self.params.type ? self.params.type : 'invitation';
            self.user.source = self.params && self.params.source ? self.params.source : null;
            self.user.newsletter = true;
            self.title = self.user.type === 'invitation' ? 'Request an invite' : '4K Art Console';
        };
    },
]);
