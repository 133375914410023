'use strict';

angular.module('billing').controller('BillingController', [
    '$scope',
    '$stateParams',
    'BillingService',
    'ArtworkService',
    'HomeDataService',
    function ($scope, $stateParams, BillingService, ArtworkService, HomeDataService) {
        $scope.BillingService = BillingService;

        $scope.init = function () {
            BillingService.artworkId = $stateParams.artworkId;
            ArtworkService.fetchArtworkData($stateParams.artworkId, {
                channelId: BillingService.channelId,
            }).then(function (res) {
                BillingService.artwork = res.data.data;
            });

            // clean collections cache
            HomeDataService.resetCachedVaultCollectionsByUser();
            HomeDataService.resetCachedPortfolioCollectionsByUser();
        };
    },
]);
