'use strict';

angular.module('core').directive('socialPanel', [
    'AdminManager',
    '$window',
    function (AdminManager, $window) {
        return {
            restrict: 'E',
            link: function (scope, element, attrs) {},
            template:
                '<a href="https://twitter.com/@niioart" target="_blank"><i class="fa fa-twitter"></i></a>' +
                '<a href="https://www.facebook.com/niioart" target="_blank"><i class="fa fa-facebook"></i></a>' +
                '<a href="https://medium.com/@niioart" target="_blank"><i class="fa fa-medium"></i></a>' +
                '<a href="https://www.instagram.com/niioart/" target="_blank"><i class="fa fa-instagram"></i></a>',
        };
    },
]);
