'use strict';

angular.module('core').directive('validFile', [
    function () {
        return {
            require: 'ngModel',
            link: function (scope, el, attrs, ngModel) {
                el.bind('change', function () {
                    scope.$apply(function () {
                        ngModel.$setViewValue(el.val());
                        ngModel.$render();
                    });
                });
            },
        };
    },
]);

// This directive is specificly for validating file input that is currently not supported by angular
