'use strict';

angular.module('home').controller('TransferHistoryController', [
    '$rootScope',
    '$http',
    '$q',
    '$log',
    '$filter',
    '_',
    'Messages',
    'NIIO_URL',
    'ENTITY_STATUSES',
    'PUBLISH_TYPES',
    'CHANNEL_TYPES',
    function (
        $rootScope,
        $http,
        $q,
        $log,
        $filter,
        _,
        Messages,
        NIIO_URL,
        ENTITY_STATUSES,
        PUBLISH_TYPES,
        CHANNEL_TYPES
    ) {
        var self = this;
        this.Messages = Messages;
        this.NIIO_URL = NIIO_URL;
        this.CHANNEL_TYPES = CHANNEL_TYPES;

        function matchTransactionsWithPublishes(publishes, entityUserStatuses) {
            var foundPublish;

            if (entityUserStatuses.length > 0) {
                entityUserStatuses.forEach(function (entityUserStatus) {
                    // match a publish
                    if (entityUserStatus.publish_id) {
                        foundPublish = _.find(publishes, function (publish) {
                            return publish.id === entityUserStatus.publish_id;
                        });
                        if (foundPublish) {
                            if (!foundPublish.entity_user_statuses) {
                                foundPublish.entity_user_statuses = [];
                            }
                            foundPublish.entity_user_statuses.push(entityUserStatus);
                        }
                    }
                });
            }
        }

        function jsonToString(jsonObject) {
            var result = JSON.stringify(jsonObject); // To string
            result = result.replace(/\"/g, '');
            result = result.replace(/:/g, ': ');
            result = result.replace(/,/g, ', ');
            result = result.substr(1); // remove first {
            result = result.substring(0, result.length - 1); // remove last }
            return result;
        }

        function convertJsonToHumansString(jsonObject) {
            var result = {},
                key,
                newKey;
            for (key in jsonObject) {
                if (jsonObject.hasOwnProperty(key)) {
                    // humanize the value of the parameter
                    jsonObject[key] = $filter('inflector')(jsonObject[key]);
                    // humanize the key of the parameter
                    newKey = $filter('inflector')(key);
                    result[newKey] = jsonObject[key];
                }
            }
            return jsonToString(result);
        }

        function revoke(transaction) {
            var deferred = $q.defer();

            var messageParams = {};
            messageParams.message =
                'Revoking the artwork will remove the artwork from that user. Are you sure you want to continue?';
            messageParams.title = 'Revoke Artwork';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Revoke';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        artworkId: transaction.artwork_id,
                        userId: transaction.user_id,
                    };

                    transaction.active = !transaction.active;

                    $http.post('/artworks/revoke', data).then(
                        function (res) {
                            $log.debug('TransferHistoryController::revoke Success', { res: res });
                            deferred.resolve(res);
                        },
                        function (error) {
                            $log.debug('TransferHistoryController::revoke Failure', { error: error });
                            transaction.active = !transaction.active;
                            deferred.reject(error);
                        }
                    );
                } else {
                    deferred.resolve();
                }
            });

            return deferred.promise;
        }

        function deletePublish(publish) {
            var deferred = $q.defer();

            var messageParams = {};
            messageParams.message =
                'Deleting this share will prevent anyone from reaching this link. Are you sure you want to continue?';
            messageParams.title = 'Delete Share';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        publishId: publish.id,
                    };

                    publish.active = !publish.active;

                    $http.post('/transfer/deletePublish', data).then(
                        function (res) {
                            $log.debug('TransferHistoryController::deletePublish Success', { res: res });
                            deferred.resolve(res);
                        },
                        function (error) {
                            $log.debug('TransferHistoryController::deletePublish Failure', { error: error });
                            publish.active = !publish.active;
                            deferred.reject(error);
                        }
                    );
                } else {
                    deferred.resolve();
                }
            });

            return deferred.promise;
        }

        function getActionByStatus(entityUserStatus) {
            if (entityUserStatus) {
                if (entityUserStatus.status_id === ENTITY_STATUSES.SHARED) {
                    entityUserStatus.removeAction = revoke;
                    entityUserStatus.removeActionName = 'Revoke';
                } else if (entityUserStatus.status_id === ENTITY_STATUSES.ON_LOAN) {
                    //entityUserStatus.removeAction = noop;
                    entityUserStatus.removeActionName = 'End Loan';
                } else if (entityUserStatus.status_id === ENTITY_STATUSES.RENTED) {
                    //entityUserStatus.removeAction = noop;
                    entityUserStatus.removeActionName = 'End Rental';
                } else if (entityUserStatus.status_id === ENTITY_STATUSES.DOWNLOADED) {
                    //entityUserStatus.removeAction = revoke;
                    entityUserStatus.removeActionName = 'N/A';
                }
            }
        }

        function getStatusNameByActive(entityUserStatus) {
            if (entityUserStatus) {
                entityUserStatus.activeStatusName = entityUserStatus.status.name;
                if (entityUserStatus.status_id === ENTITY_STATUSES.SHARED) {
                    entityUserStatus.notActiveStatusName = 'Revoked';
                } else if (entityUserStatus.status_id === ENTITY_STATUSES.ON_LOAN) {
                    entityUserStatus.notActiveStatusName = 'Ended Loan';
                } else if (entityUserStatus.status_id === ENTITY_STATUSES.RENTED) {
                    entityUserStatus.notActiveStatusName = 'Ended Rental';
                } else if (entityUserStatus.status_id === ENTITY_STATUSES.DOWNLOADED) {
                    entityUserStatus.notActiveStatusName = '';
                }
            }
        }

        function getActionByPublish(publish) {
            if (publish) {
                if (
                    publish.publish_type_id === PUBLISH_TYPES.MAIL ||
                    publish.publish_type_id === PUBLISH_TYPES.EMBED
                ) {
                    //publish.addAction = enablePublish;
                    //publish.addActionName = 'Enable';
                    publish.removeAction = deletePublish;
                    publish.removeActionName = 'Delete';
                } else if (publish.publish_type_id === PUBLISH_TYPES.LINK) {
                    //publish.addAction = enablePublish;
                    //publish.addActionName = 'Enable';
                    publish.removeAction = deletePublish;
                    publish.removeActionName = 'Delete';
                }
            }
        }

        function initEntityUserStatuses(entityUserStatuses) {
            //    var newKey;

            if (entityUserStatuses.length > 0) {
                entityUserStatuses.forEach(function (entityUserStatus) {
                    getActionByStatus(entityUserStatus);
                    getStatusNameByActive(entityUserStatus);
                    //            entityUserStatus.parametersForHumans = JSON.parse(entityUserStatus.parameters);
                    //            entityUserStatus.parametersForHumans.forEach(function(parameter, index) {
                    //                entityUserStatus.parametersForHumans[index] = $filter('inflector')(parameter);
                    //            });
                    //            Object.keys(entityUserStatus.parametersForHumans).forEach(function(key, index) {
                    //                newKey = $filter('inflector')(key);
                    //                entityUserStatus.parametersForHumans[newKey] = entityUserStatus.parameters[key];
                    //                delete entityUserStatus.parametersForHumans[key];
                    //            });
                });
            }
        }

        function initPublishes(publishes) {
            var parameters;

            if (publishes.length > 0) {
                publishes.forEach(function (publish) {
                    getActionByPublish(publish);

                    // List of mail recipients
                    // parameters = JSON.parse(publish.parameters);
                    // if (publish.publish_type_id === '1' && parameters && parameters.recipients) { // mail
                    //     publish.recipients = _.pluck(parameters.recipients, 'text');
                    // }

                    // List of allowed actions
                    if (publish.entity_preset_actions.length > 0) {
                        publish.entity_preset_actions.forEach(function (action) {
                            action.parametersForHumans = convertJsonToHumansString(
                                JSON.parse(action.parameters)
                            );
                            action.nameForHumans = $filter('inflector')(action.name);
                        });
                    }

                    //publish.num_id = parseInt(publish.id);

                    //if (publish.entity_user_statuses) {
                    //    publish.receivers = [];
                    //    publish.entity_user_statuses.forEach(function(receiver) {
                    //        publish.receivers.push({id: receiver.user_id, url: receiver.user.url, display_name: receiver.user.display_name});
                    //    });
                    //}
                });
            }
        }

        function getPublishes(artworkId, channelId) {
            var deferred = $q.defer();

            var data = {
                artworkId,
                channelId,
            };

            $http.post('/transfer/getPublishes', data).then(
                function (res) {
                    self.publishes = res.data.data.publishes;
                    self.entityUserStatuses = res.data.data.entity_user_statuses;
                    initEntityUserStatuses(self.entityUserStatuses);
                    matchTransactionsWithPublishes(self.publishes, self.entityUserStatuses);
                    initPublishes(self.publishes);
                    $log.debug('TransferHistoryController::getPublishes Success', { res: res });
                    deferred.resolve(res);
                },
                function (error) {
                    $log.debug('TransferHistoryController::getPublishes Failure', { error: error });
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        this.init = function () {
            self.artwork = self.params && self.params.artwork ? self.params.artwork : {};
            self.channel = self.params && self.params.channel ? self.params.channel : {};
            self.publishes = [];
            self.artworkId = self.params && self.params.artwork ? self.params.artwork.id : '';
            self.channelId = self.params && self.params.channel ? self.params.channel.id : '';
            // Get artwork publishes
            self.loadingTransfer = true;
            if (self.artworkId || self.channelId) {
                getPublishes(self.artworkId, self.channelId).finally(function (res) {
                    self.loadingTransfer = false;
                });
            }
            //this.transactionsOrderByField = 'start';
            //this.transactionsReverse = true;
            //this.sharesOrderByField = ['num_id', 'active'];
            //this.sharesReverse = true;
        };
    },
]);
