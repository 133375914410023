'use strict';

angular.module('artwork-info').controller('ArtworkInfoArtworkArtistBioController', [
    'Messages',
    function (Messages) {
        var self = this;
        this.Messages = Messages;

        this.init = function () {
            self.artistBio = self.params.artistBio;
            self.displayName = self.params.displayName;
        };
    },
]);
