// Setting up route
angular.module('home').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            // .when('/home', '/home/main')
            .when('/home/vault', '/home/vault/0/all')
            .when('/home/portfolio', '/home/portfolio/0/all')
            .when('/home/channels', '/home/channels/allChannels');
        // .when('/home/catalogs', '/home/catalogs/allCatalogs');
        //            .when('/home/main', '/home/main/all');
        //            .when('/home/vault', 'home/vault/vault/Shai2')
        //            .when('/home/channels', 'home/channels/vault/Shai2');

        // Users state routing
        $stateProvider
            .state('home', {
                url: '/home?reset&code&email&deleteAccount&deviceType',
                //                abstract: true,
                templateUrl: 'modules/home/views/home.client.view.html',
                controller: 'HomeController',
                data: {
                    security: {
                        secured: true,
                    },
                },
                resolve: {
                    profile: [
                        'User',
                        '$stateParams',
                        '$q',
                        function (User, $stateParams, $q) {
                            var deferred = $q.defer();

                            // $stateParams.url = undefined;
                            deferred.resolve();

                            return deferred.promise;
                        },
                    ],
                },
            })

            .state('home.main-tab', {
                //                url: '/main',
                abstract: true,
                templateUrl: 'modules/home/views/home.main-tab.client.view.html',
                controller: 'HomeMainTabController',
            })

            .state('home.main-tab.main', {
                //                url: '/:sidebarSelection',
                url: '/main',
                templateUrl: 'modules/home/views/home.main-tab.main.client.view.html',
                controller: 'HomeMainTabMainController',
            })

            .state('home.editProfile', {
                url: '/edit_profile',
                templateUrl: 'modules/home/views/home.edit-profile.client.view.html',
                controller: 'HomeEditProfileController',
                resolve: {
                    user: [
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$q',
                        '$stateParams',
                        'Messages',
                        'User',
                        function (HomeDataService, $log, $rootScope, $state, $q, $stateParams, Messages, User) {
                            var url = User.getUrl();
                            var deferred = $q.defer();

                            if (url) {
                                HomeDataService.getUserDetailsByUrl(url).then(
                                    (user) => {
                                        User.setUserDetails(user); // Update the session
                                        deferred.resolve(user);
                                    },
                                    (error) => {
                                        $log.debug('home::getUserDetailsByUrl', {
                                            state: $state,
                                            stateParams: $stateParams,
                                            error,
                                        });
                                        var messageParams = {};
                                        messageParams.message = `The url ${$rootScope.$platformManager.platform.url}/${url} doesn't exist. redirecting.`;
                                        messageParams.disableAutoDismiss = true;
                                        Messages.openMessage($rootScope, messageParams).then((res) => {
                                            $rootScope.previousState('home');
                                        });
                                        deferred.reject(error);
                                    }
                                );
                            } else {
                                deferred.reject();
                            }

                            return deferred.promise;
                        },
                    ],
                },
            })

            .state('home.portfolioUpload', {
                url: '/portfolio/upload?artworkId&collectionId&collectionLink&filterArtist',
                templateUrl: 'modules/home/views/home.upload.client.view.html',
                controller: 'HomeUploadController',
                data: {
                    from: 'portfolio',
                },
                params: {
                    eventId: 0,
                },
            })

            .state('home.portfolioUpload.basic', {
                //                url: '/basic',
                templateUrl: 'modules/home/views/home.upload.basic.client.view.html',
            })

            .state('home.portfolioUpload.advanced', {
                //                url: '/advanced',
                templateUrl: 'modules/home/views/home.upload.advanced.client.view.html',
            })

            .state('home.vaultUpload', {
                url: '/vault/upload?artworkId&collectionId&collectionLink&filterArtist',
                templateUrl: 'modules/home/views/home.upload.client.view.html',
                controller: 'HomeUploadController',
                data: {
                    from: 'vault',
                },
                params: {
                    eventId: 0,
                },
            })

            .state('home.vaultUpload.basic', {
                //                url: '/basic',
                templateUrl: 'modules/home/views/home.upload.basic.client.view.html',
            })

            .state('home.vaultUpload.advanced', {
                //                url: '/advanced',
                templateUrl: 'modules/home/views/home.upload.advanced.client.view.html',
            })

            .state('mobileUploadOpenUppy', {
                url: '/mobile_upload_cloud?from&artworkType&fromIframe&artworkId&collectionId&token&publicToken&returnToApp&open&address',
                templateUrl: 'modules/home/views/home.upload-uppy-iframe.client.view.html',
                controller: 'HomeUploadController',
            })

            .state('home.mobileUpload', {
                url: '/mobile_upload?from&fromIframe&artworkId&collectionId&open&address',
                templateUrl: 'modules/home/views/home.upload-modal.client.view.html',
                controller: 'HomeUploadController',
            })

            .state('home.vaultAddCollection', {
                url: '/vault/add_collection?collectionId&collectionLink',
                templateUrl: 'modules/home/views/home.add-collection.client.view.html',
                controller: 'HomeAddCollectionController',
                data: {
                    from: 'vault',
                },
                resolve: {
                    collection: [
                        'HomeDataService',
                        '$state',
                        '$stateParams',
                        function (HomeDataService, $state, $stateParams) {
                            var showReadyOnlyArtworks = true;

                            return HomeDataService.getCollectionData(
                                $stateParams.collectionId,
                                !showReadyOnlyArtworks
                            ).then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $state.go('home.vault.main', { collectionId: 0, collectionLink: 'all' }); // TODO - change state
                                }
                            );
                        },
                    ],
                    artworks: [
                        'HomeDataService',
                        '$state',
                        '$stateParams',
                        function (HomeDataService, $state) {
                            return HomeDataService.getAddCollectionArtworks('vault').then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $state.go('home.vault.main', { collectionId: 0, collectionLink: 'all' });
                                }
                            );
                        },
                    ],
                },
            })

            .state('home.portfolioAddCollection', {
                url: '/portfolio/add_collection?collectionId&collectionLink',
                templateUrl: 'modules/home/views/home.add-collection.client.view.html',
                controller: 'HomeAddCollectionController',
                data: {
                    from: 'portfolio',
                },
                resolve: {
                    collection: [
                        'HomeDataService',
                        '$state',
                        '$stateParams',
                        function (HomeDataService, $state, $stateParams) {
                            var showReadyOnlyArtworks = true;

                            return HomeDataService.getCollectionData(
                                $stateParams.collectionId,
                                !showReadyOnlyArtworks
                            ).then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $state.go('home.portfolio.main', {
                                        collectionId: 0,
                                        collectionLink: 'all',
                                    });
                                }
                            );
                        },
                    ],
                    artworks: [
                        'HomeDataService',
                        '$state',
                        '$stateParams',
                        function (HomeDataService, $state) {
                            return HomeDataService.getAddCollectionArtworks('portfolio').then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $state.go('home.portfolio.main', {
                                        collectionId: 0,
                                        collectionLink: 'all',
                                    });
                                }
                            );
                        },
                    ],
                },
            })

            .state('home.channels', {
                url: '/channels',
                // abstract: true,
                templateUrl: 'modules/home/views/home.channels.client.view.html',
                controller: 'HomeChannelsController',
                data: {
                    security: {
                        secured: true,
                    },
                },
                resolve: {
                    channels: [
                        'HomeDataService',
                        function (HomeDataService) {
                            return HomeDataService.getChannels();
                        },
                    ],
                },
            })

            .state('home.addChannel', {
                url: '/channels/add_channel?channelId',
                templateUrl: 'modules/home/views/home.add-channel.client.view.html',
                controller: 'HomeAddChannelController',
                resolve: {
                    channel: [
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$stateParams',
                        'Messages',
                        function (HomeDataService, $log, $rootScope, $state, $stateParams, Messages) {
                            return HomeDataService.getChannelData($stateParams.channelId, true /* isEdit */).then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $log.debug('home.addChannel::', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The channel is not valid, redirecting to your channels.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then((res) => {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                    isAllowed: [
                        'channel',
                        'HomeDataService',
                        'User',
                        '$state',
                        function (channel, HomeDataService, User, $state) {
                            var result = false;
                            if (channel && channel.creator.user_id && User.getId() !== channel.creator.user_id) {
                                $state.go('home.channels.main', { channelsName: 'all' });
                            } else {
                                result = true;
                            }
                            return result;
                        },
                    ],
                },
            })

            .state('home.channels.main', {
                url: '/:channelsName',
                templateUrl: 'modules/home/views/home.channels.main.client.view.html',
                controller: 'HomeChannelsMainController',
                resolve: {
                    displayChannels: [
                        'channels',
                        'HomeDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$stateParams',
                        'Messages',
                        function (channels, HomeDataService, $log, $rootScope, $state, $stateParams, Messages) {
                            return HomeDataService.getChannelsToDisplay(channels, $stateParams.channelsName).then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $log.debug('home.channels.main::', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting to your channels.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then((res) => {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                },
            })

            .state('home.channels.channel', {
                url: '/:channelsName/:channelUrlName',
                templateUrl: 'modules/channel-info/views/channel-info.channel.client.view.html',
                controller: 'ChannelInfoChannelController',
                controllerAs: 'vm',
            })

            .state('home.vault', {
                url: '/vault',
                templateUrl: 'modules/home/views/home.collections.client.view.html',
                controller: 'HomeCollectionsController',
                abstract: true,
                data: {
                    security: {
                        secured: true,
                    },
                    state: 'vault',
                },
                resolve: {
                    collections: [
                        'HomeDataService',
                        function (HomeDataService) {
                            var loggedInUser = null;
                            var showReadyOnlyArtworks = true;
                            return HomeDataService.getVaultCollections(loggedInUser, !showReadyOnlyArtworks);
                        },
                    ],
                },
            })

            .state('home.vault.main', {
                url: '/:collectionId/:collectionLink',
                templateUrl: 'modules/home/views/home.collections.main.client.view.html',
                controller: 'HomeCollectionsMainController',
                params: {
                    filterArtist: null,
                },
                resolve: {
                    artworks: [
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            var showReadyOnlyArtworks = true;
                            return HomeDataService.getVaultCollections(null, !showReadyOnlyArtworks).then(
                                (collections) => {
                                    console.log('collections', collections);
                                    return HomeDataService.getCollectionArtworks(
                                        collections,
                                        $stateParams.collectionId,
                                        $stateParams.collectionLink,
                                        !showReadyOnlyArtworks
                                    ).then(
                                        (value) => {
                                            return value;
                                        },
                                        (reason) => {
                                            $log.debug('home.vault.main::', {
                                                state: $state,
                                                stateParams: $stateParams,
                                                reason,
                                            });
                                            var messageParams = {};
                                            messageParams.title = 'Enter set';
                                            messageParams.message =
                                                'There was a problem entering the set, please try again later.';
                                            messageParams.disableAutoDismiss = true;
                                            Messages.openMessage($rootScope, messageParams).then((res) => {
                                                $rootScope.previousState('home');
                                            });
                                        }
                                    );
                                }
                            );
                        },
                    ],
                },
            })

            .state('home.vault.artwork', {
                url: '/:collectionId/:collectionLink/:artworkId',
                templateUrl: 'modules/artwork-info/views/artwork-info.artwork.client.view.html',
                controller: 'ArtworkInfoArtworkController',
                controllerAs: 'vm',
                // templateUrl: 'modules/home/views/home.artwork.client.view.html',
                // controller: 'HomeArtworkController',
                resolve: {
                    isValidLink: [
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.redirectIfDesiredLinkIsNotInAllLinks(
                                collections,
                                $stateParams.collectionId,
                                $stateParams.collectionLink
                            ).then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $log.debug('home.vault.artwork::isValidLink', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then((res) => {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                    // artwork: ['isValidLink', 'HomeDataService', '$log', '$state', '$rootScope', '$location', '$stateParams', '$previousState', 'Messages', function (isValidLink, HomeDataService, $log, $state, $rootScope, $location, $stateParams, $previousState, Messages) {
                    //    return HomeDataService.getArtwork($stateParams.artworkId)
                    //        .then(function (artwork) {
                    //            if (artwork.status === 'READY' || artwork.status === 'PREVIEW_READY') {
                    //                return artwork;
                    //            } else {
                    //                $log.debug('home.vault.artwork::artwork::getArtwork', {state: $state, stateParams: $stateParams});
                    //                var messageParams = {};
                    //                messageParams.message = 'The artwork has not completed uploading, redirecting.';
                    //                messageParams.disableAutoDismiss = true;
                    //                Messages.openMessage($rootScope, messageParams)
                    //                    .then(function (res) {
                    //                        $previousState.go();
                    //                    });
                    //                retur
                    // n false;
                    //            }
                    //        }, function (reason) {
                    //            $log.debug('home.vault.artwork::artwork', {state: $state, stateParams: $stateParams, reason: reason});
                    //            var messageParams = {};
                    //            messageParams.message = 'The URL is not valid, redirecting.';
                    //            messageParams.disableAutoDismiss = true;
                    //            Messages.openMessage($rootScope, messageParams)
                    //                .then(function (res) {
                    //                    $previousState.go();
                    //                });
                    //        });
                    // }]
                },
            })

            .state('home.portfolio', {
                url: '/portfolio',
                templateUrl: 'modules/home/views/home.collections.client.view.html',
                controller: 'HomeCollectionsController',
                abstract: true,
                data: {
                    security: {
                        secured: true,
                    },
                    state: 'portfolio',
                },
                resolve: {
                    collections: [
                        'HomeDataService',
                        function (HomeDataService) {
                            var loggedInUser = null;
                            var showReadyOnlyArtworks = true;
                            return HomeDataService.getPortfolioCollections(loggedInUser, !showReadyOnlyArtworks);
                        },
                    ],
                },
            })

            .state('home.portfolio.main', {
                url: '/:collectionId/:collectionLink',
                templateUrl: 'modules/home/views/home.collections.main.client.view.html',
                controller: 'HomeCollectionsMainController',
                params: {
                    filterArtist: null,
                },
                resolve: {
                    artworks: [
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            var showReadyOnlyArtworks = true;
                            return HomeDataService.getPortfolioCollections(null, !showReadyOnlyArtworks).then(
                                (collections) => {
                                    return HomeDataService.getCollectionArtworks(
                                        collections,
                                        $stateParams.collectionId,
                                        $stateParams.collectionLink,
                                        !showReadyOnlyArtworks
                                    ).then(
                                        (value) => {
                                            return value;
                                        },
                                        (reason) => {
                                            $log.debug('home.portfolio.main::artworks::getCollectionArtworks', {
                                                state: $state,
                                                stateParams: $stateParams,
                                                reason,
                                            });
                                            var messageParams = {};
                                            messageParams.title = 'Enter set';
                                            messageParams.message =
                                                'There was a problem entering the set, please try again later.';
                                            messageParams.disableAutoDismiss = true;
                                            Messages.openMessage($rootScope, messageParams).then((res) => {
                                                $rootScope.previousState('home');
                                            });
                                        }
                                    );
                                }
                            );
                        },
                    ],
                },
            })

            .state('home.portfolio.artwork', {
                url: '/:collectionId/:collectionLink/:artworkId',
                templateUrl: 'modules/artwork-info/views/artwork-info.artwork.client.view.html',
                controller: 'ArtworkInfoArtworkController',
                controllerAs: 'vm',
                // templateUrl: 'modules/home/views/home.artwork.client.view.html',
                // controller: 'HomeArtworkController',
                resolve: {
                    isValidLink: [
                        'collections',
                        'HomeDataService',
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function (
                            collections,
                            HomeDataService,
                            $log,
                            $state,
                            $rootScope,
                            $location,
                            $stateParams,
                            Messages
                        ) {
                            return HomeDataService.redirectIfDesiredLinkIsNotInAllLinks(
                                collections,
                                $stateParams.collectionId,
                                $stateParams.collectionLink
                            ).then(
                                (value) => {
                                    return value;
                                },
                                (reason) => {
                                    $log.debug(
                                        'home.portfolio.artwork::isValidLink::redirectIfDesiredLinkIsNotInAllLinks',
                                        { state: $state, stateParams: $stateParams, reason }
                                    );
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then((res) => {
                                        $rootScope.previousState('home');
                                    });
                                    return false;
                                }
                            );
                        },
                    ],
                    // artwork: ['isValidLink', 'HomeDataService', '$log', '$state', '$rootScope', '$location', '$stateParams', '$previousState', 'Messages', function (isValidLink, HomeDataService, $log, $state, $rootScope, $location, $stateParams, $previousState, Messages) {
                    //    return HomeDataService.getArtwork($stateParams.artworkId)
                    //        .then(function (artwork) {
                    //            if (artwork.status === 'READY' || artwork.status === 'PREVIEW_READY') {
                    //                return artwork;
                    //            } else {
                    //                $log.debug('home.portfolio.artwork::artwork::getArtwork', {state: $state, stateParams: $stateParams, artwork: artwork});
                    //                var messageParams = {};
                    //                messageParams.message = 'The artwork has not completed uploading, redirecting.';
                    //                messageParams.disableAutoDismiss = true;
                    //                Messages.openMessage($rootScope, messageParams)
                    //                    .then(function (res) {
                    //                        $previousState.go();
                    //                    });
                    //                return false;
                    //            }
                    //        }, function (reason) {
                    //            $log.debug('home.portfolio.artwork::artwork::getArtwork', {state: $state, stateParams: $stateParams, reason: reason});
                    //            var messageParams = {};
                    //            messageParams.message = 'The URL is not valid, redirecting.';
                    //            messageParams.disableAutoDismiss = true;
                    //            Messages.openMessage($rootScope, messageParams)
                    //                .then(function (res) {
                    //                    $previousState.go();
                    //                });
                    //            return false;
                    //        });
                    // }]
                },
            })

            //            .state('home.artwork', {
            //                url: '^/artwork/:artwork?deviceId&secret',
            //                templateUrl: 'modules/home/views/home.artwork.client.view.html',
            //                controller: 'HomeArtworkController',
            //                resolve: {
            //                    deviceLogin: ['HomeDataService', '$stateParams', function (HomeDataService, $stateParams) {
            //                        return HomeDataService.deviceLogin($stateParams.deviceId, $stateParams.secret);
            //                    }],
            //                    artwork: ['deviceLogin', 'HomeDataService', '$state', '$stateParams', function (deviceLogin, HomeDataService, $state, $stateParams) {
            //                        var artwork = '';
            //
            //                        if ($stateParams.deviceId && $stateParams.secret && deviceLogin) {
            //                            artwork = HomeDataService.getArtwork($stateParams.artwork);
            //                        } else {
            //                            $state.go('login');
            //                        }
            //
            //                        return artwork;
            //                    }]
            //                }
            //            })

            .state('home.events', {
                url: '/events',
                templateUrl: 'modules/home/views/home.events.client.view.html',
                controller: 'HomeEventsController',
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('home.enrollToEvent', {
                url: '^/enrollToEvent?eventCode&invitationCode',
                templateUrl: 'modules/home/views/home.events.client.view.html',
                controller: 'HomeEventsController',
            })

            .state('home.addEvent', {
                url: '/events/add_event?eventId',
                templateUrl: 'modules/home/views/home.add-event.client.view.html',
                controller: 'HomeAddEventController',
                // resolve: {
                //     channel: ['HomeDataService', '$log', '$rootScope', '$state', '$stateParams', '$previousState', 'Messages', function (HomeDataService, $log, $rootScope, $state, $stateParams, $previousState, Messages) {
                //         return HomeDataService.getChannelData($stateParams.channelId, true /* isEdit */)
                //             .then(function (value) {
                //                 return value;
                //             }, function (reason) {
                //                 $log.debug('home.addChannel::', {state: $state, stateParams: $stateParams, reason: reason});
                //                 var messageParams = {};
                //                 messageParams.message = 'The channel is not valid, redirecting to your channels.';
                //                 messageParams.disableAutoDismiss = true;
                //                 Messages.openMessage($rootScope, messageParams)
                //                     .then(function (res) {
                //                         $previousState.go();
                //                     });
                //             });
                //     }],
                //     isAllowed: ['channel', 'HomeDataService', 'User', '$state', function (channel, HomeDataService, User, $state) {
                //         var result = false;
                //         if (channel && channel.creator.user_id && User.getId() !== channel.creator.user_id) {
                //             $state.go('home.channels.main', { channelsName: 'all' });
                //         } else {
                //             result = true;
                //         }
                //         return result;
                //     }]
                // }
            })

            // .state('home.events.main', {
            //     url: '/:eventsName',
            //     templateUrl: 'modules/home/views/home.events.main.client.view.html',
            //     controller: 'HomeEventsMainController',
            //     // resolve: {
            //     //     displayChannels: ['channels', 'HomeDataService', '$log', '$rootScope', '$state', '$stateParams', '$previousState', 'Messages', function (channels, HomeDataService, $log, $rootScope, $state, $stateParams, $previousState, Messages) {
            //     //         return HomeDataService.getChannelsToDisplay(channels, $stateParams.channelsName)
            //     //             .then(function(value) {
            //     //                 return value;
            //     //             }, function(reason) {
            //     //                 $log.debug('home.channels.main::', {state: $state, stateParams: $stateParams, reason: reason});
            //     //                 var messageParams = {};
            //     //                 messageParams.message = 'The URL is not valid, redirecting to your channels.';
            //     //                 messageParams.disableAutoDismiss = true;
            //     //                 Messages.openMessage($rootScope, messageParams)
            //     //                     .then(function (res) {
            //     //                         $previousState.go();
            //     //                     });
            //     //             });
            //     //     }]
            //     // }
            // })

            .state('home.events.host', {
                url: '/host/:eventId',
                templateUrl: 'modules/home/views/home.events.host.client.view.html',
                controller: 'HomeEventsHostController',
                controllerAs: 'vm',
            })

            .state('home.events.submission', {
                url: '/submission/:eventId',
                templateUrl: 'modules/home/views/home.events.submission.client.view.html',
                controller: 'HomeEventsSubmissionController',
                controllerAs: 'vm',
            })

            .state('home.events.submission.instructions', {
                url: '/instructions',
                templateUrl: 'modules/home/views/home.events.submission.instructions.client.view.html',
            })

            .state('home.events.submission.profile', {
                url: '/profile',
                templateUrl: 'modules/home/views/home.edit-profile.client.view.html',
                controller: 'HomeEditProfileController',
                resolve: {
                    user: [
                        'User',
                        function (User) {
                            return User.getUserDetails();
                        },
                    ],
                },
            })

            .state('home.events.submission.form', {
                url: '/form',
                templateUrl: 'modules/home/views/home.events.submission.form.client.view.html',
                controller: 'HomeEventsSubmissionFormController',
                controllerAs: 'vm',
            })

            .state('home.events.submission.artworks', {
                url: '/artworks',
                templateUrl: 'modules/home/views/home.events.submission.artworks.client.view.html',
                controller: 'HomeEventsSubmissionArtworksController',
                controllerAs: 'vm',
            })

            .state('home.events.submission.summary', {
                url: '/summary',
                templateUrl: 'modules/home/views/home.events.submission.summary.client.view.html',
                controller: 'HomeEventsSubmissionSummaryController',
                controllerAs: 'vm',
            })

            .state('home.error', {
                url: '/{path:.*}',
                controller($log, $rootScope, $state, $stateParams, Messages) {
                    $log.debug('home.error::', { state: $state, stateParams: $stateParams });
                    var messageParams = {};
                    messageParams.message = `The URL ${$rootScope.$platformManager.platform.url}/home/${$stateParams.path} is not valid, redirecting.`;
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams).then((res) => {
                        $state.go('home');
                    });
                },
            });
    },
]);
