'use strict';

angular.module('home').controller('HomeEventsController', [
    '$rootScope',
    'ArtworkService',
    '$filter',
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$timeout',
    '$http',
    '_',
    'smoothScroll',
    'Messages',
    'HomeDataService',
    'OpenPopupForm',
    'EVENT_TYPES',
    'EVENT_HOST_TYPES',
    function (
        $rootScope,
        ArtworkService,
        $filter,
        $scope,
        $state,
        $stateParams,
        $log,
        $q,
        $timeout,
        $http,
        _,
        smoothScroll,
        Messages,
        HomeDataService,
        OpenPopupForm,
        EVENT_TYPES,
        EVENT_HOST_TYPES
    ) {
        $scope.$state = $state;
        $scope.collapsibles = {};
        $scope.HomeDataService = HomeDataService;
        $scope.ArtworkService = ArtworkService;
        $scope.userId = $scope.profile ? $scope.profile.id : null;
        $scope.currentEvent = null;
        $scope.EVENT_HOST_TYPES = EVENT_HOST_TYPES;
        $scope.eventFilters = {
            activeFilter: undefined,
            approvedFilterFn: function (artwork) {
                //return artwork.artwork_submission && artwork.artwork_submission.approved ? true:false;
                return $scope.ArtworkService.isEventShortlist(artwork, $scope.currentEvent.event.id);
            },
            finalistFilterFn: function (artwork) {
                //return artwork.artwork_submission && artwork.is_accepted ? true:false;
                return $scope.ArtworkService.isEventFinalist(
                    artwork,
                    $scope.currentEvent.event.id,
                    $scope.currentEvent.event.type
                );
            },
        };
        $scope.submissionsFilterOptions = [
            { name: 'Show All (default)', id: '1', value: {} },
            { name: 'Show Active Events', id: '2', value: { event: { active: true } } },
            { name: 'Show Past Events', id: '3', value: { event: { active: false } } },
        ];

        $scope.eventsFilterOptions = [
            { name: 'Show All (default)', id: '1', value: {} },
            { name: 'Show Active Events', id: '2', value: { active: true } },
            { name: 'Show Past Events', id: '3', value: { active: false } },
        ];

        $scope.eventAWFilters = 'none';

        $scope.resetSubmitterFilterData = function () {
            $scope.submitterFilterData = {
                data: {},
            };
        };

        $scope.scrollToTop = function () {
            //Scroll to head of page in init
            var homeSecondNavElement = document.getElementById('home-main-content');
            if (homeSecondNavElement) {
                smoothScroll(homeSecondNavElement, { offset: $rootScope.heightOfMainHome });
            }
        };

        var resetSubmitterFilter = ($scope.resetSubmitterFilter = function () {
            $scope.submitterFilter = {};
            $scope.resetSubmitterFilterData();
            $scope.availableSubmitters = [];
        });

        $scope.setEventFilters_activeFilter = function (v) {
            $scope.eventFilters.activeFilter = v;
        };

        $scope.lists = {};
        $scope.lists.filteredArtworks = [];

        $scope.Pagination = {
            itemsPerPage: 1000000,
            currentPage: 1,
            maxSize: 5,
            totalItems: 10,
            isRequire: function () {
                return this.itemsPerPage < this.totalItems;
            },
            setPage: function (pageNo) {
                this.currentPage = pageNo;
            },
        };

        $scope.search = {
            enable: false,
            text: '',
            filterArtworks: function (artwork) {
                if (!$scope.search.enable || $scope.search.text === '') {
                    return true;
                }

                var lowerCasedSearchText = $scope.search.text.toLowerCase();

                if (
                    artwork.title.toLowerCase().indexOf(lowerCasedSearchText) !== -1 ||
                    (artwork.other_categorization_id &&
                        artwork.other_categorization_id.toLowerCase().indexOf(lowerCasedSearchText) !== -1) ||
                    (artwork.artist.display_name &&
                        artwork.artist.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1)
                ) {
                    return true;
                }
                return false;
            },
        };

        $scope.toggleSearch = function () {
            $scope.search.text = '';
            if ($scope.search.enable) {
                $scope.search.text = '';
                $scope.search.enable = false;
            } else {
                $scope.search.enable = true;
            }
        };

        var validViewTypes = ['thumbnail', 'list'];
        $scope.setViewType = function (newV) {
            if (validViewTypes.indexOf(newV) < 0) {
                return;
            }

            HomeDataService.setViewType(newV);
        };

        function enrollToEvent(eventCode) {
            $scope.enrollingToEvent = true;
            HomeDataService.enrollToEvent(eventCode, $scope.userId)
                .then(
                    function (res) {
                        if (res.data && res.data.data) {
                            if (!res.data.data.wasEnrolled) {
                                var messageParams = {};
                                messageParams.title = 'Welcome to Event Submission!';
                                messageParams.message =
                                    'You have successfully enrolled to an Event Submission.\nPlease follow the steps to complete the submission process.';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                                $scope.$emit('HomeEditProfileController:enroll_to_event');
                            }

                            $state.go($scope.fromState + '.events.submission', {
                                eventId: res.data.data.eventId,
                            });
                        }
                    },
                    function (error) {
                        var messageParams = {};
                        messageParams.title = 'Failed To Enroll To Event';
                        messageParams.message =
                            'There was a problem enrolling to the event. Please contact support (press OK to continue).';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams).then(function (res) {
                            OpenPopupForm.openContact(
                                'support',
                                null,
                                'Enrolling to event with code ' + eventCode + ' failed'
                            );
                        });
                    }
                )
                .finally(function () {
                    $stateParams.eventCode = undefined;
                    $scope.enrollingToEvent = false;
                });
        }

        $scope.unenrollEvent = function (eventId) {
            var messageParams = {};
            messageParams.message = 'Are you sure you want to un-enroll from this event?';
            messageParams.message += '\nPlease note, you cannot undo this action.';
            messageParams.title = 'Un-Enroll From Event';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Un-Enroll';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    $scope.unenrollingEvent = true;
                    HomeDataService.unenrollEvent(eventId, $scope.userId)
                        .then(
                            function (res) {
                                if (res.data && res.data.data) {
                                    if (!res.data.data.wasUnenrolled) {
                                        var messageParams = {};
                                        messageParams.title =
                                            'Successfully Un-Enrolled from an Event Submission';
                                        messageParams.message =
                                            'You have successfully unenrolled from an Event Submission.';
                                        messageParams.disableAutoDismiss = true;
                                        Messages.openMessage($scope, messageParams);
                                    }

                                    $scope.init().then(function () {
                                        goToFirstEvent($scope.events);
                                    });
                                }
                            },
                            function (error) {
                                var messageParams = {};
                                messageParams.title = 'Failed To Un-Enroll From Event';
                                messageParams.message =
                                    'There was a problem unenrolling from the event. Please contact support (press OK to continue).';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams).then(function (res) {
                                    OpenPopupForm.openContact(
                                        'support',
                                        null,
                                        'Un-Enrolling from event with Id' + eventId + ' failed'
                                    );
                                });
                            }
                        )
                        .finally(function () {
                            $scope.unenrollingEvent = false;
                        });
                }
            });
        };

        $scope.openSubmissionInfo = function (userId) {
            OpenPopupForm.openMenu('submissionInfo', {
                eventId: $scope.currentEvent.event.id,
                userId: userId,
                currentEvent: $scope.currentEvent,
            });
        };

        $scope.addAllArtworksToShortlist = function () {
            if ($scope.lists && $scope.lists.filteredArtworks && $scope.lists.filteredArtworks.length > 0) {
                ArtworkService.handleArtworkAction('approveArtworks', $scope.lists.filteredArtworks[0], {
                    artworks: $scope.lists.filteredArtworks,
                    eventId: $scope.currentEvent.event.id,
                });
            }
        };

        $scope.dynamicStyle = function (step, submission) {
            var styles = {};

            if (!step.completed) {
                styles.visibility = 'hidden';
            }

            styles.color = submission.submitted ? 'green' : '#EAC752';
            return styles;
        };

        function goToFirstEvent(events) {
            if (events.host && events.host.length > 0) {
                $state.go($scope.fromState + '.events.host', { eventId: events.host[0].id });
            } else if (events.submissions && events.submissions.length > 0) {
                $state.go($scope.fromState + '.events.submission', {
                    eventId: events.submissions[0].event_id,
                });
            }
        }

        function shouldShowFilterSelection(events, inEventProperty) {
            var i;
            var foundActive = 0;
            var foundPast = 0;
            var result = false;
            for (i = 0; i < events.length; i++) {
                if (inEventProperty) {
                    if (events[i].event && events[i].event.active) {
                        foundActive++;
                    } else {
                        foundPast++;
                    }
                } else {
                    if (events[i].active) {
                        foundActive++;
                    } else {
                        foundPast++;
                    }
                }

                if (foundPast && foundActive) {
                    result = true;
                    break;
                }
            }

            return result;
        }

        $scope.init = function () {
            var deferred = $q.defer();

            if ($stateParams.eventCode) {
                // Enroll to new event
                enrollToEvent($stateParams.eventCode);
                deferred.resolve();
            } else {
                //Scroll to head of page in init
                if (!$scope.isOther) {
                    var homeSecondNavElement = document.getElementById('home-secondary-nav');
                    if (homeSecondNavElement) {
                        smoothScroll(homeSecondNavElement, { offset: $rootScope.heightOfNav });
                    }
                }

                $scope.setViewType('list');

                $scope.events = null;
                resetSubmitterFilter(null);
                $scope.eventFilters.activeFilter = undefined;

                $scope.loadingEvents = true;
                HomeDataService.getEvents($scope.userId)
                    .then(function (events) {
                        $scope.events = events;
                        $scope.events.host = _.union($scope.events.host, $scope.events.cohosts);
                        $scope.events.host = _.sortBy($scope.events.host, function (event) {
                            return event.name.toLowerCase();
                        });
                        delete $scope.events.cohosts;
                        if ($state.current.name === $scope.fromState + '.events') {
                            goToFirstEvent(events);
                        }

                        if (events.submissions && events.submissions.length > 0) {
                            $scope.submissionsFilter = $scope.submissionsFilterOptions[0];
                            $scope.showSubmissionsFilter = shouldShowFilterSelection(
                                events.submissions,
                                true
                            );

                            $scope.eventsFilter = $scope.eventsFilterOptions[0];
                            $scope.showEventsFilter = shouldShowFilterSelection(events.host);
                        }
                    })
                    .finally(function () {
                        $scope.loadingEvents = false;
                        deferred.resolve();
                    });
            }

            return deferred.promise;
        };

        $scope.$on('event_selected', function ($event, event, type) {
            $scope.currentEvent = event;

            resetSubmitterFilter();
            $scope.eventFilters.activeFilter = undefined;

            if (type === 'host') {
                // For submitter filter
                var submissionsIdx = {};

                event.submissions.forEach(function (submission) {
                    var item = {
                        submitter: { display_name: submission.user.display_name },
                        submitterId: submission.user.id,
                        count: 0,
                    };
                    $scope.availableSubmitters.push(item);
                    submissionsIdx[submission.user.id] = item;
                });

                $scope.availableSubmitters = _.sortBy($scope.availableSubmitters, function (submitter) {
                    return submitter.submitter.display_name.toLowerCase();
                });
                event.submissionsIdx = submissionsIdx;

                event.artworks.forEach(function (artwork) {
                    var item = submissionsIdx[artwork.user_id];
                    if (item !== undefined) {
                        item.count++;
                    }
                });

                $scope.countEventLists();

                HomeDataService.setIsArtistsFilterOpen(true);

                if (!event.event.isEventOwner) {
                    var selectedEvent = _.find($scope.events.host, function (hostEvent) {
                        return hostEvent.id === event.event.id;
                    });
                    // Change default to "All" and not "Shortlist"
                    // if (event.event.hosts[$scope.profile.id].event_host.type_id === EVENT_HOST_TYPES.JURY) {
                    if (selectedEvent.event_host.type_id === EVENT_HOST_TYPES.JURY) {
                        $scope.setEventFilters_activeFilter($scope.eventFilters.approvedFilterFn);
                    }
                }
            }

            // If single submission - init wizard steps
            if (type === 'submission') {
                $scope.currentEvent.steps = HomeDataService.initSubmissionWizardSteps($scope.currentEvent);
                $scope.$broadcast('HomeEventsController::steps_initiated');
            }
        });

        $scope.$on('event_shortlist_refreshed', function ($event, eventId) {
            $scope.countEventLists();
        });
        $scope.$on('artwork_revoked', function ($event, artworkId, collectionId) {
            $scope.countEventLists();
        });

        $scope.$on(
            'ArtworkService::artwork_toggled_accept',
            function ($event, artworkId, actionId, newValue) {
                if (actionId === 'share') {
                    $scope.countEventLists();
                }
            }
        );

        $scope.$on(
            'ArtworkService::artwork_toggled_consign',
            function ($event, artworkId, actionId, newValue) {
                if (actionId === 'consign') {
                    $scope.countEventLists();
                }
            }
        );

        $scope.countEventLists = function () {
            if (!$scope.currentEvent) {
                return;
            }

            var c = 0;
            var finals = 0;
            for (var i = 0; i < $scope.currentEvent.artworks.length; i++) {
                var aw = $scope.currentEvent.artworks[i];
                if (aw.artwork_submission && aw.artwork_submission.approved) {
                    c++;
                }

                if (aw.artwork_submission && (aw.is_accepted || aw.is_consignee)) {
                    if ($scope.currentEvent.event.type === EVENT_TYPES.OPEN_CALL && aw.is_accepted) {
                        finals++;
                    } else if (
                        $scope.currentEvent.event.type === EVENT_TYPES.CONSIGN_SUBMISSION &&
                        aw.is_consignee
                    ) {
                        finals++;
                    }
                }
            }

            $scope.currentEvent.shortlistCounter = c;
            $scope.currentEvent.finalistCounter = finals;
        };

        $scope.selectStep = function (step) {
            $scope.$broadcast('HomeEventsController::select_step', step);
        };

        // $scope.$on('HomeDataService::refresh_event_data', function($event, event, type) {
        // });

        $scope.$on('$destroy', function () {
            resetSubmitterFilter();
        });
    },
]);
