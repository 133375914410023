'use strict';

angular.module('core').filter('bytes', [
    function () {
        return function (bytes, precision, straight) {
            if (isNaN(parseFloat(bytes)) || !isFinite(bytes) || bytes === 0) {
                return '-';
            }
            let base = 1024;
            if (straight) {
                base = 1000;
            }

            var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
                number = Math.floor(Math.log(bytes) / Math.log(base));

            if (typeof precision === 'undefined') {
                if (number > 2) {
                    precision = 3;
                } else {
                    precision = 2;
                }
            }
            return (bytes / Math.pow(base, Math.floor(number))).toFixed(precision) + ' ' + units[number];
        };
    },
]);
