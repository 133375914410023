'use strict';

angular.module('core').service('IntercomService', [
    '$q',
    '$rootScope',
    '$log',
    '$http',
    'User',
    'INTERCOM_APP_ID',
    function ($q, $rootScope, $log, $http, User, INTERCOM_APP_ID) {
        var self = this;

        this.init = () => {
            // Run Intercom
            let APP_ID = INTERCOM_APP_ID;
            let current_user_email = User?.getEmail() ?? null;
            let current_user_name = User?.getDisplayName() ?? null;
            let current_user_first_name = User?.getFirstName() ?? null;
            let current_user_id = User?.getId() ?? null;
            let current_user_hash = User?.getIntercomIdentityVerification() ?? null;

            window.intercomSettings = {
                app_id: APP_ID,
            };

            if (current_user_name && current_user_email && current_user_id) {
                window.intercomSettings = {
                    ...window.intercomSettings,
                    name: current_user_name,
                    first_name: current_user_first_name,
                    email: current_user_email,
                    user_id: current_user_id,
                    user_hash: current_user_hash,
                };
            }

            (function () {
                var w = window;
                var ic = w.Intercom;
                if (typeof ic === 'function') {
                    ic('reattach_activator');
                    ic('update', w.intercomSettings);
                } else {
                    var d = document;
                    var i = function () {
                        i.c(arguments);
                    };
                    i.q = [];
                    i.c = function (args) {
                        i.q.push(args);
                    };
                    w.Intercom = i;
                    var l = function () {
                        var s = d.createElement('script');
                        s.type = 'text/javascript';
                        s.async = true;
                        s.src = 'https://widget.intercom.io/widget/' + APP_ID;
                        var x = d.getElementsByTagName('script')[0];
                        x.parentNode.insertBefore(s, x);
                    };
                    if (document.readyState === 'complete') {
                        l();
                    } else if (w.attachEvent) {
                        w.attachEvent('onload', l);
                    } else {
                        w.addEventListener('load', l, false);
                    }
                }
            })();

            // window.Intercom('boot', window.intercomSettings);
        };

        this.shutdown = () => {
            window.Intercom('shutdown');
            self.init();
        };

        this.update = () => {
            self.init();
        };

        $rootScope.$on('ClearData', () => {
            self.shutdown();
        });
        $rootScope.$on('InitServices', () => {
            self.init();
        });
    },
]);
