'use strict';

angular.module('form').controller('FormRadioController', [
    '$timeout',
    function ($timeout) {
        var self = this;
        this.init = function () {
            if (self.disabledRadios) {
                self.items.forEach(function (item, index) {
                    item.disabled = true;
                });
            }
        };

        this.selectedItem = function (value, disabled) {
            if (!disabled) {
                self.model = value;
                if (self.onSelect) {
                    $timeout(function () {
                        self.onSelect(value);
                    }, 0);
                }
            }
        };
    },
]);
