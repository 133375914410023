'use strict';

angular.module('discover').controller('DiscoverChannelsMainController', [
    '$scope',
    '$rootScope',
    '$log',
    '$timeout',
    '$state',
    '$stateParams',
    '$window',
    'Messages',
    'DiscoverDataService',
    'ChannelService',
    'CHANNEL_TYPES',
    'CHANNEL_COMMERCIAL_RULES',
    function (
        $scope,
        $rootScope,
        $log,
        $timeout,
        $state,
        $stateParams,
        $window,
        Messages,
        DiscoverDataService,
        ChannelService,
        CHANNEL_TYPES,
        CHANNEL_COMMERCIAL_RULES
    ) {
        $scope.CHANNEL_TYPES = CHANNEL_TYPES;
        $scope.CHANNEL_COMMERCIAL_RULES = CHANNEL_COMMERCIAL_RULES;
        $scope.offset = 0;
        $scope.channelsName = $stateParams.channelsName;
        $scope.$emit('DiscoverChannelsMainController:channels_name_changed', $stateParams.channelsName);
        $scope.ChannelService = ChannelService;
        $scope.selectedGenre = $state.params.theme || null;
        // null - no orientation filter, true - portrait / both, false - landscape / both
        $scope.selectedOrientation = $rootScope.$root.$state.params.portrait || null;

        $scope.clearCacheData = () => {
            if ($scope.type === 'channels') {
                DiscoverDataService.setCachedDisplayArtcasts(null);
            } else {
                DiscoverDataService.setCachedDisplayCatalogs(null);
            }
        };

        $rootScope.$on('$locationChangeSuccess', function (event) {
            if ($rootScope.$root.$state.params.theme !== $scope.selectedGenre) {
                $scope.selectedGenre = $rootScope.$root.$state.params.theme;
            }

            if ($rootScope.$root.$state.params.portrait !== $scope.selectedOrientation) {
                $scope.selectedOrientation = $rootScope.$root.$state.params.portrait;
            }
        });

        $scope.resetGenreFilter = function () {
            $scope.clearCacheData();
            $state.go($state.current.name, { ...$state.params, theme: null, channelsName: 'all' });
        };

        $scope.clearGenresFilter = function () {
            $scope.clearCacheData();
            $state.go($state.current.name, { theme: null });
        };

        var initial = true;
        function loadedChannels() {
            $scope.limit = 20;
            $scope.noMoreResults = false;
            $scope.loading = false;
            // $scope.initItemsToLoad();
            $scope.lastScrollItemNumber = null;
            // Loaded channels using $timeout to enable animation on them.
            // $scope.displayChannels = [];
            $timeout(function () {
                // $scope.displayChannels = displayChannels.channels;
                $scope.displayChannels.forEach(function (channel) {
                    channel.total_artworks = ChannelService.getTotalArtworksInChannel(channel);
                });

                $scope.$emit('DiscoverChannelsMainController:channels_refreshed');
            }, 100);

            $timeout(function () {
                $scope.loaded = true;
            }, 2000);
        }

        function loadCatalogs() {
            var displayCatalogs = DiscoverDataService.getCachedDisplayCatalogs();
            if (displayCatalogs && displayCatalogs.channels && displayCatalogs.channels.length) {
                $scope.displayChannels = displayCatalogs.channels;
                $scope.isAdmin = displayCatalogs.isAdmin;
                $scope.offset = $scope.displayChannels.length;
                loadedChannels();
            } else {
                $scope.loading = true;
                DiscoverDataService.getChannelsToDisplay(
                    $stateParams.channelsName,
                    'CT',
                    $scope.limit,
                    $scope.offset,
                    $scope.selectedGenre,
                    $scope.selectedOrientation
                ).then(
                    function (value) {
                        DiscoverDataService.setCachedDisplayCatalogs(value);
                        var cachedDisplayCatalogs = DiscoverDataService.getCachedDisplayCatalogs();
                        $scope.displayChannels = cachedDisplayCatalogs.channels;
                        $scope.isAdmin = cachedDisplayCatalogs.isAdmin;
                        initial = false;
                        loadedChannels();
                    },
                    function (reason) {
                        $log.debug('discover.channels.main::', {
                            state: $state,
                            stateParams: $stateParams,
                            reason: reason,
                        });
                        var messageParams = {};
                        messageParams.message = 'The URL is not valid, redirecting.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                            $rootScope.previousState('home');
                        });
                    }
                );
            }
        }

        function loadArtcasts() {
            var displayArtcasts = DiscoverDataService.getCachedDisplayArtcasts();
            if (displayArtcasts && displayArtcasts.channels && displayArtcasts.channels.length) {
                $scope.displayChannels = displayArtcasts.channels;
                $scope.isAdmin = displayArtcasts.isAdmin;
                $scope.offset = $scope.displayChannels.length;
                loadedChannels();
            } else {
                $scope.loading = true;
                return DiscoverDataService.getChannelsToDisplay(
                    $stateParams.channelsName,
                    'AC',
                    $scope.limit,
                    $scope.offset,
                    $scope.selectedGenre,
                    $scope.selectedOrientation
                ).then(
                    function (value) {
                        DiscoverDataService.setCachedDisplayArtcasts(value);
                        var cachedDisplayArtcasts = DiscoverDataService.getCachedDisplayArtcasts();
                        $scope.displayChannels = cachedDisplayArtcasts.channels;
                        $scope.isAdmin = cachedDisplayArtcasts.isAdmin;
                        initial = false;
                        loadedChannels();
                    },
                    function (reason) {
                        $log.debug('discover.channels.main::', {
                            state: $state,
                            stateParams: $stateParams,
                            reason: reason,
                        });
                        var messageParams = {};
                        messageParams.message = 'The URL is not valid, redirecting.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                            $rootScope.previousState('home');
                        });
                    }
                );
            }
        }
        $scope.loadMore = function (limit) {
            var currentLimit = limit || $scope.limit;
            if (!initial) {
                $scope.offset += currentLimit;
            }
            initial = false;
            $scope.loading = true;
            var channelType = $scope.type === 'channels' ? 'AC' : 'CT';
            DiscoverDataService.getChannelsToDisplay(
                $stateParams.channelsName,
                channelType,
                currentLimit,
                $scope.offset,
                $scope.selectedGenre,
                $scope.selectedOrientation
            ).then(function (value) {
                $scope.displayChannels = $scope.displayChannels.concat(value.channels);
                if (channelType === 'CT') {
                    DiscoverDataService.setCachedDisplayCatalogs({
                        channels: $scope.displayChannels,
                        isAdmin: value.isAdmin,
                    });
                } else {
                    DiscoverDataService.setCachedDisplayArtcasts({
                        channels: $scope.displayChannels,
                        isAdmin: value.isAdmin,
                    });
                }
                if (!value.channels.length || value.channels.length < currentLimit) {
                    $scope.noMoreResults = true;
                }
                $scope.loading = false;
                $scope.$emit('DiscoverChannelsMainController:channels_refreshed');
            });
        };
        function pageScrollEvent() {
            //TODO make a directive
            if (!$scope.loading) {
                $scope.loading = true;
                var windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
                var body = document.body,
                    html = document.documentElement;
                var docHeight = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                );
                var windowBottom = windowHeight + window.pageYOffset;
                if (windowBottom >= docHeight - 800 && !$scope.noMoreResults) {
                    $scope.loadMore();
                } else {
                    $scope.loading = false;
                }
            }
        }
        angular.element($window).on('scroll', pageScrollEvent);

        $scope.init = function () {
            if ($scope.type === 'channels') {
                loadArtcasts();
            } else {
                // Catalogs
                loadCatalogs();
            }
        };
        $scope.$on('$destroy', function () {
            angular.element($window).off('scroll', pageScrollEvent);
        });
    },
]);
