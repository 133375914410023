'use strict';

angular.module('discover').controller('DiscoverArtistsController', [
    '$scope',
    '$state',
    'User',
    'DiscoverDataService',
    'DetectMobileBrowser',
    function ($scope, $state, User, DiscoverDataService, DetectMobileBrowser) {
        $scope.init = function () {
            $scope.isAdmin = User.isAdmin();
            $scope.isProCurator = User.isProCurator();
        };

        $scope.$state = $state;
        $scope.currentState = $state.current.data.state;
        $scope.isMobile = DetectMobileBrowser.isAny;

        $scope.sidebarItems = [
            {
                name: 'Featured',
                link: 'featured',
            },
            {
                name: 'Official',
                link: 'official',
            },
            //{
            //    name: 'All',
            //    link: 'all'
            //}
        ];

        if ($scope.isAdmin) {
            $scope.sidebarItems.push({ link: 'all', name: 'All' });
        }
        $scope.selectedSection = function (sidebarSelection) {
            DiscoverDataService.setCachedDisplayArtists(null);
            $state.go('discover.artists.main', { sidebarSelection: sidebarSelection });
        };

        $scope.fakeSelection = 'featured';
        $scope.displayTitle = true;

        $scope.$on('DiscoverArtistsMainController:artists_name_changed', function (event, sidebarSelection) {
            if (sidebarSelection) {
                $scope.fakeSelection = sidebarSelection;
            }
        });
    },
]);
