angular.module('home').service('HomeDataService', [
    '$rootScope',
    '$log',
    '$http',
    '$q',
    '$filter',
    '$localStorage',
    '_',
    'ArtworkService',
    'User',
    'EventsDataService',
    'CHANNEL_TYPES',
    'LocalConfig',
    'BUSINESS_RESTRICTIONS',
    function (
        $rootScope,
        $log,
        $http,
        $q,
        $filter,
        $localStorage,
        _,
        ArtworkService,
        User,
        EventsDataService,
        CHANNEL_TYPES,
        LocalConfig,
        BUSINESS_RESTRICTIONS
    ) {
        var self = this;
        self.LocalConfig = LocalConfig;
        self.cachedVaultUserMap = {};
        self.cachedPortfolioUserMap = {};
        self.cachedChannelsUserMap = {};

        function init() {
            self.artworkSharedPassword = '';
            self.viewType = 'thumbnail';
            self.prevViewType = 'thumbnail';
            self.isFirstTimePortfolioUpload = undefined;
            self.isFirstTimeVaultUpload = undefined;
            $rootScope.verificationProfile = { verified: true };
        }

        init();

        this.getIsArtistsFilterOpen = function () {
            return $localStorage.isArtistsFilterOpen;
        };

        this.setIsArtistsFilterOpen = function (isOpen) {
            $localStorage.isArtistsFilterOpen = isOpen;
        };

        this.setViewType = function (newType) {
            if (self.viewType !== 'sort') {
                self.prevViewType = self.viewType;
            }

            self.viewType = newType;
        };

        $rootScope.$on('ClearData', () => {
            $localStorage.isArtistsFilterOpen = undefined;
            $localStorage.openSubmissions = undefined;
            self.cachedVaultUserMap = {};
            self.cachedPortfolioUserMap = {};
            self.cachedChannelsUserMap = {};
            init();
        });

        $rootScope.$on('InitServices', () => {
            $localStorage.isArtistsFilterOpen = undefined;
            $localStorage.openSubmissions = undefined;
            self.cachedVaultUserMap = {};
            self.cachedPortfolioUserMap = {};
            self.cachedChannelsUserMap = {};
            init();
        });
        this.redirectIfDesiredLinkIsNotInAllLinks = function (links, desiredId, desiredLink) {
            var deferred = $q.defer();
            var validCollection = _.find(links, (collection) => {
                return collection.id === desiredId && collection.link === desiredLink;
            });
            if (!validCollection) {
                deferred.reject('desired link is not found in links. redirected to parent state');
            } else {
                deferred.resolve(validCollection);
            }

            return deferred.promise;
        };

        // VAULT
        this.fetchVaultCollections = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };
            return $http.post(`/collections/getVaultCollectionsByUser${limit}`, data);
        };

        this.fetchVaultCollectedArtworks = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };

            return $http.post(`/collectorArtworks/getCollectedArtworksByUser${limit}`, data);
        };

        this.fetchVaultAcceptedArtworks = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };

            return $http.post(`/artworks/getTransferredArtworks${limit}`, data);
        };

        this.fetchVaultPurchasedArtworks = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };

            return $http.post(`/signedArtworks/getPurchasedArtworksByUser${limit}`, data);
        };

        this.fetchVaultWishlistArtworks = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };

            return $http.post(`/artworkWishlists/getWishlistArtworksByUser${limit}`, data);
        };

        function setVaultCollections(
            userId,
            fetchedPurchased,
            fetchedCollected,
            fetchedAccepted,
            fetchedWishlist,
            fetchedCollections,
            fetchedNfts
        ) {
            var deferred = $q.defer();

            var collections = [];

            // var allArtworks = fetchedArtworks;
            // allArtworks = allArtworks.concat(fetchedWishlist);

            var artworks = _.union(fetchedWishlist, fetchedPurchased, fetchedAccepted, fetchedCollected);
            var artworkIds = artworks.map((artwork) => {
                return artwork.id;
            });
            // Add all artworks
            collections.push({
                name: 'Artworks',
                link: 'all',
                id: '0',
                virtual: true,
                length: artworks.length,
                artworks,
                ids: artworkIds,
            });

            // Add collections
            var tempCollections = [];
            var collectionIds = fetchedCollections.map((collection) => {
                return collection.id;
            });
            angular.forEach(
                fetchedCollections,
                function (collection, key) {
                    collection.name = collection.name || '';
                    this.push({
                        name: collection.name,
                        link: $filter('inflector')(collection.name.toLowerCase(), 'underscore'),
                        id: collection.id,
                        virtual: false,
                        length: collection.artworks.length,
                        collection,
                        ids: collectionIds,
                    });
                },
                tempCollections
            );

            tempCollections.reverse();

            collections = collections.concat(tempCollections);

            var wishlistIds = fetchedWishlist.map((wishlist) => {
                return wishlist.id;
            });
            // Add wishlist collection
            if (fetchedWishlist) {
                collections.push({
                    name: 'My Favorites',
                    link: 'wishlist',
                    id: '-1',
                    virtual: true,
                    length: fetchedWishlist.length,
                    artworks: fetchedWishlist,
                    ids: wishlistIds,
                });
            }

            // Add purchased to my collection
            if (fetchedPurchased?.length > 0) {
                var purchasedIds = fetchedPurchased.map((purchased) => {
                    return purchased.id;
                });
                collections.push({
                    name: 'Purchased Artworks',
                    link: 'purchased',
                    id: '-5',
                    virtual: true,
                    length: fetchedPurchased.length,
                    artworks: fetchedPurchased,
                    ids: purchasedIds,
                });
            }

            var nftsIds = fetchedNfts.map((nft) => {
                return nft.id;
            });

            if (fetchedNfts) {
                collections.push({
                    name: 'NFT',
                    link: 'nft',
                    id: '-3',
                    virtual: true,
                    length: fetchedNfts.length,
                    artworks: fetchedNfts,
                    ids: nftsIds,
                });
            }

            // Add transferred to me collection
            if (fetchedAccepted && (!userId || userId === User.getId())) {
                var acceptedIds = fetchedAccepted.map((accepted) => {
                    return accepted.id;
                });
                collections.push({
                    name: 'Transferred To Me',
                    link: 'transfers',
                    id: '-2',
                    virtual: true,
                    length: fetchedAccepted.length,
                    artworks: fetchedAccepted,
                    ids: acceptedIds,
                });
            }

            deferred.resolve(collections);

            return deferred.promise;
        }

        this.getVaultCollections = function (userId, readyOnlyArtworks) {
            var deferred = $q.defer();
            var cachedCollection = self.getCachedVaultCollectionsByUser(userId, readyOnlyArtworks);
            if (cachedCollection) {
                setVaultCollections(
                    userId,
                    cachedCollection.vaultPurchasedArtworks,
                    cachedCollection.vaultCollectedArtworks,
                    cachedCollection.vaultAcceptedArtworks,
                    cachedCollection.vaultWishlistArtworks,
                    cachedCollection.vaultCollections,
                    cachedCollection.vaultNftArtworks
                ).then((collections) => {
                    collections.forEach((collection) => {
                        collection.link = encodeURIComponent(collection.link);
                    });
                    deferred.resolve(collections);
                });
            } else {
                $q.all([
                    self.fetchVaultCollectedArtworks(null, userId, readyOnlyArtworks),
                    self.fetchVaultPurchasedArtworks(null, userId, readyOnlyArtworks),
                    self.fetchVaultAcceptedArtworks(null, userId, readyOnlyArtworks),
                    self.fetchVaultWishlistArtworks(null, userId, readyOnlyArtworks),
                    self.fetchVaultCollections(null, userId, readyOnlyArtworks),
                    self.fetchNftArtworks('vault'),
                ]).then(
                    (res) => {
                        $log.debug('UserInfoService::getVaultCollections Success', {
                            res,
                            userId,
                        });
                        self.setCachedVaultCollectionsByUser(
                            userId,
                            readyOnlyArtworks,
                            res[0].data.data.artworks,
                            res[1].data.data.artworks,
                            res[2].data.data.artworks,
                            res[3].data.data.artworks,
                            res[4].data.data.collections,
                            res[5].data.data.artworks
                        );
                        setVaultCollections(
                            userId,
                            res[1].data.data.artworks,
                            res[0].data.data.artworks,
                            res[2].data.data.artworks,
                            res[3].data.data.artworks,
                            res[4].data.data.collections,
                            res[5].data.data.artworks
                        ).then((collections) => {
                            collections.forEach((collection) => {
                                collection.link = encodeURIComponent(collection.link);
                            });
                            deferred.resolve(collections);
                        });
                    },
                    (error) => {
                        $log.debug('UserInfoService::getPortfolioCollections Failure', {
                            error,
                            userId,
                        });
                        deferred.reject(error);
                    }
                );
            }
            return deferred.promise;
        };

        this.fetchNftArtworks = function (origin = '') {
            // empty origin - all nfts
            // vault origin - all vault nfts
            // portfolio origin - all portfolio nfts
            const data = {
                origin,
            };

            return $http.post('/artworks/getNftArtworksByUser', data);
        };

        // PORTFOLIO
        function setPortfolioCollections(
            userId,
            fetchedCollections,
            fetchedArtworks,
            fetchedConsigned,
            fetchedNftArtworks
        ) {
            var deferred = $q.defer();

            var collections = [];

            var artworks = _.union(fetchedArtworks, fetchedConsigned);
            var artworkIds = artworks.map((artwork) => {
                return artwork.id;
            });
            // Add all artworks
            collections.push({
                name: 'Artworks',
                link: 'all',
                id: '0',
                virtual: true,
                length: artworks.length,
                artworks,
                ids: artworkIds,
            });

            // Add collections
            var tempCollections = [];
            var fetchedCollectionIds = fetchedCollections.map((collection) => {
                return collection.id;
            });
            angular.forEach(
                fetchedCollections,
                function (collection, key) {
                    this.push({
                        name: collection.name,
                        link: $filter('inflector')(collection.name.toLowerCase(), 'underscore'),
                        id: collection.id,
                        virtual: false,
                        length: collection.artworks.length,
                        collection,
                        ids: fetchedCollectionIds,
                    });
                },
                tempCollections
            );

            tempCollections.reverse();

            collections = collections.concat(tempCollections);

            // Add nft artworks collection
            if (fetchedNftArtworks) {
                var fetchedNftArtworksIds = fetchedNftArtworks.map((collection) => {
                    return collection.id;
                });
                collections.push({
                    name: "My Nft's",
                    link: 'nft',
                    id: '-3',
                    virtual: true,
                    length: fetchedNftArtworks.length,
                    artworks: fetchedNftArtworks,
                    ids: fetchedNftArtworksIds,
                });
            }
            // Add transferred to me collection
            if (fetchedConsigned && (!userId || userId === User.getId())) {
                var fetchedConsignedIds = fetchedConsigned.map((collection) => {
                    return collection.id;
                });
                collections.push({
                    name: 'Consigned To Me',
                    link: 'consigned',
                    id: '-4',
                    virtual: true,
                    length: fetchedConsigned.length,
                    artworks: fetchedConsigned,
                    ids: fetchedConsignedIds,
                });
            }

            deferred.resolve(collections);

            return deferred.promise;
        }

        this.fetchPortfolioCollections = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };

            return $http.post(`/collections/getCollectionsByUser${limit}`, data);
        };

        this.fetchPortfolioArtworks = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };

            return $http.post(`/portfolioArtworks/getPortfolioByUser${limit}`, data);
        };

        this.fetchConsignedArtworks = function (limit, userId, readyOnlyArtworks) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
                readyOnlyArtworks,
            };

            return $http.post(`/artworks/getConsignedByUser${limit}`, data);
        };

        this.isUsersChannel = function (channels, channelUrlName) {
            if (channels) {
                var urlNames = [];
                Object.keys(channels).forEach((key) => {
                    channels[key].forEach((channel) => {
                        urlNames.push(channel.url_name);
                    });
                });
                return urlNames.indexOf(channelUrlName) > -1;
            }
            return true;
        };
        this.isUsersCollection = function (resourceList, resourceId) {
            if (resourceList) {
                var ids = [];
                resourceList.forEach((res) => {
                    ids = ids.concat(res.ids);
                });
                return ids.indexOf(resourceId) > -1;
            }
            return true;
        };
        this.getPortfolioCollections = function (userId, readyOnlyArtworks) {
            var deferred = $q.defer();
            var cachedCollection = self.getCachedPortfolioCollectionsByUser(userId, readyOnlyArtworks);
            if (cachedCollection) {
                setPortfolioCollections(
                    userId,
                    cachedCollection.portfolioCollections,
                    cachedCollection.portfolioArtworks,
                    cachedCollection.portfolioConsignedArtworks,
                    cachedCollection.portfolioNftArtworks
                ).then((collections) => {
                    collections.forEach((collection) => {
                        collection.link = encodeURIComponent(collection.link);
                    });
                    deferred.resolve(collections);
                });
            } else {
                $q.all([
                    self.fetchPortfolioCollections(null, userId, readyOnlyArtworks).then((res) => {
                        $log.debug(
                            'UserInfoService::getPortfolioCollections::fetchPortfolioCollections Success',
                            { res, userId }
                        );
                        // self.portfolioCollections = res.data.data.collections;
                        return res;
                    }),
                    self.fetchPortfolioArtworks(null, userId, readyOnlyArtworks).then((res) => {
                        $log.debug(
                            'UserInfoService::getPortfolioCollections::fetchPortfolioArtworks Success',
                            { res, userId }
                        );
                        return res;
                        // self.portfolioArtworks = res.data.data.artworks;
                    }),
                    self.fetchConsignedArtworks(null, userId, readyOnlyArtworks).then((res) => {
                        $log.debug(
                            'UserInfoService::getPortfolioCollections::fetchConsignedArtworks Success',
                            { res, userId }
                        );
                        return res;
                        // self.portfolioArtworks = res.data.data.artworks;
                    }),
                    self.fetchNftArtworks('portfolio').then((res) => {
                        $log.debug('UserInfoService::getPortfolioCollections::fetchNftArtworks Success', {
                            res,
                        });
                        return res;
                    }),
                ]).then(
                    (res) => {
                        $log.debug('UserInfoService::getPortfolioCollections Success', {
                            res,
                            userId,
                        });
                        self.setCachedPortfolioCollectionsByUser(
                            userId,
                            readyOnlyArtworks,
                            res[0].data.data.collections,
                            res[1].data.data.artworks,
                            res[2].data.data.artworks,
                            res[3].data.data.artworks
                        );
                        setPortfolioCollections(
                            userId,
                            res[0].data.data.collections,
                            res[1].data.data.artworks,
                            res[2].data.data.artworks,
                            res[3].data.data.artworks
                        ).then((collections) => {
                            collections.forEach((collection) => {
                                collection.link = encodeURIComponent(collection.link);
                            });
                            deferred.resolve(collections);
                        });
                    },
                    (error) => {
                        $log.debug('UserInfoService::getPortfolioCollections Failure', {
                            error,
                            userId,
                        });
                        deferred.reject(error);
                    }
                );
            }
            return deferred.promise;
        };

        this.fetchCollectionData = function (collectionId, readyOnlyArtworks, userId) {
            var data = {
                collectionId,
                userId,
                readyOnlyArtworks,
            };

            return $http.post('/collections/getCollectionData', data);
        };

        function fetchChannelData(channelId, isEdit) {
            var data = {
                channelId,
            };
            if (isEdit) {
                data.isEdit = isEdit;
            }
            return $http.post(`/channels/getChannelData?load=${self.LocalConfig.get('loadLevel', 1)}`, data);
        }

        this.getCollectionArtworks = function (
            collections,
            collectionId,
            collectionLink,
            showReadyOnlyArtworks,
            userId
        ) {
            var deferred = $q.defer();
            this.redirectIfDesiredLinkIsNotInAllLinks(collections, collectionId, collectionLink).then(
                (collection) => {
                    $log.debug(
                        'HomeDataService::getCollectionArtworks::redirectIfDesiredLinkIsNotInAllLinks Success',
                        collection,
                        collections,
                        collectionId,
                        collectionLink,
                        showReadyOnlyArtworks
                    );
                    if (collection.artworks) {
                        deferred.resolve(collection.artworks);
                    } else if (collection.collection) {
                        self.fetchCollectionData(collection.id, showReadyOnlyArtworks, userId).then(
                            (res) => {
                                $log.debug(
                                    'HomeDataService::getCollectionArtworks::fetchCollectionData Success',
                                    res,
                                    collection.id,
                                    showReadyOnlyArtworks
                                );
                                var artworks = res.data.data.artworks;
                                deferred.resolve(artworks);
                            },
                            (error) => {
                                $log.debug(
                                    'HomeDataService::getCollectionArtworks::fetchCollectionData Failed',
                                    error,
                                    collection.id,
                                    showReadyOnlyArtworks
                                );
                                deferred.reject(error);
                            }
                        );
                    }
                },
                (reason) => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        };

        this.getArtwork = function (artworkId, artworkName) {
            var deferred = $q.defer();

            ArtworkService.fetchArtworkData(artworkId).then(
                (res) => {
                    $log.debug(
                        'HomeDataService::getArtwork Success retrieving artwork data',
                        res,
                        artworkId,
                        artworkName
                    );
                    var artworkData = res.data.data;
                    var artworkDataName = $filter('inflector')(artworkData.title.toLowerCase(), 'underscore');

                    if (artworkName && artworkDataName !== artworkName) {
                        deferred.reject();
                    } else {
                        deferred.resolve(artworkData);
                    }
                },
                (error) => {
                    $log.debug(
                        'HomeDataService::getArtwork Failed retrieving artwork data',
                        error,
                        artworkId,
                        artworkName
                    );
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        function fetchSubscribedChannels(limit, userId) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
            };

            return $http.post(
                `/channels/getSubscribedChannelsByUser${limit}?load=${self.LocalConfig.get('loadLevel', 2)}`,
                data
            );
        }

        this.fetchCuratedChannels = function (limit, userId, type) {
            limit = limit ? `/${limit}` : '';
            var data = {
                userId,
            };

            if (type) {
                data.type = type;
            }

            return $http.post(
                `/channels/getChannelsByUser${limit}?load=${self.LocalConfig.get('loadLevel', 2)}`,
                data
            );
        };

        this.deleteCollection = function (collectionId) {
            var data = {
                collectionId,
            };

            return $http.post('/collections/deleteCollection', data);
        };

        this.deleteChannel = function (channelId) {
            var data = {
                channelId,
            };

            return $http.post('/channels/deleteChannel', data);
        };

        this.getChannels = function (userId) {
            var deferred = $q.defer();
            var cachedChannels = self.getCachedChannels(userId);
            if (cachedChannels) {
                deferred.resolve(cachedChannels);
            } else {
                $q.all([fetchSubscribedChannels(null, userId), self.fetchCuratedChannels(null, userId)]).then(
                    (res) => {
                        $log.debug('UserInfoService::getChannels Success', { res, userId });
                        var subscribedChannels = _.filter(res[0].data.data.channels, (channel) => {
                            return !channel.type || channel.type === CHANNEL_TYPES.ARTCAST;
                        });

                        var curatedChannels = _.filter(res[1].data.data.channels, (channel) => {
                            return !channel.type || channel.type === CHANNEL_TYPES.ARTCAST;
                        });

                        var curatedCatalogs = _.filter(res[1].data.data.channels, (channel) => {
                            return channel.type === CHANNEL_TYPES.CATALOG;
                        });

                        var sharedCatalogs = _.filter(res[0].data.data.channels, (channel) => {
                            return channel.type === CHANNEL_TYPES.CATALOG;
                        });

                        var channels = {
                            subscribedChannels,
                            sharedCatalogs,
                            curatedChannels,
                            curatedCatalogs,
                        };
                        self.setCachedChannels(userId, channels);
                        deferred.resolve(channels);
                    },
                    (error) => {
                        $log.debug('UserInfoService::getChannels Failure', { error, userId });
                        deferred.reject(error);
                    }
                );
            }
            return deferred.promise;
        };

        this.getChannelsToDisplay = function (channels, channelsLink) {
            var deferred = $q.defer();

            var displayChannels = [];

            if (channelsLink === 'allChannels') {
                displayChannels = _.union(
                    displayChannels,
                    channels.curatedChannels,
                    channels.subscribedChannels
                );
            } else if (channelsLink === 'allCatalogs') {
                displayChannels = _.union(displayChannels, channels.sharedCatalogs, channels.curatedCatalogs);
            } else if (channelsLink === 'subscriptions') {
                displayChannels = channels.subscribedChannels;
            } else if (channelsLink === 'curatedChannels') {
                displayChannels = channels.curatedChannels;
            } else if (channelsLink === 'sharedCatalogs') {
                displayChannels = channels.sharedCatalogs;
            } else if (channelsLink === 'curatedCatalogs') {
                displayChannels = channels.curatedCatalogs;
            } else {
                deferred.reject('desired link is not found in links. redirected to home.channels');
            }

            deferred.resolve(displayChannels);

            return deferred.promise;
        };

        // function createTagsObject(tags) {
        //    var result = [];
        //
        //    tags.forEach(function (tag) {
        //        var tempTag = {};
        //        tempTag.text = tag.Tag.name;
        //        result.push(tempTag);
        //    });
        //
        //    return result;
        // }

        this.getCollectionData = function (collectionId, showReadyOnlyArtworks, userId) {
            var deferred = $q.defer();

            if (collectionId) {
                self.fetchCollectionData(collectionId, showReadyOnlyArtworks, userId).then(
                    (res) => {
                        $log.debug(
                            'HomeDataService::getCollectionData::fetchCollectionData Success',
                            res,
                            collectionId,
                            showReadyOnlyArtworks
                        );
                        var collection = res.data.data;
                        deferred.resolve(collection);
                    },
                    (error) => {
                        $log.debug(
                            'HomeDataService::getCollectionData::fetchCollectionData Failed',
                            error,
                            collectionId,
                            showReadyOnlyArtworks
                        );
                        deferred.reject();
                    }
                );
            } else {
                deferred.resolve('');
            }

            return deferred.promise;
        };

        this.getChannelData = function (channelId, isEdit) {
            var deferred = $q.defer();

            if (channelId) {
                fetchChannelData(channelId, isEdit).then(
                    (res) => {
                        $log.debug(
                            'HomeDataService::getChannelData::fetchChannelData Success',
                            res,
                            channelId
                        );
                        var channel = res.data.data;
                        deferred.resolve(channel);
                    },
                    (error) => {
                        $log.debug(
                            'HomeDataService::getChannelData::fetchChannelData Success',
                            error,
                            channelId
                        );
                        deferred.reject();
                    }
                );
            } else {
                deferred.resolve('');
            }

            return deferred.promise;
        };

        this.getUserPreferences = function (userId) {
            var data = {
                userId,
            };

            return $http.post('/users/getUserPreferences', data);
        };

        this.getAddCollectionArtworks = function (from, userId, readyOnlyArtworks) {
            var deferred = $q.defer();

            if (from === 'vault') {
                $q.all([
                    self.fetchVaultCollectedArtworks(null, userId, readyOnlyArtworks),
                    self.fetchVaultPurchasedArtworks(null, userId, readyOnlyArtworks),
                    self.fetchVaultAcceptedArtworks(null, userId, readyOnlyArtworks),
                    self.fetchVaultWishlistArtworks(null, userId, readyOnlyArtworks),
                ]).then(
                    (res) => {
                        $log.debug(
                            'HomeDataService::getAddCollectionArtworks::fetchVaultAllArtworks Success',
                            res,
                            userId,
                            readyOnlyArtworks
                        );
                        deferred.resolve(
                            _.union(
                                res[0].data.data.artworks,
                                res[1].data.data.artworks,
                                res[2].data.data.artworks,
                                res[3].data.data.artworks
                            )
                        );
                    },
                    (error) => {
                        $log.debug('HomeDataService::getAddCollectionArtworks Failure', {
                            error,
                            userId,
                        });
                        deferred.reject(error);
                    }
                );

                // self.fetchVaultCollectedArtworks(null, userId)
                //    .then(function (res) {
                //        $log.debug('HomeDataService::getAddCollectionArtworks::fetchVaultCollectedArtworks Success', res, userId);
                //        var vaultCollectedArtworks = res.data.data.artworks;
                //        deferred.resolve(vaultCollectedArtworks);
                //    }, function (error) {
                //        $log.debug('HomeDataService::getAddCollectionArtworks::fetchVaultCollectedArtworks Failed', error, userId);
                //        deferred.reject();
                //    });
            } else if (from === 'portfolio') {
                $q.all([
                    self.fetchPortfolioArtworks(null, userId, readyOnlyArtworks).then((res) => {
                        $log.debug(
                            'HomeDataService::getAddCollectionArtworks::fetchPortfolioArtworks Success',
                            res,
                            userId,
                            readyOnlyArtworks
                        );
                        return res;
                    }),
                    self.fetchConsignedArtworks(null, userId, readyOnlyArtworks).then((res) => {
                        $log.debug(
                            'HomeDataService::getAddCollectionArtworks::fetchPortfolioArtworks Success',
                            res,
                            userId,
                            readyOnlyArtworks
                        );
                        return res;
                    }),
                ]).then(
                    (res) => {
                        $log.debug('HomeDataService::getAddCollectionArtworks Success', {
                            res,
                            userId,
                        });
                        var arrA = res[0].data.data.artworks;
                        var arrB = res[1].data.data.artworks;
                        arrA = arrA.concat(arrB);

                        deferred.resolve(arrA);
                    },
                    (error) => {
                        $log.debug('HomeDataService::getAddCollectionArtworks Failure', {
                            error,
                            userId,
                        });
                        deferred.reject(error);
                    }
                );
            } else {
                $log.debug('from parameter is empty. vault or portfolio should be specified.');
                deferred.reject();
            }

            return deferred.promise;
        };

        // ARTWORK UPLOAD

        this.getUploadSettings = function (artworkId, collectionId) {
            var deferred = $q.defer();

            if (artworkId) {
                ArtworkService.fetchArtworkData(artworkId, { forceShowPrice: true, isEdit: true }).then(
                    (res) => {
                        $log.debug(
                            'HomeDataService::getUploadSettings::fetchArtworkData Success',
                            res,
                            artworkId,
                            collectionId
                        );
                        var artwork = res.data.data;
                        var uploadFormObj = {};

                        if (collectionId) {
                            uploadFormObj.collectionId = collectionId;
                        }
                        uploadFormObj.uploader = artwork.uploader;
                        uploadFormObj.artworkUserId = artwork.uploader.user_id;
                        uploadFormObj.artworkThumb = artwork.thumbnail_path;
                        uploadFormObj.id = artwork.id;
                        uploadFormObj.link = artwork.link;
                        uploadFormObj.original_work_filename = artwork.original_work_filename;
                        uploadFormObj.filename = artwork.filename;
                        uploadFormObj.allow_videowall = artwork.allow_videowall;
                        uploadFormObj.visible = artwork.visible;
                        uploadFormObj.preview_wm = artwork.preview_wm;
                        uploadFormObj.preview_qtype = artwork.preview_qtype;
                        uploadFormObj.engine_tasks_priority = artwork.engine_tasks_priority;
                        uploadFormObj.artworkType = artwork.uploader_type;
                        uploadFormObj.entity_type = artwork.entity_type;

                        uploadFormObj.extra_metadata = artwork.extra_metadata;
                        if (uploadFormObj.extra_metadata.purchase_date) {
                            uploadFormObj.extra_metadata.purchase_date = $filter('formatDate')(
                                artwork.extra_metadata.purchase_date,
                                'dd/MM/yyyy'
                            );
                        }
                        uploadFormObj.extra_metadata.purchase_price = parseInt(
                            artwork.extra_metadata.purchase_price
                        );
                        uploadFormObj.extra_metadata.additional_copies = parseInt(
                            artwork.extra_metadata.additional_copies
                        );
                        uploadFormObj.extra_metadata.edition_num = parseInt(
                            artwork.extra_metadata.edition_num
                        );
                        uploadFormObj.extra_metadata.edition_of = parseInt(artwork.extra_metadata.edition_of);
                        uploadFormObj.extra_metadata.artists_proof = parseInt(
                            artwork.extra_metadata.artists_proof
                        );
                        uploadFormObj.extra_metadata.show_manual_duration =
                            uploadFormObj.extra_metadata.show_manual_duration || false;

                        uploadFormObj.file_metadata = artwork.file_metadata;
                        uploadFormObj.display_guidelines = artwork.display_guidelines || {
                            portrait_mode: false,
                            portrait_submode: 1,
                        };

                        uploadFormObj.preview_path = artwork.preview;
                        uploadFormObj.preview = {};
                        if (artwork.preview_file && artwork.preview_file.id) {
                            if (
                                Array.isArray(artwork.preview_file.properties_json) &&
                                artwork.preview_file.properties_json.length === 0
                            ) {
                                artwork.preview_file.properties_json = null;
                            }
                            uploadFormObj.preview = artwork.preview_file;
                            uploadFormObj.preview.properties_json_old = angular.copy(
                                artwork.preview_file.properties_json
                            );
                            uploadFormObj.preview.properties_json = artwork.preview_file.properties_json;
                        }

                        uploadFormObj.cover = {};
                        if (artwork.cover_file && artwork.cover_file.id) {
                            if (
                                Array.isArray(artwork.cover_file.properties_json) &&
                                artwork.cover_file.properties_json.length === 0
                            ) {
                                artwork.cover_file.properties_json = null;
                            }
                            uploadFormObj.cover = artwork.cover_file;
                            uploadFormObj.cover.properties_json_old = angular.copy(
                                artwork.cover_file.properties_json
                            );
                            uploadFormObj.cover.properties_json = artwork.cover_file.properties_json;
                        }

                        uploadFormObj.additionalFiles = artwork.additional_files || [];

                        uploadFormObj.coCreators = artwork.co_creators;
                        uploadFormObj.blockchain_id = artwork.blockchain_id;
                        uploadFormObj.other_categorization_id = artwork.other_categorization_id;
                        uploadFormObj.private_comments = artwork.private_comments;
                        uploadFormObj.title = artwork.title;
                        uploadFormObj.description = artwork.description;
                        [uploadFormObj.creationMonth, uploadFormObj.creationYear] =
                            artwork.creation_date.split('/');
                        uploadFormObj.category = artwork.category;
                        uploadFormObj.tags = artwork.tags;
                        uploadFormObj.restricted = artwork.restricted;
                        uploadFormObj.sale_type = artwork.sale_type;
                        var num_allowed_loans = parseInt(artwork.allowed_loans);
                        uploadFormObj.loanRestriction = num_allowed_loans < 0 ? num_allowed_loans : 0; // -1 unlimit. -2 limit to purchase copy.
                        uploadFormObj.loanRestrictionCount =
                            num_allowed_loans < 0
                                ? BUSINESS_RESTRICTIONS.DEFAULT_NUM_LOAN_AWS
                                : num_allowed_loans;
                        uploadFormObj.status = artwork.status;
                        uploadFormObj.currency = artwork.currency;
                        uploadFormObj.price = parseInt(artwork.price);
                        uploadFormObj.hide_price = artwork.hide_price;
                        uploadFormObj.total_copies = parseInt(artwork.total_copies);
                        uploadFormObj.artistFirstName = artwork.artist_first_name;
                        uploadFormObj.artistLastName = artwork.artist_last_name;
                        uploadFormObj.credit_line_1 = artwork.credit_line_1;
                        uploadFormObj.credit_line_2 = artwork.credit_line_2;
                        uploadFormObj.default_loan_price_level = artwork.default_loan_price_level;
                        uploadFormObj.default_loan_period = artwork.default_loan_period;
                        uploadFormObj.is_consignee = artwork.is_consignee;

                        deferred.resolve(uploadFormObj);
                    }
                );
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        };

        // PROFILE

        this.getUserDetailsByUrl = function (url) {
            var deferred = $q.defer();

            if (url) {
                var data = {
                    url,
                };

                $http.post('/users/getUserDetailsByUrl', data).then(
                    (res) => {
                        $log.debug('HomeDataService::getUserDetailsByUrl Success', res, url);
                        var user = res.data.data;
                        deferred.resolve(user);
                    },
                    (error) => {
                        $log.debug('HomeDataService::getUserDetailsByUrl Failed', error, url);
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.reject('url is empty');
            }

            return deferred.promise;
        };

        this.enums = {};

        this.enums.styles = [
            { value: 'AB', name: 'Abstract' },
            { value: 'CO', name: 'Conceptual' },
            { value: 'DO', name: 'Documentary' },
            { value: 'EX', name: 'Experimental' },
            { value: 'FI', name: 'Figurative' },
            { value: 'ID', name: 'Installation Document' },
            { value: 'MI', name: 'Minimalism' },
            { value: 'NA', name: 'Narrative' },
            { value: 'PH', name: 'Photorealism' },
            { value: 'PL', name: 'Political' },
            { value: 'PR', name: 'Portraiture' },
            { value: 'RE', name: 'Realism' },
            { value: 'SU', name: 'Surrealism' },
            { value: 'FE', name: 'Feminist' },
            { value: 'PF', name: 'Performance' },
            { value: 'CC', name: 'Cultural Commentary' },
            { value: 'PI', name: 'Post Internet' },
            { value: 'CI', name: 'Cinematic' },
            { value: 'AN', name: 'Animation' },
            { value: 'DA', name: 'Digital Art' },
            { value: 'IN', name: 'Interactive' },
            { value: 'GE', name: 'Generative' },
            { value: 'GN', name: 'Generated' },
            { value: 'IS', name: 'Installation' },
            { value: 'OU', name: 'Outsider (Art)' },

            { value: 'AR', name: 'Architecture' },
            { value: 'AD', name: 'Audio' },
            { value: 'DC', name: 'Decorative Art' },
            { value: 'DG', name: 'Design' },
            { value: 'DD', name: 'Domestic & Domesticity' },
            { value: 'XP', name: 'Expressionism' },
            { value: 'GS', name: 'Gender & Sexuality' },
            { value: 'IT', name: 'Interior' },
            { value: 'LI', name: 'Light' },
            { value: 'LN', name: 'Landscape' },
            { value: 'MP', name: 'Mapping' },
            { value: 'MD', name: 'Media' },
            { value: 'NU', name: 'Nature' },
            { value: 'NR', name: 'Net Art' },
            { value: 'NM', name: 'New Media' },
            { value: 'PE', name: 'Periodical' },
            { value: 'PO', name: 'Pop' },
            { value: 'SC', name: 'Social' },
            { value: 'SL', name: 'Still Life' },
            { value: 'TC', name: 'Technology' },
            { value: 'UR', name: 'Urbanism' },
            { value: '3D', name: '3D' },
        ];
        this.enums.currencies = ['USD', 'EUR', 'GBP'];

        this.getCachedChannels = function (userId) {
            var key = userId || '';
            return self.cachedChannelsUserMap[key];
        };
        this.setCachedChannels = function (userId, channels) {
            var key = userId || '';
            self.cachedChannelsUserMap[key] = channels;
        };
        this.resetCachedChannels = function () {
            self.cachedChannelsUserMap = {};
        };
        this.getCachedPortfolioCollectionsByUser = function (userId, readyOnlyArtworks) {
            var key = userId || '';
            readyOnlyArtworks = !!readyOnlyArtworks;
            self.cachedPortfolioUserMap[key] = self.cachedPortfolioUserMap[key] || {};
            return self.cachedPortfolioUserMap[key][readyOnlyArtworks];
        };
        this.setCachedPortfolioCollectionsByUser = function (
            userId,
            readyOnlyArtworks,
            fetchedCollections,
            fetchedArtworks,
            fetchedConsignedArtworks,
            fetchedNftArtworks
        ) {
            var key = userId || '';
            readyOnlyArtworks = !!readyOnlyArtworks;
            self.cachedPortfolioUserMap[key] = self.cachedPortfolioUserMap[key] || {};
            self.cachedPortfolioUserMap[key][readyOnlyArtworks] = {
                portfolioCollections: fetchedCollections,
                portfolioArtworks: fetchedArtworks,
                portfolioConsignedArtworks: fetchedConsignedArtworks,
                portfolioNftArtworks: fetchedNftArtworks,
            };
        };
        this.resetCachedPortfolioCollectionsByUser = function () {
            self.cachedPortfolioUserMap = {};
        };
        this.getCachedVaultCollectionsByUser = function (userId, readyOnlyArtworks) {
            var key = userId || '';
            readyOnlyArtworks = !!readyOnlyArtworks;
            self.cachedVaultUserMap[key] = self.cachedVaultUserMap[key] || {};
            return self.cachedVaultUserMap[key][readyOnlyArtworks];
        };
        this.resetCachedVaultCollectionsByUser = function () {
            self.cachedVaultUserMap = {};
        };
        this.setCachedVaultCollectionsByUser = function (
            userId,
            readyOnlyArtworks,
            fetchedCollected,
            fetchedPurchased,
            fetchedAccepted,
            fetchedWishlist,
            fetchedCollections,
            fetchedNfts
        ) {
            var key = userId || '';
            readyOnlyArtworks = !!readyOnlyArtworks;
            self.cachedVaultUserMap[key] = self.cachedVaultUserMap[key] || {};
            self.cachedVaultUserMap[key][readyOnlyArtworks] = {
                vaultPurchasedArtworks: fetchedPurchased,
                vaultCollectedArtworks: fetchedCollected,
                vaultAcceptedArtworks: fetchedAccepted,
                vaultWishlistArtworks: fetchedWishlist,
                vaultCollections: fetchedCollections,
                vaultNftArtworks: fetchedNfts,
            };
        };

        $rootScope.$on('home_reset_portfolio_cache', self.resetCachedPortfolioCollectionsByUser);
        $rootScope.$on('home_reset_vault_cache', self.resetCachedVaultCollectionsByUser);
        $rootScope.$on('home_reset_channels_cache', self.resetCachedChannels);
        $rootScope.$on('success_subscribe_toggle', self.resetCachedChannels);
        $rootScope.$on('failed_subscribe_toggle', self.resetCachedChannels);

        // EVENTS
        this.matchQuestionsToForm = function (form, questions) {
            EventsDataService.matchQuestionsToForm(form, questions);
        };

        function getEventData(eventId, userId, type, showOnlySubmitted) {
            return EventsDataService.getEventData(eventId, userId, type, showOnlySubmitted);
        }

        this.getEventHostData = function (eventId, userId, showOnlySubmitted) {
            return EventsDataService.getEventHostData(eventId, userId, showOnlySubmitted);
        };

        this.getEventSubmissionData = function (eventId, userId) {
            return EventsDataService.getEventSubmissionData(eventId, userId);
        };

        this.getSubmissionFormQuestions = function (eventId, userId) {
            return EventsDataService.getSubmissionFormQuestions(eventId, userId);
        };

        this.getAllSubmissionsFormQuestions = function (eventId, userId) {
            return EventsDataService.getAllSubmissionsFormQuestions(eventId, userId);
        };

        this.getEvents = function (userId) {
            return EventsDataService.getEvents(userId);
        };

        this.updateStepCompleted = function (eventId, userId, stepName) {
            return EventsDataService.updateStepCompleted(eventId, userId, stepName);
        };

        this.enrollToEvent = function (eventCode, userId) {
            return EventsDataService.enrollToEvent(eventCode, userId);
        };

        this.unenrollEvent = function (eventId, userId) {
            return EventsDataService.unenrollEvent(eventId, userId);
        };

        this.initSubmissionWizardSteps = function (eventData) {
            return EventsDataService.initSubmissionWizardSteps(eventData);
        };

        this.eventInProcessExists = function (userId) {
            return EventsDataService.eventInProcessExists(userId);
        };

        this.submitList = function (eventId, userId, listName) {
            return EventsDataService.submitList(eventId, userId, listName);
        };
    },
]);
