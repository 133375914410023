'use strict';

angular.module('core').service('mobileBannerService', [
    '$rootScope',
    '$http',
    '$stateParams',
    'gdprHandlerService',
    'DetectMobileBrowser',
    function ($rootScope, $http, $stateParams, gdprHandlerService, DetectMobileBrowser) {
        var self = this;
        this.getGeneralConfigData = function () {
            return $http.get('/app/getDataForWeb');
        };
        this.getBottomValue = function () {
            if ($rootScope.isCookieConsentOpen) {
                var height = document.getElementsByClassName('cc-window')[0].clientHeight - 1;
                return (height || 0) + 'px';
            } else if ($rootScope.isCookieConsentClosed) {
                return '0px';
            }
            return '0px';
        };

        this.getCoupon = function (providerName, couponCode) {
            return $http.get('/coupons/getCouponByCode/' + providerName + '/' + couponCode);
        };
        this.isMobile = function () {
            return DetectMobileBrowser.isAny;
        };
        this.markClosed = function () {
            localStorage.setItem('niio-mobile-banner', '1');
        };
        this.markCollapsed = () => {
            localStorage.setItem('niio-mobile-banner-collapsed', '1');
        };
        this.isClosed = function () {
            return !!localStorage.getItem('niio-mobile-banner');
        };
        this.isCollapsed = () => {
            return !!localStorage.getItem('niio-mobile-banner-collapsed');
        };
    },
]);
