'use strict';

angular
    .module('core')
    .service('ClipboardService', [
        '$rootScope',
        '$q',
        function ($rootScope, $q) {
            var self = this;

            this.data = {};

            this.clear = function () {
                self.data = {};
            };

            this.get = function (t, def) {
                return self.data[t] === undefined ? def : self.data[t];
            };

            this.contains = function (t) {
                return self.data[t] !== undefined;
            };

            this.set = function (t, v) {
                self.data[t] = angular.copy(v);
            };

            this.init = function () {
                self.clear();
            };
            self.init();

            function fallbackCopyTextToClipboard(text) {
                var deferred = $q.defer();
                var isRTL = document.documentElement.getAttribute('dir') === 'rtl';
                var textArea = document.createElement('textarea');

                // Prevent zooming on iOS
                textArea.style.fontSize = '12pt';
                // Avoid scrolling to bottom
                textArea.style[isRTL ? 'right' : 'left'] = '-9999px';
                textArea.style.position = 'absolute';
                // Move element to the same position vertically
                var yPosition = window.pageYOffset || document.documentElement.scrollTop;
                textArea.style.top = yPosition + 'px';
                // Reset box model
                textArea.style.border = '0';
                textArea.style.padding = '0';
                textArea.style.margin = '0';
                textArea.setAttribute('readonly', '');
                textArea.value = text;

                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                textArea.setSelectionRange(0, textArea.value.length);

                try {
                    var successful = document.execCommand('copy');

                    document.activeElement.blur();
                    window.getSelection().removeAllRanges();
                    document.body.removeChild(textArea);
                    var msg = successful ? 'successful' : 'unsuccessful';
                    if (successful) {
                        deferred.resolve(msg);
                    } else {
                        deferred.reject(msg);
                    }
                } catch (err) {
                    document.activeElement.blur();
                    window.getSelection().removeAllRanges();
                    document.body.removeChild(textArea);
                    deferred.reject(err);
                }

                return deferred.promise;
            }

            this.copyTextToClipboard = function (text) {
                var deferred = $q.defer();

                if (!navigator.clipboard) {
                    return fallbackCopyTextToClipboard(text);
                }

                navigator.clipboard.writeText(text).then(
                    function () {
                        deferred.resolve(text);
                    },
                    function (err) {
                        deferred.reject(err);
                    }
                );

                return deferred.promise;
            };
        },
    ])
    .directive('clickToCopy', [
        '$rootScope',
        'ClipboardService',
        function ($rootScope, ClipboardService) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    element.bind('click', function (e) {
                        ClipboardService.copyTextToClipboard(attrs.clickToCopy).then(
                            function () {
                                $rootScope.$broadcast('clickToCopy-success');
                            },
                            function (err) {
                                $rootScope.$broadcast('clickToCopy-failed');
                            }
                        );
                    });
                },
            };
        },
    ]);
