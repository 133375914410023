'use strict';

angular.module('devices').constant('AW_STRIP_MODES', {
    AUTO: 'AUTO',
    NONE: 'NONE',
    START: 'START',
    /*    START_END: 'START_END',*/
    ALWAYS: 'ALWAYS',
});

angular.module('devices').constant('AW_STRIP_CONTENT', {
    AW_CREDIT: 'C',
    QR: 'Q',
    AW_CREDIT_AND_QR: 'C Q',
});

angular.module('devices').directive('awCreditStrip', [
    '$filter',
    'AW_STRIP_MODES',
    'AW_STRIP_CONTENT',
    function ($filter, AW_STRIP_MODES, AW_STRIP_CONTENT) {
        var playerTypeController = [
            '$scope',
            function ($scope) {
                var self = this;
                $scope.AW_STRIP_MODES = AW_STRIP_MODES;
                $scope.AW_STRIP_CONTENT = AW_STRIP_CONTENT;
                $scope.stripMode = $scope.stripMode || $scope.AW_STRIP_MODES.AUTO;
                $scope.stripContent = $scope.stripContent || {
                    Type: $scope.AW_STRIP_CONTENT.AW_CREDIT_AND_QR,
                    Url: '',
                };
                $scope.stripDuration = $scope.stripDuration || 5;
                $scope.qrUrlMode = $scope.stripContent.Url !== '' ? 1 : 0;

                $scope.selectStripMode = function (newMode) {
                    $scope.stripMode = newMode;
                    $scope.fireEvent();
                };
                $scope.selectStripContent = function (contentType) {
                    $scope.stripContent.Type = contentType;
                    // Note :we do not clear url...
                    $scope.fireEvent();
                };
                $scope.selectQRUrlMode = function (mode) {
                    $scope.stripContent.Url = mode === 0 ? '' : $scope.stripContent.Url;
                    $scope.fireEvent();
                };
                $scope.updateQRUrl = function () {
                    $scope.qrUrlMode = $scope.stripContent.Url !== '' ? 1 : 0;
                    $scope.fireEvent();
                };
                $scope.updateQRTitle = function () {
                    $scope.updateQRUrl();
                };
                $scope.updateStripDuration = function () {
                    $scope.fireEvent();
                };
                $scope.fireEvent = function () {
                    $scope.stripModeChanged({
                        newStripMode: $scope.stripMode,
                        newStripContent: $scope.stripContent,
                        newStripDuration: $scope.stripDuration,
                        resetFN: $scope.reset,
                    });
                };
                $scope.reset = function (mode, content, durationSec) {
                    $scope.stripMode = mode;
                    $scope.stripContent = content;
                    $scope.stripDuration = durationSec;
                };
                $scope.displayQRContentSection = function () {
                    return $scope.showQrContent && $scope?.stripContent?.Type !== $scope.AW_STRIP_CONTENT.AW_CREDIT;
                };
            },
        ];

        return {
            restrict: 'E',
            scope: {
                stripMode: '<',
                stripContent: '<',
                stripDuration: '<',
                showQrContent: '=',
                showSeparator: '=',
                stripModeChanged: '&',
            },
            link: function (scope, element, attrs) {
                attrs.showSeparator = attrs.showSeparator || false;
            },
            controller: playerTypeController,
            template:
                '<label>Credits Strip appearance (in Playback mode):</label>' +
                '<div ng-cloak="true">' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="stripMode" ng-value="AW_STRIP_MODES.AUTO" name="stripMode" ng-change="selectStripMode(AW_STRIP_MODES.AUTO)">&nbsp;{{\'AW_STRIP_MODES.AUTO\'|translate}}' +
                '</span>' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="stripMode" ng-value="AW_STRIP_MODES.NONE" name="stripMode" ng-change="selectStripMode(AW_STRIP_MODES.NONE)">&nbsp;{{\'AW_STRIP_MODES.NONE\'|translate}}' +
                '</span>' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="stripMode" ng-value="AW_STRIP_MODES.START" name="stripMode" ng-change="selectStripMode(AW_STRIP_MODES.START)">&nbsp;{{\'AW_STRIP_MODES.START\'|translate}}' +
                '<div style="margin-left:2em;">' +
                '<input type="number" min="2" max="60" ng-model="stripDuration" max="200" ng-disabled="stripMode != AW_STRIP_MODES.START" ' +
                'ng-change="updateStripDuration()" style="font-size: 18px;width: 4em;"> &nbsp;Duration (Sec)' +
                '</div>' +
                '</span>' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="stripMode" ng-value="AW_STRIP_MODES.ALWAYS" name="stripMode" ng-change="selectStripMode(AW_STRIP_MODES.ALWAYS)">&nbsp;{{\'AW_STRIP_MODES.ALWAYS\'|translate}}' +
                '</span>' +
                '</div>' +
                '<div style="padding-left: 3em;">' +
                '<label>Credit strip content:</label>' +
                '<div ng-cloak="true">' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="stripContent.Type" ng-value="AW_STRIP_CONTENT.AW_CREDIT" name="stripContent" ng-change="selectStripContent(AW_STRIP_CONTENT.AW_CREDIT)">&nbsp;{{\'AW_STRIP_CONTENT.AW_CREDIT\'|translate}}' +
                '</span>' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="stripContent.Type" ng-value="AW_STRIP_CONTENT.QR" name="stripContent" ng-change="selectStripContent(AW_STRIP_CONTENT.QR)">&nbsp;{{\'AW_STRIP_CONTENT.QR\'|translate}}' +
                '</span>' +
                '<div style="padding-left: 3em;" ng-if="displayQRContentSection()">' +
                '<label>QR content:</label>' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="qrUrlMode" ng-value="0" name="qrUrlMode" ng-change="selectQRUrlMode(0)">&nbsp;AW social Url' +
                '</span>' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="qrUrlMode" ng-value="1" name="qrUrlMode" ng-change="selectQRUrlMode(1)">&nbsp;Custom Url' +
                '<div style="margin-left:2em;">' +
                '<input type="text" ng-model="stripContent.Url" id="defaultmatting" max="200" ng-disabled="qrUrlMode != 1" ' +
                'ng-change="updateQRUrl()" placeholder="URL" style="font-size: 18px;width: 20em; margin-bottom: 5px;">' +
                '<input type="text" ng-model="stripContent.Title" id="updateQRTitle" max="200" ng-disabled="qrUrlMode != 1" ' +
                'ng-change="updateQRTitle()" placeholder="Title" style="font-size: 18px;width: 20em;">' +
                '</div>' +
                '</span>' +
                '</div>' +
                '<span class="checkbox">' +
                '<input type="radio" ng-model="stripContent.Type" ng-value="AW_STRIP_CONTENT.AW_CREDIT_AND_QR" name="stripContent" ng-change="selectStripContent(AW_STRIP_CONTENT.AW_CREDIT_AND_QR)">&nbsp;{{\'AW_STRIP_CONTENT.AW_CREDIT_AND_QR\'|translate}}' +
                '</span>' +
                '</div>' +
                '</div>' +
                '<pre style="display:none">&nbsp;&nbsp;DEBUG: {{stripMode | json}}</pre>' +
                '<div ng-if="showSeparator" class="full-hl"></div>',
        };
    },
]);
