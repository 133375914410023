'use strict';

angular.module('admin-area').controller('AdminAreaUserPlansSelectedController', [
    '$rootScope',
    '$scope',
    '$filter',
    '$state',
    'currentUserPlan',
    'planDataAndPath',
    'FormValidationService',
    'Messages',
    'UserPlansDataService',
    function (
        $rootScope,
        $scope,
        $filter,
        $state,
        currentUserPlan,
        planDataAndPath,
        FormValidationService,
        Messages,
        UserPlansDataService
    ) {
        var self = this;
        this.currentUserPlan = $scope.currentUserPlan = currentUserPlan;
        this.currentPlanData = $scope.currentPlanData = planDataAndPath.plan;
        this.currentUserPlanPath = $scope.currentUserPlanPath = planDataAndPath.path;

        this.fromState = 'admin-area';

        this.init = function () {};
    },
]);
