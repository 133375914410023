'use strict';

angular.module('core').filter('upperFirstLetter', [
    function () {
        function upperFirstLetter(word) {
            return word.substring(0, 1).toUpperCase() + word.substring(1);
        }

        return function (input, allFirstLetters) {
            var result = input;

            if (allFirstLetters) {
                result = input.replace(/\w\S*/g, function (word) {
                    return upperFirstLetter(word);
                });
            } else {
                result = upperFirstLetter(input);
            }

            return result;
        };
    },
]);
