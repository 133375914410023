'use strict';

angular.module('admin-area').controller('AdminAreaUserPlansController', [
    '$rootScope',
    '$scope',
    '$filter',
    '$q',
    'plans',
    'UserPlansDataService',
    'Messages',
    '_',
    '$state',
    function ($rootScope, $scope, $filter, $q, plans, UserPlansDataService, Messages, _, $state) {
        var self = this;
        this.UserPlansDataService = UserPlansDataService;
        this.plans = plans;
        this.fromState = 'admin-area';
        this.formattedToday = $filter('date')(new Date(), 'ddMMyyyy-HHmmss');

        $scope.getMaxHeightOfSideAndMainElements = function () {
            var sideBarHeight = angular.element(document.getElementById('userPlans-sidebar'))[0].offsetHeight;
            var mainAreaHeight = angular.element(document.getElementById('userPlans-main-area'))[0].offsetHeight;
            var maxHeight = Math.max(sideBarHeight, mainAreaHeight, 100);
            maxHeight += 'px';

            return { height: maxHeight };
        };

        function goToUserPlan(plans, id) {
            var planId = plans && !id ? plans[0].uid : id;
            $state.go(self.fromState + '.user-plans.plan', { planId: planId });
        }

        this.gotoUserMapping = function () {
            $state.go(self.fromState + '.user-plans.mapping');
        };

        this.duplicateUserPlan = function (planId) {
            var messageParams = {};
            messageParams.message = 'You are about to duplicate this User-Plan';
            messageParams.title = 'Duplicate User-Plan';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Duplicate';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.UserPlansDataService.duplicateUserPlan(planId).then(
                        function (res) {
                            var messageParams = {};
                            messageParams.message = 'New User  Plan was created';
                            $scope.submitWasClicked = false;
                            Messages.openMessage($scope, messageParams);
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'User-Plan creation failed.\nInfo:' + error;
                            messageParams.title = 'Failed To Create new User-Plan';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        this.deleteUserPlan = function (planId) {
            var messageParams = {};
            messageParams.message = 'You are about to delete this User-Plan and all its data!';
            messageParams.title = 'Delete User-Plan';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.UserPlansDataService.deleteUserPlan(planId).then(
                        function () {
                            var messageParams = {};
                            messageParams.message = 'User-Plan was deleted';
                            $scope.submitWasClicked = false;
                            Messages.openMessage($scope, messageParams);
                            $state.go('admin-area.user-plans', { reload: true });
                        },
                        function (error) {
                            var info = error || 'Internal Error';

                            var messageParams = {};
                            messageParams.message = 'User-Plan deletion failed.\n More Info: ' + info;
                            messageParams.title = 'Failed To Delete User-Plan';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        $rootScope.$on('HomeDataService::refresh_all_userPlans', function ($event, plans) {
            self.plans = plans;
        });

        this.init = function () {
            if ($state.current.name === self.fromState + '.user-plans') {
                goToUserPlan(plans);
            }
        };

        // Export Report to CSV
        this.getHeaderForSubscriptionsCSV = function () {
            var array = [];
            array.push(
                'User Id',
                'Provider',
                'Plan',
                'Payment Cycle',
                'Coupon Code',
                'Event Type',
                'Event Source',
                'Auto Renew Downgrade Plan',
                'Will Auto Renew',
                'Will Auto renew Change Date',
                'Trial Start',
                'Trial End',
                'Start',
                'End',
                'Active',
                'Latest',
                'Modified',
                'Created'
            );
            return array;
        };

        function incrementAlphabetChar(char) {
            // Taken from here: http://stackoverflow.com/questions/30685916/increment-a-string-with-letters
            var result = (parseInt(char, 36) + 1).toString(36).replace(/0/g, 'a');

            if (result[0] === '1') {
                result = 'a' + result.slice(1);
            }

            return result;
        }

        this.exportAllSubscriptionsCSV = function () {
            var deferred = $q.defer();
            var messageParams = {};
            messageParams.message =
                'You are about to export all users subscriptions. Are you sure you want to continue?!';
            messageParams.title = 'Export All Subscriptions';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Export';
            messageParams.okClass = 'info';
            $scope.submitWasClicked = true;
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    self.UserPlansDataService.getSubscriptionPlansHistory().then(
                        function (data) {
                            deferred.resolve(self.exportSubscriptionsCSV(data.UserSubscriptionPlan));
                        },
                        function (err) {
                            console.log('failed to get data', err);
                            deferred.reject('failed to get data. ' + err);
                        }
                    );
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        this.exportSubscriptionsCSV = function (subscriptions) {
            var array = [];

            if (subscriptions) {
                subscriptions.forEach(function (row, rowIndex) {
                    var line = {};
                    var index = 'a';

                    // line[index] = rowIndex + 1;
                    // index = incrementAlphabetChar(index);
                    line[index] = row.user_id;
                    index = incrementAlphabetChar(index);
                    line[index] = row.provider_display_name;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user_plan_display_name;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user_plan_payment_cycle;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user_plan_coupon_code;
                    index = incrementAlphabetChar(index);
                    line[index] = row.event_type;
                    index = incrementAlphabetChar(index);
                    line[index] = row.event_source;
                    index = incrementAlphabetChar(index);
                    line[index] = row.auto_renew_downgrade_user_plan_display_name || 'N/A';
                    index = incrementAlphabetChar(index);
                    line[index] = row.will_auto_renew === '1' ? 'True' : 'False';
                    index = incrementAlphabetChar(index);
                    var formattedDate = null;
                    if (row.will_auto_renew_change_date) {
                        formattedDate = $filter('mysqlDateToISOString')(row.will_auto_renew_change_date);
                        formattedDate = $filter('date')(formattedDate, 'dd/MM/yyyy HH:mm');
                    }
                    line[index] = formattedDate || 'N/A';
                    index = incrementAlphabetChar(index);
                    formattedDate = null;
                    if (row.trial_start) {
                        formattedDate = $filter('mysqlDateToISOString')(row.trial_start);
                        formattedDate = $filter('date')(formattedDate, 'dd/MM/yyyy HH:mm');
                    }
                    line[index] = formattedDate || 'N/A';
                    index = incrementAlphabetChar(index);
                    formattedDate = null;
                    if (row.trial_end) {
                        formattedDate = $filter('mysqlDateToISOString')(row.trial_end);
                        formattedDate = $filter('date')(formattedDate, 'dd/MM/yyyy HH:mm');
                    }
                    line[index] = formattedDate || 'N/A';
                    index = incrementAlphabetChar(index);
                    formattedDate = null;
                    if (row.start) {
                        formattedDate = $filter('mysqlDateToISOString')(row.start);
                        formattedDate = $filter('date')(formattedDate, 'dd/MM/yyyy HH:mm');
                    }
                    line[index] = formattedDate || 'N/A';
                    index = incrementAlphabetChar(index);
                    formattedDate = null;
                    if (row.end) {
                        formattedDate = $filter('mysqlDateToISOString')(row.end);
                        formattedDate = $filter('date')(formattedDate, 'dd/MM/yyyy HH:mm');
                    }
                    line[index] = formattedDate || 'N/A';
                    index = incrementAlphabetChar(index);
                    line[index] = row.active === '1' ? 'True' : 'False';
                    index = incrementAlphabetChar(index);
                    line[index] = row.latest === '1' ? 'True' : 'False';
                    index = incrementAlphabetChar(index);
                    formattedDate = null;
                    if (row.modified) {
                        formattedDate = $filter('mysqlDateToISOString')(row.modified);
                        formattedDate = $filter('date')(formattedDate, 'dd/MM/yyyy HH:mm');
                    }
                    line[index] = formattedDate || 'N/A';
                    index = incrementAlphabetChar(index);
                    formattedDate = null;
                    if (row.created) {
                        formattedDate = $filter('mysqlDateToISOString')(row.created);
                        formattedDate = $filter('date')(formattedDate, 'dd/MM/yyyy HH:mm');
                    }
                    line[index] = formattedDate || 'N/A';

                    array.push(line);
                });
            }

            return array;
        };
    },
]);
