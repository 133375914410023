'use strict';

// Setting up route
angular.module('users').config([
    '$stateProvider',
    function ($stateProvider) {
        // Users state routing
        $stateProvider
            //            state('users', {
            //               templateUrl: 'modules/users/views/users.html'
            //           }).
            .state('login', {
                url: '/login?deviceId&secret&shortSecret&deviceName',
                templateUrl: 'modules/users/views/login-device.client.view.html',
                //controller: 'LoginController'
                //              data: {
                //                  usersState: 'login'
                //              }
            });
        //            state('join', {
        //                url: '/join',
        //                templateUrl: 'modules/users/views/join.client.view.html'
        ////                data: {usersState: 'join'}
        //            });
    },
]);
