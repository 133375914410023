'use strict';

//Authentication service for user variables
angular.module('core').service('CategoriesService', [
    '$q',
    '$rootScope',
    '$log',
    '$http',
    function ($q, $rootScope, $log, $http) {
        var self = this;
        this.categories = [];
        this.isReady = false;
        this.promise = new Promise((resolve, reject) => setTimeout(resolve, 5000));

        this.init = () => {
            self.promise = $http.post('/categories/getAll').then(
                (res) => {
                    self.categories = res.data.data;
                    self.categories.sort(
                        (a, b) => b.featured - a.featured || a.market_order - b.market_order
                    );
                    self.isReady = true;
                },
                (err) => {
                    $log.debug('CategoriesService::init failed to load categories', err);
                }
            );
        };

        $rootScope.$on('ClearData', this.init);
        $rootScope.$on('InitServices', this.init);

        this.init();
    },
]);
