'use strict';

angular.module('discover').controller('DiscoverArtistsMainController', [
    '$log',
    '$scope',
    '$rootScope',
    '$timeout',
    '$state',
    '$stateParams',
    '$window',
    'User',
    'Messages',
    'DiscoverDataService',
    function (
        $log,
        $scope,
        $rootScope,
        $timeout,
        $state,
        $stateParams,
        $window,
        User,
        Messages,
        DiscoverDataService
    ) {
        $scope.followingInProgress = false;
        $scope.userId = User.getId();
        $scope.sidebarSelection = $stateParams.sidebarSelection;
        $scope.offset = 0;
        var initial = true;
        $scope.$emit('DiscoverArtistsMainController:artists_name_changed', $stateParams.sidebarSelection);

        function loadedMembers() {
            $scope.limit = 20;
            $scope.noMoreResults = false;
            $scope.loading = false;
            $scope.initItemsToLoad();
            $scope.lastScrollItemNumber = null;

            $timeout(function () {
                $scope.$emit('DiscoverArtistsMainController:artists_refreshed');
            }, 100);

            $timeout(function () {
                $scope.loaded = true;
            }, 2000);
        }

        function loadMembers() {
            var displayArtists = DiscoverDataService.getCachedDisplayArtists();
            if (displayArtists && displayArtists.artists && displayArtists.artists.length) {
                $scope.artists = displayArtists.artists;
                $scope.isAdmin = displayArtists.isAdmin;
                $scope.offset = $scope.artists.length;
                loadedMembers();
            } else {
                $scope.loading = true;
                DiscoverDataService.getArtistsToDisplay($stateParams.sidebarSelection).then(
                    function (value) {
                        DiscoverDataService.setCachedDisplayArtists(value);
                        $scope.artists = DiscoverDataService.getCachedDisplayArtists().artists;
                        $scope.isAdmin = DiscoverDataService.getCachedDisplayArtists().isAdmin;
                        initial = false;
                        loadedMembers();
                    },
                    function (reason) {
                        $log.debug('discover.artists.main::', {
                            state: $state,
                            stateParams: $stateParams,
                            reason: reason,
                        });
                        var messageParams = {};
                        messageParams.message = 'The URL is not valid, redirecting.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                            $rootScope.previousState('home');
                        });
                    }
                );
            }
        }

        $scope.init = function () {
            loadMembers();
        };

        $scope.loadMore = function (limit) {
            $scope.loading = true;
            var currentLimit = limit || $scope.limit;
            if (initial) {
                $scope.offset += currentLimit;
            }
            initial = false;
            DiscoverDataService.getArtistsToDisplay(
                $stateParams.sidebarSelection,
                currentLimit,
                $scope.offset
            ).then(function (value) {
                $scope.artists = $scope.artists.concat(value.artists);
                DiscoverDataService.setCachedDisplayArtists({
                    artists: $scope.artists,
                    isAdmin: value.isAdmin,
                });
                if (!value.length || value.length < currentLimit) {
                    $scope.noMoreResults = true;
                }
                $scope.loading = false;
                $scope.$emit('DiscoverArtistsMainController:artists_refreshed');
            });
        };

        function pageScrollEvent() {
            //TODO make a directive
            if (!$scope.loading) {
                $scope.loading = true;
                var windowHeight =
                    'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
                var body = document.body,
                    html = document.documentElement;
                var docHeight = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                );
                var windowBottom = windowHeight + window.pageYOffset;
                if (windowBottom >= docHeight - 800 && !$scope.noMoreResults) {
                    $scope.loadMore();
                } else {
                    $scope.loading = false;
                }
            }
        }
        angular.element($window).on('scroll', pageScrollEvent);

        $scope.$on('$destroy', function () {
            angular.element($window).off('scroll', pageScrollEvent);
        });
    },
]);
