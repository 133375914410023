'use strict';

angular.module('core').service('FormValidationService', [
    '$http',
    '$q',
    '$filter',
    '_',
    'User',
    '$rootScope',
    function ($http, $q, $filter, _, User, $rootScope) {
        var self = this;
        this.months = [
            { name: 'January', value: 0 },
            { name: 'February', value: 1 },
            { name: 'March', value: 2 },
            { name: 'April', value: 3 },
            { name: 'May', value: 4 },
            { name: 'June', value: 5 },
            { name: 'July', value: 6 },
            { name: 'August', value: 7 },
            { name: 'September', value: 8 },
            { name: 'October', value: 9 },
            { name: 'November', value: 10 },
            { name: 'December', value: 11 },
        ];
        this.years = [];
        this.titleCharLimit = 60;
        this.shortTitleCharLimit = 45;
        this.descripionCharLimit = 3000;
        this.eventformCharLimit = 9999;
        this.privateCommentsCharLimit = 500;
        this.inquierMessageCharLimit = 500;
        this.userAboutCharLimit = 2500;
        this.userShortbioCharLimit = 500;
        this.userEducationCharLimit = 1000;
        this.userExhibitionsCharLimit = 1000;
        this.userAwardsCharLimit = 1000;
        this.userContactInfoCharLimit = 1000;
        this.userRepresentationCharLimit = 1000;

        this.eventHostTypes = [
            { value: '1', name: 'Host', display: false },
            { value: '2', name: 'Co-Curator', display: true },
            { value: '3', name: 'Jury', display: true },
        ];

        this.supportedZipMimetypes = [
            'multipart/x-zip',
            'application/zip',
            'application/x-zip',
            'application/x-zip-compressed',
            'application/x-compressed',
            'application/octet-stream',
        ];

        this.supportedImageMimetypes = [
            'image/bmp',
            'image/gif',
            'image/j2k',
            'image/jp2',
            'image/jpeg',
            'image/jpf',
            'image/jpm',
            'image/jpx',
            'image/pjpeg',
            'image/png',
            'image/tiff',
            'image/tiff-fx',
            'image/webp',
        ];

        this.supportedImageExtensions = ['j2k'];

        this.supportedAudioMimetypes = ['audio/aac', 'audio/mpeg', 'audio/ogg', 'audio/wav', 'audio/webm'];

        this.suppoertedAudioExtensions = ['mp3', 'wav', 'aif', 'ogg', 'aac'];

        this.supportedVideoMimetypes = [
            'application/x-troff-msvideo',
            'video/avi',
            'video/msvideo',
            'video/x-msvideo',
            // mpg / mpeg
            'video/mpeg',
            // mpeg2
            'video/mpeg2',
            // wmv
            'video/x-ms-wmv',
            // mp4
            'video/mp4',
            // mov
            'video/quicktime',
            // flv
            'video/x-flv',
            // vob
            'video/dvd',
            'video/x-ms-vob',
            // mkv
            'video/x-matroska',
            'video/divxplus',
            'video/matroska',
            'video/mkv',
            // dat
            'application/octet-stream',
            'zz-application/zz-winassoc-dat',
        ];

        this.supportedVideoMimetypesForPreview = [
            // mpg / mpeg
            'video/mpeg',
            // mpeg2
            'video/mpeg2',
            // mp4
            'video/mp4',
            // mov
            'video/quicktime',
        ];

        this.supportedVideoExtensions = ['dat', 'mkv', 'm2ts', 'vob'];

        this.loanPriceLevels = [];
        this.loanPeriods = [];
        this.unavailableEmails = [];
        this.unavailableUsernames = [];
        this.unavailableUrls = [];
        this.countries = [
            'United States',
            'Aaland Islands',
            'Afghanistan',
            'Albania',
            'Algeria',
            'American Samoa',
            'Andorra',
            'Angola',
            'Anguilla',
            'Antarctica',
            'Antigua and Barbuda',
            'Argentina',
            'Armenia',
            'Aruba',
            'Australia',
            'Austria',
            'Azerbaijan',
            'Bahamas',
            'Bahrain',
            'Bangladesh',
            'Barbados',
            'Belarus',
            'Belgium',
            'Belize',
            'Benin',
            'Bermuda',
            'Bhutan',
            'Bolivia',
            'Bonaire, Sint Eustatius and Saba',
            'Bosnia and Herzegovina',
            'Botswana',
            'Bouvet Island',
            'Brazil',
            'British Indian Ocean Territory',
            'Brunei Darussalam',
            'Bulgaria',
            'Burkina Faso',
            'Burundi',
            'Cambodia',
            'Cameroon',
            'Canada',
            'Canary Islands',
            'Cape Verde',
            'Cayman Islands',
            'Central African Republic',
            'Chad',
            'Chile',
            'China',
            'Christmas Island',
            'Cocos (Keeling) Islands',
            'Colombia',
            'Comoros',
            'Congo',
            'Cook Islands',
            'Costa Rica',
            "Cote D'Ivoire",
            'Croatia',
            'Cuba',
            'Curacao',
            'Cyprus',
            'Czech Republic',
            'Democratic Republic of Congo',
            'Denmark',
            'Djibouti',
            'Dominica',
            'Dominican Republic',
            'East Timor',
            'Ecuador',
            'Egypt',
            'El Salvador',
            'Equatorial Guinea',
            'Eritrea',
            'Estonia',
            'Ethiopia',
            'Falkland Islands (Malvinas)',
            'Faroe Islands',
            'Fiji',
            'Finland',
            'France',
            'French Guiana',
            'French Polynesia',
            'French Southern Territories',
            'Gabon',
            'Gambia',
            'Georgia',
            'Germany',
            'Ghana',
            'Gibraltar',
            'Greece',
            'Greenland',
            'Grenada',
            'Guadeloupe',
            'Guam',
            'Guatemala',
            'Guernsey',
            'Guinea',
            'Guinea-Bissau',
            'Guyana',
            'Haiti',
            'Heard and Mc Donald Islands',
            'Honduras',
            'Hong Kong',
            'Hungary',
            'Iceland',
            'India',
            'Indonesia',
            'Iran (Islamic Republic of)',
            'Iraq',
            'Ireland',
            'Israel',
            'Italy',
            'Jamaica',
            'Japan',
            'Jersey',
            'Jordan',
            'Kazakhstan',
            'Kenya',
            'Kiribati',
            'Korea, Republic of',
            'Kuwait',
            'Kyrgyzstan',
            "Lao People's Democratic Republic",
            'Latvia',
            'Lebanon',
            'Lesotho',
            'Liberia',
            'Libyan Arab Jamahiriya',
            'Liechtenstein',
            'Lithuania',
            'Luxembourg',
            'Macau',
            'Macedonia',
            'Madagascar',
            'Malawi',
            'Malaysia',
            'Maldives',
            'Mali',
            'Malta',
            'Marshall Islands',
            'Martinique',
            'Mauritania',
            'Mauritius',
            'Mayotte',
            'Mexico',
            'Micronesia, Federated States of',
            'Moldova, Republic of',
            'Monaco',
            'Mongolia',
            'Montenegro',
            'Montserrat',
            'Morocco',
            'Mozambique',
            'Myanmar',
            'Namibia',
            'Nauru',
            'Nepal',
            'Netherlands',
            'Netherlands Antilles',
            'New Caledonia',
            'New Zealand',
            'Nicaragua',
            'Niger',
            'Nigeria',
            'Niue',
            'Norfolk Island',
            'North Korea',
            'Northern Mariana Islands',
            'Norway',
            'Oman',
            'Pakistan',
            'Palau',
            'Palestinian Territory',
            'Panama',
            'Papua New Guinea',
            'Paraguay',
            'Peru',
            'Philippines',
            'Pitcairn',
            'Poland',
            'Portugal',
            'Puerto Rico',
            'Qatar',
            'Reunion',
            'Romania',
            'Russian Federation',
            'Rwanda',
            'Saint Kitts and Nevis',
            'Saint Lucia',
            'Saint Vincent and the Grenadines',
            'Samoa',
            'San Marino',
            'Sao Tome and Principe',
            'Saudi Arabia',
            'Senegal',
            'Serbia',
            'Seychelles',
            'Sierra Leone',
            'Singapore',
            'Slovak Republic',
            'Slovenia',
            'Solomon Islands',
            'Somalia',
            'South Africa',
            'South Georgia &amp; South Sandwich Islands',
            'South Sudan',
            'Spain',
            'Sri Lanka',
            'St. Barthelemy',
            'St. Helena',
            'St. Martin (French part)',
            'St. Pierre and Miquelon',
            'Sudan',
            'Suriname',
            'Svalbard and Jan Mayen Islands',
            'Swaziland',
            'Sweden',
            'Switzerland',
            'Syrian Arab Republic',
            'Taiwan',
            'Tajikistan',
            'Tanzania, United Republic of',
            'Thailand',
            'Togo',
            'Tokelau',
            'Tonga',
            'Trinidad and Tobago',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Turks and Caicos Islands',
            'Tuvalu',
            'Uganda',
            'Ukraine',
            'United Arab Emirates',
            'United Kingdom',
            'United States',
            'United States Minor Outlying Islands',
            'Uruguay',
            'Uzbekistan',
            'Vanuatu',
            'Vatican City State (Holy See)',
            'Venezuela',
            'Viet Nam',
            'Virgin Islands (British)',
            'Virgin Islands (U.S.)',
            'Wallis and Futuna Islands',
            'Western Sahara',
            'Yemen',
            'Zambia',
            'Zimbabwe',
        ];
        this.countryListAllIsoData = [
            { code: 'AF', name: 'Afghanistan' },
            { code: 'AL', name: 'Albania' },
            { code: 'DZ', name: 'Algeria' },
            { code: 'AS', name: 'American Samoa' },
            { code: 'AD', name: 'Andorra' },
            { code: 'AO', name: 'Angola' },
            { code: 'AI', name: 'Anguilla' },
            { code: 'AQ', name: 'Antarctica' },
            { code: 'AG', name: 'Antigua and Barbuda' },
            { code: 'AR', name: 'Argentina' },
            { code: 'AM', name: 'Armenia' },
            { code: 'AW', name: 'Aruba' },
            { code: 'AU', name: 'Australia' },
            { code: 'AT', name: 'Austria' },
            { code: 'AZ', name: 'Azerbaijan' },
            { code: 'BS', name: 'Bahamas (the)' },
            { code: 'BH', name: 'Bahrain' },
            { code: 'BD', name: 'Bangladesh' },
            { code: 'BB', name: 'Barbados' },
            { code: 'BY', name: 'Belarus' },
            { code: 'BE', name: 'Belgium' },
            { code: 'BZ', name: 'Belize' },
            { code: 'BJ', name: 'Benin' },
            { code: 'BM', name: 'Bermuda' },
            { code: 'BT', name: 'Bhutan' },
            { code: 'BO', name: 'Bolivia (Plurinational State of)' },
            { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
            { code: 'BA', name: 'Bosnia and Herzegovina' },
            { code: 'BW', name: 'Botswana' },
            { code: 'BV', name: 'Bouvet Island' },
            { code: 'BR', name: 'Brazil' },
            { code: 'IO', name: 'British Indian Ocean Territory (the)' },
            { code: 'BN', name: 'Brunei Darussalam' },
            { code: 'BG', name: 'Bulgaria' },
            { code: 'BF', name: 'Burkina Faso' },
            { code: 'BI', name: 'Burundi' },
            { code: 'CV', name: 'Cabo Verde' },
            { code: 'KH', name: 'Cambodia' },
            { code: 'CM', name: 'Cameroon' },
            { code: 'CA', name: 'Canada' },
            { code: 'KY', name: 'Cayman Islands (the)' },
            { code: 'CF', name: 'Central African Republic (the)' },
            { code: 'TD', name: 'Chad' },
            { code: 'CL', name: 'Chile' },
            { code: 'CN', name: 'China' },
            { code: 'CX', name: 'Christmas Island' },
            { code: 'CC', name: 'Cocos (Keeling) Islands (the)' },
            { code: 'CO', name: 'Colombia' },
            { code: 'KM', name: 'Comoros (the)' },
            { code: 'CD', name: 'Congo (the Democratic Republic of the)' },
            { code: 'CG', name: 'Congo (the)' },
            { code: 'CK', name: 'Cook Islands (the)' },
            { code: 'CR', name: 'Costa Rica' },
            { code: 'HR', name: 'Croatia' },
            { code: 'CU', name: 'Cuba' },
            { code: 'CW', name: 'Curaçao' },
            { code: 'CY', name: 'Cyprus' },
            { code: 'CZ', name: 'Czechia' },
            { code: 'CI', name: "Côte d'Ivoire" },
            { code: 'DK', name: 'Denmark' },
            { code: 'DJ', name: 'Djibouti' },
            { code: 'DM', name: 'Dominica' },
            { code: 'DO', name: 'Dominican Republic (the)' },
            { code: 'EC', name: 'Ecuador' },
            { code: 'EG', name: 'Egypt' },
            { code: 'SV', name: 'El Salvador' },
            { code: 'GQ', name: 'Equatorial Guinea' },
            { code: 'ER', name: 'Eritrea' },
            { code: 'EE', name: 'Estonia' },
            { code: 'SZ', name: 'Eswatini' },
            { code: 'ET', name: 'Ethiopia' },
            { code: 'FK', name: 'Falkland Islands (the) [Malvinas]' },
            { code: 'FO', name: 'Faroe Islands (the)' },
            { code: 'FJ', name: 'Fiji' },
            { code: 'FI', name: 'Finland' },
            { code: 'FR', name: 'France' },
            { code: 'GF', name: 'French Guiana' },
            { code: 'PF', name: 'French Polynesia' },
            { code: 'TF', name: 'French Southern Territories (the)' },
            { code: 'GA', name: 'Gabon' },
            { code: 'GM', name: 'Gambia (the)' },
            { code: 'GE', name: 'Georgia' },
            { code: 'DE', name: 'Germany' },
            { code: 'GH', name: 'Ghana' },
            { code: 'GI', name: 'Gibraltar' },
            { code: 'GR', name: 'Greece' },
            { code: 'GL', name: 'Greenland' },
            { code: 'GD', name: 'Grenada' },
            { code: 'GP', name: 'Guadeloupe' },
            { code: 'GU', name: 'Guam' },
            { code: 'GT', name: 'Guatemala' },
            { code: 'GG', name: 'Guernsey' },
            { code: 'GN', name: 'Guinea' },
            { code: 'GW', name: 'Guinea-Bissau' },
            { code: 'GY', name: 'Guyana' },
            { code: 'HT', name: 'Haiti' },
            { code: 'HM', name: 'Heard Island and McDonald Islands' },
            { code: 'VA', name: 'Holy See (the)' },
            { code: 'HN', name: 'Honduras' },
            { code: 'HK', name: 'Hong Kong' },
            { code: 'HU', name: 'Hungary' },
            { code: 'IS', name: 'Iceland' },
            { code: 'IN', name: 'India' },
            { code: 'ID', name: 'Indonesia' },
            { code: 'IR', name: 'Iran (Islamic Republic of)' },
            { code: 'IQ', name: 'Iraq' },
            { code: 'IE', name: 'Ireland' },
            { code: 'IM', name: 'Isle of Man' },
            { code: 'IL', name: 'Israel' },
            { code: 'IT', name: 'Italy' },
            { code: 'JM', name: 'Jamaica' },
            { code: 'JP', name: 'Japan' },
            { code: 'JE', name: 'Jersey' },
            { code: 'JO', name: 'Jordan' },
            { code: 'KZ', name: 'Kazakhstan' },
            { code: 'KE', name: 'Kenya' },
            { code: 'KI', name: 'Kiribati' },
            { code: 'KP', name: "Korea (the Democratic People's Republic of)" },
            { code: 'KR', name: 'Korea (the Republic of)' },
            { code: 'KW', name: 'Kuwait' },
            { code: 'KG', name: 'Kyrgyzstan' },
            { code: 'LA', name: "Lao People's Democratic Republic (the)" },
            { code: 'LV', name: 'Latvia' },
            { code: 'LB', name: 'Lebanon' },
            { code: 'LS', name: 'Lesotho' },
            { code: 'LR', name: 'Liberia' },
            { code: 'LY', name: 'Libya' },
            { code: 'LI', name: 'Liechtenstein' },
            { code: 'LT', name: 'Lithuania' },
            { code: 'LU', name: 'Luxembourg' },
            { code: 'MO', name: 'Macao' },
            { code: 'MG', name: 'Madagascar' },
            { code: 'MW', name: 'Malawi' },
            { code: 'MY', name: 'Malaysia' },
            { code: 'MV', name: 'Maldives' },
            { code: 'ML', name: 'Mali' },
            { code: 'MT', name: 'Malta' },
            { code: 'MH', name: 'Marshall Islands (the)' },
            { code: 'MQ', name: 'Martinique' },
            { code: 'MR', name: 'Mauritania' },
            { code: 'MU', name: 'Mauritius' },
            { code: 'YT', name: 'Mayotte' },
            { code: 'MX', name: 'Mexico' },
            { code: 'FM', name: 'Micronesia (Federated States of)' },
            { code: 'MD', name: 'Moldova (the Republic of)' },
            { code: 'MC', name: 'Monaco' },
            { code: 'MN', name: 'Mongolia' },
            { code: 'ME', name: 'Montenegro' },
            { code: 'MS', name: 'Montserrat' },
            { code: 'MA', name: 'Morocco' },
            { code: 'MZ', name: 'Mozambique' },
            { code: 'MM', name: 'Myanmar' },
            { code: 'NA', name: 'Namibia' },
            { code: 'NR', name: 'Nauru' },
            { code: 'NP', name: 'Nepal' },
            { code: 'NL', name: 'Netherlands (the)' },
            { code: 'NC', name: 'New Caledonia' },
            { code: 'NZ', name: 'New Zealand' },
            { code: 'NI', name: 'Nicaragua' },
            { code: 'NE', name: 'Niger (the)' },
            { code: 'NG', name: 'Nigeria' },
            { code: 'NU', name: 'Niue' },
            { code: 'NF', name: 'Norfolk Island' },
            { code: 'MP', name: 'Northern Mariana Islands (the)' },
            { code: 'NO', name: 'Norway' },
            { code: 'OM', name: 'Oman' },
            { code: 'PK', name: 'Pakistan' },
            { code: 'PW', name: 'Palau' },
            { code: 'PS', name: 'Palestine, State of' },
            { code: 'PA', name: 'Panama' },
            { code: 'PG', name: 'Papua New Guinea' },
            { code: 'PY', name: 'Paraguay' },
            { code: 'PE', name: 'Peru' },
            { code: 'PH', name: 'Philippines (the)' },
            { code: 'PN', name: 'Pitcairn' },
            { code: 'PL', name: 'Poland' },
            { code: 'PT', name: 'Portugal' },
            { code: 'PR', name: 'Puerto Rico' },
            { code: 'QA', name: 'Qatar' },
            { code: 'MK', name: 'Republic of North Macedonia' },
            { code: 'RO', name: 'Romania' },
            { code: 'RU', name: 'Russian Federation (the)' },
            { code: 'RW', name: 'Rwanda' },
            { code: 'RE', name: 'Réunion' },
            { code: 'BL', name: 'Saint Barthélemy' },
            { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
            { code: 'KN', name: 'Saint Kitts and Nevis' },
            { code: 'LC', name: 'Saint Lucia' },
            { code: 'MF', name: 'Saint Martin (French part)' },
            { code: 'PM', name: 'Saint Pierre and Miquelon' },
            { code: 'VC', name: 'Saint Vincent and the Grenadines' },
            { code: 'WS', name: 'Samoa' },
            { code: 'SM', name: 'San Marino' },
            { code: 'ST', name: 'Sao Tome and Principe' },
            { code: 'SA', name: 'Saudi Arabia' },
            { code: 'SN', name: 'Senegal' },
            { code: 'RS', name: 'Serbia' },
            { code: 'SC', name: 'Seychelles' },
            { code: 'SL', name: 'Sierra Leone' },
            { code: 'SG', name: 'Singapore' },
            { code: 'SX', name: 'Sint Maarten (Dutch part)' },
            { code: 'SK', name: 'Slovakia' },
            { code: 'SI', name: 'Slovenia' },
            { code: 'SB', name: 'Solomon Islands' },
            { code: 'SO', name: 'Somalia' },
            { code: 'ZA', name: 'South Africa' },
            { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
            { code: 'SS', name: 'South Sudan' },
            { code: 'ES', name: 'Spain' },
            { code: 'LK', name: 'Sri Lanka' },
            { code: 'SD', name: 'Sudan (the)' },
            { code: 'SR', name: 'Suriname' },
            { code: 'SJ', name: 'Svalbard and Jan Mayen' },
            { code: 'SE', name: 'Sweden' },
            { code: 'CH', name: 'Switzerland' },
            { code: 'SY', name: 'Syrian Arab Republic' },
            { code: 'TW', name: 'Taiwan' },
            { code: 'TJ', name: 'Tajikistan' },
            { code: 'TZ', name: 'Tanzania, United Republic of' },
            { code: 'TH', name: 'Thailand' },
            { code: 'TL', name: 'Timor-Leste' },
            { code: 'TG', name: 'Togo' },
            { code: 'TK', name: 'Tokelau' },
            { code: 'TO', name: 'Tonga' },
            { code: 'TT', name: 'Trinidad and Tobago' },
            { code: 'TN', name: 'Tunisia' },
            { code: 'TR', name: 'Turkey' },
            { code: 'TM', name: 'Turkmenistan' },
            { code: 'TC', name: 'Turks and Caicos Islands (the)' },
            { code: 'TV', name: 'Tuvalu' },
            { code: 'UG', name: 'Uganda' },
            { code: 'UA', name: 'Ukraine' },
            { code: 'AE', name: 'United Arab Emirates (the)' },
            { code: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland (the)' },
            { code: 'UM', name: 'United States Minor Outlying Islands (the)' },
            { code: 'US', name: 'United States of America (the)' },
            { code: 'UY', name: 'Uruguay' },
            { code: 'UZ', name: 'Uzbekistan' },
            { code: 'VU', name: 'Vanuatu' },
            { code: 'VE', name: 'Venezuela (Bolivarian Republic of)' },
            { code: 'VN', name: 'Viet Nam' },
            { code: 'VG', name: 'Virgin Islands (British)' },
            { code: 'VI', name: 'Virgin Islands (U.S.)' },
            { code: 'WF', name: 'Wallis and Futuna' },
            { code: 'EH', name: 'Western Sahara' },
            { code: 'YE', name: 'Yemen' },
            { code: 'ZM', name: 'Zambia' },
            { code: 'ZW', name: 'Zimbabwe' },
            { code: 'AX', name: 'Åland Islands' },
        ];
        this.states = [
            'AL',
            'AK',
            'AZ',
            'AR',
            'CA',
            'CO',
            'CT',
            'DE',
            'FL',
            'GA',
            'HI',
            'ID',
            'IL',
            'IN',
            'IA',
            'KS',
            'KY',
            'LA',
            'ME',
            'MD',
            'MA',
            'MI',
            'MN',
            'MS',
            'MO',
            'MT',
            'NE',
            'NV',
            'NH',
            'NJ',
            'NM',
            'NY',
            'NC',
            'ND',
            'OH',
            'OK',
            'OR',
            'PA',
            'RI',
            'SC',
            'SD',
            'TN',
            'TX',
            'UT',
            'VT',
            'VA',
            'WA',
            'WV',
            'WI',
            'WY',
        ];
        this.organizationTypes = [
            { id: 'AC' },
            { id: 'AR' },
            { id: 'AE' },
            { id: 'GA' },
            { id: 'HO' },
            { id: 'IN' },
            { id: 'MU' },
            { id: 'BS' },
            { id: 'AS' },
            { id: 'OT' },
        ];
        this.organizationTypes.forEach(function (type) {
            type.name = $filter('translate')('CORE.ORG_TYPE_' + type.id);
        });
        this.curationTypes = [
            { id: 'CB' },
            { id: 'CURB' },
            { id: 'COCBY' },
            { id: 'SB' },
            { id: 'PB' },
            { id: 'COF' },
            { id: 'OT' },
        ];
        this.curationTypes.forEach(function (type) {
            type.name = $filter('translate')('CORE.CURATION_TYPE_' + type.id);
        });
        this.delayTillNextServerValidation = 200;
        this.blacklistUsername = [
            'home',
            'home.',
            'discover',
            'discover.',
            'server',
            'home-page',
            'devices',
            'player',
            'search',
            'login',
            'join',
            'add-device',
            'delete-account',
            'pair',
            'admin_area',
            'plans',
            'about',
            'feedback',
            'support',
            'artcasts',
            'artcast',
            'art',
            'manage',
            'buy',
            'events',
            'enrollToEvent',
            'service-worker',
            '.well-known',
            'site',
            'get',
            'zoom',
            'art',
            'welcome',
            'getart',
            'artonoled',
            'wordpress',
            'blog',
            'private',
            'landing',
            'campaign',
            'manage',
            'download',
            'upgrade',
            'downloads',
            'pair-tv',
            'mobile_upload',
            'mobile_upload_cloud',
            'conrad',
            'conradl',
            'conrad_bm',
            'cinr',
            'clby',
            'pro-tool-plans',
            'wallpaper',
            'artTV',
            'irvineqr',
            'tempoqr',
            'aloftchqr',
            'samsung-tv',
            'silverstein',
            'samsung',
        ];

        this.blacklistUrl = this.blacklistUsername;

        function setFormDirty(requiredItem) {
            // recursion that goes over all forms and sets their inputs to dirty to enable re-validation of them all.
            if (requiredItem.$setViewValue) {
                // required item is not a form
                if (requiredItem.$isEmpty()) {
                    requiredItem.$setViewValue(''); // set it again to enable dirty
                } else {
                    requiredItem.$setViewValue(requiredItem.$viewValue); // set it again to enable dirty
                }
                requiredItem.$setDirty(); // set it dirty

                return;
            }

            if (requiredItem.$error.required) {
                var i;

                for (i = 0; i < requiredItem.$error.required.length; i++) {
                    // requiredItem is a form
                    setFormDirty(requiredItem.$error.required[i]);
                }
            }

            if (requiredItem.$error.playerTypeMismatch) {
                var p;

                for (p = 0; p < requiredItem.$error.playerTypeMismatch.length; p++) {
                    // requiredItem is a form
                    setFormDirty(requiredItem.$error.playerTypeMismatch[p]);
                }
            }
        }

        this.interacted = function (form, field) {
            return form.$submitted || field.$dirty;
        };

        this.setupYearSelector = function () {
            self.years = Object.keys(new Array(new Date().getFullYear() + 1).fill())
                .reverse()
                .slice(0, 500);
        };

        function checkIfEmailIsAvailable(email) {
            var data = {
                email: email,
            };

            return $http.post('/users/isEmailAvailable', data);
        }

        this.checkIfEmailIsAvailable = checkIfEmailIsAvailable;

        this.onEmailChangeOrBlur = function (input, eventType) {
            var storageEmail = User.getEmail();

            if (storageEmail !== '' && input.$viewValue === storageEmail) {
                input.$setValidity('emailIsAvailable', true);
            } else if (self.unavailableEmails.indexOf(input.$viewValue) !== -1) {
                input.$setValidity('emailIsAvailable', false);
            } else {
                input.validating = true;
                checkIfEmailIsAvailable(input.$viewValue).then(function (res) {
                    if (res.data.available) {
                        input.$setValidity('emailIsAvailable', true);
                    } else {
                        input.$setValidity('emailIsAvailable', false);
                        self.unavailableEmails.push(input.$viewValue);
                    }
                    input.validating = false;
                });
            }
        };

        function checkIfUsernameIsAvailable(username) {
            var data = {
                username: username,
            };

            return $http.post('/users/isUsernameAvailable', data);
        }

        this.onUserChangeOrBlur = function (input, eventType) {
            var storageUsername = User.getUsername();

            if (storageUsername !== '' && input.$viewValue === storageUsername) {
                input.$setValidity('usernameIsAvailable', true);
            } else if (self.unavailableUsernames.indexOf(input.$viewValue) !== -1) {
                input.$setValidity('usernameIsAvailable', false);
            } else {
                var username = input.$viewValue ? input.$viewValue.toLowerCase() : '';

                if (username) {
                    input.validating = true;
                    checkIfUsernameIsAvailable(username).then(
                        function (res) {
                            var blacklisted =
                                self.blacklistUsername.indexOf(username) > -1 ||
                                username.indexOf('/') > -1 ||
                                username.indexOf("'") > -1 ||
                                username.indexOf('"') > -1 ||
                                username.indexOf('%') > -1 ||
                                username.indexOf('http') > -1 ||
                                username.indexOf('www') > -1;

                            if (res.data.available && !blacklisted) {
                                input.$setValidity('usernameIsAvailable', true);
                            } else {
                                input.$setValidity('usernameIsAvailable', false);
                                self.unavailableUsernames.push(input.$viewValue);
                            }
                            input.validating = false;
                        },
                        function (error) {
                            input.$setValidity('usernameIsAvailable', false);
                            input.validating = false;
                        }
                    );
                }
            }
        };

        function checkIfUrlIsAvailable(url) {
            var data = {
                url: url,
            };

            return $http.post('/users/isUrlAvailable', data);
        }

        this.onUrlChangeOrBlur = function (input, eventType, hasInteractivity = false) {
            var storageUrl = User.getUrl();

            if (storageUrl !== '' && input.$modelValue === storageUrl) {
                input.$setValidity('urlIsAvailable', true);
            } else if (self.unavailableUrls.indexOf(input.$modelValue) !== -1) {
                input.$setValidity('urlIsAvailable', false);
            } else {
                input.validating = true;
                var url = input.$modelValue.toLowerCase();
                checkIfUrlIsAvailable(url).then(
                    function (res) {
                        const suggestions = res.data.suggestions || [];
                        var blacklisted =
                            self.blacklistUrl.indexOf(url) > -1 ||
                            url.indexOf('/') > -1 ||
                            url.indexOf("'") > -1 ||
                            url.indexOf(' ') > -1 ||
                            url.indexOf('"') > -1 ||
                            url.indexOf('%') > -1 ||
                            url.indexOf('http') > -1 ||
                            url.indexOf('www') > -1;

                        if (res.data.available && !blacklisted) {
                            $rootScope.$emit('user.url.suggestions', []);
                            input.$setValidity('urlIsAvailable', true);
                        } else if (!hasInteractivity && suggestions[0]) {
                            $rootScope.$emit('user.url.suggestions', []);
                            input.$setViewValue(suggestions[0]);
                            input.$setValidity('urlIsAvailable', true);
                            input.$render();
                        } else {
                            input.$setValidity('urlIsAvailable', false);
                            $rootScope.$emit('user.url.suggestions', suggestions);
                        }
                        input.validating = false;
                    },
                    function (error) {
                        input.$setValidity('urlIsAvailable', false);
                        input.validating = false;
                    }
                );
            }
        };

        function checkIfInvitationCodeValid(code) {
            var data = {
                code: code,
            };

            return $http.post('/invitations/isInvitationCodeValid', data);
        }

        this.onInvitationCodeChange = function (input, eventType) {
            input.$setValidity('invitationCode', false); // Init the input to not valid

            input.validating = true;
            checkIfInvitationCodeValid(input.$viewValue).then(function (res) {
                if (res.data.data.valid) {
                    input.$setValidity('invitationCode', true);
                } else {
                    input.$setValidity('invitationCode', false);
                }
                input.validating = false;
            });
        };

        this.validatePassword = function (value) {
            var regexp = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20})/;

            return value && value.match(regexp) !== null;
        };

        this.forceConfirmPasswordValidation = function (inputFieldToMatch) {
            if (inputFieldToMatch.$viewValue) {
                inputFieldToMatch.$setViewValue(inputFieldToMatch.$modelValue);
            }
        };

        this.validateConfirmPassword = function (value, inputFieldToMatch) {
            return value && value === inputFieldToMatch.$modelValue;
        };

        this.notFutureDate = function (month, year) {
            month.validating = true;
            year.validating = true;
            if (!month.$viewValue) {
                month.$viewValue = 0;
            }

            if (month.$viewValue >= 0 && year.$viewValue) {
                var dateChosen = new Date(parseInt(year.$viewValue, 10), month.$viewValue - 1, 1).setDate(1);
                var today = Date.now();
                if (dateChosen > today) {
                    month.$setValidity('notFutureDate', false);
                    year.$setValidity('notFutureDate', false);
                } else {
                    month.$setValidity('notFutureDate', true);
                    year.$setValidity('notFutureDate', true);
                }
            } else {
                month.$setValidity('notFutureDate', true);
                year.$setValidity('notFutureDate', true);
            }
            month.validating = false;
            year.validating = false;
            return true;
        };

        this.settingRequiredForPlayerTypes = function (requiredTypes, currentPlayerType, value, field) {
            if (currentPlayerType && requiredTypes.indexOf(currentPlayerType) >= 0 && !value) {
                field.$setValidity('playerTypeMismatch', false);
                return true; //true = we have a problem !!!
            } else {
                field.$setValidity('playerTypeMismatch', true);
                return false;
            }
        };

        this.onFormSubmit = function (form, validCallback, $event) {
            $event ? $event.preventDefault() : null;
            form.submitDirty = true;
            if (form.$valid) {
                validCallback();
            } else {
                setFormDirty(form);
            }
        };

        function loadLoanPriceLevels() {
            return $http.post('/entityPresets/getLoanPriceLevels').then(function (res) {
                if (res.data.levels !== undefined) {
                    res.data.levels.forEach(function (levelO) {
                        levelO.priceNum = parseFloat(levelO.price);
                        levelO.displayName = levelO.name + ' (' + levelO.price + ')';
                    });
                }
                self.loanPriceLevels = res.data.levels;
            });
        }

        function loadLoanPeriods() {
            return $http.post('/entityPresets/getLoanPeriods').then(function (res) {
                if (res.data.periods !== undefined) {
                    var multipier = {
                        D: 1,
                        W: 7,
                        M: 30,
                    };

                    res.data.periods.forEach(function (periodO) {
                        periodO.orderIdx = parseFloat(periodO.period) * multipier[periodO.period_type];
                        var suffix = periodO.period > 1 ? 's' : '';
                        var periodStr = $filter('translate')('SHARE.LOAN_PERIOD_' + periodO.period_type);
                        periodO.displayName =
                            'Period ' + periodO.id + ' (' + periodO.period + ' ' + periodStr + suffix + ')';
                    });
                }
                self.loanPeriods = res.data.periods;
            });
        }

        this.initLoanPackages = function () {
            var deferred = $q.defer();
            // var isAdmin = User.isAdmin();
            var promises = [];

            // if (self.loanPriceLevels.length > 0 || self.loanPeriods.length > 0 || !isAdmin) {
            if (self.loanPriceLevels.length > 0 || self.loanPeriods.length > 0) {
                deferred.resolve();
            } else {
                promises.push(loadLoanPriceLevels());
                promises.push(loadLoanPeriods());
            }

            $q.all(promises).then(
                function (res) {
                    deferred.resolve(res);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.onSocialUrlChange = function (input, type) {
            var link = input.$viewValue;
            var regex =
                /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
            switch (type) {
                case 'twitter':
                    // regex = '(?:https?:)?\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\/(?P<username>[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)';
                    break;
                case 'facebook':
                    break;
                case 'instagram':
                    break;
                case 'tumblr':
                    break;
                case 'discord':
                    break;
                case 'linkedin':
                    break;
                default:
                    //Taken from: https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
                    regex =
                        /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
                // code block
            }
            if (link) {
                input.$setValidity('linkNotValid', regex.test(link));
            } else {
                input.$setValidity('linkNotValid', true);
                input.$setPristine();
            }
        };
    },
]);
