'use strict';

angular.module('home').controller('EditionManagementController', [
    '$rootScope',
    '$http',
    '$q',
    '$log',
    '_',
    'Messages',
    function ($rootScope, $http, $q, $log, _, Messages) {
        var self = this;
        this.Messages = Messages;
        this.statuses = [
            { key: true, view: 'Unavailable' },
            { key: false, view: 'Available' },
        ];
        this.itemsPerPage = 20;
        this.currentPage = 1;
        this.maxSize = 5;

        this.setPage = function (pageNo) {
            self.currentPage = pageNo;
        };

        // EDITION MANAGEMENT
        function updateEditionsStatuses(editions) {
            if (editions.length > 0) {
                editions.forEach(function (edition) {
                    edition.edition_number = parseInt(edition.edition_number);
                    if (edition.unavailable || edition.purchased_user_id) {
                        edition.unavailable = true;
                    } else {
                        edition.unavailable = false;
                    }
                    edition.numericPrice = edition.price ? parseInt(edition.price) : null;
                });
            }
        }

        function getEditions(artworkId) {
            var deferred = $q.defer();

            if (artworkId) {
                var data = {
                    artworkId,
                };

                $http
                    .post('/signedArtworks/getEditions', data)
                    .success(function (res) {
                        $log.debug('EditionManagementController::getEditions Success', res, artworkId);
                        self.artwork = res.data[0];
                        updateEditionsStatuses(self.artwork.editions);
                        // Pagination
                        self.totalItems = self.artwork.editions.length;
                        deferred.resolve(res);
                    })
                    .error(function (error) {
                        $log.debug('EditionManagementController::getEditions Failed', error, artworkId);
                        deferred.reject(error);
                    });
            } else {
                deferred.reject('EditionManagementController::getEditions artwork ID is empty');
            }

            return deferred.promise;
        }

        this.editionChanged = function (edition) {
            edition.unsaved = true;
            //self.orderByField = '';
            //self.reverseSort = '';
        };

        this.updateEdition = function (edition) {
            var data = {
                editionId: edition.id,
                unavailable: edition.unavailable,
                comment: edition.comment,
                blockchain_id: edition.blockchain_id,
                artworkId: self.artwork.id,
            };

            $http.post('/signedArtworks/updateEdition', data).then(
                function (res) {
                    $log.debug(
                        'EditionManagementController::updateEdition Success in i/signedArtworks/updateEdition',
                        res
                    );
                    edition.unsaved = false;
                    //var messageParams = {};
                    //messageParams.message = 'Edition was updated';
                    //Messages.openMessage($rootScope, messageParams);
                },
                function (error) {
                    $log.debug(
                        'EditionManagementController::updateEdition Error in /signedArtworks/updateEdition',
                        error
                    );
                    var messageParams = {};
                    messageParams.message = 'Update failed.';
                    if (error && error.data && error.data.message) {
                        messageParams.message += ' ' + error.data.message.text;
                    }
                    messageParams.title = 'Failed To Update Edition';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        this.init = function () {
            self.artwork = self.params && self.params.artwork ? self.params.artwork : {};
            self.artworkId = self.params && self.params.artwork ? self.params.artwork.id : '';
            // Get artwork publishes
            self.loadingEditions = true;
            if (self.artworkId) {
                getEditions(self.artworkId)
                    .then(
                        function (res) {
                            $log.debug(
                                'EditionManagementController::init successfully received editions',
                                res
                            );
                        },
                        function (error) {
                            $log.debug('EditionManagementController::init failed receiving editions', error);
                        }
                    )
                    .finally(function () {
                        self.loadingEditions = false;
                    });
            }
        };
    },
]);
