'use strict';

angular.module('core').directive('favorites', [
    function () {
        return {
            restrict: 'E',
            scope: {
                id: '=',
                type: '=',
                color: '=false',
                iconFirst: '=false',
                addText: '=?',
                removeText: '=?',
            },
            templateUrl: 'modules/core/views/favorites.client.view.html',
            controller: 'FavoritesController',
        };
    },
]);
