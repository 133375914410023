'use strict';

angular.module('devices').controller('DevicesSelectedContentController', [
    '$scope',
    '$log',
    '$q',
    'Messages',
    'DevicesDataService',
    '_',
    'PLAYER_TYPES',
    function ($scope, $log, $q, Messages, DevicesDataService, PLAYER_TYPES) {
        $scope.collapsibles = {};
        $scope.collapsibles.channelsIsOpen = true;
        $scope.collapsibles.collectionsIsOpen = true;

        $scope.init = function () {
            $scope.defaultTab.selection = 'content';
            $scope.submitWasClicked = false;
            $scope.orgPlayerType = $scope.calcPlayerType();
        };

        $scope.calcPlayerType = function () {
            if ($scope.deviceOrGroup === 'group') {
                return 'NA';
            }
            if (!$scope.settings.Info) {
                $scope.settings.Info = {};
            }
            $scope.settings.Info.playerType = $scope.settings.Info?.playerType
                ? $scope.settings.Info.playerType
                : PLAYER_TYPES.REGULAR;
            return $scope.settings.Info.playerType;
        };

        $scope.$watch('content.selectedContent', function (newValue, oldValue) {
            $log.debug(oldValue, newValue);

            if (oldValue) {
                if (newValue === 'specific') {
                    //Check all channels that are associated with schedules already
                    $scope.content.displayVault = false;
                    $scope.content.displayPortfolio = false;
                    $scope.content.displayWishlist = false;
                    $scope.addIsCheckToItemInListByIdList(
                        $scope.content.channels,
                        $scope.channelsFromSchedules,
                        'channel'
                    );
                } else if (newValue === 'all') {
                    $scope.content.displayVault = true;
                    $scope.content.displayPortfolio = true;
                    $scope.content.displayWishlist = true;
                } else if (newValue === 'distributed') {
                    $scope.content.displayVault = false;
                    $scope.content.displayPortfolio = false;
                    $scope.content.displayWishlist = false;
                }
            }
        });

        function callToUpdateContent() {
            var deferred = $q.defer();

            DevicesDataService.updateContent($scope.deviceOrGroup, $scope.selectedId, $scope.content).then(
                function (res) {
                    var messageParams = {};
                    messageParams.message = 'Content was updated successfully';
                    messageParams.title = 'Content Update';
                    Messages.openMessage($scope, messageParams);
                    $scope.updateAvailableChannelsForSchedules($scope.content);
                    deferred.resolve();
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message =
                        'Failed to update content. Please refresh the page and try again.';
                    messageParams.title = 'Content Update Failed';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    $scope.submitWasClicked = false;
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        function callToUpdateSettings() {
            var deferred = $q.defer();

            DevicesDataService.updateSettings($scope.deviceOrGroup, $scope.selectedId, $scope.settings).then(
                function (res) {
                    $scope.selected.name = $scope.settings.Info.name;
                    $scope.selected.location = $scope.settings.Info?.location;
                    $scope.selected.player_type = $scope.settings.Info?.playerType
                        ? $scope.settings.Info.playerType
                        : PLAYER_TYPES.REGULAR;
                    $scope.sortDevices();
                    $scope.updateChannelsFromSchedules($scope.settings.Schedule);
                    deferred.resolve();
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message =
                        'Failed to update settings.\nPlease refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    messageParams.title = 'Settings Update Failed';
                    Messages.openMessage($scope, messageParams);
                    $scope.submitWasClicked = false;
                    deferred.reject();
                }
            );

            return deferred.promise;
        }

        $scope.$on('content_fetched', function (event) {
            $scope.$emit('selected_chosen', $scope.selectedId, $scope.deviceOrGroup);
        });

        $scope.updateContent = function () {
            var messageParams = {};

            // Allow distribution only for either 1 channel or 1 collection
            var bMissingDistributedContent =
                $scope.content.selectedContent === 'distributed' &&
                $scope.content.distributeOptions.selectedChannelId === 0 &&
                $scope.content.distributeOptions.selectedCollectionId === 0;

            var bMissingSpecificContent =
                $scope.content.selectedContent === 'specific' &&
                $scope.content.selectedCollectionIds &&
                $scope.content.selectedCollectionIds.length === 0 &&
                $scope.content.selectedChannelIds &&
                $scope.content.selectedChannelIds.length === 0;

            if (bMissingDistributedContent || bMissingSpecificContent) {
                messageParams = {};

                messageParams.message = 'No content was selected. Please select at least one channel to save';
                messageParams.title = 'Empty Content';
                messageParams.size = 'lg';
                messageParams.disableAutoDismiss = true;
                messageParams.okText = 'OK';
                Messages.openMessage($scope, messageParams);
                return;
            }

            if ($scope.content.selectedContent === 'distributed') {
                var excludeChannels =
                    $scope.content.distributeOptions.list === 'channels'
                        ? [$scope.content.distributeOptions.selectedChannelId]
                        : [];
                if ($scope.disableScheduleWithChannels(true /*just check*/, excludeChannels) > 0) {
                    messageParams = {};
                    messageParams.message =
                        "You are about to remove channels configured in 'Schedule Preset'.";
                    messageParams.message += '\nThis change will effect some of your schedule records';
                    messageParams.message += '\nAre you sure you want to continue?';
                    messageParams.title = 'Change Content';
                    messageParams.size = 'lg';
                    messageParams.disableAutoDismiss = true;
                    messageParams.cancelText = 'Cancel';
                    messageParams.okText = 'Save';
                    Messages.openMessage($scope, messageParams).then(function (res) {
                        if (res === 'ok') {
                            $scope.disableScheduleWithChannels(false, excludeChannels);
                            $scope.submitWasClicked = true;
                            callToUpdateContent()
                                .then(function (res) {
                                    callToUpdateSettings();
                                })
                                .finally(function () {
                                    $scope.submitWasClicked = false;
                                });
                        }
                    });
                    return;
                }
            }

            var promise = null;
            if (['distributed', 'specific'].indexOf($scope.content.selectedContent) >= 0) {
                //TODO: Alert only if any content is removed....

                var destination = $scope.deviceOrGroup === 'group' ? 'these devices' : 'this device';
                var totem = $scope.deviceOrGroup === 'group' ? 'to them!' : '!';
                messageParams = {};
                messageParams.message =
                    'Please note, this action might delete from ' +
                    destination +
                    ' other artworks that were already downloaded ' +
                    totem;
                messageParams.message +=
                    '\nYou will then need to re-download these artworks in order to play them on the device.';
                messageParams.message += '\nAre you sure you want to continue?';
                messageParams.title = 'Change Content';
                messageParams.size = 'lg';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Continue';
                promise = Messages.openMessage($scope, messageParams);
            } else {
                var df = $q.defer();
                promise = df.promise;
                df.resolve('ok');
            }

            promise.then(function (res) {
                if (res === 'ok') {
                    $scope.submitWasClicked = true;

                    var p2 = callToUpdateContent();
                    //check if we have to update playerType.
                    if ($scope.orgPlayerType !== $scope.calcPlayerType()) {
                        p2.then(function (res) {
                            callToUpdateSettings();
                        });
                    }

                    p2.finally(function () {
                        $scope.submitWasClicked = false;
                    });
                }
            });
        };
    },
]);
