'use strict';

angular.module('search').controller('SearchController', [
    '$scope',
    'User',
    'Search',
    '$location',
    function ($scope, User, Search, $location) {
        $scope.init = function () {
            $scope.query = '';
        };
        $scope.Search = Search;

        $scope.$on('SearchMainController:notify_query', function (event) {
            $scope.query = $location.search().query;
        });

        $scope.getCounter = function (type) {
            let hasMoreSuffix = '';

            if (type === 'all') {
                const countersValues = Object.values(Search.searchCounters);
                const allCounters = countersValues.reduce((acc, i) => acc + i, 0) || '';

                hasMoreSuffix = Math.min(...Object.values(Search.noMoreResults)) ? '+' : '';

                return countersValues.includes(allCounters) ? '' : allCounters + hasMoreSuffix;
            }

            hasMoreSuffix = Search.noMoreResults[type] ? '' : '+';

            return (Search.searchCounters[type] || '') + ((Search.searchCounters[type] && hasMoreSuffix) || '');
        };
    },
]);
