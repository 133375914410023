//English
translationsEN.CORE = {};

translationsEN.CORE.JOIN = 'Join';
translationsEN.CORE.LOGIN = 'Login';
translationsEN.CORE.HOW_IT_WORKS = 'HOW IT WORKS?';
translationsEN.CORE.REQUEST_INVITATION = 'Request Invitation';
translationsEN.CORE.PLAYER = 'PLAYER';
translationsEN.CORE.HOME = 'Home';
translationsEN.CORE.DISCOVER = 'Discover';
translationsEN.CORE.ADMIN_AREA = 'Admin Area';
translationsEN.CORE.MY_NIIO = 'MY NIIO';
translationsEN.CORE.MARKET = 'MARKET';
translationsEN.CORE.PREMIUM = 'PREMIUM';
translationsEN.CORE.DOWNLOAD_PLAYER = 'Download Player';

translationsEN.CORE.CLOSE = 'Close';
translationsEN.CORE.NAME = 'Name';
translationsEN.CORE.EMAIL = 'Email';
translationsEN.CORE.PASSWORD = 'Password';
translationsEN.CORE.FEEDBACK = 'Feedback';
translationsEN.CORE.SEND = 'Send';
translationsEN.CORE.SEARCH = 'Search';
translationsEN.CORE.ASK = 'Ask';
//translationsEN.CORE.LOGIN = 'Login';
translationsEN.CORE.SIGNUP = 'Sign Up';
translationsEN.CORE.TITLE = 'Title';
translationsEN.CORE.PICTURES = 'Pictures';
translationsEN.CORE.QUESTION = 'Question';

translationsEN.CORE.NAVBAR_HOME = 'Home';
translationsEN.CORE.NAVBAR_ABOUT = 'About';
translationsEN.CORE.NAVBAR_LANGUAGE = 'Language';

translationsEN.CORE.ORG_TYPE_GA = 'Gallery';
translationsEN.CORE.ORG_TYPE_MU = 'Museum';
translationsEN.CORE.ORG_TYPE_AR = 'Archive';
translationsEN.CORE.ORG_TYPE_AC = 'Academy';
translationsEN.CORE.ORG_TYPE_IN = 'Institution';
translationsEN.CORE.ORG_TYPE_HO = 'Public Installation';
translationsEN.CORE.ORG_TYPE_AE = 'Art Event';
translationsEN.CORE.ORG_TYPE_BS = 'Business';
translationsEN.CORE.ORG_TYPE_AS = 'Artist Studio';
translationsEN.CORE.ORG_TYPE_OT = 'Other';

translationsEN.CORE.USER_TITLE_1 = 'Artist';
translationsEN.CORE.USER_TITLE_2 = 'Collector';
translationsEN.CORE.USER_TITLE_3 = 'Curator';
translationsEN.CORE.USER_TITLE_4 = 'Art Fan';
translationsEN.CORE.USER_TITLE_5 = 'Organization';

translationsEN.CORE.CURATION_TYPE_SB = 'Selected by';
translationsEN.CORE.CURATION_TYPE_PB = 'Presented by';
translationsEN.CORE.CURATION_TYPE_COF = 'Courtesy of';
translationsEN.CORE.CURATION_TYPE_CURB = 'Curated by';
translationsEN.CORE.CURATION_TYPE_CB = 'Created by';
translationsEN.CORE.CURATION_TYPE_COCBY = 'Co-curated by';
translationsEN.CORE.CURATION_TYPE_OT = 'Other';

translationsEN.CORE.PORTRAIT_TYPE_1 = 'Portrait';
translationsEN.CORE.PORTRAIT_TYPE_2 = 'Portrait (90° CCW)';
translationsEN.CORE.PORTRAIT_TYPE_3 = 'Portrait (90° CW)';
translationsEN.CORE.PORTRAIT_TYPE_4 = 'Portrait (180°)';

translationsEN.SHARE = {
    COPY_LINK_link_artwork: 'Copy URL to Transfer',
    COPY_LINK_embed_artwork: 'Direct Link to Artwork page',
    COPY_LINK_link_channel: 'Copy URL to Transfer',
    COPY_LINK_embed_channel: 'Direct Link to Channel page',
    LOAN_PERIOD_D: 'Day',
    LOAN_PERIOD_W: 'Week',
    LOAN_PERIOD_M: 'Month',
};

translationsEN.WIFI_MODE = {
    MODE_0: 'Dual Band',
    MODE_1: '5Ghz only',
    MODE_2: '2Ghz only',
    MODE_: 'N/A',
};

translationsEN.VIDEO_FORMAT = {
    TITLE: 'Video Format:',
    HLS_MP4: 'HLS or MP4',
    MP4_ONLY: 'MP4 only',
};

translationsEN.SETTINGS_MISC_SECTION = {
    SECTION_TITLE: 'Analytics',
    SECTION_TITLE_FOR_ADMIN: 'Analytics &amp; Download Optimization',
};

translationsEN.ANALYTICS_BEHAVIOR = {
    TITLE: 'Analytics Behavior',
    AUTO: 'Auto Tracking (Personal in Signage TV Anonymous on Consumer TV)',
    ANONYMOUS: 'Track behavior and send anonymous data',
    PERSONAL_DATA: 'Track behavior and send personal data (i.e. account name and display name)',
    DONOTTRACK: 'Do not send event to analytics system',
};

translationsEN.SLOW_NETWORK = {
    TITLE: 'Slow Network Detection',
    BANDWIDTH_THRESHOLD: 'Bandwidth threshold (MB)',
    TITLE_FIELD: 'Tag to display in Player',
};

translationsEN.AUTO_DOWNLOAD_AWS = {
    TITLE: 'Automatically download artworks',
    DO_NOT_DOWNLOAD: 'Disabled',
    FIRST_IN_EACH_CHANNEL: 'Download first artwork in each channel',
    FIRST_CHANNEL: 'Download artworks from first channel in player playlist',
    SHORTEST: 'Download the shortest artworks first',
    LONGEST: 'Download the longest artworks first',
    MAX_NUM_AWS: 'Maximum Artworks',
    NOTE_FEATURE_IS_DISABLED: 'Download feauters are turnned off for this device',
};

translationsEN.PLAYER_IMAGES_MODE = {
    TITLE: 'Images used in Player (Premium / Preview only):',
    PREMIUM: 'Allow Premium Images',
    PREVIEW: 'Preview Only',
};

translationsEN.PLAYER_PREMIUM_VIDEO_MODE = {
    TITLE: 'Premium Video used in Player (Premium / Preview only):',
    PREMIUM: 'Allow Premium Videos',
    PREVIEW: 'Preview Only',
};

translationsEN.PLAYER_OFFLINE_SUPPORT = {
    TITLE: 'Support download content to Player:',
    AUTO: 'Auto (Enabled in Signage Models only)',
    BLOCK: 'Enabled',
    ALLOW: 'Disabled',
};

translationsEN.PLAYER_DISPLAY_DISCOVER = {
    TITLE: 'Display Discover Channels in Player:',
    AUTO: 'Auto (Display in Consumers Models only)',
    DISPLAY: 'Display',
    HIDE: 'Hide',
};

translationsEN.PLAYER_FOLLOW_DISPALY_GUIDE = {
    TITLE: 'Ignore display guidelines on artwork rotation.',
};

translationsEN.DISTRIBUTION_GROUP = {
    ALL: 'Master Distribution',
    GROUP_A: 'Group A (beta testing)',
    GROUP_B: 'Group B',
    GROUP_C: 'Group C',
    GROUP_D: 'Group D (DL)',
};
translationsEN.COVER_TEMPLATE = {
    LEGACY: 'Legacy (No Rotation)',
    TEMPLATEA: 'Legacy Rotated (A)',
    TEMPLATEB: 'Styled (B)',
    TEMPLATEC: 'Styled Covers (C)',
};

translationsEN.PLAYER_TYPE = {
    REGULAR: 'Regular Playback Mode',
    FULL: 'Regular Playback Mode',
    AW_ONLY: 'Play Artworks Only (No Covers)',
    AW_COVER: 'Artwork Cover Pages Only',
    ARTIST_BIO: 'Artist Bio Page Only',
    CH_COVER: 'Channel Cover Page Only',
    CAMPAIGN: 'Display Campaign / Sponsor Page Only',
};

translationsEN.AW_STRIP_MODES = {
    AUTO: 'Auto Mode',
    NONE: 'Do not display',
    START: 'On Start [X] seconds',
    START_END: 'On Start and End [X] seconds',
    ALWAYS: 'Always',
};

translationsEN.AW_STRIP_CONTENT = {
    AW_CREDIT: 'AW Credits',
    QR: 'AW info QR',
    AW_CREDIT_AND_QR: 'Both',
};

translationsEN.ACTIVITIES = {
    OPCODE_101: 'Artwork Consignee - Added',
    OPCODE_102: 'Artwork Consignee - Removed',
    OPCODE_103: 'Artwork Edition Purchased',
    OPCODE_111: 'Artwork Loan - Started',
    OPCODE_112: 'Artwork Loan - Ended',
    OPCODE_122: 'Artwork Deleted',
    OPCODE_201: 'Channel - Added Or Updated',
    OPCODE_202: 'Channel - Removed',
    OPCODE_211: 'Artworks in Channel - Added',
    OPCODE_212: 'Artworks in Channel  - Removed',
    OPCODE_221: 'Subscription to channel - Added',
    OPCODE_222: 'Subscription to channel - Removed',
    OPCODE_231: 'Subscription to channel - Added PRIVATELY',
    OPCODE_301: "User's Title - Updated",
    OPCODE_302: "User's Plan Updated",
    OPCODE_311: 'User - Added',
    OPCODE_312: 'User - Removed',
};

translationsEN.CORE.CHANNEL_COMMERCIAL_RULES_inquire = 'Inquire Only';
translationsEN.CORE.CHANNEL_COMMERCIAL_RULES_sell = 'Sell';
translationsEN.CORE.CHANNEL_COMMERCIAL_RULES_loan = 'Loan';
translationsEN.CORE.CHANNEL_COMMERCIAL_RULES_sell_loan = 'Sell & Loan';
translationsEN.CORE.CHANNEL_COMMERCIAL_RULES_buy = 'Buy';
translationsEN.CORE.CHANNEL_COMMERCIAL_RULES_borrow = 'Borrow';
translationsEN.CORE.CHANNEL_COMMERCIAL_RULES_buy_borrow = 'Buy & Borrow';

//Hebrew
translationsHE.CORE = {};

translationsHE.CORE.JOIN = 'הצטרף';
translationsHE.CORE.LOGIN = 'התחבר';
translationsHE.CORE.HOW_IT_WORKS = 'איך זה עובד?';
translationsHE.CORE.REQUEST_INVITATION = 'בקש הזמנה';
translationsHE.CORE.PLAYER = 'נגן';
translationsHE.CORE.MY_AREA = 'האיזור שלי';
translationsHE.CORE.DISCOVER = 'חקור';
translationsHE.CORE.MY_NIIO = 'NIIO שלי';
translationsHE.CORE.MARKET = 'חנות';
translationsHE.CORE.PREMIUM = 'PREMIUM';

translationsHE.CORE.CLOSE = 'סגור';
translationsHE.CORE.NAME = 'שם';
translationsHE.CORE.EMAIL = 'אי-מייל';
translationsHE.CORE.PASSWORD = 'סיסמה';
translationsHE.CORE.FEEDBACK = 'פידבק';
translationsHE.CORE.SEND = 'שלח';
translationsHE.CORE.SEARCH = 'חפש';
translationsHE.CORE.ASK = 'שאל';
//translationsHE.CORE.LOGIN = 'התחבר';
translationsHE.CORE.SIGNUP = 'הרשם';
translationsHE.CORE.TITLE = 'כותרת';
translationsHE.CORE.PICTURES = 'תמונות';
translationsHE.CORE.QUESTION = 'שאלה';

translationsHE.CORE.NAVBAR_HOME = 'בית';
translationsHE.CORE.NAVBAR_ABOUT = 'אודות';
translationsHE.CORE.NAVBAR_LANGUAGE = 'שפה';

translationsEN.CORE.ARTWORK_CATEGORY_FG = 'Photography';
translationsEN.CORE.ARTWORK_CATEGORY_VA = 'Video Art / Film';
translationsEN.CORE.ARTWORK_CATEGORY_MI = 'Moving Image';
translationsEN.CORE.ARTWORK_CATEGORY_MM = 'Mixed Media';
translationsEN.CORE.ARTWORK_CATEGORY_KA = 'Kinetic Art';
translationsEN.CORE.ARTWORK_CATEGORY_DP = 'Digital Painting';
translationsEN.CORE.ARTWORK_CATEGORY_FL = 'Film';
translationsEN.CORE.ARTWORK_CATEGORY_VP = 'Video Painting';
translationsEN.CORE.ARTWORK_CATEGORY_CA = 'Code Based Art';
translationsEN.CORE.ARTWORK_CATEGORY_SC = 'Sculpture';
translationsEN.CORE.ARTWORK_CATEGORY_PT = 'Painting';
translationsEN.CORE.ARTWORK_CATEGORY_PR = 'Prints & Multiples';
translationsEN.CORE.ARTWORK_CATEGORY_IS = 'Installation';
translationsEN.CORE.ARTWORK_CATEGORY_OT = 'Other';
translationsEN.CORE.ARTWORK_CATEGORY_AN = 'Animation';
translationsEN.CORE.ARTWORK_CATEGORY_SD = 'Sound Art';
translationsEN.CORE.ARTWORK_CATEGORY_DW = 'Drawing';
translationsEN.CORE.ARTWORK_CATEGORY_DG = 'Design';

translationsHE.SHARE = {
    COPY_LINK_link_artwork: 'Copy URL to Transfer',
    COPY_LINK_embed_artwork: 'Direct Link to Artwork page',
    COPY_LINK_link_channel: 'Copy URL to Transfer',
    COPY_LINK_embed_channel: 'Direct Link to Channel page',
    LOAN_PERIOD_D: 'Day',
    LOAN_PERIOD_W: 'Week',
    LOAN_PERIOD_M: 'Month',
};
