'use strict';

angular.module('admin-area').controller('AdminAreaStatusesActivitiesController', [
    '$q',
    '$rootScope',
    '$http',
    '$log',
    '$filter',
    '_',
    '$translate',
    function ($q, $rootScope, $http, $log, $filter, _, $translate) {
        var self = this;
        this.toggles = self.toggles || {};
        this.itemsPerPage = 10;
        this.currentPage = 1;
        this.maxSize = 5;

        this.setPage = function (pageNo) {
            self.currentPage = pageNo;
        };

        this.tableFilter = {};
        this.availableOpcodes = [];

        // Grid
        this.orderByField = 'id';
        this.reverseSort = true;

        this.opcodeFilterChanged = function (opcode) {
            if (opcode === 'ALL') {
                delete self.tableFilter.opcode;
            }
        };

        function getAvailableOpcodes(activities) {
            self.availableOpcodes = _.uniq(activities, function (activity) {
                return activity.opcode;
            });
            self.availableOpcodes = _.pluck(self.availableOpcodes, 'opcode');
            var tempOpcodesJson = [],
                humanOpcode = '';
            if (self.availableOpcodes.length > 0) {
                self.availableOpcodes.forEach(function (opcode) {
                    //humanOpcode = $filter('inflector')(opcode);
                    var keyString = $translate.instant('ACTIVITIES.OPCODE_' + opcode);
                    tempOpcodesJson.push({ key: keyString, value: parseInt(opcode) });
                });
            }
            self.availableOpcodes = tempOpcodesJson;
            _.sortBy(self.availableOpcodes, function (opcode) {
                return opcode.value;
            });
            self.availableOpcodes.unshift({ key: 'ALL', value: 'ALL' });
        }

        function normalizeResponse(activitiesData) {
            var result = [];
            angular.forEach(activitiesData, function (rcrd) {
                rcrd.ActivitiesLog.id = parseInt(rcrd.ActivitiesLog.id);
                rcrd.ActivitiesLog.params = JSON.parse(rcrd.ActivitiesLog.params);
                result.push(rcrd.ActivitiesLog);
            });
            return result;
        }

        this.getLatestActivities = function () {
            self.toggles.spinRefresh = true;
            return $http
                .post('/admin/getActivities')
                .then(function (res) {
                    self.activities = normalizeResponse(res.data.activities);
                    self.activitiesStatusTime = new Date();
                    getAvailableOpcodes(self.activities);
                })
                .finally(function () {
                    self.toggles.spinRefresh = false;
                });
        };

        this.init = function () {
            //self.getActivitiesOpcode();
        };
    },
]);
