'use strict';

angular.module('home').service('EventsDataService', [
    '$rootScope',
    '$log',
    '$http',
    '$q',
    '$filter',
    '$localStorage',
    '_',
    'ArtworkService',
    'User',
    function ($rootScope, $log, $http, $q, $filter, $localStorage, _, ArtworkService, User) {
        var self = this;

        function init() {
            self.defaultTab = undefined;
        }

        init();

        // EVENTS
        this.addEvent = function () {
            var deferred = $q.defer();
            var data = {};

            $http.post('/events/addEvent', data).then(
                function (res) {
                    var newEvent = res.data.event;
                    //refresh
                    self.getAllEvents();

                    deferred.resolve(newEvent);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.duplicateEvent = function (eventId) {
            var deferred = $q.defer();
            var data = {
                eventId: eventId,
            };

            $http.post('/events/duplicateEvent', data).then(
                function (res) {
                    //refresh
                    self.getAllEvents();

                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.deleteEvent = function (eventId) {
            var deferred = $q.defer();
            var data = {
                eventId: eventId,
            };

            $http.post('/events/deleteEvent', data).then(
                function (res) {
                    //refresh
                    self.getAllEvents();

                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.saveCurrentEventSettings = function (event) {
            var deferred = $q.defer();

            var data = {
                eventId: event.id,
                type: event.type,
                name: event.name,
                description: event.description,
                code: event.code,
                active: event.active,
                visible: event.visible,
                featured: event.featured,
                invitation_code: event.invitation_code,
                max_allowed_artworks: event.max_allowed_artworks,
                submission_fee: event.submission_fee,
                submission_fee_currency: event.submission_fee_currency,
                eventStatus: event.eventStatus,
                select_artworks_text: event.select_artworks_text,
                submission_email_text: event.submission_email_text,
                allow_download: event.allow_download,
            };

            $http.post('/events/saveEventSettings', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.saveCurrentEventContent = function (event) {
            var deferred = $q.defer();

            var data = {
                eventId: event.id,
                custom_form_structure: event.custom_form_structure,
                terms_link: event.terms_link,
                guidelines_filename: event.guidelines_filename,
            };

            $http.post('/events/saveEventContent', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.changeEventHost = function (eventId, newHostId) {
            var deferred = $q.defer();

            var data = {
                eventId: eventId,
                newHostId: newHostId,
            };

            $http.post('/events/changeEventHost', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.addEventCohost = function (eventId, newCohostId, type) {
            var deferred = $q.defer();

            var data = {
                eventId: eventId,
                cohostId: newCohostId,
                type: type,
            };

            $http.post('/events/addEventCohost', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.updateEventCohost = function (eventId, cohostId, cohostType) {
            var deferred = $q.defer();

            var data = {
                eventId: eventId,
                cohostId: cohostId,
                type: cohostType,
            };

            $http.post('/events/updateEventCohost', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.removeEventCohost = function (eventId, cohostId) {
            var deferred = $q.defer();

            var data = {
                eventId: eventId,
                cohostId: cohostId,
            };

            $http.post('/events/removeEventCohost', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.removeSubmitter = function (eventId, submitterId) {
            var deferred = $q.defer();

            var data = {
                eventId: eventId,
                submitterId: submitterId,
            };

            $http.post('/events/removeSubmitter', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.clearSubmissionDate = function (eventId, submitterId) {
            var deferred = $q.defer();

            var data = {
                eventId: eventId,
                submitterId: submitterId,
            };

            $http.post('/events/clearSubmissionDate', data).then(
                function () {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.matchQuestionsToForm = function (form, questions) {
            _.forEach(form, function (field) {
                field.answer = '';

                var matchAnswer = _.find(questions, function (answer) {
                    return answer.qid === field.qid;
                });

                if (matchAnswer) {
                    field.answer = matchAnswer.answer;
                }
            });
        };

        function getEventData(eventId, userId, type, showOnlySubmitted) {
            var deferred = $q.defer();
            showOnlySubmitted = showOnlySubmitted !== undefined ? showOnlySubmitted : true;

            if (eventId) {
                var data = {
                    eventId: eventId,
                    userId: userId,
                    showOnlySubmitted: showOnlySubmitted,
                };
                var link = type === 'host' ? 'getEventHostData' : 'getEventSubmissionData';

                $http.post('/events/' + link, data).then(
                    function (res) {
                        $log.debug('HomeDataService::getEventData Success', res, eventId, type);
                        var event = res.data.data;

                        if (event && event.event) {
                            event.event.isEventOwner = event.event.user_id === userId; // CoHost is not an owner
                        }

                        event?.artworks?.forEach((artwork) => {
                            artwork.display_name = artwork.artist_first_name
                                ? artwork.artist_first_name + artwork.artist_last_name
                                : artwork.uploader.display_name;
                            artwork.price = artwork.price ? parseInt(artwork.price) : null;
                        });

                        // $rootScope.$broadcast('HomeDataService::refresh_event_data', event, type);
                        deferred.resolve(event);
                    },
                    function (error) {
                        $log.debug('HomeDataService::getEventData Failed', error, eventId, type);
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.reject('event ID is empty');
            }

            return deferred.promise;
        }

        this.getEventHostData = function (eventId, userId, showOnlySubmitted) {
            showOnlySubmitted = showOnlySubmitted !== undefined ? showOnlySubmitted : true;
            return getEventData(eventId, userId, 'host', showOnlySubmitted);
        };

        this.getEventSubmissionData = function (eventId, userId) {
            return getEventData(eventId, userId, 'submission');
        };

        this.getSubmissionFormQuestions = function (eventId, userId) {
            var deferred = $q.defer();
            var data = {
                userId: userId,
                eventId: eventId,
            };

            if (eventId && userId) {
                $http.post('/events/getFormQuestions', data).then(
                    function (res) {
                        $log.debug('HomeDataService::getFormQuestions Success', res, eventId, userId);
                        var formData = res.data;

                        deferred.resolve(formData);
                    },
                    function (error) {
                        $log.debug('HomeDataService::getFormQuestions Failed', error, eventId, userId);
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.reject('invalid parameter');
            }

            return deferred.promise;
        };

        this.getAllSubmissionsFormQuestions = function (eventId, userId) {
            var deferred = $q.defer();
            var data = {
                userId: userId,
                eventId: eventId,
            };

            if (eventId && userId) {
                $http.post('/events/getAllFormQuestions', data).then(
                    function (res) {
                        $log.debug(
                            'HomeDataService::getAllSubmissionsFormQuestions Success',
                            res,
                            eventId,
                            userId
                        );
                        var formData = res.data;

                        deferred.resolve(formData);
                    },
                    function (error) {
                        $log.debug(
                            'HomeDataService::getAllSubmissionsFormQuestions Failed',
                            error,
                            eventId,
                            userId
                        );
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.reject('invalid parameter');
            }

            return deferred.promise;
        };

        this.getAllEvents = function () {
            var deferred = $q.defer();
            var data = {};

            $http.post('/events/getAllEvents', data).then(
                function (res) {
                    var events = res.data.events.all;

                    $rootScope.$broadcast('HomeDataService::refresh_all_events', events);
                    deferred.resolve(events);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getAllTerms = function (eventId, userId) {
            var deferred = $q.defer();
            var data = {
                eventId: eventId,
            };

            $http.post('/events/getAllTerms', data).then(
                function (res) {
                    var terms = res.data.terms[0].event_tnc;
                    deferred.resolve(terms);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.calculatedEventSubmissions = function (event) {
            var data = {
                availableSubmitters: [],
                submissionsIdx: {},
                totalSubmitted: 0,
            };

            event.submissions.forEach(function (submission) {
                var item = {
                    submitter: {
                        display_name: submission.user.display_name,
                        submitted: $filter('mysqlDateToISOString')(submission.submitted),
                    },
                    submitterId: submission.user.id,
                    count: 0,
                };
                data.availableSubmitters.push(item);
                data.submissionsIdx[submission.user.id] = item;
                if (submission.submitted) {
                    data.totalSubmitted++;
                }
            });

            data.availableSubmitters = _.sortBy(data.availableSubmitters, function (submitter) {
                return submitter.submitter.display_name.toLowerCase();
            });

            event.artworks.forEach(function (artwork) {
                var item = data.submissionsIdx[artwork.user_id];
                if (item !== undefined) {
                    item.count++;
                }
            });

            return data;
        };

        this.getEvents = function (userId) {
            var deferred = $q.defer();
            var data = {
                userId: userId,
            };

            $http.post('/events/getEvents', data).then(
                function (res) {
                    var events = res.data.events;

                    $rootScope.$broadcast('HomeDataService::refresh_events', events);
                    deferred.resolve(events);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.updateStepCompleted = function (eventId, userId, stepName) {
            var deferred = $q.defer();

            if (stepName !== 'instructions') {
                var data = {
                    eventId: eventId,
                    userId: userId,
                    stepName: stepName,
                };

                $http.post('/events/updateStepCompleted', data).then(
                    function (res) {
                        deferred.resolve(res);
                    },
                    function (error) {
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        };

        this.enrollToEvent = function (eventCode, userId) {
            var data = {
                eventCode: eventCode,
                userId: userId,
            };

            return $http.post('/events/enrollToEvent', data);
        };

        this.unenrollEvent = function (eventId, userId) {
            var data = {
                eventId: eventId,
                userId: userId,
            };

            return $http.post('/events/unenrollEvent', data);
        };

        this.initSubmissionWizardSteps = function (eventData) {
            var submissionWizardSteps = [];

            if (eventData) {
                var submission = eventData.submissions[0];
                var event = eventData.event;
                var stepNum = 0;
                if (submission && event) {
                    submissionWizardSteps.push({
                        link: 'instructions',
                        name: 'Instructions',
                        completed: null,
                        notRequired: true,
                    });
                    submissionWizardSteps.push({
                        number: ++stepNum,
                        link: 'profile',
                        name: 'Information',
                        completed: submission.completed_profile_step,
                    });
                    if (event.custom_form_structure) {
                        submissionWizardSteps.push({
                            number: ++stepNum,
                            link: 'form',
                            name: 'Questionnaire',
                            completed: submission.completed_form_step,
                        });
                    }

                    submissionWizardSteps.push({
                        number: ++stepNum,
                        link: 'artworks',
                        name: 'Select Artworks / Projects',
                        completed: submission.completed_artworks_step,
                    });
                    submissionWizardSteps.push({
                        number: ++stepNum,
                        link: 'summary',
                        name: 'Submit',
                        completed: submission.submitted !== null,
                    });
                }
            }

            return submissionWizardSteps;
        };

        this.eventInProcessExists = function (userId) {
            var data = {
                userId: userId,
            };

            return $http.post('/events/eventInProcessExists', data);
        };

        this.submitList = function (eventId, userId, listName) {
            var data = {
                eventId: eventId,
                userId: userId,
                listName: listName,
            };

            return $http.post('/events/submitList', data);
        };

        this.getHeaderForSubmissionsCSV = function (customFormStructure, maxArtworksSubmitted) {
            var customForm = JSON.parse(customFormStructure);
            var array = [];
            array.push(
                '#',
                'User ID',
                'Submission Status',
                'Name',
                'Email',
                'Phone',
                'Country',
                'State',
                'City',
                'Address',
                'Zip Code'
            );

            for (var i = 0; i < maxArtworksSubmitted; i++) {
                array.push('Artwork #' + (i + 1));
                array.push('Artwork #' + (i + 1) + ' Shortlist');
                array.push('Artwork #' + (i + 1) + ' Finalist');
            }

            array.push(
                'Completed Personal Details Step',
                'Completed Questionnaire Step',
                'Completed Artworks Selection Step',
                'Submitted',
                'Terms Version Signed',
                'Started Submission Process'
            );

            _.each(customForm, function (field) {
                if (field.qid) {
                    array.push('Question ID ' + field.qid + ' - ' + field.question);
                }
            });

            array.push(
                'Bio / About',
                'Education',
                'Exhibitions',
                'Awards',
                'Representation',
                'Public Contact Info',
                'Website Link',
                'CV Link'
            );

            return array;
        };

        function incrementAlphabetChar(char) {
            // Taken from here: http://stackoverflow.com/questions/30685916/increment-a-string-with-letters
            var result = (parseInt(char, 36) + 1).toString(36).replace(/0/g, 'a');

            if (result[0] === '1') {
                result = 'a' + result.slice(1);
            }

            return result;
        }

        this.exportSubmissionsCSV = function (customFormStructure, reportRows, maxArtworksSubmitted) {
            var array = [];
            var customForm = JSON.parse(customFormStructure);

            if (reportRows) {
                reportRows.forEach(function (row, rowIndex) {
                    var line = {};
                    var index = 'a';
                    var foundAnswer;
                    var artworksInsertted = 0;

                    line[index] = rowIndex + 1;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.id;
                    index = incrementAlphabetChar(index);
                    line[index] = row.submission.submitted ? 'Completed' : 'Not Completed';
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.display_name;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.email;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.phone;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.country;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.state;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.city;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.address;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.zip_code;
                    _.each(row.artworks, function (artwork, artworkIndex) {
                        artworksInsertted++;
                        index = incrementAlphabetChar(index);
                        line[index] = artwork.title;
                        // Shortlist
                        index = incrementAlphabetChar(index);
                        line[index] =
                            artwork.artwork_submission && artwork.artwork_submission.approved === true
                                ? 'Yes'
                                : 'No';
                        // Finalist
                        index = incrementAlphabetChar(index);
                        line[index] = artwork.is_accepted || artwork.is_consignee ? 'Yes' : 'No';
                    });

                    for (var i = 0; i < maxArtworksSubmitted - artworksInsertted; i++) {
                        index = incrementAlphabetChar(index);
                        line[index] = '';
                    }

                    index = incrementAlphabetChar(index);
                    line[index] = row.submission.completed_profile_step ? 'Yes' : 'No';
                    index = incrementAlphabetChar(index);
                    line[index] = row.submission.completed_form_step ? 'Yes' : 'No';
                    index = incrementAlphabetChar(index);
                    line[index] = row.submission.completed_artworks_step ? 'Yes' : 'No';
                    index = incrementAlphabetChar(index);
                    line[index] = row.submission.submitted
                        ? $filter('date')(
                              $filter('mysqlDateToISOString')(row.submission.submitted),
                              'dd/MM/yyyy HH:mm'
                          )
                        : '';
                    index = incrementAlphabetChar(index);
                    line[index] = row.submission.tnc_version;
                    index = incrementAlphabetChar(index);
                    line[index] = row.submission.created
                        ? $filter('date')(
                              $filter('mysqlDateToISOString')(row.submission.created),
                              'dd/MM/yyyy HH:mm'
                          )
                        : '';

                    _.each(customForm, function (field) {
                        if (field.qid) {
                            if (row.answers) {
                                foundAnswer = _.find(row.answers, function (answer) {
                                    return answer.qid === field.qid;
                                });

                                index = incrementAlphabetChar(index);
                                line[index] = foundAnswer ? foundAnswer.answer : '';
                            } else {
                                index = incrementAlphabetChar(index);
                                line[index] = '';
                            }
                        }
                    });

                    index = incrementAlphabetChar(index);
                    line[index] = row.user.about;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.education;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.exhibitions;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.awards;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.representation;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.contact_info;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.website_link;
                    index = incrementAlphabetChar(index);
                    line[index] = row.user.cv_path ? 'https:' + row.user.cv_path : '';

                    array.push(line);
                });
            }

            return array;
        };
    },
]);
