'use strict';

angular.module('core').directive('onLastRepeat', [
    function () {
        return function (scope, element, attrs) {
            if (scope.$last) {
                scope.$emit('onRepeatLast', element, attrs);
            }
        };
    },
]);

// This directive is specificly for handling events after ng-repeat completed rendering the dom
