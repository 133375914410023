'use strict';

angular.module('home').controller('HomeCollectionsController', [
    'collections',
    '$rootScope',
    '$filter',
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$timeout',
    '_',
    'smoothScroll',
    'HomeDataService',
    'User',
    'OpenPopupForm',
    function (
        collections,
        $rootScope,
        $filter,
        $scope,
        $state,
        $stateParams,
        $log,
        $q,
        $timeout,
        _,
        smoothScroll,
        HomeDataService,
        User,
        OpenPopupForm
    ) {
        $scope.$state = $state;
        $scope.collapsibles = {};
        $scope.HomeDataService = HomeDataService;

        function resetArtistFilter() {
            $scope.artistFilter = {};
            $scope.availableArtists = [];
        }

        //TODO: Duplicated with add-collection
        function loadArtworks() {
            var deferred = $q.defer();

            angular.forEach($scope.artworks, function (artwork) {
                //artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                //artwork.display_name = artwork.artist_first_name ? artwork.artist_first_name + artwork.artist_last_name : artwork.uploader.display_name;
                artwork.numericPrice = artwork.price ? parseInt(artwork.price) : null;
            });

            deferred.resolve();

            return deferred.promise;
        }

        // Grid
        $scope.orderByField = 'Artwork.title';
        $scope.reverseSort = false;

        $scope.hideSidebar = $scope.isOther && !User.isAdmin();

        function artistExistsInAvailableArtists(artist, availableArtists) {
            var result = false,
                i;

            if (availableArtists && availableArtists.length > 0) {
                for (i = 0; i < availableArtists.length; i++) {
                    if (
                        availableArtists[i].artist &&
                        artist.artist &&
                        availableArtists[i].artist.display_name === artist.artist.display_name
                    ) {
                        result = true;
                        break;
                    }
                }
            }

            return result;
        }

        function isFirstTime(collections) {
            var artworksExists = false,
                i;

            for (i = 0; i < collections.length; i++) {
                if (collections[i].artworks && collections[i].artworks.length > 0) {
                    artworksExists = true;
                    break;
                }
            }

            return !artworksExists;
        }

        function isFirstTimeUpload() {
            if ($scope.isOther) {
                return;
            }
            var numberOfUploadedArtworks = 0;
            var allArtworks = _.find($scope.collections, function (collection) {
                return collection.id === '0'; // All artworks
            });

            if (allArtworks) {
                var userId = $scope.profile ? $scope.profile.id : User.getId();
                var uploadedArtworks = _.filter(allArtworks.artworks, function (artwork) {
                    return artwork.uploader.user_id === userId;
                });
                if (uploadedArtworks) {
                    numberOfUploadedArtworks = uploadedArtworks.length;
                }
            }

            if (numberOfUploadedArtworks === 0) {
                if ($state.current.data.state === 'vault') {
                    HomeDataService.isFirstTimeVaultUpload = true;
                } else {
                    HomeDataService.isFirstTimePortfolioUpload = true;
                }
            } else if (numberOfUploadedArtworks > 0) {
                if ($state.current.data.state === 'vault' && HomeDataService.isFirstTimeVaultUpload) {
                    $timeout(function () {
                        OpenPopupForm.openMenu('uploadsFirstTime');
                    }, 3000);
                    HomeDataService.isFirstTimeVaultUpload = false;
                } else if (
                    $state.current.data.state === 'portfolio' &&
                    HomeDataService.isFirstTimePortfolioUpload
                ) {
                    $timeout(function () {
                        OpenPopupForm.openMenu('uploadsFirstTime');
                    }, 3000);
                    HomeDataService.isFirstTimePortfolioUpload = false;
                }
            }
        }

        $scope.init = function (initCollections) {
            //Scroll to head of page in init
            if (!$scope.isOther && !$scope.lastScroll) {
                var homeSecondNavElement = document.getElementById('home-secondary-nav');
                if (homeSecondNavElement) {
                    smoothScroll(homeSecondNavElement, { offset: $rootScope.heightOfNav });
                }
            }
            $scope.collections = initCollections || collections;
            if (
                $scope.$state.params.artworkId &&
                !HomeDataService.isUsersCollection($scope.collections, $scope.$state.params.artworkId)
            ) {
                $state.go('home');
                return;
            }
            $scope.collections = $scope.collections.map((collection) => {
                return {
                    ...collection,
                    name: collection.collection?.nft_contract_id
                        ? `${collection.collection.name} NFT`
                        : collection.name,
                };
            });
            $scope.currentState = $state.current.data.state;
            $scope.isFirstTime = isFirstTime($scope.collections);
            if ($scope.isFirstTime) {
                $rootScope.$broadcast('collection_first_time_upload', true);
            }
            isFirstTimeUpload();
        };

        function refreshCollections(event) {
            $rootScope.$broadcast('ajax-start');

            if ($state.current.data.state === 'vault') {
                HomeDataService.getVaultCollections(null, false).then(function (res) {
                    $scope.init(res);
                    $timeout(function () {
                        $rootScope.$broadcast('HomeCollectionsMainController::refresh_collection_artworks');
                    }, 100);
                });
            } else {
                HomeDataService.getPortfolioCollections(null, false).then(function (res) {
                    $scope.init(res);
                    $timeout(function () {
                        $rootScope.$broadcast('HomeCollectionsMainController::refresh_collection_artworks');
                    }, 100);
                });
            }
        }

        // Refresh collections on local uploads (batch)
        $scope.$on('batch_files_upload_started', refreshCollections);

        // Refresh collections on cloud uploads (batch)
        $scope.$on('cloud_uploads_all_finished', refreshCollections);

        $scope.$on(
            'HomeCollectionsMainController:collection_chosen',
            function (event, collectionId, artworks) {
                if ($scope.selectedCollection && $scope.selectedCollection.id === collectionId) {
                    return;
                }
                $scope.collectionId = collectionId;
                $scope.selectedCollection = _.find($scope.collections, function (collection) {
                    return collection.id === collectionId;
                });

                resetArtistFilter();

                if (artworks && artworks.length > 0) {
                    artworks.forEach(function (artwork) {
                        if (artwork.artist && artwork.artist.display_name) {
                            if (artistExistsInAvailableArtists(artwork, $scope.availableArtists)) {
                                var foundArtist = _.find($scope.availableArtists, function (artist) {
                                    return (
                                        artwork.artist &&
                                        artist.artist.display_name === artwork.artist.display_name
                                    );
                                });
                                if (foundArtist) {
                                    foundArtist.count++;
                                }
                            } else {
                                $scope.availableArtists.push({
                                    artist: { display_name: artwork.artist.display_name },
                                    count: 1,
                                });
                            }
                        }
                    });
                    $scope.availableArtists = _.sortBy($scope.availableArtists, function (artist) {
                        return artist.artist.display_name.toLowerCase();
                    });
                }
                if (
                    $state.params.filterArtist !== undefined &&
                    $state.params.filterArtist !== null &&
                    $state.params.filterArtist !== '0'
                ) {
                    $scope.artistFilter = {
                        artist: {
                            display_name: $state.params.filterArtist,
                        },
                    };
                }
            }
        );

        $rootScope.$on('collection_name_changed', function (event, collectionId, newName, oldName) {
            var collection = _.find($scope.collections, function (collection) {
                return collection.id === collectionId;
            });

            if (collection && collection.name === oldName) {
                collection.name = newName;
            }
        });

        function findArtworkInArtworks(artworks, artworkId) {
            var artworkIndexInCollection = _.findIndex(artworks, function (artwork) {
                return artwork.id === artworkId;
            });
            return artworkIndexInCollection;
        }

        function removeArtworkFromAllCollection(event, artworkId) {
            for (var i = 0; i < $scope.collections.length; i++) {
                var collection = $scope.collections[i];

                //fix incorrect object structure
                var collectionData = collection.collection !== undefined ? collection.collection : collection;

                // remove artwork from collection
                var artworkIndexInCollection = findArtworkInArtworks(collectionData.artworks, artworkId);
                if (artworkIndexInCollection > -1) {
                    collectionData.artworks.splice(artworkIndexInCollection, 1);
                    collection.length--;
                }
            }
        }

        $rootScope.$on('artwork_deleted', removeArtworkFromAllCollection);
        $rootScope.$on('artwork_revoked', removeArtworkFromAllCollection);

        //$rootScope.$on('artwork_deleted', function (event, artworkId) {
        //    if ($state.includes('home')) {
        //        var collection = _.find($scope.collections, function (collection) { return collection.id === '0'; });
        //        collection.length--;
        //    }
        //});

        $scope.$on('ArtworkInfoArtworkController:artwork_info_entered', function () {
            $scope.hideSidebar = true;
        });

        $scope.$on('ArtworkInfoArtworkController:artwork_info_destroyed', function () {
            $scope.hideSidebar = false;
        });

        $scope.$on('$destroy', function () {
            resetArtistFilter();
        });
    },
]);
