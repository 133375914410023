'use strict';

// Setting up route
angular.module('billing').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state('billing', {
                url: '/billing/:artworkId',
                abstract: true,
                controller: 'BillingController',
                templateUrl: 'modules/billing/views/billing.client.view.html',
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('plans', {
                url: '/plans?fromIframe&clickedCycle&clickedPlan&o&cc',
                templateUrl: 'modules/billing/views/billing.plans.client.view.html',
            })

            .state('plan-subscribe', {
                url: '/plans/:planName?fromIframe&cc',
                templateUrl: 'modules/billing/views/billing.plan-subscribe.client.view.html',
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('buy', {
                url: '/buy?type&artworkId&channelId&eventId&fromIframe&share&invitationCode&cc',
                templateUrl: 'modules/billing/views/billing.buy.client.view.html',
            })

            .state('billing.success', {
                url: '/success',
                templateUrl: 'modules/billing/views/billing.success.client.view.html',
                // resolve: {
                //    'isAllowed': ['$q', '$stateParams', '$rootScope', 'BillingService', function($q, $stateParams, $rootScope, BillingService) {
                //        var deferred = $q.defer();
                //
                //        BillingService.isAllowedToView($stateParams.artworkId)
                //            .then(function(res) {
                //                deferred.resolve(res);
                //            }, function(error) {
                //                $rootScope.previousState();
                //                deferred.reject(error);
                //            });
                //
                //        return deferred.promise;
                //    }]
                // }
            })

            .state('billing.pending', {
                url: '/pending',
                templateUrl: 'modules/billing/views/billing.pending.client.view.html',
                // controller: 'BillingController'
            })

            .state('billing.failure', {
                url: '/failure',
                templateUrl: 'modules/billing/views/billing.failure.client.view.html',
                // controller: 'BillingController'
            })

            .state('billing.success-rent', {
                url: '/success-rent',
                templateUrl: 'modules/billing/views/billing.success-rent.client.view.html',
            })

            .state('billing.pending-rent', {
                url: '/pending-rent',
                templateUrl: 'modules/billing/views/billing.pending-rent.client.view.html',
                // controller: 'BillingController'
            })

            .state('billing.failure-rent', {
                url: '/failure-rent',
                templateUrl: 'modules/billing/views/billing.failure-rent.client.view.html',
                // controller: 'BillingController'
            })

            .state('billing.subscription-success', {
                url: '/subscription-success',
                templateUrl: 'modules/billing/views/billing.subscription-success.client.view.html',
                controller: 'BillingSubscriptionReturnController',
            });
    },
]);
