'use strict';

angular.module('discover').controller('DiscoverChannelsController', [
    '$scope',
    '$rootScope',
    '$state',
    'User',
    'DiscoverDataService',
    '$http',
    '$stateParams',
    function ($scope, $rootScope, $state, User, DiscoverDataService, $http, $stateParams) {
        $scope.init = async function () {
            $scope.lastScroll = null;

            const allDatabaseThemes = await $http.get('/themes/getAll');
            $scope.themes = allDatabaseThemes.data.data || [];
            $scope.themesList = [];

            $scope.modalSelectedTheme = $state.params.theme;
            $scope.modalSelectedCategory = $state.params.channelsName;

            await $scope.syncUsersThemes();
        };
        $scope.$state = $state;
        $scope.isFilterModalOpen = false;
        $scope.showInternal = User.isProCurator() || User.isAdmin();

        $scope.showFilterModal = () => {
            $scope.isFilterModalOpen = true;
        };

        $scope.hideFilterModal = () => {
            $scope.isFilterModalOpen = false;
            $scope.modalSelectedTheme = $state.params.theme;
            $scope.modalSelectedCategory = $state.params.channelsName;
            $scope.syncCurrentTheme();
        };

        $scope.setSelectedCategory = (category) => {
            $scope.modalSelectedCategory = category || 'all';
        };

        $scope.setSelectedTheme = (theme) => {
            $scope.modalSelectedTheme = theme;
        };

        $scope.onShowResultsClick = () => {
            $scope.clearCacheData();
            $scope.isFilterModalOpen = false;

            $state.go($state.current.name, {
                channelsName: $scope.modalSelectedCategory || 'all',
                theme: $scope.modalSelectedTheme || null,
            });
        };

        $scope.resetTags = () => {
            $scope.clearCacheData();
            $scope.filterByOrientationChange(null, true);
            $scope.isFilterModalOpen = false;
            $scope.modalSelectedCategory = 'all';
            $scope.modalSelectedTheme = '';
            $state.params.channelsName = 'all';
            $state.params.theme = null;
            reloadWithNewParams();
        };

        $scope.addThemeToFavorite = async (theme) => {
            $rootScope.$broadcast('addNotification', {
                message: 'Genre added to your favorites.',
                type: 'success',
                duration: 3000,
            });

            $scope.isFollowingCurrentTheme = true;
            await $http.post('/themes/addToUser', { themes: [...theme.id] });
            await $scope.syncUsersThemes();
        };

        $scope.removeThemeFromFavorite = async (theme) => {
            $scope.isFollowingCurrentTheme = null;
            await $http.post('/themes/removeFromUser', { themes: [...theme.id] });
            await $scope.syncUsersThemes();
            $rootScope.$broadcast('addNotification', {
                message: 'Genre removed from your favorites.',
                type: 'success',
                duration: 3000,
            });
        };

        $scope.clearCacheData = () => {
            if ($scope.type === 'channels') {
                DiscoverDataService.setCachedDisplayArtcasts(null);
            } else {
                DiscoverDataService.setCachedDisplayCatalogs(null);
            }
        };

        $scope.filterByGenreChange = function (themeId) {
            $scope.clearCacheData();
            $state.go($state.current.name, { ...$state.params, theme: themeId });
        };

        const reloadWithNewParams = (params) => {
            const newParams = params || { ...$state.params };
            $state.go('discover.' + $scope.type + '.main', newParams, { notify: true, reload: true });
        };

        $scope.filterByOrientationChange = function (orientation, preventRedirect) {
            $scope.clearCacheData();
            $state.params.portrait = null;
            $state.params = orientation !== null ? { ...$state.params, portrait: orientation } : { ...$state.params };
            if (!preventRedirect) {
                reloadWithNewParams();
            }
        };

        $scope.clearGenresFilter = function () {
            $scope.clearCacheData();
            $state.go($state.current.name, { theme: null });
        };

        $scope.onFilterChange = function () {
            $scope.clearCacheData();
            $state.go($state.current.name, { ...$state.params, channelsName: 'all' });
        };

        $scope.syncUsersThemes = async function () {
            const userFavoritesThemes = await $http.get('/themes/getOfUser');
            $scope.themesList = userFavoritesThemes?.data?.data || [];
            $scope.syncCurrentTheme();
        };

        $scope.syncCurrentTheme = function () {
            $scope.currentGenre = $scope.themes?.find((theme) => theme.id === $state.params.theme) || null;
            $scope.isFollowingCurrentTheme =
                $scope.themesList?.find((theme) => theme.id === $state.params.theme) || null;

            $scope.channelsName = $state.params.channelsName;
        };

        $scope.$watchGroup(['currentThemeId', '$state.params', 'themes'], () => {
            $scope.syncCurrentTheme();
        });

        $scope.sidebarItems = [
            {
                name: 'Featured',
                link: 'featured',
            },
            {
                name: 'Latest',
                link: 'latest',
            },
            {
                name: 'All',
                link: 'all',
            },
        ];

        $scope.type = $state.current && $state.current.data ? $state.current.data.type : 'channels';

        /*if (User.isAdmin()) {
            $scope.sidebarItems.push({link: 'all', name: 'All'});
            $scope.sidebarItems.push({link: 'latest', name: 'Latest'});
        }*/

        /*
        $scope.sidebarItems = [];
        if (User.isAdmin()) {
            $scope.sidebarItems.push({
                name: 'Display',
                options: [
                    {label: 'Featured', value: 'featured'},
                    {label: 'Latest', value: 'latest'},
                    {label: 'All', value: 'all'}
                ],
                selected: 'featured'
            });
        }
        $scope.sidebarItems.push({
            name: 'Genre',
            options: [
                {label: 'All', value: 'all'}
            ],
            selected: 'all'
        });
        $scope.sidebarItems.push({
            name: 'Medium',
            options: [
                {label: 'All', value: 'all'}
            ],
            selected: 'all'
        });
        $scope.sidebarItems.push({
            name: 'Orientation',
            options: [
                {label: 'All', value: 'all'},
                {label: 'Landscape', value: 'landscape'},
                {label: 'Portrait', value: 'portrait'},
                {label: 'Custom', value: 'custom'}
            ],
            selected: 'all'
        });
        $scope.sidebarItems.push({
            name: 'Quality',
            options: [
                {label: 'All', value: 'all'},
                {label: 'HD (720p)', value: '720'},
                {label: 'FHD (1080p)', value: '1080'},
                {label: '4K UHD (2160p)', value: '2160'},
                {label: '8K UHD (4320p)', value: '4320'}
            ],
            selected: 'all'
        });

        if (User.isAdmin() && $scope.type === 'catalogs') {
            $scope.sidebarItems.push({
                name: 'Commercial Rule',
                options: [
                    {label: 'All', value: 'all'},
                    {label: 'Buy', value: 'buy'},
                    {label: 'Loan', value: 'loan'},
                    {label: 'Inquire Only', value: 'inquire'}
                ],
                selected: 'all'
            });
        }
        
        */

        $scope.selectedSection = function (channelLink) {
            $scope.clearCacheData();
            $state.go('discover.' + $scope.type + '.main', { channelsName: channelLink });
        };

        $scope.fakeSelection = 'featured';
        $scope.hideSidebar = false; /* !User.isAdmin();*/
        $scope.displayTitle = true;
        $scope.$on('ChannelInfoChannelController:channel_info_entered', function () {
            $scope.displayTitle = false;
            $scope.hideSidebar = true;
        });

        $scope.$on('ChannelInfoChannelController:channel_info_destroyed', function () {
            $scope.displayTitle = true;
            $scope.hideSidebar = false;
        });

        $scope.$on('DiscoverChannelsMainController:channels_name_changed', function (event, channelsName) {
            if (channelsName) {
                $scope.fakeSelection = channelsName;
            }
        });

        $rootScope.$on('discover_hide_sidebar', function () {
            $scope.hideSidebar = true;
        });

        $rootScope.$on('discover_show_sidebar', function () {
            $scope.hideSidebar = false;
        });
    },
]);
