'use strict';

angular.module('core').directive('uploadsButton', [
    'OpenPopupForm',
    function (OpenPopupForm) {
        return {
            restrict: 'E',
            templateUrl: 'modules/core/directives/core.uploads-button.client.view.html',
            link: function (scope, element, attrs) {
                scope.isUploadsWindowOpen = false;

                scope.openUploadsWindow = function () {
                    if (!scope.isUploadsWindowOpen) {
                        scope.uploadsPopupMenu = OpenPopupForm.openMenu('uploads').finally(() => {
                            scope.isUploadsWindowOpen = false;
                        });
                        scope.isUploadsWindowOpen = true;
                    }
                };
            },
        };
    },
]);
