'use strict';

//Authentication service for user variables
angular.module('core').service('PushHandlerService', [
    '$q',
    '$rootScope',
    '$log',
    '$http',
    '$interval',
    '$location',
    '$localStorage',
    'ngPushy',
    'Messages',
    '_',
    function ($q, $rootScope, $log, $http, $interval, $location, $localStorage, ngPushy, Messages, _) {
        var self = this;
        this.$storage = $localStorage;
        this.$storage.pushy = self.$storage.pushy || {
            notificationIsGranted: false,
            pushyIsSupported: false,
            askedForNotificationPermission: false,
            notificationOnPermissions: false,
        };

        this.getRemoteId = function () {
            return self.$storage?.pushy?.remote_id ?? null;
        };

        this.init = function () {
            if ($rootScope.fromPlayer) {
                return;
            }

            if (!self.$storage.pushy) {
                self.$storage.pushy = {};
            }

            // self.$storage.pushy.remote_id = self.$storage.pushy.remote_id || null;
            // self.serviceWorker = self.$storage.pushy.serviceWorker || null;
            // self.$storage.pushy.notificationIsGranted = self.$storage.pushy.notificationIsGranted || false;
            self.$storage.pushy.notificationIsGranted = false; // Need to be checked every time
            self.$storage.pushy.remoteIsVerified = false; // Checked every time calling register
            self.$storage.pushy.pushyIsSupported = self.$storage.pushy.pushyIsSupported || false;
            self.$storage.pushy.askedForNotificationPermission =
                self.$storage.pushy.askedForNotificationPermission || false;
            self.$storage.pushy.notificationOnPermissions = self.$storage.pushy.notificationOnPermissions || false;
            self.remoteKeepaliveInterval = undefined;
            self.getPushNotifications().then(
                function (res) {
                    $log.debug('PushHandlerService::init getPushNotifications', res);
                },
                function (error) {
                    $log.debug('PushHandlerService::init getPushNotifications', error);
                }
            );
        };

        function clearData() {
            // TODO: deregister remote id on log out?
            self.cancelRemoteKeepaliveInterval();
            self.$storage.pushy = {};

            // terminate service worker
            // Taken from here: https://stackoverflow.com/questions/58825829/how-to-manually-terminate-a-running-service-worker
            navigator.serviceWorker.getRegistrations().then(function (regs) {
                regs.forEach((reg) => {
                    if (reg.unregister instanceof Function) {
                        reg.unregister();
                    }
                });
            });
            // if (self.serviceWorker) {
            //     if (self.serviceWorker.terminate instanceof Function) {
            //         self.serviceWorker.terminate();
            //     }
            // }
        }

        $rootScope.$on('pushy_verify_remote', function (event, data) {
            if (data.verificationCode) {
                self.getPushNotifications(null, null, data.verificationCode).then(
                    function (res) {
                        $log.debug('PushHandlerService::pushy_verify_remote getNotificationsFromDevice Success', res);
                    },
                    function (err) {
                        $log.debug('PushHandlerService::pushy_verify_remote getNotificationsFromDevice Error', err);
                    }
                );
            }
            $log.debug('PushHandlerService::pushy_verify_remote', data);
        });

        function pushyVerifyRemote(data) {
            if (data.verificationCode) {
                self.getPushNotifications(null, null, data.verificationCode).then(
                    function (res) {
                        $log.debug('PushHandlerService::pushy_verify_remote getNotificationsFromDevice Success', res);
                    },
                    function (err) {
                        $log.debug('PushHandlerService::pushy_verify_remote getNotificationsFromDevice Error', err);
                    }
                );
            }
            $log.debug('PushHandlerService::pushy_verify_remote', data);
        }

        function handlePush(event) {
            $log.debug('PushHandlerService::handlePush Push notification was received ', event.data);

            if (event.data.Command) {
                var data;
                if (event.data.Data) {
                    data = JSON.parse(event.data.Data);
                }

                switch (event.data.Command) {
                    case 'verify':
                        $rootScope.$broadcast('pushy_verify_remote', data);
                        pushyVerifyRemote(data);
                        break;
                    case 'updateStatus':
                        $rootScope.$broadcast('pushy_update_status', data);
                        break;
                    case 'updateStatusRealTime':
                        $rootScope.$broadcast('pushy_update_status_real_time', data);
                        break;
                    case 'updateSettings':
                        $rootScope.$broadcast('pushy_update_settings', data);
                        break;
                    case 'updateList':
                        $rootScope.$broadcast('pushy_update_list', data);
                        break;
                    case 'deviceDeleted':
                        $rootScope.$broadcast('pushy_device_deleted', data);
                        break;
                    case 'updateContentSettings':
                        $rootScope.$broadcast('pushy_update_content_settings', data);
                        break;
                    case 'updateDevicePresence':
                        $rootScope.$broadcast('pushy_update_device_presence', data);
                        break;
                    case 'updateCurrentStatus':
                        $rootScope.$broadcast('pushy_update_current_status', data);
                        break;
                    case 'UpdateUserPlan':
                        $rootScope.$broadcast('pushy_update_user_plan', data);
                        break;
                    case 'updateArtworkReady':
                        $rootScope.$broadcast('pushy_update_artwork_ready', data);
                        break;
                    case 'updateArtworkPreviewReady':
                        $rootScope.$broadcast('pushy_update_artwork_preview_ready', data);
                        break;
                    case 'updateArtworkPending':
                        $rootScope.$broadcast('pushy_update_artwork_pending', data);
                        break;
                    case 'updateArtworkFailed':
                        $rootScope.$broadcast('pushy_update_artwork_failed', data);
                        break;
                    case 'updateArtworkUploadFailed':
                        $rootScope.$broadcast('pushy_update_artwork_upload_failed', data);
                        break;
                    case 'updateArtworkUploadAborted':
                        $rootScope.$broadcast('pushy_update_artwork_upload_aborted', data);
                        break;
                    case 'updateArtworkUploading':
                        $rootScope.$broadcast('pushy_update_artwork_uploading', data);
                        break;
                    case 'updateArtworkDamaged':
                        $rootScope.$broadcast('pushy_update_artwork_damaged', data);
                        break;
                }
            }
        }

        // Taken from: https://stackoverflow.com/questions/41591425/how-to-pass-data-from-service-worker-to-angular-js-controller
        function registerServiceWorker() {
            var deferred = $q.defer();

            // Start push worker
            if ('serviceWorker' in navigator && 'PushManager' in window) {
                $log.debug('PushHandlerService::registerServiceWorker Service Worker and Push is supported');

                navigator.serviceWorker
                    .register('service-worker.js')
                    .then(function (swReg) {
                        $log.debug('PushHandlerService::registerServiceWorker Service Worker is registered', swReg);
                        self.serviceWorker = swReg;
                        navigator.serviceWorker.addEventListener('message', handlePush);
                        deferred.resolve(self.serviceWorker);
                    })
                    .catch(function (error) {
                        $log.debug('PushHandlerService::registerServiceWorker Service Worker Error', error);
                    });
            } else {
                $log.debug('PushHandlerService::registerServiceWorker Push messaging is not supported');
                // pushButton.textContent = 'Push Not Supported';
            }

            return deferred.promise;
        }

        // Taken from here: https://ourcodeworld.com/articles/read/805/how-to-detect-if-you-are-in-incognito-mode-with-javascript-in-google-chrome
        function isIncognito(callback) {
            var fs = window.RequestFileSystem || window.webkitRequestFileSystem;

            if (!fs) {
                callback(false);
            } else {
                fs(window.TEMPORARY, 100, callback.bind(undefined, false), callback.bind(undefined, true));
            }
        }

        function checkNotificationStatus() {
            var deferred = $q.defer();
            var err = '';
            var messageParams = {};

            // Let's check if the browser supports notifications
            if (!window.Notification && !$rootScope.isSmartPhone && !$rootScope.fromIframe) {
                err = 'This browser does not support desktop notification';
                $log.debug('PushHandlerService::checkNotificationStatus ' + err);
                messageParams = {};
                messageParams.message = err;
                messageParams.message += '\nPerhaps you are in Incognito mode?';
                messageParams.disableAutoDismiss = true;
                messageParams.title = 'Approve Notifications';
                Messages.openMessage($rootScope, messageParams);
                deferred.reject(err);
            }
            // Let's check whether notification permissions have already been granted
            else if (window.Notification && window.Notification.permission === 'granted') {
                self.$storage.pushy.notificationIsGranted = true;
                deferred.resolve();
            } else {
                deferred.reject();
            }
            return deferred.promise;
        }

        self.askUserForPermission = function () {
            if (self.$storage.pushy.askedForNotificationPermission) {
                $log.info(`PushHandlerService::checkNotificationStatus already asked for permissions`);
                return;
            }
            window.Notification.requestPermission((permission) => {
                self.$storage.pushy.askedForNotificationPermission = true;
                if (permission === 'granted') {
                    self.$storage.pushy.notificationIsGranted = true;
                    $log.info(`PushHandlerService::checkNotificationStatus approved`);
                } else {
                    let error = 'Error init Pushy service, notification permission denied';
                    $log.debug(`PushHandlerService::checkNotificationStatus ${error}`);
                }

                if (permission === 'cancel') {
                    let error = 'Error init Pushy service, notification popup was dismissed, permission denied';
                    $log.debug(`ngPushy::checkNotificationStatus ${error}`);
                }
            });
        };

        self.registerPushy = function () {
            var deferred = $q.defer();
            var promises = [];

            checkNotificationStatus().then(
                function (res) {
                    promises.push(ngPushy.registerNew());

                    // For pushy to work, we need a service worker to receive the pushed notifications
                    promises.push(registerServiceWorker());

                    $q.all(promises).then(
                        function (res) {
                            // Pushy changed the remote id, delete old remote
                            if (self.$storage.pushy.remote_id && self.$storage.pushy.remote_id !== res[0]) {
                                var data = {
                                    remoteId: self.$storage.pushy.remote_id,
                                };

                                var newRemote = res[0];
                                $http.post('/users/unregisterRemoteForUser', data).then(
                                    function (res) {
                                        $log.debug(
                                            'PushHandlerService::registerPushy pushy generated new remote - ' +
                                                newRemote +
                                                ', removed old remote',
                                            data.remoteId
                                        );
                                    },
                                    function (err) {
                                        $log.debug(
                                            'PushHandlerService::registerPushy failed to unregister old remote',
                                            err
                                        );
                                    }
                                );
                            }

                            self.$storage.pushy.remote_id = res[0];
                            self.$storage.pushy.pushyIsSupported = true;
                            $log.debug('PushHandlerService::registerPushy getNotificationsFromDevice', res);
                            deferred.resolve(self.$storage.pushy.remote_id);
                        },
                        function (error) {
                            $log.debug('PushHandlerService::registerPushy getNotificationsFromDevice', error);
                            deferred.reject(error);
                        }
                    );
                },
                function (err) {
                    $log.debug('PushHandlerService::registerPushy checkNotificationStatus error', err);
                    deferred.reject(err);
                }
            );

            return deferred.promise;
        };

        function registerRemoteForUser(remoteId, deviceOrGroupId, deviceOrGroup, verificationCode) {
            if (!$rootScope.duringRemoteRegister) {
                $rootScope.duringRemoteRegister = true;
                var data = {
                    remoteId: remoteId,
                    verificationCode: verificationCode,
                };

                if (deviceOrGroupId) {
                    if (deviceOrGroup === 'group') {
                        data.groupId = deviceOrGroupId;
                    } else {
                        data.deviceId = deviceOrGroupId;
                    }
                }

                return $http.post('/users/registerRemoteForUser', data);
            } else {
                var deferred = $q.defer();
                deferred.resolve();
                return deferred.promise;
            }
        }

        this.getPushNotifications = function (deviceOrGroupId, deviceOrGroup, verificationCode) {
            var deferred = $q.defer();

            if ($rootScope.fromPlayer || $rootScope.duringPushyRegister) {
                $log.debug(
                    'PushHandlerService::getPushNotifications duringPushyRegister Or fromPlayer',
                    $rootScope.duringPushyRegister,
                    $rootScope.fromPlayer
                );
                deferred.resolve();
            }

            $rootScope.duringPushyRegister = true;
            self.registerPushy()
                .then(
                    function (res) {
                        registerRemoteForUser(
                            self.$storage.pushy.remote_id,
                            deviceOrGroupId,
                            deviceOrGroup,
                            verificationCode
                        )
                            .then(
                                function (res) {
                                    $log.debug(
                                        'PushHandlerService::getPushNotifications registerRemoteForUser Result',
                                        res
                                    );

                                    if (verificationCode) {
                                        $rootScope.$broadcast('pushy_remote_verified', self.$storage.pushy.remote_id);
                                    }

                                    // If res.data is sent - the remote is already verified
                                    // If res.text is sent (res.data.message.text) - the remote is not yet verified
                                    if (res?.data?.data && _.isEmpty(res.data.data)) {
                                        self.$storage.pushy.remoteIsVerified = true;
                                    }

                                    if (
                                        res?.data?.data?.RemoteKeepAliveIntervalInSeconds &&
                                        !angular.isDefined(self.remoteKeepaliveInterval)
                                    ) {
                                        self.remoteKeepaliveInterval = $interval(function () {
                                            $log.debug(
                                                'PushHandlerService::getPushNotifications Kepp alive interval started every ' +
                                                    res.data.data.RemoteKeepAliveIntervalInSeconds +
                                                    ' seconds',
                                                res
                                            );
                                            self.getPushNotifications(deviceOrGroupId, deviceOrGroup, verificationCode);
                                        }, res.data.data.RemoteKeepAliveIntervalInSeconds * 1000);
                                        // }, 10000);
                                    }

                                    deferred.resolve(res);
                                },
                                function (err) {
                                    $log.debug(
                                        'PushHandlerService::getPushNotifications registerRemoteForUser Error',
                                        err
                                    );
                                    deferred.reject(err);
                                }
                            )
                            .finally(function () {
                                $rootScope.duringRemoteRegister = false;
                            });
                    },
                    function (error) {
                        $log.debug('PushHandlerService::getPushNotifications Error', error);
                        deferred.reject(error);
                    }
                )
                .finally(function () {
                    $rootScope.duringPushyRegister = false;
                    $log.debug(
                        'PushHandlerService::getPushNotifications duringPushyRegister set to false',
                        $rootScope.duringPushyRegister
                    );
                });

            return deferred.promise;
        };

        this.cancelRemoteKeepaliveInterval = function () {
            if (angular.isDefined(self.remoteKeepaliveInterval)) {
                var result = $interval.cancel(self.remoteKeepaliveInterval);
                self.remoteKeepaliveInterval = undefined;
                $log.debug(
                    'PushHandlerService::cancelRemoteKeepaliveInterval remoteKeepaliveInterval was cancelled',
                    result
                );
            }
        };

        $rootScope.$on('ClearData', function () {
            // self.clearPushy();
            clearData();
        });

        $rootScope.$on('InitServices', function () {
            self.init();
        });
    },
]);
