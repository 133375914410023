angular.module('billing').controller('BillingPlansController', [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    '$window',
    '$location',
    '$sce',
    '$log',
    '$stateParams',
    'FormValidationService',
    'Messages',
    'User',
    'OpenPopupForm',
    'BillingService',
    'NIIO_URL',
    '_',
    'HostIframeSdk',
    function (
        $rootScope,
        $scope,
        $state,
        $timeout,
        $window,
        $location,
        $sce,
        $log,
        $stateParams,
        FormValidationService,
        Messages,
        User,
        OpenPopupForm,
        BillingService,
        NIIO_URL,
        _,
        HostIframeSdk
    ) {
        var self = this;
        this.currentDate = new Date();
        this.currentYear = this.currentDate.getFullYear();
        this.expireDate = '';
        this.isFetched = false;
        this.freeTrailExpireString = '';
        this.countries = FormValidationService.countryListAllIsoData;
        this.states = FormValidationService.states;
        this.BillingService = BillingService;
        this.Messages = Messages;
        this.User = User;
        this.elite = '4f9800fe-0cb2-11e8-ac68-55f1bcd80799';
        this.pro = '4f9800fd-0cb2-11e8-ac68-55f1bcd80799';
        this.prime = '4f9800f7-0cb2-11e8-ac68-55f1bcd80799';
        this.plus = '4f9800fb-0cb2-11e8-ac68-55f1bcd80799';
        this.standard = '4f9800f6-0cb2-11e8-ac68-55f1bcd80799';
        this.bespoke = '4f9800fe-0cb2-11e8-0000-55f1bcd80799';
        this.firstPlanUid = this.standard;
        this.planTypes = ['personal', 'business'];
        this.assetsBaseUrl = BillingService.assetsBaseUrl;
        this.plans = this.BillingService.plans;
        this.showFreePlan = false;

        $scope.$watch(
            'vm.selectedPlan',
            (newVal) => {
                if (!newVal) return;
                if (newVal === $stateParams.clickedPlan) return;
                $stateParams.clickedPlan = newVal;
                self.replaceParams();
            },
            true
        );

        $scope.$watch(
            'vm.selectedPaymentCycle',
            (newVal) => {
                if (!newVal || !newVal.id) return;
                if (newVal.id === $stateParams.clickedCycle) return;
                $stateParams.clickedCycle = newVal.id;
                self.replaceParams();
            },
            true
        );

        this.replaceParams = function (params) {
            $stateParams = params || $stateParams;
            Object.entries($stateParams).forEach(([key, val]) => {
                if (!val) return;
                $location.search(key, val);
            });
        };

        this.goToPlanSubscribe = function (selectedPlan, selectedPlanPaymentCycleId) {
            $stateParams.clickedPlan = selectedPlan.uid;
            $stateParams.clickedCycle = selectedPlanPaymentCycleId;

            if (BillingService.isFreePlan(selectedPlan.uid)) {
                if (self.showFreePlan) {
                    this.Messages.cancel();
                    return;
                }

                BillingService.promptCancelSubscription();
                return;
            }

            if (selectedPlan.uid === self.bespoke) {
                OpenPopupForm.openMenu('niio-sdk', {
                    type: 'modal',
                    url: selectedPlan.link,
                }).then(() => {
                    Messages.ok();
                    if (self.fromModal) {
                        $state.go('home-page');
                    }
                });
            }

            // Navigate to plan subscription page
            if (self.fromModal && selectedPlan.uid !== self.bespoke) {
                Messages.ok();
                OpenPopupForm.openMenu('plan-subscribe', {
                    planName: selectedPlan.options[selectedPlanPaymentCycleId].provider_plan_id,
                    fromModal: self.fromModal,
                    cc: $stateParams.cc,
                }).then((res) => {
                    if (res === 'cancel') {
                        $timeout(() => {
                            $rootScope.$emit('user_closed_plan_subscribe');
                        }, 0);
                    }
                });
            } else if (selectedPlan.uid !== self.bespoke) {
                $state.go('plan-subscribe', {
                    planName: selectedPlan.options[selectedPlanPaymentCycleId].provider_plan_id,
                    cc: $stateParams.cc,
                });
            }
        };

        this.init = () => {
            if ($stateParams.o === 'j') {
                $stateParams.source = 'plans';
                $stateParams.service = 'plans';
                self.fromModalAfterJoin = true;

                OpenPopupForm.openMenu('join', $stateParams).then(() => {
                    $stateParams.o = '';
                    self.fromModalAfterJoin = false;
                    $state.go('plans', $stateParams);
                });
            }

            self.fromModal = self.params?.fromModal;
            self.fromJoin = self.params?.fromJoin;
            self.fromIframe = $stateParams.fromIframe;
            self.showFreePlan =
                !$stateParams.fromIframe && self.fromModal && (self.fromJoin || !User.isAuthenticated());

            var xxlModal = document.getElementsByClassName('modal-xxl');
            if (xxlModal && xxlModal[0]) {
                xxlModal[0].style = 'min-height: 100%';
            }

            self.planType = User.getPlanType() || 'personal';
            self.currentPlan = User.getPlan();
            self.search = {
                type: self.planType,
            };

            BillingService.getSubscriptionPlans().then(
                (dbPlans) => {
                    self.isFetched = true;
                    self.selectedPaymentCycle = self.BillingService.billingTypes[1];
                    const currentPlan = _.find(dbPlans, (plan) => {
                        return plan.uid === self.currentPlan;
                    });
                    if (currentPlan && !User.isFreeUser()) currentPlan.actionButton = 'Your plan';
                    if (User.isFreeUser() && BillingService.isFreePlan(currentPlan.uid))
                        currentPlan.actionButton = 'Continue as Free User';

                    const activePlan = !self.User.isFreeUser() && self.User.getPlan();
                    const activePlanIndex = self.plans.findIndex((i) => i.uid === activePlan);
                    if (activePlanIndex !== -1) {
                        self.plans.forEach((plan, currentPlanIndex) => {
                            const isDowngrade = activePlanIndex > currentPlanIndex;
                            if (activePlanIndex === currentPlanIndex) plan.actionButton = 'Your plan';
                            else plan.actionButton = isDowngrade ? 'Downgrade' : 'Upgrade';

                            if (BillingService.isFreePlan(plan.uid) && !self.User.isFreeUser()) {
                                plan.actionButton = 'Downgrade to free';
                            }

                            if (plan.display_name === 'Bespoke') {
                                plan.actionButton = 'Contact us';
                            }
                        });
                    }

                    if ($stateParams.clickedPlan && $stateParams.clickedCycle) {
                        self.selectedPlanData = self.BillingService.plans.find(
                            (plan) => plan.uid === $stateParams.clickedPlan
                        );
                        self.tabSelected(self.search.type);
                        self.selectedPlan = $stateParams.clickedPlan;
                        self.selectedPaymentCycle = self.BillingService.billingTypes.find(
                            (item) => item.id === $stateParams.clickedCycle
                        );
                    } else {
                        const plansTypes = Object.keys(BillingService.plansExpanded);
                        const currentTypeKey = plansTypes.indexOf(self.planType);

                        const lastPlanInType = BillingService.plansExpanded[plansTypes[currentTypeKey]].slice(-1)[0];
                        if (lastPlanInType?.uid === self.currentPlan) {
                            self.planType = plansTypes[currentTypeKey + 1];
                        }
                        self.tabSelected(self.planType || self.search.type);
                    }
                },
                (err) => {
                    $log.debug('Something went wrong...', err);
                    const messageParams = {};
                    messageParams.message = 'Something went wrong.';
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        this.selectBillingPlan = function (type) {
            self.selectedPaymentCycle = self.BillingService.billingTypes.find((item) => item.id === type.id);
        };

        this.toggleShowMore = function (plan) {
            plan.showMore = !plan.showMore;
        };

        this.tabSelected = function (type, force = false) {
            if (force) {
                return (self.search.type = type);
            }

            if (self.showFreePlan) {
                self.search.type = type;
                return (self.selectedPlan = BillingService.freePlanId);
            }

            for (const nextUpgradablePlan of BillingService.plansExpanded[type]) {
                if (nextUpgradablePlan && nextUpgradablePlan.upgradable) {
                    self.selectedPlan = nextUpgradablePlan.uid;
                    self.search.type = nextUpgradablePlan.type;
                    break;
                }
            }
        };

        $rootScope.$on('continue_payment', () => {
            var userPlan = _.find(self.plans, (plan) => {
                return plan.uid === self.selectedPlan;
            });
            self.goToPlanSubscribe(userPlan, self.selectedPaymentCycle.id);
        });
    },
]);
