'use strict';

angular.module('core').controller('DownloadAppBannerController', [
    '$scope',
    '$state',
    function ($scope, $state) {
        $scope.bannerDismissed = false;

        $scope.close = function () {
            $scope.bannerDismissed = true;
        };
    },
]);
