'use strict';

angular.module('form').directive('niioRadio', [
    // Structure for items: [{name: ..., value: ..., disabled: true / false}, {name: ..., value: ..., disabled: true / false}]
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                disabledRadios: '=',
                model: '=',
                items: '=',
                size: '=?',
                id: '=?',
                name: '=?',
                disabledOptionText: '=?',
                onSelect: '=?',
                responsive: '=true',
            },
            templateUrl: 'modules/form/views/form.radio.client.view.html',
            controller: 'FormRadioController',
            controllerAs: 'vm',
            bindToController: true, //required in 1.3+ with controllerAs
        };
    },
]);
