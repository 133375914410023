'use strict';

// Init the application configuration module for AngularJS application
window.ApplicationConfiguration = (function () {
    // Init module configuration options
    var applicationModuleName = 'niio';
    //TODO: delete. var applicationModuleVendorDependencies = ['ngResource', 'ngCookies', 'ngStorage', 'ngAnimate', 'ngMessages', 'ui.router', 'ui.bootstrap', 'ui.validate', 'consts', 'pascalprecht.translate', 'underscore', 'colorpicker.module', 'ngTagsInput', 'ja.qr', 'angulartics', 'angulartics.google.analytics', 'ct.ui.router.extras', 'FBAngular', 'smoothScroll', 'angular-filepicker', 'ngCsv', 'as.sortable', 'directive.g+signin', 'satellizer', 'ngSanitize', 'angular-input-stars', 'angular-jwt'];
    var applicationModuleVendorDependencies = [
        'ngResource',
        'ngCookies',
        'ngStorage',
        'ngAnimate',
        'ngMessages',
        'ui.router',
        'ui.bootstrap',
        'ui.validate',
        'consts',
        'pascalprecht.translate',
        'underscore',
        'colorpicker.module',
        'ngTagsInput',
        'ja.qr',
        'angulartics',
        'angulartics.google.analytics',
        'FBAngular',
        'smoothScroll',
        /* 'angular-filepicker', */ 'ngCsv',
        'as.sortable',
        'directive.g+signin',
        'satellizer',
        'ngSanitize',
        'angular-input-stars',
        'angular-jwt',
        'btorfs.multiselect',
    ];

    // Add a new vertical module
    var registerModule = function (moduleName) {
        // Create angular module
        angular.module(moduleName, []);

        // Add the module to the AngularJS configuration file
        angular.module(applicationModuleName).requires.push(moduleName);
    };

    return {
        applicationModuleName: applicationModuleName,
        applicationModuleVendorDependencies: applicationModuleVendorDependencies,
        registerModule: registerModule,
    };
})();

//Start by defining the main module and adding the module dependencies
angular.module(
    ApplicationConfiguration.applicationModuleName,
    ApplicationConfiguration.applicationModuleVendorDependencies
);

// Setting HTML5 Location Mode

/*TODO: delete angular.module(ApplicationConfiguration.applicationModuleName).config(['$provide', '$httpProvider', '$locationProvider', '$translateProvider', '$logProvider', '$compileProvider', 'filepickerProvider', 'NIIO_DEBUG', 'FILESTACK_API',  '$authProvider', 'GOOGLE_ID', 'FB_ID',
    function($provide, $httpProvider, $locationProvider, $translateProvider, $logProvider, $compileProvider, filepickerProvider, NIIO_DEBUG, FILESTACK_API,  $authProvider, GOOGLE_ID, FB_ID) {
*/
angular.module(ApplicationConfiguration.applicationModuleName).config([
    '$provide',
    '$httpProvider',
    '$locationProvider',
    '$translateProvider',
    '$logProvider',
    '$compileProvider',
    'tagsInputConfigProvider',
    'NIIO_DEBUG',
    '$authProvider',
    'GOOGLE_ID',
    'FB_ID',
    'ENV',
    function (
        $provide,
        $httpProvider,
        $locationProvider,
        $translateProvider,
        $logProvider,
        $compileProvider,
        tagsInputConfigProvider,
        NIIO_DEBUG,
        $authProvider,
        GOOGLE_ID,
        FB_ID,
        ENV
    ) {
        $locationProvider
            .html5Mode({
                enabled: true,
                requireBase: false,
            })
            .hashPrefix('!');
        //$locationProvider.hashPrefix('!');

        // Remove debugging mode in Angular
        $compileProvider.debugInfoEnabled(NIIO_DEBUG);
        $logProvider.debugEnabled(NIIO_DEBUG);

        // add translation tables
        // $translateProvider.useSanitizeValueStrategy('sanitize');
        $translateProvider.useSanitizeValueStrategy('escaped'); // To avoid translation of & into &amp;
        $translateProvider.translations('en', translationsEN);
        $translateProvider.translations('he', translationsHE);
        $translateProvider.preferredLanguage('en');
        $translateProvider.fallbackLanguage('en');

        // push authentication interceptors to handle 401 Auth:Fail
        $httpProvider.interceptors.push('PendingRequestsInterceptor');
        $httpProvider.interceptors.push('AuthInterceptor');

        $httpProvider.defaults.withCredentials = true;

        // Add timestamp to $log messages
        $provide.decorator('$log', [
            '$delegate',
            '$filter',
            function ($delegate, $filter) {
                // Keep track of the original debug method, we'll need it later.
                var origDebug = $delegate.debug;
                /*
                 * Intercept the call to $log.debug() so we can add on
                 * our enhancement. We're going to add on a date and
                 * time stamp to the message that will be logged.
                 */
                $delegate.debug = function () {
                    var args = [].slice.call(arguments);
                    var formattedDate = $filter('date')(new Date(), 'MM/dd/yy HH:mm:ss.sss');
                    args.unshift(formattedDate + ': ');

                    // Send on our enhanced message to the original debug method.
                    origDebug.apply(null, args);
                };

                return $delegate;
            },
        ]);

        //External Login (satellizer)
        // Google
        $authProvider.google({
            baseUrl: null,
            clientId: GOOGLE_ID,
            url: null,
            authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth',
            redirectUri:
                ENV === 'prod'
                    ? window.location.origin + '/Server/users/ExternalAuthRedirect'
                    : 'https://google-login-auth.niio.dev/',
            requiredUrlParams: ['scope', 'state'],
            optionalUrlParams: ['display'],
            scope: ['profile', 'email'],
            scopePrefix: 'openid',
            scopeDelimiter: ' ',
            state: window.location.host,
            display: 'popup',
            type: '2.0',
            popupOptions: { width: 452, height: 633 },
        });

        $authProvider.facebook({
            clientId: FB_ID,
            name: 'facebook',
            url: null,
            redirectUri:
                ENV === 'prod'
                    ? window.location.origin + '/Server/users/ExternalAuthRedirect'
                    : 'https://fb-login-auth.niio.dev/',
            authorizationEndpoint: 'https://www.facebook.com/v2.5/dialog/oauth',
            requiredUrlParams: ['display', 'scope', 'state'],
            scope: ['email', 'public_profile'],
            scopeDelimiter: ',',
            state: window.location.host,
            display: 'popup',
            type: '2.0',
            popupOptions: { width: 580, height: 400 },
        });

        // on production, remove log messages
        $logProvider.debugEnabled(NIIO_DEBUG);

        //      *** Transform Angularjs request to JQuery request -
        //      *** PHP doesn't support application/json requests

        // Use x-www-form-urlencoded Content-Type
        $httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        //        $httpProvider.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
        //        $httpProvider.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';

        /**
         * The workhorse; converts an object to x-www-form-urlencoded serialization.
         * @param {Object} obj
         * @return {String}
         */
        var param = function (obj) {
            var query = '',
                name,
                value,
                fullSubName,
                subName,
                subValue,
                innerObj,
                i;

            for (name in obj) {
                value = obj[name];

                if (value instanceof Array) {
                    for (i = 0; i < value.length; ++i) {
                        subValue = value[i];
                        fullSubName = name + '[' + i + ']';
                        innerObj = {};
                        innerObj[fullSubName] = subValue;
                        query += param(innerObj) + '&';
                    }
                } else if (value instanceof Object) {
                    for (subName in value) {
                        subValue = value[subName];
                        fullSubName = name + '[' + subName + ']';
                        innerObj = {};
                        innerObj[fullSubName] = subValue;
                        query += param(innerObj) + '&';
                    }
                } else if (value !== undefined && value !== null)
                    query += encodeURIComponent(name) + '=' + encodeURIComponent(value) + '&';
            }

            return query.length ? query.substr(0, query.length - 1) : query;
        };

        // Override $http service's default transformRequest
        $httpProvider.defaults.transformRequest = [
            function (data) {
                return angular.isObject(data) && String(data) !== '[object File]' ? param(data) : data;
            },
        ];

        tagsInputConfigProvider
            .setDefaults('tagsInput', {
                placeholder: '(e.g. calming, blue, flying people)',
                minLength: 2,
                maxTags: 30,
                addOnEnter: true,
                addOnBlur: true,
                addOnPaste: true,
                replaceSpacesWithDashes: false,
            })
            .setDefaults('autoComplete', {
                minLength: 2,
                debounceDelay: 200,
                selectFirstMatch: false,
                loadOnDownArrow: true,
                loadOnEmpty: true,
            });
    },
]);

//Then define the init function for starting up the application
angular.element(document).ready(function () {
    //Fixing facebook bug with redirect
    if (window.location.hash === '#_=_') window.location.hash = '#!';

    //Then init the app
    angular.bootstrap(document, [ApplicationConfiguration.applicationModuleName], {
        strictDi: true,
    });
});
