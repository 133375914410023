angular
    .module('core')
    .service('UploadsService', [
        '$rootScope',
        '$window',
        '$state',
        '$stateParams',
        '$log',
        '_',
        'Messages',
        'FormValidationService',
        'OpenPopupForm',
        'HostIframeSdk',
        function (
            $rootScope,
            $window,
            $state,
            $stateParams,
            $log,
            _,
            Messages,
            FormValidationService,
            OpenPopupForm,
            HostIframeSdk
        ) {
            const maxFileSizeFilestack = 100 * 1024 * 1024 * 1024 * 2.5; // 250 GB for dev / pre AND
            const isFromMobileUpload =
                $state.current.name === 'home.mobileUpload' || $state.current.name === 'mobileUploadOpenUppy';
            let uploadFormObj = {};

            this.uploadFromCloud = (uploadObj, openSpecificProvider = '', params) => {
                uploadFormObj = uploadObj;
                if (isFromMobileUpload) {
                    if (!this.openMobileUploadInBrowser()) {
                        // Backward compatibility for older versions of the app
                    }
                }

                this.uploadUsingUppy(openSpecificProvider, params);
            };

            HostIframeSdk.hostSdk.addEventListener('uploadFromCloud', ({ address }) => {
                this.uploadFromCloud({}, 'IPFS', { address });
            });

            this.openMobileUploadInBrowser = () => {
                if (typeof window.openInBrowser !== 'function') {
                    return false;
                }

                let urlToOpen = new URL(`${location.origin}/mobile_upload_cloud${location.search}`);
                urlToOpen.searchParams.set('token', TokenService.getToken());
                urlToOpen.searchParams.set('publicToken', TokenService.getOrCreatePublicToken());
                urlToOpen.searchParams.set('returnToApp', 'true');
                window.openInBrowser(urlToOpen.href);
                return true;
            };

            const redirectBackHome = () => {
                var homeOrProfile = $scope.homeOrUrl === 'home' ? 'home' : 'profile';
                var parameters;
                if ($scope.params && $scope.params.type === 'modal') {
                    Messages.ok();
                    $rootScope.$broadcast('batch_files_upload_started');
                } else if ($stateParams.eventId && $stateParams.eventId > 0) {
                    parameters = { eventId: $stateParams.eventId };
                    if ($scope.homeOrUrl) {
                        parameters.url = $scope.homeOrUrl;
                    }
                    $state.go(`${homeOrProfile}.events.submission.artworks`, parameters);
                } else if (window.history && window.history.length > 2) {
                    window.history.back();
                } else if ($stateParams.collectionId && $stateParams.collectionLink) {
                    parameters = {
                        collectionId: $stateParams.collectionId,
                        collectionLink: $stateParams.collectionLink,
                        filterArtist: $stateParams.filterArtist,
                    };
                    if ($scope.homeOrUrl) {
                        parameters.url = $scope.homeOrUrl;
                    }
                    $state.go(`${homeOrProfile}.${$scope.from}.main`, parameters);
                } else {
                    parameters = { collectionId: 0, collectionLink: 'all' };
                    if ($scope.homeOrUrl) {
                        parameters.url = $scope.homeOrUrl;
                    }
                    if ($stateParams.filterArtist !== undefined) {
                        parameters.filterArtist = $stateParams.filterArtist;
                    }
                    $state.go(`${homeOrProfile}.${$scope.from}.main`, parameters);
                }
            };

            function onFilestackStoreSuccess(modifiedFile) {
                var useDefaultForm = true;
                var onlyProcess = true;

                if (modifiedFile) {
                    $rootScope.$broadcast('cloud_uploads_file_finished', modifiedFile);

                    modifiedFile.fromCloud = true;
                    modifiedFile.originalKey = modifiedFile.key;
                    modifiedFile.key = null;

                    sendFormToServer(
                        modifiedFile,
                        /* preview file */ null,
                        /* coverFile */ null,
                        /* additional files */ null,
                        useDefaultForm,
                        onlyProcess,
                        true
                    ).finally(() => {
                        var foundFileIndex = _.findIndex($rootScope.cloudFiles, (cloudFile) => {
                            return cloudFile.filename === modifiedFile.filename;
                        });

                        if (foundFileIndex >= 0) {
                            $rootScope.cloudFiles.splice(foundFileIndex, 1);
                        }

                        if ($rootScope.cloudFiles.length === 0) {
                            $log.debug('HomeUploadController::onFilestackSuccess multiple files process began');

                            if ($scope.isFromMobileUpload) {
                                if (
                                    window.filestackUploadsFinished &&
                                    typeof window.filestackUploadsFinished === 'function'
                                ) {
                                    window.filestackUploadsFinished();
                                }
                            } else {
                                Messages.ok();
                                redirectBackHome();
                                // $rootScope.$broadcast('ajax-stop');
                                $rootScope.$broadcast('cloud_uploads_all_finished');
                            }
                        }
                    });
                }
            }

            function generateFilename(filename) {
                var selectedFileExtension = filename.split('.').pop();
                return `${new Date().getTime()}-${S3Uploader.randomString(16)}.${selectedFileExtension}`;
            }

            function setNewFilenameToFile(file, fromCloud) {
                var fileName = file ? file.name || file.filename : uploadFormObj.filename;
                file.original = fileName;
                if (!fromCloud) {
                    file.filename = generateFilename(fileName);
                }
            }

            function setNewFilenameToFiles(files, fromCloud) {
                _.each(files, (file) => {
                    if (file.filename || file.name) {
                        setNewFilenameToFile(file, fromCloud);
                    } else if (file.file && file.file[0] && (file.file[0].filename || file.file[0].name)) {
                        setNewFilenameToFile(file.file[0], fromCloud);
                    } else {
                        $log.debug('HomeUploadController::setNewFilenameToFiles file not found', file);
                        var messageParams = {};
                        messageParams.title = 'Error uploading artwork';
                        messageParams.message = 'Failed to rename artwork. Please refresh the page and try again.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                });
            }

            // Uppy
            this.uploadUsingUppy = (openSpecificProvider = '', params) => {
                let providers = ['GoogleDrive', 'Dropbox', 'OneDrive', 'IPFS', 'Url', 'OpenSea'];
                let artworkId = uploadFormObj.id;
                let cryptoAddress = params?.address ? params?.address : '';
                const openSpecificQs = openSpecificProvider ? `&open=${openSpecificProvider}` : '';

                if (window.navigator.onLine) {
                    HostIframeSdk.openPortal(
                        `/micro-fe/uppyUploader?providers=${providers.join(',')}&artworkId=${artworkId}&artworkType=${
                            uploadFormObj.artworkType
                        }${openSpecificQs}&cryptoAddress=${cryptoAddress}`,
                        { hideHeader: false }
                    );

                    if ($stateParams.returnToApp) {
                        $scope.$on('closePortal', (event, data) => {
                            // Return to app when all files started to progress
                            $scope.returnToAppLink = data.returnLink;
                            const newWin = window.open($scope.returnToAppLink); // Open new tab with deeplink that will direct to the app
                            if (newWin) {
                                newWin.addEventListener('load', () => {
                                    newWin.focus();
                                    newWin.close();
                                });
                                $state.go('home');
                            } else {
                                location.href = $scope.returnToAppLink;
                            }

                            setTimeout(() => {
                                window.close(); // probably won't work... try Close current tab
                            }, 5000);
                        });
                    }
                }
            };
        },
    ])
    .run([
        'UploadsService',
        (UploadsService) => {
            console.log('UploadsService loaded', UploadsService);
        },
    ]);
