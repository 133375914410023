'use strict';
/*global angular*/

angular.module('core').controller('FooterController', [
    '$scope',
    '$rootScope',
    '$state',
    '$localStorage',
    '$timeout',
    '$http',
    'OpenPopupForm',
    'PasswordProtectLink',
    'Security',
    'NIIO_COMMIT_MESSAGE',
    'NIIO_URL',
    'ENV',
    'SERVER',
    function (
        $scope,
        $rootScope,
        $state,
        $localStorage,
        $timeout,
        $http,
        OpenPopupForm,
        PasswordProtectLink,
        Security,
        NIIO_COMMIT_MESSAGE,
        NIIO_URL,
        ENV,
        SERVER
    ) {
        $scope.currentYear = new Date().getFullYear();
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.PasswordProtectLink = PasswordProtectLink;
        $scope.SERVER = SERVER;
        $localStorage.modifiedServer = $localStorage.modifiedServer || null;
        $scope.$localStorage = $localStorage;
        $scope.toggles = {};
        $scope.toggles.chooseServerOpen = false;
        var dbclickCount = 0;
        var timeLastClicked = null;

        $scope.showVersionText = function () {
            $scope.showVersion = ' / ' + $rootScope.ENV + ' - ' + $rootScope.NIIO_VERSION;
            if ($rootScope.ENV !== 'prod') {
                if ($localStorage.modifiedServer) {
                    $scope.showVersion +=
                        ' MODIFIED SERVER: ' +
                        $localStorage.modifiedServer.name +
                        ' (' +
                        $localStorage.modifiedServer.url +
                        ') / ';
                }
                $scope.showVersion +=
                    ' (' +
                    $rootScope.NIIO_BRANCH +
                    ' - ' +
                    $rootScope.NIIO_BRANCH_COMMITER +
                    '): ' +
                    NIIO_COMMIT_MESSAGE;
            }
        };

        $scope.hideVersion = function () {
            $scope.showVersion = '';
        };

        $scope.serverOptions = [
            { name: 'Reset', env: 'reset' },
            { name: 'Prod', env: 'prod', url: 'https://www.niio.com/' },
        ];

        function getBranches() {
            $http.get(`${NIIO_URL}/Server/app/getEndpoints`).then((res) => {
                if (res && res.data && res.data.endpoints.length > 0) {
                    const endpoints = res.data.endpoints;
                    endpoints.forEach((branch) => {
                        $scope.serverOptions.push({ name: branch.branch, url: branch.url, env: branch.env });
                    });
                }
            });
        }
        if ($rootScope.ENV !== 'prod') {
            getBranches();
        }

        $rootScope.$on('ClearData', () => {
            getBranches();
        });

        $scope.checkServerConst = function () {
            if (ENV === 'prod') {
                return;
            }
            if (timeLastClicked) {
                var timeClickedNow = Date.now();
                if (Math.floor((timeClickedNow - timeLastClicked) / 1000) < 5) {
                    timeLastClicked = timeClickedNow;
                    dbclickCount++;
                    console.log('increase: ' + dbclickCount);
                } else {
                    timeLastClicked = timeClickedNow;
                    dbclickCount = 1;
                    $scope.toggles.chooseServerOpen = false;
                    console.log('reset: ' + dbclickCount);
                }
            } else {
                timeLastClicked = Date.now();
                dbclickCount = 1;
                $scope.toggles.chooseServerOpen = false;
                console.log('reset: ' + dbclickCount);
            }

            if (dbclickCount > 2) {
                console.log('Appear: ' + dbclickCount);
                $scope.toggles.chooseServerOpen = true;
            }
        };

        $scope.serverChoiceChanged = function (branch) {
            if ($rootScope.$user.exists()) {
                Security.logout(function (res) {
                    $state.go('home-page');
                });
            }

            $timeout(() => {
                $localStorage.modifiedServer = branch;

                if (branch.env === 'reset' || branch.env === 'local') {
                    SERVER.address = `${NIIO_URL}Server`;
                    $localStorage.modifiedServer = null;
                } else {
                    SERVER.address = branch.url + 'Server';
                    if (`${NIIO_URL}Server` === branch.url) {
                        $localStorage.modifiedServer = null;
                    }
                }
                $scope.toggles.chooseServerOpen = false;
            }, 100);
        };
    },
]);
