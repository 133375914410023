'use strict';

angular.module('core').controller('HeaderController', [
    '$rootScope',
    '$log',
    '$scope',
    '$state',
    '$q',
    '$timeout',
    '$translate',
    '$localStorage',
    '$location',
    'Security',
    'Search',
    'Messages',
    'S3Uploader',
    'OpenPopupForm',
    'PasswordProtectLink',
    'NIIO_URL',
    '$http',
    'User',
    'HomeDataService',
    'DiscoverDataService',
    'HostIframeSdk',
    function (
        $rootScope,
        $log,
        $scope,
        $state,
        $q,
        $timeout,
        $translate,
        $localStorage,
        $location,
        Security,
        Search,
        Messages,
        S3Uploader,
        OpenPopupForm,
        PasswordProtectLink,
        NIIO_URL,
        $http,
        User,
        HomeDataService,
        DiscoverDataService,
        HostIframeSdk
    ) {
        $scope.$state = $state;
        $scope.$localStorage = $localStorage;
        $scope.Search = Search;
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.PasswordProtectLink = PasswordProtectLink;
        $scope.NIIO_URL = NIIO_URL;

        function signOutCall() {
            Security.logout(
                function (res) {
                    $log.debug('HeaderController::signOut Success', res);
                    $state.go('home-page');
                },
                function (error) {
                    $log.debug('HeaderController::signOut Failure', error);
                    var messageParams = {};
                    messageParams.message = 'Failed to sign out. Please refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    messageParams.title = 'Sign out';
                    Messages.openMessage($scope, messageParams);
                }
            );
        }
        $scope.$on('ArtworkInfoArtworkController::search_tag', function (event, tag) {
            $scope.searchIt(tag);
        });
        $scope.signOut = function () {
            if (S3Uploader.isUploading()) {
                var messageParams = {};
                messageParams.message =
                    'Attention! There are some active artwork uploads in the website.\nIf you exit ' +
                    $rootScope.$platformManager.platform.capital +
                    ' all uploads will be canceled.\nAre you sure you want to go out?';
                messageParams.title = 'Active Uploads';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Yes';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        signOutCall();
                    }
                });
            } else {
                signOutCall();
            }
        };

        // Search
        $scope.$watch('navbarSearch.query', function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                // Update the query parameter
                $location.search('query', newVal);
            }
        });

        $scope.searchResults = function (query, limit) {
            var deferred = $q.defer();

            Search.searchAll(query, limit, true).then(function (res) {
                if (res.length > 0) {
                    var showAllResults = {
                        value: query,
                        type: 'show all results for',
                        state: 'search.main',
                        params: { sidebarSelection: 'all', query: query },
                    };
                    res.unshift(showAllResults);
                }

                deferred.resolve(res);
            });

            return deferred.promise;
        };

        $scope.searchResultsSelected = function (item, model, label) {
            if (item.type !== 'separator') {
                $state.go(item.state, item.params);
            }

            model = '';
        };

        $scope.searchIt = function (query) {
            $state.go('search.main', { sidebarSelection: 'all', query: query });
        };

        $scope.setLanguage = function (lang, direction) {
            $translate.use(lang);
            $rootScope.rtl = direction === 'rtl';
        };

        $scope.isDiscover = function () {
            return (
                $state.current.name.indexOf('discover.') > -1 ||
                ($state.current.name.indexOf('profile.') > -1 && User.isOtherFunc())
            );
        };

        $scope.languages = [
            {
                name: 'עברית',
                value: 'he',
                icon: './img/flags/il.png',
                direction: 'rtl',
            },
            {
                name: 'English',
                value: 'en',
                icon: './img/flags/us.png',
                direction: 'ltr',
            },
        ];

        $scope.waitSeconds = function (sec) {
            $scope.waited = false;
            sec = sec || 20000;
            $timeout(function () {
                $scope.waited = true;
            }, sec);
        };

        $scope.openWalletConnection = function () {
            let url = '/wallets-login/walletsManagementModal';
            HostIframeSdk.openPortal(url, { size: 'wallet', hideHeader: true });
        };
    },
]);
