'use strict';

angular.module('core').directive('httpPrefix', [
    function () {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, controller) {
                function ensureHttpPrefix(value) {
                    if (!value) return value;
                    if ('http://'.indexOf(value) === 0 || 'https://'.indexOf(value) === 0) return value; // value: 'ht'
                    if (value.indexOf('http://') === 0 || value.indexOf('https://') === 0) return value; // value: 'http://x'

                    controller.$setViewValue('http://' + value);
                    controller.$render();
                    return 'http://' + value;
                }
                controller.$formatters.push(ensureHttpPrefix);
                controller.$parsers.push(ensureHttpPrefix);
            },
        };
    },
]);
