'use strict';

angular.module('core').service('NetworkStatus', [
    '$window',
    function ($window) {
        var self = this;
        this.isOnline = $window.navigator.onLine; // Doesn't work on FF and IE

        this.init = function () {
            $window.addEventListener(
                'offline',
                function () {
                    self.isOnline = false;
                },
                false
            );

            $window.addEventListener(
                'online',
                function () {
                    self.isOnline = true;
                },
                false
            );
        };
    },
]);
