'use strict';

angular.module('admin-area').controller('AdminAreaStatusesUsersController', [
    '$rootScope',
    '$http',
    '$log',
    '$q',
    '$filter',
    'Messages',
    '_',
    'USER_TITLES',
    'ORGANIZATION_TYPES',
    'NIIO_URL',
    function ($rootScope, $http, $log, $q, $filter, Messages, _, USER_TITLES, ORGANIZATION_TYPES, NIIO_URL) {
        var self = this;
        this.newRerun = this.newRerun || {};
        this.report = this.report || {};
        this.toggles = this.toggles || {};

        // Grid
        this.orderByField = 'id';
        this.reverseSort = true;

        function attachTotalsToEachUser(data) {
            var users = data.users;
            if (data.users && data.users.length > 0) {
                users = _.map(data.users, function (user) {
                    var foundItem;
                    // Totals
                    foundItem = _.find(data.totals, function (item) {
                        return item.user_id === user.id;
                    });
                    user = _.extend(user, foundItem);

                    // TotalBytes
                    foundItem = _.find(data.totalBytes, function (item) {
                        return item.user_id === user.id;
                    });
                    user = _.extend(user, foundItem);

                    // TotalEditioned
                    foundItem = _.find(data.totalEditioned, function (item) {
                        return item.user_id === user.id;
                    });
                    user = _.extend(user, foundItem);

                    // TotalAdditionalFiles
                    foundItem = _.find(data.totalAdditionalFiles, function (item) {
                        return item.user_id === user.id;
                    });
                    user = _.extend(user, foundItem);

                    // Join Codes
                    foundItem = _.find(data.joinCodes, function (item) {
                        return item.user_id === user.id;
                    });
                    user = _.extend(user, foundItem);

                    // Publishes
                    foundItem = _.find(data.publishes, function (item) {
                        return item.user_id === user.id;
                    });
                    user = _.extend(user, foundItem);

                    return user;
                });
            }
            return users;
        }

        function getUsersReport() {
            var deferred = $q.defer(),
                data = {
                    period_days: self.toggles.period_days,
                };

            $http.post('/users/getUsersReport', data).then(
                function (res) {
                    $log.debug(
                        'AdminAreaServicesUsersController::getUsersReport successfully retrieved users report',
                        res
                    );
                    var report = attachTotalsToEachUser(res.data.data);
                    if (report && report.length === 0) {
                        var messageParams = {};
                        messageParams.message = 'Empty report';
                        messageParams.title = 'Export Users';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                    deferred.resolve(report);
                },
                function (error) {
                    $log.debug(
                        'AdminAreaServicesUsersController::getUsersReport failed to retrieve users report',
                        error
                    );
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        this.getHeaderForUsersCSV = function () {
            return [
                'ID',
                'Display',
                'First Name',
                'Last Name',
                'Email',
                'Country',
                'City',
                'Address',
                'Title',
                'Organization Type',
                'Organization Other',
                'Url',
                'Total Size',
                'Total Editioned Artworks',
                'Total Editions',
                'Total Money EUR',
                'Total Money GBP',
                'Total Money USD',
                'Total Artworks',
                'Total Additional Files',
                'Number Of Transfers',
                'Join Code',
                'Join Code Destination Email',
                'Email Verified',
                'Official Account',
                'Last Modified',
                'Created',
            ];
        };

        this.exportUsersCSV = function () {
            var deferred = $q.defer();
            self.submittingUsersReport = true;
            getUsersReport()
                .then(function (res) {
                    if (res && res.length > 0) {
                        var array = [];
                        res.forEach(function (row) {
                            array.push({
                                a: row.id,
                                b: row.display_name,
                                c: row.first_name,
                                d: row.last_name,
                                e: row.email,
                                f: row.country,
                                g: row.city,
                                h: row.address,
                                i: row.titles[0] ? USER_TITLES[row.titles[0]] : '',
                                j: row.organization_type ? ORGANIZATION_TYPES[row.organization_type] : '',
                                k: row.organization_type_other || '',
                                l: NIIO_URL + '/' + row.url,
                                m: row.totalBytes ? $filter('bytes')(row.totalBytes, 4) : '',
                                n: row.totalEditionedArtworks || '',
                                o: row.totalEditions || '',
                                p: row.totalMoneyEUR || '',
                                q: row.totalMoneyGBP || '',
                                r: row.totalMoneyUSD || '',
                                s: row.totalArtworks || '',
                                t: row.totalAdditionalFiles || '',
                                u: row.num_of_publishes || '',
                                v: row.code || '',
                                w: row.code_destination_email || '',
                                x: row.verified,
                                y: row.official_account,
                                z: row.modified || '',
                                aa: row.created || '',
                            });
                        });
                        deferred.resolve(array);
                    }
                })
                .finally(function (res) {
                    self.submittingUsersReport = false;
                });
            return deferred.promise;
        };

        this.init = function () {
            self.toggles.periodDays = '';
            self.formattedToday = $filter('date')(new Date(), 'dd/MM/yyyy');
        };
    },
]);
