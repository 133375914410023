'use strict';

angular.module('devices').controller('DevicesSelectedController', [
    '$log',
    '$scope',
    '$state',
    '$stateParams',
    '$q',
    'DevicesDataService',
    'PushHandlerService',
    'Messages',
    'User',
    'OpenPopupForm',
    '_',
    'PLAYER_TYPES',
    function (
        $log,
        $scope,
        $state,
        $stateParams,
        $q,
        DevicesDataService,
        PushHandlerService,
        Messages,
        User,
        OpenPopupForm,
        _,
        PLAYER_TYPES
    ) {
        $scope.isAdmin = User.isAdmin();
        $scope.isProCurator = User.isProCurator();
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.deviceOrGroup = $state.current.data.deviceOrGroup;
        $scope.selectedId = $stateParams.id;
        $scope.channelsFromSchedules = [];
        $scope.channelsForSchedules = [];
        $scope.spinRefresh = false;
        $scope.content = {};
        $scope.settings = {};
        $scope.displayArray = {};
        $scope.collapsibles = {
            channelsIsOpen: false,
            collectionsIsOpen: false,
        };

        $scope.pages = [
            {
                link: 'content',
                name: 'DEVICES.CONTENT',
            },
            {
                link: 'settings',
                name: 'DEVICES.SETTINGS',
            },
        ];

        // Disable Display Array
        if ($scope.deviceOrGroup === 'group') {
            $scope.pages.push({ link: 'display-array', name: 'DEVICES.DISPLAY_ARRAY' });
        }

        // Add Download Management page only for AATV devices 
        if ($scope.deviceOrGroup === 'device' && $scope.isAATV) {
            $scope.pages.push({ link: 'download-management', name: 'DEVICES.DOWNLOAD_MANAGEMENT' });
        }   


        function updateContent(content) {
            $scope.queue = content.queuePlaylist;
            $scope.content.distributed = content.distributed;
            $scope.content.displayVault = content.displayVault;
            $scope.content.displayPortfolio = content.displayPortfolio;
            $scope.content.displayWishlist = content.displayWishlist;
            $scope.content.selectedChannelIds = content.selectedChannelIds || [];
            $scope.content.selectedCollectionIds = content.selectedCollectionIds || [];
            //var defaultChannels = [{id: -1, name: 'My portfolio'}, {id: -2, name: 'My vault'}];
            //$scope.content.channels = _.union(defaultChannels, res.curatedChannels, res.subscribedChannels);
            $scope.content.channels = _.union(content.curatedChannels, content.subscribedChannels);
            $scope.content.channels = _.uniq($scope.content.channels, function (channel) {
                return channel.id;
            });
            $scope.content.collections = content.collections;
            $scope.addIsCheckToItemInListByIdList(
                $scope.content.channels,
                $scope.content.selectedChannelIds,
                'channel'
            );
            $scope.addIsCheckToItemInListByIdList(
                $scope.content.collections,
                $scope.content.selectedCollectionIds,
                'collection'
            );
            //                        $scope.content.channels = $scope.content.channels.concat(res.subscribedChannels);
            $scope.content.selectedContent =
                $scope.content.selectedChannelIds.length > 0 || $scope.content.selectedCollectionIds.length > 0
                    ? $scope.content.distributed
                        ? 'distributed'
                        : 'specific'
                    : 'all';
            $scope.content.distributeOptions = calcDistributeOptions($scope.content);
            $scope.updateAvailableChannelsForSchedules($scope.content);
            //$scope.collapsibles.channelsIsOpen = $scope.content.selectedChannelIds.length > 0;
            //$scope.collapsibles.collectionsIsOpen = $scope.content.selectedCollectionIds.length > 0;
        }

        function calcDistributeOptions(content) {
            var options = {
                list: undefined,
                selectedChannelId: 0,
                selectedCollectionId: 0,
            };
            if (content.distributed) {
                if (content.selectedChannelIds.length === 1) {
                    options.list = 'channels';
                    options.selectedChannelId = content.selectedChannelIds[0];
                }
                if (content.selectedCollectionIds.length === 1) {
                    options.list = 'collections';
                    options.selectedCollectionId = content.selectedCollectionIds[0];
                }
            }
            return options;
        }

        function updateSettings(settings) {
            $scope.settings = angular.extend($scope.settings, settings);
            $scope.updateChannelsFromSchedules($scope.settings.Schedule);
        }

        function updateDisplayArray(displayArray) {
            //displayArray = [{
            //    devices: [{
            //        col: 0,
            //        device_id: '95',
            //        row: 0
            //    }, {
            //        col: 1,
            //        device_id: '97',
            //        row: 2
            //    }],
            //    num_of_cols: 3,
            //    num_of_rows: 3,
            //    is_enabled: true,
            //    enable_videowall: true
            //}];

            $scope.displayArray = angular.extend($scope.displayArray, displayArray[0]);

            if ($scope.displayArray) {
                var devicesOnlyCopy = angular.copy($scope.displayArray.devices) || [];
                $scope.displayArray.devices = [];
                if (!$scope.displayArray.num_of_cols) {
                    $scope.displayArray.num_of_cols = 1;
                }
                if (!$scope.displayArray.num_of_rows) {
                    $scope.displayArray.num_of_rows = 1;
                }
                $scope.adaptDisplays();

                devicesOnlyCopy.forEach(function (device) {
                    $scope.displayArray.devices[device.row][device.col].device_id = device.device_id;
                    $scope.displayArray.devices[device.row][device.col].device = $scope.findDeviceById(
                        device.device_id
                    );
                    $scope.removeDeviceFromAvailableDevices(device.device_id);
                });
            }
        }

        function getSettings(deviceOrGroup, selectedId) {
            let deferred = $q.defer();
            $scope.settingsFetched = false;
            DevicesDataService.getSettings(deviceOrGroup, selectedId).then(
                function (res) {
                    updateSettings(res);
                    $scope.settingsFetched = true;
                    $scope.$broadcast('settings_fetched');
                    deferred.resolve(res);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.title = 'Display Settings';
                    messageParams.message =
                        "There was a problem with loading display's settings. Please refresh the page and try again.";
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        function getContent(deviceOrGroup, selectedId) {
            let deferred = $q.defer();

            $scope.contentFetched = false;
            DevicesDataService.getContent(deviceOrGroup, selectedId).then(
                function (res) {
                    updateContent(res);
                    $scope.contentFetched = true;
                    $scope.$broadcast('content_fetched');
                    deferred.resolve(res);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.title = 'Display Content';
                    messageParams.message =
                        "There was a problem with loading display's queue. Please refresh the page and try again.";
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        $scope.init = function () {
            $scope.settings = {};

            if ($scope.selectedId) {
                if ($scope.isDeviceOrGroupExist($scope.selectedId, $scope.deviceOrGroup)) {
                    getSettings($scope.deviceOrGroup, $scope.selectedId);
                    getContent($scope.deviceOrGroup, $scope.selectedId);

                    if ($scope.deviceOrGroup === 'group') {
                        DevicesDataService.getGroupDisplayArray($scope.selectedId).then(
                            function (res) {
                                updateDisplayArray(res.data);
                            },
                            function (error) {
                                var messageParams = {};
                                messageParams.title = 'Display Array';
                                messageParams.message =
                                    "There was a problem with loading display's content. Please refresh the page and try again.";
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                            }
                        );
                    } else {
                        // single device is selected
                    }

                    if ($state.current.name === $scope.devicesBelongsTo + '.devices.device') {
                        $state.go('.' + $scope.defaultTab.selection, {
                            id: $scope.selectedId,
                        });
                    }
                } else {
                    $log.debug('DevicesSelectedController::init Device or Group werent found');
                    $scope.selectTheFirstDevice();
                }
            } else {
                var messageParams = {};
                messageParams.title = 'Display Error';
                messageParams.message = "Can't select group or display without id.";
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams).then(function (res) {
                    $state.go($scope.devicesBelongsTo + '.devices');
                });
            }
        };

        // General
        $scope.toggleItemInList = function (list, item, itemType, forceAddRemove) {
            var indexOfItem = list.indexOf(item.id);
            if (forceAddRemove) {
                if (forceAddRemove === 'add') {
                    if (indexOfItem < 0) {
                        list.push(item.id);
                    }
                } else if (forceAddRemove === 'remove') {
                    if (indexOfItem > -1) {
                        list.splice(indexOfItem, 1);
                    }
                }
            } else {
                if (indexOfItem > -1) {
                    if ($scope.channelCanBeUnchecked(item.id) || itemType === 'collection') {
                        list.splice(indexOfItem, 1);
                    } else {
                        item.isChecked = true;
                        var messageParams = {};
                        messageParams.message =
                            'Channel "' +
                            item.name +
                            '" can\'t be unselected because it is associated with a schedule.\n';
                        messageParams.message +=
                            'If you wish to remove the channel, please disassociate it from schedules (found in settings tab).';
                        messageParams.title = 'Channel Selection';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    }
                } else {
                    list.push(item.id);
                }
            }
        };

        $scope.addIsCheckToItemInListByIdList = function (list, idList, type) {
            var itemFound;
            idList = idList || [];
            list = list || [];
            idList.forEach(function (id) {
                itemFound = _.find(list, function (item) {
                    return id === item.id;
                });
                if (itemFound) {
                    if (type === 'channel') {
                        $scope.toggleItemInList($scope.content.selectedChannelIds, itemFound, type, 'add');
                    } else {
                        $scope.toggleItemInList($scope.content.selectedCollectionIds, itemFound, type, 'add');
                    }
                    itemFound.isChecked = true;
                }
            });
        };

        // Content
        $scope.findChannelById = function (channelId) {
            return _.find($scope.content.channels, function (channel) {
                return channel.id === channelId;
            });
        };

        $scope.findChannelInQueueById = function (queue, channelId) {
            if (channelId) {
                return _.find(queue, function (section) {
                    return section.channelID === channelId;
                });
            } else {
                return null;
            }
        };

        // Handle schedule for AATV
        $scope.getStartTime = function (schedule) {
            return new Date(1970, 0, 1, schedule.startHour, schedule.startMinute);
        };

        $scope.getEndTime = function (schedule) {
            return new Date(1970, 0, 1, schedule.endHour, schedule.endMinute);
        };

        $scope.channelCanBeUnchecked = function (channelId) {
            var result = true;

            if ($scope.content.selectedContent === 'specific') {
                // Go over all already selected channels from schedules
                $scope.channelsFromSchedules.forEach(function (channelFromSchedule) {
                    // If the user just unchecked that channel, alert that he can't do that
                    if (channelFromSchedule === channelId) {
                        result = false;
                    }
                });
            }

            return result;
        };

        $scope.updateAvailableChannelsForSchedules = function (content) {
            $scope.channelsForSchedules = [];
            if (content && content.channels) {
                $scope.channelsForSchedules = content.channels;
                if (content.selectedContent === 'specific') {
                    $scope.channelsForSchedules = _.filter($scope.channelsForSchedules, function (channel) {
                        return channel.isChecked === true;
                    });
                } else if (content.selectedContent === 'distributed') {
                    $scope.channelsForSchedules = _.filter($scope.channelsForSchedules, function (channel) {
                        return channel.id === content.distributeOptions.selectedChannelId;
                    });
                }
            }
        };

        // Settings
        $scope.updateChannelsFromSchedules = function (schedules) {
            $scope.channelsFromSchedules = [];
            if (schedules === undefined || schedules.length === 0) {
                return;
            }
            schedules.forEach(function (schedule) {
                if (schedule.IsChecked && schedule.Channels && schedule.ChannelsAreChecked) {
                    schedule.Channels.forEach(function (channelId) {
                        if ($scope.channelsFromSchedules.indexOf(channelId) < 0) {
                            $scope.channelsFromSchedules.push(channelId);
                        }
                    });
                }
            });
        };

        $scope.disableScheduleWithChannels = function (justCheck, excludeChannels) {
            //We have to uncheck 'Schedule' with channels or collections
            var num = 0;
            excludeChannels = excludeChannels || [];
            $scope.settings.Schedule.forEach(function (schedule) {
                if (schedule !== undefined && schedule.IsChecked && schedule.Channels && schedule.ChannelsAreChecked) {
                    var contentNotExcluded = false;
                    schedule.Channels.forEach(function (channelId) {
                        if (excludeChannels.indexOf(schedule.Channels[0]) < 0) {
                            contentNotExcluded = true;
                        }
                    });

                    if (contentNotExcluded) {
                        num++;
                        if (!justCheck) {
                            schedule.IsChecked = false;
                        }
                    }
                }
            });
            return num;
        };

        // Display Array
        $scope.updateAvailableDevices = function (takenDevices) {
            $scope.availableDevices = [];

            if ($scope.selected) {
                $scope.availableDevices = angular.copy($scope.selected.devices);

                if (takenDevices) {
                    takenDevices.forEach(function (takenDevice) {
                        var foundDeviceIndex;
                        var foundDevice = _.find($scope.availableDevices, function (availableDevice, index) {
                            if (takenDevice === availableDevice.id) {
                                foundDeviceIndex = index;
                                return true;
                            }
                        });
                        // Remove device from available devices
                        if (foundDevice) {
                            $scope.availableDevices.splice(foundDeviceIndex, 1);
                            $scope.sortDevices($scope.availableDevices);
                        }
                    });
                }
            }
        };

        $scope.addDeviceToAvailableDevices = function (deviceId) {
            var foundDevice = _.find($scope.selected.devices, function (device) {
                return device.id === deviceId;
            });

            if (foundDevice) {
                $scope.availableDevices.push(foundDevice);
                $scope.sortDevices($scope.availableDevices);
            }
        };

        $scope.removeDeviceFromAvailableDevices = function (deviceId) {
            var foundDeviceIndex;
            var foundDevice = _.find($scope.availableDevices, function (device, index) {
                if (device.id === deviceId) {
                    foundDeviceIndex = index;
                    return true;
                }
            });

            if (foundDevice) {
                $scope.availableDevices.splice(foundDeviceIndex, 1);
                $scope.sortDevices($scope.availableDevices);
            }
        };

        $scope.selectBlock = function (row, col, forceUnselect) {
            if (
                forceUnselect ||
                ($scope.selectedBlock && $scope.selectedBlock.row === row && $scope.selectedBlock.col === col)
            ) {
                $scope.selectedBlock = undefined;
            } else {
                $scope.selectedBlock = $scope.displayArray.devices[row][col];
            }
        };

        $scope.findDeviceById = function (deviceId) {
            return _.find($scope.selected.devices, function (device) {
                return device.id === deviceId;
            });
        };

        $scope.adaptDisplays = function () {
            if ($scope.displayArray.num_of_cols && $scope.displayArray.num_of_rows) {
                $scope.displayArray.devices = $scope.displayArray.devices || [[]];

                var oldDevicesArray = angular.copy($scope.displayArray.devices);
                var newDevicesArray = [[]];
                var i, j;
                var takenDevices = [];

                // If block is out of scope, unselect it
                if (
                    $scope.selectedBlock &&
                    ($scope.selectedBlock.row + 1 > $scope.displayArray.num_of_rows ||
                        $scope.selectedBlock.col + 1 > $scope.displayArray.num_of_cols)
                ) {
                    $scope.selectBlock($scope.selectedBlock.row, $scope.selectedBlock.col);
                }

                for (i = 0; i < $scope.displayArray.num_of_rows; i++) {
                    for (j = 0; j < $scope.displayArray.num_of_cols; j++) {
                        if (oldDevicesArray[i] && oldDevicesArray[i][j]) {
                            if (!newDevicesArray[i]) {
                                newDevicesArray[i] = [];
                            }

                            if (oldDevicesArray[i][j].device_id) {
                                takenDevices.push(oldDevicesArray[i][j].device_id);
                                if (!oldDevicesArray[i][j].device) {
                                    oldDevicesArray[i][j].device = $scope.findDeviceById(
                                        oldDevicesArray[i][j].device_id
                                    );
                                }
                            }

                            newDevicesArray[i][j] = oldDevicesArray[i][j];
                            if (
                                $scope.selectedBlock &&
                                $scope.selectedBlock.col === newDevicesArray[i][j].col &&
                                $scope.selectedBlock.row === newDevicesArray[i][j].row
                            ) {
                                $scope.selectedBlock = newDevicesArray[i][j];
                            }
                        } else {
                            if (!newDevicesArray[i]) {
                                newDevicesArray[i] = [];
                            }

                            newDevicesArray[i][j] = {
                                row: i,
                                col: j,
                            };
                        }
                    }
                }

                $scope.displayArray.devices = newDevicesArray;

                $scope.updateAvailableDevices(takenDevices);
            }
        };

        // Show ID
        function sendShowIdCommandToDevice(device, command, toggle, isDisplayArray) {
            device.showId = toggle;
            var gcm_data = {
                template: 'ShowId',
                json: {},
            };
            //var gcm_data = {
            //    backgroundColor: '333333',
            //    texts: []
            //};

            // max_num_of_w - how many W will fit into that place. The more Ws the smaller the text will appear.
            // horizontal - left, center, right
            // vertical - top, center, bottom

            gcm_data.json.isDisplayArray = isDisplayArray;
            gcm_data.json.device = device;

            //if (isDisplayArray) {
            //    gcm_data.json.push({
            //        text: device.row + ', ' + device.col,
            //        color: 'A4FE4D',
            //        max_num_of_w: 15,
            //        location: {
            //            horizontal: 'left',
            //            vertical: 'top'
            //        }
            //    });
            //    gcm_data.json.push({
            //        text: 'ID: #' + device.id,
            //        sub_text: device.name,
            //        color: 'A4FE4D',
            //        max_num_of_w: 10,
            //        location: {
            //            horizontal: 'center',
            //            vertical: 'center'
            //        }
            //    });
            //} else {
            //    gcm_data.json.push({
            //        text: 'ID: #' + device.id,
            //        sub_text: device.name,
            //        color: 'FFFFFF',
            //        max_num_of_w: 10,
            //        location: {
            //            horizontal: 'center',
            //            vertical: 'center'
            //        }
            //    });
            //}
            DevicesDataService.sendCommandToPlayer(command, [device.id], gcm_data);
        }

        $scope.$on('debugging_changed', function (event, selectedId, debuggingEnabled, debuggingEmails) {
            //if (!$scope.singleSelectedDevice && $scope.selectedDevices.length === 0) {
            //var result = $scope.findDeviceById(selectedId);
            $scope.selected.debugging_enabled = debuggingEnabled;
            $scope.selected.debugging_emails = debuggingEmails;
            //}
        });

        $scope.toggleShowId = function (showIdToggle) {
            var command = showIdToggle ? 'ShowID' : 'HideID';

            if ($scope.selected.devices && $scope.selected.devices.length > 0) {
                $scope.selected.devices.forEach(function (device) {
                    sendShowIdCommandToDevice(
                        device,
                        command,
                        showIdToggle,
                        $scope.displayArray ? $scope.displayArray.is_enabled : false
                    );
                });
            } else {
                sendShowIdCommandToDevice($scope.selected, command, showIdToggle);
            }
        };

        $scope.isPlayerTypeValid = function (playerType) {
            //we should validate that relevant settigns are checked.
            //If not we will checked it automatically and warn user that settigns has be en effected.
            var messageTxt = null;
            if ($scope.settings.Appearance === undefined) {
                return null;
            }
            if (playerType === PLAYER_TYPES.AW_COVER) {
                if (!$scope.settings.Appearance.ArtworkCover.IsChecked) {
                    messageTxt = 'To select this mode, you should first enable "Artwork Cover"';
                }
            } else if (playerType === PLAYER_TYPES.ARTIST_BIO) {
                if (!$scope.settings.Appearance.ArtistBioCover.IsChecked) {
                    messageTxt = 'To select this mode, you should first enable "Artist Bio"';
                }
            } else if (playerType === PLAYER_TYPES.CHANNEL_COVER) {
                if (!$scope.settings.Appearance.ChannelCover.IsChecked) {
                    messageTxt = 'To select this mode, you should first enable "Channel Cover"';
                }
            } else if (playerType === PLAYER_TYPES.CAMPAIGN) {
                if (
                    !$scope.settings.Appearance.SponsorCover.IsChecked ||
                    $scope.settings.Appearance.SponsorCover.mode !== 'artwork'
                ) {
                    messageTxt =
                        'To select this mode, you should first setup a campaign to be displayed "before each Artwork"';
                }
            }
            return messageTxt;
        };

        $scope.onPlayerTypeChanged = function (newPlayerType, resetFN, skipValidation) {
            //we should validate that relevant settigns are checked.
            //If not we will checked it automatically and warn user that settigns has be en effected.
            var messageTxt = $scope.isPlayerTypeValid(newPlayerType);
            if (messageTxt !== null && !skipValidation) {
                var messageParams = {};
                messageParams.message = messageTxt;
                messageParams.title = 'Cover Page Mode';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams).then(function (res) {
                    resetFN($scope.settings.Info.playerType);
                });
            } else {
                $scope.settings.Info.playerType = newPlayerType;
            }
        };

        function performRefresh() {
            $scope.spinRefresh = true;
            let promises = [];

            promises.push(getUpdatedStatus($scope.deviceOrGroup, $scope.selectedId));
            promises.push(getContent($scope.deviceOrGroup, $scope.selectedId));
            promises.push(getSettings($scope.deviceOrGroup, $scope.selectedId));

            $q.all(promises).finally(() => {
                $scope.spinRefresh = false;
            });
        }

        function getUpdatedStatus(deviceOrGroup, selectedId) {
            return DevicesDataService.getUpdatedStatus(selectedId).then(
                function (newStatus) {
                    $scope.selected.last_accessed = newStatus.lastAccessed;
                    $scope.selected.current_status = newStatus.currentStatus;
                    $scope.selected.downloads = newStatus.downloads;
                    $scope.selected.is_online = newStatus.is_online;
                    loadArtworksFromDownloads($scope.selected?.downloads);
                    $scope.$broadcast('device_fetched');
                },
                function (error) {
                    //We should not come here since getUpdatedStatus() always returns status.
                    $log.debug('DevicesSelectedRemoteControlController::getUpdatedStatus Failed', error);
                }
            );
        }

        function loadArtworksFromDownloads(downloads) {
            if (downloads) {
                downloads.downloads.forEach((download) => {
                    // TODO: Load artworks from server
                    debugger;
                });
            }
        }

        $scope.refreshDevice = function () {
            if ($state.params.preventRedirect) {
                let messageParams = {};
                messageParams.title = 'Unsaved Changes';
                messageParams.message =
                    'There are unsaved changes in current display. Are you sure you want to refresh?';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Refresh';
                messageParams.okClass = 'danger';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        // User chose to leave without saving, continue with the destroy event
                        $state.params.preventRedirect = false;
                        performRefresh();
                    }
                });
            } else {
                performRefresh();
            }
        };

        $scope.onAWStripChanged = function (newStripMode, newStripContent, newStripDuration, resetFN) {
            $scope.settings.Appearance.AwCreditStrip = {
                Mode: newStripMode,
                Content: newStripContent,
                Duration: {
                    Start: newStripDuration,
                    End: newStripDuration,
                },
            };
        };
        $scope.$on('pushy_update_settings', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_settings', data);
            if (data && $scope.deviceOrGroup === 'device' && $scope.selectedId === data.deviceId) {
                getSettings($scope.deviceOrGroup, $scope.selectedId);
            }
        });

        $scope.$on('pushy_update_content_settings', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_content_settings', data);
            if (data && $scope.deviceOrGroup === 'device' && $scope.selectedId === data.deviceId) {
                getContent($scope.deviceOrGroup, $scope.selectedId);
            }
        });

        $scope.$on('pushy_update_current_status', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_current_status', data);
            if (data && $scope.deviceOrGroup === 'device' && $scope.selectedId === data.deviceId) {
                getUpdatedStatus($scope.deviceOrGroup, $scope.selectedId);
            }
        });

        $scope.$on('pushy_update_status', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_status', data);
            if (data && $scope.deviceOrGroup === 'device' && $scope.selectedId === data.deviceId) {
                getUpdatedStatus($scope.deviceOrGroup, $scope.selectedId);
            }
        });

        $scope.$on('pushy_device_deleted', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_content_settings', data);
            if (data && $scope.deviceOrGroup === 'device' && $scope.selectedId === data.deviceId) {
                var messageParams = {};
                messageParams.message = 'Current display was disassociated. Refreshing device list';
                messageParams.title = 'Display Was Disassociated';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams).then(function (res) {
                    $scope.refreshSidebar();
                });
            }
        });

        $scope.$on('$destroy', function (event) {
            $log.debug('DevicesSelectedController::destroy');
            // PushHandlerService.cancelDeviceKeepaliveInterval();
        });

        $scope.isAndroid = function () {
            return (
                $scope.settings !== undefined &&
                $scope.settings.Info !== undefined &&
                $scope.settings.Info.OSVersion !== undefined &&
                $scope.settings.Info.OSVersion.toLowerCase().indexOf('android') >= 0
            );
        };

        $scope.isOfflineDownloadEnabled = function (offlineMode) {
            return (
                $scope.settings !== undefined &&
                !$scope.isAndroid() &&
                offlineMode !== 2 /* PLAYER_OFFLINE_SUPPORT.BLOCK */
            );
        };

        // Listen to selected_changed and call init
        $scope.$on('aatv_selected', function (event, selected) {
            $scope.init();
        });

        $scope.$on('selected_chosen', function (event, selectedId, deviceOrGroup) {
            getUpdatedStatus($scope.deviceOrGroup, $scope.selectedId);
        });
    },
]);
