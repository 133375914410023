'use strict';

angular.module('core').directive('uploader', [
    function () {
        return {
            restrict: 'EA',
            scope: {
                ngModel: '=',
                uploaderTagType: '=',
                uploaderById: '=',
                ngChange: '&',
            },
            link: function (scope, element, attrs, ctrl) {
                attrs.uploaderTagType = attrs.uploaderTagType || 'button';
                attrs.uploaderById = attrs.uploaderById || null;
                attrs.ngChange = attrs.ngChange || '';

                if (attrs.uploaderById) {
                    document.getElementById(attrs.uploaderById).onclick = function () {
                        element.find('input')[0].click();
                    };
                } else {
                    element.find(attrs.uploaderTagType).bind('click', function () {
                        element.find('input')[0].click();
                    });
                }

                element.find('input').bind('change', function () {
                    scope.ngModel = [];
                    var i,
                        files = element.find('input')[0].files;

                    for (i = 0; i < files.length; ++i) {
                        scope.ngModel.push(files[i]);
                    }

                    scope.$apply();
                    if (angular.isDefined(attrs.ngChange)) {
                        //                        scope.$parent.$eval(attrs.ngChange);
                        scope.ngChange({ files: scope.ngModel }); // Send the files to the parameters of the ng-change function
                    }
                });
            },
        };
    },
]);
