'use strict';
/* global angular */
/* global grecaptcha */
/* global AppleID */

angular.module('users').controller('JoinController', [
    '$rootScope',
    '$scope',
    '$state',
    '$filter',
    '$q',
    '$timeout',
    '$location',
    '$stateParams',
    '$http',
    'jwtHelper',
    'Security',
    'Messages',
    'Partial',
    'ArtworkService',
    'ChannelService',
    'FormValidationService',
    'OpenPopupForm',
    'PlatformManager',
    '$auth',
    'User',
    'GOOGLE_RECAPTCHA',
    'Countries',
    function (
        $rootScope,
        $scope,
        $state,
        $filter,
        $q,
        $timeout,
        $location,
        $stateParams,
        $http,
        jwtHelper,
        Security,
        Messages,
        Partial,
        ArtworkService,
        ChannelService,
        FormValidationService,
        OpenPopupForm,
        PlatformManager,
        $auth,
        User,
        GOOGLE_RECAPTCHA,
        Countries
    ) {
        var self = this;
        this.Messages = Messages;
        this.PlatformManager = PlatformManager;
        this.form = {};
        this.NIIO_URL = PlatformManager.platform.url;
        this.User = {};
        this.User.titles = ['4'];
        this.User.url = '';
        this.User.first_name = '';
        this.User.last_name = '';
        this.User.display_username = false;
        self.User.agreeToConditions = true;
        this.loading = false;
        this.joinInProcess = false;
        this.allCountries = Countries.getAll();
        $scope.UserService = User;

        // Interactive module ->
        this.hasInteractivity = false;
        this.setInteractivity = (status) => {
            this.hasInteractivity = !!status;
        };

        // Changing the input value ->
        this.changeUsernameInput = (name, input) => {
            input.$setViewValue(name);
            input.$render();
        };

        // Show username/url suggestions to the user ->
        this.suggestions = [];
        $rootScope.$on('user.url.suggestions', (event, data) => {
            this.suggestions = data;
        });

        // Whenever primary country selection change ->
        this.onAccountCountryChange = (c) => {
            this.User.state = null;
        };

        function initAppleConnect() {
            AppleID.auth.init({
                clientId: 'com.niio.web',
                scope: 'email',
                redirectURI: `${PlatformManager.platform.url}/Server/users/ExternalAuthRedirect`,
                usePopup: true,
            });
        }

        function matchCountry(countryCode) {
            const countryItem = self.allCountries.find((item) => {
                return item.code.includes(countryCode);
            });

            // Match the country raw_name to the country name in the list of countries
            // in FormValidationService.countries

            // Function to strip the parenthesis and content within from a string
            const stripParenthesis = (str) => str.replace(/ \([^)]*\)/g, '');
            // Function to remove special characters
            const removeAccents = (str) => {
                return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            };

            const search = removeAccents(stripParenthesis(countryItem.raw_name));

            // Find the matching country in `myCountries`
            return FormValidationService.countries.find((country) => search === removeAccents(country));
        }

        this.init = function () {
            self.onTitleChange();
            self.step = 1;
            self.loading = true;
            self.titles = [
                { name: 'Art Fan', value: '4' },
                { name: 'Artist', value: '1' },
                { name: 'Collector', value: '2' },
                { name: 'Curator', value: '3' },
                { name: 'Organization', value: '5' },
            ];

            if (!self.User.country) {
                $http.get('/app/clientPreferences').then((response) => {
                    if (response?.data?.data?.location) {
                        const location = response.data.data.location;
                        const matchingCountry = matchCountry(location.country);
                        self.User.country = matchingCountry;
                        if (location?.city) {
                            self.User.city = location.city;
                        }

                        if (location?.state) {
                            self.User.state = location.state;
                        }
                    }
                });
            }

            if ($stateParams.invitationCode) {
                $http
                    .post('/invitations/isInvitationCodeValid', {
                        code: $stateParams.invitationCode,
                    })
                    .then((res) => {
                        if (res.data.data.valid) {
                            self.User.invitation = $stateParams.invitationCode;
                            self.joinForm1.invitation.$setDirty(); // set it dirty
                            self.invitationCodeIsValid = true;
                        } else {
                            Messages.cancel();
                            $timeout(() => {
                                OpenPopupForm.openMenu('login');
                            }, 10);
                        }
                    })
                    .finally(() => {
                        self.loading = false;
                    });
            } else {
                self.invitationCodeIsValid = true;
                self.loading = false;
            }

            self.FormValidationService = FormValidationService;
            self.organizationTypes = FormValidationService.organizationTypes;

            initAppleConnect();

            if (self.params && self.params.authProfile) {
                // coming from failed social login after successful auth (continuous login)
                onGetExternalProfileSuccess(self.params.authProfile, self.params.authProfile.provider);
            }
        };

        this.openLogin = function () {
            Messages.cancel();
            $timeout(() => {
                OpenPopupForm.openMenu('login', self.params);
            }, 10);
        };

        this.onCancel = function () {
            Messages.cancel();
            if ($state.current && $state.current.name && $state.current.name.indexOf('profile') === 0) {
                if ($rootScope.lastStateParamsBeforeLogin && $rootScope.lastStateParamsBeforeLogin.url) {
                    $state.go('profile.main-tab.main', $rootScope.lastStateParamsBeforeLogin.url);
                }
            }
        };

        this.onTitleChange = function () {
            self.isArtist = self.User.titles.indexOf('1') > -1;
            self.isOrganization = self.User.titles.indexOf('5') > -1;
            self.isArtfan = self.User.titles.indexOf('4') > -1;
            self.isCollector = self.User.titles.indexOf('2') > -1;

            if (self.isOrganization) {
                self.User.username = null;
                self.User.last_name = null;
            } else {
                self.User.organization_type = null;
                self.User.organization_type_other = null;
            }
        };

        this.backJoinStep1 = function () {
            self.step = 1;
        };

        // this.backJoinStep2 = function () {
        //   self.step = 2;
        // };

        this.joinStep1 = function () {
            self.step = 2;
        };

        function displayErrorMsg(msg) {
            var messageParams = {
                message: msg,
                disableAutoDismiss: true,
            };
            Messages.openMessage($rootScope, messageParams);
        }

        function generatePassword(plength) {
            //ref: http://stackoverflow.com/questions/1497481/javascript-password-generator
            plength = plength || 8;

            var keylistalpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var keylistalphal = 'abcdefghijklmnopqrstuvwxyz';
            var keylistint = '123456789';
            var temp = '';
            var len = plength / 2;
            len = len - 1;
            var lenslower = plength - len - len;

            for (var i = 0; i < len; i++) {
                temp += keylistalpha.charAt(Math.floor(Math.random() * keylistalpha.length));
            }

            for (i = 0; i < lenslower; i++) {
                temp += keylistalphal.charAt(Math.floor(Math.random() * keylistalphal.length));
            }

            for (i = 0; i < len; i++) {
                temp += keylistint.charAt(Math.floor(Math.random() * keylistint.length));
            }

            temp = temp
                .split('')
                .sort(function () {
                    return 0.5 - Math.random();
                })
                .join('');
            return temp;
        }

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }

        function onGetExternalProfileSuccess(data, provider) {
            var email = data.profile.email;
            if (data.user_id !== undefined && data.user_id !== null) {
                displayErrorMsg(
                    capitalizeFirstLetter(provider) +
                        " account '" +
                        email +
                        "' is already in use. Please choose a different account."
                );
                return;
            }

            self.FormValidationService.checkIfEmailIsAvailable(email).then(function (res) {
                if (res.data.available) {
                    self.User.social_provider = provider;
                    self.User.social_code = data.ticket;
                    self.User.email = email;
                    self.User.password = generatePassword(8);
                    self.User.passwordConf = self.User.password;
                    self.User.first_name = data.profile.given_name;
                    self.User.last_name = data.profile.family_name;
                    self.User.username = data.profile.username;
                    self.User.agreeToConditions = true;
                    self.User.url = self.isArtfan ? '' : buildUserUrl(self.User); //TODO: validation.
                    self.joinStep1();
                    $timeout(function () {
                        self.updateUrl();
                        self.updateUsername(true);
                    }, 100);
                } else {
                    displayErrorMsg(
                        "Email address '" + email + "' is already in use. Please choose a different email address."
                    );
                }
            });
        }

        this.linkExternalProfile = function (provider) {
            if (self.User.agreeToConditions) {
                if (provider === 'apple') {
                    AppleID.auth.signIn().then(
                        function (response) {
                            // For more info: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
                            if (response.authorization && response.authorization.code) {
                                var user = response.authorization.user;
                                var id_token = response.authorization.id_token;
                                var data = {};
                                if (user) {
                                    data.firstName = user.firstName;
                                    data.lastName = user.lastName;
                                }
                                if (id_token) {
                                    var decodedIdToken = jwtHelper.decodeToken(id_token);
                                    if (decodedIdToken) {
                                        data.oid = decodedIdToken.sub;
                                    }
                                }
                                performContinuousLogin(response.authorization.code, provider, data);
                            } else {
                                displayAuthError(provider);
                            }
                        },
                        function () {
                            displayAuthError(provider);
                        }
                    );
                } else {
                    $auth.authenticate(provider).then(
                        function (response) {
                            if (response.code) {
                                performContinuousLogin(response.code, provider);
                            } else {
                                displayAuthError(provider);
                            }
                        },
                        function () {
                            displayAuthError(provider);
                        }
                    );
                }
            }
        };

        function onSuccessContinuousLogin() {
            var deferred = $q.defer();
            if (typeof Messages.ok === 'function') {
                Messages.ok();
            }

            if (self.params && self.params.service) {
                if (self.params.service === 'artwork') {
                    deferred.resolve(
                        Partial.func(
                            ArtworkService.handleArtworkAction,
                            self.params.actionName,
                            self.params.artwork,
                            self.params.params
                        )
                    );
                } else if (self.params.service === 'channel') {
                    deferred.resolve(
                        Partial.func(
                            ChannelService.handleChannelAction,
                            self.params.actionName,
                            self.params.channel,
                            self.params.params
                        )
                    );
                }
            } else {
                deferred.resolve('default');
            }

            self.loginInProcess = false;
            return deferred.promise;
        }

        function performContinuousLogin(code, provider, data) {
            Security.loginExternal(
                provider,
                code,
                data,
                onSuccessContinuousLogin,
                function () {
                    // failure
                    displayErrorMsg('Failed to connect to ' + provider + ' account!');
                },
                'login',
                function (res) {
                    // join success function
                    onGetExternalProfileSuccess(res, provider);
                }
            );
        }

        function displayAuthError(provider) {
            displayErrorMsg('Failed to authenticate ' + provider + ' account!');
        }

        this.updateUsername = function (force) {
            if (
                (force !== undefined && !force) ||
                !self.isArtist ||
                self.joinForm2.username.$dirty ||
                (self.joinForm2.first.$pristine && self.joinForm2.last.$pristine)
            ) {
                return;
            }
            self.User.username = self.User.first_name || '';
            self.User.username += self.User.last_name ? ' ' + self.User.last_name : '';
            //self.joinForm2.username.$setViewValue(self.User.username);
            //self.joinForm2.username.$viewValue = self.User.username;
            //$scope.$apply();
            $timeout(function () {
                FormValidationService.onUserChangeOrBlur(self.joinForm2.username, 'change');
                self.joinForm2.username.$setPristine(true);
            });
        };

        this.requestInvite = function () {
            Messages.cancel();
            OpenPopupForm.openMenu('invitation', { type: 'invitation' });
        };

        this.updateUrl = () => {
            if (
                self.isArtfan ||
                this.hasInteractivity ||
                (self.joinForm2.name && self.joinForm2.name.$pristine) ||
                (self.joinForm2.first &&
                    self.joinForm2.first.$pristine &&
                    self.joinForm2.last &&
                    self.joinForm2.last.$pristine)
            ) {
                return;
            }

            self.User.url = buildUserUrl(self.User);

            //self.joinForm2.url.$setViewValue(self.User.url);
            //$scope.$apply();
            $timeout(function () {
                FormValidationService.onUrlChangeOrBlur(self.joinForm2.url, 'change');
                self.joinForm2.url.$setPristine(true);
            });
        };

        function buildUserUrl(user) {
            var url = user.first_name || '';
            url += user.last_name ? '.' + self.User.last_name : '';
            url = angular.lowercase(url);
            url = url.replace(/\ /g, '.');
            return url;
        }

        function handleLastAction() {
            var deferred = $q.defer();
            var lastParams = self.params;
            if (!$rootScope.fromPlayer && !$rootScope.duringDeviceLogin) {
                // var messageParams = {};
                // messageParams.title = 'Welcome to Niio';
                // messageParams.message = 'We are excited to have you here with us, welcome! Press OK to continue.';
                // messageParams.disableAutoDismiss = true;
                // Messages.openMessage($rootScope, messageParams)
                //     .then(function() {
                if (self.params && self.params.service) {
                    if (self.params.service === 'artwork') {
                        deferred.resolve(
                            Partial.func(
                                ArtworkService.handleArtworkAction,
                                self.params.actionName,
                                self.params.artwork,
                                self.params.params
                            )
                        );
                    } else if (self.params.service === 'channel') {
                        deferred.resolve(
                            Partial.func(
                                ChannelService.handleChannelAction,
                                self.params.actionName,
                                self.params.channel,
                                self.params.params
                            )
                        );
                    } else if (self.params.service === 'plans') {
                        $rootScope.$emit('continue_payment');
                        deferred.resolve('default');
                    }
                } else {
                    deferred.resolve('default');
                }
                // });
            } else {
                deferred.resolve('default');
            }

            return deferred.promise;
        }

        function onSuccessSignup(res) {
            Messages.ok();
            //if (res.data.verified || self.User.social_code !== undefined) {
            var lastFunc = Partial.func(handleLastAction);
            self.joinInProcess = false;
            var source = self.params && self.params.source ? self.params.source : 'join';
            Security.login(self.User, lastFunc, null, source);
            //} else {
            //    var messageParams = {};
            //    messageParams.title = 'Email Verification Required';
            //    messageParams.message = 'We just sent a verification email. Please check your inbox and follow the instructions to verify your account and login.';
            //    messageParams.disableAutoDismiss = true;
            //$location.path('/home');
            //messageParams.message = 'Welcome to ' + $scope.$platformManager.platform.name.toUpperCase();
            //    Messages.openMessage($rootScope, messageParams);
            //}
        }

        function onFailedSignup(error) {
            var messageParams = {};
            messageParams.title = 'Failed to Join';
            messageParams.message =
                'Some of the data you entered is incorrect or already exists for another user (such as Username or URL).';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($rootScope, messageParams);
            self.joinInProcess = false;
        }

        function completeSignUp() {
            if (!self.User.username) {
                self.User.username = null;
                if (self.User.display_username) {
                    self.User.display_username = false;
                }
            }

            // Enroll to event
            if ($rootScope.lastStateParamsBeforeLogin && $rootScope.lastStateParamsBeforeLogin.eventCode) {
                self.User.eventCode = $rootScope.lastStateParamsBeforeLogin.eventCode;
            }

            Security.signup(self.User, onSuccessSignup, onFailedSignup);
        }

        this.signup = function () {
            self.joinInProcess = true;
            if (self.displayCaptcha()) {
                grecaptcha.ready(function () {
                    grecaptcha.execute(GOOGLE_RECAPTCHA, { action: 'submit' }).then(
                        (token) => {
                            self.User.captcha = token;
                            completeSignUp();
                        },
                        (error) => {
                            let messageParams = {};
                            messageParams.title = 'Failed to Join';
                            messageParams.message = 'Please check "I\'m not a robot" before joining.\n' + error;
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams);
                            self.joinInProcess = false;
                            return false;
                        }
                    );
                });
            } else {
                completeSignUp();
            }
        };

        this.displayCaptcha = function () {
            var display = true;
            if (self.User.email) {
                if (
                    self.User.email.endsWith('@no.vu') ||
                    self.User.email.endsWith('@claydolab.com') ||
                    self.User.email.endsWith('@niioart.com') ||
                    self.User.email.endsWith('@novu.info') ||
                    // self.User.email.endsWith('@novuarts.com') ||
                    // self.User.email.endsWith('@novuart.net') ||
                    // self.User.email.endsWith('@niio.art') ||
                    // self.User.email.endsWith('@niio.com') ||
                    // self.User.email.endsWith('@clay.do') ||
                    // self.User.email.endsWith('@niio.email') ||
                    // self.User.email.endsWith('@novu.tv') ||
                    self.User.email === 'testniioqa@gmail.com' ||
                    self.User.email === 'adstore0816@gmail.com' ||
                    self.User.email === 'sova_yjxikva_pre@tfbnw.net' ||
                    self.User.email === 'oslik_vciujpn_pre@tfbnw.net' ||
                    self.User.email === 'shurik.student@icloud.com' ||
                    self.User.email === 'niio.testlab@gmail.com' ||
                    self.User.email === 'curator.testlab@gmail.com' ||
                    self.User.email === 'tcollector90@gmail.com' ||
                    self.User.email === 'org.testlab@gmail.com' ||
                    self.User.email === 'artfantest@gmail.com' ||
                    self.User.email === 'artist_hatbivr_test@tfbnw.net' ||
                    self.User.email === 'curator_iogweig_test@tfbnw.net' ||
                    self.User.email === 'collector_wmsvvip_test@tfbnw.net' ||
                    self.User.email === 'organization_cvlwyeh_test@tfbnw.net' ||
                    self.User.email === 'artfan_euuiauq_test@tfbnw.net'
                ) {
                    display = false;
                }
            }
            return display;
        };
    },
]);
