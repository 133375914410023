'use strict';

angular.module('admin-area').service('AdminAreaDataService', [
    '$http',
    '$q',
    '_',
    function ($http, $q, _) {
        var self = this;

        // DEVICES
        this.getGlobalDisplaysCounts = function () {
            var deferred = $q.defer();

            $http.get('/devices/getGlobalDevicesCounts').then(
                function (res) {
                    deferred.resolve(res.data.devicesCounts);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getAssociatedDisplaysReport = function (status, userId, hoursBack) {
            var data = {
                    status: status,
                    userId: userId,
                    hoursBack: hoursBack,
                },
                deferred = $q.defer();

            $http.post('/devices/getAssociatedDevicesReport', data).then(
                function (res) {
                    deferred.resolve(res.data.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getArtworksStatusReport = function () {
            var deferred = $q.defer();
            //self.toggles.spinReportRefresh = true;
            $http.post('/artworks/getArtworksStatusReport').then(
                function (res) {
                    deferred.resolve(res.data.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            //.finally(function() {
            //    self.toggles.spinReportRefresh = false;
            //});

            return deferred.promise;
        };

        this.getGlobalUsersCounts = function (periodInDays) {
            var deferred = $q.defer();

            var data = {
                period_days: periodInDays,
            };

            $http.post('/users/getUsersTotal', data).then(
                function (res) {
                    deferred.resolve(res.data.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getTransfersReport = function () {
            var deferred = $q.defer();

            $http.post('/transfer/getTransferStatusReport').then(
                function (res) {
                    var transfersReport = res.data.data;
                    transfersReport.totalTransfers = 0;
                    _.each(transfersReport.transfers, function (transfer) {
                        transfersReport.totalTransfers += parseInt(transfer.amount);
                    });

                    deferred.resolve(transfersReport);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getEventsReport = function () {
            var deferred = $q.defer();

            $http.post('/events/getEventsStatusReport').then(
                function (res) {
                    var eventsReport = res.data.data;
                    deferred.resolve(eventsReport);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getArtworkTasks = function (artworkId) {
            var deferred = $q.defer();

            var data = {
                artwork_id: artworkId,
            };

            $http.post('/artworks/getArtworkEngineTasks', data).then(
                function (res) {
                    var tasks = res.data.data.tasks;
                    deferred.resolve(tasks);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.updateTaskPriority = function (taskId, priority) {
            var deferred = $q.defer();

            var data = {
                task_id: taskId,
                priority: priority,
            };

            $http.post('/admin/updateEngineTaskPriority', data).then(
                function (res) {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.updateArtworkTasksPriority = function (artworkId, priority) {
            var deferred = $q.defer();

            var data = {
                artwork_id: artworkId,
                priority: priority,
            };

            $http.post('/admin/updateArtworkEngineTasksPriority', data).then(
                function (res) {
                    deferred.resolve();
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getHistoryStatus = function (searchDate) {
            var deferred = $q.defer();

            var data = {
                search_date: searchDate,
            };

            $http.post('/admin/getHistoryStatus', data).then(
                function (res) {
                    deferred.resolve(res.data.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };
    },
]);
