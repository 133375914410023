'use strict';

angular.module('discover').controller('DiscoverArtworksMainController', [
    'ArtworkService',
    '$rootScope',
    '$scope',
    '$timeout',
    '$log',
    '$state',
    '$stateParams',
    'Messages',
    'DiscoverDataService',
    '$window',
    '_',
    function (
        ArtworkService,
        $rootScope,
        $scope,
        $timeout,
        $log,
        $state,
        $stateParams,
        Messages,
        DiscoverDataService,
        $window,
        _
    ) {
        $scope.artworksName = $stateParams.artworksName;
        $scope.$emit('DiscoverArtworksMainController:artworks_name_changed', $stateParams.artworksName);
        $scope.ArtworkService = ArtworkService;
        $scope.leftArtworkIndexList = {};
        $scope.rightArtworkIndexList = {};
        $scope.offset = 0;
        var initial = true;

        // Genres feature ->
        $scope.themes = [];
        $scope.selectedGenre = $rootScope.$root.$state.params.theme || null;
        // null - no orientation filter, true - portrait / both, false - landscape / both
        $scope.selectedOrientation = $rootScope.$root.$state.params.portrait || null;

        $scope.resetGenreFilter = function () {
            DiscoverDataService.setCachedDisplayArtworks(null);
            $state.go('discover.artworks.main', { ...$state.params, theme: null, artworksName: 'all' });
        };

        $rootScope.$on('$locationChangeSuccess', function (event) {
            if ($rootScope.$root.$state.params.theme !== $scope.selectedGenre) {
                $scope.selectedGenre = $rootScope.$root.$state.params.theme;
            }

            if ($rootScope.$root.$state.params.portrait !== $scope.selectedOrientation) {
                $scope.selectedOrientation = $rootScope.$root.$state.params.portrait;
            }
        });
        // End of genres feature --//

        function loadedArtworks() {
            $scope.limit = 20;
            $scope.noMoreResults = false;
            $scope.loading = false;
            $scope.initItemsToLoad();
            $scope.lastScrollItemNumber = null;

            $timeout(function () {
                // $scope.displayArtworks = displayArtworks;
                $scope.$emit('DiscoverArtworksMainController:artworks_refreshed');
            }, 100);

            $timeout(function () {
                $scope.loaded = true;
            }, 2000);
        }

        function populateArtworkIndexList(artworks) {
            $scope.leftArtworkIndexList = {};
            $scope.rightArtworkIndexList = {};
            var leftSum = 0;
            var rightSum = 0;
            artworks.forEach(function (artwork, index) {
                var isPortrait = artwork.display_guidelines && artwork.display_guidelines.portrait_mode;
                var currentSum = isPortrait ? 2 : 1;
                if (leftSum > rightSum) {
                    rightSum += currentSum;
                    $scope.rightArtworkIndexList[index] = true;
                } else {
                    leftSum += currentSum;
                    $scope.leftArtworkIndexList[index] = true;
                }
            });
        }

        function loadArtworks() {
            var displayArtworks = DiscoverDataService.getCachedDisplayArtworks();
            if (displayArtworks && displayArtworks.length > 0) {
                $scope.displayArtworks = displayArtworks;
                populateArtworkIndexList($scope.displayArtworks);
                loadedArtworks();
                $scope.offset = $scope.displayArtworks.length;
            } else {
                $scope.loading = true;

                DiscoverDataService.getArtworksToDisplay(
                    $stateParams.artworksName,
                    $rootScope.discoverItemsToLoad,
                    null,
                    $scope.selectedGenre,
                    $scope.selectedOrientation
                ).then(
                    function (value) {
                        $scope.loading = false;
                        DiscoverDataService.setCachedDisplayArtworks(value);
                        $scope.displayArtworks = DiscoverDataService.getCachedDisplayArtworks();
                        populateArtworkIndexList($scope.displayArtworks);
                        loadedArtworks();
                        initial = false;
                    },
                    function (reason) {
                        $scope.loading = false;
                        $log.debug('discover.artworks.main::', {
                            state: $state,
                            stateParams: $stateParams,
                            reason: reason,
                        });
                        var messageParams = {};
                        messageParams.message = 'The URL is not valid, redirecting.';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                            $rootScope.previousState('home');
                        });
                    }
                );
            }
        }

        $scope.init = async function () {
            loadArtworks();
            // Loaded channels using $timeout to enable animation on them.
            // $scope.displayArtworks = [];
        };

        $scope.loadMore = function (limit) {
            $scope.loading = true;
            var currentLimit = limit || $scope.limit;
            if (!initial) {
                $scope.offset += currentLimit;
            }
            initial = false;
            DiscoverDataService.getArtworksToDisplay(
                $scope.artworksName,
                currentLimit,
                $scope.offset,
                $scope.selectedGenre,
                $scope.selectedOrientation
            ).then(function (value) {
                $scope.displayArtworks = $scope.displayArtworks.concat(value);
                populateArtworkIndexList($scope.displayArtworks);
                DiscoverDataService.setCachedDisplayArtworks($scope.displayArtworks);
                if (!value.length || value.length < currentLimit) {
                    $scope.noMoreResults = true;
                }
                $scope.loading = false;
                $scope.$emit('DiscoverArtworksMainController:artworks_refreshed');
            });
        };

        function pageScrollEvent() {
            if (!$scope.loading) {
                $scope.loading = true;
                var windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
                var body = document.body,
                    html = document.documentElement;
                var docHeight = Math.max(
                    body.scrollHeight,
                    body.offsetHeight,
                    html.clientHeight,
                    html.scrollHeight,
                    html.offsetHeight
                );
                var windowBottom = windowHeight + window.pageYOffset;
                if (windowBottom >= docHeight - 800 && !$scope.noMoreResults) {
                    $scope.loadMore();
                } else {
                    $scope.loading = false;
                }
            }
        }
        angular.element($window).on('scroll', pageScrollEvent);

        $scope.$on('$destroy', function () {
            angular.element($window).off('scroll', pageScrollEvent);
        });
        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
            angular.element($window).unbind('scroll');
            if (fromState.name === 'discover.artworks.main') {
                if (toParams.artworkId) {
                    var foundArtworkIndex = _.findIndex($scope.displayArtworks, function (displayArtwork) {
                        return displayArtwork.id === toParams.artworkId;
                    });

                    if (foundArtworkIndex) {
                        $scope.lastScrollItemNumber = foundArtworkIndex;
                        $rootScope.discoverItemsToLoad = $rootScope.discoverItemsToLoad + $scope.lastScrollItemNumber;
                    }
                }
            }
        });
    },
]);
