'use strict';

angular.module('core').service('GoogleAnalytics', [
    'ENV',
    'PlatformManager',
    '$window',
    '$rootScope',
    '$location',
    '$log',
    'GOOGLE_ANALYTICS',
    function (ENV, PlatformManager, $window, $rootScope, $location, $log, GOOGLE_ANALYTICS) {
        let self = this;
        this.measurementID = GOOGLE_ANALYTICS;
        this.$window = $window;

        let custom_dim = {
            user_title: 'dimension1',
        };

        function gtag() {
            if (dataLayer !== undefined) {
                $log.debug('GA4! Datalayer: ', dataLayer);
                dataLayer.push(arguments);
            } else {
                $log.debug('GA4! Datalayer undefined', dataLayer);
            }
        }

        // Listen for route changes
        $rootScope.$on('$stateChangeSuccess', function () {
            // Send a page_view event to GA4
            $log.debug('GA4! Route change success, reporting to GA4', $location.path());

            gtag('event', 'page_view', {
                page_path: $location.path(),
                search_query: $location.search().query,
            });
        });

        this.setUserProperty = function (propertyName, propertyValue) {
            // Taken from here: https://support.google.com/analytics/answer/12370404?hl=en
            $log.debug('GA4! Setting user property', propertyName, propertyValue);

            gtag('set', 'user_properties', {
                [propertyName]: propertyValue,
                user_id: $rootScope.$user.getId(),
            });
        };

        this.sendEvent = function (eventId, eventParams) {
            $log.debug('GA4! Sending even', eventId, eventParams);
            if (eventId !== undefined) {
                // custom_dim[eventId] = value;
                // let eventParams = {};
                // eventParams[eventId] = value + '';
                gtag('event', eventId, eventParams);
            }
        };

        this.setUserId = function (userId) {
            $log.debug('GA4! Setting user id', userId);

            gtag('config', self.measurementID, {
                user_id: userId,
            });
        };
    },
]);
