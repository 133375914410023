angular.module('billing').controller('BillingPlanSubscribeController', [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    '$window',
    '$sce',
    '$log',
    '$stateParams',
    '$location',
    'FormValidationService',
    'Messages',
    'User',
    'OpenPopupForm',
    'BillingService',
    'NIIO_URL',
    '_',
    function (
        $rootScope,
        $scope,
        $state,
        $timeout,
        $window,
        $sce,
        $log,
        $stateParams,
        $location,
        FormValidationService,
        Messages,
        User,
        OpenPopupForm,
        BillingService,
        NIIO_URL,
        _
    ) {
        const self = this;
        this.planExpireDaysCount = 0;
        this.currentDate = new Date();
        this.currentYear = this.currentDate.getFullYear();
        this.expireDate = '';
        this.isFetched = false;
        this.freeTrailExpireString = '';
        this.countries = FormValidationService.countryListAllIsoData;
        this.states = FormValidationService.states;
        this.BillingService = BillingService;
        this.plans = this.BillingService.plans;
        this.billingTypes = this.BillingService.billingTypes;
        this.Messages = Messages;
        this.User = User;
        this.elite = '4f9800fe-0cb2-11e8-ac68-55f1bcd80799';
        this.pro = '4f9800fd-0cb2-11e8-ac68-55f1bcd80799';
        this.prime = '4f9800f7-0cb2-11e8-ac68-55f1bcd80799';
        this.plus = '4f9800fb-0cb2-11e8-ac68-55f1bcd80799';
        this.divisionBy = 1;
        this.couponCode = '';
        this.couponCodeFetching = false;
        this.planActionButton = 'Continue';
        this.planPrice = 0;
        this.planPriceCouponFull = null;
        this.planPriceCoupon = null;
        this.planCurrency = '';
        this.firstName = $rootScope.$user.getFirstName();
        this.lastName = $rootScope.$user.getLastName();
        this.email = $rootScope.$user.getEmail();
        this.company = '';
        this.country = this.countries.find((i) => i.name === $rootScope.$user.getCountry()) || '';
        this.state = $rootScope.$user.getState();
        this.city = $rootScope.$user.getCity();
        this.street = '';
        this.zip = '';
        this.isDowngrade = null;
        this.selectedPlanData = null;

        $scope.$watch(
            'vm.selectedPlan',
            (newVal, oldVal, $scope) => {
                self.selectedPlanData = self.plans.find((plan) => plan.uid === newVal) || null;

                if (self.selectedPlanData !== null) {
                    const currentPlanIndex = this.plans.findIndex((i) => i.uid === this.selectedPlanData.uid);
                    this.isDowngrade = !this.plans[currentPlanIndex].upgradable;
                }
            },
            true
        );

        $scope.$watch(
            'vm.billingEmail',
            (newVal, oldVal, $scope) => {
                const finalBillingEmail = this.billingEmail || this.email;
                if (finalBillingEmail === $rootScope.$user.getEmail()) {
                    return;
                }

                const messageParams = {};
                messageParams.title = 'WARNING!';
                messageParams.message = `Please note: Your billing information will be sent to ${this.billingEmail} which is different than the email you are using in your Niio account.`;
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            },
            true
        );

        this.assetsBaseUrl = BillingService.assetsBaseUrl;

        // States
        this.isFetched = false;
        this.isPaymentSourcesFetched = false;
        $scope.$on('load', () => {
            this.isPaymentSourcesFetched = true;
        });

        $scope.$on('submit', (event, subscriptionData) => {
            $rootScope.$broadcast('ajax-start');
            BillingService.submitBillingInfoAndSubscribe(subscriptionData)
                .then((res) => {
                    if (self.fromIframe) {
                        // Payment completed, on Android, the app injects this function to close the webview
                        if (window.closeWebview && typeof window.closeWebview === 'function') {
                            window.closeWebview();
                        }
                    } else {
                        Messages.ok();
                        $rootScope.$emit('user_subscribed');

                        // Downgrade flow shouldn't show green strip as the result of this function won't affect immediately
                        $rootScope.backFromPaymentBool = !this.isDowngrade;
                        $rootScope.backFromPayment();
                        delete $rootScope.backFromPaymentBool;

                        if (!self.fromJoin) {
                            $state.go('discover.channels.main', { channelsName: 'featured' });
                        }
                    }
                })
                .catch((error) => {
                    try {
                        var errorParsed = self.parseError(error.data.message.text);
                        if (this.errorMessages[errorParsed.field]) {
                            this.errorMessages[errorParsed.field].active = true;
                        } else {
                            this.errorMessages.general.message = errorParsed.message;
                            this.errorMessages.general.active = true;
                        }
                    } catch (e) {
                        this.errorMessages.general.active = true;
                    }
                })
                .finally(() => {
                    $rootScope.$broadcast('ajax-stop');
                    $scope.$broadcast('submit-done');
                });
        });

        this.init = async () => {
            $stateParams = Object.assign($stateParams, self.params); // self.params are the params from modal (templateParams)
            self.fromModal = $stateParams.fromModal;
            self.fromJoin = $stateParams.fromJoin;
            self.fromIframe = $stateParams.fromIframe;

            var xxlModal = document.getElementsByClassName('modal-xxl');
            if (xxlModal && xxlModal[0]) {
                xxlModal[0].style = 'min-height: 100%';
            }

            BillingService.getSubscriptionPlans().then(
                (dbPlans) => {
                    self.isFetched = true;

                    // Check if plan is exists
                    var currentPlan = null;

                    // TODO: Refactor use BillingService.getPlanById
                    _.forEach(dbPlans, (plan) => {
                        var isCurrentMonthly = plan.options.monthly.provider_plan_id === $stateParams.planName;
                        var isCurrentAnnually = plan.options.annually.provider_plan_id === $stateParams.planName;

                        if (isCurrentMonthly || isCurrentAnnually) {
                            self.selectedPaymentCycle = isCurrentMonthly ? self.billingTypes[0] : self.billingTypes[1];
                            self.divisionBy = isCurrentMonthly ? 1 : 12;
                            currentPlan = plan;
                        }
                    });
                    if (!currentPlan) return $state.go('plans');

                    const activePlan = self.User.getPlan();
                    self.selectedPlan = currentPlan.uid;
                    const activePlanIndex = self.plans.findIndex((i) => i.uid === activePlan);
                    if (activePlanIndex !== -1) {
                        const currentPlanIndex = self.plans.findIndex((i) => i.uid === currentPlan.uid);
                        self.isDowngrade = !self.plans[currentPlanIndex].upgradable;
                        self.planActionButton = self.isDowngrade ? 'Downgrade' : 'Upgrade';
                        if (activePlanIndex === currentPlanIndex) {
                            self.planActionButton = 'Already your plan';
                            var messageParams = {};
                            messageParams.message =
                                'Already your plan, if you want switch billing cycle please contact support';
                            Messages.openMessage($rootScope, messageParams).then(() => {
                                $state.go('plans');
                            });
                        } else if (self.isDowngrade) {
                            var messageParams = {};
                            messageParams.message =
                                'Downgrade will occur automatically at the end of current billing cycle.';
                            messageParams.title = 'Downgrade Plan';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    } else {
                        self.planActionButton = currentPlan.actionButton;
                    }

                    self.planPriceFull = currentPlan.options[self.selectedPaymentCycle.id].price;
                    self.planPrice = self.planPriceFull / self.divisionBy;
                    self.planCurrency = currentPlan.options[self.selectedPaymentCycle.id].currency;
                    self.itemPriceId = currentPlan.options[self.selectedPaymentCycle.id].provider_plan_id;
                    self.planExpireDaysCount = Number(currentPlan.planExpireDaysCount);
                    self.freeTrailExpireDate = new Date(
                        self.currentDate.setDate(self.currentDate.getDate() + self.planExpireDaysCount)
                    )
                        .toDateString()
                        .substring(4);
                },
                (err) => {
                    $log.debug('Something went wrong...', err);
                    var messageParams = {};
                    messageParams.message = 'Something went wrong.';
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        };

        this.parseError = function (errorText) {
            var errorParts = errorText.split(' : ', 2);
            var erroredField = errorParts[0];
            var errorMessage = errorParts[1];

            if (erroredField.indexOf(' ') !== -1) {
                erroredField = null;
                errorMessage = errorText;
            }

            return {
                field: erroredField,
                message: errorMessage,
            };
        };
    },
]);
