'use strict';

angular.module('core').controller('ArtworkController', [
    '$scope',
    '$element',
    '$attrs',
    'ArtworkService',
    'User',
    'UploadProgress',
    'S3Uploader',
    '$state',
    '$filter',
    '_',
    'OpenPopupForm',
    '$timeout',
    'CHANNEL_COMMERCIAL_RULES',
    'FavoritesService',
    function (
        $scope,
        element,
        attrs,
        ArtworkService,
        User,
        UploadProgress,
        S3Uploader,
        $state,
        $filter,
        _,
        OpenPopupForm,
        $timeout,
        CHANNEL_COMMERCIAL_RULES,
        FavoritesService
    ) {
        $scope.FavoritesService = FavoritesService;

        // scope: {
        //     'artwork': '=',
        //     'editEnabled': '=',
        //     'adminPanelEnabled': '=',
        //     'isInWishlist': '@',
        //     'editLink': '=',
        //     'deleteArtwork': '&',
        // },
        if (!$scope.artwork) {
            return;
        }
        $scope.ArtworkService = ArtworkService;
        $scope.$user = User;
        $scope.profile = User.getUserDetailsWithBind();
        $scope.$state = $state;
        $scope.metaDescription = '';

        // Conditional ui-sref. Taken from http://stackoverflow.com/questions/28973530/disable-ui-sref-based-on-a-condition
        $scope.goState = $state.go.bind($state);

        // scope.isOwner = scope.artwork.user_id === User.getId();
        $scope.isOwner = function () {
            return $scope.artwork.user_id === User.getId();
        };

        $scope.isConsignee = function () {
            return $scope.artwork.is_consignee;
        };
        $scope.userId = User.getId();
        $scope.showActions = $scope.actionsEnabled !== undefined ? $scope.actionsEnabled : true;
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.UploadProgress = UploadProgress;
        $scope.S3Uploader = S3Uploader;
        $scope.hideManage = $scope.hideManage !== undefined ? $scope.hideManage : false;
        $scope.showManage =
            ($scope.isOwner() || User.isAdmin() || $scope.isConsignee()) && !$scope.hideManage;
        $scope.showTransfer =
            ($scope.artwork.status === 'READY' || $scope.artwork.status === 'PREVIEW_READY') &&
            ($scope.isOwner() || User.isAdmin() || $scope.isConsignee());
        $scope.showEdit =
            $scope.editLink &&
            (($scope.editEnabled && $scope.isOwner()) || User.isAdmin() || $scope.isConsignee());
        $scope.editText = $scope.editText || '';
        $scope.hideDelete = $scope.hideDelete !== undefined ? $scope.hideDelete : false;
        $scope.showDelete =
            !$scope.hideDelete &&
            $scope.deleteArtwork &&
            (($scope.editEnabled && $scope.isOwner()) || User.isAdmin());
        $scope.hideAdminPanelDelete = User.isProCurator();
        $scope.showWishlist =
            !(
                ($state.includes('home') && !$scope.FavoritesService.favorites.artworks[$scope.artwork.id]) ||
                $scope.isOwner()
            ) && $state.params.share === undefined;
        $scope.showAdminPanel =
            $scope.adminPanelEnabled && User.hasRole([User.getAdminRoleId(), User.getProCuratorRoleId()]);
        $scope.isHidden = !$scope.artwork.visible && $scope.isOwner();
        $scope.isHiddenByCreator = !$scope.artwork.visible && !$scope.isOwner();
        $scope.isHiddenByAccount = $scope.artwork.visible && $scope.isOwner() && !User.isVisible();
        $scope.isPurchased = $scope.artwork.is_purchased;
        $scope.hidePrices = $scope.hidePrices !== undefined ? $scope.hidePrices : false;
        $scope.partOfLoanChannel =
            $scope.channel &&
            ($scope.channel.commercial_rule === CHANNEL_COMMERCIAL_RULES.LOAN ||
                $scope.channel.commercial_rule === CHANNEL_COMMERCIAL_RULES.SELL_LOAN);
        // $scope.price = $scope.partOfLoanChannel ? $scope.artwork.loan_price : $scope.artwork.price;
        // $scope.currency = $scope.partOfLoanChannel ? $scope.artwork.loan_currency : $scope.artwork.currency;
        $scope.showPrice =
            (!$scope.partOfLoanChannel &&
                !$scope.isPurchased &&
                ($scope.artwork.price ||
                    $scope.artwork.sale_type === 'FRE' ||
                    $scope.artwork.sale_type === 'FCC')) ||
            User.isAdmin() ||
            $scope.isConsignee();
        // $scope.isMultiActionsEnable = $scope.showDelete; //TODO: Duplicate with isMultiActionsEnable.
        $scope.noActions = $scope.noActions !== undefined ? $scope.noActions : false;
        $scope.showEventRating = $scope.showEventRating !== undefined ? $scope.showEventRating : false;
        $scope.showSocialShare =
            (!$scope.channel || $scope.channel.visible) &&
            ($scope.artwork.status === 'READY' || $scope.artwork.status === 'PREVIEW_READY') &&
            ($scope.artwork.editions || $scope.isOwner() || User.isAdmin() || $scope.isConsignee()) &&
            !$scope.eventId;
        $scope.isPortrait =
            $scope.artwork.display_guidelines && $scope.artwork.display_guidelines.portrait_mode;
        $scope.angle = 0;
        if ($scope.artwork.display_guidelines) {
            switch ($scope.artwork.display_guidelines.portrait_submode) {
                case 2:
                    $scope.angle = 270;
                    break;
                case 3:
                    $scope.angle = 90;
                    break;
                case 4:
                    $scope.angle = 180;
                    break;
                default:
                    $scope.angle = 0;
            }
        }

        $scope.metaDescription = ArtworkService.addArtworkMetadataDescription($scope.artwork);
        $scope.onImgLoad = function (artworkId) {
            if ($scope.allowPortrait && $scope.isPortrait) {
                var imageElm = ArtworkService.getCorrectElm('.artworkThumbnailImage' + artworkId);
                var artworkThumbnailElm = ArtworkService.getCorrectElm('.artworkThumbnail' + artworkId);
                if (imageElm && artworkThumbnailElm) {
                    var naturalWidth = imageElm.naturalWidth;
                    var naturalHeight = imageElm.naturalHeight;
                    var imageWidth;
                    var imageHeight;
                    var colWidth = artworkThumbnailElm.children[0].clientWidth;
                    if ($scope.artwork.display_guidelines) {
                        switch ($scope.artwork.display_guidelines.portrait_submode) {
                            case 2: // 270;
                                imageHeight = colWidth + 'px';
                                imageWidth = 'auto';
                                break;
                            case 3: // 90;
                                imageHeight = colWidth + 'px';
                                imageWidth = 'auto';
                                break;
                            case 4: // 180;
                                imageHeight = colWidth + 'px';
                                imageWidth = 'auto';
                                break;
                            default: // 0;
                                imageHeight = '100%';
                                imageWidth = colWidth + 'px';
                        }
                    }
                    if (naturalWidth > naturalHeight) {
                        var tempWidth = naturalWidth;
                        naturalWidth = naturalHeight;
                        naturalHeight = tempWidth;
                    }
                    // else {
                    //     var tempHeight = imageHeight;
                    //     imageHeight = imageWidth;
                    //     imageWidth = tempHeight;
                    // }
                    ArtworkService.thumbnailImageRes[artworkId] =
                        ArtworkService.thumbnailImageRes[artworkId] || {};
                    ArtworkService.thumbnailImageRes[artworkId].width = imageWidth;
                    ArtworkService.thumbnailImageRes[artworkId].height = imageHeight;
                    var newHeight = (naturalHeight * colWidth) / naturalWidth;
                    if (!isNaN(newHeight)) {
                        ArtworkService.thumbnailHeight[artworkId] = newHeight.toFixed(0) + 'px';
                    }
                }
            }
        };

        $scope.artwork.title = $scope.profile?.show_ext
            ? $scope.artwork.title
            : $scope.artwork.title.replace(/\.[^/.]+$/, '');

        $scope.getThumbnailHeight = function (artworkId) {
            if ($scope.allowPortrait && $scope.isPortrait) {
                ArtworkService.thumbnailHeight[artworkId] =
                    ArtworkService.thumbnailHeight[artworkId] || 'auto';
                return { height: ArtworkService.thumbnailHeight[artworkId] };
            }
        };
        $scope.checkRotation = function (artworkId) {
            if ($scope.allowPortrait && $scope.isPortrait) {
                ArtworkService.thumbnailImageRes[artworkId] =
                    ArtworkService.thumbnailImageRes[artworkId] || {};
                ArtworkService.thumbnailImageRes[artworkId].width =
                    ArtworkService.thumbnailImageRes[artworkId].width || 'auto';
                ArtworkService.thumbnailImageRes[artworkId].height =
                    ArtworkService.thumbnailImageRes[artworkId].height || 'auto';
                return {
                    transform: 'rotate(' + $scope.angle + 'deg)',
                    position: 'absolute',
                    width: ArtworkService.thumbnailImageRes[artworkId].width,
                    height: ArtworkService.thumbnailImageRes[artworkId].height,
                };
            }
        };

        //TODO: Duplicate code.
        $scope.latestRating = {
            timer: undefined,
            value: undefined,
            init: function (v) {
                $scope.latestRating.value = v || 0;
            },
            send: function () {
                $scope.latestRating.timer = $scope.setEventRating();
            },
        };

        $scope.setEventRating = function () {
            var valueToSend = $scope.latestRating.value;
            $scope.ArtworkService.handleArtworkAction('eventRating', $scope.artwork, {
                eventId: $scope.eventId,
                rating: valueToSend,
            }).then(function (res) {
                $scope.artwork.artwork_submission.rating = res.new_value;
            });
        };

        if ($scope.showEventRating && $scope.eventId) {
            $scope.latestRating.init($scope.artwork.artwork_submission.rating);
            $scope.$watch('latestRating.value', function (newValue, oldValue) {
                if (newValue !== undefined && $scope.artwork && oldValue !== newValue) {
                    $scope.latestRating.send();
                }
            });

            $scope.$on('artwork_changed_rating', function ($event, artworkId, newValue, eventId) {
                if ($scope.eventId === eventId && $scope.artwork.id === artworkId) {
                    $scope.artwork.artwork_submission.rating = newValue;
                    $scope.latestRating.value = newValue;
                }
            });
        }

        $scope.init_sub_title_line = function () {
            var category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + $scope.artwork.category);

            $scope.artwork.sub_title_line = category + ' by ' + $scope.artwork.artist.display_name;
            if (
                $scope.artwork.uploader &&
                $scope.artwork.artist &&
                $scope.artwork.artist.display_name !== $scope.artwork.uploader.display_name
            ) {
                $scope.artwork.sub_title_line += ' (' + $scope.artwork.uploader.display_name + "'s upload)";
            }
        };

        $scope.artwork.actions = ArtworkService.initActions($scope.artwork);

        $scope.checkIfActiveActionsExists = function () {
            return (
                _.findIndex($scope.artwork.actions, function (action) {
                    return action.active === true;
                }) > -1
            );
        };

        $scope.activeActionsExists = $scope.checkIfActiveActionsExists();

        $scope.$on('artwork_revoked', function (event, artworkId) {
            $scope.checkIfActiveActionsExists();
        });

        $scope.isHome = function () {
            return $state.includes('home');
        };

        $scope.getArtworkStatusAsString = function () {
            if (
                $scope.artwork.status === 'UPLOADING' &&
                $scope.artwork.uploadId &&
                $scope.artwork.uploadId >= 0 &&
                S3Uploader.uploads[$scope.artwork.uploadId].uploaded > 0 &&
                !S3Uploader.uploads[$scope.artwork.uploadId].waiting
            ) {
                return 'Artwork Uploading ' + S3Uploader.uploads[$scope.artwork.uploadId].progress > 100
                    ? 'Still Uploading'
                    : (!S3Uploader.uploads[$scope.artwork.uploadId].progress ||
                      S3Uploader.uploads[$scope.artwork.uploadId].progress === 0
                          ? '0'
                          : S3Uploader.uploads[$scope.artwork.uploadId].progress) + '%';
            }

            if (
                $scope.artwork.status === 'UPLOADING' &&
                $scope.artwork.uploadId !== undefined &&
                $scope.artwork.uploadId !== '' &&
                $scope.artwork.uploadId >= 0 &&
                S3Uploader.uploads[$scope.artwork.uploadId].uploaded === 0 &&
                !S3Uploader.uploads[$scope.artwork.uploadId].waiting
            ) {
                return 'Preparing Upload';
            }

            if (
                $scope.artwork.status === 'UPLOADING' &&
                $scope.artwork.uploadId !== undefined &&
                $scope.artwork.uploadId !== '' &&
                $scope.artwork.uploadId >= 0 &&
                S3Uploader.uploads[$scope.artwork.uploadId].waiting
            ) {
                return 'Upload Will Start Soon';
            }

            if (
                $scope.artwork.status === 'UPLOADING' &&
                !$scope.artwork.uploadId &&
                $scope.artwork.uploadId !== 0
            ) {
                return 'Upload In Process';
            }

            if ($scope.artwork.status === 'UPLOAD_ABORTED') {
                return 'Upload Canceled';
            }

            if ($scope.artwork.status === 'UPLOAD_FAILED') {
                return 'Upload Failed';
            }

            if ($scope.artwork.status === 'DAMAGED') {
                return 'File is damaged, please try another file';
            }

            if ($scope.artwork.status === 'PENDING') {
                return 'Artwork processing...';
            }

            if ($scope.artwork.status === 'FAILED') {
                return 'Artwork processing failed';
            }

            if ($scope.artwork.status === 'PREVIEW_READY') {
                return 'Artwork Preview is ready';
            }

            if ($scope.artwork.status === 'READY') {
                return 'Artwork is ready';
            }

            return '';
        };

        $scope.initRowView = function (artwork) {
            artwork.selected = artwork.selected || false;
        };

        $scope.ArtworkSelected = function () {
            if ($scope.onSelectionChange) {
                $scope.onSelectionChange();
            }
        };

        // TODO RICH: media type should be specific, INTERACTIVE_ZIP
        $scope.artwork.thumbnail_path =
            $scope.artwork.thumbnail_path !== ''
                ? $scope.artwork.thumbnail_path
                : 'modules/home/img/ZipMedia.png';
    },
]);
