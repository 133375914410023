'use strict';

angular.module('player').controller('PlayerController', [
    '$scope',
    '$window',
    function ($scope, $window) {
        $scope.init = function () {
            $window.videojs('example_video_1', {}, function () {
                $scope.player = this;
                // Player (this) is initialized and ready.
            });
        };

        $scope.playStopVideo = function (event) {
            alert(event);
        };

        $scope.$on('$destroy', function () {
            // Destroy the object if it exists
            if ($scope.player !== undefined && $scope.player !== null) {
                $scope.player.dispose();
            }
        });
    },
]);
