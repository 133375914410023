'use strict';

angular.module('devices').controller('DevicesSelectedAATVSettingsController', [
    '$scope',
    '$log',
    '$q',
    '$rootScope',
    '$filter',
    '$state',
    'Messages',
    'DevicesDataService',
    '_',
    function ($scope, $log, $q, $rootScope, $filter, $state, Messages, DevicesDataService, _) {
        let self = this;
        this._ = _;
        this.submitWasClicked = false;

        this.deviceSearchParams = {
            onlyAAFTV: 'true',
        };
        this.init = function () {
            self.submitWasClicked = false;
            self.fromDevice = null;
            self.toDevices = [];
            self.settingsChanged();
            self.createStatusParagraph();
        };

        this.createStatusParagraph = () => {
            // Form the string with the necessary conditions
            self.statusParagraph =
                // `<b>ID</b>: ${$scope.selectedId}` +
                ($scope.settings.Info.DeviceModel
                    ? `<br><b>Device Model</b>: ${$scope.settings.Info.DeviceModel}`
                    : '') +
                ($scope.settings.Info.OSVersion ? `, <br><b>OS version</b>: ${$scope.settings.Info.OSVersion}` : '') +
                ($scope.settings.Info.AppVersion
                    ? `, <br><b>App version</b>: ${$scope.settings.Info.AppVersion}`
                    : '') +
                ($scope.settings.Info.ResourcesVersion
                    ? `, <br><b>Assets version</b>: ${$scope.settings.Info.ResourcesVersion}`
                    : '') +
                ($scope.settings.Info.IP ? `, <br><b>Local IP</b>: ${$scope.settings.Info.IP}` : '') +
                ($scope.settings.Info.WifiMode && $scope.settings.Info.WifiMode !== ''
                    ? `, <br><b>Wifi Mode</b>: ${$filter('translate')(
                          "'WIFI_MODE.MODE_'" + $scope.settings.Info.WifiMode
                      )}`
                    : '') +
                ($scope.selected.last_accessed
                    ? `<br><b>Last Seen</b>: ${$filter('date')($scope.selected.last_accessed, 'MMM d, y HH:mm')}<br>`
                    : '') +
                (($scope.isProCurator || $scope.isAdmin) && $scope.settings.Info.DecodePixelsPerSec
                    ? `<br><b>DecodePixelsPerSec</b>: ${$scope.settings.Info.DecodePixelsPerSec}`
                    : '') +
                (($scope.isProCurator || $scope.isAdmin) && $scope.settings.Info.BlitPixelsPerSec
                    ? `, <br><b>BlitPixelsPerSec</b>: ${$scope.settings.Info.BlitPixelsPerSec}`
                    : '') +
                (($scope.isProCurator || $scope.isAdmin) && $scope.settings.Info.Width
                    ? `, <br><b>Width</b>: ${$scope.settings.Info.Width}`
                    : '') +
                (($scope.isProCurator || $scope.isAdmin) && $scope.settings.Info.Height
                    ? `, <br><b>Height</b>: ${$scope.settings.Info.Height}`
                    : '') +
                (($scope.isProCurator || $scope.isAdmin) && $scope.settings.Info.RefreshRate
                    ? `, <br><b>RefreshRate</b>: ${$scope.settings.Info.RefreshRate}`
                    : '') +
                ($scope.settings.MaintenanceSchedule && $scope.settings.MaintenanceSchedule.length > 0
                    ? `, <br><b>Scheduled Maintenance Actions</b>: ${$scope.settings.MaintenanceSchedule.length}`
                    : '') +
                (($scope.isProCurator || $scope.isAdmin) && $scope.selected?.current_status?.currentArtwork
                    ? `, <br><br><b>Current Artwork</b>: ${$scope.selected.current_status.currentArtwork}`
                    : '') +
                (($scope.isProCurator || $scope.isAdmin) && $scope.selected?.current_status?.currentChannel
                    ? `, <br><b>Current Channel</b>: ${$scope.selected.current_status.currentChannel}`
                    : '');

            // Remove any whitespace before commas
            self.statusParagraph = self.statusParagraph.replace(/\s+,/g, ',');
        };

        this.settingsChanged = () => {
            self.originalSettings = angular.copy($scope.settings) ?? [];
            self.latestSettings = angular.copy($scope.settings) ?? [];
            if (Array.isArray(self.originalSettings.playerControls)) {
                self.originalSettings.playerControls = {};
            }

            if (Array.isArray(self.latestSettings.playerControls)) {
                self.latestSettings.playerControls = {};
            }

            if (Array.isArray(self.originalSettings.appearance)) {
                self.originalSettings.appearance = {};
            }

            if (Array.isArray(self.latestSettings.appearance)) {
                self.latestSettings.appearance = {};
            }

            if (Array.isArray(self.latestSettings.downloads)) {
                self.latestSettings.downloads = {};
            }

            if (Array.isArray(self.originalSettings.downloads)) {
                self.originalSettings.downloads = {};
            }
            $state.params.preventRedirect = !_.isEqual(self.originalSettings, self.latestSettings);
        };

        this.lockChanged = () => {
            $state.params.preventRedirect = !_.isEqual(self.originalSettings, self.latestSettings);
            if (self.latestSettings.lock?.isLocked === 'false' && Object.keys(self.latestSettings.lock).length === 1) {
                delete self.latestSettings.lock;
            }
        };

        this.hideDiscoverChanged = () => {
            $state.params.preventRedirect = !_.isEqual(self.originalSettings, self.latestSettings);
            if (self.latestSettings.appearance?.hideDiscover === 'false') {
                delete self.latestSettings.appearance.hideDiscover;
            }
        };

        this.autoDownloadChanged = () => {
            $state.params.preventRedirect = !_.isEqual(self.originalSettings, self.latestSettings);
            if (self.latestSettings.downloads?.autoDownload === 'false' && !self.originalSettings.downloads) {
                delete self.latestSettings.downloads;
            }

            if (self.latestSettings.downloads?.autoDownload === 'false') {
                delete self.latestSettings.downloads.autoDownload;
            }
        };

        this.updateSettings = () => {
            self.submitWasClicked = true;
            DevicesDataService.updateSettings($scope.deviceOrGroup, $scope.selectedId, self.latestSettings).then(
                function (res) {
                    $scope.settings = self.latestSettings;
                    self.settingsChanged();
                    $scope.sortDevices();
                    $scope.updateChannelsFromSchedules($scope.settings.Schedule);

                    var messageParams = {};
                    messageParams.message = 'Settings were updated successfully';
                    if (!$scope.selected?.is_online) {
                        messageParams.message +=
                            '\nPlease note: The device is offline. Changes will apply once it comes back online.';
                        messageParams.disableAutoDismiss = true;
                    }
                    messageParams.title = 'Settings Update';
                    Messages.openMessage($scope, messageParams);
                    $scope.submitWasClicked = false;
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'Failed to update settings.\nPlease refresh the page and try again.';
                    messageParams.disableAutoDismiss = true;
                    messageParams.title = 'Settings Update Failed';
                    Messages.openMessage($scope, messageParams);
                    $scope.submitWasClicked = false;
                }
            );
        };

        this.copySettings = (from, to) => {
            let deferred = $q.defer();
            self.submitWasClicked = true;
            if (!to) {
                to = [$scope.selectedId];
            } else {
                to = _.map(to, 'id');
            }
            if (!from) {
                from = $scope.selectedId;
            } else {
                from = from.id;
            }
            let params = {};
            if (self.copySchedules) {
                params.copySchedules = true;
            }

            $scope.leaveModalOpen = true;
            event.preventDefault();
            let messageParams = {};
            messageParams.title = 'Copy Settings';
            messageParams.message = 'You are about to copy settings from device id ' + from + ' to devices: ' + to;
            messageParams.message += '\nAre you sure you want to proceed?';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Copy';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    DevicesDataService.copySettings(from, to, params)
                        .then(
                            function (res) {
                                var messageParams = {};
                                messageParams.message =
                                    'Settings were updated successfully from device ' + from + ' to devices: ' + res;
                                messageParams.title = 'Settings Copied';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                                deferred.resolve();
                            },
                            function (error) {
                                var messageParams = {};
                                messageParams.message = 'Failed to copy settings: ' + error?.text;
                                messageParams.title = 'Settings Copy Failed';
                                messageParams.disableAutoDismiss = true;
                                Messages.openMessage($scope, messageParams);
                                deferred.reject();
                            }
                        )

                        .finally(() => {
                            self.submitWasClicked = false;
                            self.fromDevice = null;
                            self.toDevices = [];
                        });

                    return deferred.promise;
                }
            });
        };

        this.removeFrom = () => {
            self.fromDevice = null;
        };

        this.removeTo = (id) => {
            self.toDevices = _.reject(self.toDevices, (device) => device.id === id);
        };

        this.onAddDeviceFrom = (entity) => {
            if (entity?.selected?.type === 'device') {
                // Add device to the copy from field
                self.fromDevice = entity.selected;
            }
        };

        this.onAddDeviceTo = (entity) => {
            if (entity?.selected?.type === 'device') {
                // Add device to the copy from field
                self.toDevices.push(entity.selected);
            }
        };

        this.loadDownloads = (downloads) => {
            if (downloads) {
                debugger;
                // TODO: Load artworks from server
                self.downloads = downloads;
            }
        };

        $scope.$on('settings_fetched', function (event) {
            $scope.$emit('selected_chosen', $scope.selectedId, $scope.deviceOrGroup);
            self.init();
        });

        $scope.$on('device_fetched', function (event) {
            self.init();
        });

        if ($scope.settingsFetched) {
            self.init();
        }

        $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState, fromParams) => {
            if ($state.params.preventRedirect && !$scope.leaveModalOpen) {
                $scope.leaveModalOpen = true;
                $state.params.preventRedirect = false;
            }

            // if ($state.params.preventRedirect && !$scope.leaveModalOpen) {
            //     $scope.leaveModalOpen = true;
            //     event.preventDefault();
            //     let messageParams = {};
            //     messageParams.title = 'Queue Not Saved';
            //     messageParams.message = 'Queue was changed but not saved. Are you sure you want to leave?';
            //     messageParams.disableAutoDismiss = true;
            //     messageParams.cancelText = 'Cancel';
            //     messageParams.okText = 'Leave';
            //     messageParams.okClass = 'danger';
            //     Messages.openMessage($rootScope, messageParams).then(function (res) {
            //         $scope.leaveModalOpen = false;
            //         if (res === 'ok') {
            //             // User chose to leave without saving, continue with the destroy event
            //             $state.params.preventRedirect = false;
            //             $state.go(toState, toParams);
            //         } else {
            //             $state.go(fromState, fromParams);
            //         }
            //     });
            // }
        });
    },
]);
