'use strict';

angular.module('core').filter('showPageAfterSortData', [
    function () {
        return function (input, currentPage, itemsPerPage) {
            currentPage = currentPage || 1;
            input = input || [];
            itemsPerPage = itemsPerPage || 10;
            return input.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
        };
    },
]);
