'use strict';
/*global AWS, angular*/
angular.module('core').service('S3Uploader', [
    '$rootScope',
    '$http',
    '$q',
    '$window',
    '$log',
    '$localStorage',
    '$timeout',
    '$interval',
    'ArtworkService',
    'ChannelService',
    'AdditionalFilesService',
    'Messages',
    'COGNITO_POOL',
    'COGNITO_POOL_REGION',
    'NIIO_DEBUG',
    '_',
    function (
        $rootScope,
        $http,
        $q,
        $window,
        $log,
        $localStorage,
        $timeout,
        $interval,
        ArtworkService,
        ChannelService,
        AdditionalFilesService,
        Messages,
        COGNITO_POOL,
        COGNITO_POOL_REGION,
        NIIO_DEBUG,
        _
    ) {
        var self = this;

        function init() {
            self.resumableUploads = undefined;
            self.maximumActiveUploads = 1;
            self.uploads = [];
            //self.numberOfUploads = 0;
            self.activeUploads = [];
            self.uploadId = 0;
            self.duringSignout = false;
        }

        init();

        $rootScope.$on('InitServices', function () {
            init();
        });

        this.uploadsWithoutCover = function () {
            return _.filter(self.uploads, function (upload) {
                return ['cover', 'profile'].indexOf(upload.extraParams.uploadType) < 0;
            });
        };

        function logLine(uploadId) {
            var uploadParams = self.uploads[uploadId].params;
            var artworkId = uploadParams.extraParams ? uploadParams.extraParams.artworkId : '';
            var artworkLine = artworkId ? ' Artwork Id: ' + artworkId + ',' : '';
            var uploadLine = ' Upload id: ' + uploadId;
            return artworkLine + uploadLine + ' ';
        }

        function checkValidityOfUploadedFile(originalFileSize, bucket, key, uploadId) {
            var deferred = $q.defer();

            var s3Obj = new AWS.S3({ params: { Bucket: bucket } });

            self.generateCognitoIdentity().then(
                function (res) {
                    //var key = 'Local/Artworks/Artwork1665/us-east-1:062178e9-278e-45e8-9569-b43063eb4f49/1446562934384-WXvgGM2b6okHp45K.png';
                    s3Obj.headObject({ Bucket: bucket, Key: key }, function (err, data) {
                        //s3Obj.getObject({Bucket: bucket, Key: key}, function(err, data) {
                        if (err) {
                            if (err.code === 'Forbidden') {
                                $log.debug(
                                    'S3Uploader::checkValidityOfUploadedFile' +
                                        logLine(uploadId) +
                                        'probably valid but could not get object from S3 to see if the size is right, access denied',
                                    {
                                        error: err,
                                        bucket: bucket,
                                        key: key,
                                        uploadId: uploadId,
                                        originalFileSize: originalFileSize,
                                    }
                                );
                                deferred.resolve(data);
                            } else {
                                $log.debug(
                                    'S3Uploader::checkValidityOfUploadedFile' +
                                        logLine(uploadId) +
                                        'is not valid',
                                    {
                                        error: err,
                                        bucket: bucket,
                                        key: key,
                                        uploadId: uploadId,
                                        originalFileSize: originalFileSize,
                                    }
                                );
                                deferred.reject(err);
                            }
                        } else {
                            $log.debug(
                                'S3Uploader::checkValidityOfUploadedFile' +
                                    logLine(uploadId) +
                                    'file exists on S3',
                                data
                            );
                            if (originalFileSize.toString() === data.ContentLength) {
                                $log.debug(
                                    'S3Uploader::checkValidityOfUploadedFile' +
                                        logLine(uploadId) +
                                        'file size matches original file',
                                    { uploadedFile: data, originalSize: originalFileSize }
                                );
                                deferred.resolve(data);
                            } else {
                                $log.debug(
                                    'S3Uploader::checkValidityOfUploadedFile' +
                                        logLine(uploadId) +
                                        'file size does not match original file, new file is not valid',
                                    { uploadedFile: data, originalSize: originalFileSize }
                                );
                                deferred.reject(data);
                            }
                        }
                    });
                },
                function (error) {
                    $log.debug(
                        'S3Uploader::checkValidityOfUploadedFile' +
                            logLine(uploadId) +
                            'failed to get cognito credentials',
                        error
                    );
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }
        // Define event handlers
        function regularUploadProgress(uploadId, params, deferred, e) {
            if (e.lengthComputable) {
                self.uploads[uploadId].progress = Math.round((e.loaded * 100) / e.total);
            } else {
                self.uploads[uploadId].progress = 'unable to compute';
            }
            var msg = {
                uploadMethod: 'regular',
                type: 'progress',
                value: self.uploads[uploadId].progress,
                upload: self.uploads[uploadId],
            };
            $rootScope.$apply(function () {
                $rootScope.$emit('s3upload:progress', msg);
            });

            if (typeof deferred.notify === 'function') {
                deferred.notify(msg);
            }
        }
        function regularUploadComplete(uploadId, params, deferred, e) {
            var xhr = e.srcElement || e.target;
            $rootScope.$apply(function () {
                self.uploads[uploadId].uploading = false;
                if (xhr.status === 204) {
                    // successful upload
                    self.uploads[uploadId].success = true;
                    if (typeof params.onSuccess === 'function') {
                        params.onSuccess();
                    }
                    deferred.resolve(xhr);
                    $rootScope.$emit('s3upload:success', {
                        uploadMethod: 'regular',
                        xhr: xhr,
                        upload: self.uploads[uploadId],
                    });
                } else {
                    self.uploads[uploadId].success = false;
                    if (typeof params.onFailure === 'function') {
                        params.onFailure();
                    }
                    deferred.reject(xhr);
                    $rootScope.$emit('s3upload:error', {
                        uploadMethod: 'regular',
                        xhr: xhr,
                        upload: self.uploads[uploadId],
                    });
                }
                //                        self.removeUpload(uploadId); // remove upload from list
            });
        }
        function regularUploadFailed(uploadId, params, deferred, e) {
            var xhr = e.srcElement || e.target;
            $rootScope.$apply(function () {
                //if (self.numberOfUploads > 0) {
                //    self.numberOfUploads--;
                //}
                self.uploads[uploadId].uploading = false;
                self.uploads[uploadId].success = false;
                self.uploads[uploadId].notSyncedWithServer = true;
                if (typeof params.onFailure === 'function') {
                    params.onFailure();
                }
                deferred.reject(xhr);
                $rootScope.$emit('s3upload:error', {
                    uploadMethod: 'regular',
                    xhr: xhr,
                    upload: self.uploads[uploadId],
                });
                //                        self.removeUpload(uploadId); // remove upload from list
            });
        }
        function regularUploadCanceled(uploadId, params, deferred, e) {
            var xhr = e.srcElement || e.target;

            $rootScope.$apply(function () {
                // TODO - Writing to local storage right after refresh doesn't work!
                //if (self.numberOfUploads > 0) {
                //    self.numberOfUploads--;
                //}
                self.uploads[uploadId].aborted = true;
                self.uploads[uploadId].uploading = false;
                self.uploads[uploadId].success = false;
                self.uploads[uploadId].notSyncedWithServer = true;
                deferred.reject(xhr);
                $rootScope.$emit('s3upload:abort', {
                    uploadMethod: 'regular',
                    xhr: xhr,
                    upload: self.uploads[uploadId],
                });
                //                        self.removeUpload(uploadId); // remove upload from list
            });
        }
        function regularUpload(file, params, deferred) {
            var fd = new FormData();
            fd.append('key', params.key);
            fd.append('acl', params.acl);
            fd.append('Content-Type', file.type);
            fd.append('AWSAccessKeyId', params.accessKey);
            fd.append('policy', params.policy);
            fd.append('signature', params.signature);
            fd.append('file', file);

            var xhr = new XMLHttpRequest();
            xhr.upload.addEventListener(
                'progress',
                self.partial(regularUploadProgress, self.uploadId, params, deferred),
                false
            );
            xhr.addEventListener(
                'load',
                self.partial(regularUploadComplete, self.uploadId, params, deferred),
                false
            );
            xhr.addEventListener(
                'error',
                self.partial(regularUploadFailed, self.uploadId, params, deferred),
                false
            );
            xhr.addEventListener(
                'abort',
                self.partial(regularUploadCanceled, self.uploadId, params, deferred),
                false
            );

            // Send the file
            var s3Uri = 'https://' + params.bucket + '.s3.amazonaws.com/';
            xhr.open('POST', s3Uri, true);
            xhr.send(fd);

            return { xhr: xhr, formData: fd };
        }
        function multipartUploadProgress(params, deferred, progress) {
            //$rootScope.$apply(function () {
            if (params.originalSize === progress.total) {
                var endTime = new Date().getTime();
                var uploaded = self.uploads[params.uploadId].uploaded;
                var lastUpTime = self.uploads[params.uploadId].lastUpTime;
                self.uploads[params.uploadId].speed = Math.round(
                    ((progress.loaded - uploaded) * 1000) / (endTime - lastUpTime)
                );
                self.uploads[params.uploadId].progress = Math.round((progress.loaded / progress.total) * 100);
                self.uploads[params.uploadId].total = progress.total;
                var msg = {
                    uploadMethod: 'multipart',
                    type: 'progress',
                    value: self.uploads[params.uploadId].progress,
                    loaded: progress.loaded,
                    total: progress.total,
                    speed: self.uploads[params.uploadId].speed,
                    upload: self.uploads[params.uploadId],
                };
                self.uploads[params.uploadId].uploading = true;
                self.uploads[params.uploadId].uploaded = progress.loaded;
                self.uploads[params.uploadId].lastUpTime = endTime;
                self.uploads[params.uploadId].failInProgress = false;
                $rootScope.$apply(function () {
                    $rootScope.$emit('s3upload:progress', msg);
                });
                //$log.debug('S3Uploader::multipartUpload Progress ' + Math.round(progress.loaded / progress.total * 100) + '% done, Speed: ' + speed + 'bytes
                // /s', progress);
            } else {
                if (!self.uploads[params.uploadId].failInProgress) {
                    self.uploads[params.uploadId].failInProgress = true;
                    //alert('File size is not valid! ID: ' + params.extraParams.artworkId + ', upload ID: ' + params.uploadId);
                    multipartUploadFailed(params, deferred, { code: 'FileNotValid' });
                }
            }
            //});
        }

        function showErrorMessage(error) {
            var messageParams = {};

            if (error.code && error.code === 'NetworkingError') {
                // Show message if upload abort was done not due to signout process
                if (!self.duringSignout) {
                    messageParams.title = 'Error uploading artwork';
                    messageParams.message =
                        'It seems that you had network failure. Please click on retry.\nIf the problem persists, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            } else if (error.code && error.code === 'RequestTimeTooSkewed') {
                // Show message if upload abort was done not due to signout process
                if (!self.duringSignout) {
                    messageParams.title = 'Error uploading artwork';
                    messageParams.message =
                        "It seems that your date & time configurations are skewed. Please correct your operation system's time & date configurations to be set automatically and click on retry.\nIf the problem persists, please contact support.";
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            } else if (error.code !== 'RequestAbortedError') {
                // Show message if upload abort was done not due to signout process
                if (!self.duringSignout) {
                    messageParams.title = 'Error uploading artwork';
                    messageParams.message =
                        'Failed to upload artwork. Please click on retry.\nIf the problem persists, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            }
        }

        function getActiveUpload(uploadId) {
            return self.activeUploads.indexOf(uploadId);
        }

        function addActiveUpload(uploadId) {
            var activeUploadIndex = getActiveUpload(uploadId);
            if (activeUploadIndex < 0) {
                $log.debug(
                    'S3Uploader::multipartUpload' + logLine(uploadId) + 'Adding upload to active list'
                );
                self.activeUploads.push(uploadId);
            }
        }

        function removeActiveUpload(uploadId) {
            var activeUploadIndex = getActiveUpload(uploadId);
            if (activeUploadIndex > -1) {
                $log.debug(
                    'S3Uploader::multipartUpload' + logLine(uploadId) + 'Removing upload to active list'
                );
                self.activeUploads.splice(activeUploadIndex, 1);
            }
        }

        // Taken from here: https://github.com/aws/aws-sdk-js/issues/399
        AWS.events.on('retry', function (response) {
            if (response.error.name === 'RequestTimeTooSkewed') {
                $log.debug('User time is not correct. Handling error!');
                $log.debug('AWS systemClockOffset:', AWS.config.systemClockOffset);
                var serverTime = Date.parse(response.httpResponse.headers.date);
                var timeNow = new Date().getTime();
                $log.debug('AWS timestamp:', new Date(serverTime));
                $log.debug('Browser timestamp:', new Date(timeNow));
                AWS.config.systemClockOffset = Math.abs(timeNow - serverTime);
                response.error.retryable = true;
                $log.debug('Setting systemClockOffset to:', AWS.config.systemClockOffset);
                $log.debug('Retrying uploading to S3 once more...');
            }
        });

        function multipartUploadFailed(params, deferred, error) {
            $log.debug(
                'S3Uploader::multipartUploadFailed' +
                    logLine(params.uploadId) +
                    "Failed. Maybe it's in retrying process? " +
                    (self.uploads[params.uploadId].retrying ? 'Yes' : 'No'),
                { error: error, params: params, upload: self.uploads[params.uploadId] }
            );

            if (!self.uploads[params.uploadId].retrying && !self.uploads[params.uploadId].aborted) {
                $rootScope.decreaseImportantRequests();
                if (error && error.code) {
                    if (
                        error.code === 'RequestAbortedError' ||
                        ((error.code === 'FileNotValid' || error.code === 'RequestTimeTooSkewed') &&
                            self.uploads[params.uploadId].restarted >= 3)
                    ) {
                        self.uploads[params.uploadId].uploading = false;
                        self.uploads[params.uploadId].success = false;
                        self.uploads[params.uploadId].aborted = true;
                        self.uploads[params.uploadId].notSyncedWithServer = true;
                        //if (self.numberOfUploads > 0) {
                        $log.debug(
                            'S3Upload::multipartUploadFailed' +
                                logLine(params.uploadId) +
                                'Decreasing numberOfUplaods',
                            { activeUploads: self.activeUploads }
                        );
                        //self.numberOfUploads--;
                        removeActiveUpload(params.uploadId);
                        if (!self.duringSignout) {
                            startNextArtworkUploadInQueue();
                        }

                        var entityId;

                        //}
                        if (
                            params.extraParams &&
                            params.extraParams.entityType &&
                            params.extraParams[params.extraParams.entityType + 'Id']
                        ) {
                            if (error.code === 'RequestAbortedError') {
                                if (params.extraParams.isPreviewFile || params.extraParams.isAdditionalFile) {
                                    entityId = params.extraParams[params.extraParams.entityType + 'Id'];
                                    AdditionalFilesService.updateEntityAdditionalFileStatus(
                                        entityId,
                                        params.extraParams.entityType,
                                        {
                                            status: 'UPLOAD_ABORTED',
                                            reason: 'multipartUploadFailed::RequestAbortedError Additional File User aborted',
                                            additionalFileId: params.extraParams.additionalFileId,
                                        }
                                    ).then(function (entityId) {
                                        $rootScope.$broadcast(
                                            params.extraParams.entityType + '_additional_file_changed_status',
                                            entityId,
                                            'UPLOAD_ABORTED'
                                        );
                                    });
                                } else {
                                    ArtworkService.updateArtworkStatus(params.extraParams.artworkId, {
                                        status: 'UPLOAD_ABORTED',
                                        reason: 'multipartUploadFailed::RequestAbortedError User aborted',
                                    }).then(function (artworkId) {
                                        $rootScope.$broadcast(
                                            'artwork_changed_status',
                                            artworkId,
                                            'UPLOAD_ABORTED'
                                        );
                                    });
                                }

                                //$rootScope.$apply(function () {
                                $rootScope.$emit('s3upload:abort', {
                                    uploadMethod: 'multipart',
                                    multipartUploadObj: params.multipartUploadObj,
                                    upload: self.uploads[params.uploadId],
                                });
                                //});
                                showErrorMessage(error);
                            } else {
                                if (params.extraParams.isPreviewFile || params.extraParams.isAdditionalFile) {
                                    entityId = params.extraParams[params.extraParams.entityType + 'Id'];
                                    AdditionalFilesService.updateEntityAdditionalFileStatus(
                                        params.extraParams[params.extraParams.entityType + 'Id'],
                                        params.extraParams.entityType,
                                        {
                                            status: 'UPLOAD_FAILED',
                                            reason:
                                                'multipartUploadFailed::RequestAbortedError Additional File Error' +
                                                error.code,
                                            additionalFileId: params.extraParams.additionalFileId,
                                        }
                                    ).then(function (entityId) {
                                        $rootScope.$broadcast(
                                            params.extraParams.entityType + '_additional_file_changed_status',
                                            entityId,
                                            'UPLOAD_FAILED'
                                        );
                                    });
                                } else {
                                    ArtworkService.updateArtworkStatus(params.extraParams.artworkId, {
                                        status: 'UPLOAD_FAILED',
                                        reason: 'multipartUploadFailed::' + error.code,
                                    }).then(function (artworkId) {
                                        $rootScope.$broadcast(
                                            'artwork_changed_status',
                                            artworkId,
                                            'UPLOAD_FAILED'
                                        );
                                    });
                                }

                                //$rootScope.$apply(function () {
                                $rootScope.$emit('s3upload:error', {
                                    uploadMethod: 'multipart',
                                    multipartUploadObj: params.multipartUploadObj,
                                    upload: self.uploads[params.uploadId],
                                });
                                //});
                                showErrorMessage(error);
                            }
                        }
                        if (typeof params.onFailure === 'function') {
                            params.onFailure();
                        }
                        if (deferred) {
                            deferred.reject(error);
                        }
                    } else if (error.code === 'AccessDenied') {
                        $log.debug(
                            'S3Uploader::multipartUpload ACCESS DENIED!' +
                                logLine(params.uploadId) +
                                'Failed',
                            { error: error, params: params, upload: self.uploads[params.uploadId] }
                        );
                    } else if (error.code === 'FileNotValid' && self.uploads[params.uploadId].restarted < 3) {
                        $log.debug(
                            'S3Uploader::multipartUpload FileNotValid and restarted ' +
                                self.uploads[params.uploadId].restarted +
                                ' times. restarting upload',
                            self.upload
                        );
                        self.restartUpload(params.uploadId);
                    } else if (
                        error.code === 'RequestTimeTooSkewed' &&
                        self.uploads[params.uploadId].restarted < 3
                    ) {
                        // Auto clock sync is being run
                        $log.debug(
                            'S3Uploader::multipartUpload RequestTimeTooSkewed and restarted ' +
                                self.uploads[params.uploadId].restarted +
                                ' times. restarting upload',
                            self.upload
                        );
                        self.restartUpload(params.uploadId);
                    }
                    //else if (error.code === 'FileNotValid') {
                    //    $log.debug('S3Uploader::multipartUpload File is not valid! Upload ' + params.uploadId + ' Failed', {error: error, params: params, upload: self.uploads[params.uploadId]});
                    //    alert('File is not valid! ID: ' + params.extraParams.artworkId + ', upload ID: ' + params.uploadId + ' retrying number ' + self.uploads[params.uploadId].retries);
                    //    self.retryUpload(params.uploadId);
                    //
                    //}
                }
            }
        }
        function multipartUploadComplete(params, deferred, response) {
            $log.debug(
                'S3Uploader::multipartUpload' +
                    logLine(params.uploadId) +
                    'Completed, checking new file validity',
                response
            );

            checkValidityOfUploadedFile(params.file.size, params.bucket, params.key, params.uploadId).then(
                function (res) {
                    $rootScope.decreaseImportantRequests();

                    //if (self.numberOfUploads > 0) {
                    $log.debug('S3Upload::multipartUploadComplete Decreasing activeUploads', {
                        activeUploads: self.activeUploads,
                    });
                    //self.numberOfUploads--;
                    removeActiveUpload(params.uploadId);
                    if (!self.duringSignout) {
                        startNextArtworkUploadInQueue();
                    }
                    //}
                    self.uploads[params.uploadId].uploading = false;
                    self.uploads[params.uploadId].success = true;
                    if (typeof params.onSuccess === 'function') {
                        params.onSuccess();
                    }
                    deferred.resolve(response);
                    if (
                        params.extraParams.entityType &&
                        params.extraParams[params.extraParams.entityType + 'Id']
                    ) {
                        if (params.extraParams.isPreviewFile || params.extraParams.isAdditionalFile) {
                            $log.debug('S3Upload::multipartUploadComplete preview file process...');
                            //ArtworkService.processPreviewFile(params.extraParams.artworkId);

                            var entityId = params.extraParams[params.extraParams.entityType + 'Id'];
                            AdditionalFilesService.updateEntityAdditionalFileStatus(
                                entityId,
                                params.extraParams.entityType,
                                { status: 'READY', additionalFileId: params.extraParams.additionalFileId }
                            ).then(function (entityId) {
                                $rootScope.$broadcast(
                                    params.extraParams.entityType + '_additional_file_changed_status',
                                    entityId,
                                    'READY'
                                );
                            });
                        } else {
                            // Main media file
                            ArtworkService.processArtwork(params.extraParams.artworkId);
                        }
                    }

                    //$rootScope.$apply(function () {
                    $rootScope.$emit('s3upload:success', {
                        uploadMethod: 'multipart',
                        multipartUploadObj: params.multipartUploadObj,
                        upload: self.uploads[params.uploadId],
                    });
                    //});
                },
                function (err) {
                    multipartUploadFailed(params, deferred, { code: 'FileNotValid', object: err });
                }
            );
        }
        function multipartUpload(file, params, deferred) {
            // AWS.S3 options: http://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html
            // TODO - support resume from the multipart request on localStorage
            //var s3Obj = new AWS.S3({ maxRetries: 20, logger: $log, params: { Bucket: params.bucket } });
            var s3Obj = new AWS.S3({
                // correctClockSkew: true,
                httpOptions: { timeout: 300000 }, // 5 minutes for each request, default was 2 minutes (120000)
                logger: NIIO_DEBUG ? $log : null,
                params: {
                    Bucket: params.bucket,
                },
            });

            // Taken from: https://github.com/aws/aws-sdk-js/issues/399#issuecomment-279812538
            AWS.config.update({
                correctClockSkew: true,
            });
            var s3Params = {
                Key: params.key,
                ACL: params.acl,
                ContentType: file.type,
                Body: file,
            };
            $rootScope.increaseImportantRequests();
            // Reference http://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#upload-property
            var request = s3Obj.upload(s3Params, { leavePartsOnError: true, queueSize: 4 });
            //var request = s3Obj.upload(s3Params);
            request.on('httpUploadProgress', self.partial(multipartUploadProgress, params, deferred));
            return request;
        }

        function isRequestResponding(lastUpTime, speed) {
            var now = new Date().getTime();
            // Request is responding if it uploaded something in the last 2 minutes
            return speed && (now - lastUpTime) / 1000 < 120;
        }

        function retryCanceledMultipartUploads() {
            $log.debug('S3Uploader::retryCanceledMultipartUploads Checking if uploads are running');

            angular.forEach(self.activeUploads, function (uploadId) {
                //if (upload && !upload.aborted && !upload.success && upload.uploading && upload.request.multipartUploadObj) {
                var upload = self.uploads[uploadId];
                if (!isRequestResponding(upload.lastUpTime, upload.speed)) {
                    $log.debug(
                        'S3Uploader::retryCanceledMultipartUploads' +
                            logLine(uploadId) +
                            "wasn't responding, restarting it",
                        { request: upload.request, upload: upload }
                    );
                    self.retryUpload(uploadId);
                }
                //}
            });

            if (self.activeUploads.length === 0) {
                $interval.cancel(self.resumableUploads);
                self.resumableUploads = undefined;
            }
        }

        function currentActiveUploadsExists() {
            var result = false;

            if (self.uploads && self.uploads.length > 0) {
                self.uploads.forEach(function (upload) {
                    if (upload && upload.uploading && upload.uploading === true) {
                        result = true;
                    }
                });
            }

            return result;
        }

        this.partial = function (func /*, 0..m args */) {
            var args = Array.prototype.slice.call(arguments, 1);
            return function () {
                var allArguments = args.concat(Array.prototype.slice.call(arguments));
                return func.apply(this, allArguments);
            };
        };

        function checkStatusOfArtworkUpload(upload, notSyncedWithServer) {
            ArtworkService.getArtworkStatus(upload.extraParams.artworkId).then(function (res) {
                var status = res.data.status;
                if (status === 'UPLOAD_FAILED') {
                    //self.numberOfUploads--;
                    upload.success = false;
                    upload.uploading = false;
                }
                //else if (status === 'UPLOADING' && notSyncedWithServer) {
                //    // TODO - Recheck this area...
                //    upload.notSyncedWithServer = false;
                //    ArtworkService.updateArtworkStatus(upload.extraParams.artworkId, {status: 'UPLOAD_FAILED'})
                //        .then(function (res) {
                //            $timeout(function() {
                //                $log.debug('Synced upload with server');
                //                $rootScope.$emit('S3Uploader:upload_synced_with_server', upload.extraParams.artworkId, 'UPLOAD_FAILED');
                //                $rootScope.$broadcast('artwork_changed_status', upload.extraParams.artworkId, 'UPLOAD_FAILED');
                //            }, 100);
                //        });
                //}
            });
        }

        this.updateUploadsStatus = function () {
            // Go over all uploads and if status failed, update server
            var i = 0;

            if (self.uploads) {
                for (i; i < self.uploads.length; i++) {
                    if (self.uploads[i]) {
                        if (self.uploads[i].extraParams && self.uploads[i].extraParams.artworkId) {
                            // If upload is still marked as uploading or the upload failed and not synced with server - check status and sync local storage and server
                            if (
                                (!self.uploads[i].success && self.uploads[i].uploading) ||
                                self.uploads[i].notSyncedWithServer
                            ) {
                                checkStatusOfArtworkUpload(
                                    self.uploads[i],
                                    self.uploads[i].notSyncedWithServer
                                );
                            } else {
                                //self.uploads.splice(i, 1);
                                $log.debug(
                                    'S3Uploader::updateUploadsStatus' +
                                        logLine(i) +
                                        'not successfull should be cleaned',
                                    self.uploads[i]
                                );
                            }
                        }
                    }
                }
            }

            //self.numberOfUploads = 0;
        };

        this.getUploadArtworkOptions = function (uri, artworkId) {
            console.log('S3Uploader::getUploadArtworkOptions', uri, artworkId);
            var deferred = $q.defer();
            $http
                .get(uri + '/' + artworkId)
                .success(function (response, status) {
                    deferred.resolve(response);
                })
                .error(function (error, status) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        this.getUploadUserPicsOptions = function (uri, type, userId) {
            var deferred = $q.defer();
            $http
                .get(uri + '/' + type + '/' + userId)
                .success(function (response, status) {
                    deferred.resolve(response);
                })
                .error(function (error, status) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        this.getUploadUserCVOptions = function (uri, type, profileId, ext) {
            var deferred = $q.defer();
            $http
                .get(uri + '/' + type + '/' + profileId + '/' + ext)
                .success(function (response, status) {
                    deferred.resolve(response);
                })
                .error(function (error, status) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        this.getUploadCampaignPicsOptions = function (uri, campaignId, filename) {
            var deferred = $q.defer();
            $http
                .get(uri + '/' + campaignId + '/' + filename)
                .success(function (response, status) {
                    deferred.resolve(response);
                })
                .error(function (error, status) {
                    deferred.reject(error);
                });

            return deferred.promise;
        };

        //this.getUploadOptions = function (uri) {
        //    var deferred = $q.defer();
        //    $http.get(uri).
        //        success(function (response, status) {
        //            deferred.resolve(response);
        //        }).error(function (error, status) {
        //            deferred.reject(error);
        //        });
        //
        //    return deferred.promise;
        //};

        this.randomString = function (length) {
            var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var result = '';
            for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

            return result;
        };

        this.copyJSON = function (json) {
            return json !== undefined ? angular.copy(json) : '';
        };

        function setNewCognitoId(newCognitoId) {
            if (newCognitoId && $localStorage.cognitoId !== newCognitoId) {
                $localStorage.cognitoId = newCognitoId;
            }
        }

        function getNewCognitoCredentials(oldCognitoId) {
            var deferred = $q.defer();

            AWS.config.credentials.get(function (err) {
                if (err) {
                    $log.debug('S3Uploader::getNewCognitoCredentials Error getting cognito id', err);
                    deferred.reject(err);
                } else {
                    if (oldCognitoId && oldCognitoId !== AWS.config.credentials.identityId) {
                        $log.debug(
                            'S3Uploader::getNewCognitoCredentials Old cognito id is different from new cognito id',
                            { old: oldCognitoId, new: AWS.config.credentials }
                        );
                        //alert('old cognito ID doesn\'t match the new ones. Beware of uploads failures');
                    }
                    if (AWS.config.credentials.identityId) {
                        $log.debug(
                            'S3Uploader::getNewCognitoCredentials Success getting cognito id',
                            AWS.config
                        );
                        setNewCognitoId(AWS.config.credentials.identityId);
                        deferred.resolve(AWS.config.credentials.identityId);
                    } else {
                        $log.debug(
                            'S3Uploader::getNewCognitoCredentials Error getting cognito id - empty identityId',
                            AWS.config
                        );
                        deferred.reject();
                    }
                }
            });

            return deferred.promise;
        }
        // ANCHOR generate cognito identity function
        this.generateCognitoIdentity = function () {
            var deferred = $q.defer();
            // Initialize the Amazon Cognito credentials provider
            // https://mobile.awsblog.com/post/TxBVEDL5Z8JKAC/Use-Amazon-Cognito-in-your-website-for-simple-AWS-authentication
            AWS.config.region = COGNITO_POOL_REGION; // Region
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: COGNITO_POOL_REGION + ':' + COGNITO_POOL,
            });
            if ($localStorage.cognitoId) {
                $log.debug(
                    'S3Uploader::generateCognitoIdentity cognito id already exists, refreshing the credentials',
                    $localStorage.cognitoId
                );
                AWS.config.credentials.refresh(function (err) {
                    if (err) {
                        $log.debug('S3Uploader::generateCognitoIdentity Failed to refresh credentials', err);
                        getNewCognitoCredentials().then(
                            function (res) {
                                deferred.resolve(AWS.config.credentials.identityId);
                            },
                            function (err) {
                                deferred.resolve(err);
                            }
                        );
                    } else {
                        $log.debug(
                            'S3Uploader::generateCognitoIdentity Success to refresh credentials',
                            $localStorage.cognitoId
                        );
                        setNewCognitoId(AWS.config.credentials.identityId);
                        deferred.resolve(AWS.config.credentials.identityId);
                    }
                });
            } else {
                $log.debug('S3Uploader::generateCognitoIdentity getting new credentials');
                getNewCognitoCredentials().then(
                    function (res) {
                        setNewCognitoId(AWS.config.credentials.identityId);
                        deferred.resolve(AWS.config.credentials.identityId);
                    },
                    function (err) {
                        deferred.resolve(err);
                    }
                );
            }

            return deferred.promise;
        };

        function startUploadWatchDog() {
            if (!self.resumableUploads && self.activeUploads.length > 0) {
                self.resumableUploads = $interval(function () {
                    retryCanceledMultipartUploads();
                }, 10000);
            }
        }

        this.getWaitingUploads = function () {
            return _.filter(self.uploads, function (upload) {
                return (
                    upload &&
                    upload.extraParams &&
                    upload.extraParams.entityType &&
                    upload.extraParams[upload.extraParams.entityType + 'Id'] &&
                    (upload.waiting || upload.retrying) &&
                    !upload.deleted
                );
            });
        };

        function updateWaitingUploadsQueuePosition() {
            var waitingUploads = self.getWaitingUploads();
            if (waitingUploads.length > 0) {
                var i = 0;
                for (i; i < waitingUploads.length; i++) {
                    waitingUploads[i].queuePosition = i + 1;
                }
            }
        }

        function startNextArtworkUploadInQueue() {
            var waitingUploads = self.getWaitingUploads();
            $log.debug('S3Uploader::startNextArtworkUploadInQueue Waiting uploads', waitingUploads);

            if (waitingUploads.length > 0) {
                $timeout(function () {
                    if (self.activeUploads.length < self.maximumActiveUploads) {
                        $log.debug('S3Uploader::startNextArtworkUploadInQueue Starting upload', {
                            waitingUploads: waitingUploads[0],
                            activeUploadsLength: self.activeUploads.length,
                            maxActiveUploads: self.maximumActiveUploads,
                        });
                        startUpload(waitingUploads[0].id);
                    } else {
                        $log.debug('S3Uploader::startNextArtworkUploadInQueue Upload was not started', {
                            waitingUploads: waitingUploads[0],
                            activeUploadsLength: self.activeUploads.length,
                            maxActiveUploads: self.maximumActiveUploads,
                        });
                    }
                    updateWaitingUploadsQueuePosition();
                }, 500);
            }
        }

        this.upload = function (
            uploadMethod,
            bucket,
            key,
            acl,
            type,
            accessKey,
            policy,
            signature,
            file,
            extraParams,
            onSuccess,
            onFailure,
            restarted
        ) {
            var deferred = $q.defer();
            var xhr, multipartUploadObj, regularUploadObj, formData;
            restarted = restarted > 0 ? restarted : 0;
            var params = {
                bucket: bucket,
                key: key,
                acl: acl,
                type: type,
                accessKey: accessKey,
                policy: policy,
                signature: signature,
                file: file,
                extraParams: extraParams,
                onSuccess: onSuccess,
                onFailure: onFailure,
                uploadId: self.uploadId,
                originalSize: file.size,
            };

            if (uploadMethod === 'regular') {
                regularUploadObj = regularUpload(file, params, deferred);
                xhr = regularUploadObj.xhr;
                formData = regularUploadObj.formData;
            } else {
                // uploadType === 'multipart'
                multipartUploadObj = multipartUpload(file, params, deferred);
            }

            if (
                extraParams &&
                extraParams.artworkId &&
                !extraParams.isPreviewFile &&
                !extraParams.isAdditionalFile
            ) {
                abortExistingUploadsForSameArtwork(extraParams.artworkId, self.uploadId);
            }

            self.uploads[self.uploadId] = {
                id: self.uploadId,
                time: Date.now(),
                params: params,
                extraParams: extraParams,
                //xhr: xhr,
                //multipartUploadObj: multipartUploadObj,
                uploadMethod: uploadMethod,
                uploaded: 0,
                total: 1,
                lastUpTime: 0,
                speed: 0,
                aborted: false,
                uploading: false,
                progress: 0,
                waiting: true,
                success: false,
                notSyncedWithServer: true,
                restarted: restarted,
                deferred: deferred,
                request: {
                    xhr: xhr,
                    formData: formData,
                    multipartUploadObj: multipartUploadObj,
                    file: file,
                },
            };

            $rootScope.$emit('s3upload:start', {
                uploadMethod: uploadMethod,
                xhr: xhr,
                multipartUploadObj: multipartUploadObj,
                upload: self.uploads[self.uploadId],
            });

            self.uploadId++;
            $log.debug('S3Upload::upload Increasing numberOfUploads', { activeUploads: self.activeUploads });
            //self.numberOfUploads++;
            startNextArtworkUploadInQueue();

            return deferred.promise;
        };

        this.deleteUpload = function (uploadId) {
            if (self.uploads[uploadId]) {
                self.uploads[uploadId].deleted = true;
                updateWaitingUploadsQueuePosition();
                return self.abortUpload(uploadId);
            } else {
                var defer = $q.defer();
                defer.reject("Upload doesn't exist");
                return defer.promise;
            }
        };

        this.abortUpload = function (uploadId) {
            var deferred = $q.defer(),
                request;

            if (self.uploads[uploadId]) {
                var upload = self.uploads[uploadId];
                removeActiveUpload(uploadId);

                if (upload.request) {
                    request =
                        upload.uploadMethod === 'regular'
                            ? upload.request.xhr
                            : upload.request.multipartUploadObj;
                    if (typeof request.abort === 'function') {
                        if (self.uploads[uploadId].uploaded > 0) {
                            request.duringSignout = true;
                            request.abort();
                            $log.debug(
                                'S3Upload::abortUpload upload ' + uploadId + ' was successfully aborted'
                            );
                            deferred.resolve({ msg: 'upload ' + uploadId + ' was successfully aborted' });
                        } else {
                            //self.numberOfUploads--;
                            $log.debug(
                                'S3Upload::abortUpload upload ' +
                                    uploadId +
                                    ' was not aborted because request did not start'
                            );
                            deferred.resolve({
                                msg: 'upload ' + uploadId + ' was not aborted because request did not start',
                            });
                        }
                    } else {
                        $log.debug("S3Upload::abortUpload couldn't abort upload, there is no abort function");
                        deferred.reject({ msg: "couldn't abort upload, there is no abort function" });
                    }
                } else {
                    $log.debug("S3Upload::abortUpload couldn't abort upload, there is no request available");
                    deferred.reject({ msg: "couldn't abort upload, there is no request available" });
                }
            } else {
                $log.debug("S3Upload::abortUpload couldn't find upload");
                deferred.reject({ msg: "couldn't find upload" });
            }

            return deferred.promise;
        };

        this.stopAllActiveUploads = function () {
            self.duringSignout = true;
            if (self.uploads && self.activeUploads && self.activeUploads.length > 0) {
                self.activeUploads.forEach(function (uploadId) {
                    self.abortUpload(uploadId);
                    self.uploads[uploadId].deleted = true;
                });
            }
            self.duringSignout = false;
            self.uploads = [];
            delete $localStorage.cognitoId;
        };

        function abortExistingUploadsForSameArtwork(artworkId, exceptUploadId) {
            var uploads = _.filter(self.uploads, function (upload) {
                if (
                    upload.params.extraParams.artworkId === artworkId &&
                    !upload.params.extraParams.isPreviewFile &&
                    !upload.params.extraParams.isAdditionalFile
                ) {
                    return upload.id !== exceptUploadId;
                } else {
                    return false;
                }
            });
            if (uploads.length > 0) {
                $log.debug(
                    'S3Upload::abortExistingUploadsForSameArtwork aborting uploads with same artworks',
                    uploads
                );
                uploads.forEach(function (upload) {
                    if (!self.uploads[upload.id].deleted) {
                        //self.numberOfUploads--;
                        self.abortUpload(upload.id);
                        self.uploads[upload.id].deleted = true;
                    }
                });
            }
        }

        function getUpdatedArtworkS3TempPath(artworkId) {
            var data = {
                artworkId,
                cognitoId: $localStorage.cognitoId,
            };

            return $http.post('/artworks/getUpdatedArtworkS3TempPath', data);
        }

        function sendRequest(request, uploadParams, upload) {
            request.send(function (err, data) {
                if (!err) {
                    // successful upload
                    multipartUploadComplete(uploadParams, upload.deferred, data);
                } else {
                    multipartUploadFailed(uploadParams, upload.deferred, err);
                }
            });
        }

        function startUpload(uploadId) {
            var deferred = $q.defer();
            var upload = self.uploads[uploadId];
            var uploadParams = self.uploads[uploadId].params;
            var request =
                upload.uploadMethod === 'regular' ? upload.request.xhr : upload.request.multipartUploadObj;

            // reset params
            upload.lastUpTime = new Date().getTime();
            upload.speed = 1;
            upload.uploading = true;
            upload.aborted = false;
            upload.waiting = false;
            upload.queuePosition = 0;
            //self.uploads[uploadId].progress = 0;
            upload.success = false;
            upload.notSyncedWithServer = true;

            addActiveUpload(uploadId);

            self.generateCognitoIdentity()
                .then(
                    function (res) {
                        upload.retrying = false;

                        if (upload.uploadMethod === 'regular') {
                            request.send(self.uploads[uploadId].request.formData);
                        } else {
                            if (uploadParams.extraParams.artworkId) {
                                getUpdatedArtworkS3TempPath(uploadParams.extraParams.artworkId).then(
                                    function (res) {
                                        uploadParams.bucket = res.data.s3.bucket;
                                        uploadParams.key = res.data.s3.key;

                                        sendRequest(request, uploadParams, upload);
                                    },
                                    function (err) {
                                        $log.debug(
                                            'S3Uploader::startUpload' +
                                                logLine(uploadId) +
                                                'Error updating upload params',
                                            err
                                        );
                                    }
                                );
                            } else {
                                sendRequest(request, uploadParams, upload);
                            }
                        }

                        startUploadWatchDog();
                        if (
                            uploadParams.extraParams.entityType &&
                            uploadParams.extraParams[uploadParams.extraParams.entityType + 'Id'] &&
                            !uploadParams.extraParams.isPreviewFile
                        ) {
                            if (
                                uploadParams.extraParams.isPreviewFile ||
                                uploadParams.extraParams.isAdditionalFile
                            ) {
                                var entityId =
                                    uploadParams.extraParams[uploadParams.extraParams.entityType + 'Id'];
                                AdditionalFilesService.updateEntityAdditionalFileStatus(
                                    entityId,
                                    uploadParams.extraParams.entityType,
                                    {
                                        status: 'UPLOADING',
                                        additionalFileId: uploadParams.extraParams.additionalFileId,
                                    }
                                ).then(function (entityId) {
                                    $rootScope.$broadcast(
                                        uploadParams.extraParams.entityType +
                                            '_additional_file_changed_status',
                                        entityId,
                                        'UPLOADING'
                                    );
                                });
                            } else {
                                ArtworkService.updateArtworkStatus(uploadParams.extraParams.artworkId, {
                                    status: 'UPLOADING',
                                }).then(function (artworkId) {
                                    $rootScope.$broadcast('artwork_changed_status', artworkId, 'UPLOADING');
                                });
                            }
                        }

                        //if (self.requests[uploadId].file && self.requests[uploadId].file.type) {
                        //    self.upload(upload.uploadMethod, uploadParams.bucket, uploadParams.key,
                        //        uploadParams.acl, uploadParams.type, uploadParams.accessKey,
                        //        uploadParams.policy, uploadParams.signature, self.requests[uploadId].file,
                        //        uploadParams.extraParams, uploadParams.onSuccess, uploadParams.onFailure, uploadId, upload.deferred);
                        $log.debug(
                            'S3Uploader::startUpload' +
                                logLine(uploadId) +
                                'Success - got Cognito id and restarted request',
                            { cognito: res, request: request }
                        );
                        deferred.resolve({ msg: 'successfully retried upload' });
                        //} else {
                        //    deferred.reject({msg: 'couldn\'t find upload'});
                        //}
                    },
                    function (err) {
                        $log.debug(
                            'S3Uploader::startUpload' + logLine(uploadId) + 'Error getting cognito id',
                            err
                        );
                        deferred.reject({ msg: "couldn't generate Cognito credentials", error: err });
                    }
                )
                .finally(function () {
                    $log.debug(
                        'S3Uploader::startUpload' +
                            logLine(uploadId) +
                            'Finally - not in retrying phase anymore'
                    );
                    delete self.uploads[uploadId].retrying;
                });

            return deferred.promise;
        }

        this.retryUpload = function (uploadId) {
            var deferred = $q.defer();

            if (self.uploads[uploadId] && self.uploads[uploadId].request) {
                var upload = self.uploads[uploadId];
                upload.retrying = true;
                upload.lastUpTime = new Date().getTime();
                upload.speed = 1;
                upload.uploading = true;
                upload.aborted = false;
                upload.waiting = false;
                upload.success = false;
                upload.notSyncedWithServer = true;

                if (!upload.aborted) {
                    self.abortUpload(uploadId).finally(function () {
                        // reset params

                        //self.activeUploads--;
                        startNextArtworkUploadInQueue();
                        deferred.resolve();
                    });
                } else {
                    removeActiveUpload(uploadId);
                    startNextArtworkUploadInQueue();
                    deferred.resolve();
                }
            } else {
                deferred.reject({ msg: "couldn't find upload" });
            }

            return deferred.promise;
        };

        this.restartUpload = function (uploadId) {
            var deferred = $q.defer();
            var upload = self.uploads[uploadId];

            if (upload && upload.request) {
                upload.restarted++;
                $log.debug('S3Uploader::restartUpload restarting upload', upload);
                self.upload(
                    upload.uploadMethod,
                    upload.params.bucket,
                    upload.params.key,
                    upload.params.acl,
                    upload.params.type,
                    upload.params.accessKey,
                    upload.params.policy,
                    upload.params.signature,
                    upload.params.file,
                    upload.params.extraParams,
                    upload.params.onSuccess,
                    upload.params.onFailure,
                    upload.restarted
                ).then(
                    function (res) {
                        $log.debug('S3Uploader::restartUpload Success', upload);
                    },
                    function (error) {
                        $log.debug('S3Uploader::restartUpload Failure', { upload: upload, error: error });
                    }
                );

                //self.abortUpload(uploadId);
                //self.uploads[uploadId].deleted = true;
                if (
                    upload.params.extraParams.entityType &&
                    upload.params.extraParams[upload.params.extraParams.entityType + 'Id']
                ) {
                    if (
                        upload.params.extraParams.isPreviewFile ||
                        upload.params.extraParams.isAdditionalFile
                    ) {
                        var entityId = upload.params.extraParams[upload.params.extraParams.entityType + 'Id'];
                        AdditionalFilesService.updateEntityAdditionalFileStatus(
                            entityId,
                            upload.params.extraParams.entityType,
                            {
                                status: 'UPLOADING',
                                additionalFileId: upload.params.extraParams.additionalFileId,
                            }
                        ).then(function (entityId) {
                            $rootScope.$broadcast(
                                upload.params.extraParams.entityType + '_additional_file_changed_status',
                                entityId,
                                'UPLOADING'
                            );
                        });
                    } else {
                        ArtworkService.updateArtworkStatus(upload.params.extraParams.artworkId, {
                            status: 'UPLOADING',
                        }).then(function (artworkId) {
                            $rootScope.$broadcast('artwork_changed_status', artworkId, 'UPLOADING');
                        });
                    }
                }

                deferred.resolve();
            } else {
                $log.debug('S3Uploader::restartUpload Failure - could not find upload', { upload: upload });
                deferred.reject({ msg: "couldn't find upload" });
            }

            return deferred.promise;
        };

        this.getProgress = function (uploadId) {
            return self.uploads[uploadId].progress;
        };

        this.isUploading = function () {
            $log.debug('Is uploading? ' + self.activeUploads.length > 0);
            return self.activeUploads.length > 0;
        };

        $rootScope.$on('clear_user', function () {
            self.stopAllActiveUploads();
        });
    },
]);
