'use strict';

angular.module('core').directive('imageOnload', [
    function () {
        return {
            restrict: 'A',
            scope: {
                imageOnload: '&',
                imageOnerror: '&',
            },
            link: function (scope, element, attrs) {
                element.bind('load', function () {
                    if (scope.imageOnload) {
                        scope.$apply(scope.imageOnload());
                    }
                });
                element.bind('error', function () {
                    if (scope.imageOnerror) {
                        scope.$apply(scope.imageOnerror());
                    }
                });
            },
        };
    },
]);
