'use strict';

angular.module('home').controller('HomeChannelsMainController', [
    'displayChannels',
    '$scope',
    '$timeout',
    '$state',
    '$stateParams',
    '$log',
    'ChannelService',
    'Messages',
    'OpenPopupForm',
    'CHANNEL_TYPES',
    'User',
    'NIIO_URL',
    function (
        displayChannels,
        $scope,
        $timeout,
        $state,
        $stateParams,
        $log,
        ChannelService,
        Messages,
        OpenPopupForm,
        CHANNEL_TYPES,
        User,
        NIIO_URL
    ) {
        $scope.ChannelService = ChannelService;
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.CHANNEL_TYPES = CHANNEL_TYPES;
        $scope.NIIO_URL = NIIO_URL;
        $scope.isAdmin = User.isAdmin();

        function isFirstTime(displayChannels) {
            var result = false;

            if (displayChannels.length === 0 && $scope.channelsName !== 'default') {
                if ($scope.channelsName === 'curatedChannels' || $scope.channelsName === 'curatedCatalogs') {
                    result = 'modules/home/views/home.first-time-channels-curated.client.view.html';
                } else {
                    result = 'modules/home/views/home.first-time-channels-subscriptions.client.view.html';
                }
            }
            $scope.isFirstTimeVar = result;
        }

        //$scope.$watch('displayChannels', function() {
        //    $scope.isFirstTimeVar = isFirstTime($scope.displayChannels);
        //});

        $scope.init = function () {
            $scope.loadingArtworks = true;
            $scope.channelsName = $stateParams.channelsName;
            $scope.channelPath =
                $state.current.name.startsWith('profile.') && $scope.channelsName === 'curatedCatalogs'
                    ? 'catalogs'
                    : 'channels';
            // Loaded artworks using $timeout to enable animation on them.
            $scope.displayChannels = [];
            $timeout(function () {
                $scope.displayChannels = displayChannels;
                isFirstTime($scope.displayChannels);
                $scope.$emit('HomeChannelsMainController:channel_chosen', $stateParams.channelsName);
                $scope.loadingArtworks = false;
            }, 100);
        };

        $scope.search = {
            enable: false,
            text: '',
            filterChannels: function (artwork) {
                if (!$scope.search.enable || $scope.search.text === '') {
                    return true;
                }

                var lowerCasedSearchText = $scope.search.text.toLowerCase();

                if (
                    artwork.name.toLowerCase().indexOf(lowerCasedSearchText) !== -1 ||
                    (artwork.creator.display_name &&
                        artwork.creator.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1)
                ) {
                    return true;
                }
                return false;
            },
        };

        $scope.toggleSearch = function () {
            $scope.search.text = '';
            if ($scope.search.enable) {
                $scope.search.text = '';
                $scope.search.enable = false;
            } else {
                $scope.search.enable = true;
            }
        };
    },
]);
