'use strict';

angular.module('devices').controller('DevicesAddGroupController', [
    '$log',
    '$scope',
    '$http',
    '$stateParams',
    '_',
    'Messages',
    function ($log, $scope, $http, $stateParams, _, Messages) {
        function prepareDevicesList() {
            $scope.allDevices = JSON.parse(JSON.stringify($scope.groups));
            var temp = [];
            $scope.allDevices.forEach(function (group) {
                group.devices.forEach(function (device) {
                    var newDevice = device;
                    newDevice.isChecked = false;

                    if (group.id !== '0') {
                        newDevice.groupName = group.name;
                        newDevice.groupId = group.id;
                    }

                    temp.push(newDevice);
                });
            });

            if ($stateParams.fromSelected) {
                $scope.selectedDevices.forEach(function (selectedDevice) {
                    temp.forEach(function (newDevice) {
                        if (newDevice.id === selectedDevice.id) {
                            //newDevice.isChecked = true;
                            $scope.addRemoveDevice(newDevice);
                        }
                    });
                });
            }
            $scope.allDevices = temp;
        }

        function resetFields() {
            $scope.groupName = '';
            $scope.groupLocation = '';
            $scope.devicesId = [];
        }

        $scope.init = function () {
            resetFields();
            if (typeof $scope.unselectDevicesAndGroups === 'function') {
                $scope.unselectDevicesAndGroups();
            }

            if (typeof $scope.clearSingleSelected === 'function') {
                $scope.clearSingleSelected();
            }
            prepareDevicesList();
        };

        $scope.addRemoveAllDevices = function () {
            $scope.devicesId = [];
            if ($scope.allDevicesAreChecked) {
                $scope.allDevices.forEach(function (device) {
                    device.isChecked = false;
                });
            } else {
                $scope.allDevices.forEach(function (device) {
                    $scope.devicesId.push(device.id);
                    device.isChecked = true;
                });
            }
            $scope.allDevicesAreChecked = !$scope.allDevicesAreChecked;
        };

        $scope.addRemoveDevice = function (device) {
            device.isChecked = !device.isChecked;
            var indexOfDevice = _.indexOf($scope.devicesId, device.id);
            if (indexOfDevice === -1) {
                $scope.devicesId.push(device.id);
            } else {
                $scope.devicesId.splice(indexOfDevice, 1);
            }
        };

        $scope.createGroup = function () {
            var data = {
                groupName: $scope.groupName,
                groupLocation: $scope.groupLocation,
                devicesId: $scope.devicesId,
            };

            if ($scope.users && $scope.users[0]) {
                data.userId = $scope.users[0].id;
            }

            // update an existing device with current user's id
            $http.post('/devices/createGroup', data).then(
                function (res) {
                    $log.debug('DevicesAddGroupController::createGroup Succeeded to add group', res, data);
                    var messageParams = {};
                    messageParams.message = 'Group successfully added';
                    Messages.openMessage($scope, messageParams);
                    $scope.init();
                    if (res && res.data && res.data.group) {
                        var group = res.data.group;
                        $scope.$emit('DevicesAddGroupController:add_group', group);
                    }
                },
                function (error) {
                    $log.debug('DevicesAddGroupController::createGroup Failed to add group', error, data);
                    var messageParams = {};
                    messageParams.message = 'Failed to add group, please try again.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                }
            );
        };
    },
]);
