'use strict';

angular.module('devices').directive('bindByKey', [
    '$filter',
    '$compile',
    function ($filter, $compile) {
        return {
            restrict: 'A',
            require: '?ngModel',
            scope: {
                items: '=',
                index: '=',
                //            ngModel: '='
            },
            priority: 1001, // compiles first
            terminal: true, // prevent lower priority directives to compile after it
            //        link: function ($scope, $element, $attr, ngModel) {
            //        link: function ($scope, $element, $attr) {
            link: function ($scope, $element, $attr) {
                var key = $filter('showKey')($scope.items, $scope.index);
                var model = $attr.ngModel + '.' + key;
                $element.removeAttr('bind-by-key');
                $element.removeAttr('items');
                $element.removeAttr('index');
                $element.removeAttr('ng-model');
                $element.attr('ng-model', model);
                $compile($element);
            },
        };
    },
]);
