'use strict';

angular.module('devices').controller('DevicesController', [
    'devicesAndGroups',
    '$log',
    '$rootScope',
    '$scope',
    '$http',
    '$localStorage',
    '$state',
    '$q',
    '_',
    'Messages',
    'Partial',
    'DevicesDataService',
    'Search',
    'User',
    'smoothScroll',
    'PushHandlerService',
    'DetectMobileBrowser',
    function (
        devicesAndGroups,
        $log,
        $rootScope,
        $scope,
        $http,
        $localStorage,
        $state,
        $q,
        _,
        Messages,
        Partial,
        DevicesDataService,
        Search,
        User,
        smoothScroll,
        PushHandlerService,
        DetectMobileBrowser
    ) {
        // Data Members
        $scope.devicesBelongsTo = $state.current.data.devicesBelongsTo;
        $scope.$state = $state;
        $scope.Search = Search;
        $scope.User = User;
        $scope.selectedDevices = [];
        $scope.selectedGroups = [];
        $scope.defaultTab = {
            selection: 'content',
        };
        $scope.isMobile = DetectMobileBrowser.isAny;

        $scope.hideSidebarConditions = function () {
            return (
                $state.is('home.devices.add-device') ||
                $state.is('home.devices.add-group') ||
                (!$scope.firstTime && $scope.isMobile)
                // ($state.includes('home.devices.device.**') && $scope.isMobile) ||
                // ($state.includes('home.devices.group.**') && $scope.isMobile)
            );
        };

        $scope.oneAtATime = false;

        $scope.status = {
            isFirstOpen: false,
            isFirstDisabled: false,
        };

        function updateDeviceAndGroups(devicesAndGroups) {
            $scope.groups = devicesAndGroups.groups;
            var devicesCount = 0;
            $scope.groups.forEach(function (group) {
                group.devices.forEach(function (device) {
                    devicesCount++;
                });
            });
            devicesAndGroups.user.devicesCount = devicesCount;
            $scope.devices = devicesAndGroups.devices;
            $scope.sortDevices();
            $scope.users = [devicesAndGroups.user];
        }

        function createCapacityOptions() {
            var options = [
                0, 0.25, 0.5, 0.75, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 16, 18, 20, 24, 32, 44, 50, 64,
                128, 256, 512, 1000,
            ];
            var capacityOptions = [];

            options.forEach(function (option) {
                var opt = {};
                if (option === 0) {
                    opt.value = 'Unlimited';
                    opt.id = 0;
                } else {
                    opt.value = option + ' GB';
                    opt.id = option * 1024;
                }

                capacityOptions.push(opt);
            });

            return capacityOptions;
        }

        function findGroupById(groupId) {
            return _.find($scope.groups, function (group) {
                return group.id === groupId;
            });
        }

        function findDeviceById(deviceId) {
            var result, device, group;
            // Find in devices
            device = _.find($scope.devices, function (device) {
                return device.id === deviceId;
            });
            // Find in groups
            if (!device) {
                var i;
                for (i = 0; i < $scope.groups.length; i++) {
                    var j;
                    for (j = 0; j < $scope.groups[i].devices.length; j++) {
                        if (deviceId === $scope.groups[i].devices[j].id) {
                            device = $scope.groups[i].devices[j];
                            group = $scope.groups[i];
                            break;
                        }
                    }
                    if (device) {
                        break;
                    }
                }
            }

            if (device) {
                result = {};
                result.device = device;
                if (group) {
                    result.group = group;
                }
            }

            return result;
        }

        function findDeviceGroup(device) {
            return _.find($scope.groups, function (group) {
                return group.id === device.groupId;
            });
        }

        $scope.isDeviceOrGroupExist = function (id, deviceOrGroup) {
            if (deviceOrGroup === 'device') {
                return findDeviceById(id);
            } else if (deviceOrGroup === 'group') {
                return findGroupById(id);
            }
        };

        $scope.refreshSidebar = function () {
            $scope.unselectDevicesAndGroups();
            $scope.clearSingleSelected();
            return $scope.getDevicesAndGroupsByUser();
        };

        function chooseSelected() {
            $scope.selected =
                $scope.singleSelectedGroup ||
                $scope.singleSelectedDevice ||
                $scope.selectedGroups[0] ||
                $scope.selectedDevices[0];
            if ($scope.selected && $scope.selected.groupId) {
                $scope.canManageDevice = $scope.selected.groupId === '0';
            } else {
                $scope.canManageDevice = true;
            }
            // notify the child controllers
            $scope.isAATV = $scope.selected?.platform === 'tvOS' || $scope.selected?.platform === 'androidTV';
        }

        function isFirstTime(force) {
            return (
                force ||
                ($scope.devices.length === 0 &&
                    $scope.groups.length === 0 &&
                    !$state.is('home.devices.add-device') &&
                    !$state.is('home.devices.add-group'))
            );
        }

        $scope.init = function () {
            //Scroll to head of page in init
            if (!$scope.isOther) {
                var homeSecondNavElement = document.getElementById('home-secondary-nav');
                if (homeSecondNavElement) {
                    let scrollParams = {};
                    if (!$scope.isMobile) {
                        scrollParams.offset = $rootScope.heightOfNav;
                    }
                    smoothScroll(homeSecondNavElement, scrollParams);
                }
            }

            if (User.isAdmin()) {
                DevicesDataService.getCampaigns().then(function (campaigns) {
                    $scope.campaigns = campaigns;
                });
            }

            updateDeviceAndGroups(devicesAndGroups);
            $scope.isFirstTime = isFirstTime();
            $scope.capacityOptions = createCapacityOptions();

            if ($scope.isFirstTime) {
                reselectChosen();
            }

            // FOR ADMIN PANEL
            $scope.adminDevices = {};
            $scope.defaultSettings = {};
        };

        function compareNames(a, b) {
            var nameA = a.name.toLowerCase(),
                nameB = b.name.toLowerCase();
            if (nameA < nameB)
                //sort string ascending
                return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
        }

        $scope.sortDevices = function (devices) {
            if (devices) {
                devices.sort(compareNames);
            } else {
                // Sort main devices and groups
                $scope.groups.forEach(function (group) {
                    group.devices.sort(compareNames);
                });
                $scope.devices.sort(compareNames);
            }
        };

        // Events
        function reselectChosen() {
            if ($scope.singleSelectedDevice) {
                selectedChosen($scope.singleSelectedDevice.id, 'device', true);
            } else if ($scope.singleSelectedGroup) {
                selectedChosen($scope.singleSelectedGroup.id, 'group', true);
            }
            // else {
            //     $scope.selectTheFirstDevice();
            // }
        }

        $scope.selectTheFirstDevice = function () {
            var firstDevice;
            var firstGroup;
            var i;
            var result = {};

            var nonGroup = _.find($scope.groups, function (group) {
                return group.id === '0' && group.devices && group.devices.length > 0 && group.devices[0];
            });

            if (nonGroup) {
                firstDevice = nonGroup.devices[0];
                firstGroup = nonGroup;
            } else {
                // get the first device inside a group
                for (i = 0; i < $scope.groups.length; i++) {
                    if ($scope.groups[i].id !== '0') {
                        firstGroup = $scope.groups[i];
                        if (
                            $scope.groups[i].devices &&
                            $scope.groups[i].devices.length > 0 &&
                            $scope.groups[i].devices[0]
                        ) {
                            firstDevice = $scope.groups[i].devices[0];
                            break;
                        }
                    }
                }
            }

            result.firstDevice = firstDevice;
            result.firstGroup = firstGroup;

            $scope.unselectDevicesAndGroups();
            $scope.clearSingleSelected();

            if (firstDevice) {
                selectedChosen(firstDevice.id, 'device', true);
                $state.go($scope.devicesBelongsTo + '.devices.device.' + $scope.defaultTab.selection, {
                    id: firstDevice.id,
                });
            } else if (firstGroup) {
                selectedChosen(firstGroup.id, 'group', true);
                $state.go($scope.devicesBelongsTo + '.devices.group.' + $scope.defaultTab.selection, {
                    id: firstGroup.id,
                });
            } else {
                $state.go($scope.devicesBelongsTo + '.devices', {}, { notify: true, reload: true });
                $scope.isFirstTime = isFirstTime(true);
            }

            return result;
            //DevicesService.updateCurrentDevice(devices[devices.length - 1].devices[0], devices[devices.length - 1]);
        };

        function selectedChosen(selectedId, deviceOrGroup, force) {
            if (deviceOrGroup === 'device') {
                if (force || (!$scope.singleSelectedDevice && $scope.selectedDevices.length === 0)) {
                    var result = findDeviceById(selectedId);
                    if (result.device) {
                        result.device.singleIsChecked = true;
                        $scope.singleSelectedDevice = result.device;
                        if (result.group) {
                            result.group.isOpen = true;
                        }
                    } else {
                        $scope.selectTheFirstDevice();
                    }
                }
            } else if (deviceOrGroup === 'group') {
                if (force || (!$scope.singleSelectedGroup && $scope.selectedGroups.length === 0)) {
                    var group = findGroupById(selectedId);
                    if (group) {
                        group.singleIsChecked = true;
                        group.isOpen = true;
                        $scope.singleSelectedGroup = group;
                        group.devices.forEach(function (device) {
                            device.singleSelectedNotActive = $scope.singleSelectedGroup === group;
                        });
                    } else {
                        $scope.selectTheFirstDevice();
                    }
                }
            }
            chooseSelected();
        }

        $scope.$on('DevicesAddGroupController:add_group', function (event, group) {
            if (group) {
                $scope.unselectDevicesAndGroups();
                $scope.clearSingleSelected();
                $state.go($scope.devicesBelongsTo + '.devices.group.' + $scope.defaultTab.selection, {
                    id: group.id,
                });
                $scope.getDevicesAndGroupsByUser().then(function (res) {
                    selectedChosen(group.id, 'group', true);
                    $scope.selectSingleGroup(group);
                    $scope.isFirstTime = isFirstTime();
                    $state.go($scope.devicesBelongsTo + '.devices.group.' + $scope.defaultTab.selection, {
                        id: group.id,
                    });
                });
            }
        });

        $scope.$on('DevicesAddGroupController:add_device', function (event, device) {
            if (device) {
                $scope.unselectDevicesAndGroups();
                $scope.clearSingleSelected();
                $state.go($scope.devicesBelongsTo + '.devices.device.' + $scope.defaultTab.selection, {
                    id: device.id,
                });
                $scope.getDevicesAndGroupsByUser().then(function (res) {
                    selectedChosen(device.id, 'device', true);
                    $scope.isFirstTime = isFirstTime();
                    $state.go($scope.devicesBelongsTo + '.devices.device.' + $scope.defaultTab.selection, {
                        id: device.id,
                    });
                });
            }
        });

        $scope.$on('selected_chosen', function (event, selectedId, deviceOrGroup) {
            selectedChosen(selectedId, deviceOrGroup);
        });

        function checkIfDeviceOrGroupSelected() {
            return $scope.selectedDevices.length === 0 && $scope.selectedGroups.length === 0;
        }

        $scope.sidebarActions = {
            isDisabled: true,
            actions: [],
        };

        function toggleObjectInArray(array, object) {
            var indexOfObject = _.indexOf(array, object),
                result = { action: 'added' };

            if (indexOfObject === -1) {
                array.push(object);
                result.action = 'added';
            } else {
                array.splice(indexOfObject, 1);
                result.action = 'removed';
            }

            return result;
        }

        function changeSidebarActions() {
            $scope.sidebarActions.isDisabled = checkIfDeviceOrGroupSelected();
        }

        $scope.unselectDevicesAndGroups = function () {
            $scope.selectedDevices.forEach(function (device) {
                var group = findDeviceGroup(device);
                $scope.selectDevice(device, group);
            });
            $scope.selectedDevices = [];

            $scope.selectedDevices.forEach(function (group) {
                $scope.selectGroup(group);
            });
            $scope.selectedGroups = [];
        };

        $scope.selectedDevicesOrGroupsHaveChanged = function () {
            changeSidebarActions();
            chooseSelected();

            if (
                (!$scope.singleSelectedGroup && !$scope.singleSelectedDevice && !$scope.defaultSettings.isSelected) ||
                $scope.adminDevices.specificDeviceId ||
                $scope.adminDevices.usersSelection
            ) {
                if ($scope.adminDevices.specificDeviceId) {
                    $scope.unselectDevicesAndGroups();
                    $scope.clearSingleSelected();
                    selectedChosen($scope.adminDevices.specificDeviceId, 'device', true);
                    $state.go($scope.devicesBelongsTo + '.devices.device.' + $scope.defaultTab.selection, {
                        id: $scope.adminDevices.specificDeviceId,
                    });
                    $scope.adminDevices.specificDeviceId = null;
                } else if ($scope.selectedGroups.length === 0) {
                    if ($scope.selectedDevices.length === 0) {
                        $scope.selectTheFirstDevice();
                        // $state.go($scope.devicesBelongsTo + '.devices');
                    } else if ($scope.selectedDevices.length === 1) {
                        $state.go($scope.devicesBelongsTo + '.devices.device.' + $scope.defaultTab.selection, {
                            id: $scope.selectedDevices[0].id,
                        });
                    } else if ($scope.selectedDevices.length > 1) {
                        $state.go($scope.devicesBelongsTo + '.devices.multiple-devices');
                    }
                } else if ($scope.selectedGroups.length === 1) {
                    if ($scope.selectedDevices.length > 0) {
                        $state.go($scope.devicesBelongsTo + '.devices.multiple-devices-and-groups');
                    } else {
                        $state.go($scope.devicesBelongsTo + '.devices.group.' + $scope.defaultTab.selection, {
                            id: $scope.selectedGroups[0].id,
                        });
                    }
                } else if ($scope.selectedGroups.length > 1) {
                    if ($scope.selectedDevices.length > 0) {
                        $state.go($scope.devicesBelongsTo + '.devices.multiple-devices-and-groups');
                    } else {
                        $state.go($scope.devicesBelongsTo + '.devices.multiple-devices-and-groups');
                    }
                }
            }
        };

        $scope.preventOpen = function (event) {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
        };

        $scope.selectDefaultSettings = function (defaultSettingsIsSelected) {
            if (defaultSettingsIsSelected) {
                $scope.clearSingleSelected();
                $state.go($scope.devicesBelongsTo + '.devices.default-settings.basic');
            } else {
                //                $scope.defaultSettings.isSelected = !$scope.defaultSettings.isSelected;
                $scope.selectedDevicesOrGroupsHaveChanged();
            }
        };

        $scope.selectGroup = function (group, index, event) {
            $scope.preventOpen(event);

            group.isChecked = !group.isChecked;

            var deviceToggleState = toggleObjectInArray($scope.selectedGroups, group);

            group.devices.forEach(function (device) {
                if (device.isChecked) {
                    $scope.selectDevice(device, group); // unselect any device under that group
                }

                device.selectedNotActive = deviceToggleState.action === 'added';
            });
        };

        $scope.selectDevice = function (device, group, index, event) {
            if (!device.selectedNotActive) {
                device.isChecked = !device.isChecked;

                $scope.preventOpen(event);
                var deviceToggleState = toggleObjectInArray($scope.selectedDevices, device);

                if (group && group.isChecked) {
                    device.selectedNotActive = deviceToggleState.action === 'removed';
                }
            }
        };

        $scope.selectSingleDevice = function (device, group, index, event) {
            if ($scope.singleSelectedDevice === device) {
                $scope.clearSingleSelected();
            } else {
                $scope.clearSingleSelected();
                $scope.singleSelectedDevice = device;
            }

            device.singleIsChecked = $scope.singleSelectedDevice === device;

            if (group && group.id !== undefined) {
                device.groupId = group.id;
                device.groupName = group.name;
            } else {
                device.groupId = '0';
            }

            if ($scope.defaultSettings.isSelected === true) {
                // Unselect default settings
                $scope.defaultSettings.isSelected = false;
            }

            if ($scope.singleSelectedDevice) {
                $state.go($scope.devicesBelongsTo + '.devices.device.' + $scope.defaultTab.selection, {
                    id: device.id,
                });
            } else {
                $scope.selectedDevicesOrGroupsHaveChanged();
            }
        };

        $scope.selectSingleGroup = function (group, index, event) {
            if ($scope.singleSelectedGroup === group) {
                $scope.clearSingleSelected();
            } else {
                $scope.clearSingleSelected();
                $scope.singleSelectedGroup = group;
            }

            group.singleIsChecked = $scope.singleSelectedGroup === group;

            if (group.devices) {
                group.devices.forEach(function (device) {
                    device.singleSelectedNotActive = $scope.singleSelectedGroup === group;
                });
            }

            if ($scope.defaultSettings.isSelected === true) {
                // Unselect default settings
                $scope.defaultSettings.isSelected = false;
            }

            if ($scope.singleSelectedGroup) {
                $state.go($scope.devicesBelongsTo + '.devices.group.' + $scope.defaultTab.selection, {
                    id: group.id,
                });
            } else {
                $scope.selectedDevicesOrGroupsHaveChanged();
            }
        };

        $scope.clearSingleSelected = function () {
            if ($scope.singleSelectedDevice) {
                $scope.singleSelectedDevice.singleIsChecked = false;
                $scope.singleSelectedDevice = null;
            }

            if ($scope.singleSelectedGroup) {
                if ($scope.singleSelectedGroup.devices) {
                    $scope.singleSelectedGroup.devices.forEach(function (device) {
                        device.singleSelectedNotActive = false;
                    });
                }

                $scope.singleSelectedGroup.singleIsChecked = false;
                $scope.singleSelectedGroup = null;
            }
        };

        $scope.addDevice = function () {
            $state.go($scope.devicesBelongsTo + '.devices.add-device');
        };

        $scope.addGroup = function (fromSelected) {
            $state.go($scope.devicesBelongsTo + '.devices.add-group', { fromSelected: fromSelected });
        };

        function onDeleteDevicesAndGroupsSuccess(res) {
            var messageParams = {};
            messageParams.message = 'Success! displays / groups were deleted';
            messageParams.size = 'md';
            Messages.openMessage($scope, messageParams);

            if (!$localStorage.pushy.pushyIsSupported || !$localStorage.pushy.notificationIsGranted) {
                $scope.refreshSidebar();
            }
        }

        function onDeleteDevicesAndGroupsError(error) {
            var messageParams = {};
            messageParams.message =
                'Error! Failed to delete displays / groups.\nThe ids are: ' +
                error.unsuccessGroupsDeleted +
                ' and: ' +
                error.$unsuccessDevicesDeleted;
            messageParams.disableAutoDismiss = true;
            messageParams.size = 'md';
            Messages.openMessage($scope, messageParams);
        }

        $scope.deleteDevicesAndGroups = function (Devices, Groups) {
            var data = {
                groupsId: Groups,
                devicesId: Devices,
            };

            $http
                .post('/devices/deleteGroupsAndDevices', data)
                .success(onDeleteDevicesAndGroupsSuccess)
                .error(onDeleteDevicesAndGroupsError);
        };

        $scope.deleteDevicesAndGroupsPopup = function (device, group) {
            var devicesNames = '',
                devicesId = [],
                groupsNames = '',
                groupsId = [],
                messageParams = {};

            if (!device && !group) {
                // Delete from selected devices and groups
                $scope.selectedDevices.forEach(function (device, index) {
                    if (0 === index) {
                        // first
                        devicesNames = device.name;
                    } else {
                        devicesNames += ', ' + device.name;
                    }
                    devicesId.push(device.id);
                });

                $scope.selectedGroups.forEach(function (group, index) {
                    if (0 === index) {
                        groupsNames = group.name;
                    } else {
                        groupsNames += ', ' + group.name;
                    }
                    groupsId.push(group.id);
                });
            } else if (device) {
                devicesNames = device.name;
                devicesId.push(device.id);
            } else if (group) {
                groupsNames = group.name;
                groupsId.push(group.id);
            }

            if (User.isProCurator() && $state.includes('admin-area.devices')) {
                messageParams.message = 'Pro Curator is not allowed to delete displays';
                messageParams.title = 'Delete Displays And Groups';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            } else {
                messageParams.message = 'You are about to delete:\n';
                messageParams.message += devicesNames !== '' ? 'Displays: ' + devicesNames + '\n' : '';
                messageParams.message += groupsNames !== '' ? 'Groups: ' + groupsNames + '\n' : '';
                messageParams.message += groupsNames !== '' ? '\n* All displays will be detached from that group' : '';
                messageParams.title = 'Delete Displays And Groups';
                messageParams.size = 'lg';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Delete';
                messageParams.okClass = 'danger';
                messageParams.okFunction = Partial.func($scope.deleteDevicesAndGroups, devicesId, groupsId);
                Messages.openMessage($scope, messageParams);
            }
        };

        function onMoveToGroupSuccess(res) {
            var messageParams = {};
            messageParams.message = 'Success! displays were moved to group';
            messageParams.size = 'md';
            Messages.openMessage($scope, messageParams);

            if (!$localStorage.pushy.pushyIsSupported || !$localStorage.pushy.notificationIsGranted) {
                $scope.refreshSidebar();
            }
        }

        function onMoveToGroupError(error) {
            var messageParams = {};
            messageParams.message =
                'Error! Failed to move displays to group.\nThe ids are: ' +
                error.unsuccessGroupsDeleted +
                ' and: ' +
                error.$unsuccessDevicesDeleted;
            messageParams.title = 'Move Displays To Group';
            messageParams.disableAutoDismiss = true;
            messageParams.size = 'md';
            Messages.openMessage($scope, messageParams);
        }

        $scope.moveToGroup = function (toGroup, devices, fromGroup) {
            var data = {
                toGroupId: toGroup,
                devicesId: devices,
                fromGroupId: fromGroup,
            };

            $http.post('/devices/changeDevicesGroup', data).success(onMoveToGroupSuccess).error(onMoveToGroupError);
        };

        $scope.moveToGroupPopup = function (toGroup, device, fromGroup) {
            var devicesNames = '',
                devicesId = [],
                messageParams = {};

            if (!device) {
                // Move all selected devices
                if ($scope.singleSelectedDevice) {
                    devicesNames = $scope.singleSelectedDevice.name;
                    devicesId.push($scope.singleSelectedDevice.id);
                } else {
                    $scope.selectedDevices.forEach(function (device, index) {
                        if (0 === index) {
                            // first
                            devicesNames = device.name;
                        } else {
                            devicesNames += ', ' + device.name;
                        }
                        devicesId.push(device.id);
                    });
                }
            } else {
                devicesNames = device.name;
                devicesId.push(device.id);
            }

            messageParams.message = 'You are about to move ';
            messageParams.message += devicesNames !== '' ? devicesNames : '';
            if (toGroup.id === -1) {
                messageParams.message += ' to outside any group.';
            } else {
                messageParams.message += ' to ' + toGroup.name + ' group.';
            }
            messageParams.message +=
                '\nThe display settings and content will be overridden.\nAre sure you want to continue?';
            messageParams.title = 'Move Displays To Group';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Move';
            messageParams.okFunction = Partial.func($scope.moveToGroup, toGroup.id, devicesId, fromGroup.id);
            Messages.openMessage($scope, messageParams);
        };

        $scope.getMaxHeightOfSideAndMainElements = function () {
            var sideBarHeight = angular.element(document.getElementById('devices-sidebar'))[0].offsetHeight;
            var mainAreaHeight = angular.element(document.getElementById('devices-main-area'))[0].offsetHeight;
            var maxHeight = Math.max(sideBarHeight, mainAreaHeight, 100);
            maxHeight += 'px';

            return { height: maxHeight };
        };

        $scope.getBackgroundColorStyle = function (color) {
            return { 'background-color': color };
        };

        $scope.openRemoteControl = function () {
            var device = $scope.singleSelectedDevice || $scope.selectedDevices[0];
            var group = $scope.singleSelectedGroup || $scope.selectedGroups[0];
            var messageParams = {};
            messageParams.hideHeader = true;
            messageParams.disableAutoDismiss = true;
            messageParams.okText = 'Close';
            messageParams.template = 'modules/devices/views/devices.selected.remote-control.client.view.html';
            //messageParams.templateStyle = {'height': '80vh'};
            messageParams.size = 'lg';
            messageParams.templateController = 'DevicesSelectedRemoteControlController';
            messageParams.templateParams = { device: device, group: group };
            messageParams.windowClass = 'footer-set-higher-z-index modal-medium-width';
            Messages.openMessage($scope, messageParams);
        };

        // ADMIN PANEL
        $scope.specificUserSelected = (entity) => {
            if (entity?.selected?.type === 'device' && $scope.adminDevices.usersSelection) {
                $scope.adminUserId = $scope.adminDevices.specificUserId = entity.selected.user_id;
                $scope.adminDevices.specificDeviceId = entity.selected.id;
            } else if (entity?.selected?.basicType === 'user' && $scope.adminDevices.usersSelection) {
                $scope.adminUserId = $scope.adminDevices.specificUserId = entity.selected.id;
            }
            $scope.getDevicesAndGroupsByUser();
        };

        $scope.getDevicesAndGroupsByUser = function () {
            var userId =
                $scope.adminDevices && $scope.adminDevices.usersSelection ? $scope.adminDevices.specificUserId : '';

            return DevicesDataService.getDevicesAndGroups(userId).then(
                function (res) {
                    $log.debug('updated displays and groups');
                    updateDeviceAndGroups(res);
                    $scope.selectedDevicesOrGroupsHaveChanged();
                },
                function (error) {
                    $log.debug('Error fetching displays and groups for ' + userId + '. Error: ', error);
                }
            );
        };

        $scope.$on('pushy_update_list', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_list', data);
            $scope.getDevicesAndGroupsByUser().then(function (res) {
                reselectChosen();
            });
        });

        $scope.$on('pushy_update_device_presence', function (event, data) {
            $log.debug('DevicesSelectedController::pushy_update_device_presence', data);
            let result = findDeviceById(data.deviceId);
            if (result.device) {
                $scope.$apply(() => {
                    result.device.is_online = data.isOnline;
                });
            }
        });
    },
]);
