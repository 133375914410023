'use strict';

angular.module('core').service('AdditionalFilesService', [
    '$http',
    '$rootScope',
    '$q',
    function ($http, $rootScope, $q) {
        var self = this;

        function isAllowedForUnauth(action) {
            var result = false;

            // if (action.indexOf('getArtworksPathsForThumbnail') > -1) {
            //     result = true;
            // }

            return result;
        }

        // this.handleCampaignAction = function (actionName, campaign, params) {
        //     var deferred = $q.defer(),
        //         campaignId = campaign.id,
        //         actionNotAllowed,
        //         action;
        //     params = params || {};
        //
        //     if (!User.exists() && !isAllowedForUnauth(actionName)) {
        //         $log.debug('CampaignService::handleArtworkAction action not allowed', {campaign: campaign, action: actionName});
        //         $rootScope.$broadcast('Auth:Failed');
        //         actionNotAllowed = true;
        //     }
        //
        //     switch (actionName) {
        //         case 'delete':
        //             action = self.deleteCampaign;
        //             break;
        //         default:
        //             action = '';
        //             $log.debug('trying to perform an action on campaign that is not defined');
        //     }
        //
        //     if (action && !actionNotAllowed) {
        //         action(campaign, params)
        //             .then(function (res) {
        //                 $log.debug('CampaignService::' + actionName + ' Success', {campaign: campaign, params: params, res: res});
        //                 if (actionName !== 'getArtworksPathsForThumbnail') {
        //                     $analytics.eventTrack(actionName + 'Campaign', {
        //                         category: 'Campaign',
        //                         label: 'ID: ' + campaign.id + ' Name: ' + campaign.name
        //                     });
        //                 }
        //                 deferred.resolve(res);
        //             }, function (err) {
        //                 if (err && err === 'campaign.is_locked') {
        //                     openPasswordPopup('campaign', {campaign:campaign, campaignParams:params});
        //                     deferred.reject(err);
        //                 }
        //
        //                 $log.debug('CampaignService::' + actionName + ' Failure', {campaign: campaign, params: params, error: err});
        //                 deferred.reject(err);
        //             });
        //     } else {
        //         deferred.reject();
        //     }
        //
        //     return deferred.promise;
        // };

        this.updateEntityAdditionalFileStatus = function (entityId, entityType, params, entity) {
            var deferred = $q.defer();
            var data = {
                additionalFileId: params.additionalFileId,
                status: params.status,
                reason: params.reason,
            };

            data[entityType + 'Id'] = entityId;

            $http.post('/additionalFiles/updateEntityAdditionalFileStatus', data).then(
                function (res) {
                    // if (artwork) artwork.status = params.status;
                    deferred.resolve(entityId);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };
    },
]);
