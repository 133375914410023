'use strict';

angular.module('home-page').controller('HomePageController', [
    '$rootScope',
    '$scope',
    '$http',
    '$stateParams',
    '$window',
    '$timeout',
    'Security',
    'Messages',
    'OpenPopupForm',
    'DetectMobileBrowser',
    'NIIO_URL',
    'NIIO_APP',
    '$interval',
    'OpenDeepLink',
    function (
        $rootScope,
        $scope,
        $http,
        $stateParams,
        $window,
        $timeout,
        Security,
        Messages,
        OpenPopupForm,
        DetectMobileBrowser,
        NIIO_URL,
        NIIO_APP,
        $interval,
        OpenDeepLink
    ) {
        $scope.OpenPopupForm = OpenPopupForm;
        $scope.legacyHomepage = true;

        function handleResetPasswordFlow() {
            var messageParams = {};

            if ($stateParams.reset) {
                // Success - continue to reset password
                if ($stateParams.reset === '1') {
                    OpenDeepLink.func(
                        NIIO_APP + 'reset?code=' + $stateParams.code + '&email=' + $stateParams.email
                    ).then(
                        function (res) {
                            // Successfully opened the link in the app
                            return;
                        },
                        function (err) {
                            var params = {
                                reset: true,
                                email: $stateParams.email,
                                code: $stateParams.code,
                            };
                            Messages.openPasswordChangeModal(params);
                        }
                    );
                } else if ($stateParams.reset === '2') {
                    messageParams.title = 'Reset Password';
                    messageParams.message =
                        'Oops, there seem to be a problem with resetting your password. Please contact support (press OK to continue)';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        OpenPopupForm.openContact('support', $stateParams.email, 'reset password problem');
                    });
                } else if ($stateParams.reset === '3') {
                    OpenPopupForm.openMenu('login', { email: $stateParams.email });
                }
            }
        }

        function handleVerificationFlow() {
            var messageParams = {};

            if ($stateParams.v) {
                if ($stateParams.v === '1') {
                    messageParams.title = 'Verification Completed Successfully';
                    messageParams.message = 'Your email has been verified. Click OK to login.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        OpenPopupForm.openMenu('login', { email: $stateParams.email });
                    });
                } else if ($stateParams.v === '2') {
                    messageParams.title = 'Account Already Verified';
                    messageParams.message = 'Your account email was already verified. Click OK to login';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        $scope.openMenu('login', { email: $stateParams.email });
                    });
                } else if ($stateParams.v === '3') {
                    messageParams.title = 'Email Verification Failed';
                    messageParams.message =
                        'Oops, there seem to be a problem with verifying your account email. Please contact support (press OK to continue)';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                        OpenPopupForm.openContact(
                            'support',
                            $stateParams.email,
                            'email verification failure'
                        );
                    });
                }
            }
        }

        function onPlayerReady(player, sources, videoId, poster) {
            // Disable spinner when looping: https://github.com/videojs/video.js/issues/2677#event-428241177
            //self.videoArtwork.on('seeking', function (e) {
            //    if (self.videoArtwork.currentTime() === 0) {
            //        self.videoArtwork.addClass('vjs-looping');
            //    }
            //});
            //self.videoArtwork.on('playing', function (e) {
            //    if (self.videoArtwork.currentTime() === 0) {
            //        self.videoArtwork.removeClass('vjs-looping');
            //    }
            //});

            player.on('ended', function (e) {
                if (player.playlist().length - 1 === player.playlist.currentItem()) {
                    player.playlist.first(); // Will go to the second item due to bug
                }
            });

            var playlist = [];

            for (var i = 0; i < sources.length; i++) {
                playlist.push({
                    sources: [
                        {
                            src: sources[i],
                            type: 'video/mp4',
                        },
                    ],
                    //poster: poster
                });
            }

            player.playlist(playlist);
            // Play through the playlist automatically.
            //player.playlist.next();
            player.playlist.autoadvance(0);

            // Make video plays inline on iPhone - https://github.com/bfred-it/iphone-inline-video#readme
            //var video = document.getElementById(videoId + '_html5_api_html5_api');
            //var video = document.getElementById(videoId + '_html5_api_html5_api');
            //if (!video) {
            //    video = document.getElementById(videoId + '_html5_api');
            //}
            //$rootScope.makeVideoPlayableInline(video, /* hasAudio */ false, false /* false = run everywhere */);
            //player.play();
        }

        function loadVideoPlayer(sources, poster, videoId) {
            var placeholder = document.getElementById('mediaPlaceholder' + videoId);
            var player = document.createElement('video');
            player.setAttribute('id', videoId);
            player.setAttribute('autoplay', '');
            player.setAttribute('muted', '');
            player.setAttribute('loop', ''); // Should be on only when there is 1 artwork!!
            player.setAttribute('webkit-playsinline', '');
            player.setAttribute('class', 'IIV video-js vjs-default-skin');
            player.setAttribute('preload', 'auto');
            //player.setAttribute('width', self.artwork.file_metadata.width);
            //player.setAttribute('height', self.artwork.file_metadata.height);
            player.setAttribute('data-setup', '{ "aspectRatio":"16:9"}');
            //player.setAttribute('data-setup', '{"nativeControlsForTouch": false}');

            player.setAttribute('poster', poster);

            // Setup the media
            //for (var i = 0; i < sources.length; i++) {
            //    var media = document.createElement('source');
            //    media.setAttribute('id', 'videoArtworkSource');
            //    media.setAttribute('src', sources[i]);
            //    media.setAttribute('type', 'video/mp4');
            //    player.appendChild(media);
            //}

            placeholder.appendChild(player);

            $window
                .videojs(videoId, {
                    //'example_option': true,
                    //'customControlsOnMobile': false,
                    //'children': {
                    //    'loadingSpinner': false
                    //},
                    nativeControlsForTouch: false,
                    controls: false,
                    // 'children':
                    controlBar: {
                        remainingTimeDisplay: false,
                    },

                    //plugins: {
                    //    chromecast: {
                    //        appId: undefined,
                    //        metadata: {
                    //            title: "Title",
                    //            subtitle: "Subtitle"
                    //        }
                    //    }
                    //}
                })
                .ready(function () {
                    $scope['player' + videoId] = this;
                    onPlayerReady($scope['player' + videoId], sources, videoId, poster);
                });
        }

        $scope.intro = {
            step: 0,
            // steps: window.homepageBackgrounds,
            steps: [
                {
                    id: '1',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-1.jpeg',
                    credit: 'Ori Gersht, Big Bang',
                },
                {
                    id: '2',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-2.jpeg',
                    credit: 'Joe Hamilton, Cezanne Unfixed',
                },
                {
                    id: '3',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-3.jpeg',
                    credit: 'Zeitguised, geist.xyz',
                },
                {
                    id: '4',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-4.jpeg',
                    credit: 'Joe Hamilton, Close to Infinite',
                },
                {
                    id: '5',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-5.jpeg',
                    credit: 'Jonathan Monaghan, The Phoenix and the Medusa',
                },
                {
                    id: '6',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-6.jpeg',
                    credit: 'Jonathan Monaghan, No Longer Dead',
                },
                {
                    id: '7',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-7.jpeg',
                    credit: 'ZEITGUISED, ZEITGUISED-x-KVADRAT',
                },
                {
                    id: '8',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-8.jpeg',
                    credit: 'Quayola, Camouflage C',
                },
                {
                    id: '9',
                    classes: 'home_step_2',
                    bg: 'modules/home-page/img/intro-bg-9.jpeg',
                    credit: 'Sara Ludy, Fire Mountain',
                },
            ],
            refreshInterval: undefined,
            cancelInterval: function () {
                if (angular.isDefined($scope.intro.refreshInterval)) {
                    var result = $interval.cancel($scope.intro.refreshInterval);
                    $scope.intro.refreshInterval = undefined;
                }
            },
            startInterval: function () {
                if (angular.isDefined($scope.intro.refreshInterval)) {
                    return;
                }

                $scope.intro.step = 0;
                $scope.intro.refreshInterval = $interval(function () {
                    $scope.intro.step++;
                    $scope.intro.step %= $scope.intro.steps.length;
                }, 10000);
            },
        };

        $scope.init = function () {
            $rootScope.homepage = true;
            $scope.legacyHomepage = true;

            if ($rootScope.lastStateBeforeLogin === 'add-device') {
                displayJoinModal();
            }

            //playInlineVideoMobile();
            handleVerificationFlow();
            handleResetPasswordFlow();

            if ($stateParams.invitationCode) {
                $http
                    .post('/invitations/isInvitationCodeValid', { code: $stateParams.invitationCode })
                    .then(function (res) {
                        if (res.data.data.valid) {
                            OpenPopupForm.openMenu('join');
                        } else {
                            displayJoinModal();
                        }
                    });
            } else if ($stateParams.o === 'i') {
                OpenPopupForm.openMenu('invitation', { source: $stateParams.s });
            } else if ($stateParams.o === 'j') {
                OpenPopupForm.openMenu('join');
            } else if ($stateParams.o) {
                displayJoinModal();
            }
        };

        function displayJoinModal() {
            if ($scope.loginDisplayedInline !== undefined && $scope.loginDisplayedInline) {
                return;
            }
            OpenPopupForm.openMenu('join');
        }

        function onSuccessInvitation() {
            var messageParams = {};
            messageParams.message = 'Invitation sent.';
            Messages.openMessage($rootScope, messageParams);
        }

        function onFailedInvitation() {
            var messageParams = {};
            messageParams.message = 'Failed to send invitation.';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($rootScope, messageParams);
        }

        $scope.submitInvitation = function () {
            Security.sendInvitation($scope.user, onSuccessInvitation, onFailedInvitation);
        };
    },
]);
