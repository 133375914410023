'use strict';

angular.module('core').directive('formListErrors', [
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                form: '=',
                addedClass: '=?',
            },
            templateUrl: 'modules/core/directives/core.form-list-errors.client.view.html',
            controller: 'FormListErrorsController',
            controllerAs: 'vm',
            bindToController: true, //required in 1.3+ with controllerAs
        };
    },
]);
