'use strict';

angular.module('home').controller('HomeArtworkPasswordController', [
    'HomeDataService',
    function (HomeDataService) {
        var self = this;
        this.HomeDataService = HomeDataService;

        this.init = function () {
            HomeDataService.artworkSharedPassword = '';
        };
    },
]);
