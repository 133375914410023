'use strict';

angular.module('form').directive('billingForm', [
    // Structure for items: [{name: ..., value: ..., disabled: true / false}, {name: ..., value: ..., disabled: true / false}]
    function () {
        return {
            restrict: 'E',
            // = - two way binding
            // @ - one way bind
            // & - function
            scope: {
                title: '=',
                price: '=',
                currency: '=',
                payedItemId: '=',
                errors: '=',
                isYearly: '=',
                trialDays: '=',
                onLoad: '&?',
                onSubmit: '&?',
            },
            transclude: true,
            templateUrl: 'modules/billing/views/billing.form.client.view.html',
            controller: 'BillingFormController',
            controllerAs: 'vm',
            bindToController: true,
        };
    },
]);
