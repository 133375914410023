const Sentry = require('@sentry/browser');

// Authentication service for user variables
angular.module('users').service('User', [
    '$rootScope',
    '$q',
    '$localStorage',
    '$filter',
    '$log',
    '$http',
    '$state',
    '_',
    'Messages',
    'TokenService',
    'GoogleAnalytics',
    '$window',
    '$stateParams',
    function (
        $rootScope,
        $q,
        $localStorage,
        $filter,
        $log,
        $http,
        $state,
        _,
        Messages,
        TokenService,
        GoogleAnalytics,
        $window,
        $stateParams
    ) {
        var self = this;
        var numOfDefaultProfiles = 8;
        var numOfDefaultCovers = 4;
        this.$storage = $localStorage;
        this.profileImageDefaultPath = '/modules/users/img/defaultProfilePics/profile';
        this.coverImageDefaultPath = '/modules/users/img/defaultProfilePics/cover';

        this.tryRestoreCookie = function () {
            var tk = TokenService.getToken();
            var display_name = this.getDisplayName() || '';
            var cookiedata = { name: display_name };
            TokenService.setToken(tk, cookiedata);
        };

        this.tryLoginWithQueryParams = async (params) => {
            if (params.token) {
                TokenService.setToken(params.token, {});
                TokenService.getOrCreatePublicToken(params.publicToken);
                await self.updateUserDetailsFromServer();
                self.tryRestoreCookie();
                delete params.token;
                delete params.publicToken;
            }
        };

        this.setUserDetails = function (userDetails) {
            if (userDetails !== null) {
                var copiedUser = angular.copy(userDetails);
                if (copiedUser.token) {
                    var cookiedata = { name: copiedUser.display_name || '' };
                    TokenService.setToken(copiedUser.token, cookiedata);
                }
                delete copiedUser.token;
                self.$storage.userDetails = copiedUser;

                Sentry.setUser({ id: copiedUser.id });
                GoogleAnalytics.setUserId(copiedUser.id);
                GoogleAnalytics.setUserProperty('user_title', $rootScope.$user.getUserTitleValue()); // user_title
            }
        };

        this.getUserDetailsFromServer = function ({ token = null, publicToken = null } = {}) {
            if (!token) {
                token = TokenService.getToken();
                publicToken = TokenService.getOrCreatePublicToken();
            }

            var deferred = $q.defer();
            $http.get('/users/getUserDetails').then(
                function (response) {
                    deferred.resolve(response.data.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );
            return deferred.promise;
        };

        this.updateUserDetailsFromServer = async function () {
            const userDetails = await self.getUserDetailsFromServer();
            self.setUserDetails(userDetails);
        };

        this.getUserDetails = function () {
            var user = '';

            if (self.$storage.userDetails) {
                user = angular.copy(self.$storage.userDetails);
            }
            return user;
        };

        this.getUserDetailsWithBind = function () {
            return self.$storage.userDetails || null;
        };

        this.exists = function () {
            var result;
            if (self.$storage.userDetails) {
                result = true;
            } else {
                //$rootScope.$broadcast('Auth:Failed');
                result = false;
            }
            return result;
        };

        this.clearUser = function () {
            var deferred = $q.defer();

            $rootScope.$broadcast('clear_user');
            delete self.$storage.userDetails;
            deferred.resolve();

            Sentry.setUser(null);

            return deferred.promise;
        };

        // $rootScope.$on('ClearData', function() {
        //    self.clearUser();
        // });

        this.followUser = function (followingUserId) {
            const data = {
                followerUserId: self.getUserDetails().id,
                followingUserId,
            };

            return $http.post('/users/followUser', data);
        };

        this.unfollowUser = function (followingUserId) {
            const data = {
                followerUserId: self.getUserDetails().id,
                followingUserId,
            };

            return $http.post('/users/unfollowUser', data);
        };

        this.hasRole = function (role) {
            var currentRoleId = self.getCurrentRoleId();
            if (Array.isArray(role)) {
                // in case one of the roles exist
                return role.includes(currentRoleId);
            } else {
                return currentRoleId === role;
            }
        };

        this.isAdmin = function () {
            return self.hasRole(self.getAdminRoleId());
        };

        this.isOtherFunc = () => {
            const userProfile = self.getProfile();
            return userProfile.url !== $state.params.url;
        };

        this.isProCurator = function () {
            return self.hasRole(self.getProCuratorRoleId());
        };

        this.hasTitle = function (title, profile) {
            // Artist / Collector / Curator
            var user = profile || self.getUserDetails();
            return _.indexOf(user.titles, title) > -1;
        };

        this.isArtist = function (profile) {
            return self.hasTitle('1', profile);
        };

        this.isCollector = function (profile) {
            return self.hasTitle('2', profile);
        };

        this.isCurator = function (profile) {
            return self.hasTitle('3', profile);
        };

        this.isArtfan = function (profile) {
            return self.hasTitle('4', profile);
        };

        this.isOrganization = function (profile) {
            return self.hasTitle('5', profile);
        };

        this.isOrganizationTypeBusiness = (profile) => {
            let result = false;

            if (self.isOrganization(profile)) {
                let user = profile || self.getUserDetails();

                if (user.organization_type === 'BS') {
                    result = true;
                }
            }
            return result;
        };

        this.isGallery = function () {
            if (self.getOrganizationType() === $filter('translate')('CORE.ORG_TYPE_GA')) {
                return true;
            }
            return false;
        };

        this.isAuthenticated = function () {
            return angular.isDefined(self.$storage.userDetails) && self.$storage.userDetails !== undefined;
        };

        this.isVisible = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.visible : true;
        };

        // Getters
        this.getAdminRoleId = function () {
            return '1';
        };

        this.getProCuratorRoleId = function () {
            return '3';
        };
        this.getCurrentRoleId = function () {
            return self.getUserDetails().role_id;
        };

        this.isOfficialAccount = function (profile) {
            var user = profile || self.getUserDetails();
            return user.official_account ? !!user.official_account : false;
        };

        this.getId = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.id : '';
        };

        this.getFirstName = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.first_name : '';
        };

        this.getLastName = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.last_name : '';
        };

        this.getDisplayName = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.display_name : '';
        };

        this.getIntercomIdentityVerification = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.intercom_identity_verification_hash : '';
        };

        this.getUsername = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.username : '';
        };
        this.getOrganizationByTitle = function ($type) {
            if ($type === 'galleries') {
                return 'GA';
            }
            if ($type === 'art_event') {
                return 'AE';
            }
            if ($type === 'museums') {
                return 'MU';
            }
            if ($type === 'archives') {
                return 'AR';
            }
            if ($type === 'academies') {
                return 'AC';
            }
            if ($type === 'institutions') {
                return 'IN';
            }
            if ($type === 'installations') {
                return 'HO';
            }
            if ($type === 'other_organizations') {
                return 'OT';
            }
        };

        this.getUserTitleNum = function ($title) {
            if ($title === 'artists') {
                return '1';
            }
            if ($title === 'collectors') {
                return '2';
            }
            if ($title === 'curators') {
                return '3';
            }
            if ($title === 'art_fans') {
                return '4';
            }
            return '5';
        };

        this.getUserTitleValue = function () {
            if (self.isArtist()) {
                return 'artist';
            } else if (self.isCollector()) {
                return 'collector';
            } else if (self.isCurator()) {
                return 'curator';
            } else if (self.isArtfan()) {
                return 'art_fan';
            } else if (self.isGallery()) {
                return 'gallery';
            } else if (self.isOrganization()) {
                return 'organization';
            } else {
                return '';
            }
        };

        this.getOrganizationType = function () {
            var result = '';
            if (self.$storage.userDetails && self.hasTitle('5')) {
                if (self.$storage.userDetails.organization_type === 'OT') {
                    result = self.$storage.userDetails.organization_type_other;
                } else {
                    result = $filter('translate')(`CORE.ORG_TYPE_${self.$storage.userDetails.organization_type}`);
                }
            }

            return result;
        };

        this.getEmail = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.email : '';
        };

        this.getTitles = function () {
            // Artist / Collector / Curator
            return self.getUserDetails().titles;
        };

        this.getUrl = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.url : '';
        };

        this.getPlan = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.plan : '';
        };

        this.getPlanType = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.plan_type : '';
        };

        this.isFreeUser = function () {
            return self.getPlan() === '4f95307c-0cb2-11e8-ac68-55f1bcd80799';
        };

        this.isUpgradable = function () {
            return self.$storage.userDetails && self.$storage.userDetails.plan_upgradable !== undefined
                ? self.$storage.userDetails.plan_upgradable
                : true;
        };

        this.getCountry = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.country : '';
        };

        this.getState = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.state : '';
        };

        this.getCity = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.city : '';
        };

        this.getPlanExpires = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.plan_expires : '';
        };

        this.planWillExpire = function () {
            return self.$storage.userDetails
                ? self.$storage.userDetails.plan_expires && !self.$storage.userDetails.plan_will_renew
                : false;
        };

        this.isAutoRenew = function () {
            return self.$storage.userDetails.plan_will_renew == '1';
        };

        this.getProfile = function () {
            return self.$storage.userDetails || '';
        };

        this.getDefaultProfilePath = function () {
            return self.profileImageDefaultPath + (self.getId() % numOfDefaultProfiles);
        };

        this.getDefaultCoverPath = function () {
            return self.coverImageDefaultPath;
        };

        this.getProfileImagePath = function () {
            return self.$storage.userDetails
                ? self.$storage.userDetails.profile_path || self.getDefaultProfilePath()
                : '';
        };

        this.getCoverImagePath = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.cover_path || self.getDefaultCoverPath() : '';
        };

        this.getOtherProfileImagePath = function (profilePath, userId) {
            return profilePath || self.profileImageDefaultPath + (userId % numOfDefaultProfiles);
        };

        this.getOtherCoverImagePath = function (coverPath, userId) {
            return coverPath || self.coverImageDefaultPath + (userId % numOfDefaultCovers);
        };

        this.getFollowers = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.followers : [];
        };

        this.getFollowings = function () {
            return self.$storage.userDetails ? self.$storage.userDetails.followings : [];
        };

        // Setters
        this.setProfileCVPath = function (path) {
            self.$storage.userDetails.cv_path = path;
        };

        this.setProfileCVFilename = function (filename) {
            self.$storage.userDetails.cv_filename = filename;
        };

        this.setProfileImagePath = function (path) {
            self.$storage.userDetails.profile_path = path;
        };

        this.setCoverImagePath = function (path) {
            self.$storage.userDetails.cover_path = path;
        };

        this.getBrowser = function () {
            var userAgent = $window.navigator.userAgent;
            var browsers = {
                chrome: /chrome/i,
                safari: /safari/i,
                firefox: /firefox/i,
                ie: /internet explorer/i,
            };
            for (var key in browsers) {
                if (browsers[key].test(userAgent)) {
                    return key;
                }
            }
            return 'unknown';
        };

        // Two Factor Auth
        this.getTwoFactorAuthQR = function () {
            return $http.get('/users/generateTwoFactorAuthUrl');
        };

        this.disableTwoFactorAuth = function () {
            return $http.get('/users/removeTwoFactorAuthSecret');
        };

        this.validateTwoFactorAuthCode = function (code) {
            return $http.get(`/users/validateTwoFactorAuthCode/${code}`);
        };

        this.setPlan = function (plan) {
            self.$storage.userDetails.plan = plan;
        };

        this.setProvider = function ({ id, name }) {
            self.$storage.userDetails.provider_id = id;
            self.$storage.userDetails.provider_name = name;
        };

        this.setPlanDisplayName = function (planDisplayName) {
            self.$storage.userDetails.plan_display_name = planDisplayName;
        };

        this.setPlanType = function (planType) {
            self.$storage.userDetails.plan_type = planType;
        };

        this.setPlanUpgradable = function (planUpgradable) {
            self.$storage.userDetails.plan_upgradable = planUpgradable;
        };

        this.setPlanExpires = function (planExpires) {
            self.$storage.userDetails.plan_expires = planExpires;
        };

        this.setPlanWillRenew = function (planWillRenew) {
            self.$storage.userDetails.plan_will_renew = planWillRenew;
        };

        this.setPlanIsDowngraded = function (planIsDwongraded) {
            self.$storage.userDetails.plan_is_downgraded = planIsDwongraded;
        };

        this.setUserUsedTrial = function (userUsedTrial) {
            self.$storage.userDetails.user_used_trial = userUsedTrial;
        };

        this.getUserUsedTrial = function () {
            return self.$storage?.userDetails?.user_used_trial || 0;
        };

        this.checkUserUpgraded = function (check, announce) {
            var deferred = $q.defer();

            var data = {};
            if (check) {
                data.planUid = self.getPlan();
            }

            $http.post('/userPlans/userUpgraded', data).then(
                (res) => {
                    if (res.data && res.data.data) {
                        data = res.data.data;
                        if (data && data.plan && data.plan_name) {
                            self.setPlan(data.plan);
                            self.setPlanDisplayName(data.plan_name);
                            self.setPlanType(data.plan_type);
                            self.setPlanUpgradable(data.plan_upgradable);
                            self.setPlanExpires(data.plan_expires);
                            self.setProvider({
                                id: data.plan_provider_id,
                                name: data.plan_provider_name,
                            });

                            const prevAutoRenewStatus = self.isAutoRenew();

                            self.setPlanWillRenew(data.plan_will_renew);
                            self.setPlanIsDowngraded(data.is_downgraded);
                            self.setUserUsedTrial(data.used_trial);
                            $rootScope.pendingPayment = false;
                            delete $rootScope.backFromPaymentBool;
                            if (announce) {
                                const currentAutoRenewStatus = self.isAutoRenew();
                                const messageParams = {};
                                if (data.is_downgraded) {
                                    messageParams.title = 'Plan Expired';
                                    messageParams.message = `Please note: Your current plan is expired and changed to “${data.plan_name}”.`;
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams);
                                } else if (!currentAutoRenewStatus && prevAutoRenewStatus !== currentAutoRenewStatus) {
                                    messageParams.title = 'Plan Auto Renew was canceled';
                                    messageParams.message =
                                        'Please note: Your current plan will not auto renew in end of term.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams);
                                } else {
                                    messageParams.title = `You Were Upgraded To ${data.plan_name}`;
                                    messageParams.message = `Congratulations! You've been successfully upgraded to the ${data.plan_name} membership.\nEnjoy!`;
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams);
                                }
                            }
                            $rootScope.$emit('UserUpgraded', true);
                            deferred.resolve(data);
                        }
                        deferred.resolve();
                    }
                    deferred.resolve();
                },
                (err) => {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        this.init = function () {
            $log.debug('UserService::init');
            self.checkUserUpgraded();
        };

        $rootScope.$on('InitServices', () => {
            self.init();
        });

        $rootScope.$on('pushy_update_user_plan', (event, data) => {
            $log.debug('UserService::pushy_update_user_plan', data);
            const needCheck = data?.skipCheck !== true || false;
            self.checkUserUpgraded(needCheck, true);
        });
    },
]);
