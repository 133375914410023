'use strict';

angular.module('core').filter('userTitleName', [
    'USER_TITLES',
    'ORGANIZATION_TYPES',
    function (USER_TITLES, ORGANIZATION_TYPES) {
        return function (input, organizationType, organizationTypeOther) {
            var result = '';
            var numberInput = parseInt(input);

            if (typeof numberInput === 'number' && numberInput > 0 && numberInput < 6) {
                if (numberInput === 5) {
                    if (organizationType === 'OT') {
                        result = organizationTypeOther;
                    } else {
                        result = ORGANIZATION_TYPES[organizationType];
                    }
                } else {
                    result = USER_TITLES[numberInput];
                }
            }
            return result;
        };
    },
]);
