'use strict';

//Authentication service for user variables
angular.module('core').factory('PendingRequestsInterceptor', [
    '$q',
    '$rootScope',
    'SERVER',
    function ($q, $rootScope, SERVER) {
        $rootScope.pendingRequests = 0;
        $rootScope.showLoading = false;
        var pendingWhitelist = [
            SERVER.address + '/artworks/processArtwork',
            SERVER.address + '/artworks/getStatus',
            SERVER.address + '/users/registerRemoteForUser',
        ];

        function show() {
            $rootScope.pendingRequests++;
        }

        function hide() {
            $rootScope.pendingRequests--;
            if ($rootScope.pendingRequests <= 0) {
                $rootScope.$broadcast('ajax-stop');
                $rootScope.showLoading = false;
            }
        }

        $rootScope.$watch('pendingRequests', function (newVal, oldVal) {
            setTimeout(function () {
                if ($rootScope.pendingRequests > 0 && !$rootScope.showLoading) {
                    $rootScope.$broadcast('ajax-start');
                    $rootScope.showLoading = true;
                }
            }, 2000);
        });

        return {
            request: function (config) {
                if (!pendingWhitelist.includes(SERVER.address + config.url)) {
                    show();
                }
                return $q.when(config);
            },
            response: function (response) {
                if (!pendingWhitelist.includes(response.config.url)) {
                    hide();
                }
                return $q.when(response);
            },
            responseError: function (rejection) {
                if (!pendingWhitelist.includes(rejection?.config?.url)) {
                    hide();
                }
                return $q.reject(rejection);
            },
        };
    },
]);
