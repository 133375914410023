'use strict';

angular.module('devices').controller('RemoteArtworkThumbnailController', [
    '$scope',
    '$element',
    '$attrs',
    '$log',
    '$timeout',
    function ($scope, $element, $attrs, $log, $timeout) {
        var self = this;
        this.artwork.isActive = false;

        this.init = function () {
            if (self.isplayed !== undefined) {
                self.isActive = self.isplayed();
            }
        };

        this.onClicked = function () {
            if (self.clicked !== undefined) {
                self.clicked({
                    artwork: self.artwork,
                    channel: self.channel,
                });
            }
        };

        this.onCheckClicked = function () {
            if (self.checkclicked !== undefined) {
                self.checkclicked({
                    artwork: self.artwork,
                    channel: self.channel,
                });
            }
        };

        this.isArtworkActive = function (currentActive) {
            var artworkId = currentActive.artworkId;
            var channelId = currentActive.channelId;
            var collectionId = currentActive.collectionId;
            if (artworkId === undefined) {
                return false;
            }

            if (self.channel !== undefined && channelId !== self.channel.id) {
                return false;
            }

            if (self.artwork !== undefined && artworkId !== self.artwork.id) {
                return false;
            }

            if (self.collection !== undefined && collectionId !== self.collection.id) {
                return false;
            }

            return true;
        };

        // var updatedEvent = self.collection && self.collection.id ?
        //     'current_active_updated_AR' + self.artwork.id + '_CO' + self.collection.id + '_CH' + self.channel.id
        //     : 'current_active_updated_AR' + self.artwork.id + '_CH' + self.channel.id;
        var updatedEvent = 'current_active_updated';
        $scope.$on(updatedEvent, function (event, currentActive) {
            if (self.isplayed !== undefined) {
                $timeout(function () {
                    self.artwork.isActive = self.isArtworkActive(currentActive);
                    if (self.artwork.isActive) {
                        $scope.$apply();
                        $log.debug(
                            'RemoteArtworkThumbnailController::current_active_updated isActive for artwork ' +
                                self.artwork.id +
                                ' is ',
                            self.artwork.isActive
                        );
                    }
                });
            }
        });

        $scope.$on('artwork_remove_active', function (event) {
            if (self.artwork.isActive) {
                $timeout(function () {
                    self.artwork.isActive = false;
                });
            }
        });
    },
]);
