'use strict';

angular.module('core').service('Partial', function () {
    this.func = function (func /*, 0..m args */) {
        var args = Array.prototype.slice.call(arguments, 1);
        return function () {
            var allArguments = args.concat(Array.prototype.slice.call(arguments));
            return func.apply(this, allArguments);
        };
    };
});
