'use strict';

// Setting up route
angular.module('discover').config([
    '$stateProvider',
    '$urlRouterProvider',
    function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider
            .when('/discover', '/discover/for-you')
            .when('/discover/artworks', '/discover/artworks/all')
            .when('/discover/artists', '/discover/artists/featured')
            .when('/discover/members', '/discover/members/featured')
            .when('/discover/channels', '/discover/channels/featured')
            .when('/discover/catalogs', '/discover/catalogs/featured')
            .when('/discover/main', '/discover/main/featured');
        //            .when('/discover/vault', 'discover/vault/vault/Shai2')
        //            .when('/discover/channels', 'discover/channels/vault/Shai2');

        // Users state routing
        $stateProvider
            .state('discover', {
                url: '/discover',
                //                abstract: true,
                templateUrl: 'modules/discover/views/discover.client.view.html',
                controller: 'DiscoverController',
                data: {
                    discoverPassword: true,
                },
            })

            .state('discover.channels', {
                url: '/channels?type',
                abstract: true,
                templateUrl: 'modules/discover/views/discover.channels.client.view.html',
                controller: 'DiscoverChannelsController',
                data: {
                    security: {
                        secured: true,
                    },
                    type: 'channels',
                },
            })

            .state('discover.channels.main', {
                url: '/:channelsName?theme&portrait',
                templateUrl: 'modules/discover/views/discover.channels.main.client.view.html',
                controller: 'DiscoverChannelsMainController',
            })

            .state('discover.channels.channel', {
                url: '/:channelsName/:channelUrlName',
                templateUrl: 'modules/channel-info/views/channel-info.channel.client.view.html',
                controller: 'ChannelInfoChannelController',
                controllerAs: 'vm',
                data: {
                    from: 'discovery-artcasts',
                },
                onEnter: [
                    '$rootScope',
                    function ($rootScope) {
                        $rootScope.$emit('discover_hide_sidebar');
                    },
                ],
                onExit: [
                    '$rootScope',
                    function ($rootScope) {
                        $rootScope.$emit('discover_show_sidebar');
                    },
                ],
            })

            .state('discover.catalogs', {
                url: '/catalogs',
                abstract: true,
                templateUrl: 'modules/discover/views/discover.channels.client.view.html',
                controller: 'DiscoverChannelsController',
                data: {
                    security: {
                        secured: true,
                    },
                    type: 'catalogs',
                },
            })

            .state('discover.catalogs.main', {
                url: '/:channelsName?theme&portrait',
                templateUrl: 'modules/discover/views/discover.channels.main.client.view.html',
                controller: 'DiscoverChannelsMainController',
                data: {
                    from: 'discovery-catalogs-main',
                },
            })

            .state('discover.catalogs.channel', {
                url: '/:channelsName/:channelUrlName',
                templateUrl: 'modules/channel-info/views/channel-info.channel.client.view.html',
                controller: 'ChannelInfoChannelController',
                controllerAs: 'vm',
                data: {
                    from: 'discovery-catalogs',
                },
                onEnter: [
                    '$rootScope',
                    function ($rootScope) {
                        $rootScope.$emit('discover_hide_sidebar');
                    },
                ],
                onExit: [
                    '$rootScope',
                    function ($rootScope) {
                        $rootScope.$emit('discover_show_sidebar');
                    },
                ],
            })

            .state('discover.for-you', {
                url: '/for-you',
                templateUrl: 'modules/discover/views/discover.for-you.client.view.html',
                controller: 'DiscoverForYouController',
                controllerAs: 'vm',
                data: {
                    security: {
                        secured: true,
                    },
                },
                resolve: {
                    dynamicJson: [
                        'DiscoverDataService',
                        '$log',
                        '$rootScope',
                        '$state',
                        '$stateParams',
                        'Messages',
                        function (DiscoverDataService, $log, $rootScope, $state, $stateParams, Messages) {
                            return DiscoverDataService.getDynamicLayoutJson().then(
                                function (res) {
                                    return res.data.data;
                                },
                                function (reason) {
                                    $log.debug('discover.for-you', {
                                        state: $state,
                                        stateParams: $stateParams,
                                        reason: reason,
                                    });
                                    var messageParams = {};
                                    messageParams.message = 'The URL is not valid, redirecting.';
                                    messageParams.disableAutoDismiss = true;
                                    Messages.openMessage($rootScope, messageParams).then(function (res) {
                                        $rootScope.previousState('home');
                                    });
                                }
                            );
                        },
                    ],
                },
            })

            .state('discover.main-tab', {
                url: '/main',
                abstract: true,
                templateUrl: 'modules/discover/views/discover.main-tab.client.view.html',
                controller: 'DiscoverMainTabController',
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('discover.main-tab.main', {
                url: '/:sidebarSelection',
                templateUrl: 'modules/discover/views/discover.main-tab.main.client.view.html',
                controller: 'DiscoverMainTabMainController',
            })

            .state('discover.artworks', {
                url: '/artworks',
                abstract: true,
                templateUrl: 'modules/discover/views/discover.artworks.client.view.html',
                controller: 'DiscoverArtworksController',
                data: {
                    security: {
                        secured: true,
                    },
                    type: 'artworks',
                },
            })

            .state('discover.artworks.main', {
                url: '/:artworksName?theme&portrait',
                templateUrl: 'modules/discover/views/discover.artworks.main.client.view.html',
                controller: 'DiscoverArtworksMainController',
            })
            .state('discover.artists', {
                url: '/artists',
                abstract: true,
                templateUrl: 'modules/discover/views/discover.artists.client.view.html',
                controller: 'DiscoverArtistsController',
                data: {
                    security: {
                        secured: true,
                    },
                    type: 'artists',
                },
            })

            .state('discover.artists.main', {
                url: '/:sidebarSelection',
                templateUrl: 'modules/discover/views/discover.artists.main.client.view.html',
                controller: 'DiscoverArtistsMainController',
            })

            .state('discover.members', {
                url: '/members',
                abstract: true,
                templateUrl: 'modules/discover/views/discover.members.client.view.html',
                controller: 'DiscoverMembersController',
                data: {
                    security: {
                        secured: true,
                    },
                },
            })

            .state('discover.members.main', {
                url: '/:sidebarSelection',
                templateUrl: 'modules/discover/views/discover.members.main.client.view.html',
                controller: 'DiscoverMembersMainController',
            })

            //            .state('discover.artworkFromPlayer', {
            //                url: '^/artwork/:artworkId?deviceId&secret',
            //                templateUrl: 'modules/discover/views/discover.deviceArtwork.client.view.html',
            //                controller: 'DiscoverArtworkController',
            //                resolve: {
            //                    artwork: ['DiscoverDataService', '$log', '$rootScope', '$q', '$location', '$state', '$stateParams', 'User', 'Messages', function (DiscoverDataService, $log, $rootScope, $q, $location, $state, $stateParams, User, Messages) {
            //                        var deferred = $q.defer();
            //
            //                        if (User.isAuthenticated()) {
            //                            DiscoverDataService.getArtwork($stateParams.artworkId)
            //                                .then(function (value) {
            //                                    deferred.resolve(value);
            //                                }, function (reason) {
            //                                    $log.debug('discover.artworkFromPlayer::', {state: $state, stateParams: $stateParams, reason: reason});
            //                                    var messageParams = {};
            //                                    messageParams.message = 'Artwork doesn\'t exist.';
            //                                    messageParams.disableAutoDismiss = true;
            //                                    Messages.openMessage($rootScope, messageParams);
            ////                                    $state.go('login');
            //                                });
            //                        }
            //
            //                        return deferred.promise;
            //                    }]
            //                }
            //            })

            .state('discover.channel', {
                url: '^/channel/:channelUrlName?artworkId&deviceId&secret&share&invitationCode&utm_source&utm_medium&utm_campaign&campaignId',
                templateUrl: 'modules/channel-info/views/channel-info.channel.client.view.html',
                controller: 'ChannelInfoChannelController',
                controllerAs: 'vm',
                data: {
                    from: 'discovery-invitation',
                    discoverPassword: false,
                },
                resolve: {
                    // openInApp: [
                    //     'OpenDeepLink',
                    //     'NIIO_APP',
                    //     '$stateParams',
                    //     function (OpenDeepLink, NIIO_APP, $stateParams) {
                    //         return OpenDeepLink.func(NIIO_APP).then(
                    //             function (res) {
                    //                 // Successfully opened the link in the app
                    //             },
                    //             function (err) {
                    //                 // Continue with regular web
                    //             }
                    //         );
                    //     },
                    // ],
                    isShareValid: [
                        '$rootScope',
                        '$stateParams',
                        '$state',
                        '$q',
                        '$http',
                        'DiscoverDataService',
                        'Messages',
                        function ($rootScope, $stateParams, $state, $q, $http, DiscoverDataService, Messages) {
                            var deferred = $q.defer();

                            if ($stateParams.deviceId && $stateParams.secret) {
                                var data = {
                                    deviceId: $stateParams.deviceId,
                                    deviceCode: $stateParams.secret,
                                };

                                $http.post('/users/loginByDevice', data).then(
                                    function (res) {
                                        deferred.resolve(res);
                                    },
                                    function (error) {
                                        var messageParams = {};
                                        messageParams.message = 'This link is not valid, redirecting home.\n';
                                        messageParams.disableAutoDismiss = true;
                                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                                            $state.go('home');
                                        });
                                        //deferred.reject(error);
                                    }
                                );
                            } else if ($stateParams.share) {
                                DiscoverDataService.isPublishActive(
                                    $stateParams.share,
                                    null,
                                    $stateParams.channelUrlName
                                ).then(
                                    function (res) {
                                        deferred.resolve();
                                    },
                                    function (error) {
                                        var messageParams = {};
                                        messageParams.message =
                                            'This link is no longer valid.\nPlease contact the channel owner for a new url.';
                                        messageParams.disableAutoDismiss = true;
                                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                                            $state.go('home');
                                        });
                                        //deferred.reject();
                                    }
                                );
                            } else {
                                // Public open channel page
                                deferred.resolve();

                                // var messageParams = {};
                                // messageParams.message = 'This link is not valid, redirecting home.\n';
                                // messageParams.disableAutoDismiss = true;
                                // Messages.openMessage($rootScope, messageParams)
                                //     .then(function (res) {
                                //         $state.go('home');
                                //     });
                                //deferred.reject('No credential to view this page');
                            }

                            return deferred.promise;
                        },
                    ],
                },
            })

            .state('discover.artwork', {
                url: '^/artwork/:artworkId?channelId&deviceId&secret&share&invitationCode',
                templateUrl: 'modules/artwork-info/views/artwork-info.wrapper.client.view.html',
                controller: 'ArtworkInfoArtworkController',
                controllerAs: 'vm',
                data: {
                    discoverPassword: false,
                    from: 'discovery',
                },
                resolve: {
                    // openInApp: [
                    //     'OpenDeepLink',
                    //     'NIIO_APP',
                    //     '$stateParams',
                    //     function (OpenDeepLink, NIIO_APP, $stateParams) {
                    //         return OpenDeepLink.func(NIIO_APP).then(
                    //             function (res) {
                    //                 // Successfully opened the link in the app
                    //             },
                    //             function (err) {
                    //                 // Continue with regular web
                    //             }
                    //         );
                    //     },
                    // ],
                    isShareValid: [
                        '$rootScope',
                        '$stateParams',
                        '$state',
                        '$q',
                        '$http',
                        'DiscoverDataService',
                        'Messages',
                        function ($rootScope, $stateParams, $state, $q, $http, DiscoverDataService, Messages) {
                            var deferred = $q.defer();

                            if ($stateParams.deviceId && $stateParams.secret) {
                                var data = {
                                    deviceId: $stateParams.deviceId,
                                    deviceCode: $stateParams.secret,
                                };

                                $http.post('/users/loginByDevice', data).then(
                                    function (res) {
                                        deferred.resolve(res);
                                    },
                                    function (error) {
                                        var messageParams = {};
                                        messageParams.message = 'This link is not valid, redirecting home.\n';
                                        messageParams.disableAutoDismiss = true;
                                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                                            $state.go('home');
                                        });
                                        //deferred.reject(error);
                                    }
                                );
                            } else if ($stateParams.share) {
                                DiscoverDataService.isPublishActive($stateParams.share, $stateParams.artworkId).then(
                                    function (res) {
                                        deferred.resolve();
                                    },
                                    function (error) {
                                        var messageParams = {};
                                        messageParams.message =
                                            'This link is no longer valid.\nPlease contact the artwork owner for a new url.';
                                        messageParams.disableAutoDismiss = true;
                                        Messages.openMessage($rootScope, messageParams).then(function (res) {
                                            $state.go('home');
                                        });
                                        //deferred.reject();
                                    }
                                );
                            } else {
                                // Public open artwork page

                                // var messageParams = {};
                                // messageParams.message = 'This link is not valid, redirecting home.\n';
                                // messageParams.disableAutoDismiss = true;
                                // Messages.openMessage($rootScope, messageParams)
                                //     .then(function (res) {
                                //         $state.go('home');
                                //     });
                                deferred.resolve();
                                //deferred.reject('No credential to view this page');
                            }

                            return deferred.promise;
                        },
                    ],
                },
            })

            .state('discover.artworks.artwork', {
                url: '/:artworksName/:artworkId',
                templateUrl: 'modules/artwork-info/views/artwork-info.artwork.client.view.html',
                controller: 'ArtworkInfoArtworkController',
                controllerAs: 'vm',
                data: {
                    from: 'discovery',
                },
                onEnter: [
                    '$rootScope',
                    function ($rootScope) {
                        $rootScope.$emit('discover_hide_sidebar');
                    },
                ],
                onExit: [
                    '$rootScope',
                    function ($rootScope) {
                        $rootScope.$emit('discover_show_sidebar');
                    },
                ],
                //templateUrl: 'modules/discover/views/discover.artwork.client.view.html',
                //controller: 'DiscoverArtworkController',
                //resolve: {
                //    artwork: ['DiscoverDataService', '$log', '$rootScope', '$location', '$q', '$state', '$stateParams', 'Messages', function (DiscoverDataService, $log, $rootScope, $location, $q, $state, $stateParams, Messages) {
                //        var deferred = $q.defer();
                //
                //        DiscoverDataService.getArtwork($stateParams.artworkId)
                //            .then(function (value) {
                //                deferred.resolve(value);
                //            }, function (reason) {
                //                $log.debug('discover.artworks.artwork::', {state: $state, stateParams: $stateParams, reason: reason});
                //                var messageParams = {};
                //                messageParams.message = 'Artwork doesn\'t exist.';
                //                messageParams.disableAutoDismiss = true;
                //                Messages.openMessage($rootScope, messageParams)
                //                    .then(function (res) {
                //                        $state.go('discover.main-tab.main', {sidebarSelection: 'featured'});
                //                    });
                //            });
                //
                //        return deferred.promise;
                //    }]
                //}
            })

            .state('discover.error', {
                url: '/{path:.*}',
                resolve: {
                    notValidLink: [
                        '$log',
                        '$state',
                        '$rootScope',
                        '$location',
                        '$stateParams',
                        'Messages',
                        function ($log, $state, $rootScope, $location, $stateParams, Messages) {
                            $log.debug('discover.error::', { state: $state, stateParams: $stateParams });
                            var messageParams = {};
                            messageParams.message =
                                'The URL ' +
                                $rootScope.$platformManager.platform.url +
                                '/discover/' +
                                $stateParams.path +
                                ' is not valid, redirecting to your home.';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams).then(function (res) {
                                $rootScope.previousState('home');
                            });
                        },
                    ],
                },
            });
    },
]);
