'use strict';

angular.module('core').service('PlatformManager', [
    '$location',
    '$log',
    function ($location, $log) {
        this.platform = {
            name: undefined,
            capital: undefined,
            url: undefined,
        };

        function getUrl() {
            return $location.protocol() + '://' + $location.host();
        }

        var niio = {
            name: 'niio',
            capital: 'Niio',
        };

        var novu = {
            name: 'novu',
            capital: 'Novu',
        };

        this.setPlatform = function () {
            var host = $location.host();

            //$log.debug('PlatformManager::setPlatform Checking platform by host ' + host);

            if (host.indexOf('niio.com') > -1 || host.indexOf('niio.one') > -1 || host.indexOf('niio.dev') > -1) {
                this.platform = angular.copy(niio);
            } else if (host.indexOf('no.vu') > -1) {
                this.platform = angular.copy(novu);
            } else if (host.indexOf('localhost') > -1) {
                this.platform = angular.copy(niio);
            } else {
                $log.error(`PlatformManager::setPlatform could not resolve host: ${host}`);
            }

            this.platform.url = getUrl();

            //$log.debug('PlatformManager::setPlatform Found platform', this.platform);
        };
    },
]);
