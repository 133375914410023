'use strict';

angular.module('core').directive('fbImage', [
    function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                var fn = function () {
                    element.css({
                        'background-image': 'url(' + attrs.fbImage + ')',
                        'background-size': 'cover',
                        'background-position': 'center',
                        margin: 'auto',
                    });
                };
                fn();
                attrs.$observe('fbImage', function (newValue, oldValue) {
                    //console.log('fbImage changed:', newValue);
                    if (newValue !== oldValue) {
                        fn();
                    }
                });
            },
        };
    },
]);
