'use strict';

angular.module('admin-area').controller('AdminAreaEventsSelectedContentController', [
    '$rootScope',
    '$scope',
    function ($rootScope, $scope) {
        var self = this;
        $scope.customForm = '';

        $scope.contentEditor = {
            viewMode: 'preview',
            editMode: undefined,
            editQid: undefined,
            editQidx: undefined,
            temp: undefined,
            getEditedItem: function () {
                if ($scope.contentEditor.temp === undefined) {
                    return undefined;
                }

                return angular.copy($scope.contentEditor.temp);
            },
            onTypeChanged: function () {
                if ($scope.contentEditor.temp.description === undefined) {
                    $scope.contentEditor.temp.description = '';
                }

                if (
                    $scope.contentEditor.temp.type === undefined ||
                    $scope.contentEditor.temp.type === 'title'
                ) {
                    if ($scope.contentEditor.temp.question !== undefined) {
                        $scope.contentEditor.temp.title = $scope.contentEditor.temp.question;
                        $scope.contentEditor.temp.question = undefined;
                        return;
                    }
                }

                if (
                    $scope.contentEditor.temp.type !== undefined &&
                    $scope.contentEditor.temp.type !== 'title'
                ) {
                    if ($scope.contentEditor.temp.title !== undefined) {
                        $scope.contentEditor.temp.question = $scope.contentEditor.temp.title;
                        $scope.contentEditor.temp.title = undefined;
                    }

                    //be sure we have qid
                    $scope.contentEditor.temp.qid =
                        $scope.contentEditor.temp.qid || $scope.contentEditor.editQid || generateNewQid();
                    $scope.contentEditor.editQid = $scope.contentEditor.temp.qid;
                }
            },
        };

        $scope.onCustomFormRawChanged = function () {
            $scope.customForm = JSON.parse($scope.currentEvent.event.custom_form_structure);
        };

        this.updateQuestionsFromUI = function () {
            for (var i = 0; i < $scope.customForm.length; i++) {
                var item = $scope.customForm[i];
                if (item.type === 'title' && item.qid) {
                    delete item.qid;
                }
            }

            $scope.currentEvent.event.custom_form_structure = JSON.stringify($scope.customForm);
        };

        this.updateContent = function () {
            $scope.eventCtrl.saveCurrentEventContent($scope.currentEvent);
        };

        this.moveQuestionUp = function (idx, item) {
            if (idx <= 0 || idx > $scope.customForm.length) {
                return;
            }
            var temp = angular.copy($scope.customForm[idx]);
            $scope.customForm[idx] = angular.copy($scope.customForm[idx - 1]);
            $scope.customForm[idx - 1] = temp;
        };

        this.moveQuestionDown = function (idx, item) {
            if (idx < 0 || idx >= $scope.customForm.length - 1) {
                return;
            }

            var temp = angular.copy($scope.customForm[idx]);
            $scope.customForm[idx] = angular.copy($scope.customForm[idx + 1]);
            $scope.customForm[idx + 1] = temp;
        };

        this.removeQuestion = function (idx, item) {
            if (idx < 0 || idx >= $scope.customForm.length) {
                return;
            }
            $scope.customForm.splice(idx, 1);
        };

        this.editQuestion = function (idx, item) {
            if (idx < 0 || idx >= $scope.customForm.length) {
                return;
            }
            $scope.contentEditor.editMode = 'edit';
            $scope.contentEditor.editQid = item.qid;
            $scope.contentEditor.editQidx = idx;
            $scope.contentEditor.temp = angular.copy(item);
        };

        this.cancelEditQuestion = function () {
            $scope.contentEditor.editMode = undefined;
            $scope.contentEditor.editQid = undefined;
            $scope.contentEditor.editQidx = undefined;
            $scope.contentEditor.temp = undefined;
        };

        function randomString(length) {
            //TODO: Duplicate with s3...
            var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            var result = '';
            for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
            return result;
        }
        function generateNewQid() {
            return $scope.currentEvent.event.id + '_' + randomString(16);
        }

        this.addNewQuestionRow = function () {
            var newItem = {
                qid: generateNewQid(),
                type: 'textarea',
                question: 'New question',
            };
            $scope.customForm = $scope.customForm || [];
            $scope.customForm.push(newItem);
            self.updateQuestionsFromUI();
            self.editQuestion($scope.customForm.length - 1, newItem);

            //$scope.contentEditor.editMode = 'edit';
            //$scope.contentEditor.editQidx = $scope.customForm.length;
            //$scope.contentEditor.temp =
            //$scope.contentEditor.editQid = $scope.contentEditor.temp.qid;
        };

        this.saveEditQuestion = function () {
            var idx = $scope.contentEditor.editQidx;
            if (idx === undefined || idx < 0 || idx >= $scope.customForm.length) {
                return;
            }

            $scope.customForm[idx] = $scope.contentEditor.getEditedItem();
            self.cancelEditQuestion();
        };

        this.init = function () {
            $scope.customForm = JSON.parse($scope.currentEvent.event.custom_form_structure);
            $rootScope.$broadcast('admin_event_selectedTab', 'content');
        };
    },
]);
