'use strict';

angular.module('admin-area').controller('AdminAreaServicesBatchImportController', [
    '$scope',
    '$http',
    '$log',
    'Messages',
    'Search',
    '_',
    function ($scope, $http, $log, Messages, Search, _) {
        var self = this;
        this.report = this.params ? this.params.report : {};
        this.toggles = this.toggles || {};
        this.Search = Search;

        // Grid
        this.orderByField = 'id';
        this.reverseSort = true;

        this.specialTypes = [
            { view: 'Not Special', value: null },
            { view: 'Categories', value: 'categories' },
            { view: 'Genres', value: 'genres' },
            { view: 'Keywords', value: 'keywords' },
            { view: 'Date of Creation', value: 'creation' },
        ];

        this.specificUserSelected = function (item, model, label) {
            if (item.basicType === 'user') {
                self.newImport.userId = item.id;
            }
        };

        function initNewImport() {
            self.newImport = {
                name: '',
                userId: '',
                parameters: {
                    mapping: [],
                    joinMapping: [],
                },
            };
        }

        this.getNiioFieldsList = function () {
            self.toggles.loadingFields = true;
            $http
                .post('/batchImport/getNiioFieldsList')
                .then(
                    function (res) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::getNiioFieldsList Success retrieving fields',
                            res
                        );
                        self.fieldsFromServer = res.data ? res.data.fields : [];
                        self.fields = [{ view: 'Other / Special', value: 'other' }];
                        _.each(self.fieldsFromServer, function (table, tableKey) {
                            _.each(table, function (field, fieldKey) {
                                self.fields.push({
                                    view: field,
                                    value: tableKey + '.' + field,
                                });
                            });
                        });
                    },
                    function (error) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::getNiioFieldsList Error retrieving fields',
                            error
                        );
                    }
                )
                .finally(function () {
                    self.toggles.loadingFields = false;
                });
        };

        this.loadImport = function (batchImport) {
            var parameters = JSON.parse(batchImport.parameters);
            self.newImport = batchImport;
            self.newImport.parameters = parameters;
        };

        this.getBatchImports = function () {
            self.toggles.loadingBatchImports = true;
            $http
                .post('/batchImport/getBatchImports')
                .then(
                    function (res) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::getBatchImports Success retrieving fields',
                            res
                        );
                        self.batchImports = res.data ? res.data.batchImports : [];
                    },
                    function (error) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::getBatchImports Error retrieving fields',
                            error
                        );
                    }
                )
                .finally(function () {
                    self.toggles.loadingBatchImports = false;
                });
        };

        this.addMatch = function () {
            var match = {
                niioField: '',
                importedField: '',
                forceValue: '',
                format: '',
                specialType: '',
                matchExisting: false,
            };
            self.newImport.parameters.mapping.push(match);
        };

        this.removeMatch = function (match) {
            self.newImport.parameters.mapping.splice(self.newImport.parameters.mapping.indexOf(match), 1);
        };

        this.addJoin = function () {
            var join = {
                field1: '',
                field2: '',
            };
            self.newImport.parameters.joinMapping.push(join);
        };

        this.removeJoin = function (join) {
            self.newImport.parameters.joinMapping.splice(
                self.newImport.parameters.joinMapping.indexOf(join),
                1
            );
        };

        this.createNewImport = function () {
            var data = angular.copy(self.newImport);
            self.toggles.submitWasClicked = true;
            $http
                .post('/batchImport/createNewImport', data)
                .then(
                    function (res) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::createNewImport Success importing',
                            res
                        );
                        var messageParams = {};
                        messageParams.message = 'Successfully ran batch.\n';
                        messageParams.message += res.data;
                        messageParams.title = 'Batch Import';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    },
                    function (error) {
                        $log.debug(
                            'AdminAreaServicesBatchImportController::createNewImport Error importing',
                            error
                        );
                        var messageParams = {};
                        messageParams.message = 'Failed to ran batch.\n';
                        messageParams.message += error.data.message.text;
                        messageParams.title = 'Batch Import';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($scope, messageParams);
                    }
                )
                .finally(function () {
                    self.toggles.submitWasClicked = false;
                });
        };

        this.init = function () {
            self.submitWasClicked = false;
            self.reportWasClicked = false;
            initNewImport();
            self.getNiioFieldsList();
            self.getBatchImports();
        };
    },
]);
