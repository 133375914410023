'use strict';

angular.module('core').directive('ngEnter', [
    function () {
        return function (scope, element, attrs) {
            element.bind('keydown keypress', function (event) {
                if (event.which === 13) {
                    event.preventDefault();

                    scope.$apply(function () {
                        scope.$eval(attrs.ngEnter);
                    });
                }
            });
        };
    },
]);

angular.module('core').directive('clickedDisable', function ($rootScope) {
    return {
        restrict: 'A',
        link: function (scope, ele, attrs) {
            ele.bind('click', function () {
                attrs.$set('disabled', true);
            });
            //$(ele).click(function () {
            //    $(ele).attr('disabled', true);
            //});
            var events = scope.$eval(attrs.clickedDisable);
            if (events === undefined || events.length === undefined || events.length === 0) {
                return;
            }
            angular.forEach(events, function (eventName) {
                $rootScope.$on(eventName, function () {
                    attrs.$set('disabled', false);
                });
            });
        },
    };
});

//ref: https://gist.github.com/mlynch/dd407b93ed288d499778
angular.module('core').directive('angularAutoFocus', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            setTimeout(function () {
                element[0].focus();
            }, 100);
        },
    };
});
