'use strict';

angular.module('core').filter('formatDate', [
    '$filter',
    function ($filter) {
        return function (input, dateFormat) {
            var dateObject = new Date(input),
                formatedDate;
            if (!dateFormat) {
                dateFormat = 'longDate';
            }
            if (dateObject.toString() !== 'Invalid Date') {
                formatedDate = $filter('date')(new Date(dateObject), dateFormat);
            } else {
                formatedDate = input;
            }
            return formatedDate;
        };
    },
]);
