'use strict';

angular.module('core').service('ngPushy', [
    '$window',
    '$q',
    '$log',
    '$rootScope',
    'Messages',
    function ($window, $q, $log, $rootScope, Messages) {
        var self = this;
        this.pushy = $window.Pushy;

        function callPushyRegister(deferred) {
            self.pushy
                .register({ appId: '5e2ee1dbcc95c3343ee338f4' })
                .then(function (deviceToken) {
                    // Print device token to console
                    $log.debug('ngPushy::callPushyRegister Pushy device token: ', deviceToken);

                    // Send the token to your backend server via an HTTP GET request
                    //fetch('https://your.api.hostname/register/device?token=' + deviceToken);

                    // Succeeded, optionally do something to alert the user
                    deferred.resolve(deviceToken);
                })
                .catch(function (err) {
                    var permission = Notification.permission;
                    // Handle registration errors
                    $log.debug('ngPushy::callPushyRegister Error init Pushy service: ', permission, err);
                    deferred.reject(err);
                });
        }

        this.registerNew = function () {
            var deferred = $q.defer();
            callPushyRegister(deferred);
            return deferred.promise;
        };
        // return $window.Pushy;
    },
]);
