'use strict';

angular.module('admin-area').controller('AdminAreaEventsSelectedController', [
    '$rootScope',
    '$scope',
    '$q',
    '$filter',
    '$state',
    'currentEvent',
    'FormValidationService',
    'Messages',
    'EventsDataService',
    'HomeDataService',
    'User',
    '_',
    function (
        $rootScope,
        $scope,
        $q,
        $filter,
        $state,
        currentEvent,
        FormValidationService,
        Messages,
        EventsDataService,
        HomeDataService,
        User,
        _
    ) {
        var self = this;
        this.EventsDataService = EventsDataService;
        this.currentEvent = $scope.currentEvent = currentEvent;
        this.fromState = 'admin-area';
        this.maxArtworksSubmitted = 0;
        this.userId = User.getId();

        this.pages = [
            {
                link: 'settings',
                name: 'Settings',
            },
            {
                link: 'users',
                name: 'Users',
            },
            {
                link: 'content',
                name: 'Submission Form',
            },
            {
                link: 'terms',
                name: 'Terms',
            },
        ];

        this.saveCurrentEventSettings = function (currentEvent) {
            var event = currentEvent ? currentEvent.event : self.currentEvent.event;
            $scope.submitWasClicked = false;
            var df = EventsDataService.saveCurrentEventSettings(event);
            handleSaveCurrentEventResult(df);
            return df;
        };
        this.saveCurrentEventContent = function (currentEvent) {
            var event = currentEvent ? currentEvent.event : self.currentEvent.event;
            $scope.submitWasClicked = false;
            var df = EventsDataService.saveCurrentEventContent(event);
            handleSaveCurrentEventResult(df);
            return df;
        };

        this.changeEventHost = function (newHostId) {
            var eventId = self.currentEvent.event.id;
            $scope.submitWasClicked = false;
            var df = EventsDataService.changeEventHost(eventId, newHostId);
            handleSaveCurrentEventResult(df);
            return df;
        };

        this.addEventCohost = function (newCohostId, newCohostType) {
            var eventId = self.currentEvent.event.id;
            $scope.submitWasClicked = false;
            var df = EventsDataService.addEventCohost(eventId, newCohostId, newCohostType);
            handleSaveCurrentEventResult(df);
            return df;
        };

        this.updateEventCohost = function (cohostId, cohostType) {
            var eventId = self.currentEvent.event.id;
            $scope.submitWasClicked = false;
            var df = EventsDataService.updateEventCohost(eventId, cohostId, cohostType);
            handleSaveCurrentEventResult(df);
            return df;
        };

        this.removeEventCohost = function (cohostId) {
            var eventId = self.currentEvent.event.id;
            $scope.submitWasClicked = false;
            var df = EventsDataService.removeEventCohost(eventId, cohostId);
            handleSaveCurrentEventResult(df);
            return df;
        };

        this.removeSubmitter = function (submitterId) {
            var eventId = self.currentEvent.event.id;
            $scope.submitWasClicked = false;
            var df = EventsDataService.removeSubmitter(eventId, submitterId);
            handleSaveCurrentEventResult(df);
            return df;
        };

        function handleSaveCurrentEventResult(promise) {
            promise.then(
                function () {
                    var eventId = $scope.currentEvent.event.id || $scope.currentEvent.eventId;
                    EventsDataService.getEventHostData(eventId, null, false /* showOnlySubmitted) */).then(
                        function (event) {
                            if (event.event.id === self.currentEvent.event.id) {
                                $scope.currentEvent = event;
                                self.extendCurrentEvent();
                            }
                        }
                    );

                    var messageParams = {};
                    messageParams.message = 'Event Data saved successfully';
                    $scope.submitWasClicked = false;
                    Messages.openMessage($scope, messageParams);
                },
                function (error) {
                    var messageParams = {};
                    messageParams.message = 'Save Event data failed.\n';
                    messageParams.title = 'Failed To Save Event Data';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    $scope.submitWasClicked = false;
                }
            );
        }

        this.clearSubmissionDate = function (submitterId) {
            var event = self.currentEvent.event;
            $scope.submitWasClicked = false;
            var df = EventsDataService.clearSubmissionDate(event.id, submitterId);
            handleSaveCurrentEventResult(df);
            return df;
        };

        this.extendCurrentEvent = function () {
            $scope.currentEvent.event.submission_fee =
                $scope.currentEvent.event.submission_fee && $scope.currentEvent.event.submission_fee !== '0'
                    ? parseInt($scope.currentEvent.event.submission_fee)
                    : null;
            $scope.currentEvent.event.max_allowed_artworks = $scope.currentEvent.event.max_allowed_artworks
                ? parseInt($scope.currentEvent.event.max_allowed_artworks)
                : 0;
            $scope.currentEvent.event.eventStatus = 'VS'; //TODO: Mapping from/to event status <->event visible/sctive/featured fields.
            $scope.currentEvent.calculatedData = EventsDataService.calculatedEventSubmissions(
                $scope.currentEvent
            );
            $scope.currentEvent.event.inviteLink =
                $rootScope.$platformManager.platform.url +
                '/enrollToEvent?eventCode=' +
                $scope.currentEvent.event.code;
            $scope.currentEvent.event.inviteLink += $scope.currentEvent.event.invitation_code
                ? '&invitationCode=' + $scope.currentEvent.event.invitation_code
                : '';
        };

        function goToFirstTab() {
            EventsDataService.defaultTab = EventsDataService.defaultTab || self.pages[0].link;
            $state.go(self.fromState + '.events.event.' + EventsDataService.defaultTab);
        }

        function getRows() {
            var deferred = $q.defer();
            var result = [];

            // get questions

            $q.all([
                HomeDataService.getEventHostData(
                    self.currentEvent.event.id,
                    self.userId,
                    false /* show not only submitted */
                ),
                HomeDataService.getAllSubmissionsFormQuestions(self.currentEvent.event.id, self.userId),
            ]).then(
                function (res) {
                    var event = res[0];
                    var answers = res[1].data;
                    var foundArtworks;

                    if (event) {
                        if (event.submissions) {
                            event.submissions.forEach(function (submission) {
                                foundArtworks = [];

                                if (event.artworks) {
                                    foundArtworks = _.filter(event.artworks, function (artwork) {
                                        return artwork.uploader.user_id === submission.user_id;
                                    });
                                }

                                result.push({
                                    artworks: foundArtworks,
                                    submission: submission,
                                    user: submission.user,
                                    answers: answers[submission.user.id],
                                });
                            });
                        }
                    } else {
                        deferred.reject();
                    }

                    deferred.resolve(result);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        }

        $rootScope.$on('admin_event_selectedTab', function (event, selectedTab) {
            EventsDataService.defaultTab = selectedTab;
        });

        this.init = function () {
            $scope.formValidator = FormValidationService;
            $scope.formattedToday = $filter('date')(new Date(), 'dd/MM/yyyy');
            $scope.loadingDataForReport = false;
            $scope.invitationCode = '';
            self.extendCurrentEvent();

            if ($state.current.name === self.fromState + '.events.event') {
                goToFirstTab();
            }

            // Get all terms
            EventsDataService.getAllTerms($scope.currentEvent.event.id).then(function (res) {
                $scope.currentEvent.event.terms = res;
            });

            self.loadingDataForReport = true;
            getRows()
                .then(function (rows) {
                    self.reportRows = rows;
                    // var groupedByArtworkSubmissions = _.groupBy($scope.artworks, function(artwork) {
                    //     return artwork.artwork_submission.user_id;
                    // });

                    var maxArtworks = _.max(self.reportRows, function (row) {
                        return row.artworks.length;
                    });

                    if (maxArtworks && maxArtworks.artworks) {
                        self.maxArtworksSubmitted = maxArtworks.artworks.length;
                    }
                })
                .finally(function () {
                    self.loadingDataForReport = false;
                });
        };
    },
]);
