'use strict';

angular.module('core').controller('SocialShareController', [
    '$rootScope',
    '$state',
    '$scope',
    '$stateParams',
    '$timeout',
    '$q',
    'Messages',
    'ClipboardService',
    'NIIO_URL',
    function ($rootScope, $state, $scope, $stateParams, $timeout, $q, Messages, ClipboardService, NIIO_URL) {
        var self = this;
        self.ClipboardService = ClipboardService;

        function changeCopyText() {
            if (self.copyText) {
                self.copyText = 'Copied!';
                $timeout(function () {
                    self.copyText = 'Copy';
                }, 3000);
            } else {
                self.copyText = 'Copy';
            }
        }

        function successMessage() {
            var messageParams = {};
            messageParams.message = 'The link was copied to your clipboard';
            Messages.openMessage($scope, messageParams);
        }

        $scope.$on('clickToCopy-success', function (e) {
            successMessage();
            changeCopyText();
        });

        function initSocial() {
            var textToShare = '';
            var isVideo = false;
            var media = '';
            if (self.artwork) {
                textToShare =
                    'Check out ' +
                    self.artwork.title +
                    ' by ' +
                    self.artwork.artist.display_name +
                    ' On Niio';
                // isVideo = self.artwork.file_metadata.media_type === 'video' ? 'true' : 'false';
                // media = self.artwork.preview;
                media = 'https:' + self.artwork.thumbnail_path;
            } else if (self.channel) {
                textToShare =
                    'Check out ' +
                    self.channel.name +
                    ' by ' +
                    self.channel.creator.display_name +
                    ' On Niio';
                if (self.channel.cover) {
                    media = 'https:' + self.channel.cover.path;
                }
            } else if (self.user) {
                textToShare = 'Check out ' + self.user.display_name + ' profile On Niio';
                if (self.user.profile_path) {
                    media = 'https:' + self.user.profile_path;
                }
            }

            self.textToShare = encodeURIComponent(textToShare);

            self.social = [
                {
                    name: 'Facebook',
                    link: 'https://www.facebook.com/sharer/sharer.php?u=' + self.link,
                    icon: 'fa fa-facebook-square',
                },
                {
                    name: 'Twitter',
                    link:
                        'https://twitter.com/intent/tweet?original_referer=' +
                        self.link +
                        '&text=' +
                        self.textToShare +
                        '&url=' +
                        self.link +
                        '&via=niioart&hashtags=niio,hiioart,digitalart',
                    icon: 'fa fa-twitter',
                },
                {
                    name: 'LinkedIn',
                    link: 'https://www.linkedin.com/sharing/share-offsite/?url=' + self.link,
                    icon: 'fa fa-linkedin-square',
                },
                {
                    name: 'Pinterest',
                    link:
                        'http://pinterest.com/pin/create/bookmarklet/?url=' +
                        self.link +
                        '&is_video=' +
                        isVideo +
                        '&description=' +
                        self.textToShare +
                        '&media=' +
                        media,
                    icon: 'fa fa-pinterest',
                },
                {
                    name: 'WhatsApp',
                    link: 'https://api.whatsapp.com/send?text=' + self.textToShare + ' ' + self.link,
                    action: 'share/whatsapp/shari',
                    icon: 'fa fa-whatsapp',
                },
                {
                    name: 'Mail',
                    link:
                        'mailto:?subject=' +
                        self.textToShare +
                        '&body=' +
                        self.textToShare +
                        ':%0D%0A' +
                        self.link +
                        '.',
                    icon: 'fa fa-envelope',
                },
            ];
        }
        function entityHidden(entityName) {
            var messageParams = {};
            messageParams.title = entityName + ' is Hidden';
            messageParams.message =
                entityName +
                ' is hidden. If you wish to share it in social platforms, please change the visibility to visible.';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($rootScope, messageParams).finally(function (res) {
                Messages.ok();
            });
        }

        this.init = function (params) {
            angular.extend(self, params);
            if (self.artwork) {
                if (self.channel && self.channel.url_name) {
                    self.link =
                        NIIO_URL + '/channel/' + self.channel.url_name + '?artworkId=' + self.artwork.id;
                } else {
                    self.link = NIIO_URL + '/artwork/' + self.artwork.id;
                }
            } else if (self.channel) {
                self.link = NIIO_URL + '/channel/' + self.channel.url_name;
            } else if (self.user) {
                self.link = NIIO_URL + '/member/' + self.user.url;
            }

            initSocial();
            changeCopyText();
            if (self.artwork && !self.artwork.visible) {
                entityHidden('Artwork');
            } else if (self.channel && !self.channel.visible) {
                entityHidden('Channel');
            } else if (self.user && !self.user.visible) {
                entityHidden('Member');
            }
        };

        self.openShare = function (link) {
            // e.preventDefault();
            window.open(
                link,
                'fbShareWindow',
                'height=550, width=700, top=' +
                    (window.innerHeight / 2 - 275) +
                    ', left=' +
                    (1350 + (window.innerWidth / 2 - 355)) +
                    ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0'
            );
        };
    },
]);
