'use strict';

angular.module('home').controller('HomeAddChannelController', [
    'channel',
    'HomeDataService',
    'ChannelService',
    '$scope',
    '$rootScope',
    '$filter',
    '$location',
    '$http',
    '$q',
    '$log',
    '$localStorage',
    '$state',
    'Messages',
    'Partial',
    'FormValidationService',
    'TagsService',
    '_',
    'User',
    'S3Uploader',
    'CHANNEL_TYPES',
    'CHANNEL_COMMERCIAL_RULES',
    'ENV',
    'CategoriesService',
    function (
        channel,
        HomeDataService,
        ChannelService,
        $scope,
        $rootScope,
        $filter,
        $location,
        $http,
        $q,
        $log,
        $localStorage,
        $state,
        Messages,
        Partial,
        FormValidationService,
        TagsService,
        _,
        User,
        S3Uploader,
        CHANNEL_TYPES,
        CHANNEL_COMMERCIAL_RULES,
        ENV,
        CategoriesService
    ) {
        $scope.allArtworks = {};
        $scope.checkedItems = [];
        $scope.categories = [];
        $scope.formValidator = FormValidationService;
        $scope.CHANNEL_TYPES = CHANNEL_TYPES;
        $scope.CHANNEL_COMMERCIAL_RULES = CHANNEL_COMMERCIAL_RULES;
        $scope.isAdmin = User.isAdmin();
        $scope.viewChecked = false;
        $scope.maxFileSize = 1024 * 1024; // 1MB
        $scope.supportedImageMimetypes = [
            'image/bmp',
            'image/gif',
            'image/j2k',
            'image/jp2',
            'image/jpeg',
            'image/jpf',
            'image/jpm',
            'image/jpx',
            'image/pjpeg',
            'image/png',
            'image/tiff',
            'image/tiff-fx',
            'image/webp',
        ];

        $scope.supportedImageExtensions = ['j2k'];

        $scope.genres = HomeDataService.enums.styles;
        $scope.currencies = HomeDataService.enums.currencies;
        $scope.themes = ['Transitions', 'The journey', 'In Movement', 'The Aesthetics of Resistance', 'Other'];

        $scope.commercialRules = [
            { value: 'sell', name: 'Sell' },
            { value: 'loan', name: 'Loan' },
            { value: 'sell_loan', name: 'Sell or Loan' },
            { value: 'inquire', name: 'Inquire Only' },
        ];
        $scope.channelTypes = [
            { value: CHANNEL_TYPES.ARTCAST, name: 'Artcast' },
            { value: CHANNEL_TYPES.CATALOG, name: 'Catalog' },
        ];

        $scope.curationTypes = FormValidationService.curationTypes;

        var s3UploadOptions = {};
        var opts = angular.extend({}, s3UploadOptions);
        opts = angular.extend(
            {
                submitOnChange: true,
                getOptionsUri: '/getS3Options',
                acl: 'private',
                uploadingKey: 'uploading',
                folder: '',
                enableValidation: true,
            },
            opts
        );

        $scope.search = {
            enable: false,
            text: '',
            filterRows: function (row) {
                if (!$scope.search.enable || $scope.search.text === '') {
                    return true;
                }

                var lowerCasedSearchText = $scope.search.text.toLowerCase();

                if (row.title.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }
                if (row.uploader && row.uploader.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }
                if (row.creator && row.creator.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }
                if (row.artist && row.artist.display_name.toLowerCase().indexOf(lowerCasedSearchText) !== -1) {
                    return true;
                }

                return false;
            },
        };
        $scope.toggleSearch = function () {
            $scope.search.text = '';
            if ($scope.search.enable) {
                $scope.search.text = '';
                $scope.search.enable = false;
            } else {
                $scope.search.enable = true;
            }
        };

        $scope.filterByLoanLevel = {
            filterRows: function (row) {
                if (
                    ($scope.channel.commercial_rule !== CHANNEL_COMMERCIAL_RULES.LOAN &&
                        $scope.channel.commercial_rule !== CHANNEL_COMMERCIAL_RULES.SELL_LOAN) ||
                    row.default_loan_price_level === $scope.channel.level
                ) {
                    return true;
                }
            },
        };

        function loadCollections() {
            var fetchPortfolioArtworks = Partial.func(HomeDataService.fetchPortfolioArtworks, null, channel.user_id),
                fetchConsignedArtworks = Partial.func(HomeDataService.fetchConsignedArtworks, null, channel.user_id),
                fetchPortfolioCollections = Partial.func(
                    HomeDataService.fetchPortfolioCollections,
                    null,
                    channel.user_id
                ),
                fetchVaultCollectedArtworks = Partial.func(
                    HomeDataService.fetchVaultCollectedArtworks,
                    null,
                    channel.user_id
                ),
                fetchVaultPurchasedArtworks = Partial.func(
                    HomeDataService.fetchVaultPurchasedArtworks,
                    null,
                    channel.user_id
                ),
                fetchVaultWishlistArtworks = Partial.func(
                    HomeDataService.fetchVaultWishlistArtworks,
                    null,
                    channel.user_id
                ),
                fetchVaultAcceptedArtworks = Partial.func(
                    HomeDataService.fetchVaultAcceptedArtworks,
                    null,
                    channel.user_id
                ),
                fetchVaultCollections = Partial.func(HomeDataService.fetchVaultCollections, null, channel.user_id),
                deferred = $q.defer();
            //
            $q.all([
                fetchPortfolioArtworks(),
                fetchConsignedArtworks(),
                fetchPortfolioCollections(),
                fetchVaultCollectedArtworks(),
                fetchVaultPurchasedArtworks(),
                fetchVaultWishlistArtworks(),
                fetchVaultAcceptedArtworks(),
                fetchVaultCollections(),
            ]).then(function (result) {
                $scope.artworks = [];
                $scope.rows = [];
                $scope.collections = [];
                var fetchedPortfolioArtworks = result[0].data.data.artworks;
                angular.forEach(fetchedPortfolioArtworks, function (artwork) {
                    artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                    artwork.isArtwork = true;
                    artwork.belongsTo = 'portfolio';
                    artwork.commercialRule =
                        artwork.sale_type === 'SRB' && artwork.belongsTo === 'portfolio' ? 'Sell' : '';
                    if (artwork.loan_price) {
                        artwork.commercialRule += artwork.commercialRule ? ' / Loan' : 'Loan';
                    }
                    artwork.price = artwork.price ? parseInt(artwork.price) : null;
                    artwork.loan_price = artwork.loan_price ? parseInt(artwork.loan_price) : null;
                    artwork.loan_period = artwork.loan_period ? parseInt(artwork.loan_period) : null;
                    artwork.numericFileSize =
                        artwork.file_metadata !== undefined && artwork.file_metadata.size
                            ? parseInt(artwork.file_metadata.size)
                            : 0;
                    if (artwork.file_metadata !== undefined && artwork.file_metadata.video_duration !== null) {
                        artwork.durationAsString = artwork.file_metadata.video_duration;
                        artwork.durationInSec = Date.parse(
                            'Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC'
                        );
                    } else {
                        artwork.durationAsString = '';
                        artwork.durationInSec = 0;
                    }

                    $scope.rows.push(artwork);
                });

                var fetchedConsignedArtworks = result[1].data.data.artworks;
                angular.forEach(fetchedConsignedArtworks, function (artwork) {
                    artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                    artwork.isArtwork = true;
                    artwork.belongsTo = 'portfolio';
                    artwork.commercialRule =
                        artwork.sale_type === 'SRB' && artwork.belongsTo === 'portfolio' ? 'Sell' : '';
                    if (artwork.loan_price) {
                        artwork.commercialRule += artwork.commercialRule ? ' / Loan' : 'Loan';
                    }
                    artwork.price = artwork.price ? parseInt(artwork.price) : null;
                    artwork.loan_price = artwork.loan_price ? parseInt(artwork.loan_price) : null;
                    artwork.loan_period = artwork.loan_period ? parseInt(artwork.loan_period) : null;
                    artwork.numericFileSize =
                        artwork.file_metadata !== undefined && artwork.file_metadata.size
                            ? parseInt(artwork.file_metadata.size)
                            : 0;
                    if (artwork.file_metadata !== undefined && artwork.file_metadata.video_duration !== null) {
                        artwork.durationAsString = artwork.file_metadata.video_duration;
                        artwork.durationInSec = Date.parse(
                            'Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC'
                        );
                    } else {
                        artwork.durationAsString = '';
                        artwork.durationInSec = 0;
                    }

                    $scope.rows.push(artwork);
                });

                var fetchedPortfolioCollections = result[2].data.data.collections;
                angular.forEach(fetchedPortfolioCollections, function (collection) {
                    collection.isCollection = true;
                    if (collection.artworks && collection.artworks.length > 0) {
                        collection.thumbnail_path = collection.artworks[0].thumbnail_path;
                    }
                    collection.title = collection.name;
                    collection.belongsTo = 'portfolio';
                    collection.price = collection.price || null;
                    collection.durationInSec = 0; //TODO: Calc duration
                    $scope.rows.push(collection);
                });

                var vaultCollectedArtworks = result[3].data.data.artworks;
                angular.forEach(vaultCollectedArtworks, function (artwork) {
                    artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                    artwork.isArtwork = true;
                    artwork.belongsTo = 'vault';
                    artwork.price = artwork.price ? parseInt(artwork.price) : null;
                    artwork.numericFileSize =
                        artwork.file_metadata !== undefined && artwork.file_metadata.size
                            ? parseInt(artwork.file_metadata.size)
                            : 0;
                    if (artwork.file_metadata !== undefined && artwork.file_metadata.video_duration !== null) {
                        artwork.durationAsString = artwork.file_metadata.video_duration;
                        artwork.durationInSec = Date.parse(
                            'Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC'
                        );
                    } else {
                        artwork.durationAsString = '';
                        artwork.durationInSec = 0;
                    }
                    $scope.rows.push(artwork);
                });

                var vaultPurchasedArtworks = result[4].data.data.artworks;
                angular.forEach(vaultPurchasedArtworks, function (artwork) {
                    artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                    artwork.isArtwork = true;
                    artwork.belongsTo = 'vault';
                    artwork.price = artwork.price ? parseInt(artwork.price) : null;
                    artwork.numericFileSize =
                        artwork.file_metadata !== undefined && artwork.file_metadata.size
                            ? parseInt(artwork.file_metadata.size)
                            : 0;
                    if (artwork.file_metadata !== undefined && artwork.file_metadata.video_duration !== null) {
                        artwork.durationAsString = artwork.file_metadata.video_duration;
                        artwork.durationInSec = Date.parse(
                            'Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC'
                        );
                    } else {
                        artwork.durationAsString = '';
                        artwork.durationInSec = 0;
                    }
                    $scope.rows.push(artwork);
                });

                var vaultWishlistArtworks = result[5].data.data.artworks;
                angular.forEach(vaultWishlistArtworks, function (artwork) {
                    artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                    artwork.isArtwork = true;
                    artwork.belongsTo = 'favorites';
                    artwork.price = artwork.price ? parseInt(artwork.price) : null;
                    artwork.numericFileSize =
                        artwork.file_metadata !== undefined && artwork.file_metadata.size
                            ? parseInt(artwork.file_metadata.size)
                            : 0;
                    if (artwork.file_metadata !== undefined && artwork.file_metadata.video_duration !== null) {
                        artwork.durationAsString = artwork.file_metadata.video_duration;
                        artwork.durationInSec = Date.parse(
                            'Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC'
                        );
                    } else {
                        artwork.durationAsString = '';
                        artwork.durationInSec = 0;
                    }
                    $scope.rows.push(artwork);
                });

                var vaultAcceptedArtworks = result[6].data.data.artworks;
                angular.forEach(vaultAcceptedArtworks, function (artwork) {
                    artwork.category = $filter('translate')('CORE.ARTWORK_CATEGORY_' + artwork.category);
                    artwork.isArtwork = true;
                    artwork.belongsTo = 'vault';
                    artwork.price = artwork.price ? parseInt(artwork.price) : null;
                    artwork.numericFileSize =
                        artwork.file_metadata !== undefined && artwork.file_metadata.size
                            ? parseInt(artwork.file_metadata.size)
                            : 0;
                    if (artwork.file_metadata !== undefined && artwork.file_metadata.video_duration !== null) {
                        artwork.durationAsString = artwork.file_metadata.video_duration;
                        artwork.durationInSec = Date.parse(
                            'Thu, 01 Jan 1970 ' + artwork.file_metadata.video_duration + ' UTC'
                        );
                    } else {
                        artwork.durationAsString = '';
                        artwork.durationInSec = 0;
                    }
                    $scope.rows.push(artwork);
                });

                var vaultCollections = result[7].data.data.collections;
                angular.forEach(vaultCollections, function (collection) {
                    collection.isCollection = true;
                    var numOfArtworks = 0;
                    if (collection.artworks && collection.artworks.length > 0) {
                        collection.thumbnail_path = collection.artworks[0].thumbnail_path;
                        numOfArtworks = collection.artworks.length;
                    }
                    collection.title = collection.name + ' (' + numOfArtworks + ')';
                    collection.belongsTo = 'vault';
                    collection.price = collection.price || null;
                    collection.durationInSec = 0; //TODO: Calc duration
                    $scope.rows.push(collection);
                });

                $scope.rows = _.filter($scope.rows, function (row) {
                    row.isChecked = !!row.isChecked;
                    return !row.status || row.status === 'READY';
                });

                deferred.resolve();
            });

            return deferred.promise;
        }

        function setOrderToCheckedItems() {
            var i,
                j,
                index = 1;

            for (i = 0; i < $scope.checkedItems.length; i++) {
                $scope.checkedItems[i].order = index;
                for (j = 0; j < $scope.rows; j++) {
                    if ($scope.rows[j].id === $scope.checkedItems[i].id) {
                        $scope.rows[j].order = index;
                    }
                }
                index++;
            }
        }

        function compare(a, b) {
            var comparison = 0;
            if (a.order >= b.order) {
                comparison = 1;
            } else if (a.order < b.order) {
                comparison = -1;
            }
            return comparison;
        }

        function sortCheckedItemsByOrder() {
            if ($scope.checkedItems && $scope.checkedItems.length > 1 && $scope.checkedItems[0].order) {
                $scope.checkedItems = $scope.checkedItems.sort(compare);
            }
        }

        function checkArtworks() {
            var i, j;
            $scope.checkedItems = [];

            for (i = 0; i < $scope.channel.artworks.length; i++) {
                for (j = 0; j < $scope.rows.length; j++) {
                    if ($scope.rows[j].isArtwork && $scope.rows[j].id === $scope.channel.artworks[i].id) {
                        $scope.rows[j].isChecked = true;
                        $scope.rows[j].order = parseInt($scope.channel.artworks[i].order);
                        $scope.checkedItems.push(copyOnlyNecessaryItems($scope.rows[j]));
                        break;
                    }
                }
            }

            for (i = 0; i < $scope.channel.collections.length; i++) {
                for (j = 0; j < $scope.rows.length; j++) {
                    if ($scope.rows[j].isCollection && $scope.rows[j].id === $scope.channel.collections[i].id) {
                        $scope.rows[j].isChecked = true;
                        $scope.rows[j].order = parseInt($scope.channel.collections[i].order);
                        $scope.checkedItems.push(copyOnlyNecessaryItems($scope.rows[j]));
                        break;
                    }
                }
            }

            sortCheckedItemsByOrder();
            setOrderToCheckedItems();
            $scope.allArtworksAreChecked();
        }

        $scope.init = function () {
            FormValidationService.initLoanPackages();
            $scope.submitWasClicked = false;
            $scope.channel = channel !== '' ? channel : {};
            S3Uploader.generateCognitoIdentity();
            initVisible();
            initOrientationMode();
            initMandatoryFields();
            initHidePrices();
            initViewProperties();
            initCommercialRule();
            $scope.artworksLoaded = false;
            loadCollections().then(function (res) {
                if ($scope.channel && $scope.channel.id) {
                    checkArtworks();
                }
                $scope.artworksLoaded = true;
            });

            CategoriesService.promise.then(() => {
                $scope.categories = CategoriesService.categories;
            });
        };

        $scope.loadTags = function ($query) {
            var data = {
                query: $query,
            };

            return $http.post('/tags/getTags', data).then(function (res) {
                var tags = [];
                res.data.tags.forEach(function (tag) {
                    tags.push({ text: tag.Tag.name });
                });
                return tags;
            });
        };

        $scope.onAdditionalFileChange = function (file, isCover) {
            // Validate File
            var fileIsValid = $scope.validateCoverFile(file, isCover);

            if (!fileIsValid) {
                $scope.removePreviewFile();
            }
        };

        $scope.removePreviewFile = function () {
            $scope.addChannelForm.cover.file = [];
            delete $scope.addChannelForm.cover.file;
            angular.element(document.querySelector('#coverFileUpload')).val('');
            $scope.addChannelForm.cover.fakeValidationCoverModel = undefined;
            delete $scope.addChannelForm.cover.fakeValidationCoverModel;
        };

        $scope.validateCoverFile = function (file, isCover) {
            var result = true;
            var messageParams = {};

            if (file || $scope.addChannelForm.cover.file.length === 1) {
                file = file || $scope.addChannelForm.cover.file[0];

                $scope.addChannelForm.cover.fileType = '';

                var type = file.type;
                var extension = file.name.split('.').pop().toLowerCase();

                if (file.size > $scope.maxFileSize) {
                    // 1 MB
                    messageParams = {};
                    messageParams.title = 'File Size Exceeds Upload Limit';
                    messageParams.message =
                        file.name +
                        ' File size (' +
                        $filter('bytes')(file.size) +
                        ') exceeds your allowed single upload limit.\nPlease use a reduced file size (up to ' +
                        $filter('bytes')($scope.maxFileSize) +
                        ')';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    result = false;
                    // } else if ((!isCover && isVideo(type, extension)) || (isCover && isVideoForPreview(type, extension))) {
                    //     switch (type) {
                    //         case 'video/ogg':
                    //         case 'video/mp4':
                    //         case 'video/webm':
                    //             break;
                    //         default:
                    //             type = '';
                    //     }
                    //     $scope.fileType = 'video';
                } else if (isImage(type, extension)) {
                    $scope.fileType = 'image';
                } else {
                    messageParams = {};
                    messageParams.message =
                        file.name +
                        ' File type is not supported.\nSupported file types are: gif, jpeg, jpg, png, mpg, mpeg' +
                        (isCover ? ' and mp4' : ', mp4, avi, mov, flv and mkv');
                    messageParams.message +=
                        file.name +
                        '\nTo upload these types of file format, please use the "Additional Files" section under "Edit Artwork" which allows upload of files related to the artwork, in various formats, such as PDF documents, Audio files etc.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($scope, messageParams);
                    result = false;
                }
            } else {
                result = false;
            }
            return result;
        };

        function isImage(type, extension) {
            return (
                _.indexOf($scope.supportedImageMimetypes, type) > -1 ||
                _.indexOf($scope.supportedImageExtensions, extension) > -1
            );
        }

        $scope.deleteAdditionalFile = function (additionalFile, additionalFileIndex) {
            var messageParams = {};

            messageParams.message = 'You are about to delete this file from this channel.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete File';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    var data = {
                        channelId: additionalFile.channel_id,
                        additionalFileId: additionalFile.id,
                    };

                    additionalFile.deleting = true;

                    return $http
                        .post('/additionalFiles/deleteEntityAdditionalFile', data)
                        .then(function (res) {
                            var messageParams = {};
                            messageParams.message = 'Successfully deleted the file';
                            messageParams.title = 'Delete File';
                            Messages.openMessage($scope, messageParams);
                            if (additionalFileIndex !== undefined) {
                                $scope.addChannelForm.additionalFiles.splice(additionalFileIndex, 1);
                            }
                            if (additionalFile.type_id === '2') {
                                // Thumbnail
                                $scope.channel.cover = {};
                            }
                            // if ($scope.addChannelForm.additionalFiles.length === 0 && $scope.addChannelForm.newAdditionalFiles.length === 0) {
                            //     $scope.toggles.additionalFilesIsOpen = false;
                            // }
                            $rootScope.$broadcast('add_channel_changed', true);
                        })
                        .finally(function (res) {
                            additionalFile.deleting = false;
                            additionalFile.isChanged = false;
                        });
                }
            });
        };

        $scope.removeCoverFile = function () {
            $scope.addChannelForm.cover.file = [];
            delete $scope.addChannelForm.cover.file;
            $scope.addChannelForm.fakeValidationCoverModel = undefined;
            delete $scope.addChannelForm.fakeValidationCoverModel;
        };

        function onCreateNewChannelSuccess(res) {
            var messageParams = {};

            if (res && res.data.coverS3 && $scope.addChannelForm.cover.file[0]) {
                // If new preview file, upload it
                $scope.uploadFile(
                    $scope.addChannelForm.cover.file[0],
                    res.data.coverS3.bucket,
                    res.data.coverS3.key,
                    res.data.coverS3.channelId,
                    $scope.channel,
                    /* isPreview */ true,
                    /* is additional file */ false,
                    res.data.coverS3.additionalFileId,
                    $scope.addChannelForm.cover.file[0]
                );
            }
            $rootScope.$broadcast('add_channel_changed', true);
            var channelByType = $scope.channel.type === CHANNEL_TYPES.CATALOG ? 'Catalog' : 'Artcast';
            messageParams.message =
                channelByType + ($scope.channel.id ? ' was changed successfully.' : ' was created successfully.');
            Messages.openMessage($scope, messageParams).then(function (res) {
                var type = $scope.channel.type === CHANNEL_TYPES.CATALOG ? 'Catalogs' : 'Channels';
                if (window.history && window.history.length > 2) {
                    window.history.back();
                } else {
                    $location.path('/home/channels/curated' + type);
                }
                $scope.submitWasClicked = false;
            });
        }

        function onCreateNewChannelError(error) {
            var messageParams = {};
            $rootScope.$broadcast('add_channel_changed', true);
            messageParams.message = 'An error has occurred. Please try again later.';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($scope, messageParams);
            $scope.submitWasClicked = false;
        }

        $scope.uploadFile = function (
            file,
            bucket,
            key,
            channelId,
            entityData,
            isCoverFile,
            isAdditionalFile,
            additionalFileId,
            additionalFile
        ) {
            $log.debug('HomeAddChannelController::uploadFile Uploading file', {
                file: file,
                bucket: bucket,
                key: key,
                channelId: channelId,
            });

            var deferred = $q.defer();

            var exraParams = {
                channelId: channelId,
                entityType: 'channel',
                isPreviewFile: isCoverFile,
                isAdditionalFile: isAdditionalFile,
                additionalFileId: additionalFileId,
                additionalFile: additionalFile,
                channel: entityData || $scope.addChannelForm,
            };

            S3Uploader.upload(
                'multipart',
                bucket,
                key,
                opts.acl,
                file.type,
                null,
                null,
                null,
                file,
                exraParams,
                null
            ).then(
                function (res) {
                    deferred.resolve(res);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        function collectAllCheckedRows() {
            var checkedRows = {
                checkedArtworks: [],
                checkedCollections: [],
            };

            $scope.checkedItems.forEach(function (checkedItem) {
                if (checkedItem.isChecked) {
                    if (checkedItem.isArtwork) {
                        checkedRows.checkedArtworks.push({ id: checkedItem.id, order: checkedItem.order });
                    } else if (checkedItem.isCollection) {
                        checkedRows.checkedCollections.push({ id: checkedItem.id, order: checkedItem.order });
                    }
                }
            });

            return checkedRows;
        }

        function setNewFilenameToFile(file) {
            var fileName = file ? file.name || file.filename : $scope.uploadFormObj.filename;
            file.original = fileName;
            file.filename = generateFilename(fileName);
        }

        function generateFilename(filename) {
            var selectedFileExtension = filename.split('.').pop();
            return new Date().getTime() + '-' + S3Uploader.randomString(16) + '.' + selectedFileExtension;
        }

        $scope.sendFormToServer = function () {
            sortCheckedItemsByOrder();
            setOrderToCheckedItems();
            var checkedRows = collectAllCheckedRows();
            $scope.submitWasClicked = true;
            $scope.channel.aws = checkedRows.checkedArtworks;
            $scope.channel.cols = checkedRows.checkedCollections;
            var coverFile, coverOriginalFilename, coverFilename, coverSize, coverFileType;

            if (
                $scope.addChannelForm.cover &&
                $scope.addChannelForm.cover.file &&
                $scope.addChannelForm.cover.file[0]
            ) {
                coverFile = $scope.addChannelForm.cover.file[0];
                setNewFilenameToFile(coverFile);
                coverOriginalFilename = coverFile.original;
                coverFilename = coverFile.filename || coverFile.name;
                coverSize = coverFile.size;
                coverFileType = coverFile.type;
            }

            var data = {
                channelId: $scope.channel.id, // For edit
                artworkType: $scope.channel.artworkType,
                name: $scope.channel.name,
                subtitle: $scope.channel.subtitle,
                level: $scope.channel.level,
                type: $scope.channel.type,
                password: $scope.channel.is_locked ? $scope.channel.password : null,
                curated_by: $scope.channel.curated_by,
                curation_type: $scope.channel.curation_type,
                curation_type_other: $scope.channel.curation_type_other,
                categories: $scope.channel.categories,
                genre: $scope.channel.genre,
                secondary_genre: $scope.channel.secondary_genre,
                theme: $scope.channel.theme,
                price: $scope.channel.price && $scope.channel.price > 0 ? $scope.channel.price : null,
                currency: $scope.channel.price && $scope.channel.price > 0 ? $scope.channel.currency : null,
                visible: $scope.channel.visible,
                hide_prices: $scope.channel.type === CHANNEL_TYPES.ARTCAST ? $scope.channel.hide_prices : false,
                download_to_display: $scope.channel.download_to_display,
                display_with_watermark: $scope.channel.display_with_watermark,
                commercial_rule: $scope.channel.commercial_rule,
                display_guidelines: $scope.channel?.display_guidelines
                    ? $scope.channel.display_guidelines
                    : { portrait_mode: false },
                description: $scope.channel.description,
                view_properties_json: $scope.channel.view_properties_json,
                tags: $scope.channel.tags,
                aws: $scope.channel.aws,
                cols: $scope.channel.cols,
                coverFileUpload: !!coverFile,
                coverOriginalFilename: coverOriginalFilename ? coverOriginalFilename : null,
                coverFilename: coverFilename ? coverFilename : null,
                coverSize: coverSize ? coverSize : null,
                coverFileType: coverFileType ? coverFileType : null,
                cognitoId: $localStorage.cognitoId ? $localStorage.cognitoId : null,
            };

            return $http
                .post('/channels/createNewChannel', data)
                .success(onCreateNewChannelSuccess)
                .error(onCreateNewChannelError);
        };

        $scope.typeChanged = function (event) {
            if (
                $scope.channel.id &&
                $scope.channel.type === CHANNEL_TYPES.ARTCAST &&
                (!$scope.channel.download_to_display || $scope.channel.display_with_watermark)
            ) {
                var messageParams = {};
                messageParams.message =
                    'By changing to Artcast you will not be able to control the display on player options. ';
                messageParams.message +=
                    '\nThese changes will affect currently running players.\nAre you sure you want to change type?';
                messageParams.title = 'Display Options Change';
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Change';
                messageParams.okClass = 'danger';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        initDisplayOptions();
                        initHidePrices();
                        initCommercialRule();
                    } else {
                        $scope.channel.type = CHANNEL_TYPES.CATALOG;
                        event.preventDefault();
                    }
                });
            } else {
                initDisplayOptions();
                initHidePrices();
                initCommercialRule();
            }
        };

        function copyOnlyNecessaryItems(row) {
            var newRow = {
                id: row.id,
                isChecked: row.isChecked,
                order: row.order,
                title: row.title,
                category: row.category,
                belongsTo: row.belongsTo,
                numericFileSize: row.numericFileSize,
                durationInSec: row.durationInSec,
                commercialRule: row.commercialRule,
                loan_price: row.loan_price,
                loan_currency: row.loan_currency,
                loan_period: row.loan_period,
                price: row.price,
                currency: row.currency,
                thumbnail_path: row.thumbnail_path,
                isArtwork: row.isArtwork,
                isCollection: row.isCollection,
            };

            if (row.User) newRow.User = { display_name: row.User.display_name };
            if (row.uploader) newRow.uploader = { display_name: row.uploader.display_name };
            if (row.creator) newRow.creator = { display_name: row.creator.display_name };

            return newRow;
        }

        $scope.itemToggled = function (row) {
            if (row.isChecked) {
                if (!row.order) {
                    row.order = $scope.checkedItems.length + 1;
                }
                $scope.checkedItems.push(copyOnlyNecessaryItems(row));
            } else {
                $scope.checkedItems.splice(
                    _.findIndex($scope.checkedItems, function (item) {
                        return item.id === row.id;
                    }),
                    1
                );

                var foundRow = _.find($scope.rows, function (repoRow) {
                    return repoRow.id === row.id;
                });

                if (foundRow) {
                    foundRow.isChecked = row.isChecked;
                }
            }

            sortCheckedItemsByOrder();
            $scope.allArtworksAreChecked();
            if ($scope.checkedItems.length === 0 && $scope.viewChecked) {
                $scope.toggleViewChecked();
            }
        };

        $scope.allArtworksAreChecked = function () {
            var checkedArtworksNum = 0,
                lengthOfTotalArtworks = $scope.rows.length;

            $scope.rows.forEach(function (row) {
                checkedArtworksNum += row.isChecked ? 1 : 0;
            });

            $scope.allArtworks.selectAllIsChecked = checkedArtworksNum === lengthOfTotalArtworks;
        };

        function toggleAllArtworks(check) {
            var filteredRows = $filter('filter')($scope.rows, $scope.search.filterRows);
            filteredRows.forEach(function (row) {
                row.isChecked = check ? true : false;
            });
        }

        function initOrientationMode() {
            if (!$scope.channel.display_guidelines) {
                $scope.channel.display_guidelines = {};
            }

            if (!$scope.channel.display_guidelines.portrait_mode || $scope.channel.display_guidelines.landscape_mode) {
                $scope.channel.display_guidelines.landscape_mode = true;
            }
        }

        $scope.toggleOrientationMode = function () {
            if (!$scope.channel.display_guidelines.portrait_mode && !$scope.channel.display_guidelines.landscape_mode) {
                $scope.channel.display_guidelines.landscape_mode = true;
            }
        };

        function initVisible() {
            if ($scope.channel.visible === undefined) {
                $scope.channel.visible = true;
            }
        }

        function initHidePrices() {
            if ($scope.channel.hide_prices === undefined || $scope.channel.id === undefined) {
                // Prices should be hidden by default for ArtCast, and visible for Catalog
                $scope.channel.hide_prices = $scope.channel.type === CHANNEL_TYPES.ARTCAST;
            }
        }
        function initViewProperties() {
            $scope.channel.view_properties_json = $scope.channel.view_properties_json || {};
            $scope.channel.view_properties_json.display_set_titles =
                $scope.channel.view_properties_json.display_set_titles || true;
            $scope.channel.view_properties_json.display_artworks_numbers =
                $scope.channel.view_properties_json.display_artworks_numbers || false;
            $scope.channel.view_properties_json.artwork_sorting_type =
                $scope.channel.view_properties_json.artwork_sorting_type || 'artwork_order';
        }
        function initDisplayOptions() {
            $scope.channel.download_to_display =
                $scope.channel.download_to_display && ($scope.isAdmin || $scope.channel.type === CHANNEL_TYPES.CATALOG)
                    ? $scope.channel.download_to_display
                    : true;
            $scope.channel.display_with_watermark =
                $scope.channel.display_with_watermark &&
                ($scope.isAdmin || $scope.channel.type === CHANNEL_TYPES.CATALOG)
                    ? $scope.channel.display_with_watermark
                    : false;
        }

        function initCommercialRule() {
            if ($scope.channel.type === CHANNEL_TYPES.CATALOG) {
                $scope.channel.commercial_rule = $scope.channel.commercial_rule || CHANNEL_COMMERCIAL_RULES.SELL;
            } else {
                $scope.channel.commercial_rule = undefined;
            }
        }

        function initMandatoryFields() {
            $scope.channel.price =
                $scope.channel.price && $scope.channel.price > 0 ? parseInt($scope.channel.price) : null;
            $scope.channel.currency = $scope.channel.price && $scope.channel.price > 0 ? $scope.channel.currency : null;
            $scope.channel.type = $scope.channel.type || CHANNEL_TYPES.ARTCAST;
            $scope.channel.curated_by_ph = $scope.channel.curated_by
                ? $scope.channel.curated_by
                : $scope.channel.creator && $scope.channel.creator.display_name
                ? $scope.channel.creator.display_name
                : User.getDisplayName();
            $scope.channel.is_locked =
                $scope.channel.is_locked || ($scope.channel.password !== undefined && $scope.channel.password !== null);
            $scope.channel.download_to_display =
                $scope.channel.download_to_display !== undefined ? $scope.channel.download_to_display : true;
        }

        $scope.selectAllToggle = function () {
            if ($scope.allArtworks.selectAllIsChecked) {
                toggleAllArtworks(true);
            } else {
                toggleAllArtworks(false);
            }
        };
        $scope.redirectBack = function () {
            if (window.history && window.history.length > 2) {
                window.history.back();
            } else {
                $state.go('home.channels');
            }
        };
        $scope.deleteChannel = function (channelId, index) {
            $scope.submitWasClicked = true;
            var messageParams = {};
            messageParams.message = 'Are you sure you want to delete this channel?';
            messageParams.message += '\nPlease note, you cannot undo this action.';
            messageParams.title = 'Delete Channel';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then(function (res) {
                if (res === 'ok') {
                    ChannelService.handleChannelAction('delete', channelId).then(
                        function (res) {
                            var messageParams = {};
                            messageParams.message = 'Channel was deleted successfully.';
                            Messages.openMessage($scope, messageParams).then(function (res) {
                                if (window.history && window.history.length > 2) {
                                    window.history.back();
                                } else {
                                    $location.path('/home/channels/curated');
                                }
                                $scope.submitWasClicked = false;
                            });
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'An error has occurred. Please try again later.';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                            $scope.submitWasClicked = false;
                        }
                    );
                } else {
                    $scope.submitWasClicked = false;
                }
            });
        };

        $scope.preventEnter = function (event) {
            if (event.which === 13) {
                // 13 is the key code for "Enter"
                event.preventDefault();
            }
        };

        $scope.toggleViewChecked = function () {
            setOrderToCheckedItems();
            $scope.viewChecked = !$scope.viewChecked;
            if ($scope.viewChecked) {
                $scope.savedReverseState = $scope.reverseSort;
                $scope.reverseSort = false;
            } else if ($scope.savedReverseState !== undefined) {
                $scope.reverseSort = $scope.savedReverseState;
                delete $scope.savedReverseState;
            }
        };

        function rowOrderChanged(event) {
            var fromIdx = event.source.index;
            var toIdx = event.dest.index;
            setOrderToCheckedItems();
        }

        function placeHolder(itemScope) {
            var tr = itemScope.element[0];
            var clone = tr.cloneNode(true);
            clone.id = 'drag-placeholder';
            clone.style.display = 'table-row';
            return clone;
        }

        // Handle Tags
        $scope.$watch(
            'channel.tags',
            function (newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    // Call your updateTags function here
                    TagsService.updateTags(newVal);
                }
            },
            true
        );

        // Taken from: https://gist.github.com/SebLours/224266714a2f52bfa253f03c6b518a45
        $scope.dragControlListeners = {
            orderChanged: rowOrderChanged,
            containment: '#artworks-table',
            placeholder: placeHolder,
        };

        // Grid
        $scope.orderByField = 'category';
        $scope.reverseSort = false;
    },
]);
