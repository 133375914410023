'use strict';

angular.module('core').controller('MemberController', [
    '$scope',
    '$element',
    '$attrs',
    '$log',
    '$rootScope',
    'Messages',
    'User',
    'UploadProgress',
    'S3Uploader',
    '$state',
    '$filter',
    '_',
    'OpenPopupForm',
    function (
        $scope,
        element,
        attrs,
        $log,
        $rootScope,
        Messages,
        User,
        UploadProgress,
        S3Uploader,
        $state,
        $filter,
        _,
        OpenPopupForm
    ) {
        var self = this;
        // scope: {
        //     'artwork': '=',
        //     'editEnabled': '=',
        //     'adminPanelEnabled': '=',
        //     'isInWishlist': '@',
        //     'editLink': '=',
        //     'deleteArtwork': '&',
        // },
        if (!self.member) {
            return;
        }
        this.$user = User;
        this.$state = $state;
        this.OpenPopupForm = OpenPopupForm;
        this.accounts = $scope.accounts ?? $scope.$parent.accounts;
        $scope.showActions = $scope.actionsEnabled !== undefined ? $scope.actionsEnabled : true;

        this.init = function () {
            self.showAdminPanel =
                self.adminPanelEnabled && User.hasRole([User.getAdminRoleId(), User.getProCuratorRoleId()]);
            self.hideAdminPanelDelete = User.isProCurator();
            self.member.profileImagePath = User.getOtherProfileImagePath(
                self.member.profile_path,
                self.member.id
            );
        };

        function followUser(user) {
            self.followingInProgress = true;

            User.followUser(user.id).then(
                function (res) {
                    $log.debug('HomeController::followUser Success', { userId: user.id, res: res });
                    self.followingInProgress = false;
                    user.is_following = true;
                },
                function (error) {
                    $log.debug('HomeController::followUser Failure', { userId: user.id, error: error });
                    self.followingInProgress = false;
                    var messageParams = {};
                    messageParams.title = 'Error following user';
                    messageParams.message =
                        'Failed to follow user, Please retry.\nIf the problem persists, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        }

        function unfollowUser(user) {
            self.followingInProgress = true;

            User.unfollowUser(user.id).then(
                function (res) {
                    $log.debug('HomeController::unfollowUser Success', { userId: user.id, res: res });
                    self.followingInProgress = false;
                    user.is_following = false;
                },
                function (error) {
                    $log.debug('HomeController::unfollowUser Failure', { userId: user.id, error: error });
                    self.followingInProgress = false;
                    var messageParams = {};
                    messageParams.title = 'Error unfollowing user';
                    messageParams.message =
                        'Failed to unfollow user, Please retry.\nIf the problem persists, please contact support.';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                }
            );
        }

        // Social Share
        self.openSocialPopup = function (user) {
            OpenPopupForm.openMenu('social', { user: user });
        };

        self.toggleFollow = function (user) {
            if (user.is_following) {
                unfollowUser(user);
            } else {
                followUser(user);
            }
        };
    },
]);
