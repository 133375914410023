'use strict';

angular.module('admin-area').controller('AdminAreaEventsSelectedTermsController', [
    '$rootScope',
    '$scope',
    '$timeout',
    '$sce',
    '$http',
    'AngularQuill',
    'Messages',
    'ENV',
    function ($rootScope, $scope, $timeout, $sce, $http, AngularQuill, Messages, ENV) {
        var self = this;
        this.$scope = $scope;
        this.Messages = Messages;
        this.ENV = ENV;

        this.saveTermsAsDraft = function () {
            $scope.eventCtrl.saveCurrentEventContent();
        };

        this.init = function () {
            $rootScope.$broadcast('admin_event_selectedTab', 'terms');

            self.tnc = '<h2><em>Hello</em></h2><b>Yo</b>';
            $scope.quill = new AngularQuill('#quillEditor', {
                theme: 'snow',
                placeholder: 'Please enter terms & conditions',
            });

            if (
                $scope.currentEvent.event.terms_latest_version &&
                parseInt($scope.currentEvent.event.terms_latest_version) > 0
            ) {
                $scope.currentEvent.event.terms_latest_version = parseInt(
                    $scope.currentEvent.event.terms_latest_version
                );
            } else {
                $scope.currentEvent.event.terms_latest_version = 0;
            }

            self.defaultAddressToggleOpen = false;

            // Get terms latest draft
            // self.termsLink =  $sce.trustAsResourceUrl('/Server/events/terms/' + $scope.currentEvent.event.id + '/0');
            $http.get('/events/terms/' + $scope.currentEvent.event.id + '/0').then(
                function (res) {
                    self.draft = res.data.content;
                    self.pasteTerms(self.draft);
                },
                function (err) {
                    self.draft = '';
                    self.pasteTerms(self.draft);
                }
            );

            if ($scope.currentEvent.event.terms_latest_version > 0) {
                $http
                    .get(
                        '/events/terms/' +
                            $scope.currentEvent.event.id +
                            '/' +
                            $scope.currentEvent.event.terms_latest_version
                    )
                    .then(
                        function (res) {
                            self.latestTerms = res.data;
                        },
                        function (err) {
                            self.latestTerms = '';
                        }
                    );
            }

            // $timeout(function() {
            //     var element = document.getElementsByClassName('ql-editor')[0];
            //     element.innerHTML = self.tnc;
            // }, 10);
        };

        this.pasteTerms = function (content) {
            self.draft = content;
            var element = document.getElementsByClassName('ql-editor')[0];
            element.innerHTML = content;
        };

        function getLatestTerms() {
            return document.getElementsByClassName('ql-editor')[0].innerHTML;
        }

        this.publishNewTerms = function (eventId) {
            var latestTerms = getLatestTerms();
            var data = {
                eventId,
                terms: latestTerms,
            };

            self.publishWasClicked = true;

            $http
                .post('/events/publishNewTerms', data)
                .then(
                    function (res) {
                        $scope.currentEvent.event.terms_latest_version = res.data.version;

                        var messageParams = {};
                        messageParams.message = 'Successfully published new version';
                        messageParams.title = 'Event Publish Terms';
                        Messages.openMessage($rootScope, messageParams);
                    },
                    function (err) {
                        var messageParams = {};
                        messageParams.message = 'Failed to publish new version';
                        messageParams.title = 'Event Publish Terms';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                )
                .finally(function () {
                    self.publishWasClicked = false;
                });
        };

        this.saveDraft = function (eventId) {
            var latestTerms = getLatestTerms();
            var data = {
                eventId,
                terms: latestTerms,
            };

            self.draftWasClicked = true;

            $http
                .post('/events/saveTermsDraft', data)
                .then(
                    function (res) {
                        // self.tnc = res.data.terms;
                        // $scope.currentEvent.event.latestTerm = res.data.version;

                        var messageParams = {};
                        messageParams.message = 'Successfully saved draft';
                        messageParams.title = 'Event Save Terms Draft';
                        Messages.openMessage($rootScope, messageParams);
                    },
                    function (err) {
                        var messageParams = {};
                        messageParams.message = 'Failed to save draft';
                        messageParams.title = 'Event Save Terms Draft';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                )
                .finally(function () {
                    self.draftWasClicked = false;
                });
        };
    },
]);
