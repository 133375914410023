'use strict';

angular.module('core').directive('mobileBanner', function () {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'modules/core/views/mobile-banner.client.view.html',
        controller: 'MobileBannerController',
    };
});
