'use strict';

angular.module('core').controller('ChannelController', [
    '$scope',
    '$element',
    '$attrs',
    '$log',
    'ChannelService',
    'Messages',
    'OpenPopupForm',
    'User',
    'HomeDataService',
    function ($scope, element, attrs, $log, ChannelService, Messages, OpenPopupForm, User, HomeDataService) {
        var self = this;
        this.ChannelService = ChannelService;
        this.OpenPopupForm = OpenPopupForm;

        if (!self.channel) {
            return;
        }

        this.init = function () {
            self.userId = User.getId();
            self.showAdminPanel =
                self.adminPanelEnabled && User.hasRole([User.getAdminRoleId(), User.getProCuratorRoleId()]);
            self.hideAdminPanelDelete = User.isProCurator();
            self.channel.profileImagePath = User.getOtherProfileImagePath(
                self.channel.creator.profile_path,
                self.channel.creator.user_id
            );
            ChannelService.handleChannelAction('getArtworksPathsForThumbnail', self.channel);
        };
        this.getCommercialRule = function (rule, isDiscover) {
            if (isDiscover) {
                if (rule === 'sell_loan') {
                    return 'buy_borrow';
                } else if (rule === 'loan') {
                    return 'borrow';
                } else if (rule === 'sell') {
                    return 'buy';
                }
            }
            return rule;
        };
        this.deleteChannel = function (channelId, index) {
            self.submitWasClicked = true;
            var messageParams = {};
            messageParams.message = 'Are you sure you want to delete this channel?';
            messageParams.message += '\nPlease note, you cannot undo this action.';
            messageParams.title = 'Delete Channel';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($scope, messageParams).then((res) => {
                if (res === 'ok') {
                    ChannelService.handleChannelAction('delete', channelId).then(
                        (res) => {
                            $log.debug(
                                'HomeCollectionsMainController::deleteChannel channel was deleted',
                                res
                            );
                            var messageParams = {};
                            messageParams.message = 'Channel was deleted successfully.';
                            Messages.openMessage($scope, messageParams);
                            const displayChannels = $scope.displayChannels ?? $scope.$parent.displayChannels;
                            displayChannels.splice(index, 1);
                            HomeDataService.resetCachedChannels();
                        },
                        (error) => {
                            $log.debug(
                                'HomeCollectionsMainController::deleteChannel channel was failed to delete',
                                error
                            );
                            var messageParams = {};
                            messageParams.message = 'An error has occurred. Please try again later.';
                            messageParams.title = 'Delete Channel';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };
    },
]);
