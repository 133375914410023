/* global NiioPlayer, angular */

angular.module('artwork-info').controller('ArtworkInfoArtworkController', [
    'ArtworkInfoService',
    'ArtworkService',
    'OpenPopupForm',
    '$log',
    '$window',
    '$scope',
    '$location',
    '$rootScope',
    '$state',
    '$stateParams',
    '$sce',
    '$http',
    '$q',
    '_',
    'Messages',
    'smoothScroll',
    'User',
    'Partial',
    'ENTITY_ACTIONS',
    '$filter',
    '$timeout',
    'CHANNEL_COMMERCIAL_RULES',
    'EVENT_TYPES',
    'NIIO_URL',
    'DetectMobileBrowser',
    'FavoritesService',
    'mobileBannerService',
    function (
        ArtworkInfoService,
        ArtworkService,
        OpenPopupForm,
        $log,
        $window,
        $scope,
        $location,
        $rootScope,
        $state,
        $stateParams,
        $sce,
        $http,
        $q,
        _,
        Messages,
        smoothScroll,
        User,
        Partial,
        ENTITY_ACTIONS,
        $filter,
        $timeout,
        CHANNEL_COMMERCIAL_RULES,
        EVENT_TYPES,
        NIIO_URL,
        DetectMobileBrowser,
        FavoritesService,
        mobileBannerService
    ) {
        var self = this;
        $rootScope.playersCounter++;
        this.playersId = $rootScope.playersCounter;
        var videoId = `video${this.playersId}`;
        this.nothingLoadedYet = true;
        this.User = User;
        this.$sce = $sce;
        this.isPlaying = true;
        this.initialPlay = false;
        this.ArtworkService = ArtworkService;
        this.ArtworkInfoService = ArtworkInfoService;
        this.OpenPopupForm = OpenPopupForm;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.loadingSharedActions = true;
        this.event = ($scope.paramToController && $scope.paramToController.event) || undefined;
        this.eventId = ($scope.paramToController && $scope.paramToController.eventId) || undefined;
        this.isEventOwner = ($scope.paramToController && $scope.paramToController.isEventOwner) || undefined;
        this.homeOrUrl = $stateParams.url || 'home';
        this.viewingNow = '';
        this.viewingNowExtension = '';
        this.viewingNowSource = '';
        this.profile = User.getUserDetailsWithBind();
        this.discoveryPeriodAndPrice = {
            active: false,
            periodAndPrice: '',
        };
        this.from = this.$state.current.data && this.$state.current.data.from ? this.$state.current.data.from : '';
        // this.isDiscovery = this.from === 'discovery' || this.from === 'discovery-catalogs' || this.from === 'discovery-artcasts'|| this.from === 'discovery-invitation';
        this.isDiscovery = true;
        this.metaDescription = '';
        this.isMobile = DetectMobileBrowser.isAny;
        this.showMobilePopup = false;
        this.closeMobilePopup = function () {
            self.showMobilePopup = false;
        };
        this.favorites = FavoritesService.favorites;
        this.showAdminPanel = User.hasRole([User.getAdminRoleId(), User.getProCuratorRoleId()]);
        this.hideAdminPanelDelete = User.isProCurator();

        if ($rootScope.fromPlayer) {
            self.qrLink = `${$rootScope.$platformManager.platform.url}/artwork/${$stateParams.artworkId}`;
        }

        this.videoArtworkTtl = {
            count: 0,
            limit: 5,
            first: undefined,
            intervalSec: 26, // 5 times in 25 sec.(Retry requests are sent every 5 sec)
            toString() {
                return `${this.count}/${this.limit} first:${this.first} interval: ${this._intervalTime()}`;
            },
            clear() {
                this.count = 0;
                this.first = undefined;
            },
            _intervalTime() {
                var cur = new Date();
                if (this.first === undefined) {
                    return -1;
                }
                return (cur - this.first) / 1000; // Sec
            },
            add() {
                if (this._intervalTime() > this.intervalSec) {
                    this.clear();
                }
                if (this.first === undefined) {
                    this.first = new Date();
                }
                this.count++;
            },
            isEnded() {
                return this.count !== undefined && this.count >= this.limit;
            },
        };

        this.videoSourceIdx = 0;
        $scope.videoError = { fatal: false, fallbackSrc: false };
        this.onVideoError = function () {
            // TODO: Fallback to mp4.
            self.videoSourceIdx++;
            self.videoArtwork.player_.tech_.pause();

            // PATCH!!!!
            if (
                self.videoArtwork.player_.tech_ &&
                self.videoArtwork.player_.tech_.hls &&
                self.videoArtwork.player_.tech_.hls.playlists.state === 'HAVE_METADATA'
            ) {
                self.videoArtwork.player_.tech_.hls.playlists.state = '';
                $scope.videoError.fallbackSrc = true;
            }

            self.videoArtwork.player_.tech_.reset();
            self.videoArtwork.player_.pause();
            if (self.videoSourceIdx < self.artwork.preview_allSrcs.length) {
                var newSrc = {
                    src: self.artwork.preview_allSrcs[self.videoSourceIdx].src,
                    type: self.artwork.preview_allSrcs[self.videoSourceIdx].type,
                };
                self.videoArtwork.player_.src(newSrc);
                self.videoArtwork.player_.reset();
                self.videoArtwork.player_.play();
            } else {
                $scope.$apply(() => {
                    $scope.videoError.fatal = true;
                });
                self.videoArtwork.player_.dispose();
            }
        };

        function onPlayerReady() {
            // Disable spinner when looping: https://github.com/videojs/video.js/issues/2677#event-428241177
            self.videoArtwork.on('seeking', (e) => {
                if (self.videoArtwork.currentTime() === 0) {
                    self.videoArtwork.addClass('vjs-looping');
                }
            });
            self.videoArtwork.on('playing', (e) => {
                if (self.videoArtwork.currentTime() === 0) {
                    self.videoArtwork.removeClass('vjs-looping');
                }
            });
            self.videoArtwork.on('pause', () => {
                $timeout(() => {
                    self.isPlaying = false;
                }, 0);
            });

            self.videoArtwork.on('play', () => {
                $timeout(() => {
                    self.isPlaying = true;
                    self.initialPlay = true;
                }, 0);
            });

            self.videoArtworkTtl.clear();

            // JUNO-148: fallback on hls fatal error.
            self.videoArtwork.player_.tech_.on('blacklistplaylist', (e) => {
                console.log('blacklistplaylist', e);
                self.onVideoError();
            });

            self.videoArtwork.player_.tech_.on('retryplaylist', (e) => {
                console.log('retryplaylist', self.videoArtworkTtl.toString());
                self.videoArtworkTtl.add();
                if (self.videoArtworkTtl.isEnded()) {
                    self.onVideoError();
                }
            });

            self.videoArtwork.player_.on('error', (e) => {
                console.log('player.error', e);
                self.onVideoError();
            });

            $rootScope.$broadcast('ArtworkInfoArtworkController::finished_loading');
        }

        function loadVideo(src, type) {
            var placeholder = document.getElementById(`mediaPlaceholder${self.playersId}`);
            // placeholder.setAttribute('ng-click', 'vm.toggleControls();');
            // placeholder.setAttribute('data-tap-disabled', 'true');

            self.videoSourceIdx = 0;
            $scope.videoError = { fatal: false };

            var player = document.createElement('video');
            player.setAttribute('id', videoId);
            player.setAttribute('class', 'IIV video-js vjs-default-skin');
            player.setAttribute('preload', 'auto');
            if ($stateParams.autoPlay) {
                player.setAttribute('autoplay', '');
            }
            if (self.artwork.file_metadata.width) {
                player.setAttribute('width', self.artwork.file_metadata.width);
            }
            if (self.artwork.file_metadata.height) {
                player.setAttribute('height', self.artwork.file_metadata.height);
            }
            player.setAttribute('data-setup', '{ "aspectRatio":"16:9"}');
            // player.setAttribute('data-setup', '{"nativeControlsForTouch": false}');
            player.setAttribute('loop', '');
            player.setAttribute('webkit-playsinline', '');
            // TODO RICH: media type should be specific, INTERACTIVE_ZIP, and 'https:' should be added from server
            var prefix = self.artwork.thumbnail_path.indexOf('https:') === 0 ? '' : 'https:';
            player.setAttribute(
                'poster',
                (self.artwork.file_metadata.media_type !== null ? prefix : '') + self.artwork.thumbnail_path
            );

            // Setup the media
            var media = document.createElement('source');
            media.setAttribute('id', 'videoArtworkSource');
            media.setAttribute('src', src);
            media.setAttribute('type', type);
            if (type === 'application/x-mpegURL') {
                // HLS
                media.setAttribute('withCredentials', true);
            }
            player.appendChild(media);

            placeholder.appendChild(player);

            $window
                .videojs(videoId, {
                    // 'example_option': true,
                    // 'customControlsOnMobile': false,
                    // 'children': {
                    //    'loadingSpinner': false
                    // },
                    nativeControlsForTouch: true,
                    controls: true,
                    // 'children':
                    controlBar: {
                        remainingTimeDisplay: false,
                    },
                    html5: {
                        hls: {
                            withCredentials: true,
                        },
                    },

                    // plugins: {
                    //    chromecast: {
                    //        appId: undefined,
                    //        metadata: {
                    //            title: "Title",
                    //            subtitle: "Subtitle"
                    //        }
                    //    }
                    // }
                })
                .ready(function () {
                    self.videoArtwork = this;
                    if (type === 'application/x-mpegURL') {
                        try {
                            // HLS
                            this?.hlsQualitySelector?.();
                        } catch (e) {
                            console.log('Error on tried to torn on HLS quality selector: ', e, videojs);
                        }
                    }
                    onPlayerReady();
                });
        }

        // TODO: Duplicated. Move to Utils.
        this.isImage = function (src) {
            var deferred = $q.defer();

            var image = new Image();
            image.onerror = function () {
                deferred.resolve(false);
            };
            image.onload = function () {
                deferred.resolve(true);
            };
            image.src = src;

            return deferred.promise;
        };

        // Taken from: http://stackoverflow.com/questions/14843815/recursive-deep-extend-assign-in-underscore-js
        function deep(a, b) {
            return _.isObject(a) && _.isObject(b) ? _.extend(a, b, deep) : b;
        }

        function loadArtwork() {
            self.nothingLoadedYet = true;

            var deferred = $q.defer();

            var promiseArtworkLoader = null;
            // Note (patch): We can start the controller with a cached artwork. In that case we pass it in the parent scope and use ng-include.
            // TODO - This patch doesn't work - additional files are missing
            // if($scope.paramToController && $scope.paramToController.artworks){
            //     var dfArtworkLoader = $q.defer();
            //     var currentArtworkIndex = $scope.paramToController.currentArtworkIndex || 0;
            //     dfArtworkLoader.resolve($scope.paramToController.artworks[currentArtworkIndex]);
            //     promiseArtworkLoader = dfArtworkLoader.promise;
            // } else {
            promiseArtworkLoader = ArtworkInfoService.getArtwork(
                $stateParams.artworkId,
                '',
                $stateParams.share,
                ArtworkInfoService.artworkSharedPassword,
                $stateParams.channelId,
                $stateParams.collectionId,
                self.eventId
            );
            // }

            // if (User.isAuthenticated()) {
            getSharedActions().then(() => {
                promiseArtworkLoader.then(
                    (value) => {
                        var loadedArtwork = {};
                        // For the sake of having all the configurations and assumptions of the caller's own keys of artwork
                        if ($scope.paramToController && $scope.paramToController.artworks) {
                            var currentArtworkIndex = $scope.paramToController.currentArtworkIndex || 0;
                            loadedArtwork = $scope.paramToController.artworks[currentArtworkIndex] || {};
                        }
                        self.artwork = _.extend(loadedArtwork, value);
                        self.artwork.description = $sce.trustAsHtml(
                            self.artwork.description?.replaceAll?.('\n', '<br/>')?.replaceAll?.('  ', ' &nbsp;') || ''
                        );
                        if (self.artwork.preview_file && self.artwork.preview_file.id) {
                            if (
                                Array.isArray(self.artwork.preview_file.properties_json) &&
                                self.artwork.preview_file.properties_json.length === 0
                            ) {
                                self.artwork.preview_file.properties_json = null;
                            }
                        }

                        if (self.artwork.cover_file && self.artwork.cover_file.id) {
                            if (
                                Array.isArray(self.artwork.cover_file.properties_json) &&
                                self.artwork.cover_file.properties_json.length === 0
                            ) {
                                self.artwork.cover_file.properties_json = null;
                            }
                        }
                        self.artwork.is_inquire = ArtworkService.isInquire(self.artwork, $stateParams.channel);
                        self.artwork.cant_loaned_in_catalog = ArtworkService.canNotLoanedInCatalog(
                            self.artwork,
                            $stateParams.channel
                        );
                        self.isPurchased = self.artwork.is_purchased;
                        self.isOwner = self.artwork.user_id === User.getId();
                        self.isAdmin = User.isAdmin();
                        self.displayWishlist =
                            ((!self.isOwner && !self.isPurchased && !self.artwork.is_consignee) ||
                                self.artwork.in_wishlist) &&
                            $stateParams.share === undefined;
                        if (self.artwork.entity_type === 'nft') {
                            self.artwork.nft_metadata = self.artwork.nft_metadata?.data;
                        }

                        self.artwork.isHidden = !self.artwork.visible && self.isOwner;
                        self.artwork.isHiddenByCreator = !self.artwork.visible && !self.isOwner;
                        self.artwork.isHiddenByAccount = self.artwork.visible && self.isOwner && !User.isVisible();

                        self.vaultOrPortfolio = self.artwork.uploader_type === 'COLLECTOR' ? 'vault' : 'portfolio';
                        self.currentState =
                            $state.current.data && $state.current.data.state
                                ? $state.current.data.state
                                : self.vaultOrPortfolio;

                        // TODO RICH: media type should be specific, INTERACTIVE_ZIP
                        if (self.artwork.file_metadata.media_type === null) {
                            if (
                                !self.artwork.preview_file ||
                                (self.artwork.preview_file.length !== undefined &&
                                    self.artwork.preview_file.length === 0)
                            ) {
                                if (!self.artwork.thumbnail_path) {
                                    // In case of no preview but there is a cover
                                    self.artwork.thumbnail_path = 'modules/artwork-info/img/ZipMediaBig.png';
                                }
                            }
                        }

                        self.artwork.expiration_message = undefined;
                        if (self.artwork.is_loaned || self.artwork.is_rented) {
                            var title = self.artwork.is_loaned ? 'Loan period ends at: ' : 'Rental period ends at: ';
                            var till = '';
                            till = $filter('mysqlDateToISOString')(self.artwork.expiration);
                            till = $filter('date')(till, 'dd/MM/yyyy');
                            self.artwork.expiration_message = {
                                title,
                                date: till,
                            };
                        }

                        if (self.artwork.artwork_submission !== undefined) {
                            self.latestRating.init(self.artwork.artwork_submission.rating);
                        }

                        // ArtworkService.handleArtworkAction('getPreviewPathWithExtension', self.artwork, {preview: self.artwork.preview, mediaType: self.artwork.file_metadata.media_type})
                        //    .then(function (res) {
                        self.nothingLoadedYet = false;
                        self.artwork.in_wishlist = self.artwork.in_wishlist || self.favorites.artworks[self.artwork.id];
                        self.actions = ArtworkService.initActions(self.artwork);
                        if ($stateParams.share && !$stateParams.channelId) {
                            self.sharedActions.forEach((action) => {
                                // action.toggleAction = 'toggle' + $filter('upperFirstLetter')(action.name) + 'Artwork';
                                ArtworkService.setActionActiveState(action, self.artwork);
                                ArtworkService.setActionNames(action, self.artwork);
                            });
                        }
                        self.loadingSharedActions = false;
                        setActionButtons();
                        sortActionButtons();
                        self.partOfLoanChannel =
                            $stateParams.channel &&
                            $stateParams.channel.commercial_rule === CHANNEL_COMMERCIAL_RULES.LOAN &&
                            $stateParams.channel.commercial_rule === CHANNEL_COMMERCIAL_RULES.SELL_LOAN;
                        self.showPrice =
                            (!self.partOfLoanChannel &&
                                !$stateParams.channelHidePrices &&
                                !self.isPurchased &&
                                (self.artwork.price ||
                                    self.artwork.sale_type === 'FRE' ||
                                    self.artwork.sale_type === 'FCC')) ||
                            self.isAdmin;

                        if (self.artwork.educational_path) {
                            self.artwork.educational_path = $sce.trustAsResourceUrl(
                                `https:${self.artwork.educational_path}`
                            );
                        }

                        self.metaDescription = ArtworkService.addArtworkMetadataDescription(self.artwork);
                        self.toggleBetweenOriginalAndPreview();

                        self.artwork.artworkCategory = $filter('translate')(
                            'CORE.ARTWORK_CATEGORY_' + self.artwork.category
                        );
                        deferred.resolve();

                        self.artwork.title = self.profile.show_ext
                            ? self.artwork.title
                            : self.artwork.title.replace(/\.[^/.]{3,4}$/, '');

                        if (self.artwork.editions.length > 0) {
                            // Prepare artwork certificates
                            ArtworkInfoService.loadCertificateAssets(self.artwork);
                        }
                    },
                    (reason) => {
                        $log.debug('artwork-info.artworkFromPlayer::', {
                            state: $state,
                            stateParams: $stateParams,
                            reason,
                        });
                        // On 401 the auth interceptor will catch it, the state will be saved for after login

                        if (reason && reason.status && reason.status !== 401 && !$rootScope.fromPlayer) {
                            var messageParams = {};
                            messageParams.message = 'The artwork is hidden. Please contact artist or gallery';
                            // messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams);
                            $state.go('home');
                        }

                        deferred.reject(reason);
                    }
                );
            });

            return deferred.promise;
        }

        function isPasswordRequired(sharedLink, channelId) {
            if (sharedLink && !channelId) {
                var data = {
                    publishUniqueSeq: sharedLink,
                };

                return $http.post('/transfer/isPasswordRequiredForPublish', data);
            }

            var deferred = $q.defer();
            var result = {
                data: {
                    isPasswordRequired: false,
                },
            };
            deferred.resolve(result);
            return deferred.promise;
        }

        function openPasswordPopup() {
            var messageParams = {};

            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.blur = true;
            messageParams.cancelFunction = function () {
                $state.go('home-page');
            };
            messageParams.okFunction = loadArtwork;
            messageParams.template = 'modules/artwork-info/views/artwork-info.artwork-password.client.view.html';
            Messages.openMessage($rootScope, messageParams);
        }

        function toggleArtwork(action) {
            var prevAddedArtwork = self.artwork.is_added;
            ArtworkService.handleArtworkAction('toggleArtwork', self.artwork).then((res) => {
                if (prevAddedArtwork !== self.artwork.is_added) {
                    action.active = !action.active;
                    if (!self.artwork.is_added) {
                        var messageParams = {};
                        messageParams.message = 'Artwork was removed from your vault';
                        Messages.openMessage($rootScope, messageParams);
                        $rootScope.$emit('artwork_deleted', self.artwork.id);
                    }
                }
            });
        }

        function toggleWishlist(action, type = 'artwork') {
            if (!User.exists()) {
                $log.debug('ArtworkService::handleArtworkAction action not allowed', {
                    action: 'toggleWishlist',
                });
                $rootScope.$broadcast('Auth:Failed', {
                    service: 'artwork',
                    actionName: 'toggleWishlist',
                    artwork: self.artwork,
                });

                $rootScope.$on('Auth:Succeed', () => {
                    $timeout(() => {
                        action.active = self.favorites.artworks[self.artwork.id];
                    }, 1000);
                });
            } else {
                FavoritesService.toggleToFavorites(type, [self.artwork.id]);
                action.active = self.favorites.artworks[self.artwork.id];
            }
        }

        $scope.$on('ArtworkService::artwork_toggled_wishlist', (event, artworkId, inWishlist) => {
            if (self.artwork.id !== artworkId) {
                return;
            }

            var foundActionButton = _.find(self.actionButtons, (actionButton) => {
                return actionButton.id === '11';
            });

            if (!foundActionButton) {
                return;
            }

            foundActionButton.active = inWishlist ? 1 : 0;
            var messageParams = {};
            if (self.artwork.in_wishlist) {
                messageParams.message = 'Artwork was added to your favorites';
                Messages.openMessage($rootScope, messageParams);
            } else {
                messageParams.message = 'Artwork was removed from your favorites';
                Messages.openMessage($rootScope, messageParams);
                $rootScope.$emit('artwork_deleted', self.artwork.id, '-1' /* wishlist */);
            }
        });

        function toggleEventShortlist(action) {
            ArtworkService.handleArtworkAction('approve', self.artwork, { eventId: self.eventId }).then((res) => {
                if (res.new_value !== undefined) {
                    self.artwork.artwork_submission.approved = res.new_value === true || res.new_value === 1;
                    action.active = !action.active;
                }
            });
        }

        self.openMobilePopup = (action) => {
            $rootScope.$broadcast('openMobilePopup', { action });
        };

        function toggleEventComments(action) {
            Partial.func(OpenPopupForm.openMenu, 'artworkInfoEventJudgeComments', {
                artwork: self.artwork,
                eventId: self.eventId,
            });
            self.commentsAction = action;
            OpenPopupForm.openMenu('artworkInfoEventJudgeComments', {
                artwork: self.artwork,
                eventId: self.eventId,
            });
        }

        $scope.$on('event_submission_comments_changed', ($event, artworkId, newValue, eventId) => {
            if (self.eventId === eventId && self.artwork.id === artworkId && self.commentsAction) {
                self.commentsAction.active = self.artwork.artwork_submission.judge_comments.comments;
            }
        });

        function setLoanAction(actionButton, period, price, currency, periodType) {
            var periodTypeString = 'month';
            var periodTypeShortStr = 'month';
            switch (periodType) {
                case 'M':
                    {
                        periodTypeString = 'month';
                        periodTypeShortStr = 'mo';
                    }
                    break;
                case 'W':
                    {
                        periodTypeString = 'week';
                        periodTypeShortStr = 'wk';
                    }
                    break;
                case 'D':
                    {
                        periodTypeString = 'day';
                        periodTypeShortStr = 'd';
                    }
                    break;
            }
            var periodAndPrice = `${period} ${periodTypeString}${period > 1 ? 's' : ''}`;
            if (price && actionButton.name === 'rent') {
                // {{ currentEvent.event.submission_fee ? ' (' + (currentEvent.event.submission_fee | currency: (currentEvent.event.submission_fee_currency | currencySymbol):0) + ')' : '' }}
                periodAndPrice += ` X ${$filter('currency')(price, $filter('currencySymbol')(currency, 0), 0)}`;
                periodAndPrice += `/${periodTypeShortStr}`;
            }

            if (actionButton.name === 'rent' && self.artwork.BusinessRestrictions.maxLoans === 0) {
                actionButton.popover = 'No borrow editions left';
            }

            actionButton.totalPrice = $filter('currency')(price * period, $filter('currencySymbol')(currency, 0), 0);
            // actionButton.addName += periodAndPrice;
            // actionButton.removeName += periodAndPrice;
            actionButton.periodAndPrice = periodAndPrice;
            self.discoveryPeriodAndPrice.periodAndPrice = `${actionButton.totalPrice} / ${
                period === '1' ? '' : period
            } ${periodTypeShortStr}`;
            if (self.artwork.editions && self.artwork.editions.length > 0) {
                actionButton.isDisabled = true;
                self.discoveryPeriodAndPrice.active = false;
                var messageParams = {};
                messageParams.message =
                    'Please note, you already own an edition of this artwork, therefore Renting / Loaning is disabled';
                messageParams.title = 'Rent / Loan Artwork';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
            }
            actionButton.isDisabled =
                self.artwork.BusinessRestrictions !== undefined &&
                self.artwork.BusinessRestrictions.maxLoans !== undefined &&
                self.artwork.BusinessRestrictions.maxLoans === 0;
            self.discoveryPeriodAndPrice.active = actionButton.isDisabled;
        }

        function setBuyAction(actionButton, specificEdition, price, currency) {
            if (specificEdition) {
                actionButton.addName += ` Edition #${specificEdition} / ${self.artwork.total_copies}`;
                actionButton.removeName += ` Edition #${specificEdition} / ${self.artwork.total_copies}`;
            }
            if (price && currency) {
                self.artwork.price = price;
                self.artwork.currency = currency;
                self.artwork.sale_type = 'SRB';
            }
        }

        function setActionButtons() {
            if (!self.loadingSharedActions) {
                self.actionButtons = [];
                self.additionalActions = [];

                if (self.sharedActions && !self.isOwner) {
                    self.actionButtons = _.uniq(angular.copy(self.sharedActions), (action, key) => {
                        return action.id;
                    });
                    self.actionButtons.forEach((actionButton) => {
                        actionButton.action = Partial.func(self.handleAction, actionButton);
                        actionButton.display = true;
                        actionButton.isSharedAction = true;
                        var parametersJson = JSON.parse(actionButton.parameters);

                        if (
                            (actionButton.name === 'buy' || actionButton.name === 'transferOwnership') &&
                            parametersJson
                        ) {
                            if ($state.includes('home.vault') && ArtworkService.userOwnsAnEdition(self.artwork)) {
                                // Do NOT display Buy button in user's vault if he already owns it.
                                var emptyBlock = !false;
                            } else {
                                setBuyAction(
                                    actionButton,
                                    parametersJson.specificEdition,
                                    parametersJson.price,
                                    parametersJson.currency
                                );
                            }
                        }

                        if (
                            ['rent', 'loan'].indexOf(actionButton.name) >= 0 &&
                            parametersJson &&
                            parametersJson.periodInMonths
                        ) {
                            parametersJson.periodType = parametersJson.periodType || 'M';
                            setLoanAction(
                                actionButton,
                                parametersJson.periodInMonths,
                                parametersJson.price,
                                parametersJson.currency,
                                parametersJson.periodType
                            );
                        }
                    });
                } else {
                    if (self.actions) {
                        self.actionButtons = _.uniq(angular.copy(self.actions), (action, key) => {
                            return action.id;
                        });
                        self.actionButtons.forEach((actionButton) => {
                            actionButton.action = Partial.func(self.handleAction, actionButton);

                            actionButton.isSharedAction = ['share', 'loan', 'rent'].indexOf(actionButton.name) >= 0;

                            // Handle default loan for channels
                            var parametersJson;
                            // var parametersJson = JSON.parse(actionButton.parameters);
                            if (
                                actionButton.name.indexOf('rent') >= 0 &&
                                $stateParams.channel &&
                                ($stateParams.channel.commercial_rule === CHANNEL_COMMERCIAL_RULES.LOAN ||
                                    $stateParams.channel.commercial_rule === CHANNEL_COMMERCIAL_RULES.SELL_LOAN)
                            ) {
                                if (
                                    !self.artwork.cant_loaned_in_catalog &&
                                    self.artwork.default_preset_actions &&
                                    self.artwork.default_preset_actions.length > 0
                                ) {
                                    var defaultLoanPreset = _.find(self.artwork.default_preset_actions, (action) => {
                                        return action.id === ENTITY_ACTIONS.RENT;
                                    });
                                    if (defaultLoanPreset) {
                                        actionButton.display = true;
                                        actionButton.parameters = defaultLoanPreset.parameters;
                                        parametersJson = JSON.parse(actionButton.parameters);
                                        parametersJson.periodType = parametersJson.periodType || 'M';
                                        setLoanAction(
                                            actionButton,
                                            parametersJson.periodInMonths,
                                            parametersJson.price,
                                            parametersJson.currency,
                                            parametersJson.periodType
                                        );
                                    }
                                }
                            }
                            if (
                                (actionButton.name === 'buy' || actionButton.name === 'transferOwnership') &&
                                $state.includes('home.vault') &&
                                ArtworkService.userOwnsAnEdition(self.artwork)
                            ) {
                                // Do NOT display Buy button in user's vault if he already owns it.
                                actionButton.active = false;
                                actionButton.display = false;
                                actionButton.shouldShow = false;
                            }

                            if (actionButton.name === 'download' && (self.isOwner || self.isAdmin)) {
                                actionButton.active = true;
                                actionButton.display = true;
                                actionButton.shouldShow = true;
                            }
                        });
                    }

                    if (self.eventId) {
                        // TODO: Remove event
                        // Shortlist
                        if (self.isEventOwner) {
                            var shortlistButton = {
                                id: '17',
                                name: 'events-shortlist',
                                display: true,
                                active: self.artwork.artwork_submission && self.artwork.artwork_submission.approved,
                                removeName: 'UNLIST',
                                addName: 'ADD TO SHORTLIST',
                                removeIcon: 'modules/core/img/check_btn.svg',
                                addIcon: 'modules/core/img/trash.svg',
                                addedClass: 'btn-primary',
                            };
                            shortlistButton.action = Partial.func(toggleEventShortlist, shortlistButton);
                            self.actionButtons.push(shortlistButton);
                            // self.actionButtons.push({action: Partial.func(toggleEventShortlist, self.artwork), display: true, active: true, removeName: 'UNLIST', addName: 'ADD TO SHORTLIST', addedClass: 'btn-primary'});
                        }

                        // Accept / Discard by host
                        var acceptedAction;

                        if (self.event && self.event.event && self.event.event.type === EVENT_TYPES.OPEN_CALL) {
                            acceptedAction = _.find(self.actionButtons, (actionButton) => {
                                return actionButton.name === 'share';
                            });
                            if (acceptedAction) {
                                acceptedAction.id = '18';
                                acceptedAction.name = 'events-finalist';
                                acceptedAction.display = true;
                                acceptedAction.addName = 'ADD TO FINALIST';
                            }
                        } else if (
                            self.event &&
                            self.event.event &&
                            self.event.event.type === EVENT_TYPES.CONSIGN_SUBMISSION
                        ) {
                            acceptedAction = _.find(self.actionButtons, (actionButton) => {
                                return actionButton.name === 'consign';
                            });
                            if (acceptedAction) {
                                acceptedAction.id = '18';
                                acceptedAction.name = 'events-finalist';
                                acceptedAction.display = true;
                                acceptedAction.addName = 'ACCEPT';
                                acceptedAction.removeName = 'DISCARD';
                            }
                        }

                        // self.actionButtons.push({action: Partial.func(self.handleAction, 'share', self.artwork, {eventId: self.eventId}), display: true, active: true, removeName: 'DISCARD', addName: 'ACCEPT TO VAULT', addedClass: 'btn-primary'});

                        // Download
                        if (self.event && self.event.event && self.event.event.allow_download) {
                            var downloadAction = _.find(self.actionButtons, (actionButton) => {
                                return actionButton.name === 'download';
                            });
                            if (downloadAction) {
                                downloadAction.display = true;
                            }
                        }

                        // Comments
                        var commentsButton = {
                            id: '19',
                            name: 'events-comment',
                            display: true,
                            active: self.artwork.artwork_submission.judge_comments.comments,
                            removeName: 'VIEW COMMENTS',
                            addName: 'ADD COMMENTS',
                            addedClass: 'btn-default',
                        };
                        commentsButton.action = Partial.func(toggleEventComments, commentsButton);
                        self.actionButtons.push(commentsButton);
                    }

                    if (!self.isOwner) {
                        if (
                            !$stateParams.channel ||
                            $stateParams.channel.commercial_rule !== CHANNEL_COMMERCIAL_RULES.LOAN
                        ) {
                            // Buy
                            if (
                                !self.artwork.is_inquire &&
                                self.artwork.sale_type === 'SRB' &&
                                parseInt(self.artwork.purchased_copies) < parseInt(self.artwork.total_copies)
                            ) {
                                if ($state.includes('home.vault') && ArtworkService.userOwnsAnEdition(self.artwork)) {
                                    // DO not display buy!
                                    var emptyBlock = !false;
                                } else {
                                    self.actionButtons.push({
                                        action: Partial.func(ArtworkService.handleArtworkAction, 'buy', self.artwork, {
                                            channelId: $stateParams.channelId,
                                            fromModal: $stateParams.fromModal,
                                            share: $stateParams.share,
                                            invitationCode: $stateParams.invitationCode,
                                        }),
                                        id: ENTITY_ACTIONS.BUY,
                                        name: 'buy',
                                        display: true,
                                        active: true,
                                        removeName: 'Buy',
                                        addName: 'Buy',
                                        addedClass: 'btn-primary',
                                    });
                                }
                            }
                        }

                        // Inquire
                        if (self.artwork.is_inquire) {
                            self.actionButtons.push({
                                action: Partial.func(
                                    ArtworkService.handleArtworkAction,
                                    'inquire',
                                    self.artwork,
                                    self.channel
                                ),
                                id: '10',
                                name: 'inquire',
                                display: true,
                                active: true,
                                removeName: 'Inquire',
                                addName: 'Inquire',
                                removeIcon: 'modules/core/img/envelope.svg',
                                addIcon: 'modules/core/img/envelope.svg',
                                addedClass: '',
                            });
                        }

                        // Add / Remove
                        if (
                            (self.artwork.sale_type === 'FRE' || self.artwork.sale_type === 'FCC') &&
                            !self.artwork.is_accepted &&
                            !self.artwork.is_loaned &&
                            !self.artwork.is_rented
                        ) {
                            var addButton = {
                                id: '16',
                                name: 'add',
                                display: true,
                                active: self.artwork.is_added,
                                removeName: 'Remove',
                                addName: 'Add',
                                removeIcon: 'modules/core/img/trash.svg',
                                addIcon: 'modules/core/img/check-big.svg',
                                addedClass: '',
                            };
                            addButton.action = Partial.func(toggleArtwork, addButton);
                            self.actionButtons.push(addButton);
                        }
                    }
                }

                // Owner
                if (self.isOwner || self.isAdmin || self.artwork.is_consignee) {
                    // Transfer Artwork
                    self.actionButtons.push({
                        action: Partial.func(OpenPopupForm.openMenu, 'share', { artwork: self.artwork }),
                        id: '1',
                        name: 'share',
                        active: true,
                        removeName: 'Transfer Artwork',
                        addName: 'Transfer Artwork',
                        removeIcon: 'modules/core/img/transfer.svg',
                        addIcon: 'modules/core/img/transfer.svg',
                    });
                    // Artwork Management
                    self.actionButtons.push({
                        action: Partial.func(OpenPopupForm.openMenu, 'artworkManagement', {
                            artwork: self.artwork,
                        }),
                        id: '15',
                        name: 'manage',
                        active: true,
                        removeName: 'Manage',
                        addName: 'Manage',
                        removeIcon: 'modules/core/img/manage.svg',
                        addIcon: 'modules/core/img/manage.svg',
                        addedClass: '',
                    });
                    // Edit
                    if (self.currentState) {
                        var parameters = { artworkId: self.artwork.id, collectionId: self.collectionId };
                        var homeOrProfile = self.isOwner ? 'home' : 'profile';
                        if (self.artwork.uploader && self.artwork.uploader.url && !self.isOwner) {
                            parameters.url = self.artwork.uploader.url;
                        }
                        self.actionButtons.push({
                            action: Partial.func($state.go, `${homeOrProfile}.${self.currentState}Upload`, parameters),
                            id: '13',
                            name: 'edit',
                            active: true,
                            removeName: 'Edit',
                            addName: 'Edit',
                            removeIcon: 'modules/core/img/pencil.svg',
                            addIcon: 'modules/core/img/pencil.svg',
                            addedClass: '',
                        });
                    }
                    // Delete
                    if (!self.artwork.is_consignee) {
                        self.actionButtons.push({
                            action: Partial.func(self.deleteArtwork, self.artwork),
                            id: '14',
                            name: 'delete',
                            active: true,
                            removeName: 'Delete',
                            addName: 'Delete',
                            removeIcon: 'modules/core/img/trash.svg',
                            addIcon: 'modules/core/img/trash.svg',
                            addedClass: 'btn-warning',
                        });
                    }
                }

                // Wishlist
                if (self.displayWishlist) {
                    var wishlistButton = {
                        id: '11',
                        name: 'wishlist',
                        display: true,
                        active: self.favorites.artworks[self.artwork.id],
                        removeName: $sce.trustAsHtml(
                            '<span class="hidden-md hidden-xs hidden-sm">Remove from </span> Favorites'
                        ),
                        addName: $sce.trustAsHtml(
                            '<span class="hidden-md hidden-xs hidden-sm">Add to </span> Favorites'
                        ),
                        removeIcon: 'modules/core/img/heart-bigger-selected.svg',
                        addIcon: 'modules/core/img/heart-bigger.svg',
                        addedClass: '',
                    };
                    wishlistButton.action = Partial.func(toggleWishlist, wishlistButton);

                    if (self.isMobile) {
                        self.additionalActions.push(wishlistButton);
                    } else {
                        self.actionButtons.push(wishlistButton);
                    }
                }

                // Social Share
                if (
                    (!$stateParams.channel || $stateParams.channel.visible) &&
                    (self.artwork.visible || self.isOwner || self.isAdmin || self.artwork.is_consignee) &&
                    !self.eventId
                ) {
                    var socialLink = '';
                    if ($stateParams.channelId && $stateParams.channel && $stateParams.channel.url_name) {
                        socialLink = `${NIIO_URL}/channel/${$stateParams.channel.url_name}?artworkId=${self.artwork.id}`;
                    } else {
                        socialLink = `${NIIO_URL}/artwork/${self.artwork.id}`;
                    }

                    const socialShareBtn = {
                        action: Partial.func(OpenPopupForm.openMenu, 'social', {
                            artwork: self.artwork,
                            channel: $stateParams.channel,
                            link: socialLink,
                        }),
                        id: '12',
                        name: 'social-share',
                        active: true,
                        removeName: 'Social Share',
                        addName: 'Social Share',
                        removeIcon: 'modules/core/img/share.svg',
                        addIcon: 'modules/core/img/share.svg',
                        addedClass: '',
                    };

                    if (self.isMobile) {
                        self.additionalActions.push(socialShareBtn);
                    } else {
                        self.actionButtons.push(socialShareBtn);
                    }
                }

                // Add to queue
                self.additionalActions.push({
                    id: '27',
                    name: 'add-to-queue',
                    display: true,
                    active: true,
                    removeName: 'Add to queue',
                    addName: 'Add to queue',
                    removeIcon: 'modules/core/img/add-to-queue.svg',
                    addIcon: 'modules/core/img/add-to-queue.svg',
                    addedClass: '',
                    action: self.openMobilePopup.bind(self, 'add-to-queue'),
                });

                // Add to playlist
                self.additionalActions.push({
                    id: '28',
                    name: 'add-to-playlist',
                    display: true,
                    active: true,
                    removeName: 'Add to playlist',
                    addName: 'Add to playlist',
                    removeIcon: 'modules/core/img/add-to-a-playlist.svg',
                    addIcon: 'modules/core/img/add-to-a-playlist.svg',
                    addedClass: '',
                    action: self.openMobilePopup.bind(self, 'add-to-playlist'),
                });

                self.additionalActions.forEach((actionButton) => {
                    actionButton.isAdmin = self.isAdmin;
                    actionButton.isOwner = self.isOwner;
                });

                self.actionButtons.forEach((actionButton) => {
                    actionButton.isAdmin = self.isAdmin;
                    actionButton.isOwner = self.isOwner;
                });
            }
        }

        function sortActionButtons() {
            if (self.actionButtons.length > 0) {
                if (self.isOwner || self.artwork.is_consignee || self.isAdmin) {
                    var editAction;
                    var editActionIndex = _.findIndex(self.actionButtons, (button) => {
                        return button.id === '13' && (button.display || button.active);
                    });
                    if (editActionIndex > -1) {
                        editAction = self.actionButtons[editActionIndex];
                        self.actionButtons.splice(editActionIndex, 1);
                        self.actionButtons.splice(0, 0, editAction);
                    }
                }

                if ((!self.isOwner && !self.artwork.is_consignee) || self.isAdmin) {
                    var rentAction;
                    var loanAction;
                    var buyAction;
                    var addAction;
                    var rentActionIndex = _.findIndex(self.actionButtons, (button) => {
                        return button.id === '3' && (button.display || button.active);
                    });
                    var loanActionIndex = _.findIndex(self.actionButtons, (button) => {
                        return button.id === '2' && (button.display || button.active);
                    });
                    var buyActionIndex = _.findIndex(self.actionButtons, (button) => {
                        return button.id === '6' && (button.display || button.active);
                    });
                    var addActionIndex = _.findIndex(self.actionButtons, (button) => {
                        return button.id === '16' && (button.display || button.active);
                    });

                    if (buyActionIndex > -1 || addActionIndex > -1) {
                        if (buyActionIndex > -1) {
                            buyAction = self.actionButtons[buyActionIndex];
                            self.actionButtons.splice(buyActionIndex, 1);
                            self.actionButtons.splice(0, 0, buyAction);
                        } else if (addActionIndex > -1) {
                            addAction = self.actionButtons[addActionIndex];
                            self.actionButtons.splice(addActionIndex, 1);
                            self.actionButtons.splice(0, 0, addAction);
                        }

                        if (rentActionIndex > -1) {
                            rentAction = self.actionButtons[rentActionIndex];
                            self.actionButtons.splice(rentActionIndex, 1);
                            self.actionButtons.splice(1, 0, rentAction);
                        } else if (loanActionIndex > -1) {
                            loanAction = self.actionButtons[loanActionIndex];
                            self.actionButtons.splice(loanActionIndex, 1);
                            self.actionButtons.splice(1, 0, loanAction);
                        }
                    } else {
                        if (rentActionIndex > -1) {
                            rentAction = self.actionButtons[rentActionIndex];
                            self.actionButtons.splice(rentActionIndex, 1);
                            self.actionButtons.splice(0, 0, rentAction);
                        } else if (loanActionIndex > -1) {
                            loanAction = self.actionButtons[loanActionIndex];
                            self.actionButtons.splice(loanActionIndex, 1);
                            self.actionButtons.splice(0, 0, loanAction);
                        }
                    }
                }

                var deleteActionIndex = _.findIndex(self.actionButtons, (button) => {
                    return button.id === '14' && (button.display || button.active);
                });
                if (deleteActionIndex > -1) {
                    var deleteAction = self.actionButtons[deleteActionIndex];
                    self.actionButtons.splice(deleteActionIndex, 1);
                    self.actionButtons.splice(self.actionButtons.length, 0, deleteAction);
                }

                var firstActionIndex = _.findIndex(self.actionButtons, (button) => {
                    return button.display || button.active;
                });
                if (firstActionIndex > -1 && self.actionButtons[firstActionIndex]) {
                    self.actionButtons[firstActionIndex].addedClass = 'btn-primary'; // first button is primary
                }
            }
        }

        this.colorIcon = function (actionButton) {
            if (actionButton.addedClass === 'btn-danger' || (actionButton.active && actionButton.isSharedAction)) {
                return ['danger-color-svg'];
            }
            if (actionButton.addedClass === 'btn-primary') {
                return ['white-color-svg'];
            }
            if (!actionButton.addedClass || actionButton.addedClass === 'btn-default') {
                return ['black-color-svg'];
            }
        };

        function excludePreviewAction(sharedActions) {
            var actions = [];
            sharedActions.forEach((action) => {
                if (action.id !== ENTITY_ACTIONS.SENDPREVIEW) {
                    actions.push(action);
                }
            });
            return actions;
        }

        function getSharedActions() {
            $rootScope.$broadcast('ajax-start');

            var data = {
                artworkId: $stateParams.artworkId,
            };
            var deferred = $q.defer();

            if ($stateParams.share && !$stateParams.channelId) {
                data.publishUniqueSeq = $stateParams.share;
                data.password = ArtworkInfoService.artworkSharedPassword;

                // TODO: check why on embed loan got broken json on action.parameters
                $http
                    .post('/transfer/getEntityPresetActions', data)
                    .then(
                        (res) => {
                            self.sharedActions = res.data.actions;
                            self.sharedActions = excludePreviewAction(self.sharedActions);
                            deferred.resolve();
                        },
                        (error) => {
                            var messageParams = {};
                            messageParams.message = `There was a problem with the shared link: ${error.data.message.text}`;
                            if (error.data.message.text === 'Link is not active') {
                                messageParams.message += '\nPlease contact the artist to get new URL';
                            }
                            messageParams.title = 'Shared Link Error';
                            messageParams.blur = true;
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($rootScope, messageParams).then((res) => {
                                if (self.passwordRequired) {
                                    openPasswordPopup();
                                } else {
                                    $state.go('home-page');
                                }
                                deferred.reject();
                            });
                        }
                    )
                    .finally(() => {
                        $rootScope.$broadcast('ajax-stop');
                    });
            } else {
                $rootScope.$broadcast('ajax-stop');
                deferred.resolve();
            }

            return deferred.promise;
        }

        this.searchTag = function (tag) {
            $rootScope.$broadcast('ArtworkInfoArtworkController::search_tag', tag);
        };

        this.downloadAdditionalFile = function (file, index, forceDownload) {
            if (file.status === 'READY') {
                var data = {
                    additionalFileId: file.id,
                    artworkId: self.artwork.id,
                    forceDownload,
                };

                $rootScope.$broadcast('ajax-start');
                file.downloading = true;

                $http
                    .post('/additionalFiles/downloadEntityAdditionalFile', data)
                    .then(
                        (res) => {
                            self.sharedActions = res.data.url;

                            var placeholder = document.getElementById(file.id);
                            var download = document.createElement('a');
                            download.setAttribute('href', res.data.url);
                            download.setAttribute('ng-hide', true);
                            if (forceDownload) {
                                download.setAttribute('download', '');
                            }
                            // download.setAttribute('download', file.original_filename);
                            // download.setAttribute('target', '_self');
                            placeholder.appendChild(download);
                            download.click();
                        },
                        (error) => {}
                    )
                    .finally(() => {
                        file.downloading = false;
                        $rootScope.$broadcast('ajax-stop');
                    });
            }
        };

        this.applyCursor = function (additionalFile) {
            return additionalFile.status === 'READY' ? { cursor: 'pointer' } : {};
        };

        this.init = function () {
            $scope.$emit('ArtworkInfoArtworkController:artwork_info_entered');

            // Scroll to head of page in init
            var homeSecondNavElement = document.getElementById('home-secondary-nav');
            if (homeSecondNavElement && !$stateParams.fromModal) {
                smoothScroll(homeSecondNavElement, { offset: $rootScope.heightOfNav });
            }
            self.fullscreenIsOpen = false;
            self.collectionId = $stateParams.collectionId;
            $rootScope.$broadcast('ajax-start');

            // 1. See if a password is needed from server
            isPasswordRequired($stateParams.share, $stateParams.channelId)
                .then((res) => {
                    self.passwordRequired = res.data.isPasswordRequired;
                    if (self.passwordRequired) {
                        openPasswordPopup();
                    } else {
                        loadArtwork();
                    }
                })
                .finally(() => {
                    $rootScope.$broadcast('ajax-stop');
                });
            $timeout(() => {
                if (!self.initialPlay) {
                    self.isPlaying = false;
                }
            }, 1500);
        };

        this.init();

        this.handleAction = function (action) {
            action.isDisabled = true;
            var event = self.event && self.event.event ? self.event.event : null;
            ArtworkService.handleArtworkAction(action.name, self.artwork, {
                share: $stateParams.share,
                password: ArtworkInfoService.artworkSharedPassword,
                action,
                eventId: self.eventId,
                event,
                channelId: $stateParams.channelId,
                fromModal: $stateParams.fromModal,
            }).finally(() => {
                action.isDisabled = false;
            });
        };

        this.deleteArtwork = function (artwork) {
            var deferred = $q.defer();
            var messageParams = {};

            messageParams.message = 'You are about to permanently Delete this artwork from your account.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = `Delete Artwork “${artwork.title}”`;
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then((res) => {
                if (res === 'ok') {
                    ArtworkService.handleArtworkAction('delete', artwork).then(
                        (res) => {
                            $rootScope.$emit('artwork_deleted', artwork.id);
                            var messageParams = {};
                            messageParams.message = 'Successfully deleted the artwork';
                            messageParams.title = 'Delete Artwork';
                            Messages.openMessage($rootScope, messageParams);
                            $rootScope.previousState('home');
                            deferred.resolve();
                        },
                        (error) => {
                            var messageParams = {};
                            messageParams.message = `Failed to delete the artwork. ${error}`;
                            messageParams.title = 'Delete Artwork';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams).then((res) => {
                                deferred.reject();
                            });
                        }
                    );
                } else {
                    deferred.reject();
                }
            });

            return deferred.promise;
        };

        this.getDataUrl = async function (ctx) {
            return new Promise((resolve, reject) => {
                ctx.getDataUrl((url) => resolve(url));
            });
        };

        this.convertPDFtoURL = async function (ctx) {
            const base64url = await this.getDataUrl(ctx);
            const blobData = await fetch(base64url).then((r) => r.blob());
            return URL.createObjectURL(blobData);
        };

        this.handleCertificateAction = function (action, certificate) {
            var certPdf = ArtworkInfoService.createCertificatePDF(certificate, self.artwork);
            if (this.isMobile && action === 'view') {
                action = 'download';
            }
            switch (action) {
                case 'view':
                    this.convertPDFtoURL(certPdf).then((url) => {
                        const fileWindow = window.open(url);
                        fileWindow.focus();
                    });
                    break;
                case 'print':
                    this.convertPDFtoURL(certPdf).then((url) => {
                        const fileWindow = window.open(url);
                        setTimeout(() => {
                            fileWindow.focus();
                            fileWindow.print();
                        }, 1000);
                    });
                    break;
                case 'download':
                    certPdf.download('certificate.pdf');
                    break;
            }
        };

        this.closeDeviceInterface = function () {
            NiioPlayer.Close();
        };

        // TODO: Duplicate code.
        $scope.latestRating = this.latestRating = {
            timer: undefined,
            value: undefined,
            init(v) {
                self.latestRating.value = v || 0;
                if (self.eventId !== undefined) {
                    $scope.$watch('latestRating.value', (newValue, oldValue) => {
                        if (newValue !== undefined && self.artwork && oldValue !== newValue) {
                            self.latestRating.send();
                        }
                    });
                    $rootScope.$on('artwork_changed_rating', ($event, artworkId, newValue, eventId) => {
                        if (self.eventId === eventId && self.artwork.id === artworkId) {
                            self.artwork.artwork_submission.rating = newValue;
                            self.latestRating.value = newValue;
                        }
                    });
                }
            },
            send() {
                self.latestRating.timer = self.setEventRating();
            },
        };

        this.setEventRating = function () {
            var valueToSend = self.latestRating.value;
            self.ArtworkService.handleArtworkAction('eventRating', self.artwork, {
                eventId: self.eventId,
                rating: valueToSend,
            }).then((res) => {
                self.artwork.artwork_submission.rating = res.new_value;
            });
        };
        this.openInquireArtworkModal = function () {
            self.ArtworkService.openInquireArtworkModal(self.artwork.id, null, self.artwork);
        };
        this.editArtwork = function () {
            if (self.currentState) {
                var parameters = { artworkId: self.artwork.id, collectionId: self.collectionId };
                var homeOrProfile = self.isOwner ? 'home' : 'profile';
                if (self.artwork.uploader && self.artwork.uploader.url && !self.isOwner) {
                    parameters.url = self.artwork.uploader.url;
                }
                $state.go(`${homeOrProfile}.${self.currentState}Upload`, parameters);
            }
        };

        function viewOriginal() {
            if (self.videoArtwork !== undefined && self.videoArtwork !== null) {
                self.videoArtwork.dispose();
                removeVideo();
            }
            ArtworkService.handleArtworkAction('getPreviewPathWithExtension', self.artwork, {
                preview: self.artwork.preview_original,
                preview_hls: self.artwork.preview_original_hls,
                media_type: self.artwork.file_metadata.media_type,
            }).then((res) => {
                self.viewingNow = 'original';
                self.viewingNowExtension = res.type;
                if (res.type === 'video') {
                    self.artwork.preview_allSrcs = res.src;
                    self.viewingNowSource = res.src[0].src;
                    loadVideo(self.viewingNowSource, res.src[0].type);
                } else {
                    self.viewingNowSource = res.path;
                    $rootScope.$broadcast('ArtworkInfoArtworkController::finished_loading');
                }
            });
        }

        function viewPreview() {
            if (self.videoArtwork !== undefined && self.videoArtwork !== null) {
                self.videoArtwork.dispose();
                removeVideo();
            }

            ArtworkService.handleArtworkAction('getPreviewPathWithExtension', self.artwork, {
                preview: self.artwork.preview,
                preview_hls: self.artwork.preview_hls,
                preview_file: self.artwork.preview_file,
                media_type: self.artwork.file_metadata.media_type,
            }).then((res) => {
                self.viewingNow = self.artwork.preview_file && self.artwork.preview_file.id ? 'preview' : 'original';
                self.viewingNowExtension = res.type;
                if (res.type === 'video') {
                    self.artwork.preview_allSrcs = res.src;
                    self.viewingNowSource = res.src[0].src.includes('null') ? res.src[1].src : res.src[0].src;
                    loadVideo(self.viewingNowSource, res.src[0].type);
                } else {
                    self.viewingNowSource = res.path;
                    $rootScope.$broadcast('ArtworkInfoArtworkController::finished_loading');
                }
            });
        }

        this.toggleBetweenOriginalAndPreview = function () {
            const alternativePreviewFileExists = self.artwork.preview_file && self.artwork.preview_file.id;
            const preventOriginalPlayback =
                alternativePreviewFileExists &&
                self.artwork.preview_file.properties_json &&
                self.artwork.preview_file.properties_json.preventOriginalPlayback;

            if (alternativePreviewFileExists) {
                let shouldShow;
                if (self.viewingNow) {
                    // We need to declare the desired viewing, so we toggle
                    shouldShow = self.viewingNow === 'original' ? 'preview' : 'original';
                }

                if (!self.viewingNow) {
                    // First time only
                    shouldShow = !self.artwork.preview_wm ? 'original' : 'preview';
                }

                if (shouldShow === 'original') {
                    viewOriginal();
                } else {
                    viewPreview();
                }
            } else {
                viewPreview();
            }
        };

        self.formatAddress = (address, charsFromStart, charsFromEnd) => {
            if (!address) return '';

            const firstChars = (str, chars) => str.substr(0, chars);
            const lastChars = (str, chars) => str.substr(str.length - chars);
            const startOfStr = firstChars(address, charsFromStart);
            const endOfStr = lastChars(address, charsFromEnd);
            return `${startOfStr}...${endOfStr}`;
        };

        $scope.showTooltip = false;
        $scope.copyAddressToClipboard = function (address) {
            $scope.showTooltip = true;
            $window.navigator.clipboard.writeText(address);
            $timeout(() => {
                $scope.showTooltip = false;
            }, 1500);
        };

        $scope.openLinkUrl = function (url) {
            $window.open(url, '_blank');
        };

        function removeVideo() {
            // self.videoArtwork.player_.reset();
            var currentPlayer = document.getElementById(videoId);
            if (currentPlayer) {
                currentPlayer.parentNode.removeChild(currentPlayer);
            }
        }

        $scope.$on('$destroy', () => {
            //            Destroy the object if it exists
            if (self.videoArtwork !== undefined && self.videoArtwork !== null) {
                self.videoArtwork.dispose();
            }
            ArtworkInfoService.artworkSharedPassword = '';
            $scope.$emit('ArtworkInfoArtworkController:artwork_info_destroyed');
        });
    },
]);
