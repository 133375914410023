'use strict';

//http://victorblog.com/2014/01/12/fixing-autocomplete-autofill-on-angularjs-form-submit/
angular.module('core').directive('formAutofillFix', function () {
    return function (scope, elem, attrs) {
        // Fixes Chrome bug: https://groups.google.com/forum/#!topic/angular/6NlucSskQjY
        elem.prop('method', 'POST');

        // Fix autofill issues where Angular doesn't know about autofilled inputs
        if (attrs.ngSubmit) {
            setTimeout(function () {
                elem.unbind('submit').bind('submit', function (e) {
                    e.preventDefault();
                    elem.find('input, textarea, select')
                        .triggerHandler('input')
                        .triggerHandler('change')
                        .triggerHandler('keydown');
                    scope.$apply(attrs.ngSubmit);
                });
            }, 0);
        }
    };
});
