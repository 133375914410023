'use strict';

angular.module('core').service('PasswordProtectLink', [
    '$rootScope',
    '$state',
    '$localStorage',
    'Messages',
    'Partial',
    'User',
    'AREAS_PASSWORDS',
    function ($rootScope, $state, $localStorage, Messages, Partial, User, AREAS_PASSWORDS) {
        var self = this;

        function initService() {
            self.setPassword('');
            self.passwordPopupIsOpen = false;
        }

        this.isAuthorized = function () {
            //return User.isAdmin() || self.getPassword() === AREAS_PASSWORDS.Discover;
            return true;
        };

        function goToState(state) {
            if (self.isAuthorized()) {
                $state.go(state);
            } else {
                var messageParams = {};
                messageParams.message = 'Wrong password was entered, please try again or cancel';
                messageParams.title = 'Wrong Password';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams).then(function (res) {
                    self.openPassword(state);
                });
            }
        }

        this.openPassword = function (state) {
            if (!self.passwordPopupIsOpen) {
                if (self.isAuthorized()) {
                    $state.go(state);
                } else {
                    self.passwordPopupIsOpen = true;
                    var messageParams = {};
                    messageParams.disableAutoDismiss = true;
                    messageParams.cancelText = 'Cancel';
                    //messageParams.blur = true;
                    messageParams.okFunction = Partial.func(goToState, state);
                    messageParams.cancelText = 'Cancel';
                    messageParams.template =
                        'modules/core/services/core.password-protect-popup.client.view.html';
                    Messages.openMessage($rootScope, messageParams).finally(function () {
                        self.passwordPopupIsOpen = false;
                    });
                }
            }
        };

        this.setPassword = function (password) {
            $localStorage.discoverPhrase = password;
        };

        this.getPassword = function () {
            return $localStorage.discoverPhrase;
        };

        $rootScope.$on('InitServices', function () {
            initService();
        });
    },
]);
