'use strict';

angular.module('share').controller('ShareController', [
    '$rootScope',
    '$state',
    '$scope',
    '$stateParams',
    '$q',
    '$http',
    '$log',
    '$timeout',
    'Security',
    'Messages',
    'FormValidationService',
    'User',
    'NIIO_URL',
    'NIIO_API_URL',
    '_',
    '$sce',
    function (
        $rootScope,
        $state,
        $scope,
        $stateParams,
        $q,
        $http,
        $log,
        $timeout,
        Security,
        Messages,
        FormValidationService,
        User,
        NIIO_URL,
        NIIO_API_URL,
        _,
        $sce
    ) {
        var self = this;
        self.childCB = undefined;
        self.share = {};
        self.embed = {
            showinfo: false,
            useSocialLink: false,
            autoplay: false,
            controls: true,
            hidefullscreen: false,
            hideinfobtn: false,
            mute: true,
            posterlink: undefined,
            posterlinkUrl: undefined,
        };

        self.embedRatio = 9 / 16;

        this.FormValidationService = FormValidationService;
        this.Messages = Messages;
        this.NIIO_URL = NIIO_URL;
        this.NIIO_API_URL = NIIO_API_URL;
        this.isAdmin = User.isAdmin();
        this.processingSave = false;
        this.editions = [];
        this.editionsWereLoaded = false;
        this.loadingEditions = false;
        this.currencies = ['USD', 'EUR', 'GBP'];

        /*function arrangeActions() {
            var actions = [], action, i;

            for (i = 0; i < self.actions.length; i++) {
                if (self.actions[i].selected) {
                    action = angular.copy(self.actions[i]);
                    delete action.selected;
                    delete action.shouldShow;
                    delete action.view;
                    if ((self.actions[i].name === 'buy' || self.actions[i].name === 'transferOwnership') && !self.actions[i].allowSpecificEdition && self.actions[i].parameters.specificEdition) {
                        delete action.parameters.specificEdition;
                    }
                    if (self.actions[i].name === 'share' && !self.actions[i].allowSpecificTextEdition && self.actions[i].parameters.specificEdition) {
                        delete action.parameters.specificEdition;
                    }
                    // if (['loan','rent'].indexOf(self.actions[i].name)>=0 && self.actions[i].parameters.periodInMonths) {
                        //delete action.parameters.periodInMonths;
                        //action.parameters.periodInMonths = 3;
                    // }
                    // if (['loan','rent'].indexOf(self.actions[i].name)>=0 && self.actions[i].parameters.periodInMonths) {
                        //delete action.parameters.periodInMonths;
                        //action.parameters.periodInMonths = 3;
                    // }
                    actions.push(action);
                }
            }

            return actions;
        }*/

        function arrangePublish(specificPublish) {
            var publish;
            if (specificPublish) {
                publish = angular.copy(specificPublish);
            } else {
                publish = angular.copy(self.selectedPublish);
            }

            if (publish.name === 'embed') {
                publish.parameters = publish.parameters || {};
                publish.parameters.embed = self.embed;
            }

            delete publish.icon;
            delete publish.actionButtons;
            delete publish.view;

            return publish;
        }

        this.savePreset = function (specificPublish) {
            var deferred = $q.defer();
            // TODO - generator link from server

            if (!self.processingSave) {
                self.processingSave = true;

                // 1. send preset with actions
                var actions = self.childCB.arrangeActions();
                var publish = arrangePublish(specificPublish);

                var data = {
                    presetId: self.share.presetId,
                    actions: actions,
                    url: self.share.url,
                    publish: publish,
                    publishId: self.share.publishId,
                    uniqueSeq: self.share.uniqueSeq,
                    invitationCode: self.share.invitationCode,
                };

                self.childCB.extendPresetRequestData(data);

                if (self.enablePassword && self.share.password) {
                    data.password = self.share.password;
                }

                $http.post('/transfer/savePreset', data).then(
                    function (res) {
                        //var res = { 'unique_seq': randomString(), presetId: '23' };
                        self.share.uniqueSeq = res.data.data.uniqueSeq;
                        self.share.presetId = res.data.data.entityPresetId;
                        self.share.publishId = res.data.data.publishId;
                        self.share.invitationCode = res.data.data.invitationCode;
                        self.childCB.generateEmbedCode();
                        $log.debug('ShareController::savePreset Success', { res: res });
                        self.processingSave = false;
                        deferred.resolve(res);
                    },
                    function (error) {
                        $log.debug('ShareController::savePreset Failure', { error: error });
                        self.processingSave = false;
                        deferred.reject(error);
                    }
                );
            } else {
                $log.debug('ShareController::savePreset Tried to save but currently in process of other share');
                deferred.reject('Saving in process already');
            }

            return deferred.promise;
        };

        this.loadTags = function ($query) {
            var data = {
                query: $query,
            };

            return $http.post('/tags/getContacts', data).then(function (res) {
                var tags = [];
                res.data.tags.forEach(function (tag) {
                    tags.push({ text: tag });
                });
                return tags;
            });
        };

        /*        function updateEditionsStatuses(editions) {
            if (editions.length > 0) {
                editions.forEach(function(edition) {
                    edition.edition_number = parseInt(edition.edition_number);
                    if (edition.unavailable || edition.purchased_user_id) {
                        edition.unavailable = true;
                    } else {
                        edition.unavailable = false;
                    }
                });
            }
        }

        function getEditions (artworkId) {
            var deferred = $q.defer();

            if (artworkId) {
                var data = {
                    artworkId: artworkId
                };

                $http.post('/signedArtworks/getEditions', data)
                    .success(function (res) {
                        $log.debug('ShareController::getEditions Success', res, artworkId);
                        self.artwork = res.data[0];
                        updateEditionsStatuses(self.artwork.editions);
                        deferred.resolve(res);
                    })
                    .error(function (error) {
                        $log.debug('ShareController::getEditions Failed', error, artworkId);
                        deferred.reject(error);
                    });
            } else {
                deferred.reject('ShareController::getEditions artwork ID is empty');
            }

            return deferred.promise;
        }*/

        /*function handleEditionsForRequiredActions(newAction, oldActionString) {
            var firstAvailableEdition = _.find(self.artwork.editions, function(edition) {
                return !edition.unavailable;
            });
            if (firstAvailableEdition) {
                self.share.selectedAction.parameters.specificEdition = firstAvailableEdition.edition_number;
            } else {
                var oldAction = JSON.parse(oldActionString);
                if (oldAction) {
                    self.share.selectedAction = _.find(self.actions, function(action) {
                        return action.id === oldAction.id;
                    });
                    var messageParams = {};
                    messageParams.message = 'There are no available editions for sale. Reverting to previous action.';
                    messageParams.title = 'No Available Editions';
                    messageParams.disableAutoDismiss = true;
                    Messages.openMessage($rootScope, messageParams);
                    self.actionWasChanged(self.share.selectedAction, null);
                }
            }
            self.editionsWereLoaded = true;
            self.loadingEditions = false;

            handlePriceForRequiredAction(newAction);
        }*/

        /*function handlePriceForRequiredAction(newAction) {
            if (['rent', 'buy'].indexOf(newAction.name) >= 0) {
                self.share.selectedAction.parameters.price = self.share.selectedAction.parameters.price || parseInt(self.artwork.price) || 1;
                self.share.selectedAction.parameters.currency = self.share.selectedAction.parameters.currency || self.artwork.currency || self.currencies[0];
            }
        }*/

        /* this.actionWasChanged = function (newAction, oldActionString) {
            self.actions.forEach(function (action) {
                if (action.id === newAction.id && action.view === newAction.view) {
                    action.selected = true;
                } else if (action.id !== '4') {
                    action.selected = false;
                }
            });

            if (newAction.name === 'transferOwnership' || newAction.name === 'buy') {
                if (!self.editionsWereLoaded) {
                    self.loadingEditions = true;
                    getEditions(self.artwork.id)
                        .finally(function (res) {
                            handleEditionsForRequiredActions(newAction, oldActionString);
                        });
                } else {
                    handleEditionsForRequiredActions(newAction, oldActionString);
                }
            } else if (['rent', 'loan'].indexOf(newAction.name) >= 0) {
                handlePriceForRequiredAction(newAction);
            }

        };*/

        /*this.priceChanged = function() {
            if (!self.share.selectedAction.parameters.price) {
                self.share.selectedAction.parameters.price = parseInt(self.artwork.price);
            }
            if (!self.share.selectedAction.parameters.currency) {
                self.share.selectedAction.parameters.currency = self.artwork.currency;
            }
        };*/

        this.sendEmail = function () {
            var deferred = $q.defer();

            self.savePreset(self.selectedPublish).then(
                function (res) {
                    $log.debug('ShareController::sendEmail Success', { res: res });
                    var messageParams = {};
                    messageParams.message = 'Email was sent successfully';
                    Messages.openMessage($rootScope, messageParams).then(function () {
                        Messages.cancel();
                    });
                },
                function (error) {
                    $log.debug('ShareController::sendEmail Failure', { error: error });
                    if (!self.processingSave) {
                        var messageParams = {};
                        messageParams.message = 'There was a problem with sending the email: \n' + error.data.errors;
                        messageParams.title = 'Transfer Error';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                }
            );

            return deferred.promise;
        };

        this.activateEmbed = function () {
            var deferred = $q.defer();

            self.savePreset(self.selectedPublish).then(
                function (res) {
                    $log.debug('ShareController::activateEmbed Success', { res: res });
                    var messageParams = {};
                    messageParams.message = 'Email was sent successfully';
                    Messages.openMessage($rootScope, messageParams).then(function () {
                        Messages.cancel();
                    });
                },
                function (error) {
                    $log.debug('ShareController::activateEmbed Failure', { error: error });
                    if (!self.processingSave) {
                        var messageParams = {};
                        messageParams.message = 'There was a problem with Activating embed code.\n' + error.data.errors;
                        messageParams.title = 'Error';
                        messageParams.disableAutoDismiss = true;
                        Messages.openMessage($rootScope, messageParams);
                    }
                }
            );

            return deferred.promise;
        };

        /* function initArtworkResolutions(artworkId) {
            self.artworkDownloadResolutions = [];
            ArtworkService.getArtworkResolutions(artworkId).success(function(resp){
                self.artworkDownloadResolutions = [];
                var data = resp.resolutions;
                for (var i=0 ; i < data.length ; i++) {
                    var profileview ='';
                    // TODO RICH: Why check both for 0 and '0'? Don't we know the exact answer from server?
                    var fps = (data[i].parameters.mediaType === 'VIDEO' && data[i].parameters.fps !== 0 && data[i].parameters.fps !== '0') ? (' ' + data[i].parameters.fps + ' fps') : '';
                    var invalidDim = data[i].parameters.width === null || data[i].parameters.height === null;

                    if (data[i].profile === 'Preview' || invalidDim){
                        profileview = data[i].title + (data[i].parameters.watermark ? ' (Watermark)' : '');
                    } else if (data[i].profile === 'original'){
                        // TODO RICH: Please simplify code, turn into if instead of ?
                        // TODO RICH: Why check both for 0 and '0'? Don't we know the exact answer from server?
                        var originalDims = (data[i].parameters.width !== 0 && data[i].parameters.height !== 0 && data[i].parameters.width !== '0' && data[i].parameters.height !== '0') ?
                            (' ' + data[i].parameters.width + ' X ' + data[i].parameters.height) : '';
                        profileview = data[i].title + ' -' + originalDims + fps;
                    } else {
                        profileview = data[i].title + fps + ' - ' + data[i].parameters.width + ' X ' + data[i].parameters.height + (data[i].parameters.watermark ? ' (Watermark)' : '') + (data[i].parameters.drm ? ' + DRM ' : '');
                    }
                    self.artworkDownloadResolutions.push({profile: data[i].profile, view: profileview});
                }
            });
        }*/

        function initPublishes() {
            // TODO - get publishes from server
            self.publishes = [
                {
                    id: '1',
                    name: 'mail',
                    view: 'Mail',
                    icon: 'fa-envelope',
                    actionButtons: [{ view: 'Send', action: self.sendEmail }],
                },
                //{ id: '2', name: 'link', view: 'URL', icon: 'fa-link', actionButtons: [{view: 'Close', action: Messages.cancel}, {view: 'Copy', action: self.sendEmail2}] }
                //{ id: '2', name: 'link', view: 'URL', icon: 'fa-link', actionButtons: [{view: 'Activate URL', action: Messages.cancel}] },
                {
                    id: '3',
                    name: 'embed',
                    view: 'Embed',
                    icon: 'fa-share-alt-square',
                    actionButtons: [{ view: 'Activate Embed', action: Messages.cancel }],
                },
            ];
            if (self.isAdmin) {
                self.publishes.push({
                    id: '2',
                    name: 'link',
                    view: 'URL',
                    icon: 'fa-link',
                    actionButtons: [{ view: 'Activate URL', action: Messages.cancel }],
                });
            }
            self.selectedPublish = self.publishes[0];
            self.linkPublish = self.publishes[1];
        }

        this.onTabClicked = function (publish) {
            self.selectedPublish = publish;
            if (['link', 'embed'].indexOf(publish.name) >= 0) {
                if (publish.name === 'embed') {
                    self.trySwitchToPreviewAction();
                }
                self.savePreset(publish);
            }
        };

        this.trySwitchToPreviewAction = function () {
            var oldAction = self.share.selectedAction;
            self.share.selectedAction = _.find(self.actions, function (action) {
                return action.name === 'sendPreview';
            });
            self.share.selectedAction = self.share.selectedAction || oldAction;
            oldAction.selected = false;
            self.share.selectedAction.selected = true;
        };

        $rootScope.$on('embed:refreshed', function ($event, embedCode) {
            self.trustedEmbedCode = $sce.trustAsHtml(embedCode);
            self.embedCode = embedCode;
        });

        this.init = function (params, childCb) {
            changeCopyText();
            self.childCB = childCb;
            self.childCB.initPresets();
            initPublishes();
            self.childCB.generateEmbedCode();
            self.savePreset(self.linkPublish);
        };

        this.shouldShow = function (action) {
            return action.shouldShow;
        };

        this.warnAndClearPassword = function () {
            if (this.enablePassword) {
                this.enablePassword = false;
                var messageParams = {};
                messageParams.message = 'Password field was cleared since password is not supported in Embed feature.';
                messageParams.title = 'Warning';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($rootScope, messageParams);
            }
        };

        this.cancelPublish = function () {
            var data = {
                publishId: self.share.publishId,
                remove: true,
            };

            $http.post('/transfer/deletePublish', data).then(
                function (res) {
                    $log.debug('ShareController::cancelPublish Success', { res: res });
                },
                function (error) {
                    $log.debug('ShareController::cancelPublish Failure', { error: error });
                }
            );
            Messages.cancel();
        };

        this.openLearnMore = function () {
            self.childCB.openLearnMore();
        };

        function changeCopyText() {
            if (self.copyText) {
                self.copyText = 'Copied!';
                $timeout(function () {
                    self.copyText = 'Copy';
                }, 3000);
            } else {
                self.copyText = 'Copy';
            }
        }

        function successMessage() {
            var messageParams = {};
            messageParams.message = 'The link was copied to your clipboard';
            Messages.openMessage($scope, messageParams);
        }

        $scope.$on('clickToCopy-success', function (e) {
            successMessage();
            changeCopyText();
        });

        //this.specificTextEditionChanged = function(action) {
        //    if (['share', 'loan', 'rent'].indexOf(action.name) >= 0 && action.allowSpecificTextEdition) {
        //        if (action.parameters === null || typeof action.parameters !== 'object') {
        //            action.parameters = {};
        //        }
        //
        //        if (self.share.specificTextEdition && self.share.specificTextEdition.total) {
        //            action.parameters.specificEdition = '';
        //
        //            if (self.share.specificTextEdition.number) {
        //                action.parameters.specificEdition += '#' + self.share.specificTextEdition.number + ' / ';
        //                action.parameters.specificEdition += self.share.specificTextEdition.total;
        //            } else {
        //                action.parameters.specificEdition += 'of ' + self.share.specificTextEdition.total;
        //            }
        //
        //            if (self.share.specificTextEdition.ap) {
        //                action.parameters.specificEdition += ' + ' + self.share.specificTextEdition.ap + ' AP';
        //            }
        //        }
        //    }
        //};
    },
]);
