'use strict';

angular.module('home').controller('ChannelManagementController', [
    'Messages',
    'User',
    'CHANNEL_TYPES',
    function (Messages, User, CHANNEL_TYPES) {
        var self = this;
        this.Messages = Messages;
        this.selectedPage = {};

        function setPages() {
            self.pages = [
                {
                    source: 'modules/home/views/home.transfer-history.client.view.html',
                    name: 'Transfers',
                },
            ];

            if (User.isAdmin()) {
                self.pages.push({
                    source: 'modules/home/views/home.channel-subscribers.client.view.html',
                    name: 'Subscribers',
                });
            }
        }

        this.init = function () {
            self.channel = self.params && self.params.channel ? self.params.channel : {};
            self.channelId = self.params && self.params.channel ? self.params.channel.id : '';

            setPages();
            self.selectedPage = self.pages[0];
        };
    },
]);
