//English
translationsEN.DEVICES = {};
translationsEN.DEVICES.BASIC = 'Default System Display Settings';
translationsEN.DEVICES.ADVANCED = 'Advanced';
translationsEN.DEVICES.CONTENT = 'Content';
translationsEN.DEVICES.SETTINGS = 'Settings';
translationsEN.DEVICES.DOWNLOAD_MANAGEMENT = 'Download Management';
translationsEN.DEVICES.DISPLAY_ARRAY = 'Display array';
translationsEN.DEVICES.DEVICE_SETTINGS = 'Display settings';
translationsEN.DEVICES.GROUP_SETTINGS = 'Group settings';

// Add device
translationsEN.DEVICES.DEVICE_ID = 'ID';
translationsEN.DEVICES.DEVICE_CODE = 'Code';
translationsEN.DEVICES.DEVICE_NAME = 'Name';

//Hebrew
translationsHE.DEVICES = {};
