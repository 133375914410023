'use strict';

angular.module('home').controller('CloudUploadsProgressController', [
    '$rootScope',
    '$http',
    '$q',
    '$log',
    '_',
    'Messages',
    'UploadProgress',
    function ($rootScope, $http, $q, $log, _, Messages, UploadProgress) {
        var self = this;
        this.Messages = Messages;
        this.loading = false;
        this.itemsPerPage = 20;
        this.currentPage = 1;
        this.maxSize = 5;
        this.UploadProgress = UploadProgress;

        this.init = function () {
            $rootScope.cloudUploadProgressOpen = true;
            // self.files = self.params && self.params.files ? self.params.files : [];
            // _.each(self.files, function(file) {
            //     file.uploading = true;
            //     file.progress = 0;
            // });
        };

        function matchFileToFiles(file) {
            return _.find($rootScope.cloudFiles, function (fileFromArray) {
                return fileFromArray.id === file.id;
            });
        }

        $rootScope.$on('cloud_uploads_error', function (event, modifiedFile, error) {
            $log.debug('CloudUploadsProgressController::cloud_uploads_error', modifiedFile, error);
            var file = matchFileToFiles(modifiedFile);
            var messageParams = {};
            messageParams.title = 'Upload Failed';
            var fileName = file && file.original ? file.original : '';
            messageParams.message =
                'File ' +
                fileName +
                " didn't complete successfully, please try again.\nIf it continues to fail, please contact support.";
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                Messages.ok();
            });
        });

        $rootScope.$on('cloud_uploads_progress', function (event, modifiedFile, progress) {
            $log.debug('CloudUploadsProgressController::cloud_uploads_progress', modifiedFile, progress);
            var file = matchFileToFiles(modifiedFile);
            if (file) {
                file.progress = progress;
            }
        });

        $rootScope.$on('cloud_uploads_file_finished', function (event, modifiedFile) {
            $log.debug('CloudUploadsProgressController::cloud_uploads_file_finished', modifiedFile);
            var file = matchFileToFiles(modifiedFile);
            if (file) {
                file.progress = 100;
                file.uploading = false;
            }
        });

        $rootScope.$on('cloud_uploads_all_finished', function (event) {
            var messageParams = {};
            messageParams.title = 'Upload Completed';
            messageParams.message = 'Upload completed successfully, artworks has been sent to processing';
            Messages.ok();
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                Messages.ok();
            });
        });
    },
]);
