'use strict';

angular.module('core').controller('ArtworkActionButtonController', [
    '$scope',
    '$element',
    '$attrs',
    '$transclude',
    '$rootScope',
    '$log',
    '$state',
    '$stateParams',
    'UserService',
    'ArtworkService',
    'Messages',
    function (
        $scope,
        $element,
        $attrs,
        $transclude,
        $rootScope,
        $log,
        $state,
        $stateParams,
        UserService,
        ArtworkService,
        Messages
    ) {
        var self = this;
        this.ArtworkService = ArtworkService;
        this.isOwner =
            self.artwork && self.artwork.uploader
                ? UserService.getId() === self.artwork.uploader.user_id
                : UserService.getId() === self.artwork.user_id;
        this.UserService = UserService;

        this.init = function () {
            self.editState =
                'home.' +
                $state.current.data.state +
                'Upload({ artworkId: ' +
                $stateParams.artworkId +
                ', collectionId: ' +
                $stateParams.collectionId +
                ' })';
        };

        this.action = function (name) {
            ArtworkService.handleArtworkAction(name, self.artwork);
        };

        this.deleteArtwork = function (artwork) {
            var messageParams = {};

            messageParams.message = 'You are about to permanently Delete this artwork from your account.';
            messageParams.message += '\nAre you sure you want to continue?';
            messageParams.title = 'Delete Artwork “' + artwork.title + '”';
            messageParams.disableAutoDismiss = true;
            messageParams.cancelText = 'Cancel';
            messageParams.okText = 'Delete';
            messageParams.okClass = 'danger';
            Messages.openMessage($rootScope, messageParams).then(function (res) {
                if (res === 'ok') {
                    ArtworkService.handleArtworkAction('delete', artwork).then(
                        function (res) {
                            $rootScope.$emit('artwork_deleted', artwork.id);
                            var messageParams = {};
                            messageParams.message = 'Successfully deleted the artwork';
                            messageParams.title = 'Delete Artwork';
                            Messages.openMessage($rootScope, messageParams);
                        },
                        function (error) {
                            var messageParams = {};
                            messageParams.message = 'Failed to delete the artwork. ' + error;
                            messageParams.title = 'Delete Artwork';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams);
                        }
                    );
                }
            });
        };
    },
]);
