'use strict';

angular.module('core').controller('FormListErrorsController', [
    '$scope',
    '$element',
    '$attrs',
    '$transclude',
    function ($scope, $element, $attrs, $transclude) {
        var self = this;

        this.init = function () {};
    },
]);
