'use strict';

angular.module('home').controller('HomeEventsSubmissionSummaryController', [
    '$rootScope',
    '$filter',
    '$scope',
    '$state',
    '$stateParams',
    '$log',
    '$q',
    '$timeout',
    '$sce',
    '$http',
    '_',
    'smoothScroll',
    'HomeDataService',
    'FormValidationService',
    'Messages',
    'OpenPopupForm',
    'S3Uploader',
    'BillingService',
    'SERVER',
    function (
        $rootScope,
        $filter,
        $scope,
        $state,
        $stateParams,
        $log,
        $q,
        $timeout,
        $sce,
        $http,
        _,
        smoothScroll,
        HomeDataService,
        FormValidationService,
        Messages,
        OpenPopupForm,
        S3Uploader,
        BillingService,
        SERVER
    ) {
        $scope.HomeDataService = HomeDataService;
        $scope.FormValidationService = FormValidationService;

        $scope.init = function () {
            if ($scope.currentEvent) {
                $scope.termsLink = $scope.currentEvent.event.terms_link
                    ? $sce.trustAsResourceUrl($scope.currentEvent.event.terms_link)
                    : null;

                if (
                    $scope.currentEvent.event.submission_fee &&
                    $scope.currentEvent.submissions[0].paid_submission_fee &&
                    !$scope.currentEvent.submissions[0].submitted
                ) {
                    checkIfPaidAndNotSubmitted();
                }

                var tncVer = $scope.currentEvent.submissions[0].tnc_version;
                if (tncVer) {
                    $scope.termsLink = $sce.trustAsResourceUrl(
                        '/Server/events/terms/' + $scope.currentEvent.event.id + '/' + tncVer
                    );
                } else {
                    $scope.termsLink = $scope.currentEvent.event.terms_link
                        ? $sce.trustAsResourceUrl(SERVER.address + $scope.currentEvent.event.terms_link)
                        : null;
                }
            }
        };

        $scope.$on('reinitiazlie_step', function (event) {
            $scope.init();
        });

        function artworksAreStillUploading() {
            var result = [];

            if ($scope.currentEvent.artworks) {
                $scope.currentEvent.artworks.forEach(function (artwork) {
                    var uploadFound = _.find(S3Uploader.uploads, function (upload) {
                        return upload.extraParams.artworkId === artwork.id;
                    });

                    if (uploadFound && !uploadFound.success) {
                        result.push(uploadFound.extraParams.artwork);
                    }
                });
            }

            return result;
        }

        function checkValidityOfSubmission() {
            var stepsMissingError = '';
            var messageParams = {};
            var result = true;

            $scope.currentEvent.steps.forEach(function (step) {
                if (step.link !== 'summary' && !step.completed && !step.notRequired) {
                    stepsMissingError += 'Step ' + step.number + ' - ' + step.name + ' is missing.\n';
                }
            });

            if (stepsMissingError) {
                messageParams = {};
                messageParams.title = 'Complete All Steps';
                messageParams.message =
                    'The following steps are missing:\n' +
                    stepsMissingError +
                    'Please complete them to submit.';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
                result = false;
            } else if ($scope.currentEvent.submissions[0].submitted) {
                messageParams = {};
                messageParams.title = 'Submission Was Already Submitted';
                messageParams.message =
                    'This submissions was already submitted. No need to submit it again.\nAll information was updated already when you saved the steps.';
                messageParams.disableAutoDismiss = true;
                Messages.openMessage($scope, messageParams);
                result = false;
            }

            return result;
        }

        function checkIfPaidAndNotSubmitted() {
            var messageParams = {};

            var submissionValid = checkValidityOfSubmission();

            if (
                submissionValid &&
                (!$scope.currentEvent.event.submission_fee ||
                    $scope.currentEvent.submissions[0].paid_submission_fee)
            ) {
                var tncVer =
                    $scope.currentEvent.submissions && $scope.currentEvent.submissions[0].tnc_version
                        ? $scope.currentEvent.submissions[0].tnc_version
                        : $scope.currentEvent.event.terms_latest_version;
                var data = {
                    eventId: $scope.currentEvent.event.id,
                    userId: $scope.userId,
                    tncId: tncVer,
                };

                $scope.submitWasClicked = true;

                var uploadingSubmittedArtworks = artworksAreStillUploading();

                $http
                    .post('/events/submitSubmission', data)
                    .then(
                        function (res) {
                            $log.debug(
                                'HomeEventsSubmissionFormController::updateSelectedArtworks Success',
                                res,
                                data
                            );
                            var messageParams = {};
                            messageParams.title = 'Application Submitted';
                            messageParams.message = 'Your application form was successfully submitted.<br>';
                            if (uploadingSubmittedArtworks.length > 0) {
                                messageParams.title =
                                    '<i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;Artworks Still Uploading';
                                messageParams.disableAutoDismiss = true;
                                messageParams.message +=
                                    '<br>Please note - some of your artworks are still being uploaded:<br>';
                                messageParams.message += '<ul>';
                                uploadingSubmittedArtworks.forEach(function (artwork) {
                                    messageParams.message += '<li><b>' + artwork.title + '</b></li>';
                                });

                                messageParams.message += '</ul>';

                                messageParams.message +=
                                    'Please make sure that you keep the web page open until all uploads are complete, and that you have a stable internet connection.<br><br>';
                                messageParams.message +=
                                    'You can follow the progress of your uploads by clicking the floating button located in the bottom right corner of the screen.';
                                messageParams.message +=
                                    '<div style="text-align: center; margin-bottom: -40px;"><img src="modules/home/img/UploadingIcon.jpg"></div><br>';

                                // messageParams.message += 'If there is any upload failure please go back to the artwork selection step in the events submission wizard and retry uploading your work.<br>';
                            } else {
                                messageParams.message += 'Thank you!';
                            }

                            // To allow style
                            messageParams.message = $sce.trustAsHtml(messageParams.message);
                            Messages.openMessage($scope, messageParams).then(function () {
                                $scope.currentEvent.submissions[0].submitted = res.data.submitted;

                                // Update in the list
                                var foundEventSubmission = _.find(
                                    $scope.events.submissions,
                                    function (submission) {
                                        if (submission.event && $scope.currentEvent.event) {
                                            return submission.event.id === $scope.currentEvent.event.id;
                                        } else {
                                            return false;
                                        }
                                    }
                                );

                                if (foundEventSubmission) {
                                    foundEventSubmission.submitted = res.data.submitted;
                                }

                                $scope.$emit('submission_step_completed');
                            });
                        },
                        function (error) {
                            $log.debug(
                                'HomeEventsSubmissionFormController::updateSelectedArtworks Failed',
                                error,
                                data
                            );
                            var messageParams = {};
                            messageParams.title = 'Failed to Submit';
                            messageParams.message =
                                'There was a problem submitting the submission. Please contact support (press OK to continue).';
                            messageParams.disableAutoDismiss = true;
                            Messages.openMessage($scope, messageParams).then(function (res) {
                                OpenPopupForm.openContact(
                                    'support',
                                    null,
                                    'event submission failed to submit (event id: ' +
                                        $stateParams.eventId +
                                        ')'
                                );
                            });
                        }
                    )
                    .finally(function () {
                        $scope.submitWasClicked = false;
                    });
            }
        }

        $scope.submit = function () {
            var messageParams = {};

            var submissionValid = checkValidityOfSubmission();
            var warningText =
                $scope.currentEvent && $scope.currentEvent.event && $scope.currentEvent.event.warning_text
                    ? $scope.currentEvent.event.warning_text
                    : 'Are you sure you want to submit?';

            if (submissionValid) {
                messageParams = {};
                messageParams.title = 'Submit Application';
                messageParams.message = warningText;
                messageParams.disableAutoDismiss = true;
                messageParams.cancelText = 'Cancel';
                messageParams.okText = 'Submit';
                Messages.openMessage($scope, messageParams).then(function (res) {
                    if (res === 'ok') {
                        $scope.submitWasClicked = true;

                        var uploadingSubmittedArtworks = artworksAreStillUploading();

                        // Redirect to billing
                        var data = {
                            type: BillingService.otpTypes.submission_fee,
                            eventId: $scope.currentEvent.event.id,
                        };

                        if (
                            $scope.currentEvent.event.submission_fee &&
                            !$scope.currentEvent.submissions[0].paid_submission_fee
                        ) {
                            BillingService.event = $scope.currentEvent.event;
                            $state.go('buy', data);
                        } else {
                            checkIfPaidAndNotSubmitted();
                        }
                    }
                });
            }
        };

        $scope.deleteArtwork = function () {
            var messageParams = {};
            messageParams.message = 'If you want to delete the artwork, please do so from your prortfolio.';
            messageParams.title = 'Delete Artwork';
            messageParams.disableAutoDismiss = true;
            Messages.openMessage($scope, messageParams);
        };
    },
]);
