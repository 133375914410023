'use strict';

//Authentication service for user variables
angular.module('users').service('TokenService', [
    '$window',
    '$rootScope',
    '$localStorage',
    'ENV',
    'PlatformManager',
    'GoogleAnalytics',
    '$cookies',
    function ($window, $rootScope, $localStorage, ENV, PlatformManager, GoogleAnalytics, $cookies) {
        var self = this;
        this.$storage = $localStorage;

        function generatePublicToken() {
            var publicToken =
                ENV + $window.navigator.appCodeName + $window.navigator.appName + $window.navigator.platform;
            publicToken = publicToken.replace(/M/g, Math.random().toString().substring(2, 6));
            publicToken = publicToken.replace(/N/g, Math.random().toString().substring(2, 6));
            publicToken = publicToken.replace(/d/g, Math.random().toString().substring(2, 6));
            return publicToken;
        }

        function initToken() {
            self.$storage.token = self.$storage.token || undefined;
            self.getOrCreatePublicToken();
        }

        this.setToken = function (token, cookiedata) {
            self.$storage.token = token;
            self.setCookie(cookiedata);
        };

        this.setCookie = function (cookiedata) {
            var ed = new Date();
            ed.setDate(ed.getDate() + (120 * 6) / 2); // Expired after 1 year
            var domain = ENV !== 'local' ? PlatformManager.platform.name + '.com' : 'localhost';
            var options = {
                expires: ed,
                domain: domain,
                path: '/',
            };
            cookiedata.env = ENV;
            $cookies.put('useapp-' + ENV, JSON.stringify(cookiedata), options);
        };

        this.getToken = function () {
            return self.$storage.token;
        };

        this.getTokenData = () => {
            const token = self.getToken();
            if (token) {
                return JSON.parse(atob(token.split('.')[1]));
            }
            return null;
        };

        this.getOrCreatePublicToken = function (publicToken) {
            self.$storage.publicToken = publicToken || self.$storage.publicToken || generatePublicToken();
            return self.$storage.publicToken;
        };

        this.clearToken = function () {
            self.$storage.token = undefined;
            self.$storage.publicToken = undefined;
            var domain = ENV !== 'local' ? PlatformManager.platform.name + '.com' : 'localhost';
            $cookies.remove('useapp-' + ENV, { domain: domain });
        };

        //this.setUserFromToken = function () {
        //    var user = undefined;
        //
        //    if (self.$storage.token) {
        //        var encodedProfile = self.$storage.token.split('.')[1];
        //        user = JSON.parse(url_base64_decode(encodedProfile));
        //        $log.debug('TokenService::setUserFromToken', JSON.stringify({user: user}));
        //    }
        //
        //    return user;
        //};

        //self.clearToken();

        $rootScope.$on('ClearData', function () {
            self.clearToken();
            GoogleAnalytics.setUserProperty('user_title', null);
            GoogleAnalytics.setUserId(null);
        });

        $rootScope.$on('clear_user', function () {
            self.clearToken();
            GoogleAnalytics.setUserProperty('user_title', null);
            GoogleAnalytics.setUserId(null);
        });

        $rootScope.$on('InitServices', initToken);
        initToken();
    },
]);
