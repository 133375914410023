const { allCountries } = require('country-telephone-data');
const emojiFlags = require('emoji-flags');
const libphonenumberJs = require('libphonenumber-js');

angular.module('core').service('Countries', [
    '$window',
    function ($window) {
        this.flags = emojiFlags;
        this.list = allCountries;

        this.getAll = () => {
            return this.list.map((c) => {
                const IATA_CODE = c.iso2.toUpperCase();
                const numberFormat = c.format?.replaceAll('.', '#');
                const countryIcon = this.flags.countryCode(IATA_CODE)?.emoji;
                const cleanName = c.name.includes('(') ? c.name.split('(')[0] : c.name;

                return {
                    raw_name: c.name,
                    name: `${countryIcon} ${cleanName} +${c.dialCode} `,
                    code: IATA_CODE,
                    format: numberFormat,
                    dialCode: c.dialCode,
                    icon: countryIcon,
                    isValid: (phone) => libphonenumberJs.isValidPhoneNumber(phone, IATA_CODE) === true,
                };
            });
        };
    },
]);
