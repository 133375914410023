require('./css/main.scss');

window.translationsEN = {};
window.translationsHE = {};

const files = require.context('./modules', true, /\.js$/);

const firstFiles = ['./consts.js', './config.js', './application.js'];

const modulesSorter = (first, second) => {
    if (!first.includes('profile.client.module') && second.includes('profile.client.module')) {
        return -1;
    }
    if (first.includes('profile.client.module') && !second.includes('profile.client.module')) {
        return 1;
    }
    return 0;
};

const moduleFiles = files
    .keys()
    .filter((file) => file.includes('.module.js'))
    .sort(modulesSorter);

const allOtherFiles = files
    .keys()
    .filter((file) => !firstFiles.includes(file))
    .filter((file) => !moduleFiles.includes(file))
    .filter((file) => !/\\.(spec|test)\\.js$/.test(file));

firstFiles.forEach((file) => files(file));
moduleFiles.forEach((file) => files(file));
allOtherFiles.forEach((file) => files(file));

// cache angular templates
angular.module('niio').run([
    '$templateCache',
    ($templateCache) => {
        const requireAll = (requireContext) => {
            return requireContext.keys().map((val) => ({
                content: requireContext(val),
                name: val.substr(2),
            }));
        };

        const templates = requireAll(require.context('./', true, /modules\/.+\/.+\.html$/));

        templates.forEach((file) => {
            $templateCache.put(file.name, file.content.default);
        });
    },
]);
