//English
translationsEN.HOME = {};

translationsEN.HOME.BASIC = 'Main';
translationsEN.HOME.ADVANCED = 'Advanced';
translationsEN.HOME.MAIN = 'Overview';
translationsEN.HOME.CHANNELS = 'Channels';
translationsEN.HOME.VAULT = 'Vault';
translationsEN.HOME.PORTFOLIO = 'Portfolio';
translationsEN.HOME.CHANNELS_ADMIN = 'Channels';
translationsEN.HOME.VAULT_ADMIN = 'Vault';
translationsEN.HOME.PORTFOLIO_ADMIN = 'Portfolio';
translationsEN.HOME.DEVICES = 'Displays';
translationsEN.HOME.ARTWORKS = 'Artworks';
translationsEN.HOME.ABOUT = 'About';
translationsEN.HOME.ARTCASTS = 'Artcasts';
translationsEN.HOME.CATALOGS = 'Catalogs';

//HebrewHOME
translationsHE.HOME = {};

translationsHE.HOME.STREAMS = 'שותף';
translationsHE.HOME.MY_ARTWORKS = 'האומנות שלי';
translationsHE.HOME.CONNECTIONS = 'קשרים';
translationsHE.HOME.DEVICES = 'מכשירים';
