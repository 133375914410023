/**
 * Created by Ami on 2/8/2018.
 */
'use strict';

angular.module('home').service('UserPlansDataService', [
    '$rootScope',
    '$log',
    '$http',
    '$q',
    '$filter',
    '_',
    'HomeDataService',
    function ($rootScope, $log, $http, $q, $filter, _, HomeDataService) {
        var self = this;
        self.HomeDataService = HomeDataService;

        this.duplicateUserPlan = function (planId) {
            var deferred = $q.defer();
            deferred.reject('NOT IMPLEMENTED');
            return deferred.promise;
        };

        this.setPlanToUser = function (userId, planId) {
            var deferred = $q.defer();
            var data = {
                userId: userId,
                planId: planId,
            };

            $http.post('/userPlans/setPlanToUser', data).then(
                function (res) {
                    $log.debug('setPlanToUser Success', res, data);
                    deferred.resolve();
                },
                function (err) {
                    deferred.reject(err);
                }
            );

            return deferred.promise;
        };

        this.setAccountStatus = function (userId, status) {
            var deferred = $q.defer();
            var data = {
                userId: userId,
                status: status,
            };

            $http.post('/userPlans/setAccountStatus', data).then(
                function (res) {
                    $log.debug('setAccountStatus Success', res, data);
                    deferred.resolve();
                },
                function (err) {
                    deferred.reject(err);
                }
            );

            return deferred.promise;
        };

        this.mapStatusName = function (status) {
            var s = parseInt(status);
            switch (s) {
                case 0: {
                    return 'Blocked';
                }
                case 1: {
                    return 'Active';
                }
                case 2: {
                    return 'Trial';
                }
                default: {
                    return '';
                }
            }
        };

        this.deleteUserPlan = function (planId) {
            var deferred = $q.defer();
            deferred.reject('NOT IMPLEMENTED');
            return deferred.promise;
        };

        this._plans = undefined;

        this.getAllPlans = function () {
            var deferred = $q.defer();
            var data = {};

            if (self._plan !== undefined) {
                deferred.resolve(self._plans);
                return deferred.promise;
            }

            $http.post('/userPlans/getAllPlans', data).then(
                function (res) {
                    self._plans = res.data.plans.all;
                    $rootScope.$broadcast('HomeDataService::refresh_all_userplans', self._plans);
                    deferred.resolve(self._plans);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getUserPlanData = function (planId) {
            var deferred = $q.defer();
            self.getAllPlans().then(
                function (plans) {
                    var selectedPlan = _.find(plans, function (planItem) {
                        return planItem.uid === planId;
                    });
                    if (selectedPlan !== undefined) {
                        deferred.resolve(selectedPlan);
                    } else {
                        deferred.reject();
                    }
                },
                function () {
                    deferred.reject();
                }
            );

            return deferred.promise;
        };

        this.getPlanDataAndPath = function (planId) {
            var deferred = $q.defer();
            var data = {};

            $http.post('/userPlans/getPlanDataAndPath/' + planId, data).then(
                function (res) {
                    deferred.resolve(res.data.planDataAndPath);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.checkLatestReceipt = function (userId) {
            var deferred = $q.defer();
            var data = {
                userId: userId,
            };

            $http.post('/billing/checkLatestReceipt', data).then(
                function (res) {
                    deferred.resolve(res.data.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getSubscriptionPlansHistory = function (userId) {
            var deferred = $q.defer();
            var data = {};
            if (userId) {
                data.userId = userId;
            }

            $http.post('/userPlans/getSubscriptionPlansHistory', data).then(
                function (res) {
                    deferred.resolve(res.data.data);
                },
                function (error) {
                    deferred.reject(error);
                }
            );

            return deferred.promise;
        };

        this.getUserPlanStatusByUserUrl = function (userUrl) {
            var deferred = $q.defer();

            if (userUrl) {
                self.HomeDataService.getUserDetailsByUrl(userUrl).then(
                    function (user) {
                        deferred.resolve(user);
                    },
                    function (error) {
                        $log.debug('getUserDetailsByUrl failed for url:' + userUrl);
                        deferred.reject(error);
                    }
                );
            } else {
                deferred.reject('parameter is empty');
            }

            return deferred.promise;
        };

        function init() {
            self.defaultTab = undefined;
        }
        init();
    },
]);
