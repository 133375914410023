'use strict';

angular.module('home').controller('HomePopupController', [
    'Messages',
    function (Messages) {
        var self = this;
        this.Messages = Messages;

        this.init = function () {
            self.message = self.params.message;
            self.title = self.params.title;
            self.cancelText = self.params.cancelText;
            self.okText = self.params.okText;
            self.okFunction = self.params.okFunction;
            self.toggles = self.params.toggles;
        };
    },
]);
